import { FormControl } from '@angular/forms';

// tslint:disable-next-line: max-line-length
const EMAIL_REGEX = /^([\w!#$%&"*+\/=?^_`{|}~.-]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

export function validMutipleEmails(control: FormControl): { [s: string]: boolean } {
    const val = control.value;
    if (val === '' || val === null || val === undefined ) { // email not required
       return null;
    }
    const result = val.split(/,|;/);
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.length; i++) {
      if (!isValidEmail(result[i])) {
        return {multipleEmails: true};
      }
    }
    return null;
}

export function isValidEmail(val: string) {
  if (!val) {
      return null;
  }

  if (val.trim().match(EMAIL_REGEX)) {
     return true;
  }

  return false;
}
