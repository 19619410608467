<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="modal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Data Retention Policy Changed</h4>
      </div>
      <div class="modal-body">A change was made to the data retention policy for your account on {{modifiedOn}} by {{modifiedBy}}.
        <br /><br />
        The policy was changed from retaining <b>{{previousNumberOfWeeks}}</b> weeks to retaining <b>{{numberOfWeeks}}</b> weeks
        of data, effective on {{startDate}} UTC - 5:00; <br/><br/> If you did not intend to make this change, please go to
        <a (click)= "goToAdmin()">Company Admin </a> to change your policy.<br/><br/> This notice will persist at log in until {{startDate}}.
      </div>
      <div class="clearfix"></div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" (click)="modal.hide()">{{ 'common.Ok' | translate }}</button>
        </div>
    </div>
  </div>
</div>

