/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./terms.component";
import * as i3 from "@angular/common/http";
import * as i4 from "../../../alk-components/alk-translations/alk-language-context";
import * as i5 from "@angular/platform-browser";
import * as i6 from "@ngx-translate/core/src/translate.service";
var styles_Terms = [];
var RenderType_Terms = i0.ɵcrt({ encapsulation: 2, styles: styles_Terms, data: {} });
export { RenderType_Terms as RenderType_Terms };
function View_Terms_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "alert alert-danger alert-dismissible"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "button", [["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.errorMessage = null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(4, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform(_co.errorMessage)); _ck(_v, 5, 0, currVal_0); }); }
export function View_Terms_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "message-panel"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_Terms_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.errorMessage)); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.termsHtml; _ck(_v, 4, 0, currVal_1); }); }
export function View_Terms_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "terms", [], null, null, null, View_Terms_0, RenderType_Terms)), i0.ɵdid(1, 114688, null, 0, i2.Terms, [i3.HttpClient, i4.ALKLanguageContext, i5.DomSanitizer, i6.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TermsNgFactory = i0.ɵccf("terms", i2.Terms, View_Terms_Host_0, {}, {}, []);
export { TermsNgFactory as TermsNgFactory };
