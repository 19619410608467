// angular components
import { Component, OnInit, OnDestroy, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription ,  Observable } from 'rxjs';

// 3rd party components
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { ModalDirective } from 'ngx-bootstrap';

// app components
import { VehicleService, Vehicle } from './../../vehicle/shared';

@Component({
  selector: 'vehicle-edit-modal',
  providers: [VehicleService],
  templateUrl: './vehicle-edit-modal.component.html'
})
// tslint:disable-next-line: component-class-suffix
export class VehicleEditModal implements OnInit, OnDestroy {
  vehicleId: number;
  vehicle: Vehicle;
  addMode = false; // if false we are in edit mode
  isProcessing = false;
  submitted = false;
  roles: Array<any> = [];
  selectedRole: Array<any> = [];
  emailErrorMsg = '';
  errorMsg = '';
  form: FormGroup;

  @ViewChild('modal', { static: true }) modal: ModalDirective;
  @Output() vehicleChanged = new EventEmitter();

  private sub1: Subscription;

  constructor(
    private vehicleService: VehicleService,
    private fb: FormBuilder,
    private el: ElementRef,
    private ag2analytics: Angulartics2GoogleAnalytics) {
    this.vehicle = { name: '', vehicleId: '' };
    this.form = this.fb.group({
      name: [this.vehicle.name, [Validators.minLength(1), Validators.maxLength(320)]],
      vehicleId: [this.vehicle.vehicleId, [Validators.required, Validators.minLength(1), Validators.maxLength(320)]]
    });
  }

  ngOnInit() {

    this.sub1 = this.modal.onShown.subscribe(() => {
      const nativeElement = this.el.nativeElement.getElementsByTagName('input')[this.addMode ? 0 : 1];
      if (nativeElement) { nativeElement.focus(); }
    });
  }

  ngOnDestroy() {
    this.sub1.unsubscribe();
  }

  open(addMode: boolean, vehicleId?: number) {
    this.addMode = addMode;
    if (vehicleId) {
      this.vehicleId = vehicleId;
    }

    // better way?  should we be destroying this on modal close instead of hide?
    this.errorMsg = '';
    this.emailErrorMsg = '';

    this.ag2analytics.eventTrack(`Vehicle ${addMode ? 'Add' : 'Edit'} dialog opened`, {});
    this.modal.show();

    if (this.addMode) {
      this.vehicle = { name: '', vehicleId: '' };
      this.updateControlValues();
    } else {
      this.isProcessing = true;

      this.vehicleService.getVehicle(vehicleId).subscribe(data => {
        this.vehicle.name = data.name;
        this.vehicle.vehicleId = data.vehicleId;
        this.updateControlValues();
        this.isProcessing = false;
      });
    }
  }

  save() {
    this.submitted = true;
    // clear error and success messages when saving
    this.errorMsg = null;

    if (!this.form.valid) {
      return;
    }

    if (!this.form.valid) {
      this.ag2analytics.eventTrack('Vehicle Edit save attempted', { failReason: 'Invalid input' });
      return;
    }

    this.vehicle.name = this.form.value.name;
    this.vehicle.vehicleId = this.form.value.vehicleId;
    this.isProcessing = true;

    let createOrUpdateVehicle$: Observable<any> = null;
    if (this.addMode) {
      createOrUpdateVehicle$ = this.vehicleService.createVehicle(this.vehicle);
    } else { createOrUpdateVehicle$ = this.vehicleService.updateVehicle(this.vehicleId, this.vehicle); }

    createOrUpdateVehicle$.subscribe(resp => {
      this.vehicle.name = resp.name;

      this.isProcessing = false;
      this.submitted = false;
      this.ag2analytics.eventTrack('Vehicle update save successful', {});
      this.modal.hide();
      this.vehicleChanged.emit({
        addedNew: this.addMode,
        id: this.vehicleId,
        vehicle: this.vehicle
      });
    }, (err) => {
      this.isProcessing = false;
      let errMsg = '';
      if (err.status === 409) {
        let duplicateOf = 'value';
        if (err.statusText.toUpperCase().includes('DUPLICATE VEHICLE NAME')) {
          duplicateOf = 'name';
        } else if (err.statusText.toUpperCase().includes('DUPLICATE VEHICLE ID')) {
          duplicateOf = 'id';
             }
        errMsg = `A vehicle with this ${duplicateOf} already exists.`;
      } else {
        errMsg = `An error occurred ${this.addMode ? 'adding' : 'updating'} the vehicle: ` + err;
      }
      this.ag2analytics.eventTrack('Vehicle create/update error', { errMsg });
      this.errorMsg = errMsg;
    });
  }

  private updateControlValues() {
    const controlName = this.form.controls.name as FormControl;
    controlName.setValue(this.vehicle.name);
    const controlVehicleId = this.form.controls.vehicleId as FormControl;
    controlVehicleId.setValue(this.vehicle.vehicleId);
  }

}
