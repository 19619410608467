<fieldset [disabled]="isProcessing">
  <form [formGroup]="form" (ngSubmit)="save()" novalidate>

    <div class="container">

      <div class="message-panel">
        <div *ngIf="(successMessage | async)" class="alert alert-success alert-dismissible" role="alert">
          <button type="button" class="close" (click)="successMessage=null"><span>&times;</span></button>
          <strong>{{successMessage | async}}</strong>
        </div>
        <div *ngIf="(errorMessage | async)" class="alert alert-danger alert-dismissible" role="alert">
          <button type="button" class="close" (click)="errorMessage=null"><span>&times;</span></button>
          <strong>{{errorMessage | async}}</strong>
        </div>
      </div>


      <div class="panel page-panel">
        <div class="row panel action-panel">
          <div class="col-xs-12">
            <h4 class="text-center">
              <span [innerHtml]="'views.avoid-favorSet.Title' | translate"></span>
            </h4>
          </div>
        </div>

        <div class="row header-panel">
          <label class="col-xs-4" [ngClass]="{'has-error': !isInitializing && !form.controls.name.valid }">
            <span [innerHtml]="'views.avoid-favorSet.SetName' | translate"></span>
            <input type="text" id="group-name-input" class="form-control input-sm" formControlName="name" readonly = "true" />
            <div *ngIf="!isInitializing && (!form.controls.name.valid)" class="glyphicon glyphicon-exclamation-sign error-glyph">
              <div class="error-tooltip">
                <p *ngIf="form.controls.name.errors?.required">{{ 'common.FieldIsRequired' | translate }}</p>
              </div>
            </div>
          </label>
          <div class="col-xs-4"></div>
        </div>

        <div class="row tab-panel">
          <tabset>
            <tab heading="{{'views.avoid-favorSet.tab.VehicleGroups' | translate}}">
               <!-- <h4 class="section-header">
                 <span [innerHtml]="'views.avoid-favorSet.tab.VehicleGroups' | translate"></span>
                </h4> -->
              <group-associations #vehicleGroupAssociations entityLabel="{{'views.avoid-favorSet.tab.VehicleGroups' | translate}}"
                [displayPerPage]="10" additionalTableClasses="clickable-row" (itemSelected)="onItemSelected($event)">
              </group-associations>

            </tab>

          </tabset>
        </div>

        <div class="modal-footer">

          <button type="button" class="btn btn-default cancel-btn" (click)="cancel()">
              <span [innerHtml]="'common.Cancel' | translate"></span>
            </button>
          <button type="submit" id="save-vehicle-group" class="btn btn-success pull-right" [disabled]="!form.valid">
              <i *ngIf="!isInitializing && !form.valid" class="glyphicon glyphicon-exclamation-sign error-glyph"></i>
              <span [innerHtml]="'common.Save' | translate"></span>
            </button>
        </div>


      </div>
    </div>
  </form>
</fieldset>

<confirmation-modal #confirmModal title="{{ 'views.unsaved-changes-modal.title' | translate}}" body="{{ 'views.unsaved-changes-modal.body' | translate}}"></confirmation-modal>
