<div class="device-list-panel">
  <div class="panel-heading">
    <h3 class="panel-title pull-left">
      Vehicles
      <span class="ico ico-circle-notch ico-spin" *ngIf="isProcessing"></span>
    </h3>

    <div class="pull-right">
    </div>

    <div class="clearfix"></div>
  </div>

  <div class="panel-body">
    <div class="filter-panel">
      <label>
        <span [innerHtml]="'common.Filter' | translate"></span>
        <input type="text" class="form-control input-sm" placeholder="" [formControl]="searchTerm" (keydown.escape)="clearSearchTerm()" />
      </label>
      <label>
        <input type="checkbox" (change)="toggleActiveVehicles()" [(ngModel)]="isActiveVehiclesOnly" style="margin-left:10px"/>
        <span [innerHtml]="'views.company-administration.ShowOnlyActiveVehicles' | translate" style="color:black"></span>
        <span class="glyphicon glyphicon-refresh spinner" *ngIf="isProcessing"></span>
      </label>

      <button class="btn btn-default btn-sm pull-right btn-sm filter-fix" type="button" (click)="vehicleEditModal.open(true)"
        *ngIf="addButtonVisible">
        <span class="ico ico-plus"></span>
        <span [innerHtml]="'common.New' | translate"></span>
      </button>
      <div class="pull-right filter-fix">
        <span *ngIf="!isImportEnabled && !isExportEnabled">
          <br />
          <br />
        </span>
        <div class="btn-group push-right">
          <button class="btn btn-default btn-sm" *ngIf="isImportEnabled" [routerLink]="['/vehicles/import']">
            <a class="link-btn">
              <span class="glyphicon glyphicon-cloud-upload"></span>
              <span [innerHtml]="'common.Import' | translate"></span>
            </a>
          </button>
        <a class="link-btn btn btn-default btn-sm" [href]="downloadUrl" *ngIf="isExportEnabled" download="vehicles.csv" style="border-left: 1px solid white;">
          <span class="glyphicon glyphicon-cloud-download"></span>
          <span> Vehicle Summary</span>
        </a>

        </div>
      </div>
    </div>

    <div *ngIf="vehicleAdded==true" class="alert alert-success" role="alert">
      <strong>New vehicle successfully added</strong>
    </div>
    <div *ngIf="errorMsg" class="row alert alert-danger" role="alert">
      <label [innerHtml]="'common.Error' | translate"></label>
      <span>{{errorMsg}}</span>
    </div>

    <table class="table table-striped table-hover list-table clickable-row">
      <thead>
        <tr>
          <th (click)="sortString('vehicleId', sortAsc);sortAsc=!sortAsc;sortCol='vehicleId'" class="sortable-column vehicle-list-id-col">
            <span>Vehicle ID</span>
            <span class="column-arrows">
              <span *ngIf="sortCol=='vehicleId'" class="glyphicon" [ngClass]="{'glyphicon-triangle-top': !sortAsc, 'glyphicon-triangle-bottom': sortAsc}"></span>
            </span>
          </th>
          <th (click)="sortString('name', sortAsc);sortAsc=!sortAsc;sortCol='name'" class="sortable-column vehicle-list-name-col">
            <span>Name</span>
            <span class="column-arrows">
              <span *ngIf="sortCol=='name'" class="glyphicon" [ngClass]="{'glyphicon-triangle-top': !sortAsc, 'glyphicon-triangle-bottom': sortAsc}"></span>
            </span>
          </th>
          <th (click)="sortString('vehicleGroupsAbbreviated', sortAsc);sortAsc=!sortAsc;sortCol='vehicleGroupsAbbreviated'"
            class="sortable-column vehicle-group-abbreviated-list-col">
            <span>Vehicle Groups</span>
            <span class="column-arrows">
              <span *ngIf="sortCol=='vehicleGroups'" class="glyphicon" [ngClass]="{'glyphicon-triangle-top': !sortAsc, 'glyphicon-triangle-bottom': sortAsc}"></span>
            </span>
          </th>
          <th style="text-align:right;width:125px;">
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of pageItems; let i = index">
          <td (click)="showVehicleDetails(item)" class="vehicle-list-id-col">
            <span [innerHtml]="item.vehicleId | alkHighlight:searchTerm.value" title="{{item.vehicleId}}"></span>
          </td>
          <td (click)="showVehicleDetails(item)" class="vehicle-list-name-col">
            <span [innerHtml]="item.name | alkHighlight:searchTerm.value" title="{{item.name}}"></span>
          </td>
          <td (click)="showVehicleDetails(item)" class="vehicle-list-id-col">
            <span [innerHtml]="item.vehicleGroupsAbbreviated | alkHighlight:searchTerm.value" [title]="getToolTipForGroups(item.vehicleGroups)"></span>
          </td>
          <td class="text-right">
            <a title="{{'common.Edit' | translate}}" (click)="editVehicle(item)" class="btn btn-default" *ngIf="editButtonVisible">
              <span class="glyphicon glyphicon-edit"></span>
            </a>
            <button type="button" (click)="deleteItem(item)" title="{{'common.Delete' | translate}}" class="btn btn-default"
              *ngIf="deleteButtonVisible">
              <span class="ico ico-trash"></span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="pagination-panel">
      <div class="pull-left grid-details">
        {{ 'common.DisplayOf' | translate:{pageStartNumber:pageStart(), pageEndNumber:pageEnd(), total:total} }}
      </div>
      <div class="pull-right">
        <button type="button" class="btn btn-default" (click)="previousPage()" [disabled]="!hasPreviousPage()">
          <span class="glyphicon glyphicon-menu-left"></span>
          <span [innerHtml]="'common.Previous' | translate"></span>
        </button>
        <button type="button" class="btn btn-default" (click)="nextPage()" [disabled]="!hasNextPage()">
          <span [innerHtml]="'common.Next' | translate"></span>
          <span class="glyphicon glyphicon-menu-right"></span>
        </button>
      </div>
    </div>

  </div>
</div>

<confirmation-modal #confirmModal title="Delete Vehicle" body="You are deleting this vehicle. Are you sure?"></confirmation-modal>
<vehicle-edit-modal #vehicleEditModal (vehicleChanged)="onVehicleUpdated($event)"></vehicle-edit-modal>
