
import {from as observableFrom,   Observable } from 'rxjs';
// angular components
import { Component,  OnInit,  ViewChild } from '@angular/core';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';

// 3rd party components
import { TranslateService } from '@ngx-translate/core';

import { ConfirmationModal, incrementBy, min, max} from './../shared';
import {  RoadSpeedsService } from './../shared/road-speeds-service';

import {  AccountManagementService } from './../../alk-components/account-management';
import {  UserContextService } from './../../alk-components/authentication';
import {convertKMToMile, convertMileToKM} from './../shared/utils/convertUtil';

const GlobalRoadSpeedsConstants = {
  Truck : [
    {roadCategory: 'InterStateRural', roadSpeedValue: 43},
    {roadCategory: 'DividedRural', roadSpeedValue: 31},
    {roadCategory: 'PrimaryRural', roadSpeedValue: 25},
    {roadCategory: 'FerriesRural', roadSpeedValue: 18},
    {roadCategory: 'SecondaryRural', roadSpeedValue: 16},
    {roadCategory: 'LocalRural', roadSpeedValue: 12},
    {roadCategory: 'InterStateUrban', roadSpeedValue: 43},
    {roadCategory: 'DividedUrban', roadSpeedValue: 31},
    {roadCategory: 'PrimaryUrban', roadSpeedValue: 25},
    {roadCategory: 'FerriesUrban', roadSpeedValue: 18},
    {roadCategory: 'SecondaryUrban', roadSpeedValue: 16},
    {roadCategory: 'LocalUrban', roadSpeedValue: 12}
  ],

  Auto : [
    {roadCategory: 'InterStateRural', roadSpeedValue: 55},
    {roadCategory: 'DividedRural', roadSpeedValue: 31},
    {roadCategory: 'PrimaryRural', roadSpeedValue: 25},
    {roadCategory: 'FerriesRural', roadSpeedValue: 18},
    {roadCategory: 'SecondaryRural', roadSpeedValue: 16},
    {roadCategory: 'LocalRural', roadSpeedValue: 12},
    {roadCategory: 'InterStateUrban', roadSpeedValue: 55},
    {roadCategory: 'DividedUrban', roadSpeedValue: 31},
    {roadCategory: 'PrimaryUrban', roadSpeedValue: 25},
    {roadCategory: 'FerriesUrban', roadSpeedValue: 18},
    {roadCategory: 'SecondaryUrban', roadSpeedValue: 16},
    {roadCategory: 'LocalUrban', roadSpeedValue: 12}
  ],

  MidsizeTruck : [
    {roadCategory: 'InterStateRural', roadSpeedValue: 50},
    {roadCategory: 'DividedRural', roadSpeedValue: 35},
    {roadCategory: 'PrimaryRural', roadSpeedValue: 30},
    {roadCategory: 'FerriesRural', roadSpeedValue: 18},
    {roadCategory: 'SecondaryRural', roadSpeedValue: 25},
    {roadCategory: 'LocalRural', roadSpeedValue: 18},
    {roadCategory: 'InterStateUrban', roadSpeedValue: 50},
    {roadCategory: 'DividedUrban', roadSpeedValue: 31},
    {roadCategory: 'PrimaryUrban', roadSpeedValue: 25},
    {roadCategory: 'FerriesUrban', roadSpeedValue: 18},
    {roadCategory: 'SecondaryUrban', roadSpeedValue: 16},
    {roadCategory: 'LocalUrban', roadSpeedValue: 12}
  ],

  LightDuty : [
    {roadCategory: 'InterStateRural', roadSpeedValue: 65},
    {roadCategory: 'DividedRural', roadSpeedValue: 45},
    {roadCategory: 'PrimaryRural', roadSpeedValue: 30},
    {roadCategory: 'FerriesRural', roadSpeedValue: 18},
    {roadCategory: 'SecondaryRural', roadSpeedValue: 25},
    {roadCategory: 'LocalRural', roadSpeedValue: 18},
    {roadCategory: 'InterStateUrban', roadSpeedValue: 55},
    {roadCategory: 'DividedUrban', roadSpeedValue: 30},
    {roadCategory: 'PrimaryUrban', roadSpeedValue: 20},
    {roadCategory: 'FerriesUrban', roadSpeedValue: 18},
    {roadCategory: 'SecondaryUrban', roadSpeedValue: 15},
    {roadCategory: 'LocalUrban', roadSpeedValue: 10}
  ],

  Bus : [
    {roadCategory: 'InterStateRural', roadSpeedValue: 50},
    {roadCategory: 'DividedRural', roadSpeedValue: 35},
    {roadCategory: 'PrimaryRural', roadSpeedValue: 30},
    {roadCategory: 'FerriesRural', roadSpeedValue: 18},
    {roadCategory: 'SecondaryRural', roadSpeedValue: 25},
    {roadCategory: 'LocalRural', roadSpeedValue: 18},
    {roadCategory: 'InterStateUrban', roadSpeedValue: 50},
    {roadCategory: 'DividedUrban', roadSpeedValue: 31},
    {roadCategory: 'PrimaryUrban', roadSpeedValue: 25},
    {roadCategory: 'FerriesUrban', roadSpeedValue: 18},
    {roadCategory: 'SecondaryUrban', roadSpeedValue: 16},
    {roadCategory: 'LocalUrban', roadSpeedValue: 12}
  ]

};

@Component({
  providers: [RoadSpeedsService],
  templateUrl: './road-speeds.component.html'
})
// tslint:disable-next-line: component-class-suffix
export class RoadSpeedsProfile implements OnInit {
  @ViewChild('confirmModal', { static: true }) confirmModal: ConfirmationModal;
  @ViewChild('globalModal', { static: true }) globalModal: ConfirmationModal;
  @ViewChild('jurisModal', { static: true }) jurisModal: ConfirmationModal;

  @ViewChild('vehicleType', { static: false }) vehicleType: any; // why doesn't SelectComponent work?  (from ng2-select)

  // preset values
  public isRoadSpeedsEnabled = false;
  public region: string = this.userContext.getUser().regionalDataSet.indexOf('EU') >= 0 ? 'EU' : 'NA';
  public showGlobalRoadSpeeds = this.userContext.getUser().showGlobalRoadSpeeds;
  public units: string = this.userContext.getUser().unitType === 'Metric' ? 'kph' : 'mph';
  public maxSpeed: number = this.userContext.getUser().unitType === 'Metric' ? 137 : 85;
  public minSpeed: number = this.userContext.getUser().unitType === 'Metric' ? 8 : 5;
  public unitType: string;
  public showUnitConversionTooltip = false;

  // defaults
  public form: FormGroup;
  public speedTypeForm: FormGroup;

  public isProcessing = false;

  public speedSetting = '';

  public vehicleTypes: Array < any > = [];
  public selectedVehicleType = '';
  public vehicleObj: Array < any > = [];

  public selectedSpeedType = ''; // tab to be displayed

  public globalSpeeds: Array < any > = [];
  public jurisdictionSpeeds: Array < any > = [];
  public speedsBeforeReset: Array <any> = [];

  public jurisdictionList: Array < any > = [];
  public jurisAbbr = '';
  public jurisName = '';
  public areJurisSpeedsDefault = false;
  public countryList: Array < any > = [];

  public origSpeeds: Array < any > = [];

  public selectedCountry = '';

  public resetSelected = false;
  public enableSave = false; // used only for reset

  public confirmationMsg = '';
  public errorMessage: Observable < any > ;
  public successMessage: Observable < any > ;

  constructor(
    private roadSpeedsService: RoadSpeedsService,
    private accountService: AccountManagementService,
    private fb: FormBuilder,
    private fb2: FormBuilder,
    private translate: TranslateService,
    private userContext: UserContextService
  ) {
    this.form = this.fb.group({
      vehicleType: [],
      InterStateRural: [5, Validators.compose([
        min(this.minSpeed), max(this.maxSpeed), incrementBy(1), Validators.required,
      ])],
      DividedRural: [5, Validators.compose([
        min(this.minSpeed), max(this.maxSpeed), incrementBy(1), Validators.required
      ])],
      PrimaryRural: [5, Validators.compose([
        min(this.minSpeed), max(this.maxSpeed), incrementBy(1), Validators.required
      ])],
      FerriesRural: [5, Validators.compose([
        min(this.minSpeed), max(this.maxSpeed), incrementBy(1), Validators.required
      ])],
      SecondaryRural: [5, Validators.compose([
        min(this.minSpeed), max(this.maxSpeed), incrementBy(1), Validators.required
      ])],
      LocalRural: [5, Validators.compose([
        min(this.minSpeed), max(this.maxSpeed), incrementBy(1), Validators.required
      ])],
      InterStateUrban: [5, Validators.compose([
        min(this.minSpeed), max(this.maxSpeed), incrementBy(1), Validators.required
      ])],
      DividedUrban: [5, Validators.compose([
        min(this.minSpeed), max(this.maxSpeed), incrementBy(1), Validators.required
      ])],
      PrimaryUrban: [5, Validators.compose([
        min(this.minSpeed), max(this.maxSpeed), incrementBy(1), Validators.required
      ])],
      FerriesUrban: [5, Validators.compose([
        min(this.minSpeed), max(this.maxSpeed), incrementBy(1), Validators.required
      ])],
      SecondaryUrban: [5, Validators.compose([
        min(this.minSpeed), max(this.maxSpeed), incrementBy(1), Validators.required
      ])],
      LocalUrban: [5, Validators.compose([
        min(this.minSpeed), max(this.maxSpeed), incrementBy(1), Validators.required
      ])]
    });
    this.speedTypeForm = this.fb2.group({
      speedSelection: []
    });
  }

  public ngOnInit() {
    const user = this.userContext.getUser();
    this.unitType = user.unitType;
    this.showUnitConversionTooltip = user.enableUnitConversion;
    this.isRoadSpeedsEnabled = this.userContext.isRoadSpeedsEnabled();
    if (!this.isRoadSpeedsEnabled) {
      return;
    }
    this.isProcessing = true;
    this.vehicleTypes = [];
    this.jurisdictionList = [];

    this.accountService.getVehicleTypes().subscribe(result => {
      const availableVehicleTypes: Array < any > = [];
      result.forEach((vehicleType) => {
        switch (vehicleType) {
          case 'Truck':
            availableVehicleTypes.push({ seq : 0, id: 'Truck', text: 'Heavy Duty', icon: 'ico-heavyduty' });
            break;
          case 'MidsizeTruck':
            availableVehicleTypes.push({ seq: 1, id: 'MidsizeTruck', text: 'Medium Duty', icon: 'ico-mediumduty' });
            break;
          case 'LightDuty':
            availableVehicleTypes.push({ seq: 2, id: 'LightDuty', text: 'Light Duty', icon: 'ico-lightduty' });
            break;
          case 'Auto':
            availableVehicleTypes.push({ seq: 3, id: 'Auto', text: 'Auto', icon: 'ico-route-car' });
            break;

          case 'Bus':
            availableVehicleTypes.push({ seq: 4, id: 'Bus', text: 'School Bus', icon: 'ico-school-bus' });
            break;
        }
      });

      this.vehicleTypes = availableVehicleTypes.sort((a, b) => {
        return (a.seq > b.seq) ? 1 : ((b.seq > a.seq) ? -1 : 0);
      });
      this.vehicleObj = [this.vehicleTypes[0]];
      this.selectedVehicleType = this.vehicleTypes[0].id;
      this.form.get('vehicleType').setValue(this.vehicleTypes[0].id);

      if (this.userContext.getUser().useGlobalRoadSpeeds && this.userContext.getUser().showGlobalRoadSpeeds) {
        this.speedTypeForm.get('speedSelection').setValue('global');
        this.getGlobalSpeeds(false);
        this.speedSetting = 'global';
        this.selectedSpeedType = 'global'; // tab setting
      } else {
        this.speedTypeForm.get('speedSelection').setValue('juris');
        this.getJurisdictionList(this.selectedVehicleType);
        this.speedSetting = 'juris';
        this.selectedSpeedType = 'juris'; // tab setting

      }
    });

  }

  public onVehicleTypeChanged() {
    this.form.get('vehicleType').markAsPristine();
    if (this.form.dirty) {
      this.confirmModal.open().then(() => {
        this.changeVehicleType(this.form.get('vehicleType').value);
        this.form.markAsPristine();
      });
    } else {
      this.changeVehicleType(this.form.get('vehicleType').value);
    }
  }

  public changeVehicleType(id) {
    this.selectedVehicleType = id;
    const typeName = this.vehicleTypes.filter(type => type.id === id)[0].text;
    this.vehicleObj = [{
      id,
      text: typeName
    }];
    if (this.selectedSpeedType === 'juris') {
      this.getJurisdictionList(this.selectedVehicleType);
    } else {
      this.getGlobalSpeeds(false);
    }
    this.form.markAsPristine();
  }

  public getJurisdictionList(selectedVehicleType) {
    this.isProcessing = true;
    this.roadSpeedsService.getJurisdictionsList(selectedVehicleType).subscribe(result => {

      if (this.region === 'NA') {
        this.jurisdictionList = this.groupby(result, 'countryName');

        this.countryList = this.getCountries(this.jurisdictionList);
        this.selectedCountry = this.selectedCountry ? this.selectedCountry : this.countryList[0];
        if (this.selectedCountry) {
          this.changeVisibility(this.selectedCountry);
        }

        this.jurisAbbr = this.jurisAbbr ? this.jurisAbbr : this.jurisdictionList[this.selectedCountry][0].stateAbbr;
        this.jurisName = this.jurisName ? this.jurisName : this.jurisdictionList[this.selectedCountry][0].stateName;
        this.areJurisSpeedsDefault = this.jurisName ?
          this.isDefault(this.selectedCountry, this.jurisName, 'NA') : this.jurisdictionList[this.selectedCountry][0].areSpeedsDefault;
        this.onJurisdictionChange(this.jurisAbbr, this.jurisName, this.areJurisSpeedsDefault, false);

      } else {
        this.jurisdictionList = result;
        this.jurisAbbr = this.jurisAbbr ? this.jurisAbbr : this.jurisdictionList[0].countryAbbr;
        this.jurisName = this.jurisName ? this.jurisName : this.jurisdictionList[0].countryName;
        this.areJurisSpeedsDefault = this.jurisName ?
          this.isDefault('', this.jurisName, 'EU') : this.jurisdictionList[0].areJurisSpeedsDefault;
        this.onJurisdictionChange(this.jurisAbbr, this.jurisName, this.areJurisSpeedsDefault, false);
      }

    }, error => {
      this.errorMessage = this.translate.get('views.road-speeds.errors.getError');
      this.isProcessing = false;
    });
  }

  public getGlobalSpeeds(isDefault: boolean) {
    this.isProcessing = true;
    this.roadSpeedsService.getGlobalSpeeds(this.selectedVehicleType, isDefault).subscribe(result => {
      this.globalSpeeds = result;
      this.loadForm(this.globalSpeeds);
      this.isProcessing = false;
      this.areJurisSpeedsDefault = this.areGlobalSpeedsDefault(this.globalSpeeds, this.selectedVehicleType);
    }, error => {
      this.errorMessage = this.translate.get('views.road-speeds.errors.getError');
      this.isProcessing = false;
    });
  }

  public changeVisibility(country: string) { // since country is string, using list object
    if (this.jurisdictionList[country].show) {
      this.jurisdictionList[country].show = !this.jurisdictionList[country].show;
     } else {
       this.jurisdictionList[country].show = true;
     }
    this.selectedCountry = country;
  }

  public onTabChange(tab: string) {
    if (this.selectedSpeedType !== tab && this.speedSetting !== 'global') {
    if (this.form.dirty) {
      this.confirmModal.open().then(() => {
        this.form.markAsPristine();
        this.changeView(tab);
      });
    } else {
      this.changeView(tab);
    }
    // this.handleFormChanges(this.changeView, tab);
  }
}

  public changeView(tab: string) {
    this.isProcessing = true;
    if (tab === 'juris') {
      this.getJurisdictionList(this.selectedVehicleType);
    } else {
      this.getGlobalSpeeds(false);
    }
    this.selectedSpeedType = tab;
  }

  public onJurisdictionChange(state, name, areSpeedsDefault, isDefault) {
    this.isProcessing = true;
    if (this.form.dirty && !isDefault) {
      this.confirmModal.open().then(() => {
        this.form.markAsPristine();
        this.changeJurisdiction(state, name, areSpeedsDefault, isDefault);
      });
    } else {
      this.changeJurisdiction(state, name, areSpeedsDefault, isDefault);
    }
  }

  public changeJurisdiction(state, name, areSpeedsDefault, isDefault) {
    this.jurisName = name;
    this.jurisAbbr = state;
    this.areJurisSpeedsDefault = areSpeedsDefault;
    this.roadSpeedsService.getSpeedsbyJurisdiction(state, this.selectedVehicleType, isDefault).subscribe(result => {
      this.jurisdictionSpeeds = result;
      this.loadForm(this.jurisdictionSpeeds);
      this.enableSave = isDefault;
      this.isProcessing = false;
    }, error => {
      this.errorMessage = this.translate.get('views.road-speeds.errors.getError');
      this.isProcessing = false;
    });
  }

  public onSettingDropdown(type: string) {
    const modal = type === 'global' ? this.globalModal : this.jurisModal;
    if (this.speedSetting !== 'type') {
      modal.open().then(() => {
        this.changeSpeedSetting(type);
      });
    }
    this.speedTypeForm.get('speedSelection').setValue(this.speedSetting);
  }

  public changeSpeedSetting(type: string) {
    const useGlobal = type === 'global' ? true : false;
    this.roadSpeedsService.enableGlobalSpeeds(useGlobal)
      .subscribe(r => {
        this.speedSetting = type;
        this.successMessage = this.translate.get('view.road-speeds.savedSettings');
        this.speedTypeForm.get('speedSelection').setValue(this.speedSetting);
        this.userContext.getUser().useGlobalRoadSpeeds = useGlobal;
        if (type === 'global' && this.selectedSpeedType === 'juris') {
          this.form.markAsPristine(); // SWAT-1002 enable buttons
          this.changeView('global');
        }
      }, error => {
        this.speedTypeForm.get('speedSelection').setValue(this.speedSetting);
        this.errorMessage = this.translate.get('view.road-speeds.unableToSaveSettings');
      });
  }

  public loadForm(list) {
    this.origSpeeds = this.resetSelected ? this.origSpeeds : list;
    this.resetSelected = false;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < list.length; i++) {
      const category = list[i].roadCategory;
      const speedValue = list[i].roadSpeedValue;
      this.form.get(category).setValue(speedValue);
    }
  }

  public getCountries(list) {
    const countryList = [];
    Object.keys(list).forEach((key, index) => {
      countryList.push(key);
    });
    return countryList;
  }

  public resetRoadSpeeds() {
    this.form.markAsDirty();
    this.resetSelected = true;
    this.speedsBeforeReset = this.origSpeeds;
    if (this.selectedSpeedType === 'global') {
      this.getGlobalSpeeds(true);
    } else {
      this.onJurisdictionChange(this.jurisAbbr, this.jurisName, this.areJurisSpeedsDefault, true);
    }
  }

  public cancel() {
    this.form.markAsPristine();
    const speeds = this.resetSelected ? this.speedsBeforeReset : this.origSpeeds;
    if (this.selectedSpeedType === 'global') {
      this.areJurisSpeedsDefault =  this.areGlobalSpeedsDefault(speeds, this.selectedVehicleType);
    }
    this.resetSelected = false;
    this.loadForm(speeds);
  }

  public save() {
    this.errorMessage = null;
    this.successMessage = null;
    this.resetSelected = false;

    this.roadSpeedsService.updateSpeeds(
      this.selectedVehicleType,
      this.selectedSpeedType,
      this.jurisAbbr,
      this.form
    ).toPromise().then(() => {
      this.form.markAsPristine();
      if (this.selectedSpeedType === 'juris') {
        this.getJurisdictionList(this.selectedVehicleType);
      } else {
        this.getGlobalSpeeds(false);
      }
      this.successMessage = this.translate.get('views.road-speeds.saved');
    }, error => {
      this.isProcessing = false;
      this.errorMessage = this.translate.get('views.road-speeds.errors.DataError');
    });
  }

  public canDeactivate(): Observable < boolean > | boolean {
    if (!this.form.dirty) {
      return true;
    }
    return observableFrom(this.confirmModal.open());
  }

  // TODO @vani change all the functions to use this
  public handleFormChanges(func, ...args) {
    if (this.form.dirty) {
      this.confirmModal.open().then(() => {
        this.form.markAsPristine();
        func(args);
      });
    } else {
      func(args);
    }
  }

  public showInterstateSpeedConversionInfo() {
    if (this.unitType === 'English') {
      if (this.selectedSpeedType === 'global') {
        return `${convertMileToKM(this.form.controls.InterStateRural.value).toString()} kph`;
      }
      // tslint:disable-next-line: max-line-length
      return `${convertMileToKM(this.form.controls.InterStateRural.value).toString()} kph / ${convertMileToKM(this.form.controls.InterStateUrban.value).toString()} kph`;
    } else {
      if (this.selectedSpeedType === 'global') {
        return `${convertKMToMile(this.form.controls.InterStateRural.value).toString()} mph`;
      }
      // tslint:disable-next-line: max-line-length
      return `${convertKMToMile(this.form.controls.InterStateRural.value).toString()} mph / ${convertKMToMile(this.form.controls.InterStateUrban.value).toString()} mph`;
    }

  }

  public showDividedSpeedConversionInfo() {
    if (this.unitType === 'English') {
      if (this.selectedSpeedType === 'global') {
        return `${convertMileToKM(this.form.controls.DividedRural.value).toString()} kph`;
      }
      // tslint:disable-next-line: max-line-length
      return `${convertMileToKM(this.form.controls.DividedRural.value).toString()} kph / ${convertMileToKM(this.form.controls.DividedUrban.value).toString()} kph`;
    } else {
      if (this.selectedSpeedType === 'global') {
        return `${convertKMToMile(this.form.controls.DividedRural.value).toString()} mph `;
      }
    }
    // tslint:disable-next-line: max-line-length
    return `${convertKMToMile(this.form.controls.DividedRural.value).toString()} mph / ${convertKMToMile(this.form.controls.DividedUrban.value).toString()} mph`;
  }

  public showSecondarySpeedConversionInfo() {
    if (this.unitType === 'English') {
      if (this.selectedSpeedType === 'global') {
        return `${convertMileToKM(this.form.controls.SecondaryRural.value).toString()} kph`;
      }
      // tslint:disable-next-line: max-line-length
      return `${convertMileToKM(this.form.controls.SecondaryRural.value).toString()} kph / ${convertMileToKM(this.form.controls.SecondaryUrban.value).toString()} kph`;
    } else {
      if (this.selectedSpeedType === 'global') {
        return `${convertKMToMile(this.form.controls.SecondaryRural.value).toString()}  mph`;
      }
    }
    // tslint:disable-next-line: max-line-length
    return `${convertKMToMile(this.form.controls.SecondaryRural.value).toString()}  mph / ${convertKMToMile(this.form.controls.SecondaryUrban.value).toString()} mph`;
  }

  public showPrimarySpeedConversionInfo() {
    if (this.unitType === 'English') {
      if (this.selectedSpeedType === 'global') {
        return `${convertMileToKM(this.form.controls.PrimaryRural.value).toString()} kph`;
      }
      // tslint:disable-next-line: max-line-length
      return `${convertMileToKM(this.form.controls.PrimaryRural.value).toString()} kph / ${convertMileToKM(this.form.controls.PrimaryUrban.value).toString()} kph`;
    } else {
      if (this.selectedSpeedType === 'global') {
        return `${convertKMToMile(this.form.controls.PrimaryRural.value).toString()} mph`;
      }
    }
    // tslint:disable-next-line: max-line-length
    return `${convertKMToMile(this.form.controls.PrimaryRural.value).toString()} mph / ${convertKMToMile(this.form.controls.PrimaryUrban.value).toString()} mph`;
  }

  public showLocalSpeedConversionInfo() {
    if (this.unitType === 'English') {
      if (this.selectedSpeedType === 'global') {
        return `${convertMileToKM(this.form.controls.LocalRural.value).toString()} kph`;
      }
      // tslint:disable-next-line: max-line-length
      return `${convertMileToKM(this.form.controls.LocalRural.value).toString()} kph / ${convertMileToKM(this.form.controls.LocalUrban.value).toString()} kph`;
    } else {
      if (this.selectedSpeedType === 'global') {
        return `${convertKMToMile(this.form.controls.LocalRural.value).toString()} mph`;
      }
    }
    // tslint:disable-next-line: max-line-length
    return `${convertKMToMile(this.form.controls.LocalRural.value).toString()} mph / ${convertKMToMile(this.form.controls.LocalUrban.value).toString()} mph`;
  }

  public showFerrySpeedConversionInfo() {
    if (this.unitType === 'English') {
      if (this.selectedSpeedType === 'global') {
        return `${convertMileToKM(this.form.controls.FerriesRural.value).toString()} kph`;
      }
      // tslint:disable-next-line: max-line-length
      return `${convertMileToKM(this.form.controls.FerriesRural.value).toString()} kph / ${convertMileToKM(this.form.controls.FerriesUrban.value).toString()} kph`;
    } else {
      if (this.selectedSpeedType === 'global') {
        return `${convertKMToMile(this.form.controls.FerriesRural.value).toString()} mph`;
      }
    }
    // tslint:disable-next-line: max-line-length
    return `${convertKMToMile(this.form.controls.FerriesRural.value).toString()} mph / ${convertKMToMile(this.form.controls.FerriesUrban.value).toString()} mph`;
  }

  private isDefault(country, juris, region) {
    const list = region === 'NA' ? this.jurisdictionList[country] : this.jurisdictionList;
    const i = list.map((s) => {
      return s.stateName || s.countryName;
    }).indexOf(juris);
    return list[i].areSpeedsDefault;
  }

  private areGlobalSpeedsDefault(speeds, vehicleType) {
    return (JSON.stringify(speeds) === JSON.stringify(GlobalRoadSpeedsConstants[vehicleType]));
  }

  private groupby(list, prop) {
    return list.reduce((groups, item) => {
      const val = item[prop];
      groups[val] = groups[val] || [];
      groups[val].push(item);
      return groups;
    }, []);
  }
}
