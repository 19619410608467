// This was created for the file size of mapsets, if that functionality is never
// implemented in mapsets list or elsewhere then this pipe can be removed.

import {Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'fileSizeFormatter'})
export class FileSizeFormatterPipe implements PipeTransform {
  transform(value: number): string {
    if (value < 1000) {
      return value + ' MB';
    } else {
      return value.toString().slice(0, -3) + '.' + value.toString().slice(-3, -1) + ' GB';
    }
  }
}
