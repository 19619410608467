<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="onCancelClicked()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" [innerHtml]="title"></h4>
      </div>
      <div class="modal-body" [innerHtml]="body">
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default cancel-btn" (click)="onCancelClicked()">{{ 'common.Cancel' | translate }}</button>
        <button type="button" class="btn btn-default" (click)="onOKClicked()">OK</button>
      </div>
    </div>
  </div>
</div>
