<!--<pre>{{ form.value | json}}</pre>-->
<fieldset [disabled]="isProcessing || isInitializing">
    <form [formGroup]="form" (ngSubmit)="save()" novalidate>
      <div class="container">

        <div class="message-panel">
          <div *ngIf="(successMessage | async)" class="alert alert-success alert-dismissible" role="alert">
            <button type="button" class="close" (click)="successMessage=null"><span>&times;</span></button>
            <strong>{{successMessage | async}}</strong>
          </div>
          <div *ngIf="(errorMessage | async)" class="alert alert-danger alert-dismissible" role="alert">
            <button type="button" class="close" (click)="errorMessage=null"><span>&times;</span></button>
            <strong>{{errorMessage | async}}</strong>
          </div>
        </div>

        <div class="panel page-panel">

          <div class="row panel action-panel">
            <div class="col-xs-12">
              <h4 class="text-center">
                <span [innerHtml]="'views.routing-profile-detail.VehicleRoutingProfile' | translate"></span>
                <span style="font-size:13px;" *ngIf="isInitializing"> <span class="glyphicon glyphicon-refresh spinner"></span>              ({{'common.Loading' | translate}})</span>
              </h4>
            </div>

          </div>

          <div class="row header-panel">
            <label class="col-xs-3" [ngClass]="{'has-error': !isInitializing && !form.controls.name.valid }">
              <span [innerHtml]="'views.routing-profile-detail.ProfileName' | translate"></span>
              <input type="text" class="form-control input-sm" formControlName="name" />
              <div *ngIf="(!isInitializing && !form.controls.name.valid)" class="glyphicon glyphicon-exclamation-sign error-glyph">
                <div class="error-tooltip">
                  <p *ngIf="form.controls.name.errors?.required">{{ 'validation.required' | translate}}</p>
                  <p *ngIf="form.controls.name.errors?.maxlength">{{ 'common.MustBeLessThanChars' | translate: {maxChars: form.controls.name.errors?.maxlength.requiredLength} }}</p>
                  <p *ngIf="form.controls.name.errors?.minlength">{{ 'common.MustBeMoreThanChars' | translate:{minchars: form.controls.name.errors?.minlength.requiredLength} }}</p>
                  <p *ngIf="form.controls.name.errors?.isNameValid">{{ 'common.InvalidRoutingProfileName' | translate }}</p>
                  <p *ngIf="form.controls.name.errors?.isCharAllowed">{{ 'views.routing-profile-detail.errors.NameNotAllowed' | translate }}</p>
                  <p *ngIf="form.controls.name.errors?.multipleSpaces">{{ 'views.routing-profile-detail.errors.MultipleSpacesBetweenWords' | translate }}</p>
                </div>
              </div>
            </label>

            <label class="col-xs-3" [ngClass]="{'has-error': !isInitializing && !isVehicleTypeAllowed }">
            <span>{{'views.routing-profile-detail.VehicleType' | translate}} <i class="glyphicon glyphicon-question-sign" data-container="body" [tooltip]="'views.routing-profile-detail.tooltip.VehicleType' | translate"></i></span>
            <ng-select class="custom" [items]="vehicleTypes" formControlName="vehicleType" bindLabel="text" bindValue="id" (change)="onVehicleTypeChanged($event)" [clearable]="false">
              <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div class="type-item clearfix">
                  <i class="ico icon-large" [ngClass]="item.icon"></i>
                  <div class="type-text vehicle-type-text">{{item.text}}</div>
                </div>
              </ng-template>
            </ng-select>
              <div *ngIf="!isVehicleTypeAllowed" class="glyphicon glyphicon-exclamation-sign error-glyph">
                  <div class="error-tooltip">
                    <p>{{ 'views.routing-profile-detail.tooltip.VehicleTypeNotAllowed' | translate: {type: lastSavedVehicleType} }}</p>
                  </div>
                </div>
            </label>

            <div class="col-xs-4">
              <div class="pull-right">
                <span (click)="isDefaultToggle()">
                  <span *ngIf="!form.value.isAccountWideDefault" class="ico ico-checkbox-unchecked"></span>
                  <span *ngIf="form.value.isAccountWideDefault" class="ico ico-checkbox-checked"></span>
                </span>
                <label>
                  <span [innerHtml]="'views.customdata-profile-detail.form.IsDefault' | translate"></span>
                </label>
              </div>
            </div>
            <div class="clearfix"></div>

          </div>

          <div class="row tab-panel">
            <tabset>
              <tab heading="{{'views.routing-profile-detail.tab.Options' | translate}}">

                <div class="panel accordion-panel">

                  <accordion closeOthers="true" class="accordion">
                    <accordion-group isOpen="true" #generalGroup>
                      <div accordion-heading>
                        <span [innerHtml]="'views.routing-profile-detail.General' | translate"></span>
                        <i class="pull-right glyphicon" [ngClass]="{'glyphicon-triangle-bottom': generalGroup?.isOpen, 'glyphicon-triangle-right': !generalGroup?.isOpen}"></i>
                      </div>
                      <div class="row">
                        <label class="col-md-4 col-sm-4 col-xs-4">
                          <span>{{'views.routing-profile-detail.RoutingType' | translate}} <i class="glyphicon glyphicon-question-sign" data-container="body" [tooltip]="'views.routing-profile-detail.tooltip.RoutingType' | translate"></i></span>
                          <select class="form-control input-sm" formControlName="routingType">
                            <option *ngIf="(region != 'EU' && form.value.vehicleType === 'Auto') || form.value.vehicleType != 'Auto'" value="Practical">{{ 'views.routing-profile-detail.reference.Practical' | translate }}</option>
                            <option *ngIf="region === 'EU' && form.value.vehicleType === 'Auto'"  value="Practical">{{ 'views.routing-profile-detail.reference.Quickest' | translate }}</option>
                            <option value="Shortest">{{ 'views.routing-profile-detail.reference.Shortest' | translate }}</option>
                          </select>
                        </label>
                        <label class="col-md-4 col-sm-4 col-xs-4">
                          <span>{{'views.routing-profile-detail.TollRoads' | translate}} <i class="glyphicon glyphicon-question-sign" data-container="body" [tooltip]="'views.routing-profile-detail.tooltip.TollRoads' | translate"></i></span>
                          <select class="form-control input-sm" formControlName="tollRoadType">
                            <option value="Use">{{ 'views.routing-profile-detail.reference.TollRoadUse' | translate }}</option>
                            <option value="AvoidIfPossible">{{ 'views.routing-profile-detail.reference.TollRoadAvoidIfPossible' | translate }}</option>
                            <option value="AlwaysAvoid">{{ 'views.routing-profile-detail.reference.TollRoadAlwaysAvoid' | translate }}</option>
                          </select>
                        </label>
                        <label class="col-md-4 col-sm-4 col-xs-4">
                            <span>{{'views.routing-profile-detail.Ferries' | translate}} <i class="glyphicon glyphicon-question-sign" data-container="body"  [tooltip]="'views.routing-profile-detail.tooltip.Ferries' | translate"></i></span>
                            <select class="form-control input-sm" formControlName="ferryClosed">
                              <option [ngValue]="false">{{ 'views.routing-profile-detail.reference.Use' | translate }}</option>
                              <option [ngValue]="true">{{ 'views.routing-profile-detail.reference.Avoid' | translate }}</option>
                            </select>
                        </label>
                      </div>
                      <!-- Governor Speed & Elevation Limit -->
                      <div class="row">
                        <label class="col-md-4 col-sm-4 col-xs-4">
                          <span>{{'views.routing-profile-detail.GovernorSpeedLimit' | translate}}
                            <i class="glyphicon glyphicon-question-sign" data-container="body"
                              [tooltip]="'views.routing-profile-detail.tooltip.GovernorSpeedLimit' | translate"></i>
                          </span>
                          <select class="form-control input-sm" formControlName="governorSpeedLimitSelect" (change)="onGovSpeedLimitChanged($event.target.value)" [disableFC]="form.controls.governorSpeedLimitSelect" [disableCond]="!isGovSpeedLimitEditable()" >
                            <option value="disabled">{{ 'views.routing-profile-detail.governorSpeedLimitSelect.Disabled' | translate }}</option>
                            <option value="enabled">{{ 'views.routing-profile-detail.reference.governorSpeedLimitSelect.Enabled' | translate }}</option>
                          </select>
                        </label>
                        <label class="col-md-4 col-sm-4 col-xs-4" *ngIf="form.value.vehicleType != 'Bus'">
                            <span>{{'views.routing-profile-detail.Elevation' | translate}}
                              <i class="glyphicon" data-container="body"></i>
                            </span>
                            <select class="form-control input-sm" formControlName="elevationSelect" (change)="onElevationLimitChanged($event.target.value)">
                              <option value="any">{{ 'views.routing-profile-detail.elevationSelect.Any' | translate }}</option>
                              <option value="discouraged">{{ 'views.routing-profile-detail.elevationSelect.DiscouragedAbove' | translate }}</option>
                            </select>
                        </label>
                      </div>
                      <div class="row">
                        <label class="col-md-2 col-sm-2 col-xs-2" [ngClass]="{'has-error': !form.controls.governorSpeedLimit.valid }">
                            <div class="input-group input-group-sm">
                              <input type="number" class="form-control input-sm" formControlName="governorSpeedLimit" [readonly]="isGovSpeedDisabled()" />
                              <span class="input-group-addon" [innerHtml]="(form.value.unitsOfMeasure === 'English' ? 'views.routing-profile-detail.reference.mph' : 'views.routing-profile-detail.reference.kph') | translate"></span>
                              <i *ngIf="showUnitConversionTooltip" class="glyphicon glyphicon-info-sign" data-container="body" [tooltip]="showGovernorSpeedLimitConversionInfo()"></i>
                            </div>
                            <div *ngIf="(!form.controls.governorSpeedLimit.valid)" class="glyphicon glyphicon-exclamation-sign error-glyph error-glyph-3x"  style = "margin-top:-12px;">
                              <div class="error-tooltip tooltip-left">
                                <p *ngIf="form.controls.governorSpeedLimit.errors?.required">{{ 'validation.required' | translate}}</p>
                                <p *ngIf="form.controls.governorSpeedLimit.errors?.incrementBy">{{ 'common.Increment' | translate: {increment: form.controls.governorSpeedLimit.errors.incrementBy.increments} }}</p>
                                <p *ngIf="form.controls.governorSpeedLimit.errors?.governorSpeedLimit">
                                  {{ 'common.FallsBetween' | translate: {min: form.controls.governorSpeedLimit.errors.governorSpeedLimit.min, max: form.controls.governorSpeedLimit.errors.governorSpeedLimit.max} }}
                                </p>
                              </div>
                            </div>
                          </label>
                          <label class="col-md-2 col-sm-2 col-xs-2"></label>
                        <label class="col-md-2 col-sm-2 col-xs-2" [ngClass]="{'has-error': !form.controls.elevationLimit.valid }" *ngIf="form.value.vehicleType != 'Bus'">
                            <div class="input-group input-group-sm">
                              <input type="number" class="form-control input-sm" formControlName="elevationLimit" [readonly]="isElevationDisabled()" />
                              <span class="input-group-addon" [innerHtml]="(form.value.unitsOfMeasure === 'English' ? 'views.routing-profile-detail.reference.ft' : 'views.routing-profile-detail.reference.m') | translate"></span>
                              <i *ngIf="showUnitConversionTooltip" class="glyphicon glyphicon-info-sign" data-container="body" [tooltip]="showElevationLimitConversionInfo()"></i>
                            </div>
                            <div *ngIf="(!form.controls.elevationLimit.valid)" class="glyphicon glyphicon-exclamation-sign error-glyph error-glyph-3x" style = "margin-top:-12px; margin-left: 20px;">
                              <div class="error-tooltip">
                                <p *ngIf="form.controls.elevationLimit.errors?.required">{{ 'validation.required' | translate}}</p>
                                <p *ngIf="form.controls.elevationLimit.errors?.incrementBy">{{ 'common.Increment' | translate: {increment: form.controls.elevationLimit.errors.incrementBy.increments} }}</p>
                                <p *ngIf="form.controls.elevationLimit.errors?.elevation">
                                  {{ 'common.FallsBetween' | translate: {min: form.controls.elevationLimit.errors.elevation.min, max: form.controls.elevationLimit.errors.elevation.max} }}
                                </p>
                              </div>
                            </div>
                          </label>
                      </div>
                      <!-- End -->
                      <div class="row">
                        <label class="col-md-4 col-sm-4 col-xs-4"  *ngIf="region !== 'EU'">
                          <span>{{'views.routing-profile-detail.InternationalBorders' | translate}} <i class="glyphicon glyphicon-question-sign" data-container="body" [tooltip]="'views.routing-profile-detail.tooltip.InternationalBorders' | translate"></i></span>
                          <select class="form-control input-sm" formControlName="bordersOpen">
                            <option [ngValue]="true">{{ 'views.routing-profile-detail.reference.Open' | translate }}</option>
                            <option [ngValue]="false">{{ 'views.routing-profile-detail.reference.Closed' | translate }}</option>
                          </select>
                        </label>
                        <!-- Active Routes-->
                        <label class="col-md-4 col-sm-4 col-xs-4"  *ngIf="enableActiveRoutes">
                          <span>{{'views.routing-profile-detail.ActiveRoutes' | translate}} <i class="glyphicon glyphicon-question-sign" data-container="body" [tooltip]="'views.routing-profile-detail.tooltip.ActiveRoutes' | translate"></i></span>
                          <select class="form-control input-sm" formControlName="activeRoutes" [disableFC]="form.controls.activeRoutes" [disableCond]="!isActiveRoutesEditable()">
                            <option value ="Disabled">{{ 'views.routing-profile-detail.activeRoutes.disabled' | translate }}</option>
                            <option value ="Enabled">{{ 'views.routing-profile-detail.activeRoutes.enabled' | translate }}</option>
                          </select>
                        </label>
                      </div>
                    </accordion-group>
                    <accordion-group #restrictionGroup>
                      <div accordion-heading>
                        <span [innerHtml]="'views.routing-profile-detail.Restrictions' | translate"></span>
                        <i class="pull-right glyphicon" [ngClass]="{'glyphicon-triangle-bottom': restrictionGroup?.isOpen, 'glyphicon-triangle-right': !restrictionGroup?.isOpen}"></i>
                      </div>
                      <div class="row">
                        <label class="col-md-4 col-sm-4 col-xs-4" *ngIf="form.value.vehicleType != 'Bus'">
                          <span>{{'views.routing-profile-detail.Hazmat' | translate}} <i class="glyphicon glyphicon-question-sign" data-container="body" [tooltip]="'views.routing-profile-detail.tooltip.Hazmat' | translate"></i></span>
                          <select class="form-control input-sm" formControlName="hazmatType" [disableFC]="form.controls.hazmatType" [disableCond]="!isHazmatEditable()">
                            <option value="None">{{ 'views.routing-profile-detail.reference.None' | translate }}</option>
                            <option value="General">{{ 'views.routing-profile-detail.reference.General' | translate }}</option>
                            <option value="Explosives">{{ 'views.routing-profile-detail.reference.Explosives' | translate }}</option>
                            <option *ngIf="region === 'NA'" value="Inhalants">{{ 'views.routing-profile-detail.reference.Inhalants' | translate }}</option>
                            <option *ngIf="region === 'NA'" value="Radioactive">{{ 'views.routing-profile-detail.reference.Radioactive' | translate }}</option>
                            <option *ngIf="region === 'NA'" value="Caustic">{{ 'views.routing-profile-detail.reference.Caustic' | translate }}</option>
                            <option value="Flammable">{{ 'views.routing-profile-detail.reference.Flammable' | translate }}</option>
                            <option *ngIf="region === 'EU'" value="HarmfulToWater">{{ 'views.routing-profile-detail.reference.HarmfulToWater' | translate }}</option>
                          </select>
                        </label>
                        <label class="col-md-4 col-sm-4 col-xs-4">
                          <span>{{'views.routing-profile-detail.DisplayRestrictions' | translate}} <i class="glyphicon glyphicon-question-sign" data-container="body" [tooltip]="'views.routing-profile-detail.tooltip.DisplayRestrictions' | translate"></i></span>
                          <select class="form-control input-sm" formControlName="displayRestrictions" [disableFC]="form.controls.displayRestrictions" [disableCond]="!isDisplayRestrictionsEnabled()">
                            <option value="AlwaysOn">{{ 'views.routing-profile-detail.reference.AlwaysOn' | translate }}</option>
                            <option value="BasedOnTruckRestrictions">{{ 'views.routing-profile-detail.reference.BasedOnTruckRestrictions' | translate }}</option>
                            <option value="AlwaysOff">{{ 'views.routing-profile-detail.reference.AlwaysOff' | translate }}</option>
                          </select>
                        </label>
                        <label *ngIf="region === 'EU'" class="col-md-4 col-sm-4 col-xs-4">
                            <span>{{'views.routing-profile-detail.TunnelRestriction' | translate}} <i class="glyphicon glyphicon-question-sign" data-container="body" [tooltip]="'views.routing-profile-detail.tooltip.TunnelRestriction' | translate"></i></span>
                            <select class="form-control input-sm" formControlName="tunnelRestriction" [disableFC]="form.controls.tunnelRestriction" [disableCond]="!isTunnelRestrictionsEnabled()">
                              <option value="None">{{ 'views.routing-profile-detail.reference.None' | translate }}</option>
                              <option value="EUTunnelE">{{ 'views.routing-profile-detail.reference.EUTunnelE' | translate }}</option>
                              <option value="EUTunnelDE">{{ 'views.routing-profile-detail.reference.EUTunnelDE' | translate }}</option>
                              <option value="EUTunnelCDE">{{ 'views.routing-profile-detail.reference.EUTunnelCDE' | translate }}</option>
                              <option value="EUTunnelBCDE">{{ 'views.routing-profile-detail.reference.EUTunnelBCDE' | translate }}</option>
                            </select>
                          </label>

                      </div>
                      <div class="row">
                        <label class="col-md-4 col-sm-4 col-xs-4" *ngIf="isNotEUOrBus()">
                          <span>{{'views.routing-profile-detail.PropaneRestrictions' | translate}} <i class="glyphicon glyphicon-question-sign" data-container="body" [tooltip]="'views.routing-profile-detail.tooltip.PropaneRestrictions' | translate"></i></span>
                          <select class="form-control input-sm" formControlName="propane" [disableFC]="form.controls.propane" [disableCond]="!isPropaneEditable()">
                            <option [ngValue]="true">{{ 'views.routing-profile-detail.reference.Avoid' | translate }}</option>
                            <option [ngValue]="false">{{ 'views.routing-profile-detail.reference.Use' | translate }}</option>
                          </select>
                        </label>
                        <label *ngIf="region === 'EU'" class="col-md-4 col-sm-4 col-xs-4">
                          <span>{{'views.routing-profile-detail.CongestionZones' | translate}} <i class="glyphicon glyphicon-question-sign" data-container="body" [tooltip]="'views.routing-profile-detail.tooltip.CongestionZones' | translate"></i></span>
                          <select class="form-control input-sm" formControlName="congestionZone">
                            <option value="0">{{ 'views.routing-profile-detail.Avoid' | translate }}</option>
                            <option value="1">{{ 'views.routing-profile-detail.Allow' | translate }}</option>
                            <option value="2">{{ 'views.routing-profile-detail.WarnWhenDriving' | translate }}</option>
                          </select>
                        </label>
                        <label *ngIf="region === 'EU'" class="col-md-4 col-sm-4 col-xs-4">
                          <span>{{'views.routing-profile-detail.EnvironmentalZones' | translate}} <i class="glyphicon glyphicon-question-sign" data-container="body" [tooltip]="'views.routing-profile-detail.tooltip.EnvironmentalZones' | translate"></i></span>
                          <select class="form-control input-sm" formControlName="environmentalZone">
                            <option value="0">{{ 'views.routing-profile-detail.Avoid' | translate }}</option>
                            <option value="1">{{ 'views.routing-profile-detail.Allow' | translate }}</option>
                            <option value="2">{{ 'views.routing-profile-detail.WarnWhenDriving' | translate }}</option>
                          </select>
                        </label>
                      </div>
                    </accordion-group>
                    <accordion-group *ngIf="form.value.vehicleType != 'Auto'" #dimensionsGroup>
                      <div accordion-heading>
                        <span [innerHtml]="'views.routing-profile-detail.VehicleDimensions' | translate"></span>
                        <i *ngIf="!isInitializing && isVehicleDimensionWarningNeeded()" class="glyphicon glyphicon-exclamation-sign error-glyph"></i>
                        <i class="pull-right glyphicon" [ngClass]="{'glyphicon-triangle-bottom': dimensionsGroup?.isOpen, 'glyphicon-triangle-right': !dimensionsGroup?.isOpen}"></i>
                      </div>
                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-6">
                          <span>{{'views.routing-profile-detail.TruckDimensions' | translate}} <i class="glyphicon glyphicon-question-sign" data-container="body" [tooltip]="'views.routing-profile-detail.tooltip.TruckDimensions' | translate"></i></span>
                          <select class="form-control input-sm" formControlName="truckDimensions" [disableFC]="form.controls.truckDimensions" [disableCond]="!isTruckPresetDimensionsEditable()" (change)="onTruckDimensionsChanged($event.target.value)">
                            <option value="NoRestriction">{{ 'views.routing-profile-detail.reference.NoRestriction' | translate }}</option>
                            <option *ngIf="region === 'NA'" value="SemiTrailer48">{{ 'views.routing-profile-detail.reference.SemiTrailer48' | translate }}</option>
                            <option *ngIf="region === 'NA'" value="TrailerOrTwins53">{{ 'views.routing-profile-detail.reference.Trailer53' | translate }}</option>
                            <option *ngIf="region === 'NA'" value="DoubleTrailers28">{{ 'views.routing-profile-detail.reference.Double28' | translate }}</option>
                            <option *ngIf="region === 'NA'" value="StraightTruck40">{{ 'views.routing-profile-detail.reference.Straight40' | translate }}</option>
                            <option *ngIf="region === 'EU' && form.value.unitsOfMeasure === 'Metric'" value="EU16Meter">{{ 'views.routing-profile-detail.reference.EU16Meter' | translate }}</option>
                            <option *ngIf="region === 'EU' && form.value.unitsOfMeasure === 'Metric'" value="EU18Meter">{{ 'views.routing-profile-detail.reference.EU18Meter' | translate }}</option>
                            <option *ngIf="region === 'EU' && form.value.unitsOfMeasure === 'Metric'" value="EU12Meter">{{ 'views.routing-profile-detail.reference.EU12Meter' | translate }}</option>
                            <option *ngIf="region === 'EU' && form.value.unitsOfMeasure === 'English'" value="EU54Foot">{{ 'views.routing-profile-detail.reference.EU54Foot' | translate }}</option>
                            <option *ngIf="region === 'EU' && form.value.unitsOfMeasure === 'English'" value="EU61Foot">{{ 'views.routing-profile-detail.reference.EU61Foot' | translate }}</option>
                            <option *ngIf="region === 'EU' && form.value.unitsOfMeasure === 'English'" value="EU40Foot">{{ 'views.routing-profile-detail.reference.EU40Foot' | translate }}</option>
                            <option value="Custom">{{ 'views.routing-profile-detail.reference.Custom' | translate }}</option>
                          </select>
                          <span *ngIf="form.value.truckDimensions === 'DoubleTrailers28'" [innerHtml]="'views.routing-profile-detail.warning.28FootSelected' | translate"></span>
                        </label>
                        <label class="col-md-3 col-sm-3 col-xs-3" [ngClass]="{'has-error': !isInitializing && (form.errors?.maxHeight) }">
                          <span>{{'views.routing-profile-detail.MaxHeight' | translate}} <i class="glyphicon glyphicon-question-sign" data-container="body" [tooltip]="'views.routing-profile-detail.tooltip.MaxHeight' | translate"></i></span>
                          <div class="input-group input-group-sm">
                            <input type="number" class="form-control" formControlName="maxHeightFeet" [readonly]="!isDimensionsEditable()" />
                            <span class="input-group-addon" [innerHtml]="(form.value.unitsOfMeasure === 'English' ? 'views.routing-profile-detail.reference.ft' : 'views.routing-profile-detail.reference.m') | translate"></span>
                          </div>
                          <div *ngIf="!isInitializing && (form.errors?.maxHeight)" class="glyphicon glyphicon-exclamation-sign error-glyph error-glyph-3x">
                            <div class="error-tooltip">
                              <p *ngIf="form.errors?.maxHeight">
                                {{ 'common.FallsBetween' | translate: {min: form.errors.maxHeight.min, max: form.errors.maxHeight.max} }}
                              </p>
                            </div>
                          </div>
                        </label>
                        <label class="col-md-3 col-sm-3 col-xs-3" [ngClass]="{'has-error': !isInitializing && (!form.controls.maxHeightInches.valid) }">
                          <span>&nbsp;</span>
                          <div class="input-group input-group-sm">
                            <input type="number" class="form-control" formControlName="maxHeightInches" [readonly]="!isDimensionsEditable()" />
                            <span class="input-group-addon" [innerHtml]="(form.value.unitsOfMeasure === 'English' ? 'views.routing-profile-detail.reference.in' : 'views.routing-profile-detail.reference.cm') | translate"></span>
                            <i *ngIf="showUnitConversionTooltip" class="glyphicon glyphicon-info-sign" data-container="body" [tooltip]="showMaxHeightConversionInfo()"></i>
                          </div>
                          <div *ngIf="!isInitializing && (!form.controls.maxHeightInches.valid)" class="glyphicon glyphicon-exclamation-sign error-glyph error-glyph-3x">
                            <div class="error-tooltip">
                              <p *ngIf="form.controls.maxHeightInches.errors?.inches">
                                {{ 'common.FallsBetween' | translate: {min: form.controls.maxHeightInches.errors.inches.min, max: form.controls.maxHeightInches.errors.inches.max} }}
                              </p>
                            </div>
                          </div>
                        </label>
                      </div>

                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-6" [ngClass]="{'has-error': !form.controls.maxWidth.valid }">
                          <span>{{'views.routing-profile-detail.MaxWidth' | translate}} <i class="glyphicon glyphicon-question-sign" data-container="body" [tooltip]="'views.routing-profile-detail.tooltip.MaxWidth' | translate"></i></span>
                          <div class="input-group input-group-sm">
                            <input type="number" class="form-control input-sm" formControlName="maxWidth" [readonly]="!isDimensionsEditable()" />
                            <span class="input-group-addon" [innerHtml]="(form.value.unitsOfMeasure === 'English' ? 'views.routing-profile-detail.reference.in' : 'views.routing-profile-detail.reference.m') | translate"></span>
                            <i *ngIf="showUnitConversionTooltip" class="glyphicon glyphicon-info-sign" data-container="body" [tooltip]="showMaxWidthConversionInfo()"></i>
                          </div>
                          <div *ngIf="(!form.controls.maxWidth.valid)" class="glyphicon glyphicon-exclamation-sign error-glyph error-glyph-3x">
                            <div class="error-tooltip">
                              <p *ngIf="form.controls.maxWidth.errors?.required">{{ 'validation.required' | translate}}</p>
                              <p *ngIf="form.controls.maxWidth.errors?.maxWidth">
                                {{ 'common.FallsBetween' | translate: {min: form.controls.maxWidth.errors.maxWidth.min, max: form.controls.maxWidth.errors.maxWidth.max} }}
                              </p>
                            </div>
                          </div>
                        </label>
                        <label class="col-md-3 col-sm-3 col-xs-3" [ngClass]="{'has-error': !isInitializing && (!form.controls.totalLengthFeet.valid || form.errors?.totalLength) }">
                          <span>{{'views.routing-profile-detail.TotalLength' | translate}} <i class="glyphicon glyphicon-question-sign" data-container="body" [tooltip]="'views.routing-profile-detail.tooltip.TotalLength' | translate"></i></span>
                          <div class="input-group input-group-sm">
                            <input type="number" class="form-control input-sm" formControlName="totalLengthFeet" [readonly]="!isDimensionsEditable()" />
                            <span class="input-group-addon" [innerHtml]="(form.value.unitsOfMeasure === 'English' ? 'views.routing-profile-detail.reference.ft' : 'views.routing-profile-detail.reference.m') | translate"></span>
                          </div>
                          <div *ngIf="!isInitializing && (!form.controls.totalLengthFeet.valid || form.errors?.totalLength)" class="glyphicon glyphicon-exclamation-sign error-glyph error-glyph-3x">
                            <div class="error-tooltip">
                              <p *ngIf="form.controls.totalLength.errors?.required">{{ 'validation.required' | translate}}</p>
                              <p *ngIf="form.errors?.totalLength">
                                {{ 'common.FallsBetween' | translate: {min: form.errors.totalLength.min, max: form.errors.totalLength.max} }}
                              </p>
                            </div>
                          </div>
                        </label>
                        <label class="col-md-3 col-sm-3 col-xs-3" [ngClass]="{'has-error': !isInitializing && (!form.controls.totalLengthInches.valid) }">
                          <span>&nbsp;</span>
                          <div class="input-group input-group-sm">
                            <input type="number" class="form-control input-sm" formControlName="totalLengthInches" [readonly]="!isDimensionsEditable()" />
                            <span class="input-group-addon" [innerHtml]="(form.value.unitsOfMeasure === 'English' ? 'views.routing-profile-detail.reference.in' : 'views.routing-profile-detail.reference.cm') | translate"></span>
                            <i *ngIf="showUnitConversionTooltip" class="glyphicon glyphicon-info-sign" data-container="body" [tooltip]="showMaxLengthConversionInfo()"></i>
                          </div>
                          <div *ngIf="!isInitializing && (!form.controls.totalLengthInches.valid)" class="glyphicon glyphicon-exclamation-sign error-glyph error-glyph-3x">
                            <div class="error-tooltip">
                              <p *ngIf="form.controls.totalLengthInches.errors?.inches">
                                {{ 'common.FallsBetween' | translate: {min: form.controls.totalLengthInches.errors.inches.min, max: form.controls.totalLengthInches.errors.inches.max} }}
                              </p>
                            </div>
                          </div>
                        </label>
                      </div>

                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-6" [ngClass]="{'has-error': !isInitializing && (!form.controls.totalWeight.valid || form.errors?.totalWeight) }">
                          <span>{{'views.routing-profile-detail.TotalWeight' | translate}} <i class="glyphicon glyphicon-question-sign" data-container="body" [tooltip]="'views.routing-profile-detail.tooltip.TotalWeight' | translate"></i></span>
                          <div class="input-group input-group-sm">
                            <input type="number" class="form-control input-sm" formControlName="totalWeight" [readonly]="!isDimensionsEditable()" />
                            <span class="input-group-addon" [innerHtml]="(region === 'NA' ? 'views.routing-profile-detail.reference.lbs': 'views.routing-profile-detail.reference.t') | translate"></span>
                            <i *ngIf="showUnitConversionTooltip" class="glyphicon glyphicon-info-sign" data-container="body" [tooltip]="showTotalWeightConversionInfo()"></i>
                          </div>
                          <div *ngIf="!isInitializing && (!form.controls.totalWeight.valid || form.errors?.totalWeight)" class="glyphicon glyphicon-exclamation-sign error-glyph error-glyph-3x">
                            <div class="error-tooltip tooltip-top">
                              <p *ngIf="form.controls.totalWeight.errors?.required">{{ 'validation.required' | translate}}</p>
                              <p *ngIf="form.controls.totalWeight.errors?.totalWeight">
                                {{ 'common.FallsBetween' | translate: {min: form.controls.totalWeight.errors.totalWeight.min, max: form.controls.totalWeight.errors.totalWeight.max} }}
                              </p>
                            </div>
                          </div>
                        </label>
                        <label class="col-md-6 col-sm-6 col-xs-6" [ngClass]="{'has-error': !isInitializing && (!form.controls.totalWeightPerAxle.valid) }">
                          <span>{{'views.routing-profile-detail.TotalWeightPerAxle' | translate}} <i class="glyphicon glyphicon-question-sign" data-container="body" [tooltip]="'views.routing-profile-detail.tooltip.TotalWeightPerAxle' | translate:{example: (region === 'NA' ? '34,000 lbs.': '7.26 t.')}"></i></span>
                          <div class="input-group input-group-sm">
                            <input type="number" class="form-control input-sm" formControlName="totalWeightPerAxle" [readonly]="!isDimensionsEditable()" />
                            <span class="input-group-addon" [innerHtml]="(region === 'NA' ? 'views.routing-profile-detail.reference.lbs': 'views.routing-profile-detail.reference.t') | translate"></span>
                            <i *ngIf="showUnitConversionTooltip" class="glyphicon glyphicon-info-sign" data-container="body" [tooltip]="showTotalWeightPerAxleConversionInfo()"></i>
                          </div>
                          <div *ngIf="!isInitializing && (!form.controls.totalWeightPerAxle.valid)" class="glyphicon glyphicon-exclamation-sign error-glyph error-glyph-3x">
                            <div class="error-tooltip tooltip-top">
                              <p *ngIf="form.controls.totalWeightPerAxle.errors?.required">{{ 'validation.required' | translate}}</p>
                              <p *ngIf="form.controls.totalWeightPerAxle.errors?.totalWeightPerAxle">
                                {{ 'common.FallsBetween' | translate: {min: form.controls.totalWeightPerAxle.errors.totalWeightPerAxle.min, max: form.controls.totalWeightPerAxle.errors.totalWeightPerAxle.max} }}
                              </p>
                            </div>
                          </div>
                        </label>
                      </div>
                      <div class="row">
                        <div class="col-xs-6">
                          <label *ngIf="isNotEUOrBus()" class="national-network-warning-label">
                            <span>{{'views.routing-profile-detail.RoadNetwork' | translate}} <i class="glyphicon glyphicon-question-sign" data-container="body" [tooltip]="'views.routing-profile-detail.tooltip.RoadNetwork' | translate"></i></span>
                            <select class="form-control input-sm ui-select-container" formControlName="nationalNetwork" [disableFC]="form.controls.nationalNetwork" [disableCond]="!isRoadNetworkEnabled()" (change)="onRoadNetworkChanged($event.target.value)">
                              <option [ngValue]="false">{{ 'views.routing-profile-detail.reference.None' | translate }}</option>
                              <option [ngValue]="true">{{ 'views.routing-profile-detail.reference.StateNational' | translate }}</option>
                            </select>
                          </label>
                        </div>
                        <div *ngIf="isNetworkWarningValid()" class="col-xs-6 national-network-warning-container">
                          <span style="float:left;">
                            <i class="glyphicon glyphicon-exclamation-sign error-glyph national-network-warning-icon"></i>
                          </span>
                          <p class="national-network-warning-text">
                            {{ 'views.routing-profile-detail.NationalNetworkWarning' | translate }}
                          </p>
                        </div>
                      </div>
                    </accordion-group>

                    <accordion-group *ngIf="useRoadPreferences" #roadPreferencesGroup>
                      <div accordion-heading>
                        <span>{{'views.routing-profile-detail.RoadPreferences' | translate}} <i class="glyphicon glyphicon-question-sign" data-container="body" [tooltip]="'views.routing-profile-detail.tooltip.RoadPreferences' | translate"></i></span>
                        <i class="pull-right glyphicon" [ngClass]="{'glyphicon-triangle-bottom': roadPreferencesGroup?.isOpen, 'glyphicon-triangle-right': !roadPreferencesGroup?.isOpen}"></i>
                      </div>

                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-6">
                          <span [innerHtml]="'views.routing-profile-detail.FreewayAFType' | translate"></span>
                          <select class="form-control input-sm" formControlName="roadPreferencesFreewaysAFType">
                            <option value="StronglyFavor">{{ 'views.routing-profile-detail.reference.StronglyFavor' | translate }}</option>
                            <option value="Favor">{{ 'views.routing-profile-detail.reference.Favor' | translate }}</option>
                            <option value="Neutral">{{ 'views.routing-profile-detail.reference.Neutral' | translate }}</option>
                            <option value="Avoid">{{ 'views.routing-profile-detail.reference.Avoid' | translate }}</option>
                            <option value="StronglyAvoid">{{ 'views.routing-profile-detail.reference.StronglyAvoid' | translate }}</option>
                          </select>
                        </label>
                      </div>

                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-6">
                          <span [innerHtml]="'views.routing-profile-detail.DividedHighwayAFType' | translate"></span>
                          <select class="form-control input-sm" formControlName="roadPreferencesDividedHighwaysAFType">
                            <option value="StronglyFavor">{{ 'views.routing-profile-detail.reference.StronglyFavor' | translate }}</option>
                            <option value="Favor">{{ 'views.routing-profile-detail.reference.Favor' | translate }}</option>
                            <option value="Neutral">{{ 'views.routing-profile-detail.reference.Neutral' | translate }}</option>
                            <option value="Avoid">{{ 'views.routing-profile-detail.reference.Avoid' | translate }}</option>
                            <option value="StronglyAvoid">{{ 'views.routing-profile-detail.reference.StronglyAvoid' | translate }}</option>
                          </select>
                        </label>
                      </div>

                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-6">
                          <span [innerHtml]="'views.routing-profile-detail.PrimaryRoadsAFType' | translate"></span>
                          <select class="form-control input-sm" formControlName="roadPreferencesPrimaryRoadsAFType">
                            <option value="StronglyFavor">{{ 'views.routing-profile-detail.reference.StronglyFavor' | translate }}</option>
                            <option value="Favor">{{ 'views.routing-profile-detail.reference.Favor' | translate }}</option>
                            <option value="Neutral">{{ 'views.routing-profile-detail.reference.Neutral' | translate }}</option>
                            <option value="Avoid">{{ 'views.routing-profile-detail.reference.Avoid' | translate }}</option>
                            <option value="StronglyAvoid">{{ 'views.routing-profile-detail.reference.StronglyAvoid' | translate }}</option>
                          </select>
                        </label>
                      </div>

                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-6">
                          <span [innerHtml]="'views.routing-profile-detail.SecondaryRoadsAFType' | translate"></span>
                          <select class="form-control input-sm" formControlName="roadPreferencesSecondaryRoadsAFType">
                            <option value="StronglyFavor">{{ 'views.routing-profile-detail.reference.StronglyFavor' | translate }}</option>
                            <option value="Favor">{{ 'views.routing-profile-detail.reference.Favor' | translate }}</option>
                            <option value="Neutral">{{ 'views.routing-profile-detail.reference.Neutral' | translate }}</option>
                            <option value="Avoid">{{ 'views.routing-profile-detail.reference.Avoid' | translate }}</option>
                            <option value="StronglyAvoid">{{ 'views.routing-profile-detail.reference.StronglyAvoid' | translate }}</option>
                          </select>
                        </label>
                      </div>

                      <div class="row">
                        <label class="col-md-6 col-sm-6 col-xs-6">
                          <span [innerHtml]="'views.routing-profile-detail.LocalStreetsAFType' | translate"></span>
                          <select class="form-control input-sm" formControlName="roadPreferencesLocalStreetsAFType">
                            <option value="StronglyFavor">{{ 'views.routing-profile-detail.reference.StronglyFavor' | translate }}</option>
                            <option value="Favor">{{ 'views.routing-profile-detail.reference.Favor' | translate }}</option>
                            <option value="Neutral">{{ 'views.routing-profile-detail.reference.Neutral' | translate }}</option>
                            <option value="Avoid">{{ 'views.routing-profile-detail.reference.Avoid' | translate }}</option>
                            <option value="StronglyAvoid">{{ 'views.routing-profile-detail.reference.StronglyAvoid' | translate }}</option>
                          </select>
                        </label>
                      </div>
                    </accordion-group>
                  </accordion>
                </div>
              </tab>
              <tab heading="{{'views.routing-profile-detail.tab.Assignments' | translate}}">

                <group-associations #vehicleAssociations entityLabel="Vehicle groups" [displayPerPage]="10">
                </group-associations>
              </tab>
            </tabset>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default cancel-btn" (click)="cancel()">
                <span [innerHtml]="'common.Cancel' | translate"></span>
              </button>
            <button type="submit" class="btn btn-success" [disabled]="!form.valid || !isVehicleTypeAllowed">
                  <i *ngIf="!isInitializing && !form.valid" class="glyphicon glyphicon-exclamation-sign error-glyph"></i>
                  <span [innerHtml]="'common.Save' | translate"></span>
                </button>

          </div>
        </div>
      </div>
    </form>
  </fieldset>

  <confirmation-modal #setDefaultConfirmModal title="{{ 'views.routing-profile-list.SetDefaultTitle' | translate }}" body="{{ 'views.routing-profile-list.ConfirmDefaultBody' | translate }}"></confirmation-modal>
  <confirmation-modal #confirmModal title="{{ 'views.unsaved-changes-modal.title' | translate}}" body="{{ 'views.unsaved-changes-modal.body' | translate}}"></confirmation-modal>
