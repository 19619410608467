<div bsModal #modal="bs-modal" [config]="{ keyboard: false, backdrop: 'static' }" class="modal fade" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="modal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">{{ addMode ? ('common.New' | translate) : ('common.Edit' | translate) }} Driver</h4>
      </div>
      <form [formGroup]="form" (ngSubmit)="save()" novalidate>
        <div class="modal-body">
          <div class="row">
              <div>
                <label class="col-md-6" [ngClass]="{'has-error': !form.controls.driverId.valid }">
                  <span>Driver Id</span>
                  <input formControlName="driverId" type="text" class="form-control input-sm" [disabled]="isProcessing || !addMode" autocomplete="off" />
                  <div *ngIf="(!form.controls.driverId.valid)" class="glyphicon glyphicon-exclamation-sign error-glyph">
                    <div class="error-tooltip">
                      <p *ngIf="form.controls.driverId.errors?.required">{{ 'common.FieldIsRequired' | translate }}</p>
                      <p *ngIf="form.controls.driverId.errors?.maxlength">{{ 'common.MustBeLessThanChars' | translate: {maxChars: form.controls.driverId.errors?.maxlength.requiredLength} }}</p>
                      <p *ngIf="form.controls.driverId.errors?.minlength">{{ 'common.MustBeMoreThanChars' | translate:{minchars: form.controls.driverId.errors?.minlength.requiredLength} }}</p>
                    </div>
                  </div>
                </label>
              </div>
              <div>
                <label class="col-md-6" [ngClass]="{'has-error': !form.controls.name.valid }">
                  <span>Driver Name</span>
                  <input formControlName="name" type="text" class="form-control input-sm" [disabled]="isProcessing" autocomplete="off" />
                  <div *ngIf="(!form.controls.name.valid)" class="glyphicon glyphicon-exclamation-sign error-glyph">
                    <div class="error-tooltip">
                      <p *ngIf="form.controls.name.errors?.required">{{ 'common.FieldIsRequired' | translate }}</p>
                      <p *ngIf="form.controls.name.errors?.maxlength">{{ 'common.MustBeLessThanChars' | translate: {maxChars: form.controls.name.errors?.maxlength.requiredLength} }}</p>
                      <p *ngIf="form.controls.name.errors?.minlength">{{ 'common.MustBeMoreThanChars' | translate:{minchars: form.controls.name.errors?.minlength.requiredLength} }}</p>
                    </div>
                  </div>
                </label>
              </div>
          </div>

          <div class="row" style="margin-top: 15px;">
            <div class="col-md-12">
              <div *ngIf="errorMsg" class="alert alert-danger" role="alert">
                <label [innerHtml]="'common.Error' | translate"></label>
                <span>{{errorMsg}}</span>
              </div>
            </div>
          </div>

        </div>
        <div class="clearfix"></div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default cancel-btn" [disabled]="isProcessing" (click)="modal.hide()">{{ 'common.Cancel' | translate }}</button>
          <button type="submit" class="btn btn-default" [disabled]="!form.valid || isProcessing">{{ addMode ? ('common.Save' | translate) : ('common.Update' | translate) }}</button>
        </div>
      </form>
    </div>
  </div>
</div>
