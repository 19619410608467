
import { throwError as observableThrowError, of as observableOf, Observable, of, defer } from 'rxjs';

import { catchError, map, flatMap } from 'rxjs/operators';
// angular components
import { Injectable } from '@angular/core';

// alk app components
import { PagingService } from './../../shared';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Mapset } from './mapset.model';
import { PanRegion } from '../mapsets-detail/map-regions/map-region.model';
import { stringify } from 'querystring';
import { CustomMapset } from './custom-mapset.model';
import { VehicleGroup } from './vehicle-group.model';
import { AuthenticationService } from './../../shared/authentication';

@Injectable()
export class MapsetsService {

  constructor(
    private http: HttpClient,
    private paging: PagingService,
    private authService: AuthenticationService
    ) { }
  fleetApi = environment.fleetApiUrl + 'api/assets/v1';

  public getMapsets(query?: string, paging?: any) {
    const params = [];
    if (paging) {
      params.push(this.paging.getPagingQuery(paging));
    }

    if (query && query.trim().length) {
      params.push('query=' + encodeURIComponent(query));
    }

    const url = `${this.fleetApi}/mapsets?` + params.join('&');

    return this.authService.getFleetsApiToken()
    .pipe(
      flatMap(resp => {
        const token = resp;
        return this.http.get<any>(url, {headers: { Authorization: `Bearer ${token}`}});
      })
    );
  }

  public deleteMapset(
    mapsetId: number
  ) {
    return this.authService.getFleetsApiToken().
    pipe(
      flatMap(resp => {
        return this.http.delete(`${this.fleetApi}/mapsets/${mapsetId}`, {headers: { Authorization: `Bearer ${resp}`}});
      })
    );
  }

  public setDefaultMapset(
    mapset: any
  ) {
    return this.updateMapset(mapset);
  }

  public getConnectionSettings() {
    return this.authService.getFleetsApiToken()
    .pipe(
      flatMap(resp => {
        return this.http.get<any>(`${this.fleetApi}/company/connectionSettings`, {headers: { Authorization: `Bearer ${resp}`}});
      })
    );
  }

  public updateConnectionSettings(blackListedWifiNames, isWifiOnly) {
    const body = { isWifiOnly, blackListedWifiNames };
    return this.authService.getFleetsApiToken().
    pipe(
      flatMap(resp => {
        return this.http.post<any>(`${this.fleetApi}/company/connectionSettings`, body, { headers: { Authorization: `Bearer ${resp}`} });
      })
    );
  }

  assignVehicleGroupsToMapset(id: number, vehicleGroups: number[]){
    const url = `${this.fleetApi}/mapsets/${id}/vehicleGroups/assignment`;
    const body = JSON.stringify(vehicleGroups);

    return this.authService.getFleetsApiToken()
      .pipe(
        flatMap((res: any) => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: `Bearer ${res}`
            })
          };
          return this.http.put(url, body, httpOptions);
        })
      );
  }

  public createMapset(mapset: CustomMapset) {
    return this.authService.getFleetsApiToken()
      .pipe(
        flatMap((res: any) => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: `Bearer ${res}`
            })
          };
          return this.http.post(`${this.fleetApi}/mapsets`, mapset, httpOptions);
        })
      );
  }

  public updateMapset(mapset: CustomMapset) {
    return this.authService.getFleetsApiToken()
      .pipe(
        flatMap((res: any) => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: `Bearer ${res}`
            })
          };
          return this.http.put(`${this.fleetApi}/mapsets/${mapset.customMapsetId}`, mapset, httpOptions);
        })
      );
  }

  public getMapsetById(id: number) {
    return this.authService.getFleetsApiToken()
      .pipe(
        flatMap((res: any) => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: `Bearer ${res}`
            })
          };
          return this.http.get(`${this.fleetApi}/mapsets/${id}`, httpOptions);
        })
      );
  }

  public getMapRegions() {
    return this.authService.getFleetsApiToken()
      .pipe(
        flatMap((res: any) => {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: `Bearer ${res}`
            })
          };
          return this.http.get(`${this.fleetApi}/mapsets/availableRegions`, httpOptions) as Observable<PanRegion>;
        })
      );
  }

  public getVehicleGroupMapsets(id: number) {
    return this.authService.getFleetsApiToken()
    .pipe(
      flatMap(resp => {
        return this.http.get(`${this.fleetApi}/vehicleGroups/${id}/mapsets`, {headers: { Authorization: `Bearer ${resp}`}});
      })
    );
  }

  public updateMapsetsForVehicleGroup(mapsetsAdded, mapsetsRemoved, vehicleGroupId): Observable<any> {
    const add = mapsetsAdded.map(mapset => mapset.id);
    const remove = mapsetsRemoved.map(mapset => mapset.id);
    return this.authService.getFleetsApiToken()
    .pipe(
      flatMap(resp => {
        return this.http.put(`${this.fleetApi}/vehicleGroups/${vehicleGroupId}/mapsets`,
          { add, remove },
          {headers: { Authorization: `Bearer ${resp}`}});
      })
    );
  }
}
