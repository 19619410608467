import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { clone, isEqual } from 'lodash';
import { CompanySettingsService } from '../../../admin/company-settings/company-settings.service';
import { MapsetsService } from '../../shared/mapsets.service';
import { ResponseRegion } from '../../shared/region.enum';
var MapRegionsComponent = /** @class */ (function () {
    function MapRegionsComponent(mapsetsService, companySettingsService) {
        this.mapsetsService = mapsetsService;
        this.companySettingsService = companySettingsService;
        this.MAX_SELECTED_REGIONS = 5;
        this.OTHER_REGIONS = [
            'Alaska',
            'Hawaii',
            'Puerto Rico',
            'US Virgin Islands',
        ];
        this.otherRegionArray = [];
        this.na = ResponseRegion.NA;
        this.eu = ResponseRegion.EU; // can't use enum in angular template unless it's a local variable
        this.descriptionCheckboxes = new Array();
        this.nonDescriptionCheckboxes = new Array();
        this.loading = true;
    }
    MapRegionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.mapsetsService.getMapRegions().subscribe(function (x) {
            _this.regions = x;
            _this.initRegions();
        });
        this.companySettingsService.getMapSetSettings().subscribe(function (x) {
            _this.mapSetSettings = x;
            _this.MAX_SELECTED_REGIONS = _this.mapSetSettings.maxMapSetAssignments;
        });
    };
    MapRegionsComponent.prototype.initRegions = function () {
        var e_1, _a, e_2, _b, e_3, _c, e_4, _d;
        var _this = this;
        this.updateInitialMapset();
        this.initialMapset.mapRegions.sort(function (x, y) { return _this.sortMapsets(x, y); });
        this.checkboxModel = {
            id: this.regions.regionDetails.mapsetId,
            name: this.regions.regionDetails.name,
            checked: this.shouldBeChecked(this.regions.regionDetails.mapsetId),
            description: this.regions.regionDetails.description,
            children: [],
        };
        if (this.regions.regionDetails.name.indexOf('North America') >= 0) {
            this.region = ResponseRegion.NA;
        }
        if (this.regions.regionDetails.name.indexOf('Europe') >= 0) {
            this.region = ResponseRegion.EU;
        }
        try {
            for (var _e = tslib_1.__values(this.regions.fullRegions), _f = _e.next(); !_f.done; _f = _e.next()) {
                var fullRegion = _f.value;
                if (fullRegion.regionDetails.description === 'Canada') {
                    var fullRegionCheckbox = this.returnCheckboxNode(fullRegion.regionDetails.mapsetId, fullRegion.regionDetails.name, fullRegion.regionDetails.description);
                    this.checkboxModel.children.push(fullRegionCheckbox);
                    var canadianProvincesCheckbox = this.returnCheckboxNode(fullRegion.regionDetails.mapsetId, 'Canadian Provinces', 'Canada');
                    fullRegionCheckbox.children.push(canadianProvincesCheckbox);
                    try {
                        for (var _g = (e_2 = void 0, tslib_1.__values(fullRegion.majorRegions)), _h = _g.next(); !_h.done; _h = _g.next()) {
                            var majorRegion = _h.value;
                            var subRegionCheckbox = this.returnCheckboxNode(majorRegion.regionDetails.mapsetId, majorRegion.regionDetails.name, majorRegion.regionDetails.description);
                            canadianProvincesCheckbox.children.push(subRegionCheckbox);
                        }
                    }
                    catch (e_2_1) { e_2 = { error: e_2_1 }; }
                    finally {
                        try {
                            if (_h && !_h.done && (_b = _g.return)) _b.call(_g);
                        }
                        finally { if (e_2) throw e_2.error; }
                    }
                }
                else {
                    var fullRegionCheckbox = this.returnCheckboxNode(fullRegion.regionDetails.mapsetId, fullRegion.regionDetails.name, fullRegion.regionDetails.description);
                    this.checkboxModel.children.push(fullRegionCheckbox);
                    try {
                        for (var _j = (e_3 = void 0, tslib_1.__values(fullRegion.majorRegions)), _k = _j.next(); !_k.done; _k = _j.next()) {
                            var majorRegion = _k.value;
                            var subRegionCheckbox = this.returnCheckboxNode(majorRegion.regionDetails.mapsetId, majorRegion.regionDetails.name, majorRegion.regionDetails.description);
                            if (this.OTHER_REGIONS.indexOf(majorRegion.regionDetails.name) >= 0) {
                                this.otherRegionArray.push(subRegionCheckbox);
                                continue;
                            }
                            fullRegionCheckbox.children.push(subRegionCheckbox);
                            if (this.region === ResponseRegion.EU) {
                                if (majorRegion.regionDetails.description.split(',').length > 1) {
                                    this.descriptionCheckboxes.push([
                                        subRegionCheckbox,
                                        fullRegionCheckbox,
                                    ]);
                                }
                                else {
                                    this.nonDescriptionCheckboxes.push([
                                        subRegionCheckbox,
                                        fullRegionCheckbox,
                                    ]);
                                }
                            }
                            if (!majorRegion.minorRegions) {
                                continue;
                            }
                            try {
                                for (var _l = (e_4 = void 0, tslib_1.__values(majorRegion.minorRegions)), _m = _l.next(); !_m.done; _m = _l.next()) {
                                    var minorRegion = _m.value;
                                    var stateCheckbox = this.returnCheckboxNode(minorRegion.mapsetId, minorRegion.name, minorRegion.description);
                                    subRegionCheckbox.children.push(stateCheckbox);
                                }
                            }
                            catch (e_4_1) { e_4 = { error: e_4_1 }; }
                            finally {
                                try {
                                    if (_m && !_m.done && (_d = _l.return)) _d.call(_l);
                                }
                                finally { if (e_4) throw e_4.error; }
                            }
                        }
                    }
                    catch (e_3_1) { e_3 = { error: e_3_1 }; }
                    finally {
                        try {
                            if (_k && !_k.done && (_c = _j.return)) _c.call(_j);
                        }
                        finally { if (e_3) throw e_3.error; }
                    }
                    if (this.otherRegionArray.length > 0) {
                        fullRegionCheckbox.children.push({
                            name: 'Other',
                            id: 0,
                            description: '',
                            children: this.otherRegionArray,
                            checked: this.otherRegionArray.every(function (x) { return x.checked === true; }),
                        });
                    }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_f && !_f.done && (_a = _e.return)) _a.call(_e);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.InitializeCheckBoxes(this.checkboxModel);
    };
    MapRegionsComponent.prototype.updateInitialMapset = function () {
        if (this.mapset) {
            this.initialMapset = JSON.parse(JSON.stringify(this.mapset));
        }
    };
    MapRegionsComponent.prototype.shouldBeChecked = function (id) {
        if (this.mapset.mapRegions == null) {
            return false;
        }
        return this.mapset.mapRegions.filter(function (x) { return x.mapsetId === id; }).length > 0;
    };
    MapRegionsComponent.prototype.returnCheckboxNode = function (id, name, description) {
        return {
            id: id,
            name: name,
            description: description || '',
            checked: this.shouldBeChecked(id),
            children: [],
        };
    };
    MapRegionsComponent.prototype.mapsetHasChanged = function () {
        var _this = this;
        if (!this.initialMapset) {
            return false;
        }
        else {
            var initial = clone(this.initialMapset.mapRegions);
            var mapsets = this.buildMapsets();
            mapsets.sort(function (x, y) { return _this.sortMapsets(x, y); });
            initial.sort(function (x, y) { return _this.sortMapsets(x, y); });
            var hasChanged = (this.mapset &&
                this.mapset.customMapsetName !==
                    this.initialMapset.customMapsetName) ||
                this.mapset.defaultMapset !== this.initialMapset.defaultMapset ||
                !isEqual(initial, mapsets);
            return hasChanged;
        }
    };
    MapRegionsComponent.prototype.sortMapsets = function (x, y) {
        if (x.mapsetId === y.mapsetId) {
            return 0;
        }
        if (x.mapsetId < y.mapsetId) {
            return -1;
        }
        return 1;
    };
    MapRegionsComponent.prototype.parentClicked = function (parentBox) {
        if (this.maxCheckboxesSelected && !parentBox.checked) {
            return;
        }
        parentBox.checked = !parentBox.checked;
        var child = function (checkbox) {
            checkbox.checked = parentBox.checked;
        };
        this.iterateTree(child, null, parentBox);
    };
    MapRegionsComponent.prototype.InitializeCheckBoxes = function (checkbox) {
        var _this = this;
        var parent = function () {
            if (_this.checkboxModel.checked) {
                _this.selectUnselectAll(checkbox);
            }
        };
        var child = function (childCheckBox) {
            if (childCheckBox.checked) {
                _this.selectUnselectAll(childCheckBox);
            }
        };
        this.iterateTree(child, parent, checkbox);
        this.loading = false;
    };
    MapRegionsComponent.prototype.middleNodeClicked = function (parentCheckBoxes, childCheckBox) {
        var _this = this;
        if (parentCheckBoxes[0].checked ||
            (!childCheckBox.checked && this.maxCheckboxesSelected)) {
            return;
        }
        if (childCheckBox.name === 'Other' && !childCheckBox.checked) {
            if (this.mapsetsSelected(true) + this.OTHER_REGIONS.length >
                this.MAX_SELECTED_REGIONS) {
                return;
            }
        }
        this.parentClicked(childCheckBox);
        parentCheckBoxes.forEach(function (x) {
            _this.childDetection(x);
        });
    };
    MapRegionsComponent.prototype.childClicked = function (parentCheckBoxes, childCheckBox) {
        var _this = this;
        if (parentCheckBoxes[0].checked ||
            (!childCheckBox.checked && this.maxCheckboxesSelected)) {
            return;
        }
        childCheckBox.checked = !childCheckBox.checked;
        parentCheckBoxes.forEach(function (x) {
            _this.childDetection(x);
        });
    };
    MapRegionsComponent.prototype.childDetection = function (parentCheckBox) {
        parentCheckBox.checked = !parentCheckBox.checked;
        parentCheckBox.checked = parentCheckBox.children.every(function (x) {
            return x.checked;
        });
    };
    MapRegionsComponent.prototype.masterClicked = function (masterCheckBox) {
        if (this.maxCheckboxesSelected) {
            return;
        }
        masterCheckBox.checked = !masterCheckBox.checked;
        this.selectUnselectAll(masterCheckBox);
    };
    MapRegionsComponent.prototype.selectUnselectAll = function (masterCheckBox) {
        var func = function (checkbox) {
            checkbox.checked = masterCheckBox.checked;
        };
        this.iterateTree(func, null, masterCheckBox);
    };
    MapRegionsComponent.prototype.buildMapsets = function () {
        var _this = this;
        var mapsets = new Array();
        var parentFunction = function () {
            if (_this.checkboxModel.checked) {
                mapsets.push(_this.checkboxToMapset(_this.checkboxModel.id, _this.checkboxModel.name));
                return true;
            }
        };
        var childFunction = function (checkbox) {
            if (checkbox.name === 'Other') {
                return false;
            }
            if (checkbox.checked) {
                mapsets.push(_this.checkboxToMapset(checkbox.id, checkbox.name));
                return true;
            }
        };
        this.iterateTree(childFunction, parentFunction);
        return mapsets;
    };
    Object.defineProperty(MapRegionsComponent.prototype, "maxCheckboxesSelected", {
        get: function () {
            return (this.mapsetsSelected() === this.MAX_SELECTED_REGIONS ||
                this.mapsetsSelected() >= this.MAX_SELECTED_REGIONS);
        },
        enumerable: true,
        configurable: true
    });
    MapRegionsComponent.prototype.mapsetsSelected = function (ignoreOther) {
        var _this = this;
        var numChecked = 0;
        var parentFunction = function () {
            if (_this.checkboxModel && _this.checkboxModel.checked) {
                numChecked++;
                return true;
            }
        };
        var childFunction = function (checkbox) {
            var ignoreRegion = false;
            if (ignoreOther) {
                ignoreRegion = _this.otherRegionArray.some(function (region) {
                    return region.id === checkbox.id;
                });
            }
            if (checkbox.name === 'Other' || ignoreRegion) {
                return false;
            }
            if (checkbox.checked) {
                numChecked++;
                return true;
            }
        };
        this.iterateTree(childFunction, parentFunction);
        return numChecked;
    };
    MapRegionsComponent.prototype.checkboxToMapset = function (id, name) {
        return {
            mapsetId: id,
            mapsetName: name,
        };
    };
    MapRegionsComponent.prototype.iterateTree = function (childFunction, parentFunction, startingCheckBox) {
        if (this.checkboxModel) {
            var checkbox = startingCheckBox ? startingCheckBox : this.checkboxModel;
            if (parentFunction && parentFunction.apply(this)) {
                return;
            }
            this.treeNode(checkbox, childFunction);
        }
    };
    MapRegionsComponent.prototype.treeNode = function (checkbox, func) {
        var e_5, _a;
        try {
            for (var _b = tslib_1.__values(checkbox.children), _c = _b.next(); !_c.done; _c = _b.next()) {
                var child = _c.value;
                if (func.apply(this, [child])) {
                    continue;
                }
                this.treeNode(child, func);
            }
        }
        catch (e_5_1) { e_5 = { error: e_5_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_5) throw e_5.error; }
        }
    };
    return MapRegionsComponent;
}());
export { MapRegionsComponent };
