/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "ngx-bootstrap/modal/modal.directive";
import * as i2 from "ngx-bootstrap/component-loader/component-loader.factory";
import * as i3 from "@ngx-translate/core/src/translate.pipe";
import * as i4 from "@ngx-translate/core/src/translate.service";
import * as i5 from "./data-retention-policy-modal.component";
import * as i6 from "@angular/router";
var styles_DataRetentionPolicyModal = [];
var RenderType_DataRetentionPolicyModal = i0.ɵcrt({ encapsulation: 2, styles: styles_DataRetentionPolicyModal, data: {} });
export { RenderType_DataRetentionPolicyModal as RenderType_DataRetentionPolicyModal };
export function View_DataRetentionPolicyModal_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { modal: 0 }), (_l()(), i0.ɵeld(1, 16777216, null, null, 34, "div", [["bsModal", ""], ["class", "modal fade"], ["tabindex", "-1"]], null, [[null, "click"], [null, "keydown.esc"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.esc" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onEsc($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 212992, [[1, 4], ["modal", 4]], 0, i1.ModalDirective, [i0.ElementRef, i0.ViewContainerRef, i0.Renderer2, i2.ComponentLoaderFactory], null, null), (_l()(), i0.ɵeld(3, 0, null, null, 32, "div", [["class", "modal-dialog modal-md"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 31, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "button", [["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Data Retention Policy Changed"])), (_l()(), i0.ɵeld(11, 0, null, null, 19, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵted(12, null, ["A change was made to the data retention policy for your account on ", " by ", ". "])), (_l()(), i0.ɵeld(13, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" The policy was changed from retaining "])), (_l()(), i0.ɵeld(16, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(17, null, ["", ""])), (_l()(), i0.ɵted(-1, null, [" weeks to retaining "])), (_l()(), i0.ɵeld(19, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(20, null, ["", ""])), (_l()(), i0.ɵted(21, null, [" weeks of data, effective on ", " UTC - 5:00; "])), (_l()(), i0.ɵeld(22, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(23, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" If you did not intend to make this change, please go to "])), (_l()(), i0.ɵeld(25, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToAdmin() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Company Admin "])), (_l()(), i0.ɵted(-1, null, [" to change your policy."])), (_l()(), i0.ɵeld(28, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(29, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(30, null, [" This notice will persist at log in until ", ". "])), (_l()(), i0.ɵeld(31, 0, null, null, 0, "div", [["class", "clearfix"]], null, null, null, null, null)), (_l()(), i0.ɵeld(32, 0, null, null, 3, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(33, 0, null, null, 2, "button", [["class", "btn btn-default"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(34, null, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modifiedOn; var currVal_1 = _co.modifiedBy; _ck(_v, 12, 0, currVal_0, currVal_1); var currVal_2 = _co.previousNumberOfWeeks; _ck(_v, 17, 0, currVal_2); var currVal_3 = _co.numberOfWeeks; _ck(_v, 20, 0, currVal_3); var currVal_4 = _co.startDate; _ck(_v, 21, 0, currVal_4); var currVal_5 = _co.startDate; _ck(_v, 30, 0, currVal_5); var currVal_6 = i0.ɵunv(_v, 34, 0, i0.ɵnov(_v, 35).transform("common.Ok")); _ck(_v, 34, 0, currVal_6); }); }
export function View_DataRetentionPolicyModal_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "data-retention-policy-modal", [], null, null, null, View_DataRetentionPolicyModal_0, RenderType_DataRetentionPolicyModal)), i0.ɵdid(1, 49152, null, 0, i5.DataRetentionPolicyModal, [i6.Router], null, null)], null, null); }
var DataRetentionPolicyModalNgFactory = i0.ɵccf("data-retention-policy-modal", i5.DataRetentionPolicyModal, View_DataRetentionPolicyModal_Host_0, {}, {}, []);
export { DataRetentionPolicyModalNgFactory as DataRetentionPolicyModalNgFactory };
