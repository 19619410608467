import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserContextService } from './../alk-components/authentication';

@Injectable()
export class TOSGuard implements CanActivate {
  constructor(private auth: UserContextService, private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.auth.getUser();

    if (this.auth.isAzureAccount) {
      this.router.navigate(['/azure']);
      return false;
    }

    if (!user || user.currentTermsOfUseAccepted === true) {
      return true;
    }

    const params = { r: state.url };

    this.router.navigate(['/accept-terms', params]);
    return false;
  }
}
