<div class="container">
  <div class="message-panel">
    <div *ngIf="showHyperlinkError" class="alert alert-danger alert-dismissible" role="alert">
      <button type="button" class="close" (click)="showHyperlinkError=false"><span>&times;</span></button>
      <strong>
        {{ 'views.mapsets-list.error.TooManyMapsets1' | translate}}
        <a target="_blank" href="https://kb.omnitracs.com/Omnitracs_One/CoPilot/">{{ 'views.mapsets-list.error.TooManyMapsets2' | translate}}</a>
        {{ 'views.mapsets-list.error.TooManyMapsets3' | translate}}
      </strong>
    </div>
    <div *ngIf="errorMsg" class="row alert alert-danger" role="alert">
      <span>{{errorMsg}}</span>
    </div>
  </div>

  <div class="panel list-page-panel">

    <div class="row panel action-panel">
      <div class="col-xs-4">&nbsp;</div>
      <div class="col-xs-4">
        <h4 class="text-center">
        <span [innerHtml]="'views.header.Mapsets' | translate" class="page-title"></span>
      </h4>
      </div>
      <div class="col-xs-4">&nbsp;</div>
    </div>

    <div class="row configure-panel">
      <div class="filter-panel">
        <div class="panel-body">
          <label class="col-xs-4">
            <span [innerHtml]="'common.Filter' | translate"></span>
            <input type="text" class="form-control" placeholder="" [formControl]="searchTerm" (keydown.escape)="clearSearchTerm()"/>
          </label>
          <div class="col-xs-4"></div>
          <div class="col-xs-4 text-right filter-add">
<!-- hiding from UI for now
              <a class="btn btn-default connection-btn" [routerLink]="['/connection-settings']">
              <span class="ico ico-gears"></span>
              <span [innerHtml]="'views.mapsets-list.Connection' | translate"></span>
            </a>
-->
            <button class="btn btn-default" [routerLink]="['/mapsets/create']" [disabled]="!isFullAccess">
              <span [innerHtml]="'views.mapsets-list.CreateMapset' | translate"></span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row grid-panel">
      <table class="table table-striped table-hover list-table clickable-row">
        <thead>
          <tr>
            <th (click)="toggleSortDir();sortCol='name';updateCurrentPage()" class="sortable-column col-md-6">
              <span [innerHtml]="'views.mapsets-list.Name' | translate" class="column-name"></span>
              <span class="column-arrows">
                <span *ngIf="sortCol=='name'" class="glyphicon" [ngClass]="{'glyphicon-triangle-top': sortDir === 'ASC', 'glyphicon-triangle-bottom': sortDir === 'DESC'}"></span>
              </span>
            </th>
            <th (click)="toggleSortDir();sortCol='fileSize';updateCurrentPage()" class="col-md-2 sortable-column" *ngIf="false">
              <span [innerHtml]="'views.mapsets-list.FileSize' | translate" class="column-name"></span>
              <span class="column-arrows">
                <span *ngIf="sortCol=='fileSize'" class="glyphicon" [ngClass]="{'glyphicon-triangle-top': sortDir === 'ASC', 'glyphicon-triangle-bottom': sortDir === 'DESC'}"></span>
              </span>
            </th>
            <th (click)="toggleSortDir();sortCol='vehicleGroupCount';updateCurrentPage()" class="col-md-2 sortable-column">
              <span [innerHtml]="'views.mapsets-list.VehicleGroups' | translate" class="column-name"></span>
              <span class="column-arrows">
                <span *ngIf="sortCol=='vehicleGroupCount'" class="glyphicon" [ngClass]="{'glyphicon-triangle-top': sortDir === 'ASC', 'glyphicon-triangle-bottom': sortDir === 'DESC'}"></span>
              </span>
            </th>
            <th (click)="toggleSortDir();sortCol='IsDefault';updateCurrentPage()" class="col-md-2 sortable-column">
              <span [innerHtml]="'views.mapsets-list.Default' | translate" class="column-name"></span>
              <span class="column-arrows">
                <span *ngIf="sortCol=='IsDefault'" class="glyphicon" [ngClass]="{'glyphicon-triangle-top': sortDir === 'ASC', 'glyphicon-triangle-bottom': sortDir === 'DESC'}"></span>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let mapset of pageItems; let i = index">
            <td [routerLink]=" isFullAccess ? ['/mapsets', mapset.customMapsetId] : ['./']">
              <span [innerHtml]="mapset.customMapsetName | alkHighlight:searchTerm.value" title="{{mapset.customMapsetName}}" class="row-text"></span>
              <br/>
              <span class="row-subtext">{{mapset.regions.join(', ')}}</span>
            </td>
            <td *ngIf="false">
              <span class="row-text">{{mapset.fileSize | fileSizeFormatter}}</span>
            </td>
            <td>
                <span class="row-text">{{mapset.vehicleGroups.length}}</span>
            </td>
            <td>
              <button (click)="toggleDefaultMapset(mapset)" class="btn btn-default" [disabled]="!isFullAccess">
                <span *ngIf="!mapset.defaultMapset" class="ico ico-checkbox-unchecked"></span>
                <span *ngIf="mapset.defaultMapset" class="ico ico-checkbox-checked"></span>
              </button>
            </td>
            <td class="text-right">
              <button [routerLink]="['/mapsets', mapset.customMapsetId]" title="{{'common.Edit' | translate}}" class="btn btn-default" [disabled]="!isFullAccess">
                <span class="glyphicon glyphicon-edit"></span>
              </button>
              <button type="button" (click)="deleteMapset(mapset)" title="{{'common.Delete' | translate}}" class="btn btn-default" [disabled]="!isFullAccess">
                <span class="ico ico-trash"></span>
              </button>
            </td>
          </tr>
          <tr *ngIf="isProcessing">
            <td colspan="7"> <span class="glyphicon glyphicon-refresh spinner"></span>  {{'common.Loading' | translate}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row pagination-panel">
      <div class="col-md-4 grid-details">
        {{ 'common.DisplayOf' | translate:{pageStartNumber:pageStart(), pageEndNumber:pageEnd(), total:total} }}
      </div>
      <div class="col-md-8">
        <div class="pull-right">
          <button type="button" class="btn btn-default" (click)="previousPage()" [disabled]="!hasPreviousPage()">
            <span class="glyphicon glyphicon-menu-left"></span>
            <span [innerHtml]="'common.Previous' | translate"></span>
          </button>
          <button type="button" class="btn btn-default" (click)="nextPage()" [disabled]="!hasNextPage()">
            <span [innerHtml]="'common.Next' | translate"></span>
            <span class="glyphicon glyphicon-menu-right"></span>
          </button>
        </div>
      </div>
    </div>

  </div>
</div>

<confirmation-modal #confirmModal title="{{ 'views.mapsets-list.DeleteMapsetTitle' | translate }}" body="{{ 'views.mapsets-list.deleteMapsetBody' | translate }}"></confirmation-modal>
<confirmation-modal #setDefaultConfirmModal title="{{ 'views.mapsets-list.SetDefaultTitle' | translate }}" body="{{ 'views.mapsets-list.ConfirmDefaultBody' | translate }}"></confirmation-modal>
