import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'alkHighlight'
})
export class HighlightPipe implements PipeTransform {
  public transform(value, searchTerm: string) {
    if (value == null || searchTerm == null) {
      return value;
    }

    // the value from the pipe might not be a string...
    searchTerm = searchTerm.toString().toUpperCase();

    const startIndex = value.toString().toUpperCase().indexOf(searchTerm);
    if (startIndex < 0) { return value; } // search term does not exist, return now.

    const left = value.toString().substring(0, startIndex);
    const right = value.toString().substring(startIndex + searchTerm.length);
    const middle = value.toString().substring(startIndex, startIndex + searchTerm.length);

    return left + '<span class="highlight">' + middle + '</span>' + right;
  }
}
