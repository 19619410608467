import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, of, throwError, timer } from "rxjs";
import { retryWhen, share, switchMap, take } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var TID_AUTHCODE_KEY = "tidAuthCode";
var TID_TOKEN_KEY = "tidToken";
var TidService = /** @class */ (function () {
    function TidService(http) {
        this.http = http;
        this.accounts$ = new BehaviorSubject([]);
        this.errorMessage$ = new BehaviorSubject(null);
        this.authorizeUrl = environment.tidUrl + "/authorize";
        this.logoutUrl = environment.tidUrl + "/logout";
        this.checkForComponents$ = timer(0, 100).pipe(switchMap(function () {
            var migrateComponent = document.getElementById("tmxtid-sign-in-component");
            if (migrateComponent) {
                return of(true);
            }
            return throwError(true);
        }), retryWhen(function (error) { return error; }), take(1), share());
        var authParams = "?response_type=code&scope=openid " + environment.tidAppName;
        var clientId = "&client_id=" + environment.tidClientId;
        var redirectUrl = "&redirect_uri=" + location.origin + "/tid/redirect";
        this.authorizeUrl += "" + authParams + clientId + redirectUrl;
        var logoutRedirect = "?post_logout_redirect_uri=" + location.origin + "/tid/logout";
        this.logoutUrl += logoutRedirect;
    }
    TidService.prototype.signInWithTid = function (email, promptCreate) {
        if (email === void 0) { email = null; }
        if (promptCreate === void 0) { promptCreate = false; }
        var url = this.authorizeUrl + "&prompt=" + (promptCreate ? "create" : "login");
        if (email) {
            url += "&login_hint=" + encodeURIComponent(email);
        }
        window.location.href = url;
    };
    TidService.prototype.checkMigrationStatus = function (email) {
        var url = environment.ssoUrl + "/v1/trimbleid/migration-status";
        return this.http.post(url, {
            email: email,
        });
    };
    TidService.prototype.logoutWithTid = function () {
        var authCode = this.getAuthCode();
        var token = this.getIdToken();
        this.removeAuthCode();
        this.removeIdToken();
        if (!authCode || !token) {
            return;
        }
        window.location.href = this.logoutUrl + ("&id_token_hint=" + token);
    };
    TidService.prototype.setAuthCode = function (authCode) {
        localStorage.setItem(TID_AUTHCODE_KEY, authCode);
    };
    TidService.prototype.removeAuthCode = function () {
        localStorage.removeItem(TID_AUTHCODE_KEY);
    };
    TidService.prototype.getAuthCode = function () {
        return localStorage.getItem(TID_AUTHCODE_KEY);
    };
    TidService.prototype.setIdToken = function (token) {
        localStorage.setItem(TID_TOKEN_KEY, token);
    };
    TidService.prototype.removeIdToken = function () {
        localStorage.removeItem(TID_TOKEN_KEY);
    };
    TidService.prototype.getIdToken = function () {
        return localStorage.getItem(TID_TOKEN_KEY);
    };
    TidService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TidService_Factory() { return new TidService(i0.ɵɵinject(i1.HttpClient)); }, token: TidService, providedIn: "root" });
    return TidService;
}());
export { TidService };
