// Vehicle Types

// Heavy Duty/ Truck
export const EUEnglishHeavyDuty = {
  unitsOfMeasure: 'English',
  distanceUnits: 'Miles',
  fuelUnits: 'Gallons',
  vehicleType: 'Truck',
  numAxles: 5,
  truckDimensions: 'Custom',
  maxHeight: (13 * 12) + 1,
  maxWidth: (9 * 12) + 2,
  totalLength: (41 * 12),
  totalWeight: 36, // // metric tons
  totalWeightPerAxle: 7.5 , // // metric tons

  // road preferences
  roadPreferencesFreewaysAFType: 'Neutral',
  roadPreferencesFreewaysSpeed: 43,
  roadPreferencesDividedHighwaysAFType: 'Neutral',
  roadPreferencesDividedHighwaysSpeed: 31,
  roadPreferencesPrimaryRoadsAFType: 'Neutral',
  roadPreferencesPrimaryRoadsSpeed: 25,
  roadPreferencesSecondaryRoadsAFType: 'Neutral',
  roadPreferencesSecondaryRoadsSpeed: 16,
  roadPreferencesLocalStreetsAFType: 'Neutral',
  roadPreferencesLocalStreetsSpeed: 12,
  classOverrides: 3,
  nationalNetwork: true,
  fiftyThreeFootTrailer: true,
  routingType: 'Practical',
  tunnelRestriction: 'None',
  propane: false
};

// Medium Duty
export const EUEnglishMediumDuty = {
  unitsOfMeasure: 'English',
  distanceUnits: 'Miles',
  fuelUnits: 'Gallons',
  vehicleType: 'MidsizeTruck',
  numAxles: 2,
  truckDimensions: 'Custom',
  maxHeight: (12 * 12) + 6,
  maxWidth: (8 * 12) + 2,
  totalLength: (32 * 12) + 10,
  totalWeight: 7.5, // metric tons
  totalWeightPerAxle: 7.5, // metric tons

  // road preferences
  roadPreferencesFreewaysAFType: 'Neutral',
  roadPreferencesFreewaysSpeed: 50,
  roadPreferencesDividedHighwaysAFType: 'Neutral',
  roadPreferencesDividedHighwaysSpeed: 31,
  roadPreferencesPrimaryRoadsAFType: 'Neutral',
  roadPreferencesPrimaryRoadsSpeed: 25,
  roadPreferencesSecondaryRoadsAFType: 'Neutral',
  roadPreferencesSecondaryRoadsSpeed: 16,
  roadPreferencesLocalStreetsAFType: 'Neutral',
  roadPreferencesLocalStreetsSpeed: 12,
  classOverrides: 0,
  nationalNetwork: false,
  fiftyThreeFootTrailer: false,
  routingType: 'Practical',
  tunnelRestriction: 'None',
  propane: false,
  trailerHeight: 0
};

// Light Duty
export const EUEnglishLightDuty = {
  unitsOfMeasure: 'English',
  distanceUnits: 'Miles',
  fuelUnits: 'Gallons',
  vehicleType: 'LightDuty',
  numAxles: 2,
  truckDimensions: 'Custom',
  maxHeight: (8 * 12) + 2,
  maxWidth: (7 * 12) + 10,
  totalLength: (18 * 12) + 1,
  totalWeight: 2.5,
  totalWeightPerAxle: 0,

  // road preferences
  roadPreferencesFreewaysAFType: 'Neutral',
  roadPreferencesFreewaysSpeed: 55,
  roadPreferencesDividedHighwaysAFType: 'Neutral',
  roadPreferencesDividedHighwaysSpeed: 30,
  roadPreferencesPrimaryRoadsAFType: 'Neutral',
  roadPreferencesPrimaryRoadsSpeed: 20,
  roadPreferencesSecondaryRoadsAFType: 'Neutral',
  roadPreferencesSecondaryRoadsSpeed: 16,
  roadPreferencesLocalStreetsAFType: 'Neutral',
  roadPreferencesLocalStreetsSpeed: 12,
  classOverrides: 0,
  nationalNetwork: false,
  fiftyThreeFootTrailer: false,
  displayRestrictions: 'BasedOnTruckRestrictions',
  hazmatType: 'None',
  tunnelRestriction: 'None',
  routingType: 'Practical',
  trailerHeight: 0
};

export const EUEnglishBus = {
  unitsOfMeasure: 'English',
  distanceUnits: 'Miles',
  fuelUnits: 'Gallons',
  vehicleType: 'Bus',
  truckDimensions: 'Custom',
  maxHeight: 10 * 12 + 9,
  maxWidth: 96,
  totalLength: 40 * 12,
  totalWeight: 14.96,
  totalWeightPerAxle: 10.43,
  numAxles: 2,
  classOverrides: 0,
  nationalNetwork: false,
  fiftyThreeFootTrailer: false,
  displayRestrictions: 'BasedOnTruckRestrictions',
  hazmatType: 'None'
};

// Auto
export const EUEnglishAuto = {
  unitsOfMeasure: 'English',
  distanceUnits: 'Miles',
  fuelUnits: 'Gallons',
  vehicleType: 'Auto',
  numAxles: 2,
  truckDimensions: 'Custom',
  maxHeight: 0,
  maxWidth: 0,
  totalLength: 0,
  totalWeight: 0,
  totalWeightPerAxle: 0,

  // road preferences
  roadPreferencesFreewaysAFType: 'Neutral',
  roadPreferencesFreewaysSpeed: 55,
  roadPreferencesDividedHighwaysAFType: 'Neutral',
  roadPreferencesDividedHighwaysSpeed: 30,
  roadPreferencesPrimaryRoadsAFType: 'Neutral',
  roadPreferencesPrimaryRoadsSpeed: 20,
  roadPreferencesSecondaryRoadsAFType: 'Neutral',
  roadPreferencesSecondaryRoadsSpeed: 16,
  roadPreferencesLocalStreetsAFType: 'Neutral',
  roadPreferencesLocalStreetsSpeed: 12,
  classOverrides: 0,
  nationalNetwork: false,
  fiftyThreeFootTrailer: false,
  displayRestrictions: 'BasedOnTruckRestrictions',
  hazmatType: 'None',
  tunnelRestriction: 'None',
  routingType: 'Practical'
};


// truck dimension presets
export const EU54Foot = {
  truckDimensions: 'EU54Foot',
  maxHeight: (13 * 12) + 1,
  maxWidth: (8 * 12) + 2,
  totalLength: (54 * 12) + 2,
  totalWeight: 36.29, // lbs
  totalWeightPerAxle: 7.26, // lbs
  nationalNetwork: false,
  fiftyThreeFootTrailer: false,
  numAxles: 2,
  trailerHeight: 14900
};

export const EU61Foot = {
  truckDimensions: 'EU61Foot',
  maxHeight: (13 * 12) + 1,
  maxWidth: (8 * 12) + 2,
  totalLength: (61 * 12) + 6,
  totalWeight: 36.29, // metric tons
  totalWeightPerAxle: 7.26,  // metric tons
  nationalNetwork: false,
  fiftyThreeFootTrailer: false,
  numAxles: 2,
  trailerHeight: 14900
};

export const EU40Foot = {
  truckDimensions: 'EU40Foot',
  maxHeight: (13 * 12) + 1,
  maxWidth: (8 * 12) + 2,
  totalLength: (40 * 12),
  totalWeight: 36.29, // metric tons
  totalWeightPerAxle: 7.26,  // metric tons
  nationalNetwork: false,
  fiftyThreeFootTrailer: false,
  numAxles: 3,
  trailerType: 1,
  trailerCount: 0,
  trailerAxleCount: 0,
  trailerHeight: 0,
  tireCount: 6
};

export const EUEnglishCustom = {
  truckDimensions: 'Custom',
  maxHeight: (13 * 12) + 1,
  maxWidth: (9 * 12) + 2,
  totalLength: (54 * 12) + 2,
  totalWeight: 36, // metric tons
  totalWeightPerAxle: 7.5 // metric tons
};
