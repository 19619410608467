// Vehicle Types

// Heavy Duty/ Truck
export const EUMetricHeavyDuty = {
  unitsOfMeasure: 'Metric',
  distanceUnits: 'Kilometers',
  fuelUnits: 'Liters',
  vehicleType: 'Truck',
  numAxles: 5,
  truckDimensions: 'Custom',
  maxHeight: 4,
  maxWidth: 2.8,
  totalLength: 12.5,
  totalWeight: 36,
  totalWeightPerAxle: 7.5,

  // road preferences
  roadPreferencesFreewaysAFType: 'Neutral',
  roadPreferencesFreewaysSpeed: 69,
  roadPreferencesDividedHighwaysAFType: 'Neutral',
  roadPreferencesDividedHighwaysSpeed: 50,
  roadPreferencesPrimaryRoadsAFType: 'Neutral',
  roadPreferencesPrimaryRoadsSpeed: 40,
  roadPreferencesSecondaryRoadsAFType: 'Neutral',
  roadPreferencesSecondaryRoadsSpeed: 26,
  roadPreferencesLocalStreetsAFType: 'Neutral',
  roadPreferencesLocalStreetsSpeed: 19,
  classOverrides: 3,
  nationalNetwork: true,
  fiftyThreeFootTrailer: true,
  routingType: 'Practical',
  tunnelRestriction: 'None',
  propane: false
};

// Medium Duty
export const EUMetricMediumDuty = {
  unitsOfMeasure: 'Metric',
  distanceUnits: 'Kilometers',
  fuelUnits: 'Liters',
  vehicleType: 'MidsizeTruck',
  numAxles: 2,
  truckDimensions: 'Custom',
  maxHeight: 3.80,
  maxWidth: 2.5,
  totalLength: 10,
  totalWeight: 7.5, // metric tons
  totalWeightPerAxle: 7.5, // metric tons

  // road preferences
  roadPreferencesFreewaysAFType: 'Neutral',
  roadPreferencesFreewaysSpeed: 80,
  roadPreferencesDividedHighwaysAFType: 'Neutral',
  roadPreferencesDividedHighwaysSpeed: 50,
  roadPreferencesPrimaryRoadsAFType: 'Neutral',
  roadPreferencesPrimaryRoadsSpeed: 40,
  roadPreferencesSecondaryRoadsAFType: 'Neutral',
  roadPreferencesSecondaryRoadsSpeed: 26,
  roadPreferencesLocalStreetsAFType: 'Neutral',
  roadPreferencesLocalStreetsSpeed: 19,
  classOverrides: 0,
  nationalNetwork: false,
  fiftyThreeFootTrailer: false,
  routingType: 'Practical',
  tunnelRestriction: 'None',
  propane: false,
  trailerHeight: 0,
};

// Light Duty
export const EUMetricLightDuty = {
  unitsOfMeasure: 'Metric',
  distanceUnits: 'Kilometers',
  fuelUnits: 'Liters',
  vehicleType: 'LightDuty',
  numAxles: 2,
  truckDimensions: 'Custom',
  maxHeight: 2.5,
  maxWidth: 2.4,
  totalLength: 5.5,
  totalWeight: 2.5,
  totalWeightPerAxle: 0,

  // road preferences
  roadPreferencesFreewaysAFType: 'Neutral',
  roadPreferencesFreewaysSpeed: 89,
  roadPreferencesDividedHighwaysAFType: 'Neutral',
  roadPreferencesDividedHighwaysSpeed: 48,
  roadPreferencesPrimaryRoadsAFType: 'Neutral',
  roadPreferencesPrimaryRoadsSpeed: 32,
  roadPreferencesSecondaryRoadsAFType: 'Neutral',
  roadPreferencesSecondaryRoadsSpeed: 26,
  roadPreferencesLocalStreetsAFType: 'Neutral',
  roadPreferencesLocalStreetsSpeed: 19,
  classOverrides: 0,
  nationalNetwork: false,
  fiftyThreeFootTrailer: false,
  displayRestrictions: 'BasedOnTruckRestrictions',
  hazmatType: 'None',
  tunnelRestriction: 'None',
  routingType: 'Practical',
  trailerHeight: 0
};

// School Bus
export const EUMetricBus = {
  unitsOfMeasure: 'Metric',
  distanceUnits: 'Kilometers',
  fuelUnits: 'Liters',
  vehicleType: 'Bus',
  truckDimensions: 'Custom',
  maxHeight: 3.32,
  maxWidth: 2.44,
  totalLength: 12.19,
  totalWeight: 14.97,
  totalWeightPerAxle: 10.43,
  numAxles: 2,
  classOverrides: 0,
  nationalNetwork: false,
  fiftyThreeFootTrailer: false,
  hazmatType: 'None'
};

// Auto
export const EUMetricAuto = {
  unitsOfMeasure: 'Metric',
  distanceUnits: 'Kilometers',
  fuelUnits: 'Liters',
  vehicleType: 'Auto',
  numAxles: 2,
  truckDimensions: 'Custom',
  maxHeight: 0,
  maxWidth: 0,
  totalLength: 0,
  totalWeight: 0,
  totalWeightPerAxle: 0,

  // road preferences
  roadPreferencesFreewaysAFType: 'Neutral',
  roadPreferencesFreewaysSpeed: 89,
  roadPreferencesDividedHighwaysAFType: 'Neutral',
  roadPreferencesDividedHighwaysSpeed: 48,
  roadPreferencesPrimaryRoadsAFType: 'Neutral',
  roadPreferencesPrimaryRoadsSpeed: 32,
  roadPreferencesSecondaryRoadsAFType: 'Neutral',
  roadPreferencesSecondaryRoadsSpeed: 26,
  roadPreferencesLocalStreetsAFType: 'Neutral',
  roadPreferencesLocalStreetsSpeed: 19,
  classOverrides: 0,
  nationalNetwork: false,
  fiftyThreeFootTrailer: false,
  displayRestrictions: 'BasedOnTruckRestrictions',
  hazmatType: 'None',
  tunnelRestriction: 'None',
  routingType: 'Practical'
};

// Truck Dimensions presets
export const EU16Meter = {
  truckDimensions: 'EU16Meter', // should be same as EU54Foot
  maxHeight: 4,
  maxWidth: 2.50,
  totalLength: 16.50,
  totalWeight: 36.29, // metric tons
  totalWeightPerAxle: 7.26, // metric tons
  nationalNetwork: false,
  fiftyThreeFootTrailer: false,
  numAxles: 2,
  trailerHeight: 14900
};

export const EU18Meter = {
  truckDimensions: 'EU18Meter',
  maxHeight: 4,
  maxWidth: 2.50,
  totalLength: 18.75,
  totalWeight: 36.29, // metric tons
  totalWeightPerAxle: 7.26, // metric tons
  nationalNetwork: false,
  fiftyThreeFootTrailer: false,
  numAxles: 2,
  trailerHeight: 14900
};

export const EU12Meter = {
  truckDimensions: 'EU12Meter',
  maxHeight: 4,
  maxWidth: 2.50,
  totalLength: 12,
  totalWeight: 36.29, // metric tons
  totalWeightPerAxle: 7.26, // metric tons
  nationalNetwork: false,
  fiftyThreeFootTrailer: false,
  numAxles: 3,
  trailerType: 1,
  trailerCount: 0,
  trailerAxleCount: 0,
  trailerHeight: 0,
  tireCount: 6
};

export const EUMetricCustom = {
  truckDimensions: 'Custom',
  maxHeight: 4,
  maxWidth: 2.8,
  totalLength: 12.5,
  totalWeight: 36,
  totalWeightPerAxle: 7.5
};

