import { CommonModule } from "@angular/common";
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from "@angular/core";
import { LogoutComponent } from "./components/logout/logout.component";
import { RedirectComponent } from "./components/redirect/redirect.component";
import { TidRoutingModule } from "./routing/tid-routing.module";
import { TidComponent } from "./tid.component";

@NgModule({
  declarations: [TidComponent, RedirectComponent, LogoutComponent],
  imports: [CommonModule, TidRoutingModule, HttpClientModule],
  exports: [TidComponent],
})
export class TidModule {}
