/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../shared/terms.component.ngfactory";
import * as i2 from "../shared/terms.component";
import * as i3 from "@angular/common/http";
import * as i4 from "../../../alk-components/alk-translations/alk-language-context";
import * as i5 from "@angular/platform-browser";
import * as i6 from "@ngx-translate/core/src/translate.service";
import * as i7 from "./read-terms.component";
var styles_ReadTerms = [];
var RenderType_ReadTerms = i0.ɵcrt({ encapsulation: 2, styles: styles_ReadTerms, data: {} });
export { RenderType_ReadTerms as RenderType_ReadTerms };
export function View_ReadTerms_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "container  with-nav terms-page"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "panel page-panel"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "col-xs-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "terms", [], null, null, null, i1.View_Terms_0, i1.RenderType_Terms)), i0.ɵdid(5, 114688, null, 0, i2.Terms, [i3.HttpClient, i4.ALKLanguageContext, i5.DomSanitizer, i6.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 5, 0); }, null); }
export function View_ReadTerms_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "read-terms", [], null, null, null, View_ReadTerms_0, RenderType_ReadTerms)), i0.ɵdid(1, 49152, null, 0, i7.ReadTerms, [], null, null)], null, null); }
var ReadTermsNgFactory = i0.ɵccf("read-terms", i7.ReadTerms, View_ReadTerms_Host_0, {}, {}, []);
export { ReadTermsNgFactory as ReadTermsNgFactory };
