
import {of as observableOf } from 'rxjs';

import {switchMap, distinctUntilChanged, debounceTime} from 'rxjs/operators';
// angular components
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

// alk components
import { DriverService, Driver } from '../shared/driver.service';
import { DriverEditModal } from '../driver-edit-modal';

import { ConfirmationModal } from './../../shared';
import { UserContextService } from './../../../alk-components/authentication';
import { GroupsUtil } from './../../../app/shared/utils/groupsUtil';

@Component({
  selector: 'driver-list',
  providers: [DriverService],
  templateUrl: './driver-list.component.html'
})
// tslint:disable-next-line: component-class-suffix
export class DriverList implements OnInit {

  @ViewChild('confirmModal', { static: true }) confirmModal: ConfirmationModal;
  @ViewChild('driverEditModal', { static: true }) driverEditModal: DriverEditModal;

  isProcessing = false;
  allDrivers: Array<any> = [];
  filteredDrivers: Array<any> = [];
  sortAsc = true;
  searchTerm = new FormControl();
  downloadUrl = '';
  isImportEnabled = true;
  isExportEnabled = true;
  addButtonVisible = true;
  editButtonVisible = true;
  deleteButtonVisible = true;

  errorMsg: string;
  total = 0;
  currentPage = 0;
  perPage = 10;
  pageItems: Array<any> = [];

  sortCol = '';
  driverAdded = false;

  constructor(
    private driverService: DriverService,
    private userContext: UserContextService
  ) {
      const partnerActivities = this.userContext.getUser().partner.partnerActivities;
      this.isImportEnabled = partnerActivities.canImportDrivers;
      this.isExportEnabled = partnerActivities.canExportDrivers;
      this.addButtonVisible = partnerActivities.canAddDrivers;
      this.editButtonVisible = partnerActivities.canUpdateDrivers;
      this.deleteButtonVisible = partnerActivities.canDeleteDrivers;

  }

  ngOnInit() {
    this.isProcessing = true;

    this.searchTerm.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => this.filterAll(term)), )
      .subscribe(items => {
        this.filteredDrivers = items;
        this.total = this.filteredDrivers.length;
        this.currentPage = 0;
        this.setCurrentPageItems();
      });

    this.loadDriverList();
  }

  loadDriverList() {
    this.driverService.getDriverList()
      .subscribe(drivers => {
        this.allDrivers = drivers.items;
        this.filteredDrivers = this.allDrivers;
        this.total = this.filteredDrivers.length;
        this.setCurrentPageItems();
      }, error => {
        this.errorMsg = 'There was an error retrieving drivers: ' + error.message;
        console.log(error);
      }, () => {
        this.isProcessing = false;
        this.loadDrivers();
      });
  }

  loadDrivers() {
    this.driverService.getDrivers()
      .subscribe(drivers => {
        this.allDrivers = drivers.items;
        this.filteredDrivers = this.allDrivers;
        this.total = this.filteredDrivers.length;
        this.setCurrentPageItems();
      }, error => {
        this.errorMsg = 'There was an error retrieving drivers: ' + error.message;
        console.log(error);
      }, () => {
        this.isProcessing = false;
      });

    this.downloadUrl = this.driverService.getExportUrl(null, true);
  }

  editDriver(driver) {
    if (this.editButtonVisible) {
      this.driverEditModal.open(false, driver.id);
    }
  }

  deleteItem(item) {
    this.confirmModal.open()
      .then(() => {
        // tslint:disable-next-line:no-string-literal
        item['isProcessing'] = true;

        this.driverService.deleteDriver(item.id)
          .subscribe(c => {
            // tslint:disable-next-line:no-string-literal
            item['isProcessing'] = false;
            // remove the group from the list and get the current page of items
            const index = this.getItemIndex(this.allDrivers, item);

            // if we didn't find the group index, don't do anything.  Otherwise, remove the item from the list
            if (index >= 0) {
              this.allDrivers.splice(index, 1);
              this.filteredDrivers = this.allDrivers;
              this.total = this.filteredDrivers.length;
              this.setCurrentPageItems();
            }

          }, (error) => {
            // tslint:disable-next-line:no-string-literal
            item['isProcessing'] = false;
            this.errorMsg = 'Error while deleting driver';
          });
      });
  }

  getToolTipForGroups(groups: Array<any>) {
    return GroupsUtil.getToolTipforGroups(groups);
  }

  previousPage() {
    if (!this.hasPreviousPage()) { return; }

    this.currentPage--;
    this.setCurrentPageItems();
  }

  nextPage() {
    if (!this.hasNextPage()) { return; }

    this.currentPage++;
    this.setCurrentPageItems();
  }

  hasNextPage(): boolean {
    const nextPageStartIndex = (this.currentPage + 1) * this.perPage;
    return this.total > nextPageStartIndex;
  }

  hasPreviousPage(): boolean {
    return this.currentPage > 0;
  }

  pageStart() {
    if (this.filteredDrivers.length === 0) { return 0; }
    return (this.currentPage * this.perPage) + 1;
  }

  pageEnd() {
    let retVal = (this.currentPage * this.perPage) + this.perPage;
    if (retVal > this.total) {
      retVal = this.total;
    }
    return retVal;
  }

  getItemIndex(groups, group): number {
    for (let i = 0; i < groups.length; i++) {
      if (groups[i].id === group.id) {
        return i;
      }
    }

    return -1;
  }

  setCurrentPageItems() {
    const startPageIndex = this.currentPage * this.perPage;
    const endPageIndex = startPageIndex + this.perPage;

    this.pageItems = this.filteredDrivers.slice(startPageIndex, endPageIndex);
  }

  sortString(column: string, asc: boolean) {
    const multiplyBy = asc ? 1 : -1;

    this.filteredDrivers.sort((a, b) => {
      let aUpperCase = '';
      if (a[column] !== null) {
        aUpperCase = a[column].toUpperCase();
      }
      let bUpperCase = '';
      if (b[column] !== null) {
        bUpperCase = b[column].toUpperCase();
      }

      if (aUpperCase < bUpperCase) { return (-1 * multiplyBy); }
      if (aUpperCase > bUpperCase) { return (1 * multiplyBy); }
      return 0;
    });

    this.setCurrentPageItems();
  }

  sortNumber(column: string, asc: boolean) {
    const multiplyBy = asc ? 1 : -1;

    this.filteredDrivers.sort((a, b) => {
      return (a[column] - b[column]) * multiplyBy;
    });

    this.setCurrentPageItems();
  }

  filter(drivers: Array<any>, searchTerm: string) {
    searchTerm = searchTerm.toUpperCase();

    return drivers.filter(driver => {
      if (driver.name != null && driver.name.toUpperCase().indexOf(searchTerm) !== -1) { return true; }
      if (driver.driverId != null && driver.driverId.toUpperCase().indexOf(searchTerm) !== -1) { return true; }
      if (driver.driverGroupsAbbreviated != null && driver.driverGroupsAbbreviated.toUpperCase().indexOf(searchTerm) !== -1) {return true; }

      return false;
    });
  }

  filterAll(searchTerm?: string) {
    let results = this.allDrivers;

    if (searchTerm) {
      results = this.filter(results, searchTerm);
    }

    return observableOf(results);
  }

  onDriverUpdated(update) {
    if (update.addedNew) {
      this.loadDrivers();
    } else {
      // this.loadDrivers();
      const modifiedDriver: Driver = this.allDrivers.filter(driver => driver.id === update.id)[0];
      modifiedDriver.name = update.driver.name;
      modifiedDriver.driverId = update.driver.driverId;
    }
  }

  clearSearchTerm() {
    this.searchTerm.setValue('');
  }

}
