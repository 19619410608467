<div bsModal #modal="bs-modal" [config]="{ keyboard: false, backdrop: 'static' }" class="move-modal modal fade" tabindex="-1">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <!--<button type="button" class="close" (click)="modal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>-->
        <h4 class="modal-title">Move Devices </h4>
      </div>

      <form [formGroup]="form" (ngSubmit)="save()" novalidate>
        <div class="modal-body">
        <label class="modal-heading"> Move {{deviceNumber}} devices to  </label>
       <select class="form-control input-sm" formControlName="selectedAccount">
         <option *ngFor="let account of partnerAccounts;" [value]="account.id" >{{account.name}}</option>
       </select>

       <div class="row" style="margin-top: 15px;">
            <div class="col-md-12">
              <div *ngIf="errorMsg" class="alert alert-danger" role="alert">
                <span>{{errorMsg}}</span>
              </div>
            </div>
          </div>
       </div>
        <div class="clearfix"></div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default cancel-btn" (click)="modal.hide()">{{ 'common.Cancel' | translate }}</button>
          <button type="submit" class="btn btn-default">{{ 'common.Save' | translate }}</button>
        </div>
      </form>

    </div>
  </div>
</div>
