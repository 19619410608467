
// angular components
import { Injectable } from '@angular/core';

// third party components
import { Observable } from 'rxjs';

// alk components
import { PartnerAccount } from './partner-account';

import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PartnerAccountService {

  constructor(private http: HttpClient) {
  }

  public getPartnerAccounts(): Observable<Array<PartnerAccount>> {
    const url = environment.apiUrl + '/v1/account/partneraccounts';

    return this.http.get<any>(url).map(responseData => {
        const partnerAccounts: Array<PartnerAccount> = [];
        responseData.forEach(responseItem => {
          const partnerAccount: PartnerAccount = {
            id: responseItem.accountId,
            name: responseItem.accountName,
            vehicleCount: responseItem.vehicleCount
          };
          partnerAccounts.push(partnerAccount);
        });
        return partnerAccounts;
      });
  }

}
