<fieldset [disabled]="isProcessing">
  <form [formGroup]="form" (ngSubmit)="save()" novalidate>

    <div class="container">

      <div class="message-panel">
        <div *ngIf="(successMessage | async)" class="alert alert-success alert-dismissible" role="alert">
          <button type="button" class="close" (click)="successMessage=null"><span>&times;</span></button>
          <strong>{{successMessage | async}}</strong>
        </div>
        <div *ngIf="showHyperlinkError" class="alert alert-danger alert-dismissible" role="alert">
          <button type="button" class="close" (click)="showHyperlinkError=false"><span>&times;</span></button>
          <strong>
            {{ 'views.vehicle-group-detail.error.TooManyMapsets1' | translate}}
            {{hyperlinkErrorType | async}}
            {{ 'views.vehicle-group-detail.error.TooManyMapsets2' | translate}}
            <a target="_blank" href="https://kb.omnitracs.com/Omnitracs_One/CoPilot/">{{ 'views.vehicle-group-detail.error.TooManyMapsets3' | translate}}</a>
          </strong>
        </div>
        <div *ngIf="(errorMessage | async)" class="alert alert-danger alert-dismissible" role="alert">
          <button type="button" class="close" (click)="errorMessage=null"><span>&times;</span></button>
          <strong>{{errorMessage | async}}</strong>
        </div>
      </div>


      <div class="panel page-panel">

        <div class="row panel action-panel">
          <div class="col-xs-12">
            <h4 class="text-center">
              <span [innerHtml]="'views.vehicle-group-detail.VehicleGroup' | translate"></span>
              <span *ngIf="isNew" class="label label-success">NEW</span>
            </h4>
          </div>

        </div>

        <div class="row header-panel">
          <label class="col-xs-4" [ngClass]="{'has-error': !isInitializing && !form.controls.name.valid }">
            <span [innerHtml]="'views.vehicle-group-detail.GroupName' | translate"></span>
            <input type="text" id="group-name-input" class="form-control input-sm" formControlName="name" [readonly]="!vgPermissions.editButtonVisible" />
            <div *ngIf="!isInitializing && (!form.controls.name.valid)" class="glyphicon glyphicon-exclamation-sign error-glyph">
              <div class="error-tooltip">
                <p *ngIf="form.controls.name.errors?.required">{{ 'common.FieldIsRequired' | translate }}</p>
                <p *ngIf="form.controls.name.errors?.maxlength">{{ 'common.MustBeLessThanChars' | translate: {maxChars: form.controls.name.errors?.maxlength.requiredLength} }}</p>
              </div>
            </div>
          </label>
          <div class="col-xs-4" *ngIf ="isRouteReporterAccount">
              <div class="col-xs-12">
                <input type = "checkbox" formControlName="oorEmailIsEnabled" (change)="toggleOOREmailIsEnabled();">
                 <label>
                   <span [innerHtml]="'views.configuration-profile-detail.form.OOREmailIsEnabled' | translate"></span>
                 </label>
               </div>
               <label [ngStyle]="{'color': (!oorEmailIsEnabled) ? '#848484' : 'black'}" class="col-xs-12" [ngClass]="{'has-error': !isInitializing && !form.controls.oorEmail.valid}">
                   <span [innerHtml]="'views.vehicle-group-detail.OOREmail' | translate"></span>
                   <input type="text" id="oorEmail-input" name="oorEmail-input" class="form-control input-sm" formControlName="oorEmail" [readonly]="!oorEmailIsEnabled"
                    [ngStyle]="{'cursor': (!oorEmailIsEnabled) ? 'not-allowed' : 'auto'}"/>
                   <div *ngIf="(!isInitializing && !form.controls.oorEmail.valid)" class="glyphicon glyphicon-exclamation-sign error-glyph">
                     <div class="error-tooltip">
                        <p *ngIf="form.controls.oorEmail.errors?.required">{{ 'common.FieldIsRequired' | translate }}</p>
                       <p *ngIf="form.controls.oorEmail.errors?.maxlength">{{ 'common.MustBeLessThanChars' | translate: {maxChars: form.controls.name.errors?.maxlength.requiredLength} }}</p>
                       <p *ngIf="form.controls.oorEmail.errors?.multipleEmails">{{ 'common.invalidMultipleEmails'| translate}}</p>
                     </div>
                   </div>
                 </label>
            </div>
          <div class="col-xs-4"></div>
        </div>

        <div class="row tab-panel">
          <tabset [ngClass]="{'hideProfiles': !canDisplayProfilesTab, 'hideMapsets': !mapsetsEnabled}">
            <tab heading="{{'views.vehicle-group-detail.tab.Vehicles' | translate}}">
               <!-- <h4 class="section-header">
                 <span [innerHtml]="'views.vehicle-group-detail.tab.Vehicles' | translate"></span>
                </h4> -->
              <group-associations #vehicleAssociations entityLabel="{{'views.driver-group-detail.Vehicles' | translate}}" [downloadUrl]="downloadUrl"
                [displayPerPage]="10" additionalTableClasses="clickable-row" (itemSelected)="onItemSelected($event)" [readonly]="!vgPermissions.editButtonVisible">
              </group-associations>

            </tab>
            <tab id="assignment-tab" heading="{{'views.vehicle-group-detail.tab.Profiles' | translate}}">
              <div class="assignment-container">

                <div *ngIf = "this.userContext.isConfigurationProfileEnabled()">
                  <h4 class="section-header">
                    <span [innerHtml]="'views.vehicle-group-detail.ConfigurationProfile' | translate"></span>
                  </h4>
                  <div class="row control-row">
                    <label class="col-xs-6">
                    <span [innerHtml]="'views.vehicle-group-detail.ConfigurationProfile' | translate"></span>
                    <ng-select formControlName="configurationProfile" class="custom profile-select" [items]="configurationProfiles" bindLabel="text" bindValue="id" [clearable]="true"></ng-select>
                  </label>
                  </div>
                </div>

                <div *ngIf="this.userContext.isSpeedCoachingEnabled()">
                  <h4 class="section-header" >
                    <span [innerHtml]="'views.vehicle-group-detail.speedCoachingProfile' | translate"></span>
                  </h4>
                  <div class="row control-row">
                    <label class="col-xs-6">
                    <span [innerHtml]="'views.vehicle-group-detail.speedCoachingProfile' | translate"></span>
                    <ng-select formControlName="speedCoachingProfile" class="custom profile-select" [items]="speedCoachingProfiles" bindLabel="text" bindValue="id" [clearable]="true"></ng-select>
                  </label>
                  </div>
                </div>
                <div [hidden] = "!this.userContext.isRoutingProfileEnabled()">
                  <h4 class="section-header">
                    <span [innerHtml]="'views.vehicle-group-detail.VehicleRoutingProfiles' | translate"></span>
                  </h4>

                  <group-associations #routingProfileAssociations entityLabel="Routing Profiles" [downloadUrl]="downloadRoutingProfilesUrl"
                    [displayPerPage]="10" >
                  </group-associations>
                </div>
              </div>
            </tab>

            <tab id="assignment-tab" heading="Mapsets">
              <div class="assignment-container">
                <div class="row">
                  <group-associations #mapsetsAssociations entityLabel="Mapsets" [displayPerPage]="10" >
                  </group-associations>
                </div>
              </div>
            </tab>

            <tab id="assignment-tab" heading="{{'views.vehicle-group-detail.tab.routeModifiers' | translate}}">
              <div class="assignment-container">
                <div class="row">
                  <!-- <h4 class="section-header">
                    <span [innerHtml]="'views.vehicle-group-detail.VehicleRouteModifierSets' | translate"></span>
                  </h4> -->
                  <group-associations #avoidFavorSetAssociations entityLabel="Route Modifier Set" [displayPerPage]="10" >
                  </group-associations>
                </div>
              </div>
            </tab>

            <tab id="assignment-tab" heading="{{'views.vehicle-group-detail.tab.CustomPlaces' | translate}}">
              <div class="assignment-container">
                <div class="row">
                  <!-- <h4 class="section-header">
                    <span [innerHtml]="'views.vehicle-group-detail.VehicleCustomPlaceSets' | translate"></span>
                  </h4> -->
                  <group-associations #customPlaceSetAssociations entityLabel="Custom Place Sets" [displayPerPage]="10" >
                  </group-associations>
                </div>
              </div>
            </tab>

          </tabset>
        </div>

        <div class="modal-footer">

          <button type="button" class="btn btn-default cancel-btn" (click)="cancel()">
              <span [innerHtml]="'common.Cancel' | translate"></span>
            </button>
          <button type="submit" id="save-vehicle-group" class="btn btn-success pull-right" [disabled]="!form.valid">
              <i *ngIf="!isInitializing && !form.valid" class="glyphicon glyphicon-exclamation-sign error-glyph"></i>
              <span [innerHtml]="'common.Save' | translate"></span>
            </button>
        </div>


      </div>
    </div>
  </form>
</fieldset>

<confirmation-modal #confirmModal title="{{ 'views.unsaved-changes-modal.title' | translate}}" body="{{ 'views.unsaved-changes-modal.body' | translate}}"></confirmation-modal>
