
import {switchMap, tap, distinctUntilChanged, debounceTime} from 'rxjs/operators';
// angular components
import { Component, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';

// 3rd party components
import { Observable } from 'rxjs';





import { TranslateService } from '@ngx-translate/core';

import { VehicleGroupService } from './../../vehicle-group/shared';
import { ConfirmationModal, RoutingProfileService } from './../../shared';

@Component({
  providers: [RoutingProfileService, VehicleGroupService],
  templateUrl: './routing-profile-list.component.html'
})
// tslint:disable-next-line: component-class-suffix
export class RoutingProfileList implements OnInit {

  @ViewChild('setDefaultConfirmModal', { static: true }) setDefaultConfirmModal: ConfirmationModal;
  @ViewChild('confirmModal', { static: true }) confirmModal: ConfirmationModal;
  @ViewChild('deleteGroupWarning', { static: true }) deleteGroupWarningModal: ConfirmationModal;

  public numberOfAssignedGroups: number;

  public isProcessing = false;
  public allItems: Array<any> = [];
  public filteredItems: Array<any> = [];
  public sortAsc = true;
  public sortCol = 'name';

  public searchTerm = new FormControl();

  public errorMsg: Observable<string>;

  public currentPage = 0;
  public perPage = 10;
  public pageItems: Array<any> = [];
  public total = 0;

  constructor(
    private routingProfileService: RoutingProfileService,
    private translate: TranslateService,
    private router: Router
  ) { }

  public ngOnInit() {
    this.isProcessing = true;
    this.searchTerm.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => {
        this.isProcessing = true;
        this.currentPage = 0;
      }),
      switchMap(searchTerm => this.getItems(searchTerm)), )
      .subscribe(resp => {
        this.pageItems = resp.items;
        this.total = resp.total;
        this.isProcessing = false;
      });

    this.updateCurrentPage();

  }

  public updateCurrentPage() {
    this.isProcessing = true;

    this.getItems(this.searchTerm.value)
      .subscribe(itemsResponse => {
        this.pageItems = itemsResponse.items;
        this.total = itemsResponse.total;
        this.isProcessing = false;
      }, error => {
        this.isProcessing = false;
      });
  }

  public previousPage() {
    if (!this.hasPreviousPage()) { return; }
    this.currentPage--;

    this.updateCurrentPage();
  }

  public nextPage() {
    if (!this.hasNextPage()) { return; }
    this.currentPage++;

    this.updateCurrentPage();
  }

  public deleteItem(item) {
    if (item.numberOfAssignedGroups === 0) {
      this.showDeleteConfirmationWarning(item);
    } else {
      this.showDeleteGroupWarning(item, item.numberOfAssignedGroups);
    }
  }

  public showDeleteGroupWarning(item: any, numberOfAssignedGroups: number) {
    this.numberOfAssignedGroups = numberOfAssignedGroups;
    this.deleteGroupWarningModal.open()
      .then(() => this.router.navigate(['/routing-profiles', item.routingProfileId]));
  }

  public showDeleteConfirmationWarning(item) {
    this.confirmModal.open()
      .then(() => {
        // tslint:disable-next-line:no-string-literal
        item['isProcessing'] = true;

        this.routingProfileService.deleteRoutingProfile(item.routingProfileId)
          .subscribe(c => {
            // tslint:disable-next-line:no-string-literal
            this.updateCurrentPage();
          }, (error) => {
            // tslint:disable-next-line:no-string-literal
            item['isProcessing'] = false;
            this.errorMsg = this.translate.get('views.routing-profile-list.errors.DeletingItemUnknown');
          });
      });
  }

  public isDefaultToggle(item: any) {

    const selectedElementCurDefaultVal = !item.isAccountWideDefault;

    if (!selectedElementCurDefaultVal) {
      this.translate.get('views.routing-profile-list.UnassignDefaultTitle').subscribe(res => {
        this.setDefaultConfirmModal.title = res;
      });
      this.translate.get('views.routing-profile-list.UnassignDefaultBody').subscribe(res => {
        this.setDefaultConfirmModal.body = res;
      });
    } else {
      this.translate.get('views.routing-profile-list.SetDefaultTitle').subscribe(res => {
        this.setDefaultConfirmModal.title = res;
      });
      this.translate.get('views.routing-profile-list.ConfirmDefaultBody').subscribe(res => {
        this.setDefaultConfirmModal.body = res;
      });
    }

    this.setDefaultConfirmModal.open()
      .then(() => {
        item.isProcessing = true;
        item.isAccountWideDefault = !item.isAccountWideDefault;
        this.routingProfileService.setIsDefaultChanged(item.routingProfileId, item.isAccountWideDefault)
       .subscribe(res => {

        },
        (error) => {
        item.isProcessing = false;
        this.errorMsg = this.translate.get('views.routing-profile-list.errors.SetDefaultProfile');
       });
      });
  }

  public hasNextPage(): boolean {
    const nextPageStartIndex = (this.currentPage + 1) * this.perPage;
    return this.total > nextPageStartIndex;
  }

  public hasPreviousPage(): boolean {
    return this.currentPage > 0;
  }

  public clearSearchTerm() {
    this.searchTerm.setValue('');
  }

  public sortString(column: string, asc: boolean) {
   this.sortCol = column;
   this.sortAsc = asc;
   this.updateCurrentPage();
  }

  public sortNumber(column: string, asc: boolean) {
   this.sortCol = column;
   this.sortAsc = asc;
   this.updateCurrentPage();
  }

  public pageStart() {
    if (this.pageItems.length === 0) { return 0; }

    return (this.currentPage * this.perPage) + 1;
  }

  public pageEnd() {
    // this page currently uses server side paging so the number of items on the current page is in this.items.length
    const retVal = (this.currentPage * this.perPage) + this.pageItems.length;
    return retVal;
  }

  private getItems(searchTerm: string) {
    return this.routingProfileService.getRoutingProfiles(null, null, searchTerm,
     { sortBy: this.sortCol, sortAscending: this.sortAsc, pageNumber: this.currentPage, limit: this.perPage });
  }
}
