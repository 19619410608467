import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';
import { AcceptTermsService } from './accept-terms.service';

@Component({
  selector: 'accept-terms',
  templateUrl: './accept-terms.component.html',
  providers: [AcceptTermsService]
})
// tslint:disable-next-line: component-class-suffix
export class AcceptTerms implements OnInit, OnDestroy {
  redirectUrl: string;
  isProcessing: boolean;
  message: string;

  private sub1: Subscription;

  constructor(private route: ActivatedRoute, private router: Router, private acceptTermsService: AcceptTermsService) { }

  ngOnInit() {

    this.sub1 = this.route.params.subscribe(params => {
      if (params.r) {
        this.redirectUrl = decodeURIComponent(params.r);
      }

      this.redirectUrl = this.redirectUrl || '/';
    });
  }

  ngOnDestroy() {
    this.sub1.unsubscribe();
  }

  acceptTerms() {
    this.isProcessing = true;

    this.acceptTermsService.acceptTerms()
      .subscribe(c => {
        this.router.navigateByUrl(this.redirectUrl);
      },
      (error) => {
        this.isProcessing = false;
        this.message = 'Error while attempting to accept terms of use.';
      });
  }
}
