import * as tslib_1 from "tslib";
import { of as observableOf } from 'rxjs';
import { Router } from '@angular/router';
import { UserContextService } from './../alk-components/authentication';
var AuthGuard = /** @class */ (function () {
    function AuthGuard(auth, router) {
        this.auth = auth;
        this.router = router;
    }
    AuthGuard.prototype.canActivate = function (next, state) {
        // some special roles are required so check if the user has at least 1 of those roles
        var user = this.auth.getUser();
        // if user is not authenticated let them authenticate first, bring them to the login page with a redirect back to this page.
        if (!this.auth.isAuthenticated()) {
            var authParams = { s: 0, r: state.url };
            this.router.navigate(['/login', authParams]);
            return observableOf(false);
        }
        // tslint:disable-next-line:no-string-literal
        var roles = next.data['roles'] || [];
        // user is authenticated at this point, and no special roles are required, return true.
        if (roles.length === 0) {
            return observableOf(true);
        }
        // use rhas at least one of the roles, return true
        if (this.hasAnyRole(user.roles, roles)) {
            return observableOf(true);
        }
        // user does not have at least one of the roles, redirect them to the login page
        // todo: update login page to look at 's' parameter to know what message to appear. 1- user not authorized, 0 - user not authenticated.
        var params = { s: 1 };
        this.auth.clearUser();
        this.router.navigate(['/login', params]);
        return observableOf(false);
    };
    AuthGuard.prototype.hasAnyRole = function (hasRoles, lookForRoles) {
        var e_1, _a, e_2, _b;
        try {
            for (var lookForRoles_1 = tslib_1.__values(lookForRoles), lookForRoles_1_1 = lookForRoles_1.next(); !lookForRoles_1_1.done; lookForRoles_1_1 = lookForRoles_1.next()) {
                var lookForRole = lookForRoles_1_1.value;
                try {
                    for (var hasRoles_1 = (e_2 = void 0, tslib_1.__values(hasRoles)), hasRoles_1_1 = hasRoles_1.next(); !hasRoles_1_1.done; hasRoles_1_1 = hasRoles_1.next()) {
                        var hasRole = hasRoles_1_1.value;
                        // needs to be case insensitive search, in some environments, it's "Support" vs "support"
                        if (hasRole.toUpperCase() === lookForRole.toUpperCase()) {
                            return true;
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (hasRoles_1_1 && !hasRoles_1_1.done && (_b = hasRoles_1.return)) _b.call(hasRoles_1);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (lookForRoles_1_1 && !lookForRoles_1_1.done && (_a = lookForRoles_1.return)) _a.call(lookForRoles_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return false;
    };
    return AuthGuard;
}());
export { AuthGuard };
