<fieldset [disabled]="isProcessing">
  <form [formGroup]="form" (ngSubmit)="onSubmit()" #f="ngForm" novalidate>
    <div class="container">
      <div class="message-panel">
        <!-- <div *ngIf="successMessage" class="alert alert-success alert-dismissible" role="alert">
          <button type="button" class="close" (click)="successMessage=''"><span>&times;</span></button>
          <strong>{{successMessage}}</strong>
        </div>
        <div *ngIf="errorMessage" class="alert alert-danger alert-dismissible" role="alert">
          <button type="button" class="close" (click)="errorMessage=''"><span>&times;</span></button>
          <strong>{{errorMessage}}</strong>
        </div> -->

        <div *ngIf="saved == true" class="alert alert-success" role="alert">
          <strong>Successfully updated</strong>
        </div>
        <div *ngIf="errMsg.length > 0" class="alert alert-danger" role="alert">
          {{ errMsg }}
        </div>
      </div>

      <div class="panel page-panel">
        <div class="row panel action-panel">
          <div class="col-xs-4"></div>
          <div class="col-xs-4">
            <h4 class="text-center">
              <span
                [innerHtml]="'views.user-settings.UserSettings' | translate"
              ></span>
            </h4>
          </div>
          <div class="col-xs-4">
            <div class="pull-right">
              <button
                type="submit"
                class="btn btn-success"
                [disabled]="!form.valid"
              >
                <i
                  *ngIf="!isInitializing && !form.valid"
                  class="glyphicon glyphicon-exclamation-sign error-glyph"
                ></i>
                <span [innerHtml]="'common.Save' | translate"></span>
              </button>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>

        <div class="row user-panel">
          <label
            class="col-xs-4"
            [ngClass]="{ 'has-error': !form.controls.firstName.valid }"
          >
            <span
              [innerHtml]="'views.user-settings.FirstName' | translate"
            ></span>
            <input
              type="input"
              class="form-control"
              formControlName="firstName"
              required
              name="firstName"
              [disabled]="isProcessing"
            />
            <div
              *ngIf="!form.controls.firstName.valid"
              class="glyphicon glyphicon-exclamation-sign error-glyph"
            >
              <div class="error-tooltip">
                <p *ngIf="form.controls.firstName.errors?.required">
                  {{ "validation.required" | translate }}
                </p>
              </div>
            </div>
          </label>

          <label
            class="col-xs-4"
            [ngClass]="{ 'has-error': !form.controls.lastName.valid }"
          >
            <span
              [innerHtml]="'views.user-settings.LastName' | translate"
            ></span>
            <input
              type="input"
              class="form-control"
              formControlName="lastName"
              required
              name="lastName"
              [disabled]="isProcessing"
            />
            <div
              *ngIf="!form.controls.lastName.valid"
              class="glyphicon glyphicon-exclamation-sign error-glyph"
            >
              <div class="error-tooltip">
                <p *ngIf="form.controls.lastName.errors?.required">
                  {{ "validation.required" | translate }}
                </p>
              </div>
            </div>
          </label>

          <label
            class="col-xs-4"
            [ngClass]="{ 'has-error': !form.controls.emailAddress.valid }"
          >
            <span
              [innerHtml]="'views.user-settings.EmailAddress' | translate"
            ></span>
            <input
              type="input"
              class="form-control"
              readonly
              formControlName="emailAddress"
              required
              name="emailAddress"
              [disabled]="isProcessing"
            />
            <div
              *ngIf="!form.controls.emailAddress.valid"
              class="glyphicon glyphicon-exclamation-sign error-glyph"
            >
              <div class="error-tooltip">
                <p
                  *ngIf="
                    form.controls.emailAddress.errors?.alkValidEmailAddress
                  "
                >
                  {{ "common.InvalidEmailAddress" | translate }}
                </p>
              </div>
            </div>
          </label>

          <!--  hiding language select until more languages are available -->
          <label class="col-xs-4" [hidden]="true">
            <span
              [innerHtml]="'views.user-settings.Language' | translate"
            ></span>
            <alk-language-select></alk-language-select>
          </label>
        </div>
      </div>
    </div>
  </form>
</fieldset>

<confirmation-modal
  #confirmModal
  title="{{ 'views.unsaved-changes-modal.title' | translate }}"
  body="{{ 'views.unsaved-changes-modal.body' | translate }}"
></confirmation-modal>
