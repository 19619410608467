<div class="marketing-page login-page login-fleetview">
  <div class="top-section fleetlink-top-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-10">
          <img
            src="/assets/img/solera-nav-logo.svg"
            width="230"
            height="50"
            alt="FleetPortal"
            class="page-logo"
          />
        </div>
        <div class="col-sm-2 text-right form-group">
          <alk-language-select></alk-language-select>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 trim-padding" style="padding-top: 30px; padding-bottom: 130px;">
          <div class="row">
            <h1 class="with-icon">
              <span class="landing-app-icon">
                <img src="/assets/img/cloud-menu-fleetportal.svg" alt="" />
              </span>
              <p class="app-icon-text">
                Remotely configure and manage mobile application settings.
              </p>
            </h1>
          </div>
          <p class="subhead bullet">
            <span class="ico ico-check-circle-o"></span>Assign vehicle and
            routing profiles from the back office
          </p>
          <p class="subhead bullet">
            <span class="ico ico-check-circle-o"></span>Reduce manual entry
            errors and create operational consistency
          </p>
          <p class="availability">Ideal for fleets of 50 trucks or more</p>
        </div>
        <div class="col-sm-5 col-sm-offset-1 trim-padding">
          <div class="login-box">
            <div class="login-form" [hidden]="formTab !== 'login'">
              <p class="login-title">
                {{ "common.landing.loginTitle" | translate }}
              </p>
              <p
                class="login-subhead visible-xs-block"
                translate="{{ 'common.landing.mobileLoginMsg' }}"
                translate-values='{productName: "CoPilot FleetPortal"}'
              ></p>
              <login></login>
            </div>
            <div class="login-tabs">
              <div
                class="col-xs-12 login-tab"
                [ngClass]="{ active: formTab === 'login' }"
                (click)="formTab = 'login'"
              >
                {{ "common.login.signIn" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="landing-support-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 col-sm-offset-2 text-center">
          <p class="subhead">
            <img
              src="/assets/img/landing-support-center.svg"
              class="landing-support-icon"
            /><br />
            <strong>We're Here to Help</strong><br />
            We have various ways to help our customers get quick, easy answers
            to common questions and technical support needs<br />
            <a
              class="btn btn-primary support-button"
              href="https://kb.omnitracs.com/Omnitracs_One/CoPilot/"
              rel="noopener noreferrer"
              target="_blank"
              >Support Center</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
  <marketing-footer></marketing-footer>
</div>
