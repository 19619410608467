// angular components
import { Component, OnInit, OnDestroy, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

// 3rd party components
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { ModalDirective } from 'ngx-bootstrap';

// app components
import { VehicleService, Vehicle } from './../../vehicle';

@Component({
  selector: 'partner-account-new-modal',
  providers: [VehicleService],
  templateUrl: './partner-account-new-modal.component.html'
})
// tslint:disable-next-line: component-class-suffix
export class PartnerAccountNewModal implements OnInit, OnDestroy {
  vehicleId: number;
  vehicle: Vehicle;
  addMode = false; // if false we are in new mode
  isProcessing = false;
  submitted = false;
  roles: Array<any> = [];
  selectedRole: Array<any> = [];
  emailErrorMsg = '';
  errorMsg = '';
  form: FormGroup;

  @ViewChild('modal', { static: true }) modal: ModalDirective;
  @Output() vehicleChanged = new EventEmitter();

  private sub1: Subscription;
  public currentAccountId: number;
  public accountName = '';

  constructor(
    private vehicleService: VehicleService,
    private fb: FormBuilder,
    private el: ElementRef,
    private ag2analytics: Angulartics2GoogleAnalytics) {
    this.vehicle = { name: '', vehicleId: '' };
    this.form = this.fb.group({
      name: [this.vehicle.name, [Validators.required, Validators.minLength(1), Validators.maxLength(320)]],
      vehicleId: [this.vehicle.vehicleId, [Validators.required, Validators.minLength(1), Validators.maxLength(320)]]
    });
  }

  ngOnInit() {

    this.sub1 = this.modal.onShown.subscribe(() => {
      const nativeElement = this.el.nativeElement.getElementsByTagName('input')[this.addMode ? 0 : 1];
      if (nativeElement) { nativeElement.focus(); }
    });
  }

  ngOnDestroy() {
    this.sub1.unsubscribe();
  }

  open(accountId, accountName) {
    this.currentAccountId = accountId;
    // better way?  should we be destroying this on modal close instead of hide?
    this.accountName = accountName;
    this.errorMsg = '';
    this.emailErrorMsg = '';
    this.isProcessing = false;

    this.ag2analytics.eventTrack(`Vehicle Add`, {});
    this.modal.show();
    this.vehicle = { name: '', vehicleId: '' };
    this.updateControlValues();
  }

  save() {
    this.submitted = true;
    // clear error and success messages when saving
    this.errorMsg = null;
    if (!this.form.valid) {
      return;
    }

    if (!this.form.valid) {
      this.ag2analytics.eventTrack('Vehicle new save attempted', { failReason: 'Invalid input' });
      return;
    }

    this.vehicle.name = this.form.value.name;
    this.vehicle.vehicleId = this.form.value.vehicleId;
    this.isProcessing = true;

    this.vehicleService.createVehicleByAccount(this.vehicle, this.currentAccountId)
       .subscribe( resp => {
      this.modal.hide();
      this.vehicleChanged.emit({
        id: this.currentAccountId,
        name: this.accountName
      });
    }, (err) => {
      this.isProcessing = false;
      let errMsg = '';
      if (err.status === 409) {
        let duplicateOf = 'value';
        if (JSON.parse(err._body).message.toUpperCase() === ('DUPLICATE SCREENNAME')) {
          duplicateOf = 'name';
        } else if (JSON.parse(err._body).message.toUpperCase() === ('DUPLICATE VEHICLE ID')) {
          duplicateOf = 'id';
             }
        errMsg = `A vehicle with this ${duplicateOf} already exists.`;
      } else {
        errMsg = `An error occurred adding the vehicle: ` + err;
      }
      this.ag2analytics.eventTrack('Vehicle create error', { errMsg });
      this.errorMsg = errMsg;
    });
  }

  private updateControlValues() {
    const controlName = this.form.controls.name as FormControl;
    controlName.setValue(this.vehicle.name);
    const controlVehicleId = this.form.controls.vehicleId as FormControl;
    controlVehicleId.setValue(this.vehicle.vehicleId);
  }

}
