/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./logout.component";
import * as i2 from "../../../authentication/authentication.service";
import * as i3 from "../../tid.service";
import * as i4 from "@angular/router";
var styles_LogoutComponent = [];
var RenderType_LogoutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LogoutComponent, data: {} });
export { RenderType_LogoutComponent as RenderType_LogoutComponent };
export function View_LogoutComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "card shadow-1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "text-center p-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "div", [["class", "spinner-border"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "h3 text-primary mt-3 mb-0"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Logging Out..."]))], null, null); }
export function View_LogoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_LogoutComponent_0, RenderType_LogoutComponent)), i0.ɵdid(1, 114688, null, 0, i1.LogoutComponent, [i2.AuthenticationService, i3.TidService, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogoutComponentNgFactory = i0.ɵccf("ng-component", i1.LogoutComponent, View_LogoutComponent_Host_0, {}, {}, []);
export { LogoutComponentNgFactory as LogoutComponentNgFactory };
