<div class="container with-nav terms-page">
  <div class="panel page-panel">
    <div class="row">
      <div class="col-xs-12">
        <p style="margin-top: 10px">FleetPortal is not supported on the Azure Cloud and your account is setup to use Azure.
          Please talk to support to make sure your account is setup correctly.</p>
      </div>
    </div>
  </div>
</div>
