
import {throwError as observableThrowError } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
// angular components
import { Injectable } from '@angular/core';

// alk common components
import { UserContextService } from './../../../alk-components/authentication';

// alk app components
import { PagingService } from './../../shared';

// shared components
import { GroupsUtil } from './../../../app/shared/utils/groupsUtil';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

export interface VehicleWithId {
  name: string;
  vehicleId: string;
  id: number;
}

export interface Vehicle {
  name: string;
  vehicleId: string;
  vehicleGroups?: VehicleGroup[];
}

export interface VehicleGroup {
  name: string;
}

@Injectable()
export class VehicleService {

  constructor(private http: HttpClient,
              private userContext: UserContextService,
              private paging: PagingService) { }

  getVehicle(vehicleId: number) {
    const url = environment.apiUrl + '/v1/vehicles/' + vehicleId;

    return this.http.get<any>(url);
  }

  public getCoPilotRequestDetails(vehicleId: any) {
    const url = `${environment.apiUrl}/v1/coPilotRequest/${vehicleId}`;

    return this.http.get<any>(url).pipe(
      catchError((err) => {
        return observableThrowError({ status: err.status, resp: err._body });
      }), );
  }

  public getRequestBodyById(requestId: number) {
    const url = `${environment.apiUrl}/v1/coPilotRequest/RequestBody/${requestId}`;

    return this.http.get<any>(url).pipe(
      catchError((err) => {
        return observableThrowError({ status: err.status, resp: err._body });
      }), );
  }

  moveVehicles(vehicles: Array<VehicleWithId>, accountId: number) {
    const url = `${environment.apiUrl}/v1/vehicles/move/${accountId}`;
    const body = JSON.stringify(vehicles);

    return this.http.put(url, body).pipe(
      map(result => {
        return { status: 200, resp: result };
      }),
      catchError((err) => {
        return observableThrowError({ status: err.status, resp: err.error });
      }), );
  }

  getVehiclesByAccount(accountId: number, paging?: any) {
    let url = `${environment.apiUrl}/v1/accounts/${accountId}/vehicles?`;
    const params = [];
    if (paging) {
      params.push(this.paging.getPagingQuery(paging));
    }
    url += params.join('&');
    return this.http.get<any>(url, { observe: 'response' }).map(resp => {
        const total = +resp.headers.get('X-Total-Count');
        return { total, items: resp.body };
      });
  }

  getVehicleList(activeOnly = false) {
    const url = environment.apiUrl + `/v1/vehiclelist?activeOnly=${activeOnly}`;
    return this.http.get<any>(url).map(data => {
      const items = data.map(vehicle => {
        vehicle.vehicleGroupsAbbreviated = 'Loading...';
        return vehicle;
      });
      return { total: items.length, items };
    });

  }

  getVehicles(includeGroupId?: number, excludeGroupId?: number, searchTerm?: string, paging?: any, activeOnly = false) {
    let url = environment.apiUrl + '/v1/vehicles?';

    const params = [];
    if (paging) {
      params.push(this.paging.getPagingQuery(paging));
    }

    if (searchTerm) {
      params.push('searchTerm=' + encodeURIComponent(searchTerm));
    }

    if (includeGroupId) {
      params.push('includeGroupId=' + includeGroupId);
    }

    if (excludeGroupId) {
      params.push('excludeGroupId=' + excludeGroupId);
    }

    params.push(`activeOnly=${activeOnly}`);

    url += params.join('&');
    const vehicles: any[] = [];
    const maxGroupsToDisplay = 2;

    return this.http.get<any>(url).map(responses => {
        responses.forEach(resp => {
          resp.vehicleGroupsAbbreviated = (resp.vehicleGroups.length > 0) ?
          GroupsUtil.getAbbreviatedGroupsName(resp.vehicleGroups, maxGroupsToDisplay) : '';
          vehicles.push(resp);
        });
        return { total: vehicles.length, items: vehicles };
      });
  }

  getVehicleDetails(vehicleId: number) {
    const url = `${environment.apiUrl}/v1/vehicles/${vehicleId}`;

    return this.http.get(url);
  }

  getExportUrl(groupId?: number, passDateTimeOffSet?: boolean) {
    const param = [];
    param.push(`authToken=${this.userContext.getUser().authToken}`);

    if (this.userContext.getUser().currentPartnerAccountId) {
      param.push(`targetAccount=${this.userContext.getUser().currentPartnerAccountId}`);
    }

    if (groupId > 0) {
      param.push(`groupId=` + groupId);
    }

    const jan = new Date((new Date()).getFullYear(), 0, 1);
    const jul = new Date((new Date()).getFullYear(), 6, 1);
    const offSet = Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());

    if (passDateTimeOffSet) {
      param.push(`dateTimeOffSetInMinutes=` + offSet);
    }

    const url = `${environment.apiUrl}/v1/vehicles/export?` + param.join('&');

    return url;
  }

  deleteVehicle(vehicleId: number) {
    const url = `${environment.apiUrl}/v1/vehicles/${vehicleId}`;
    return this.http.delete(url);
  }

  updateVehicle(vehicleId: number, vehicleDetails) {
    const url = `${environment.apiUrl}/v1/vehicles/${vehicleId}`;
    const body = JSON.stringify(vehicleDetails);

    return this.http.put(url, body).pipe(
      catchError(error => {
        return observableThrowError(error);
      }));
  }

  createVehicle(vehicleDetails) {
    const url = `${environment.apiUrl}/v1/vehicles`;
    const body = JSON.stringify(vehicleDetails);

    return this.http.post(url, body, { observe: 'response' }).pipe(
      map(resp => {
        const modifiedOn = +resp.headers.get('X-LastModified');
        vehicleDetails.modifiedOn = modifiedOn;

        const location = resp.headers.get('Location');

        const idRegex = /\/v1\/vehicles\/([0-9].*)/g;
        const matches = idRegex.exec(location);

        if (matches.length > 1) { vehicleDetails.id = +matches[1]; }

        return vehicleDetails;

      }), catchError(error => {
        return observableThrowError(error);
      }), );
  }

  createVehicleByAccount(vehicleDetails, accountId) {
    const url = `${environment.apiUrl}/v1/accounts/${accountId}/vehicle`;
    const body = JSON.stringify(vehicleDetails);

    return this.http.post(url, body, { observe: 'response' }).pipe(
      map(resp => {
        const modifiedOn = +resp.headers.get('X-LastModified');
        vehicleDetails.modifiedOn = modifiedOn;

        const location = resp.headers.get('Location');

        const idRegex = /\/v1\/vehicles\/([0-9].*)/g;
        const matches = idRegex.exec(location);

        if (matches.length > 1) { vehicleDetails.id = +matches[1]; }

        return vehicleDetails;

      }), catchError(error => {
        return observableThrowError(error);
      }), );
  }

  import(vehicles: Array<Vehicle>) {
    const url = `${environment.apiUrl}/v1/vehicles/import?validateOnly=false`;
    const body = JSON.stringify(vehicles);

    return this.http.post<any>(url, body).pipe(
      map(result => {
        return { status: 200, resp: result};
      }),
      catchError((err) => {
        return observableThrowError({ status: err.status, resp: err.error });
      }), );
  }

}
