// angular components
import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

// 3rd party components
import { Subscription ,  Observable } from 'rxjs';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { ModalDirective } from 'ngx-bootstrap';
import { TranslateService } from '@ngx-translate/core';

// app components
import { AccountUserManagementService, UserInAccount } from './../../../alk-components/account-user-management';
import { alkValidEmailAddress } from './../../../alk-components/common-validation';

@Component({
  selector: 'admin-user-replace-modal',
  providers: [AccountUserManagementService],
  templateUrl: './admin-user-replace-modal.component.html'
})
// tslint:disable-next-line: component-class-suffix
export class AdminUserReplaceModal implements OnInit, OnDestroy {
  isProcessing = false;
  submitted = false;
  errorMsg: Observable<string>;
  showSubmittedSuccess = false;
  form: FormGroup;

  @Input() oldUser: UserInAccount;
  @ViewChild('modal', { static: true }) modal: ModalDirective;
  @Output() userChanged = new EventEmitter();

  private sub1: Subscription;

  constructor(
    private accountUserService: AccountUserManagementService,
    private el: ElementRef,
    private fb: FormBuilder,
    private translate: TranslateService,
    private ag2analytics: Angulartics2GoogleAnalytics) {
    this.oldUser = new UserInAccount();
    this.form = this.fb.group({
      firstName: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(64)]],
      lastName: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(64)]],
      emailAddress: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(64), alkValidEmailAddress]]
    });
  }

  ngOnInit() {
    this.sub1 = this.modal.onShown.subscribe(() => {
      const nativeElement = this.el.nativeElement.getElementsByTagName('input')[0];
      if (nativeElement) { nativeElement.focus(); }
    });
  }

  ngOnDestroy() {
    this.sub1.unsubscribe();
  }

  loadForm(attributes) {
    for (const attr in attributes) {
      if (!attributes.hasOwnProperty(attr)) { continue; }

      const control =  this.form.controls[attr] as FormControl;
      if (!control) { continue; }

      control.setValue(attributes[attr]);
    }
  }

  open() {
    // clear out any input from previous usage
    this.loadForm({ firstName: '', lastName: '', emailAddress: '' });
    this.errorMsg = null;

    this.ag2analytics.eventTrack('Replace User dialog opened', {});
    this.modal.show();
  }

  save() {
    this.submitted = true;
    this.errorMsg = null;

    if (!this.form.valid) {
      this.ag2analytics.eventTrack('Replace User attempted', { failReason: 'Invalid input' });
      return;
    }

    this.isProcessing = true;

    this.accountUserService.replaceUser(this.oldUser.userId, this.form.value)
      .subscribe((data) => {
        this.submitted = false;

        this.ag2analytics.eventTrack('Replace User successful', {
          oldUserName: `${this.oldUser.firstName} ${this.oldUser.lastName}`,
          oldUserEmail: this.oldUser.emailAddress,
          newUserName: `${this.form.value.firstName} ${this.form.value.lastName}`,
          newUserEmailAddress: this.form.value.emailAddress
        });

        this.showSubmittedSuccess = true;
        setTimeout(() => {
          this.isProcessing = false;
          this.showSubmittedSuccess = false;
          this.modal.hide();
          this.userChanged.emit(this.form.value);
        }, 2500);

      }, (err) => {
        if (err.status === 409) {
          this.errorMsg = this.translate.get('views.admin-users-replace.errors.AlreadyExists');
        } else {
          this.errorMsg = this.translate.get('views.admin-users-replace.errors.Unknown');
        }

        this.isProcessing = false;
      });
  }
}
