export class Utils {

  // return array of string values, or NULL if CSV string not well formed.
  // this is needed if fields in csv's have comma's, they need to be wrapped in quotes to be valid
  // http://stackoverflow.com/questions/8493195/how-can-i-parse-a-csv-string-with-javascript-which-contains-comma-in-data
  static csvToArray(text: string): Array<string> {

    // Quick hack to fix single quote error: replace it with a temp string here
    text = text.replace(/'/g, '<singlequote>');

    // tslint:disable-next-line:max-line-length
    const reValid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
    // tslint:disable-next-line
    const reValue = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
    // Return NULL if input string is not well formed CSV string.
    if (!reValid.test(text)) { return null; }
    const a = [];                     // Initialize array to receive values.
    text.replace(reValue, // "Walk" the string using replace with callback.
        (m0, m1, m2, m3) => {
            // Remove backslash from \' in single quoted values.
            if (m1 !== undefined) {
              a.push(m1.replace(/\\'/g, '\''));
            } else if (m2 !== undefined) {
              a.push(m2.replace(/\\"/g, '"'));
            } else if (m3 !== undefined) {
              a.push(m3);
            }
            return ''; // Return empty string.
        });
    // Handle special case of empty last value.
    if (/,\s*$/.test(text)) { a.push(''); }

    // Part 2 of quick hack: add the single quotes back in
    for (let i = 0; i < a.length; i++) {
      a[i] = a[i].replace(/<singlequote>/g, '\'');
    }
    return a;
  }

  // if a value for a field in a csv contains a comma, it needs to be wrapped in quotes to be valid CSV syntax
  static csvSafeString(text: any): string {
    if (text && text.includes(',')) {
      text = `"${text}"`;
    }
    return text;
  }

}
