/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./alk-copyright.component";
var styles_ALKCopyright = [];
var RenderType_ALKCopyright = i0.ɵcrt({ encapsulation: 2, styles: styles_ALKCopyright, data: {} });
export { RenderType_ALKCopyright as RenderType_ALKCopyright };
export function View_ALKCopyright_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [["class", "copyright-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Copyright \u00A9 "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "a", [["href", "https://www.trimble.com/en/about"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Trimble Inc."])), (_l()(), i0.ɵted(4, null, [" 1986-", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentYear; _ck(_v, 4, 0, currVal_0); }); }
export function View_ALKCopyright_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "alk-copyright", [], null, null, null, View_ALKCopyright_0, RenderType_ALKCopyright)), i0.ɵdid(1, 49152, null, 0, i1.ALKCopyright, [], null, null)], null, null); }
var ALKCopyrightNgFactory = i0.ɵccf("alk-copyright", i1.ALKCopyright, View_ALKCopyright_Host_0, {}, {}, []);
export { ALKCopyrightNgFactory as ALKCopyrightNgFactory };
