/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./azure.component";
var styles_AzureComponent = [];
var RenderType_AzureComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AzureComponent, data: {} });
export { RenderType_AzureComponent as RenderType_AzureComponent };
export function View_AzureComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "container with-nav terms-page"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "panel page-panel"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "col-xs-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "p", [["style", "margin-top: 10px"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["FleetPortal is not supported on the Azure Cloud and your account is setup to use Azure. Please talk to support to make sure your account is setup correctly."]))], null, null); }
export function View_AzureComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "azure", [], null, null, null, View_AzureComponent_0, RenderType_AzureComponent)), i0.ɵdid(1, 49152, null, 0, i1.AzureComponent, [], null, null)], null, null); }
var AzureComponentNgFactory = i0.ɵccf("azure", i1.AzureComponent, View_AzureComponent_Host_0, {}, {}, []);
export { AzureComponentNgFactory as AzureComponentNgFactory };
