import { Component } from '@angular/core';

@Component({
  selector: 'alk-copyright',
  // tslint:disable-next-line: max-line-length
  template: '<span class="copyright-text">Copyright © <a href="https://www.trimble.com/en/about">Trimble Inc.</a> 1986-{{currentYear}}</span>'
})
// tslint:disable-next-line: component-class-suffix
export class ALKCopyright  {
  public currentYear = new Date().getFullYear();
}
