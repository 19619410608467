import { RouterModule } from '@angular/router';
import { Admin } from './admin';
import { AuthGuard } from './auth.guard';
import { AvoidFavorSetDetail, AvoidFavorSetList } from './avoid-favor-sets';
import { CanDeactivateGuard } from './can-deactivate-guard.service';
import { ConfigurationProfileDetail, ConfigurationProfileList } from './configuration-profile';
import { CustomPlaceSetDetail, CustomPlaceSetList } from './custom-place-sets';
import { DriverImport } from './driver';
import { DriverGroupDetail, DriverGroupList } from './driver-group';
// app components
import { Landing } from './landing';
import { ConnectionSettingsComponent } from './mapsets/connection-settings/connection-settings.component';
import { MapsetsDetailComponent } from './mapsets/mapsets-detail/mapsets-detail.component';
import { MapsetsDetailGuardService } from './mapsets/mapsets-detail/mapsets-detail.guard.service';
import { MapsetsListComponent } from './mapsets/mapsets-list/mapsets-list.component';
import { PartnerAccountList } from './partner-account-selector/partner-account-list';
import { RoadSpeedsProfile } from './road-speeds';
import { RoutingProfileDetail, RoutingProfileList } from './routing-profile';
import { LogoutComponent } from './shared/tid/components/logout/logout.component';
import { SpeedCoachingDetail, SpeedCoachingList } from './speed-coaching';
import { AcceptTerms, ReadTerms } from './terms';
import { TOSGuard } from './tos.guard';
import { UserSettings } from './user-settings';
import { VehicleImport } from './vehicle';
import { VehicleGroupDetail, VehicleGroupList } from './vehicle-group';
import { VehicleSummary } from './vehicle-summary';
import { AzureComponent } from './azure';
import { AzureGuard } from './azure.guard';
var ɵ0 = { title: 'views.user-settings.Title' }, ɵ1 = { title: 'views.read-terms.Title' }, ɵ2 = { title: 'views.accept-terms.Title' }, ɵ3 = { title: 'views.azure.Title' }, ɵ4 = { title: 'views.login.Title' }, ɵ5 = { title: 'views.routing-profile-list.Title' }, ɵ6 = { title: 'views.routing-profile-detail.Title' }, ɵ7 = { title: 'views.configuration-profile-list.Title' }, ɵ8 = { title: 'views.configuration-profile-detail.Title' }, ɵ9 = { title: 'views.speed-coaching-list.Title' }, ɵ10 = { title: 'views.speed-coaching-detail.Title' }, ɵ11 = { title: 'views.road-speeds.Title' }, ɵ12 = { title: 'views.vehicle-group-list.Title' }, ɵ13 = { title: 'views.vehicle-group-detail.Title' }, ɵ14 = { title: 'views.vehicle-summary.Title' }, ɵ15 = { title: 'views.driver-group-list.Title' }, ɵ16 = { title: 'views.driver-group-detail.Title' }, ɵ17 = { title: 'views.partner-account-list.Title' }, ɵ18 = { title: 'views.avoid-favorSet-detail.Title' }, ɵ19 = { title: 'views.avoid-favorSet-detail.Title' }, ɵ20 = { title: 'views.custom-placeSet-list.Title' }, ɵ21 = { title: 'views.custom-placeSet-detail.Title' }, ɵ22 = { title: 'views.header.Mapsets' }, ɵ23 = { title: 'views.mapsets-connection-settings.ConnectionSettings' }, ɵ24 = { title: 'views.mapsets.EditMapset' }, ɵ25 = { title: 'views.mapsets.EditMapset', justCreated: true }, ɵ26 = { title: 'views.mapsets.CreateMapset' }, ɵ27 = { roles: ['Company Administrator', 'ALK Administrator', 'ALK Support', 'Support'], title: 'views.account-management.Title' }, ɵ28 = { roles: ['Company Administrator', 'ALK Administrator', 'ALK Support', 'Support'], title: 'views.vehicle-import.Title' }, ɵ29 = { roles: ['Company Administrator', 'ALK Administrator', 'ALK Support', 'Support'], title: 'views.driver-import.Title' };
var routes = [
    {
        path: 'user', component: UserSettings, canActivate: [AuthGuard, TOSGuard],
        canDeactivate: [CanDeactivateGuard],
        data: ɵ0
    },
    {
        path: 'terms', component: ReadTerms,
        data: ɵ1
    },
    {
        path: 'accept-terms', component: AcceptTerms, canActivate: [AuthGuard],
        data: ɵ2
    },
    {
        path: 'azure', component: AzureComponent, canActivate: [AuthGuard, AzureGuard],
        data: ɵ3
    },
    {
        path: 'login', component: Landing,
        data: ɵ4
    },
    {
        path: 'logout', component: LogoutComponent
    },
    // routing profiles
    {
        path: 'routing-profiles', component: RoutingProfileList, canActivate: [AuthGuard, TOSGuard],
        data: ɵ5
    },
    {
        path: 'routing-profiles/:id', component: RoutingProfileDetail, canActivate: [AuthGuard, TOSGuard],
        canDeactivate: [CanDeactivateGuard],
        data: ɵ6
    },
    // configuration profiles
    {
        path: 'configuration-profiles', component: ConfigurationProfileList, canActivate: [AuthGuard, TOSGuard],
        data: ɵ7
    },
    {
        path: 'configuration-profiles/:id', component: ConfigurationProfileDetail, canActivate: [AuthGuard, TOSGuard],
        canDeactivate: [CanDeactivateGuard],
        data: ɵ8
    },
    // speed coaching profiles
    {
        path: 'speed-coaching', component: SpeedCoachingList, canActivate: [AuthGuard, TOSGuard],
        data: ɵ9
    },
    {
        path: 'speed-coaching/:id', component: SpeedCoachingDetail, canActivate: [AuthGuard, TOSGuard],
        canDeactivate: [CanDeactivateGuard],
        data: ɵ10
    },
    // road speeds
    {
        path: 'road-speeds', component: RoadSpeedsProfile, canActivate: [AuthGuard, TOSGuard],
        canDeactivate: [CanDeactivateGuard],
        data: ɵ11
    },
    // vehicle groups
    {
        path: 'vehicle-groups', component: VehicleGroupList, canActivate: [AuthGuard, TOSGuard],
        data: ɵ12
    },
    {
        path: 'vehicle-groups/:id', component: VehicleGroupDetail, canActivate: [AuthGuard, TOSGuard],
        canDeactivate: [CanDeactivateGuard],
        data: ɵ13
    },
    {
        path: 'vehicle/:id', component: VehicleSummary, canActivate: [AuthGuard, TOSGuard],
        data: ɵ14
    },
    // driver groups
    {
        path: 'driver-groups', component: DriverGroupList, canActivate: [AuthGuard, TOSGuard],
        data: ɵ15
    },
    {
        path: 'driver-groups/:id', component: DriverGroupDetail, canActivate: [AuthGuard, TOSGuard],
        canDeactivate: [CanDeactivateGuard],
        data: ɵ16
    },
    // partner section
    {
        path: 'partnerAccounts', component: PartnerAccountList, canActivate: [AuthGuard, TOSGuard],
        data: ɵ17
    },
    // avoid-favor-set section
    {
        path: 'avoidFavorSets', component: AvoidFavorSetList, canActivate: [AuthGuard, TOSGuard],
        data: ɵ18
    },
    {
        path: 'avoidFavorSets/:id', component: AvoidFavorSetDetail, canActivate: [AuthGuard, TOSGuard],
        canDeactivate: [CanDeactivateGuard],
        data: ɵ19
    },
    // custom-place-set section
    {
        path: 'customPlaceSets', component: CustomPlaceSetList, canActivate: [AuthGuard, TOSGuard],
        data: ɵ20
    },
    {
        path: 'customPlaceSets/:id', component: CustomPlaceSetDetail, canActivate: [AuthGuard, TOSGuard],
        canDeactivate: [CanDeactivateGuard],
        data: ɵ21
    },
    // mapsets section
    {
        path: 'mapsets-list', component: MapsetsListComponent, canActivate: [AuthGuard, TOSGuard],
        data: ɵ22
    },
    {
        path: 'connection-settings', component: ConnectionSettingsComponent, canActivate: [AuthGuard, TOSGuard],
        data: ɵ23
    },
    {
        path: 'mapsets/:id', component: MapsetsDetailComponent, canActivate: [AuthGuard, TOSGuard],
        canDeactivate: [MapsetsDetailGuardService], data: ɵ24
    },
    {
        path: 'mapsets/created/:id', component: MapsetsDetailComponent, canActivate: [AuthGuard, TOSGuard],
        canDeactivate: [MapsetsDetailGuardService], data: ɵ25
    },
    {
        path: 'mapsets/create', component: MapsetsDetailComponent, canActivate: [AuthGuard, TOSGuard],
        canDeactivate: [MapsetsDetailGuardService], data: ɵ26
    },
    // admin section
    {
        path: 'admin', component: Admin, canActivate: [AuthGuard, TOSGuard],
        data: ɵ27
    },
    // if anyone removes these paths, please clean up all the related components and all the references
    // {
    //   path: 'admin/integration', component: AdminIntegration, canActivate: [AuthGuard, TOSGuard],
    //   data: { roles: ['Company Administrator', 'ALK Administrator', 'ALK Support', 'Support'], title: 'views.admin-integration.Title' }
    // },
    // {
    //   path: 'admin/users', component: AdminUsers, canActivate: [AuthGuard, TOSGuard],
    //   data: { roles: ['Company Administrator', 'ALK Administrator', 'ALK Support', 'Support'], title: 'views.admin-users.Title' }
    // },
    {
        path: 'vehicles/import', component: VehicleImport, canActivate: [AuthGuard, TOSGuard],
        data: ɵ28
    },
    {
        path: 'drivers/import', component: DriverImport, canActivate: [AuthGuard, TOSGuard],
        data: ɵ29
    },
    { path: '', redirectTo: 'vehicle-groups', pathMatch: 'full' },
    { path: '**', redirectTo: 'vehicle-groups' },
];
export var appRoutingProviders = [
    CanDeactivateGuard,
    TOSGuard,
    AuthGuard,
    AzureGuard
];
export var routing = RouterModule.forRoot(routes);
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28, ɵ29 };
