<fieldset [disabled]="isProcessing || isInitializing">
  <form [formGroup]="form" (ngSubmit)="save()" novalidate>
    <div class="container">

      <div class="message-panel">
        <div *ngIf="(successMessage | async)" class="alert alert-success alert-dismissible" role="alert">
          <button type="button" class="close" (click)="successMessage=null"><span>&times;</span></button>
          <strong>{{successMessage | async}}</strong>
        </div>
        <div *ngIf="(errorMessage | async)" class="alert alert-danger alert-dismissible" role="alert">
          <button type="button" class="close" (click)="errorMessage=null"><span>&times;</span></button>
          <strong>{{errorMessage | async}}</strong>
        </div>
      </div>

      <div class="panel page-panel">
        <div class="row panel action-panel">
          <div class="col-xs-12">
            <h4 class="text-center">
              <span [innerHtml]="'views.speed-coaching-detail.Title' | translate"></span>
              <span style="font-size:13px;" *ngIf="isInitializing">({{'common.Loading' | translate}})</span>
            </h4>
          </div>
        </div>

        <div class="row header-panel">
          <label class="col-md-6" [ngClass]="{'has-error': !isInitializing && !form.controls.name.valid }">
            <span [innerHtml]="'views.speed-coaching-detail.form.Name' | translate"></span>
            <input type="text" class="form-control input-sm" formControlName="name" />
            <div *ngIf="!isInitializing && (!form.controls.name.valid)" class="glyphicon glyphicon-exclamation-sign error-glyph">
              <div class="error-tooltip">
                <p *ngIf="form.controls.name.errors?.required">{{ 'common.FieldIsRequired' | translate }}</p>
                <p *ngIf="form.controls.name.errors?.maxlength">{{ 'common.MustBeLessThanChars' | translate: {maxChars: form.controls.name.errors?.maxlength.requiredLength} }}</p>
                <p *ngIf="form.controls.name.errors?.minlength">{{ 'common.MustBeMoreThanChars' | translate:{minchars: form.controls.name.errors?.minlength.requiredLength} }}</p>
              </div>
            </div>
          </label>

          <div class="col-md-6">
            <div class="pull-right">
              <span (click)="isDefaultToggle()">
                <span *ngIf="!form.value.isDefault" class="ico ico-checkbox-unchecked" style="position:absolute;margin-left:-20px;margin-top:3px;"></span>
                <span *ngIf="form.value.isDefault" class="ico ico-checkbox-checked" style="position:absolute;margin-left:-20px;margin-top:3px;"></span>
              </span>
              <label>
                <span [innerHtml]="'views.customdata-profile-detail.form.IsDefault' | translate"></span>
              </label>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>

        <div class="row tab-panel">
          <tabset>
            <tab heading="{{'views.speed-coaching-detail.tab.Options' | translate}}">
              <div class="assignment-container">
                <form [formGroup]="form" (ngSubmit)="save()" novalidate>
                  <div class="">
                    <h4 class="section-header">
                      <span [innerHtml]="'views.speed-coaching-detail.warnings' | translate"></span>
                    </h4>
                    <div class="row control-row">
                      <label class="col-xs-3" [ngClass]="{'has-error': !form.controls.warningSpeedLimit.valid }">
                        <span>{{'views.speed-coaching-detail.overSpeedThreshold' | translate}} {{(unitType === 'English' ? 'views.speed-coaching-profile-detail.reference.mph' : 'views.speed-coaching-profile-detail.reference.kph') | translate}} <i class="glyphicon glyphicon-question-sign" data-container="body" [tooltip]="'views.speed-coaching-detail.tooltip.WarningSpeedLimit' | translate:{speedUnit:speedUnit}"></i></span>
                        <input type="number" min="1" max="30" step="1" class="form-control input-sm" formControlName="warningSpeedLimit" />
                        <i *ngIf="showUnitConversionTooltip" style = "margin-left:255px; margin-top:20px;"class="glyphicon glyphicon-info-sign" data-container="body" [tooltip]="showWarningSpeedLimitConversionInfo()"></i>
                        <div *ngIf="(!form.controls.warningSpeedLimit.valid)" class="glyphicon glyphicon-exclamation-sign error-glyph error-glyph-2x">
                          <div class="error-tooltip">
                            <p *ngIf="form.controls.warningSpeedLimit.errors?.required">{{ 'common.FieldIsRequired' | translate }}</p>
                            <p *ngIf="form.controls.warningSpeedLimit.errors?.max">{{ 'common.MustBeLessThan' | translate: {max: form.controls.warningSpeedLimit.errors?.max.requiredLength} }}</p>
                            <p *ngIf="form.controls.warningSpeedLimit.errors?.min">{{ 'common.MustBeMoreThan' | translate: {min: form.controls.warningSpeedLimit.errors?.min.requiredLength} }}</p>
                            <p *ngIf="form.controls.warningSpeedLimit.errors?.incrementBy">{{ 'common.Increment' | translate: {increment: form.controls.warningSpeedLimit.errors?.incrementBy.increments} }}</p>
                          </div>
                        </div>
                      </label>
                    </div>
                    <div class="row control-row">
                      <label class="col-xs-3" [ngClass]="{'has-error': !form.controls.warningTimeLimit.valid }">
                              <span>{{'views.speed-coaching-detail.timeThreshold' | translate}} <i class="glyphicon glyphicon-question-sign" data-container="body" [tooltip]="'views.speed-coaching-detail.tooltip.WarningTimeLimit' | translate"></i></span>
                          <input type="number" min="1" max="600" step="1" class="form-control input-sm" formControlName="warningTimeLimit" />
                          <div *ngIf="(!form.controls.warningTimeLimit.valid)" class="glyphicon glyphicon-exclamation-sign error-glyph error-glyph-2x">
                            <div class="error-tooltip">
                              <p *ngIf="form.controls.warningTimeLimit.errors?.required">{{ 'common.FieldIsRequired' | translate }}</p>
                              <p *ngIf="form.controls.warningTimeLimit.errors?.max">{{ 'common.MustBeLessThan' | translate: {max: form.controls.warningTimeLimit.errors?.max.requiredLength} }}</p>
                              <p *ngIf="form.controls.warningTimeLimit.errors?.min">{{ 'common.MustBeMoreThan' | translate: {min: form.controls.warningTimeLimit.errors?.min.requiredLength} }}</p>
                              <p *ngIf="form.controls.warningTimeLimit.errors?.incrementBy">{{ 'common.Increment' | translate: {increment: form.controls.warningTimeLimit.errors?.incrementBy.increments} }}</p>
                            </div>
                          </div>
                        </label>
                    </div>
                  </div>

                  <div class="">
                    <h4 class="section-header">
                      <span [innerHtml]="'views.speed-coaching-detail.infractions' | translate"></span>
                    </h4>
                    <div class="row control-row">
                      <label class="col-xs-3" [ngClass]="{'has-error': !form.controls.infractionSpeedLimit.valid }">
                              <span>{{'views.speed-coaching-detail.overSpeedThreshold' | translate}} {{(unitType === 'English' ? 'views.speed-coaching-profile-detail.reference.mph' : 'views.speed-coaching-profile-detail.reference.kph') | translate}} <i class="glyphicon glyphicon-question-sign" data-container="body" [tooltip]="'views.speed-coaching-detail.tooltip.InfractionSpeedLimit' | translate:{speedUnit:speedUnit}"></i></span>
                          <input type="number" min="1" max="30" step="1" class="form-control input-sm" formControlName="infractionSpeedLimit" />
                          <i *ngIf="showUnitConversionTooltip" style = "margin-left:255px; margin-top:20px;"class="glyphicon glyphicon-info-sign" data-container="body" [tooltip]="showInfractionSpeedLimitConversionInfo()"></i>
                          <div *ngIf="(!form.controls.infractionSpeedLimit.valid)" class="glyphicon glyphicon-exclamation-sign error-glyph error-glyph-2x">
                            <div class="error-tooltip">
                              <p *ngIf="form.controls.infractionSpeedLimit.errors?.required">{{ 'common.FieldIsRequired' | translate }}</p>
                              <p *ngIf="form.controls.infractionSpeedLimit.errors?.max">{{ 'common.MustBeLessThan' | translate: {max: form.controls.infractionSpeedLimit.errors?.max.requiredLength} }}</p>
                              <p *ngIf="form.controls.infractionSpeedLimit.errors?.min">{{ 'common.MustBeMoreThan' | translate: {min: form.controls.infractionSpeedLimit.errors?.min.requiredLength} }}</p>
                              <p *ngIf="form.controls.infractionSpeedLimit.errors?.incrementBy">{{ 'common.Increment' | translate: {increment: form.controls.infractionSpeedLimit.errors?.incrementBy.increments} }}</p>
                            </div>
                          </div>
                        </label>
                    </div>
                    <div class="row control-row" [ngClass]="{'has-error': !form.controls.infractionTimeLimit.valid }">
                      <label class="col-xs-3">
                            <span>{{'views.speed-coaching-detail.timeThreshold' | translate}} <i class="glyphicon glyphicon-question-sign" data-container="body" [tooltip]="'views.speed-coaching-detail.tooltip.InfractionTimeLimit' | translate"></i></span>
                            <input type="number" min="1" max="600" step="1" class="form-control input-sm" formControlName="infractionTimeLimit" />
                            <div *ngIf="(!form.controls.infractionTimeLimit.valid)" class="glyphicon glyphicon-exclamation-sign error-glyph error-glyph-2x">
                              <div class="error-tooltip">
                                <p *ngIf="form.controls.infractionTimeLimit.errors?.required">{{ 'common.FieldIsRequired' | translate }}</p>
                                <p *ngIf="form.controls.infractionTimeLimit.errors?.max">{{ 'common.MustBeLessThan' | translate: {max: form.controls.infractionTimeLimit.errors?.max.requiredLength} }}</p>
                                <p *ngIf="form.controls.infractionTimeLimit.errors?.min">{{ 'common.MustBeMoreThan' | translate: {min: form.controls.infractionTimeLimit.errors?.min.requiredLength} }}</p>
                                <p *ngIf="form.controls.infractionTimeLimit.errors?.incrementBy">{{ 'common.Increment' | translate: {increment: form.controls.infractionTimeLimit.errors?.incrementBy.increments} }}</p>
                              </div>
                            </div>
                          </label>
                    </div>

                  </div>
                </form>

              </div>
            </tab>
            <tab heading="{{'views.speed-coaching-detail.tab.Assignments' | translate}}">
              <group-associations #vehicleGroupAssociations entityLabel="Vehicle groups" [displayPerPage]="10">
              </group-associations>

            </tab>

          </tabset>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default cancel-btn" (click)="cancel()">
              <span [innerHtml]="'common.Cancel' | translate"></span>
            </button>
          <button type="submit" class="btn btn-success" [disabled]="!form.valid">
                <i *ngIf="!isInitializing && !form.valid" class="glyphicon glyphicon-exclamation-sign error-glyph"></i>
                <span [innerHtml]="'common.Save' | translate"></span>
          </button>
        </div>
      </div>
    </div>
  </form>
</fieldset>

<confirmation-modal #setDefaultConfirmModal title="{{ 'views.speed-coaching-profile-list.SetDefaultTitle' | translate }}" body="{{ 'views.speed-coaching-profile-list.ConfirmDefaultBody' | translate }}"></confirmation-modal>
<confirmation-modal #confirmModal title="{{ 'views.unsaved-changes-modal.title' | translate}}" body="{{ 'views.unsaved-changes-modal.body' | translate}}"></confirmation-modal>
<confirmation-modal #changeDefaultModal title="{{ 'views.speed-coaching-detail.warning.ChangeDefaultTitle' | translate}}"
  body="{{ 'views.speed-coaching-detail.warning.ChangeDefaultBody' | translate:{name: defaultProfile?.name} }}"></confirmation-modal>
