// vehicle types

export const NAEnglishHeavyDuty = {
  unitsOfMeasure: 'English',
  distanceUnits: 'Miles',
  fuelUnits: 'Gallons',
  vehicleType: 'Truck',
  truckDimensions: 'TrailerOrTwins53',
  roadPreferencesFreewaysAFType: 'Neutral',
  roadPreferencesFreewaysSpeed: 43,
  roadPreferencesDividedHighwaysAFType: 'Neutral',
  roadPreferencesDividedHighwaysSpeed: 31,
  roadPreferencesPrimaryRoadsAFType: 'Neutral',
  roadPreferencesPrimaryRoadsSpeed: 25,
  roadPreferencesSecondaryRoadsAFType: 'Neutral',
  roadPreferencesSecondaryRoadsSpeed: 16,
  roadPreferencesLocalStreetsAFType: 'Neutral',
  roadPreferencesLocalStreetsSpeed: 12,
  classOverrides: 0,
  nationalNetwork: true,
  fiftyThreeFootTrailer: true,
  propane: false
};

export const NAEnglishMediumDuty = {
  unitsOfMeasure: 'English',
  distanceUnits: 'Miles',
  fuelUnits: 'Gallons',
  vehicleType: 'MidsizeTruck',
  truckDimensions: 'Custom',
  maxHeight: (13 * 12) + 6,
  maxWidth: 96,
  totalLength: 26 * 12,
  totalWeight: 26000,
  totalWeightPerAxle: 20000,
  numAxles: 2,
  roadPreferencesFreewaysAFType: 'Neutral',
  roadPreferencesFreewaysSpeed: 50,
  roadPreferencesDividedHighwaysAFType: 'Neutral',
  roadPreferencesDividedHighwaysSpeed: 31,
  roadPreferencesPrimaryRoadsAFType: 'Neutral',
  roadPreferencesPrimaryRoadsSpeed: 25,
  roadPreferencesSecondaryRoadsAFType: 'Neutral',
  roadPreferencesSecondaryRoadsSpeed: 16,
  roadPreferencesLocalStreetsAFType: 'Neutral',
  roadPreferencesLocalStreetsSpeed: 12,
  classOverrides: 0,
  nationalNetwork: false,
  fiftyThreeFootTrailer: false,
  propane: false
};

export const NAEnglishLightDuty = {
  unitsOfMeasure: 'English',
  distanceUnits: 'Miles',
  fuelUnits: 'Gallons',
  vehicleType: 'LightDuty',
  truckDimensions: 'Custom',
  maxHeight: 7 * 12,
  maxWidth: 96,
  totalLength: 20 * 12,
  totalWeight: 8500,
  totalWeightPerAxle: 7500,
  numAxles: 2,
  roadPreferencesFreewaysAFType: 'Neutral',
  roadPreferencesFreewaysSpeed: 55,
  roadPreferencesDividedHighwaysAFType: 'Neutral',
  roadPreferencesDividedHighwaysSpeed: 31,
  roadPreferencesPrimaryRoadsAFType: 'Neutral',
  roadPreferencesPrimaryRoadsSpeed: 25,
  roadPreferencesSecondaryRoadsAFType: 'Neutral',
  roadPreferencesSecondaryRoadsSpeed: 16,
  roadPreferencesLocalStreetsAFType: 'Neutral',
  roadPreferencesLocalStreetsSpeed: 12,
  classOverrides: 0,
  nationalNetwork: false,
  fiftyThreeFootTrailer: false,
  displayRestrictions: 'BasedOnTruckRestrictions',
  hazmatType: 'None'
};

export const NAEnglishAuto = {
  unitsOfMeasure: 'English',
  distanceUnits: 'Miles',
  fuelUnits: 'Gallons',
  vehicleType: 'Auto',
  truckDimensions: 'Custom',
  maxHeight: (7 * 12),
  maxWidth: 96,
  totalLength: 20 * 12,
  totalWeight: 8500,
  totalWeightPerAxle: 7500,
  roadPreferencesFreewaysAFType: 'Neutral',
  roadPreferencesFreewaysSpeed: 55,
  roadPreferencesDividedHighwaysAFType: 'Neutral',
  roadPreferencesDividedHighwaysSpeed: 31,
  roadPreferencesPrimaryRoadsAFType: 'Neutral',
  roadPreferencesPrimaryRoadsSpeed: 25,
  roadPreferencesSecondaryRoadsAFType: 'Neutral',
  roadPreferencesSecondaryRoadsSpeed: 16,
  roadPreferencesLocalStreetsAFType: 'Neutral',
  roadPreferencesLocalStreetsSpeed: 12,
  classOverrides: 0,
  nationalNetwork: false,
  fiftyThreeFootTrailer: false,
  displayRestrictions: 'BasedOnTruckRestrictions',
  hazmatType: 'None'
};

export const NAEnglishBus = {
  unitsOfMeasure: 'English',
  distanceUnits: 'Miles',
  fuelUnits: 'Gallons',
  vehicleType: 'Bus',
  truckDimensions: 'Custom',
  maxHeight: 10 * 12 + 9,
  maxWidth: 96,
  totalLength: 40 * 12,
  totalWeight: 33000,
  totalWeightPerAxle: 23000,
  numAxles: 2,
  classOverrides: 0,
  nationalNetwork: false,
  fiftyThreeFootTrailer: false,
  displayRestrictions: 'BasedOnTruckRestrictions',
  hazmatType: 'None',
  trailerHeight: 0
};

// preset truck dimensions
export const NA48Foot = {
  truckDimensions: 'SemiTrailer48',
  maxHeight: (13 * 12) + 6,
  maxWidth: 96,
  totalLength: 48 * 12,
  totalWeight: 80000,
  totalWeightPerAxle: 34000,
  numAxles: 2,
  nationalNetwork: false,
  fiftyThreeFootTrailer: false,
  classOverrides: 0
};

export const NA53Foot = {
  truckDimensions: 'TrailerOrTwins53',
  maxHeight: (13 * 12) + 6,
  maxWidth: 102,
  totalLength: 53 * 12,
  totalWeight: 80000,
  totalWeightPerAxle: 34000,
  numAxles: 5,
  nationalNetwork: true
};

export const NA28Foot = {
  truckDimensions: 'DoubleTrailers28',
  maxHeight: (13 * 12) + 6,
  maxWidth: 102,
  totalLength: 56 * 12,
  totalWeight: 80000,
  totalWeightPerAxle: 34000,
  numAxles: 2,
  nationalNetwork: true,
  classOverrides: 0,
  lcv: true,
  trailerCount: 2,
  trailerAxleCount: 3,
  trailerHeight: 0,
  tireCount: 22
};

export const NA40Foot = {
  truckDimensions: 'StraightTruck40',
  maxHeight: (13 * 12) + 6,
  maxWidth: 96,
  totalLength: 40 * 12,
  totalWeight: 45000,
  totalWeightPerAxle: 34000,
  numAxles: 3,
  nationalNetwork: false,
  classOverrides: 0,
  fiftyThreeFootTrailer: false,
  trailerCount: 0,
  trailerAxleCount: 0,
  trailerHeight: 0,
  tireCount: 0
};

export const NAEnglishCustom = {
  truckDimensions: 'Custom',
  maxHeight: (13 * 12) + 6,
  maxWidth: 102,
  totalLength: 53 * 12,
  totalWeight: 80000,
  totalWeightPerAxle: 34000,
  numAxles: 5,
  nationalNetwork: true
};
