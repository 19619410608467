// angular components
import { Injectable } from "@angular/core";
// third party components
import { BehaviorSubject, Observable } from "rxjs";

declare var trackJs;
const SELECTED_PARTNER_ACCOUNT = "selectedPartnerAccount"

@Injectable()
export class UserContextService {
  // tslint:disable-next-line: variable-name
  private _user: BehaviorSubject<any> = new BehaviorSubject(null);
  // tslint:disable-next-line: variable-name
  private _activePartnerAccount: BehaviorSubject<any> = new BehaviorSubject(
    null
  );
  isAzureAccount = false;

  get user() {
    return new Observable((fn) => this._user.subscribe(fn));
  }

  get activePartnerAccount() {
    return new Observable((fn) => this._activePartnerAccount.subscribe(fn));
  }

  constructor() {
    const u = localStorage.getItem("user");

    if (u) {
      this._user.next(JSON.parse(u)); // set initial value of user and let subscribers know.
    }
  }

  public clearUser() {
    localStorage.removeItem("user");
    this._user.next(null); // let subscribers know the user is cleared
  }

  public clearSelectedPartnerAccount() {
    localStorage.removeItem(SELECTED_PARTNER_ACCOUNT);
  }

  public setUser(user: any) {
    user.region = user.regionalDataSet.indexOf("EU") >= 0 ? "EU" : "NA";
    user.speedUnit = user.region === "EU" ? "kmph" : "mph";

    const selectedPartnerAccount = JSON.parse(
      localStorage.getItem(SELECTED_PARTNER_ACCOUNT));

    if (isPartnerAccountUser(user) && selectedPartnerAccount) {
      user.currentPartnerAccountId = parseInt(
        selectedPartnerAccount.partnerAccountId,
        10
      );
      user.currentPartnerAccountName = selectedPartnerAccount.partnerAccountName;
    } else {
      user.currentPartnerAccountId = user.accountId;
      user.currentPartnerAccountName = user.account;
    }

    localStorage.setItem("user", JSON.stringify(user));

    this._user.next(user); // let subscribers know of the user
    // partner account might have been set initially from login component
    if (user.currentPartnerAccountId) {
      this._activePartnerAccount.next(user.currentPartnerAccountId);
    }
  }

  public setSelectedPartnerAccount(partnerAccountId: string, partnerAccountName: string) {
    localStorage.setItem(SELECTED_PARTNER_ACCOUNT, JSON.stringify({ partnerAccountId, partnerAccountName }));
  }

  public setPartnerAccount(partnerAccountId: number, partnerAccountName: string) {
    const user = this._user.getValue();
    if (user == null) {
      console.error(
        "Attempted to set current partner account when no user context yet exists"
      );
      return;
    }

    // add a property for the current partner account selected
    user.currentPartnerAccountId = partnerAccountId;
    user.currentPartnerAccountName = partnerAccountName;
    localStorage.setItem("user", JSON.stringify(user));

    // let subscribers know of the user and partner account context
    this._user.next(user);
    this._activePartnerAccount.next(partnerAccountId);
  }

  public getUser() {
    return this._user.getValue();
  }

  public isAuthenticated(): boolean {
    return this._user.getValue() != null;
  }

  public hasRole(roles: string[]): boolean {
    if (!this.isAuthenticated()) {
      return false;
    }

    const user = this._user.getValue();

    return userHasRole(user, roles);
  }

  public hasAcceptedTerms(): boolean {
    if (!this.isAuthenticated()) {
      return false;
    }

    const user = this._user.getValue();

    if (user == null) {
      return false;
    }

    return user.currentTermsOfUseAccepted === true;
  }

  public isManualIntegration(): boolean {
    const user = this._user.getValue();

    if (user == null) {
      return false;
    }

    const partner = user.partner;

    if (partner == null) {
      return false;
    }

    const partnerActivities = partner.partnerActivities;

    if (
      partnerActivities != null &&
      partnerActivities.canAddVehicles &&
      partnerActivities.canUpdateVehicles &&
      partnerActivities.canDeleteVehicles &&
      partnerActivities.canImportVehicles &&
      partnerActivities.canAddVehicleGroups &&
      partnerActivities.canUpdateVehicleGroups &&
      partnerActivities.canDeleteVehicleGroups &&
      partnerActivities.canAddDrivers &&
      partnerActivities.canUpdateDrivers &&
      partnerActivities.canDeleteDrivers &&
      partnerActivities.canImportDrivers &&
      partnerActivities.canAddDriverGroups &&
      partnerActivities.canUpdateDriverGroups &&
      partnerActivities.canDeleteDriverGroups
    ) {
      return true;
    }

    return false;
  }

  public isVehicleGroupDisabled(): boolean {
    const user = this._user.getValue();

    if (user == null) {
      return false;
    }

    const partner = user.partner;

    if (partner == null) {
      return false;
    }

    return !partner.partnerActivities.canViewVehicleGroups;
  }

  public isPartnerAccountUser(): boolean {
    const user = this._user.getValue();
    return isPartnerAccountUser(user);
  }

  public isSpeedCoachingEnabled(): boolean {
    return this._user.getValue().isSpeedCoachingEnabled;
  }

  public isRoutingProfileEnabled(): boolean {
    return this._user.getValue().isRoutingProfileEnabled;
  }

  public isConfigurationProfileEnabled(): boolean {
    return this._user.getValue().isConfigProfileEnabled;
  }

  public isRoadSpeedsEnabled(): boolean {
    return this._user.getValue().isRoadSpeedsEnabled;
  }

  public isMapSetEnabled(): boolean {
    return this._user.getValue().isMapSetEnabled;
  }

  public isAuthorizedToViewDeviceRequest(): boolean {
    const user = this._user.getValue();
    return isAuthorizedToViewDeviceRequest(user);
  }

  public isAuthorizedToEditDataRetention(): boolean {
    const user = this._user.getValue();
    return (
      !user.isDisabled &&
      user.currentTermsOfUseAccepted &&
      isAuthorizedToEditDataRetention(user)
    );
  }

  public getRedirectUrl(redirectUrl) {
    if (this.isPartnerAccountUser()) {
      return "/partnerAccounts";
    } else if (this.isVehicleGroupDisabled()) {
      return "/routing-profiles";
    } else {
      return redirectUrl;
    }
  }
}

// moved these outside the class as they are also called from login component with the authResp

export function isPartnerAccountUser(user: any): boolean {
  const hasSupportRole: boolean = userHasRole(user, ["ALK Support", "Support"]);
  const isValidPartnerType: boolean = isValidPartnerAccountType(user);

  // since we're not supporting partner type 'Other' yet, return false if the user has partner
  // type of 'Other', even if the user has a support role.
  return hasSupportRole && isValidPartnerType;
}

export function isAuthorizedToViewDeviceRequest(user: any): boolean {
  return userHasRole(user, ["ALK Support", "ALK Administrator"]);
}

export function isAuthorizedToEditDataRetention(user: any): boolean {
  return userHasRole(user, ["ALK Administrator", "Company Administrator"]);
}

// returns true if the passed user has any of the roles passed
export function userHasRole(user: any, rolesToCheckFor: string[]): boolean {
  if (user == null || user.roles == null || !user.roles.length) {
    return false;
  }

  // check if the user has any of the roles
  for (const hasRole of user.roles) {
    for (const passedRole of rolesToCheckFor) {
      // needs to be case insensitive search, in some environments, it's "Support" vs "support"
      if (hasRole.toUpperCase() === passedRole.toUpperCase()) {
        return true;
      }
    }
  }

  // if user has none of the roles, return false
  return false;
}

export function isValidPartnerAccountType(user): boolean {
  if (user == null || user.partner.partnerName == null) {
    return false;
  }

  let isOtherTypePartnerAccount = false;
  switch (user.partner.partnerName.toUpperCase()) {
    case "OTHER":
    case "UNKNOWN":
      isOtherTypePartnerAccount = true;
  }

  return !isOtherTypePartnerAccount;
}
