import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, of, throwError, timer } from "rxjs";
import { retryWhen, share, switchMap, take } from "rxjs/operators";
import { environment } from "../../../environments/environment";

const TID_AUTHCODE_KEY = "tidAuthCode";
const TID_TOKEN_KEY = "tidToken";

@Injectable({
  providedIn: "root",
})
export class TidService {
  readonly accounts$ = new BehaviorSubject<
    { displayName: string; value: string }[]
  >([]);
  readonly errorMessage$ = new BehaviorSubject<string | null>(null);
  private authorizeUrl = `${environment.tidUrl}/authorize`;
  private logoutUrl = `${environment.tidUrl}/logout`;
  readonly checkForComponents$ = timer(0, 100).pipe(
    switchMap(() => {
      const migrateComponent = document.getElementById(
        "tmxtid-sign-in-component"
      );
      if (migrateComponent) {
        return of(true);
      }
      return throwError(true);
    }),
    retryWhen((error) => error),
    take(1),
    share()
  );

  constructor(private http: HttpClient) {
    const authParams = `?response_type=code&scope=openid ${environment.tidAppName}`;
    const clientId = `&client_id=${environment.tidClientId}`;
    const redirectUrl = `&redirect_uri=${location.origin}/tid/redirect`;
    this.authorizeUrl += `${authParams}${clientId}${redirectUrl}`;

    const logoutRedirect = `?post_logout_redirect_uri=${location.origin}/tid/logout`;
    this.logoutUrl += logoutRedirect;
  }

  signInWithTid(email: string | null = null, promptCreate = false) {
    let url = `${this.authorizeUrl}&prompt=${
      promptCreate ? "create" : "login"
    }`;

    if (email) {
      url += `&login_hint=${encodeURIComponent(email)}`;
    }

    window.location.href = url;
  }

  checkMigrationStatus(email: string) {
    const url = `${environment.ssoUrl}/v1/trimbleid/migration-status`;
    return this.http.post<{ exists: boolean; migrated: boolean }>(url, {
      email,
    });
  }

  logoutWithTid() {
    const authCode = this.getAuthCode();
    const token = this.getIdToken();

    this.removeAuthCode();
    this.removeIdToken();

    if (!authCode || !token) {
      return;
    }

    window.location.href = this.logoutUrl + `&id_token_hint=${token}`;
  }

  setAuthCode(authCode: string) {
    localStorage.setItem(TID_AUTHCODE_KEY, authCode);
  }

  removeAuthCode() {
    localStorage.removeItem(TID_AUTHCODE_KEY);
  }

  getAuthCode() {
    return localStorage.getItem(TID_AUTHCODE_KEY);
  }

  setIdToken(token: string) {
    localStorage.setItem(TID_TOKEN_KEY, token);
  }

  removeIdToken() {
    localStorage.removeItem(TID_TOKEN_KEY);
  }

  getIdToken() {
    return localStorage.getItem(TID_TOKEN_KEY);
  }
}
