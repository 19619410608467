<div class="nav-section partner-section">
  <form [formGroup]="form">
    <label>
      <span> Customer Account</span>
      <ng-select class="custom"
      formControlName="partner"
      [items]="partnerItems"
      bindLabel="text"
      bindValue="id"
      [clearable]="false"
      (change)="onPartnerSelectionChanged()"></ng-select>
    </label>
  </form>
</div>
