<div class="container panel-backround">
    <div class="message-panel">
      <div *ngIf="showSuccessMessage" class="alert alert-success alert-dismissible" role="alert">
        <button type="button" class="close" (click)="showSuccessMessage=false"><span>&times;</span></button>
        <strong [innerHtml]="'views.mapsets-list.MapsetSaved' | translate"></strong>
      </div>
      <div *ngIf="showHyperlinkError" class="alert alert-danger alert-dismissible" role="alert">
        <button type="button" class="close" (click)="showHyperlinkError=false"><span>&times;</span></button>
        <strong>
          {{ 'views.mapsets-list.error.TooManyMapsets1' | translate}}
          {{ hyperlinkErrorType | async }}
          {{ 'views.mapsets-list.error.TooManyMapsets2' | translate}}
          <a target="_blank" href="https://kb.omnitracs.com/Omnitracs_One/CoPilot/">{{ 'views.mapsets-list.error.TooManyMapsets3' | translate}}</a>
        </strong>
      </div>
      <div *ngIf="errorMsg" class="row alert alert-danger alert-dismissible" role="alert">
        <button type="button" class="close" (click)="errorMsg=''"><span>&times;</span></button>
        <span>{{errorMsg}}</span>
      </div>
    </div>

    <div class="panel page-panel bg-panel-background">
      <div class="row panel action-panel">
        <div class="col-xs-4">&nbsp;</div>
        <div class="col-xs-4">
          <h4 class="text-center">
            <span class="panel-header-text" [innerHtml]="headerText | translate"></span>
          </h4>
        </div>
        <div class="col-xs-4">&nbsp;</div>
      </div>
      <div class="row bg-panel-background">
        <div class="filter-panel mb-2">
          <div class="panel-body">
            <label class="col-xs-4">
              <span [innerHtml]="'views.mapsets.detail.MapsetName' | translate"></span>
              <input type="text" maxlength="{{MAPSET_NAME_CHARACTER_LIMIT}}" class="form-control large"
                placeholder="{{'views.mapsets.mapset-name-field.Placeholder' | translate }}"
                [(ngModel)]="mapset && mapset.customMapsetName" />
              <span class="characters-left">{{ MAPSET_NAME_CHARACTER_LIMIT - mapset?.customMapsetName?.length }}</span>
            </label>
            <div class="col-xs-6 info-field">
              <span class="glyphicon glyphicon-exclamation-sign col-md-1 col-sm-1 col-xs-1"></span>
              <span class="text-field" [innerHtml]="'views.mapsets.detail.MaximumRegionsInfo' | translate:{maxNumber: MAX_SELECTED_REGIONS}"></span>
            </div>
            <div class="default-field custom-control custom-checkbox default-mapset-checkbox text-right col-xs-2"
              (click)="confirmSetAsDefault()">
              <span class="custom-control-input ico"
                [ngClass]="{'ico-checkbox-checked': mapset?.defaultMapset, 'ico-checkbox-unchecked': !mapset?.defaultMapset}"></span>
              <label class="custom-control-label">Use as Default</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row tab-panel">

        <tabset>
          <tab heading="{{'views.mapsets.detail.tabs.MapRegions' | translate}}">
              <map-regions-list *ngIf="mapset" [mapset]="mapset"></map-regions-list>
          </tab>
          <tab heading="{{'views.mapsets.detail.tabs.VehicleGroupAssignments' | translate}}">
            <group-associations #vehicleGroupAssociations
              entityLabel="{{'views.mapsets.detail.tabs.VehicleGroupAssignments.VehicleGroups' | translate}}"
              [displayPerPage]="10">
            </group-associations>
          </tab>
        </tabset>
      </div>
      <div class="row">
        <div class="modal-footer">
          <button class="btn btn-default btn-footer cancel-btn" [routerLink]="['/mapsets-list']">Cancel</button>
          <button class="btn btn-success btn-pad-left btn-footer" (click)="save()"
            [disabled]="mapset && (!mapset.customMapsetName || mapset.customMapsetName.length < 1)">Save</button>
        </div>
      </div>
    </div>

    <confirmation-modal #confirmModal title="{{ 'views.mapsets-details.SetAsDefault' | translate }}"
      body="{{ 'views.mapsets-details.DefaultConfirmation' | translate }}"></confirmation-modal>

      <confirmation-modal #confirmLeave title="{{ 'views.mapsets.detail.ConfirmLeave' | translate }}"
      body="{{ 'views.mapsets.detail.LeaveWithoutSaving' | translate }}"></confirmation-modal>

