
import {throwError as observableThrowError, of as observableOf } from 'rxjs';

import {catchError, map, switchMap} from 'rxjs/operators';
// angular components
import { Injectable } from '@angular/core';

// alk common components
import { UserContextService } from './../../../alk-components/authentication';

// alk app components
import { PagingService } from './../../shared';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from './../../shared/authentication';

@Injectable()
export class VehicleGroupService {

  constructor(private http: HttpClient,
              private userContext: UserContextService,
              private paging: PagingService,
              private authService: AuthenticationService) { }

  public getGroupsByCPSetId(
    setId: number
  ) {

    const url = environment.apiUrl + '/v1/vehicle-group-associations/custom-place-sets/' + setId;

    return this.http.get(url);
  }

    public getGroupsByAFSetId(
    setId: number
  ) {

    const url = environment.apiUrl + '/v1/vehicle-group-associations/avoid-favor-sets/' + setId;

    return this.http.get(url);
  }
  public getGroups(
    groupId?: number[],
    vehicleId?: number[],
    searchTerm?: string,
    routingProfileId?: number[],
    configurationProfileId?: number[]
  ) {

    let url = environment.apiUrl + '/v1/vehicle-groups?';

    const params = [];

    if (searchTerm) { params.push('searchTerm=' + encodeURIComponent(searchTerm)); }

    if (groupId && groupId.length > 0) {
      params.push(this.getArrayQueryParam(groupId, 'groupId'));
    }

    if (vehicleId && vehicleId.length > 0) {
      params.push(this.getArrayQueryParam(vehicleId, 'vehicleId'));
    }

    if (routingProfileId && routingProfileId.length > 0) {
      params.push(this.getArrayQueryParam(routingProfileId, 'routingProfileId'));
    }

    if (configurationProfileId && configurationProfileId.length > 0) {
      params.push(this.getArrayQueryParam(configurationProfileId, 'configurationProfileId'));
    }

    url += params.join('&');

    return this.http.get<Array<any>>(url);
  }

  public getGroup(groupId: number) {
    const url = environment.apiUrl + '/v1/vehicle-groups/' + groupId;

    return this.http.get(url);
  }

  public getVehicleList(includeGroupIds?: number[]) {
    const baseUrl = environment.apiUrl + '/v1/vehiclelist';
    const query = includeGroupIds ? `?includeGroupIds=${includeGroupIds.join(',')}` : '';
    const url = baseUrl + query;
    return this.http.get<any>(url).pipe(
    map(items => {
      return { total: items.length, items };
    }), );
  }

  public getVehicles(includeGroupId?: number, excludeGroupId?: number, searchTerm?: string, paging?: any) {
    let url = environment.apiUrl + '/v1/vehicles?';

    const params = [];
    if (paging) {
      params.push(this.paging.getPagingQuery(paging));
    }

    if (searchTerm) {
      params.push('searchTerm=' + encodeURIComponent(searchTerm));
    }

    if (includeGroupId) {
      params.push('includeGroupId=' + includeGroupId);
    }

    if (excludeGroupId) {
      params.push('excludeGroupId=' + excludeGroupId);
    }

    url += params.join('&');

    return this.http.get(url, { observe: 'response' }).map(resp => {
        const total = +resp.headers.get('X-Total-Count');
        return { total, items: resp.body };
      });
  }

  public getVehicleDetails(vehicleId: number) {
    const url = `${environment.apiUrl}/v1/vehicles/${vehicleId}`;

    return this.http.get(url);
  }

  public getConfigurationProfiles(includeGroupId: Array<number>) {
    let url = environment.apiUrl + '/v1/configuration-profiles?';

    const params = [];

    if (includeGroupId && includeGroupId.length > 0) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < includeGroupId.length; i++) {
        params.push('includeGroupId=' + includeGroupId[i]);
      }
    }

    url += params.join('&');

    return this.http.get<any>(url);
  }

  public getConfigurationProfile(groupId: number) {
    const url = environment.apiUrl + '/v1/configuration-profiles?includeGroupId=' + groupId;
    return this.http.get<any>(url).map(resp => {
        if (resp && resp.length > 0) { return resp[0]; }

        return null;
      });
  }

  public getVehiclesExportUrl(groupId: number) {
    const param = [];
    param.push(`authToken=${this.userContext.getUser().authToken}`);
    param.push(`groupId=${groupId}`);

    if (this.userContext.getUser().currentPartnerAccountId) {
      param.push(`targetAccount=${this.userContext.getUser().currentPartnerAccountId}`);
    }

    const url = `${environment.apiUrl}/v1/vehicles/export?` + param.join('&');

    return url;
  }

  public deleteGroup(groupId: number) {
    const url = `${environment.apiUrl}/v1/vehicle-groups/${groupId}`;
    return this.http.delete(url);
  }

  public associateVehicles(groupId: number, assignmentVehicleIds: string[], unassignmentVehicleIds: string[]) {
    if (assignmentVehicleIds.length === 0 && unassignmentVehicleIds.length === 0) { return observableOf({}); }

    const url = `${environment.fleetApiUrl}api/assets/v1/vehicleGroups/${groupId}/vehicles/combinedassignment`;
    const body = JSON.stringify({ assignmentVehicleIds, unassignmentVehicleIds });

    return this.authService.getFleetsApiToken()
    .pipe(
      switchMap(resp => {
        const token = resp;
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          })
        };
        return this.http.post<any>(url, body, httpOptions);
      })
    );
  }

  public associateRoutingProfiles(groupId: number, add: Array<number>, remove: Array<number>) {
    if ((!add || add.length === 0) && (!remove || remove.length === 0)) { return observableOf({}); }
    const url = `${environment.apiUrl}/v1/vehicle-groups/${groupId}/routing-profiles`;
    const body = JSON.stringify({ add, remove });

    return this.http.put(url, body);
  }

  public associateConfigurationProfile(groupId: number, profileId: number) {
    const url = `${environment.apiUrl}/v1/vehicle-groups/${groupId}/configuration-profile`;

    if (profileId) {
      const body = JSON.stringify({ profileId });
      return this.http.put(url, body);
    } else {
      return this.http.delete(url);
    }
  }

  public associateSpeedCoachingProfile(groupId: number, profileId: number) {
    const url = `${environment.apiUrl}/v1/vehicle-groups/${groupId}/speedCoaching-profile`;
    if (profileId) {
      const body = JSON.stringify({ profileId });
      return this.http.put(url, body);
    } else {
      return this.http.delete(url);
    }
  }

 public associateAvoidFavorSet(groupId: number, add: Array<number>, remove: Array<number>) {
    if ((!add || add.length === 0) && (!remove || remove.length === 0)) { return observableOf({}); }

    const url = `${environment.apiUrl}/v1/vehicle-groups/${groupId}/avoid-favor-sets`;
    const body = JSON.stringify({ add, remove });

    return this.http.put(url, body);
  }

  public associateCustomPlaceSet(groupId: number, add: Array<number>, remove: Array<number>) {
    if ((!add || add.length === 0) && (!remove || remove.length === 0)) { return observableOf({}); }

    const url = `${environment.apiUrl}/v1/vehicle-groups/${groupId}/custom-place-sets`;
    const body = JSON.stringify({ add, remove });

    return this.http.put(url, body);
  }

  public updateGroupDetails(groupId: number, groupDetails) {
    const url = `${environment.apiUrl}/v1/vehicle-groups/${groupId}`;
    const body = JSON.stringify(groupDetails);

    return this.http.put(url, body).pipe(
      catchError(error => {
        return observableThrowError(error);
      }));
  }

  public createGroupDetails(groupDetails) {
    const url = `${environment.apiUrl}/v1/vehicle-groups`;
    if (groupDetails.oorEmailIsEnabled == null) {
      groupDetails.oorEmailIsEnabled = false;
    }
    const body = JSON.stringify(groupDetails);

    return this.http.post(url, body, { observe: 'response' }).pipe(
      map(resp => {
        const modifiedOn = +resp.headers.get('X-LastModified');
        groupDetails.modifiedOn = modifiedOn;

        const location = resp.headers.get('Location');

        const idRegex = /\/v1\/vehicle-groups\/([0-9].*)/g;
        const matches = idRegex.exec(location);

        if (matches.length > 1) { groupDetails.id = +matches[1]; }

        return groupDetails;

      }), catchError(error => {
        return observableThrowError(error);
      }));
  }

  public getDefaultVehicleGroup() {
    return {
      name: ''
    };
  }

  private getArrayQueryParam(arr, propName) {
    const params = [];

    if (arr && arr.length > 0) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < arr.length; i++) {
        params.push(propName + '=' + arr[i]);
      }
    }

    return params.join('&');
  }
}
