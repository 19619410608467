import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LogoutComponent } from "../components/logout/logout.component";
import { RedirectComponent } from "../components/redirect/redirect.component";
import { TidComponent } from "../tid.component";
import { TidGuard } from "./guards/tid.guard";

const routes: Routes = [
  {
    path: "tid",
    component: TidComponent,
    children: [
      {
        path: "redirect",
        component: RedirectComponent,
        canActivate: [TidGuard],
      },
      {
        path: "logout",
        component: LogoutComponent,
      },
      { path: "**", redirectTo: "/tid/redirect" },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TidRoutingModule {}
