/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./redirect.component";
import * as i2 from "../../../../../alk-components/authentication/user-context.service";
import * as i3 from "@angular/router";
import * as i4 from "../../../authentication/authentication.service";
import * as i5 from "../../tid.service";
import * as i6 from "angulartics2/ga";
var styles_RedirectComponent = [];
var RenderType_RedirectComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RedirectComponent, data: {} });
export { RenderType_RedirectComponent as RenderType_RedirectComponent };
export function View_RedirectComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "card shadow-1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "text-center p-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "div", [["class", "spinner-border"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "h3 text-primary mt-3 mb-0"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Logging In..."]))], null, null); }
export function View_RedirectComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_RedirectComponent_0, RenderType_RedirectComponent)), i0.ɵdid(1, 114688, null, 0, i1.RedirectComponent, [i2.UserContextService, i3.ActivatedRoute, i3.Router, i4.AuthenticationService, i5.TidService, i6.Angulartics2GoogleAnalytics], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RedirectComponentNgFactory = i0.ɵccf("ng-component", i1.RedirectComponent, View_RedirectComponent_Host_0, {}, {}, []);
export { RedirectComponentNgFactory as RedirectComponentNgFactory };
