import { Input, Directive } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
  selector: '[disableFC][disableCond]'
})
export class DisableFCDirective {
  @Input() disableFC: FormControl;

  constructor() { }

  get disableCond(): boolean { // getter, not needed, but here only to completude
    return !!this.disableFC && this.disableFC.disabled;
  }

  @Input('disableCond') set disableCond(s: boolean) {
    if (!this.disableFC) { return; } else if (s) { this.disableFC.disable(); } else { this.disableFC.enable(); }
  }
}
