import * as tslib_1 from "tslib";
// alk components
import { VehicleService } from '../shared/vehicle.service';
import { UserContextService } from './../../../alk-components/authentication';
import { Utils } from '../../shared/utils';
import { TranslateService } from '@ngx-translate/core';
var VehicleImport = /** @class */ (function () {
    function VehicleImport(vehicleService, userContext, translate) {
        this.vehicleService = vehicleService;
        this.userContext = userContext;
        this.translate = translate;
        this.firstRowHasHeadings = true;
        this.validVehicleLines = [];
        this.preProcessSuccess = false;
        this.importSuccess = false;
        this.isProcessing = false;
        this.importResults = [];
        this.importFilename = '';
        this.rawTextFile = '';
        this.canImportVehicles = this.userContext.getUser().partner.partnerActivities.canImportVehicles;
    }
    // as soon as a file is selected, start pre-processing it
    VehicleImport.prototype.importFileChanged = function ($event) {
        this.readImportFile($event.target.files[0]);
        this.importSuccess = false;
        this.importSuccessMessage = null;
        this.importErrorMessage = null;
    };
    VehicleImport.prototype.firstRowHasHeadingsChanged = function () {
        if (!this.rawTextFile) {
            return;
        }
        // no need to reload import file, but re-process the import if the headings checkbox has changed
        this.preProcessImport();
    };
    VehicleImport.prototype.upload = function () {
        var _this = this;
        this.isProcessing = true;
        this.importSuccess = false;
        this.importSuccessMessage = null;
        this.importErrorMessage = null;
        // also clear out the preprocess messages since we're past that now
        this.preprocessSuccessMessage = null;
        this.preprocessErrorMessage = null;
        this.vehicleService.import(this.validVehicleLines)
            .subscribe(function (result) {
            _this.isProcessing = false;
            _this.importSuccess = true;
            _this.importResults = result.resp;
            var updatedItems = _this.importResults.filter(function (item) { return item.importResult.toString().toUpperCase() === 'UPDATED'; });
            var insertedItems = _this.importResults.filter(function (item) { return item.importResult.toString().toUpperCase() === 'INSERTED'; });
            // tslint:disable-next-line:max-line-length
            _this.importSuccessMessage = _this.translate.get('views.driver-vehicle-import-SuccessMessage-Vehicles', { updatedItemsLength: updatedItems.length, insertedItemsLength: insertedItems.length });
        }, function (err) {
            _this.isProcessing = false;
            _this.importSuccess = false;
            _this.importErrorMessage = _this.translate.get('views.driver-vehicle-import-importErrorMessage');
            _this.importResults = err.resp;
        });
    };
    VehicleImport.prototype.downloadSample = function () {
        var blob = new Blob([this.sampleImportCsv()], { type: 'text/csv' });
        var uri = window.URL.createObjectURL(blob);
        this.saveFileAs(uri, 'vehicle-import-sample.csv');
    };
    VehicleImport.prototype.downloadResults = function () {
        var blob = new Blob([this.convertJsonItemResultsToCsv()], { type: 'text/csv' });
        var uri = window.URL.createObjectURL(blob);
        var importFilenameSansExt = this.importFilename.replace('.csv', '');
        this.saveFileAs(uri, importFilenameSansExt + "-results.csv");
    };
    VehicleImport.prototype.sampleImportCsv = function () {
        var csvLines = [];
        csvLines.push('Vehicle ID,Vehicle Name,Vehicle Groups');
        csvLines.push('SampleVehicleID1,SampleVehicleName1,Vehicle Group 1|Vehicle Group 2|Vehicle Group 3');
        csvLines.push('SampleVehicleID2,SampleVehicleName2,Vehicle Group 1|Vehicle Group 2|Vehicle Group 3');
        return csvLines.join('\r\n');
    };
    VehicleImport.prototype.convertJsonItemResultsToCsv = function () {
        var csvLines = [];
        csvLines.push('VehicleId,VehicleName,VehicleGroups,ImportResult,ValidationResult');
        this.importResults.forEach(function (resultItem) {
            // tslint:disable-next-line: prefer-const
            var _a = resultItem.item, name = _a.name, vehicleId = _a.vehicleId, groups = _a.groups;
            var validationResult = resultItem.validationResult, importResult = resultItem.importResult;
            name = Utils.csvSafeString(name);
            vehicleId = Utils.csvSafeString(vehicleId);
            var groupString = '';
            groups.forEach(function (group) {
                groupString += group.name + "|";
            });
            groupString = groupString.slice(0, groupString.length - 1); // groupString
            csvLines.push([vehicleId, name, groupString, importResult, validationResult].join(','));
        });
        return csvLines.join('\r\n');
    };
    VehicleImport.prototype.saveFileAs = function (uri, filename) {
        var link = document.createElement('a');
        if (typeof link.getAttribute('download') === 'string' || typeof link.getAttribute('download') === 'object') {
            link.href = uri;
            link.setAttribute('download', filename);
            // Firefox requires the link to be in the body
            document.body.appendChild(link);
            // simulate click
            link.click();
            // remove the link when done
            document.body.removeChild(link);
        }
        else {
            window.open(uri);
        }
    };
    VehicleImport.prototype.readImportFile = function (importFile) {
        var _this = this;
        this.importFilename = importFile.name;
        var fileReader = new FileReader();
        fileReader.onloadend = function (e) {
            _this.rawTextFile = fileReader.result;
            _this.preProcessImport();
        };
        fileReader.readAsText(importFile);
    };
    VehicleImport.prototype.preProcessImport = function () {
        var _this = this;
        this.preprocessSuccessMessage = null;
        this.preprocessErrorMessage = null;
        var lines = this.rawTextFile.split(/\r?\n/);
        if (this.firstRowHasHeadings) {
            if (Utils.csvToArray(lines[0]) === null) {
                this.preprocessErrorMessage = this.translate.get('views.driver-vehicle-import-invalidFileType', {
                    linesLength: lines.length,
                    importFilename: this.importFilename
                });
                return;
            }
            else {
                var _a = tslib_1.__read(Utils.csvToArray(lines[0])), vehicleIdHeader = _a[0], nameHeader = _a[1], vehicleGroupHeader = _a[2], unexpected = _a.slice(3);
                if (nameHeader.toUpperCase() === 'VEHICLE NAME' && vehicleIdHeader.toUpperCase() === 'VEHICLE ID' &&
                    vehicleGroupHeader && vehicleGroupHeader.toUpperCase() === 'VEHICLE GROUPS') {
                    lines.splice(0, 1);
                }
                else {
                    this.preprocessErrorMessage = this.translate.get('views.driver-vehicle-import-invalidFileType', {
                        linesLength: lines.length,
                        importFilename: this.importFilename
                    });
                    return;
                }
            }
        }
        // filter out any blank lines
        lines = lines.filter(function (line) { return line.trim().length > 0; });
        this.validVehicleLines = [];
        var unexpectedLines = [];
        var incompleteLines = [];
        var lineNum = this.firstRowHasHeadings ? 1 : 0;
        lines.forEach(function (line) {
            var e_1, _a, e_2, _b;
            lineNum++;
            var _c = tslib_1.__read(Utils.csvToArray(line)), vehicleId = _c[0], name = _c[1], vehicleGroups = _c[2], unexpected = _c.slice(3);
            if (name === '' || vehicleId === '') {
                incompleteLines.push({ lineNum: lineNum, line: line });
            }
            else if (unexpected.length > 0) {
                unexpectedLines.push({ lineNum: lineNum, line: line });
            }
            else {
                var groups = vehicleGroups ? vehicleGroups.split('|') : [];
                var filteredGroups = new Array();
                try {
                    for (var groups_1 = tslib_1.__values(groups), groups_1_1 = groups_1.next(); !groups_1_1.done; groups_1_1 = groups_1.next()) {
                        var group = groups_1_1.value;
                        if (filteredGroups.length < 1) {
                            filteredGroups.push({ name: group });
                        }
                        try {
                            for (var filteredGroups_1 = (e_2 = void 0, tslib_1.__values(filteredGroups)), filteredGroups_1_1 = filteredGroups_1.next(); !filteredGroups_1_1.done; filteredGroups_1_1 = filteredGroups_1.next()) {
                                var filteredGroup = filteredGroups_1_1.value;
                                if (group.toUpperCase() !== filteredGroup.name.toUpperCase()) {
                                    filteredGroups.push({ name: group });
                                    break;
                                }
                            }
                        }
                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                        finally {
                            try {
                                if (filteredGroups_1_1 && !filteredGroups_1_1.done && (_b = filteredGroups_1.return)) _b.call(filteredGroups_1);
                            }
                            finally { if (e_2) throw e_2.error; }
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (groups_1_1 && !groups_1_1.done && (_a = groups_1.return)) _a.call(groups_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                _this.validVehicleLines.push({ name: name, vehicleId: vehicleId, groups: filteredGroups });
            }
        });
        var duplicateError = this.checkDuplicates(lines);
        var numInvalidLines = unexpectedLines.length + incompleteLines.length;
        var numValidLines = this.validVehicleLines.length;
        var numTotalLines = numValidLines + numInvalidLines;
        this.preProcessSuccess = (numInvalidLines === 0) && numValidLines > 0 && (duplicateError.length === 0);
        if (this.preProcessSuccess) {
            // tslint:disable-next-line:max-line-length
            this.preprocessSuccessMessage = this.translate.get('views.driver-vehicle-import-preprocessSuccessMessage', { linesLength: lines.length, importFilename: this.importFilename });
        }
        else {
            if (numValidLines === 0) {
                this.preprocessErrorMessage = this.translate.get('views.driver-vehicle-import-preprocessErrorNoLinesMessage');
            }
            else if (incompleteLines.length > 0) {
                var _b = tslib_1.__read(incompleteLines, 1), firstIncompleteLineNum = _b[0].lineNum;
                // tslint:disable-next-line:max-line-length
                this.preprocessErrorMessage = this.translate.get('views.driver-vehicle-import-preprocessErrorMessage', { numInvalidLines: numInvalidLines, numTotalLines: numTotalLines, importFilename: this.importFilename, firstLineNum: firstIncompleteLineNum, incompleteLinesLength: incompleteLines.length - 1 });
            }
            else if (unexpectedLines.length > 0) {
                var _c = tslib_1.__read(unexpectedLines, 1), firstUnexpectedLineNum = _c[0].lineNum;
                // tslint:disable-next-line:max-line-length
                this.preprocessErrorMessage = this.translate.get('views.driver-vehicle-import-preprocessErrorMessage', { numInvalidLines: numInvalidLines, numTotalLines: numTotalLines, importFilename: this.importFilename, firstLineNum: firstUnexpectedLineNum, incompleteLinesLength: unexpectedLines.length - 1 });
            }
            else if (duplicateError.length > 0) {
                // tslint:disable-next-line: max-line-length
                this.preprocessErrorMessage = this.translate.get('views.driver-vehicle-import-preprocessErrorDuplicateVehicleIds', { vehicleIds: duplicateError });
            }
        }
    };
    VehicleImport.prototype.checkDuplicates = function (lines) {
        var allLines = lines;
        var duplicates = allLines.map(function (line) {
            var l = line.split(',');
            return l[0];
        }).reduce(function (acc, e, i, a) {
            // if the element exists at an earlier point in the array (this is not the first instance)
            // and if the element doesn't exist in the accumulator (this is the first duplicate)
            if (a.indexOf(e) !== i && acc.indexOf(e) < 0) {
                acc.push(e);
            }
            return acc;
        }, []);
        return duplicates.join(', ');
    };
    return VehicleImport;
}());
export { VehicleImport };
