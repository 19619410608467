// angular components
import { Component, OnInit, OnDestroy, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

// 3rd party components
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { ModalDirective } from 'ngx-bootstrap';
import { Subscription ,  Observable } from 'rxjs';

// app components
import { DriverService, Driver } from './../../driver/shared/driver.service';

@Component({
  selector: 'driver-edit-modal',
  providers: [DriverService],
  templateUrl: './driver-edit-modal.component.html'
})
// tslint:disable-next-line: component-class-suffix
export class DriverEditModal implements OnInit, OnDestroy {
  driverId: number;
  driver: Driver;
  addMode = false; // if false we are in edit mode
  isProcessing = false;
  submitted = false;
  roles: Array<any> = [];
  selectedRole: Array<any> = [];
  emailErrorMsg = '';
  errorMsg = '';
  form: FormGroup;

  @ViewChild('modal', { static: true }) modal: ModalDirective;
  @Output() driverChanged = new EventEmitter();

  private sub1: Subscription;

  constructor(
    private driverService: DriverService,
    private fb: FormBuilder,
    private el: ElementRef,
    private ag2analytics: Angulartics2GoogleAnalytics) {
    this.driver = { name: '', driverId: '' };
    this.form = this.fb.group({
      // name field in database has a max length of 500
      name: [this.driver.name, [Validators.required, Validators.minLength(1), Validators.maxLength(500)]],
      // driverid (which maps to external driver id field in db) has a max length of 250
      driverId: [this.driver.driverId, [Validators.required, Validators.minLength(1), Validators.maxLength(250)]]
    });
  }

  ngOnInit() {

    this.sub1 = this.modal.onShown.subscribe(() => {
      const nativeElement = this.el.nativeElement.getElementsByTagName('input')[this.addMode ? 0 : 1];
      if (nativeElement) { nativeElement.focus(); }
    });
  }

  ngOnDestroy() {
    this.sub1.unsubscribe();
  }

  open(addMode: boolean, driverId?: number) {
    this.addMode = addMode;
    if (driverId) {
      this.driverId = driverId;
    }

    // better way?  should we be destroying this on modal close instead of hide?
    this.errorMsg = '';
    this.emailErrorMsg = '';

    this.ag2analytics.eventTrack(`Driver ${addMode ? 'Add' : 'Edit'} dialog opened`, {});
    this.modal.show();

    if (this.addMode) {
      this.driver = { name: '', driverId: '' };
      this.updateControlValues();
    } else {
      this.isProcessing = true;

      this.driverService.getDriver(driverId).subscribe(data => {
        this.driver.name = data.name;
        this.driver.driverId = data.driverId;
        this.updateControlValues();
        this.isProcessing = false;
      });
    }
  }

  save() {
    this.submitted = true;
    // clear error and success messages when saving
    this.errorMsg = null;

    if (!this.form.valid) {
      return;
    }

    if (!this.form.valid) {
      this.ag2analytics.eventTrack('Driver Edit save attempted', { failReason: 'Invalid input' });
      return;
    }

    this.driver.name = this.form.value.name;
    this.driver.driverId = this.form.value.driverId;
    this.isProcessing = true;

    let createOrUpdateDriver$: Observable<any> = null;
    if (this.addMode) {
      createOrUpdateDriver$ = this.driverService.createDriver(this.driver);
    } else {
      createOrUpdateDriver$ = this.driverService.updateDriver(this.driverId, this.driver);
    }

    createOrUpdateDriver$.subscribe(resp => {
      this.isProcessing = false;
      this.submitted = false;
      this.ag2analytics.eventTrack('Driver update save successful', {});
      this.modal.hide();
      this.driverChanged.emit({
        addedNew: this.addMode,
        id: this.driverId,
        driver: this.driver
      });
    }, (err) => {
      this.isProcessing = false;
      let errMsg = '';
      if (err.status === 409) {
        let duplicateOf = 'value';
        if (err.statusText.toUpperCase().includes('DUPLICATE DRIVER NAME')) {
          duplicateOf = 'name';
        } else if (err.statusText.toUpperCase().includes('DUPLICATE DRIVER ID')) {
          duplicateOf = 'id';
             }
        errMsg = `A driver with this ${duplicateOf} already exists.`;
      } else {
        errMsg = `An error occurred ${this.addMode ? 'adding' : 'updating'} the driver: ` + err;
      }
      this.ag2analytics.eventTrack('Vehicle create/update error', { errMsg });
      this.errorMsg = errMsg;
    });
  }

  private updateControlValues() {
    const controlName = this.form.controls.name as FormControl;
    controlName.setValue(this.driver.name);
    const controlDriverId = this.form.controls.driverId as FormControl;
    controlDriverId.setValue(this.driver.driverId);
  }

}
