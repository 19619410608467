import { pairwise } from "rxjs/operators";
// angular components
// needed for Router
import {
  AfterViewInit,
  Component,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { Event, NavigationEnd, NavigationStart, Router } from "@angular/router";

import { forkJoin } from "rxjs";

// 3rd party components
import { Angulartics2GoogleAnalytics } from "angulartics2/ga";

// shared alk components
import { ALKLanguageContext } from "./../alk-components/alk-translations";
import { UserContextService } from "./../alk-components/authentication";

import { AccountManagementService } from "./../alk-components/account-management";
import { DataRetentionPolicyModal } from "./../app/admin/data-retention-policy-modal";

// reference all sitewide css/less styles here - TODO: these are currently run through the less processor
// and than output as a large string inside the <head> element.  These should be bundled and put into a minified css
// require('!style!css!less!commoncss/alk-bootstrap-theme.less');

@Component({
  selector: "app",
  providers: [AccountManagementService, Angulartics2GoogleAnalytics],
  templateUrl: "./app.component.html",
})
export class AppComponent implements AfterViewInit {
  public viewContainerRef: ViewContainerRef;
  public showHeaderFooter = true;
  public user: any = [];

  @ViewChild("dataRetentionPolicyModal", { static: true })
  dataRetentionPolicyModal: DataRetentionPolicyModal;

  checkRetentionPolicy(events) {
    // We have to show the DataRetentionPolicy Modal on any page we land on after login.
    // The url might be changed to go into a specific route instead of the landing page which is
    // usually the Vehicle Group List Page or the Partner Page(for ALK Support or Support)

    // Note: Compliance Information should be shown if and only if the account has access to RouteReporter
    if (
      events[0] instanceof NavigationEnd &&
      events[1] instanceof NavigationStart
    ) {
      const prev = (events[0] as NavigationEnd).url;
      const next = (events[1] as NavigationEnd).url;

      if (
        next !== "/" &&
        next.indexOf("/reset") === -1 &&
        next.indexOf("/accept-terms") === -1 &&
        next.indexOf("/azure") === -1 &&
        (prev.indexOf("/login") > -1 || prev.indexOf("/accept-terms") > -1)
      ) {
        if (this.userContext.isAuthorizedToEditDataRetention()) {
          // returns 204 when there's no pending change
          const getDataRetentionPolicy =
            this.accountManagementService.getDataRetentionPolicy();
          const isRouteReporterAccount =
            this.accountManagementService.isRouteReporterAccount();
          forkJoin([getDataRetentionPolicy, isRouteReporterAccount]).subscribe(
            (results: any) => {
              if (results[0] && results[1]) {
                setTimeout(() => {
                  // TODO: refactor to use a custom event, which tells us when app is fully loaded
                  this.dataRetentionPolicyModal.open(results[0]);
                }, 1500);
              }
            },
            (error) => {}
          );
        }
      }
    }
  }

  ngAfterViewInit() {
    this.router.events.pipe(pairwise()).subscribe((events) => {
      this.checkRetentionPolicy(events);
    });
  }

  constructor(
    private languageContext: ALKLanguageContext,
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private router: Router,
    viewContainerRef: ViewContainerRef,
    private userContext: UserContextService,
    private accountManagementService: AccountManagementService
  ) {
    // initialize the language context so the entire app has access to translations.
    this.languageContext.init();
    this.viewContainerRef = viewContainerRef;

    // send each navigation change to Segment
    router.events.subscribe((event: Event) => {
      this.user = this.userContext.getUser();
      if (event instanceof NavigationStart) {
        const url = event.url;
        this.angulartics2GoogleAnalytics.pageTrack(url);

        if (
          url.indexOf("/login") > -1 ||
          url.indexOf("/reset") > -1 ||
          url.indexOf("/forgot") > -1
        ) {
          // Add body background
          document.body.className = "marketingPage";
          this.showHeaderFooter = false;
        } else {
          // Remove body background
          document.body.className = "";
          this.showHeaderFooter = true;
        }
      }
      // NavigationEnd
      // NavigationCancel
      // NavigationError
      // RoutesRecognized
    });

    // when logging out, reload window to remove sensitive data in Network tab.
    router.events.pipe(pairwise()).subscribe((events) => {
      if (
        events[0] instanceof NavigationEnd &&
        events[1] instanceof NavigationStart &&
        this.user == null
      ) {
        const prev = (events[0] as NavigationEnd).url;
        const next = (events[1] as NavigationEnd).url;
        // if prevoius url is not from root, reset or accept terms page and next url is to login page
        // It means that user is logged out from FleetLink, refresh the page.
        if (
          prev !== "/" &&
          prev.indexOf("/reset") === -1 &&
          prev.indexOf("/accept-terms") === -1 &&
          prev.indexOf("/azure") === -1 &&
          next === "/login"
        ) {
          window.location.reload();
        }
      }
    });
  }
}
