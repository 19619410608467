import { Injectable } from '@angular/core';

import { AuthenticationService } from '../../shared/authentication/authentication.service';
import { UserContextService } from '../../../alk-components/authentication';

/*
  During application initialization, try and authenticate via the a session cookie.
  If it is not valid, remove any user data from storage and continue as normal (resolve). The navigation guards will handle navigating back
  to the login page if necessary.
  If it IS valid, leave any user data in storage and continue (resolve)
*/
@Injectable()
export class SSOTokenChecker {
  constructor(private authenticationService: AuthenticationService,
              private userContextService: UserContextService) { }

  public loginViaCookie(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.userContextService.clearUser();
      this.authenticationService.cookieLogin()
        .then(() => {
          resolve({});
        }, () => {
          resolve({});
        });
    });
  }
}
