
import {of as observableOf,  Observable } from 'rxjs';

import {switchMap, distinctUntilChanged, debounceTime} from 'rxjs/operators';
// angular components
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';

// 3rd party components
import { TranslateService } from '@ngx-translate/core';

import { SpeedCoachingProfileService } from './../../vehicle-group/shared'; // , ConfigurationProfilesService
import { ConfirmationModal } from './../../shared';

@Component({
  providers: [SpeedCoachingProfileService], // ConfigurationProfilesService,
  templateUrl: './speed-coaching-list.component.html'
})
// tslint:disable-next-line: component-class-suffix
export class SpeedCoachingList implements OnInit {

  @ViewChild('confirmModal', { static: true }) confirmModal: ConfirmationModal;
  @ViewChild('deleteGroupWarning', { static: true }) deleteGroupWarningModal: ConfirmationModal;
  @ViewChild('setDefaultConfirmModal', { static: true }) setDefaultConfirmModal: ConfirmationModal;

  public numberOfAssignedGroups: number;

  public isProcessing = false;
  public allItems: Array<any> = [];
  public filteredItems: Array<any> = [];
  public sortAsc = true;
  public sortCol = 'name';

  public searchTerm = new FormControl();

  public errorMessage: Observable<any>;

  public currentPage = 0;
  public perPage = 10;
  public pageItems: Array<any> = [];

  constructor(
    // private configurationProfileService: ConfigurationProfilesService,
    private speedCoachingProfileService: SpeedCoachingProfileService,
    private router: Router,
    private translate: TranslateService
  ) { }

  public ngOnInit() {
    this.isProcessing = true;

    this.searchTerm.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => this.filterAll(term)), )
      .subscribe(items => {
        this.filteredItems = items;

        this.currentPage = 0;
        this.setCurrentPageItems();
      });

    this.speedCoachingProfileService.getSpeedCoachingProfiles()
      .subscribe(response => {

        this.allItems = response.items;
        this.filteredItems = this.allItems;
        this.isProcessing = false;

        this.setCurrentPageItems();
      }, error => {
        this.errorMessage = error.statusText === 'Forbidden' ?
          this.translate.get('views.speed-coaching-list.errors.ForbiddenRetrievingSpeedCoachingProfiles')
          : this.translate.get('views.speed-coaching-list.errors.UnknownRetrievingSpeedCoachingProfiles');
        this.isProcessing = false;
      });

  }

  public isDefaultToggle(item: any) {

    const selectedElementCurDefaultVal = !item.isDefault;

    if (!selectedElementCurDefaultVal) {
      this.translate.get('views.speed-coaching-profile-list.UnassignDefaultTitle').subscribe(res => {
        this.setDefaultConfirmModal.title = res;
      });
      this.translate.get('views.speed-coaching-profile-list.UnassignDefaultBody').subscribe(res => {
        this.setDefaultConfirmModal.body = res;
      });
    } else {
      this.translate.get('views.speed-coaching-profile-list.SetDefaultTitle').subscribe(res => {
        this.setDefaultConfirmModal.title = res;
      });
      this.translate.get('views.speed-coaching-profile-list.ConfirmDefaultBody').subscribe(res => {
        this.setDefaultConfirmModal.body = res;
      });
    }

    this.setDefaultConfirmModal.open()
      .then(() => {
        item.isProcessing = true;

        this.filteredItems.forEach(ele => {
            if (ele.id !== item.id && ele.isDefault) {
              ele.isDefault = false;
             }
           });
        item.isDefault = !item.isDefault;

        // tslint:disable-next-line: deprecation
        this.speedCoachingProfileService.setIsDefaultChanged(item.id, item.isDefault).subscribe(null, (error) => {
          item.isProcessing = false;
          this.errorMessage = this.translate.get('views.speed-coaching-list.errors.UnableToSetDefaultSet');
       });
      });
  }


  public previousPage() {
    if (!this.hasPreviousPage()) { return; }

    this.currentPage--;
    this.setCurrentPageItems();
  }

  public nextPage() {
    if (!this.hasNextPage()) { return; }

    this.currentPage++;
    this.setCurrentPageItems();
  }

  public deleteItem(item) {
    if (item.numberOfAssignedVehicleGroups === 0) {
      this.showDeleteConfirmationWarning(item);
    } else {
      this.showDeleteGroupWarning(item, item.numberOfAssignedVehicleGroups);
    }
  }

  public showDeleteGroupWarning(item: any, numberOfAssignedVehicleGroups: number) {
    this.numberOfAssignedGroups = numberOfAssignedVehicleGroups;
    this.deleteGroupWarningModal.open()
      .then(() => this.router.navigate(['/speed-coaching', item.id]));
  }

  public showDeleteConfirmationWarning(item) {
    this.confirmModal.open()
      .then(() => {
        // tslint:disable-next-line:no-string-literal
        item['isProcessing'] = true;

        this.speedCoachingProfileService.deleteSpeedCoachingProfile(item.id)
          .subscribe(() => {
            // tslint:disable-next-line:no-string-literal
            item['isProcessing'] = false;
            // remove the group from the list and get the current page of items
            const index = this.allItems.indexOf(item);

            // if we didn't find the group index, don't do anything.  Otherwise, remove the item from the list
            if (index >= 0) {
              this.allItems.splice(index, 1);
              this.filteredItems = this.allItems;
              this.setCurrentPageItems();
            }

          }, (error) => {
            // tslint:disable-next-line:no-string-literal
            item['isProcessing'] = false;
            this.errorMessage = this.translate.get('views.speed-coaching-list.errors.UnknownDeletingSpeedCoachingProfile');
          });

      });
  }

  public hasNextPage(): boolean {
    const nextPageStartIndex = (this.currentPage + 1) * this.perPage;
    return this.filteredItems.length > nextPageStartIndex;
  }

  public hasPreviousPage(): boolean {
    return this.currentPage > 0;
  }

  public clearSearchTerm() {
    this.searchTerm.setValue('');
  }

  public sortString(column: string, asc: boolean) {
    const multiplyBy = asc ? 1 : -1;

    this.filteredItems.sort((a, b) => {
      const aUpperCase = a[column].toUpperCase();
      const bUpperCase = b[column].toUpperCase();

      if (aUpperCase < bUpperCase) { return (-1 * multiplyBy); }
      if (aUpperCase > bUpperCase) { return (1 * multiplyBy); }
      return 0;
    });

    this.setCurrentPageItems();
  }

  public sortNumber(column: string, asc: boolean) {
    const multiplyBy = asc ? 1 : -1;

    this.filteredItems.sort((a, b) => {
      return (a[column] - b[column]) * multiplyBy;
    });

    this.setCurrentPageItems();
  }

  public pageStart() {
    if (this.filteredItems.length === 0) { return 0; }
    return (this.currentPage * this.perPage) + 1;
  }

  public pageEnd() {
    let retVal = (this.currentPage * this.perPage) + this.perPage;
    if (retVal > this.filteredItems.length) {
      retVal = this.filteredItems.length;
    }
    return retVal;
  }

  private setCurrentPageItems() {
    const startPageIndex = this.currentPage * this.perPage;
    const endPageIndex = startPageIndex + this.perPage;

    this.pageItems = this.filteredItems.slice(startPageIndex, endPageIndex);
  }

  private filter(items: Array<any>, searchTerm: string) {
    searchTerm = searchTerm.toUpperCase();

    return items.filter(item => {
      if (item.name != null && item.name.toUpperCase().indexOf(searchTerm) !== -1) { return true; }

      return false;
    });
  }

  private filterAll(searchTerm?: string) {
    let results = this.allItems;

    if (searchTerm) {
      results = this.filter(results, searchTerm);
    }

    return observableOf(results);
  }

}
