import { FormControl } from '@angular/forms';

import {VehicleMeasurements} from './../../vehicle/shared/vehicle-measurements';

export function getTotalLengthValidator(getForm, getRegion) {
  const vehicleMeasurements = new VehicleMeasurements();
  return (fc: FormControl) => {
    const form = getForm();
    if (!form || !form.value) { return null; }

    const profile = form.value;
    const region = getRegion();
    const truckDimensions = form.controls.truckDimensions.value;

    // preset dimensions are always valid
    if (truckDimensions !== 'Custom') { return null; }

    const dimensions: any = { vehicleType: profile.vehicleType, region};
    if (profile.unitsOfMeasure) {
      dimensions.unitType = profile.unitsOfMeasure;
    }

    let currentLength: number;

    if (profile.unitsOfMeasure === 'English') {
      currentLength = (+profile.totalLengthFeet * 12) + +profile.totalLengthInches;
    } else {
      currentLength = (+profile.totalLengthFeet * 100) + +profile.totalLengthInches;
    }

    return vehicleMeasurements.getLengthLimits(currentLength, dimensions);

  };
}

export function getMaxHeightValidator(getForm, getRegion) {
  const vehicleMeasurements = new VehicleMeasurements();
  return (fc: FormControl) => {
    const form = getForm();
    if (!form || !form.value) { return null; }

    const profile = form.value;
    const region = getRegion();
    const truckDimensions = form.controls.truckDimensions.value;

    // preset dimensions are always valid
    if (truckDimensions !== 'Custom') { return null; }

    const dimensions: any = { vehicleType: profile.vehicleType, region};
    if (profile.unitsOfMeasure) {
      dimensions.unitType = profile.unitsOfMeasure;
    }

    let currentHeight: number;
    if (profile.unitsOfMeasure === 'English') {
      currentHeight = (+profile.maxHeightFeet * 12) + +profile.maxHeightInches;
    } else {
      currentHeight = (+profile.maxHeightFeet * 100) + +profile.maxHeightInches;
    }

    return vehicleMeasurements.getHeightLimits(currentHeight, dimensions);
  };
}

export function getMaxWidthValidator(getForm, getRegion) {
  const vehicleMeasurements = new VehicleMeasurements();
  return (fc: FormControl) => {
    const value = fc.value;

    const form = getForm();
    if (!form || !form.value) { return null; }

    const profile = form.value;
    const region = getRegion();
    const truckDimensions = form.controls.truckDimensions.value;

    const dimensions: any = { vehicleType: profile.vehicleType, region};
    if (profile.unitsOfMeasure) {
      dimensions.unitType = profile.unitsOfMeasure;
    }
    // preset dimensions are always valid
    if (truckDimensions !== 'Custom') { return null; }

    return vehicleMeasurements.getWidthLimits(value, dimensions);
  };
}

export function getTotalWeightValidator(getForm, getRegion) {
  // TODO: update these validators to take into account form.value.unitsOfMeasure
  const vehicleMeasurements = new VehicleMeasurements();
  return (fc: FormControl) => {
    const value = fc.value;

    const form = getForm();
    if (!form || !form.value) { return null; }

    const profile = form.value;
    const region = getRegion();
    const truckDimensions = form.controls.truckDimensions.value;

    // preset dimensions are always valid
    if (truckDimensions !== 'Custom') { return null; }

    const dimensions: any = { vehicleType: profile.vehicleType, region};
    if (profile.unitsOfMeasure) {
      dimensions.unitType = profile.unitsOfMeasure;
    }
    return vehicleMeasurements.getWeightLimits(value, dimensions);
  };
}

export function getTotalWeightPerAxleValidator(getForm, getRegion) {
  const vehicleMeasurements = new VehicleMeasurements();
  // TODO: update these validators to take into account form.value.unitsOfMeasure
  return (fc: FormControl) => {
    const value = fc.value;

    const form = getForm();
    if (!form || !form.value) { return null; }

    const profile = form.value;
    const region = getRegion();
    const truckDimensions = form.controls.truckDimensions.value;

    // preset dimensions are always valid
    if (truckDimensions !== 'Custom') { return null; }

    const dimensions: any = { vehicleType: profile.vehicleType, region};
    if (profile.unitsOfMeasure) {
      dimensions.unitType = profile.unitsOfMeasure;
    }

    return vehicleMeasurements.getWeightPerAxleLimits(value, dimensions);
  };
}

// this validates inches  (0-11 for inches, 0-99 for cm)
export function getInchesValidator(getForm, getRegion) {
  return (fc: FormControl) => {
    const value = fc.value;
    let min;
    let max;

    const form = getForm();
    if (!form || !form.value) { return null; }

    const profile = form.value;
    const truckDimensions = form.controls.truckDimensions.value;

    // preset dimensions are always valid
    if (truckDimensions !== 'Custom') { return null; }

    min = 0;

    if (profile.unitsOfMeasure === 'Metric') {
      max = 99;
    } else {
      max = 11;
    }

    if (value >= min && value <= max) { return null; }

    return { inches: { min, max } };
  };
}

export function getGovernorSpeedLimitValidator(getForm) {
  return (fc: FormControl) => {
    const value = fc.value;
    let min;
    let max;

    const form = getForm();
    if (!form || !form.value) { return null; }

    const profile = form.value;
    min = 1;
    if (profile.unitsOfMeasure === 'English') {
      max = 100;
    } else {
      max = 160;
    }
    if (value >= min && value <= max) { return null; }

    return { governorSpeedLimit: { min, max } };
  };
}

export function getElevationValidator(getForm) {
  return (fc: FormControl) => {
    const value = fc.value;
    let min;
    let max;

    const form = getForm();
    if (!form || !form.value) { return null; }

    const profile = form.value;
    min = 1;
    if (profile.unitsOfMeasure === 'English') {
      max = 29035;
    } else {
      max = 8850;
    }
    if (value >= min && value <= max) { return null; }

    return { elevation: { min, max } };
  };
}

export function getRoadPreferencesValidator(getForm, getRegion) {
  return (fc: FormControl) => {
    const value = fc.value;
    let min;
    let max;

    const form = getForm();
    if (!form || !form.value) { return null; }

    const profile = form.value;

    if (profile.unitsOfMeasure === 'English') {
      min = 5;
      max = 100;
    } else {
      min = 5;
      max = 160;
    }

    if (value >= min && value <= max) { return null; }

    return { roadPreferences: { min, max } };
  };
}
