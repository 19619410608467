<div class="container-fluid footer-trimble-corp">
  <div class="container">
    <div class="row py-3 text-white">
      <div class="col-12">
        <a
          href="https://www.trimble.com/Corporate/Copyrights.aspx"
          target="_blank"
          rel="noopener noreferrer"
          class="text-white mr-3 pl-4"
        >
          © Copyright {{ currentYear }}, Trimble Inc.
        </a>
      </div>
    </div>
  </div>
</div>
