import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserContextService } from './../alk-components/authentication';

@Injectable()
export class AzureGuard implements CanActivate {
  constructor(private auth: UserContextService, private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.auth.isAzureAccount) {
      return true;
    }

    this.router.navigate(['/']);
    return false;
  }
}
