<div class="container">
  <div class="message-panel">
    <div *ngIf="errorMsg" class="row alert alert-danger" role="alert">
      <span>{{errorMsg}}</span>
    </div>
    <div *ngIf="showSuccessMessage" class="alert alert-success alert-dismissible" role="alert">
      <button type="button" class="close" (click)="showSuccessMessage=false"><span>&times;</span></button>
      <strong [innerHtml]="'views.mapsets-connection-settings.ConnectionSettingsSaved' | translate"></strong>
    </div>
  </div>

  <div class="panel page-panel">
    <div class="panel action-panel">
      <h4 class="text-center">
        <span [innerHtml]="'views.mapsets-connection-settings.ConnectionSettings' | translate" class="page-title"></span>
      </h4>
    </div>
    <div class="panel-body">
      <div class="row">
        <div class="col-xs-6">
          <label>
            <span [innerHtml]="'views.mapsets-connection-settings.MapsetsDownloads' | translate"></span>
          </label>
          <div class="div-wifi">
            <input type="radio" name="isWifiOnly" [value]="true" class="radio-wifi" [disabled]="!isFullAccess" [(ngModel)]="isWifiOnly">
            <span [innerHtml]="'views.mapsets-connection-settings.WiFiOnly' | translate"></span> <i> (default)</i>
            <input type="radio" name="isWifiOnly" [value]="false" class="radio-wifi radio-wifi-cell" [disabled]="!isFullAccess" [(ngModel)]="isWifiOnly">
            <span [innerHtml]="'views.mapsets-connection-settings.WiFiAndCellular' | translate"></span>
          </div>
        </div>
        <div class="col-xs-6">
          <label>
            <span [innerHtml]="'views.mapsets-connection-settings.WiFiBlacklist' | translate"></span>
          </label>
          <div class="div-blacklist-input">
            <input type="text" class="input-sm text-input-blacklist" [(ngModel)]="wifiName" [disabled]="!isFullAccess">
            <button class="btn btn-default btn-pad-left" [disabled]="!isValidAdd()" (click)="addConnection(wifiName)" [innerHtml]="'common.Add' | translate"></button>
          </div>
          <ul class="ul-blacklist">
            <li class ="li-connection" *ngFor="let connection of blacklistedConnections; let i = index">
              <span class="connection-name">{{connection.wifiName}}</span>
              <button type="button" (click)="deleteConnection(i)" title="{{'common.Delete' | translate}}" class="btn btn-icon btn-float-left">
                <span class="ico ico-trash"></span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-default btn-footer cancel-btn" [routerLink]="['/mapsets-list']" [innerHtml]="'common.Cancel' | translate"></button>
        <button class="btn btn-success btn-pad-left btn-footer" (click)="save()" [disabled]="!isValidSave()" [innerHtml]="'common.Save' | translate"></button>
      </div>
  </div>
</div>
<confirmation-modal #confirmModal title="{{ 'views.mapsets-connection-settings.WiFiBlacklist' | translate }}" body="{{ 'views.mapsets-connection-settings.DeleteConnectionBody' | translate }}"></confirmation-modal>
