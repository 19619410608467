import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
// 3rd party components
// alk components
import { UserContextService } from "./../../../alk-components/authentication";
import { AlkCloudApp, AlkCloudService } from "./alk-cloud.service";

@Component({
  selector: "alk-cloud",
  templateUrl: "./alk-cloud.component.html",
  styles: [],
  providers: [AlkCloudService],
})
// tslint:disable-next-line: component-class-suffix
export class AlkCloudMenu implements OnInit {
  webToolsImgSrc: string;
  isOpen: boolean;
  alkCloudProducts: Array<any>;
  licensedApps: Array<AlkCloudApp>;
  unlicensedApps: Array<AlkCloudApp>;
  expiredApps: Array<AlkCloudApp>;

  constructor(
    private userContext: UserContextService,
    private router: Router,
    private alkCloudService: AlkCloudService
  ) {
    this.webToolsImgSrc = "/assets/img/webtools-icon.svg";
    this.isOpen = false;
    this.unlicensedApps = [];
    this.licensedApps = [];
    this.expiredApps = [];
  }

  ngOnInit() {
    const region = this.userContext.getUser().region;
    this.alkCloudService.getLicensedApps().subscribe((apps) => {
      this.licensedApps = apps.filter(
        (app) => app.productLicensed && !app.productExpired
      );
      this.unlicensedApps = apps.filter(
        (app) => !app.productLicensed && !app.productExpired
      );
      // "[Temporarily] remove RouteReporter and MapExact products for EU accounts in ALK Cloud menu" SWMI-1173
      if (region === "EU") {
        this.unlicensedApps = this.unlicensedApps.filter(
          (app) =>
            app.productTitle !== "RouteReporter" &&
            app.productTitle !== "MapExact"
        );
      }
      this.expiredApps = apps.filter((app) => app.productExpired);
    });
  }

  logout(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.router.navigate(["tid/logout"]);
  }

  toggleUnlicensedProductInfo(product, event) {
    product.showUnlicensedMessage = !product.showUnlicensedMessage;
    event.stopPropagation();
    event.preventDefault();
  }

  goToAppPage(product) {
    if (environment.applicationName === product.productTitle) {
      return;
    }
    this.isOpen = false;
    window.open(product.productAppUrl, "_blank");
  }

  goToInfoPage(product) {
    this.isOpen = false;
    window.open(product.productInfoUrl, "_blank");
  }
}
