// angular components
import { AfterViewInit, Component, OnDestroy } from "@angular/core";
import { TmxTidModule, TmxTidService } from "../../tmx-tid-3.0.2";

declare var tmxtidmodule: TmxTidModule;

@Component({
  selector: "landing",
  templateUrl: "./landing.component.html",
})
// tslint:disable-next-line: component-class-suffix
export class Landing implements AfterViewInit, OnDestroy {
  public formTab = "login";
  componentService: TmxTidService;

  constructor() {
    this.formTab = "login";
  }

  ngAfterViewInit() {
    this.componentService = tmxtidmodule.init();
  }

  ngOnDestroy() {
    this.componentService.destroy();
  }
}
