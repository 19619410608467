
import {
  forkJoin as observableForkJoin,
  of as observableOf,
  from as observableFrom,
  Observable,
  Subscription
} from 'rxjs';

import {pluck} from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

import { ConfirmationModal, RoutingProfileService, incrementBy } from './../../shared';
import { VehicleGroupService } from './../../vehicle-group/shared';
import { AccountManagementService } from './../../../alk-components/account-management';
import { RoutingProfileDefaults } from './routing-profile-measurements-en';
import {
  getMaxHeightValidator,
  getTotalLengthValidator,
  getMaxWidthValidator,
  getTotalWeightValidator,
  getTotalWeightPerAxleValidator,
  getInchesValidator,
  getGovernorSpeedLimitValidator,
  getElevationValidator
} from './routing-profile-validators';
import { GroupAssociationsComponent } from './../../shared/group-associations';
import { UserContextService } from './../../../alk-components/authentication';
import { validRoutingProfileName } from './../../../alk-components/common-validation';
import {
  metricTonneToLbs,
  lbsToMetricTonne,
  convertKMToMile,
  convertMileToKM,
  convertFtToMetre,
  convertMetreToFt,
  inchtoMcm,
  convertInchToMetre,
  convertMetreToInch,
  cmtoFeetInches
} from './../../shared/utils/convertUtil';

@Component({
  selector: 'routing-profile-detail',
  templateUrl: './routing-profile-detail.component.html',
  providers: [RoutingProfileService, RoutingProfileDefaults, VehicleGroupService, AccountManagementService],
  styleUrls: ['./routing-profile-detail.component.css'],
  encapsulation: ViewEncapsulation.None
})
// tslint:disable-next-line: component-class-suffix
export class RoutingProfileDetail implements OnInit, OnDestroy {
  public routingProfileId = -1;
  public isNew: boolean;
  public isProcessing = false;
  public isInitializing = true;
  public submitted = false;

  public errorMessage: Observable<string>;
  public successMessage: Observable<string>;
  public isVehicleTypeAllowed = false;
  public lastSavedVehicleType: string;
  public vehicleTypes: Array<any> = [];
  public form: FormGroup;

  public useRoadPreferences = false;

  public enableActiveRoutes = false;

  public unitType = 'English';

  public showUnitConversionTooltip = false;

  public region = 'NA';

  // Default gov speed and elevation limits
  defaultMetricGovSpeedLimit = 88;
  defaultMetricElevationLimit = 2286;
  defaultEnglishGovSpeedLimit = 55;
  defaultEnglishElevationLimit = 7500;

  truckInchWidthLimitForNoNationalNetwork = 96;
  truckFeetLengthLimitForNoNationalNetwork = 48;

  onLoadGovSpeedLimit: number;
  onLoadElevationLimit: number;
  isDefaultToggleDirty = false;
  @ViewChild('setDefaultConfirmModal', { static: true }) setDefaultConfirmModal: ConfirmationModal;
  @ViewChild('confirmModal', { static: true }) confirmModal: ConfirmationModal;
  @ViewChild('vehicleAssociations', { static: true }) vehicleAssociations: GroupAssociationsComponent;
  @ViewChild('vehicleType', { static: true }) vehicleType: any; // why doesn't SelectComponent work?  (from ng2-select)

  private sub1: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private profileService: RoutingProfileService,
    private userContext: UserContextService,
    private fb: FormBuilder,
    private routingProfileDefaults: RoutingProfileDefaults,
    private vehicleGroupService: VehicleGroupService,
    private accountService: AccountManagementService,
    private translate: TranslateService
  ) {
    this.form = this.fb.group({
      name: [null, Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(64), validRoutingProfileName])],
      isAccountWideDefault: [false],
      vehicleType: [],
      routingType: [],
      tollRoadType: [],
      activeRoutes: [],

      classOverrides: [],
      nationalNetwork: [],
      fiftyThreeFootTrailer: [],
      lcv: [],
      trailerType: [],
      trailerHeight: [],
      trailerCount: [],
      trailerAxleCount: [],
      tireCount: [],

      ferryClosed: [],
      bordersOpen: [],
      hazmatType: [],
      elevationSelect: [],
      governorSpeedLimitSelect: [],
      governorSpeedLimit: [1, Validators.compose([incrementBy(1), getGovernorSpeedLimitValidator(() => this.form), Validators.required])],
      elevationLimit: [1, Validators.compose([incrementBy(1), getElevationValidator(() => this.form), Validators.required])],
      displayRestrictions: [],
      tunnelRestriction: [],
      congestionZone : [],
      environmentalZone: [],
      propane: [],
      truckDimensions: [],

      unitsOfMeasure: [],
      fuelUnits: [],
      distanceUnits: [],

      maxHeight: [],
      maxHeightFeet: [0],
      maxHeightInches: [0, getInchesValidator(() => this.form, () => this.region)],

      totalLength: [],
      totalLengthFeet: [],
      totalLengthInches: [0, getInchesValidator(() => this.form, () => this.region)],

      maxWidth: [null, Validators.compose([getMaxWidthValidator(() => this.form, () => this.region), Validators.required])],

      totalWeight: [null, Validators.compose([getTotalWeightValidator(() => this.form, () => this.region), Validators.required])],

      totalWeightPerAxle: [null, Validators.compose([
        getTotalWeightPerAxleValidator(() => this.form, () => this.region),
        Validators.required
      ])],

      numAxles: [],

      roadPreferencesDividedHighwaysAFType: [],
      roadPreferencesFreewaysAFType: [],
      roadPreferencesPrimaryRoadsAFType: [],
      roadPreferencesSecondaryRoadsAFType: [],
      roadPreferencesLocalStreetsAFType: [],
    }, {
        // these validators depend on multiple feeds
        // (example: height is made of height inches + height feet OR height meters if it is metric)
        validator: Validators.compose([
          getTotalLengthValidator(() => this.form, () => this.region),
          getMaxHeightValidator(() => this.form, () => this.region)
        ])
      }
    );
  }

  public canDeactivate(): Observable<boolean> | boolean {
    if (!this.isDefaultToggleDirty && !this.form.dirty &&
      this.vehicleAssociations.itemsPendingAddition.length === 0 &&
      this.vehicleAssociations.itemsPendingRemoval.length === 0) { return true; }

    return observableFrom(this.confirmModal.open());
  }

  public ngOnInit() {

    const user = this.userContext.getUser();
    this.unitType = user.unitType;
    this.showUnitConversionTooltip = user.enableUnitConversion;
    this.region = user.region;
    this.useRoadPreferences  = user.useRoadPreferences;
    this.enableActiveRoutes  = user.enableActiveRoutes;

    if (this.region === 'EU') {
      this.vehicleTypes = [
        { id: 'Truck', text: 'Heavy Duty', icon: 'ico-heavyduty' },
        { id: 'MidsizeTruck', text: 'Medium Duty', icon: 'ico-mediumduty' },
        { id: 'Auto', text: 'Auto', icon: 'ico-route-car' }
      ];
    }

    this.vehicleAssociations.columnDefinitions = [
      { title: 'Name', isFilterable: true }
    ];

    this.sub1 = this.route.params.pipe(pluck('id'))
      .subscribe(id => {
        this.isInitializing = true;

        // we determine if the page is for a new routing profile or an existing routing profile based on the id ('new' is for a new profile)
        this.isNew = isNaN(+id);

        const routingProfileObs = this.isNew ? this.loadNewRoutingProfile() : this.loadExistingRoutingProfile(+id);
        const vehicleGroupsObs = this.isNew ? observableOf([]) : this.vehicleGroupService.getGroups(null, null, null, [+id]);
        const vehicleTypesObs = this.accountService.getVehicleTypes();

        observableForkJoin([
          vehicleGroupsObs,
          this.vehicleGroupService.getGroups(),
          routingProfileObs,
          vehicleTypesObs
        ]).subscribe(result => {

          this.routingProfileId = result[2].routingProfileId;

          this.onLoadGovSpeedLimit = result[2].governorSpeedLimit;
          this.onLoadElevationLimit = result[2].elevationLimit;
          this.loadForm(result[2]);
          this.setActiveRouteElements(result[2]);

          const availableVehicleTypes: Array<any> = [];

          result[3].forEach((vehicleType) => {
            switch (vehicleType) {
              case 'Truck':
                availableVehicleTypes.push({ seq : 0, id: 'Truck', text: 'Heavy Duty', icon: 'ico-heavyduty' });
                break;

              case 'MidsizeTruck':
                availableVehicleTypes.push({ seq: 1, id: 'MidsizeTruck', text: 'Medium Duty', icon: 'ico-mediumduty' });
                break;

              case 'LightDuty':
                availableVehicleTypes.push({ seq: 2, id: 'LightDuty', text: 'Light Duty', icon: 'ico-lightduty' });
                break;

              case 'Auto':
                availableVehicleTypes.push({ seq: 3, id: 'Auto', text: 'Auto', icon: 'ico-route-car' });
                break;

              case 'Bus':
                availableVehicleTypes.push({ seq: 4, id: 'Bus', text: 'School Bus', icon: 'ico-school-bus' });
                break;
            }
          });
          this.lastSavedVehicleType = result[2].vehicleType;
          this.form.controls.vehicleType.setValue(this.lastSavedVehicleType);
          this.vehicleTypes = availableVehicleTypes.sort((a, b) => {
            return (a.seq > b.seq) ? 1 : ((b.seq > a.seq) ? -1 : 0);
          });
          this.isVehicleTypeAllowed = this.vehicleTypes.filter(c => c.id === result[2].vehicleType).length === 0 ? false : true;

          this.vehicleAssociations.initialItems = result[0].map(group => {
            return {
              id: group.id,
              fields: [group.name],
              pendingRemoval: false,
              pendingAddition: false
            };
          });

          this.vehicleAssociations.allPossibleItems = result[1].map(group => {
            return {
              id: group.id,
              fields: [group.name],
              pendingRemoval: false,
              pendingAddition: false
            };
          });

          // finished loading page, update flag to let ui know to not display loading indicator
          this.isInitializing = false;
        }, error => {
          this.errorMessage = this.translate.get('views.routing-profile-detail.errors.UnknownLoading');
          this.isInitializing = false;
        });

      });
  }

  public isDefaultToggle() {

    const selectedElementCurDefaultVal = !this.form.controls.isAccountWideDefault.value;

    if (!selectedElementCurDefaultVal) {
      this.translate.get('views.routing-profile-list.UnassignDefaultTitle').subscribe(res => {
        this.setDefaultConfirmModal.title = res;
      });
      this.translate.get('views.routing-profile-list.UnassignDefaultBody').subscribe(res => {
        this.setDefaultConfirmModal.body = res;
      });
    } else {
      this.translate.get('views.routing-profile-list.SetDefaultTitle').subscribe(res => {
        this.setDefaultConfirmModal.title = res;
      });
      this.translate.get('views.routing-profile-list.ConfirmDefaultBody').subscribe(res => {
        this.setDefaultConfirmModal.body = res;
      });
    }
    this.setDefaultConfirmModal.open()
    .then(() => {
      this.form.controls.isAccountWideDefault.setValue(selectedElementCurDefaultVal);
      this.isDefaultToggleDirty = true;
    });



  }

  public isHazmatEditable(): boolean {
    // only enabled for truck and midsize, other vehicle types are not enabled
    if (this.form.controls.vehicleType.value === 'Truck' || this.form.controls.vehicleType.value === 'MidsizeTruck') {
      return true;
    }

    return false;
  }

  public isGovSpeedLimitEditable(): boolean {
    // only enabled for truck, midsize and light duty, other vehicle types are not enabled
    if (this.form.controls.vehicleType.value === 'Truck' ||
      this.form.controls.vehicleType.value === 'MidsizeTruck' ||
      this.form.controls.vehicleType.value === 'LightDuty') {
      return true;
    } else {
      this.form.controls.governorSpeedLimitSelect.setValue('disabled');
      // tslint:disable-next-line: max-line-length
      this.userContext.getUser().unitsOfMeasure === 'Metric' ?  this.form.controls.governorSpeedLimit.setValue(this.defaultMetricGovSpeedLimit) : this.form.controls.governorSpeedLimit.setValue(this.defaultEnglishGovSpeedLimit);
    }
    return false;
  }

  public isActiveRoutesEditable(): boolean {
    if ( this.form.controls.routingType.value === 'Practical') {
      return true;
    } else {
      this.form.controls.activeRoutes.setValue('Disabled');
    }
    return false;
  }

  public isTruckPresetDimensionsEditable(): boolean {
    // this is the same logic for EU as well as NA
    // tslint:disable-next-line:no-string-literal
    if (this.form.controls['vehicleType'].value === 'Truck') {
      return true;
    }

    return false;
  }

  public isRoadNetworkEnabled(): boolean {
    if (this.form.controls.vehicleType.value === 'Truck') {
      if (this.form.controls.truckDimensions.value === 'TrailerOrTwins53' ||
        this.form.controls.truckDimensions.value === 'DoubleTrailers28') {
        return false;
      } else {
        return true;
      }
    } else if ((this.form.controls.vehicleType.value === 'MidsizeTruck' || this.form.controls.vehicleType.value === 'LightDuty')) {
      return true;
    }
    return false;
  }

  public isDisplayRestrictionsEnabled(): boolean {
    // only enabled for truck and midsize, other vehicle types are not enabled
    if (this.form.controls.vehicleType.value === 'Truck' ||
      this.form.controls.vehicleType.value === 'MidsizeTruck' ||
      this.form.controls.vehicleType.value === 'Bus') {
      return true;
    }

    return false;
  }

  public isTunnelRestrictionsEnabled(): boolean {
    if (this.form.controls.hazmatType.value === 'None') {
      this.form.controls.tunnelRestriction.setValue('None');
      return false;
    }
    return true;
  }

  public isPropaneEditable(): boolean {
    // disabled for truck/midsize, smaller vehicle types allowed.
    if (this.form.controls.vehicleType.value === 'Truck' || this.form.controls.vehicleType.value === 'MidsizeTruck') {
      return false;
    }

    return true;
  }

  public isDimensionsEditable(): boolean {
    // this is the same logic for EU as well as NA
    // tslint:disable-next-line:no-string-literal
    if (this.form.controls['truckDimensions'].value === 'Custom') {
      return true;
    }

    return false;
  }

  public inchesToFeetInches(total: number) {
    if (total == null) { return { parts: 0, whole: 0 }; }

    return {
      parts: total % 12,
      whole: Math.floor(total / 12)
    };
  }

  public feetInchesToTotalInches(whole: number, parts: number) {
    return whole * 12 + parts;
  }

  public metersToMeterCentimeters(total: number) {
    if (total == null) { return { whole: 0, parts: 0 }; }

    return {
      whole: Math.floor(total),
      parts: Math.round(total * 100) % 100
    };
  }

  public metersCentimetersToTotalMeters(whole: number, parts: number) {
    return ((whole * 100) + parts) / 100;
  }

  public loadForm(attributes) {

    const conversionFunction = attributes.unitsOfMeasure === 'Metric' ? this.metersToMeterCentimeters : this.inchesToFeetInches;

    if (this.onLoadGovSpeedLimit === null) {
      attributes.governorSpeedLimitSelect = 'disabled';
      // tslint:disable-next-line: max-line-length
      attributes.governorSpeedLimit = attributes.unitsOfMeasure === 'Metric' ? this.defaultMetricGovSpeedLimit : this.defaultEnglishGovSpeedLimit;
    } else {
      attributes.governorSpeedLimitSelect = 'enabled';
    }

    if (this.onLoadElevationLimit === null) {
      attributes.elevationSelect = 'any';
      // tslint:disable-next-line: max-line-length
      attributes.elevationLimit = (attributes.unitsOfMeasure === 'Metric') ? this.defaultMetricElevationLimit : this.defaultEnglishElevationLimit;
    } else {
      attributes.elevationSelect = 'discouraged';
    }

    // whenever loading the form, if we are setting the max height, split this into the max height in inches/feet
    if ('maxHeight' in attributes) {
      attributes.maxHeightFeet = conversionFunction(attributes.maxHeight).whole;
      attributes.maxHeightInches = conversionFunction(attributes.maxHeight).parts;
    }

    // whenever loading the form, if we are setting the totalLenght, split this into feet/inches or meters/centimeters based on the units
    if ('totalLength' in attributes) {
      attributes.totalLengthFeet = conversionFunction(attributes.totalLength).whole;
      attributes.totalLengthInches = conversionFunction(attributes.totalLength).parts;
    }

    // leaving width as a single field for now. copilot displays max width as two fields for eu
    // if ('maxWidth' in attributes) {
    //   attributes.maxWidthFeet = conversionFunction(attributes.maxWidth).whole;
    //   attributes.maxWidthInches = conversionFunction(attributes.maxWidth).parts;
    // }

    for (const attr in attributes) {
      if (!attributes.hasOwnProperty(attr)) { continue; }

      const control =  this.form.controls[attr] as FormControl;
      if (!control) { continue; }

      control.setValue(attributes[attr]);
    }

    for (const field in this.form.controls) {
      const control = this.form.get(field);
      control.updateValueAndValidity();
    }
  }

  public onGovSpeedLimitChanged(governorSpeedLimitSelect) {
    if (governorSpeedLimitSelect === 'enabled') {
      this.onLoadGovSpeedLimit = this.form.controls.governorSpeedLimit.value;
    } else {
      this.onLoadGovSpeedLimit = null;
    }
  }

  public resetLimitValidators() {
    this.form.controls.governorSpeedLimit.setValidators
    ([Validators.compose([incrementBy(1), getGovernorSpeedLimitValidator(() => this.form), Validators.required])]);
    this.form.controls.elevationLimit.setValidators
    ([Validators.compose([incrementBy(1), getElevationValidator(() => this.form), Validators.required])]);
  }

  public onElevationLimitChanged(elevationLimitSelect) {
    if (elevationLimitSelect === 'discouraged') {
      this.onLoadElevationLimit = this.form.controls.elevationLimit.value;
    } else {
      this.onLoadElevationLimit = null;
    }
  }

  public onRoadNetworkChanged(nationalNetwork) {
    // not sure why the boolean field comes across as "1: true" and not just a boolean true. maybe a bug in angular in this rc?
    if (nationalNetwork === '1: true') {
      this.loadForm({ classOverrides: 3 });
    } else {
      this.loadForm({ classOverrides: 0 });
    }
  }

  public isGovSpeedDisabled(): boolean {
    return this.form.controls.governorSpeedLimitSelect.value === 'disabled';
  }

  public isElevationDisabled(): boolean {
    return this.form.controls.elevationSelect.value === 'any';
  }

  public onTruckDimensionsChanged(truckDimensions) {
    const defaults = { region: this.region, unitType: this.form.value.unitsOfMeasure };
    const attributes = this.routingProfileDefaults.getMeasurements(truckDimensions, defaults);
    this.loadForm(attributes);
  }

  public isNetworkWarningValid() {
    return this.isNotEUOrBus() &&
      this.isNationalNetworkLengthAndWidth() &&
      !this.form.controls.nationalNetwork.value;
  }

  public isNotEUOrBus() {
    return this.region !== 'EU' && this.form.value.vehicleType !== 'Bus';
  }

  public isNationalNetworkLengthAndWidth() {
    return this.form.controls.maxWidth.value > this.truckInchWidthLimitForNoNationalNetwork ||
      this.form.controls.totalLengthFeet.value > this.truckFeetLengthLimitForNoNationalNetwork;
  }

  public isVehicleDimensionWarningNeeded() {
    return this.form.errors &&
      (this.form.errors.maxHeight ||
      !this.form.controls.maxHeightFeet.valid ||
      !this.form.controls.maxHeightInches.valid ||
      !this.form.controls.maxWidth.valid ||
      this.form.errors.totalLength ||
      !this.form.controls.totalLengthFeet.valid ||
      !this.form.controls.totalLengthInches.valid ||
      !this.form.controls.totalWeight.valid ||
      !this.form.controls.totalWeightPerAxle.valid) ||
      this.isNetworkWarningValid();
  }

  public onVehicleTypeChanged(selection) {
    const vehicleType = selection.id;
    const defaults = { region: this.region, unitType: this.form.value.unitsOfMeasure };
    let attributes = this.routingProfileDefaults.getVehiclePreset(vehicleType, defaults);

    if ('truckDimensions' in attributes && attributes.truckDimensions !== 'Custom') {
      const measurementAttributes = this.routingProfileDefaults.getMeasurements(attributes.truckDimensions, defaults);
      attributes = _.merge({}, attributes, measurementAttributes);
    }
    this.isVehicleTypeAllowed = true;

    this.loadForm(attributes);
  }

  public loadNewRoutingProfile(): Observable<any> {
    const profile = this.routingProfileDefaults.getDefaultRoutingProfile();
    return observableOf(profile);
  }

  public loadExistingRoutingProfile(id: number): Observable<any> {
    return this.profileService.getRoutingProfile(id);
  }

  public ngOnDestroy() {
    this.sub1.unsubscribe();
  }

  public save() {
    this.submitted = true;
    this.isDefaultToggleDirty = false;
    // clear error and success messages when saving
    this.errorMessage = null;
    this.successMessage = null;

    if (!this.form.valid) {
      return;
    }

    // governor Speed and Elevation Limit

    if (this.form.controls.governorSpeedLimitSelect.value === 'disabled') {
      this.form.controls.governorSpeedLimit.setValidators([]);
      this.form.controls.governorSpeedLimit.setValue(null);
    }

    if (this.form.controls.elevationSelect.value === 'any') {
      this.form.controls.elevationLimit.setValidators([]);
      this.form.controls.elevationLimit.setValue(null);
    }

    const conversionFunction = this.form.value.unitsOfMeasure === 'Metric' ?
      this.metersCentimetersToTotalMeters : this.feetInchesToTotalInches;

    this.form.controls.maxHeight.setValue(conversionFunction(+this.form.value.maxHeightFeet, this.form.value.maxHeightInches));
    this.form.controls.totalLength.setValue(conversionFunction(+this.form.value.totalLengthFeet, this.form.value.totalLengthInches));
    this.form.value.name = this.form.controls.name.value.trim();

    // values being set here for now until we find a better solution as to how to disable these fields.
    // using the disable() function would completely omit these fields when we went to save hence why they're
    // being set here.
    this.form.value.truckDimensions = this.form.controls.truckDimensions.value;
    this.form.value.nationalNetwork = this.form.controls.nationalNetwork.value;
    this.form.value.hazmatType = this.form.controls.hazmatType.value;
    this.form.value.tunnelRestriction = this.form.controls.tunnelRestriction.value;
    this.form.value.displayRestrictions = this.form.controls.displayRestrictions.value;
    this.form.value.propane = this.form.controls.propane.value;
    this.form.value.congestionZone = this.form.controls.congestionZone.value;
    this.form.value.environmentalZone = this.form.controls.environmentalZone.value;

    // For Custom Dimension if the length and width are below 53ft and 102inches do not set the is53FootTrailer flag as true
    if (this.form.controls.truckDimensions.value === 'Custom') {
      if ((this.form.controls.totalLengthFeet.value * 12 + this.form.controls.totalLengthInches.value) < 53 * 12
        || (this.form.controls.maxWidth.value < 102)) {
        this.form.value.fiftyThreeFootTrailer = 0;
      } else {
        this.form.value.fiftyThreeFootTrailer = 1;
      }

    }

    this.isProcessing = true;

    let promise: Promise<any>;

    // if this is a new profile, save them in sequence.  If it is an existing, save them at the same time
    if (this.isNew) {
      promise = this.saveRoutingProfileDetails();

      // only need to save the vehicle groups if we have any
      if (this.vehicleAssociations.resultItems.length > 0) {
        promise = promise.then(() => this.saveVehicleGroups());
      }

    } else {
      promise = Promise.all([this.saveRoutingProfileDetails(), this.saveVehicleGroups()]);
    }
    // reset to Practical, since Fastest is not a valid option in the RoutingType drop down, should be used just for persisting
    if (this.form.controls.routingType.value === 'Fastest') {
      this.form.controls.routingType.setValue('Practical');
    }

    promise.then(() => {
      // once the page is successfully saved, mark this form as pristine/untouched.
      this.form.markAsPristine();

      this.successMessage = this.translate.get('views.routing-profile-detail.SaveSuccess');

      if (this.isNew) {
        this.router.navigate(['/routing-profiles', this.routingProfileId]);
      }

      this.isProcessing = false;

    }, error => {
      if (error.code === -2) {
        this.errorMessage = this.translate.get('views.routing-profile-detail.errors.MaxReached');
      } else if (error.code === -3) {
        this.errorMessage = this.translate.get('views.routing-profile-detail.errors.NameExists');
      } else if (error.code === -4) {
        this.errorMessage = this.translate.get('views.routing-profile-detail.errors.NameNotAllowed');
      } else {
        this.errorMessage = this.translate.get('views.routing-profile-detail.errors.Unknown');
      }

      this.isProcessing = false;
    });
    this.resetLimitValidators();
    this.submitted = false; // back to clean slate.
  }

  public cancel() {
    this.router.navigate(['/routing-profiles']);
  }

  // Tooltip conversion methods
  public showGovernorSpeedLimitConversionInfo(): string {
    const value = this.form.controls.governorSpeedLimit.value;
    if (!value) { return ''; }
    if (this.unitType === 'English') {
      return `${convertMileToKM(value).toString()} kph`;
    }
    return `${convertKMToMile(value).toString()} mph`;
  }

  public showElevationLimitConversionInfo(): string {
    const value = this.form.controls.elevationLimit.value;
    if (!value) { return ''; }
    if (this.unitType === 'English') {
      return `${convertFtToMetre(value.toString())} m`;
    }
    return `${convertMetreToFt(value).toString()} ft`;
  }

  public showMaxHeightConversionInfo(): string {
    if (this.unitType === 'English') {
      return `${inchtoMcm(this.form.controls.maxHeightFeet.value * 12  + this.form.controls.maxHeightInches.value ).toString()}`;
    }
    return `${cmtoFeetInches(this.form.controls.maxHeightFeet.value * 100 + this.form.controls.maxHeightInches.value).toString()}`;
  }

  public showMaxWidthConversionInfo(): string {
    if (this.unitType === 'English') {
      return `${convertInchToMetre(this.form.controls.maxWidth.value).toString()} m`;
    }
    return `${convertMetreToInch(this.form.controls.maxWidth.value).toString()} in`;
  }

  public showMaxLengthConversionInfo(): string {
    if (this.unitType === 'English') {
      return `${inchtoMcm(this.form.controls.totalLengthFeet.value * 12 + this.form.controls.totalLengthInches.value).toString()}`;
    }
    return `${cmtoFeetInches(this.form.controls.totalLengthFeet.value * 100 + this.form.controls.totalLengthInches.value).toString()}`;
  }

  public showTotalWeightConversionInfo(): string {
    if (this.region !== 'EU') {
      return `${lbsToMetricTonne(this.form.controls.totalWeight.value).toString()}`;
    }
    return `${metricTonneToLbs(this.form.controls.totalWeight.value).toString()}`;
  }

  public showTotalWeightPerAxleConversionInfo(): string {
    if (this.region !== 'EU') {
      return `${lbsToMetricTonne(this.form.controls.totalWeightPerAxle.value).toString()}`;
    }
    return `${metricTonneToLbs(this.form.controls.totalWeightPerAxle.value).toString()}`;
  }

  private saveRoutingProfileDetails(): Promise<any> {
    let promise;

    // If routing type is Practical and Active Routes is enabled, set the routing type as Fastest
    if (this.form.controls.routingType.value === 'Practical' && this.form.controls.activeRoutes.value === 'Enabled') {
      this.form.controls.routingType.setValue('Fastest');

    }
    if (this.isNew) {
      promise = this.profileService.createRoutingProfileDetails(this.form.value).toPromise().then((newProfile) => {
        this.routingProfileId = newProfile.routingProfileId;
        return newProfile;
      });

    } else {
      promise = this.profileService.updateRoutingProfileDetails(this.routingProfileId, this.form.value).toPromise();
    }

    return promise;
  }

  private saveVehicleGroups(): Promise<any> {
    return this.profileService.associateVehicleGroups(
      this.routingProfileId,
      this.vehicleAssociations.resultItems.map(r => r.id)
    ).toPromise().then(() => {
      this.vehicleAssociations.initialItems = this.vehicleAssociations.resultItems;
      this.vehicleAssociations.resetInitialData();
    });
  }

  private setActiveRouteElements(routingProfile: any) {
    if (routingProfile.routingType === 'Fastest') {
      this.form.controls.activeRoutes.setValue('Enabled');
      this.form.controls.routingType.setValue('Practical');
    } else {
    this.form.controls.activeRoutes.setValue('Disabled');
    }
  }
}
