// angular components
import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

// 3rd party components
import { ModalDirective } from 'ngx-bootstrap';

// app components
import { DataRetentionPolicy} from './../../admin/dataRetentionPolicy';

@Component({
  selector: 'data-retention-policy-modal',
  providers: [],
  templateUrl: './data-retention-policy-modal.component.html'
})

// tslint:disable-next-line: component-class-suffix
export class DataRetentionPolicyModal {
  public policy: DataRetentionPolicy;
  public modifiedBy: string;
  public modifiedOn: string;
  public previousNumberOfWeeks: number;
  public numberOfWeeks: number;
  public startDate: string;

  @ViewChild('modal', { static: true }) modal: ModalDirective;

  constructor(
    private router: Router
  ) {
  }

  close() {
    this.modal.hide();
  }

  open(policy: DataRetentionPolicy) {
    this.modifiedBy = policy.modifiedBy;
    this.modifiedOn = new Date(policy.modifiedOn).toLocaleDateString();
    this.previousNumberOfWeeks = policy.previousNumberOfWeeks;
    this.numberOfWeeks = policy.numberOfWeeks;
    this.startDate = policy.startDate.toLocaleDateString();
    this.modal.show();
  }

  goToAdmin() {
    this.modal.hide();
    this.router.navigate(['/admin']);
  }
}
