import { FormControl } from '@angular/forms';
var DisableFCDirective = /** @class */ (function () {
    function DisableFCDirective() {
    }
    Object.defineProperty(DisableFCDirective.prototype, "disableCond", {
        get: function () {
            return !!this.disableFC && this.disableFC.disabled;
        },
        set: function (s) {
            if (!this.disableFC) {
                return;
            }
            else if (s) {
                this.disableFC.disable();
            }
            else {
                this.disableFC.enable();
            }
        },
        enumerable: true,
        configurable: true
    });
    return DisableFCDirective;
}());
export { DisableFCDirective };
