
import {throwError as observableThrowError } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
// angular components
import { Injectable } from '@angular/core';

// alk common components
import { UserContextService } from './../../../alk-components/authentication';

// alk app components
import { PagingService } from './../../shared';

// shared components
import { GroupsUtil } from './../../../app/shared/utils/groupsUtil';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

export interface Driver {
  name: string;
  driverId: string;
}

@Injectable()
export class DriverService {

  constructor(private http: HttpClient,
              private userContext: UserContextService,
              private paging: PagingService) { }

  getDriver(driverId: number) {
    const url = environment.apiUrl + '/v1/drivers/' + driverId;

    return this.http.get<any>(url);
  }

  getDriverList() {
    const url = environment.apiUrl + '/v1/driverlist';

    return this.http.get<any>(url).map(data => {
        const drivers = data.map(driver => {
          driver.driverGroupsAbbreviated = 'Loading...';
          return driver;
        });
        return { total: drivers.length, items: drivers };
      });
  }

  getDrivers(includeGroupId?: number, excludeGroupId?: number, searchTerm?: string, paging?: any) {
    let url = environment.apiUrl + '/v1/drivers?';

    const params = [];
    if (paging) {
      params.push(this.paging.getPagingQuery(paging));
    }

    if (searchTerm) {
      params.push('searchTerm=' + encodeURIComponent(searchTerm));
    }

    if (includeGroupId) {
      params.push('includeGroupId=' + includeGroupId);
    }

    if (excludeGroupId) {
      params.push('excludeGroupId=' + excludeGroupId);
    }

    url += params.join('&');
    const drivers: any[] = [];
    const maxGroupsToDisplay = 2;

    return this.http.get<any>(url).map(responses => {
        responses.forEach(resp => {
          resp.driverGroupsAbbreviated = (resp.driverGroups.length > 0) ?
          GroupsUtil.getAbbreviatedGroupsName(resp.driverGroups, maxGroupsToDisplay) : '';
          drivers.push(resp);
        });
        return { total: drivers.length, items: drivers };
      });
  }

  getDriverDetails(driverId: number) {
    const url = `${environment.apiUrl}/v1/drivers/${driverId}`;

    return this.http.get(url);
  }

  getExportUrl(groupId?: number, passDateTimeOffSet?: boolean) {
    const param = [];
    param.push(`authToken=${this.userContext.getUser().authToken}`);

    if (this.userContext.getUser().currentPartnerAccountId) {
      param.push(`targetAccount=${this.userContext.getUser().currentPartnerAccountId}`);
    }

    if (groupId > 0) {
      param.push(`groupId=` + groupId);
    }

    const jan = new Date((new Date()).getFullYear(), 0, 1);
    const jul = new Date((new Date()).getFullYear(), 6, 1);
    const offSet = Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());

    if (passDateTimeOffSet) {
      param.push(`dateTimeOffSetInMinutes=` + offSet);
    }

    const url = `${environment.apiUrl}/v1/drivers/export?` + param.join('&');
    return url;
  }

  deleteDriver(driverId: number) {
    const url = `${environment.apiUrl}/v1/drivers/${driverId}`;
    return this.http.delete(url);
  }

  updateDriver(driverId: number, driverDetails) {
    const url = `${environment.apiUrl}/v1/drivers/${driverId}`;
    const body = JSON.stringify(driverDetails);

    return this.http.put(url, body).pipe(
      catchError(error => {
        return observableThrowError(error);
      }));
  }

  createDriver(driverDetails) {
    const url = `${environment.apiUrl}/v1/drivers`;
    const body = JSON.stringify(driverDetails);

    return this.http.post(url, body, { observe: 'response' }).pipe(
      map(resp => {
        const modifiedOn = +resp.headers.get('X-LastModified');
        driverDetails.modifiedOn = modifiedOn;

        const location = resp.headers.get('Location');

        const idRegex = /\/v1\/drivers\/([0-9].*)/g;
        const matches = idRegex.exec(location);

        if (matches.length > 1) { driverDetails.id = +matches[1]; }

        return driverDetails;

      }), catchError(error => {
        return observableThrowError(error);
      }), );
  }

  import(drivers: Array<Driver>) {
    const url = `${environment.apiUrl}/v1/drivers/import?validateOnly=false`;
    const body = JSON.stringify(drivers);

    return this.http.post<any>(url, body).pipe(
      map(result => {
        return { status: 200, resp: result };
      }),
      catchError((err) => {
        return observableThrowError({ status: err.status, resp: err._body });
      }), );
  }


  // public getDefaultDriverGroup() {
  //   return {
  //     name: ''
  //   };
  // }

  // private getArrayQueryParam(arr, propName) {
  //   let params = [];

  //   if (arr && arr.length > 0) {
  //     for (let i = 0; i < arr.length; i++) {
  //       params.push(propName + '=' + arr[i]);
  //     }
  //   }

  //   return params.join('&');
  // }
}
