import { FormControl } from '@angular/forms';

// Validates that the numeric data is strictly an integer
export function validateInteger(getForm: any) {
   return (fc: FormControl) => {
    const value = fc.value;

    const form = getForm();
    if (!form || !form.value) { return null; }

    if (typeof(value) === 'number' && value % 1 === 0) { return null; }

    return { isNotValidInteger: true };
  };
}
