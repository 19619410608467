<div class="container partner-management">

  <div class="message-panel">
    <div *ngIf="errorMsg" class="row alert alert-danger" role="alert">
      <label [innerHtml]="'common.Error' | translate"></label>
      <span>{{errorMsg}}</span>
    </div>
  </div>
  <div class="panel partner-panel">
     <div class="panel-header">
        <h3 class="text-center">
          <span [innerHtml]="'views.partner-account-list.Title' | translate"></span>
        </h3>
        </div>
      <div class="panel-section row">
          <div class="col-md-6 col-sm-6 col-xs-6"> <h5> <strong> Total Accounts: {{totalAcc}} </strong> </h5> </div>
          <div class="col-md-6 col-sm-6 col-xs-6">   <h5> <strong> Total Devices: {{totalVehicleCount}} </strong> </h5> </div>
        </div>
       <div class="filters">
         <div class="accounts-header filter-panel">
           <h4>Accounts</h4>
       <div class="form-inline filter-group">
          <div class="input-group">
            <span class="input-group-btn">
                <span class="btn btn-sm btn-fake-addon">
                <i class="ico ico-filter"></i>
                </span>
            </span>
             <input type="text" class="form-control" [(ngModel)]="searchAccount" (ngModelChange)="filterAccounts(searchAccount);" placeholder="" (keydown.escape)="clearSearchTerm()"/>
            <span class="input-group-btn">
                <button class="btn btn-sm btn-default clear-btn" type="button" (click)="searchAccount=''; filterAccounts('')">×</button>
              </span>
          </div>
        </div>
         </div>
        <div class="devices-header filter-panel">
           <h5>Devices <span ng-if="currentAccountname"> {{"in " + currentAccountname}} </span> </h5>
           <div class="">
             <button class="btn btn-default btn-sm" type="button" [disabled]="isDisabled()" (click)="moveDevices()">Move</button>
             <button class="btn btn-default btn-sm" type="button" [disabled]="!currentAccountId || disableNew" (click)="newDevice()"><span class="ico ico-plus" > </span> New Device</button>
           </div>
        <div class="form-inline filter-group">
          <div class="input-group">
            <span class="input-group-btn">
                <span class="btn btn-sm btn-fake-addon">
                <i class="ico ico-filter"></i>
                </span>
            </span>
            <input type="text" class="form-control" [(ngModel)]="searchDevice" (ngModelChange)="filterDevices(searchDevice);" placeholder="" [formControl]="searchTerm" (keydown.escape)="clearSearchTerm()"/>
            <span class="input-group-btn">
                <button class="btn btn-sm btn-default clear-btn" type="button" (click)="searchDevice='';">×</button>
              </span>
          </div>
          <button class="btn btn-default btn-sm" (click)="selectAll();">Select All</button>
             <button class="btn btn-default btn-sm" (click)="selectNone();">Select None</button>
        </div>
         </div>
        </div>
        <div class="panel-body account-list">
          <ul class="list-group result-list">
           <!--<li (click)="loadVehicles(account.id, account.name)" class="list-group-item">
              <div class="flex-group">
                <div class="flex-element text-ellipsis"><label>Unassigned</label> </div>
                <span class="badge with-actions ng-binding">10</span>
              </div>
            </li>-->
            <li *ngFor="let account of filteredAccountItems;" (click)="loadVehicles(account.id, account.name)" class="list-group-item"
            [ngClass]="(account.id === currentAccountId) ? 'selected-item' : ''">
              <div class="flex-group">
                <div class="flex-element text-ellipsis" [innerHtml]="account.name | alkHighlight:searchTerm.value" title="{{account.name}}"> </div>
                <span class="badge with-actions ng-binding">{{account.vehicleCount}}</span>
              </div>
            </li>
          </ul>
        </div>
        <div class="divider panel-body"></div>
        <div class="panel-body devices-list">
          <div class="selection-conditional">
            <span  *ngIf="selectAccount && total === 0"> {{selectAccount}} </span>
            <span *ngIf="!selectAccount && total === 0">No Devices to display</span>
            <span *ngIf="!selectAccount && total !== 0 && !loading">{{ids.length}} Devices Selected</span>
             <span *ngIf = "loading">
               <span class="glyphicon glyphicon-refresh spinner"></span> loading....</span>
            </div>

          <ul class="list-group result-list" style="margin-left:0px;" *ngIf="total !== 0">
            <li *ngFor="let vehicle of filteredVehicles;" class="list-group-item" (click)="toggleSelectedId(vehicle);">
              <div class="flex-group">
                <input type="checkbox" name="selectedIds" [checked]="ids.indexOf(vehicle) >= 0">
                 <div class="flex-element text-ellipsis" [innerHtml]="vehicle.vehicleId +' <label>(' + vehicle.name + ') </label>' | alkHighlight:searchTerm.value" title="{{vehicle.vehicleId}} ({{vehicle.name}})">
                 </div>
              </div>
            </li>
          </ul>
          </div>
  </div>
</div>

<partner-account-move-modal #partnerAccountMoveModal (vehicleChanged)="reloadVehicles($event)"></partner-account-move-modal>
<partner-account-new-modal #partnerAccountNewModal (vehicleChanged)="reloadVehicles($event)"></partner-account-new-modal>

