<div class="container road-speeds">
  <div class="message-panel">
    <div *ngIf="(successMessage | async)" class="alert alert-success alert-dismissible" role="alert">
      <button type="button" class="close" (click)="successMessage=''">
        <span>&times;</span>
      </button>
      <strong>{{successMessage | async}}</strong>
    </div>
    <div *ngIf="(errorMessage | async)" class="row alert alert-danger" role="alert">
      <label [innerHtml]="'common.Error' | translate"></label>
      <span>{{errorMessage | async}}</span>
      <button type="button" class="close" (click)="errorMessage=null">
        <span>&times;</span>
      </button>
    </div>
  </div>

  <div class="panel list-page-panel" *ngIf="isRoadSpeedsEnabled">

    <div class="row panel action-panel">
      <div class="col-xs-4">&nbsp;</div>
      <div class="col-xs-4">
        <h4 class="text-center">
          <span [innerHtml]="'views.road-speeds.Title' | translate"></span>
        </h4>
      </div>
      <div class="col-xs-4">&nbsp;</div>
    </div>

    <div *ngIf="showGlobalRoadSpeeds">
      <div class="row speed-settings" >
        <form [formGroup]="speedTypeForm" novalidate>
          <div class="row">
            <label class="col-md-3 col-sm-3 col-xs-3">
              <span><strong>Select Speed Setting</strong></span>
              <select class="" formControlName="speedSelection" (change)="onSettingDropdown(speedTypeForm.value.speedSelection)">
                <option value="global">{{'views.road-speeds.universal' | translate}}</option>
                <option value="juris">{{'views.road-speeds.local' | translate}}</option>
              </select>
            </label>
            <div class="col-md-2 col-sm-2 col-xs-2"></div>
            <div class="info-text col-md-7 col-sm-12 col-xs-12">
              <div class="row">
                  <span class="glyphicon glyphicon-exclamation-sign col-md-1 col-sm-1 col-xs-1"></span>
                  <ul class="col-md-11 col-sm-11 col-xs-11">
                  <li><span class="" [innerHtml]="'views.road-speeds.infoText1' | translate"></span></li>
                  <li><span class="" [innerHtml]="'views.road-speeds.infoText2' | translate"></span></li>
                  <li><span class="" [innerHtml]="'views.road-speeds.infoText3' | translate"></span></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">

          </div>
        </form>
      </div>
    </div>

    <div class="panel-body" [ngClass]="{'add-margin': (showGlobalRoadSpeeds)}">
      <form [formGroup]="form" (ngSubmit)="save()" novalidate>
        <div class="speeds-container" [ngStyle]="{'min-height': selectedSpeedType ==='global' ? '500px' : ''}">

          <div class="row">
            <div class="col-sm-3 col-xs-3 col-md-3" [ngStyle]="{'margin-left': (!showGlobalRoadSpeeds) ? '15px' : ''}">
              <label>
                <span>{{'views.road-speeds.vehicleType' | translate}}
                  <i class="glyphicon glyphicon-question-sign" data-container="body" [tooltip]="'views.routing-profile-detail.tooltip.VehicleType' | translate"></i>
                </span>
                <ng-select class="custom" [items]="vehicleTypes" formControlName="vehicleType" bindLabel="text" bindValue="id"
                  (change)="onVehicleTypeChanged()" [clearable]="false">
                  <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div class="type-item clearfix">
                      <i class="ico icon-large" [ngClass]="item.icon"></i>
                      <div class="type-text vehicle-type-text">{{item.text}}</div>
                    </div>
                  </ng-template>
                </ng-select>
              </label>
            </div>
            <div class="col-sm-3" *ngIf="selectedSpeedType==='juris'">
            </div>
          </div>

          <div class="row tab-like" *ngIf="showGlobalRoadSpeeds">
            <span class="tab-group">
              <span (click)="onTabChange('global')" [ngClass]="{'active': selectedSpeedType ==='global'}"> {{'views.road-speeds.universalTab' | translate}}
                  <i class="glyphicon glyphicon-question-sign" data-container="body"[tooltip]="'views.road-speeds.universalHelp' | translate"></i>
              </span>
              <span (click)="onTabChange('juris')" [ngClass]="{'active': selectedSpeedType ==='juris', 'disabled': speedSetting ==='global' }">{{'views.road-speeds.JurisTab' | translate}}
                  <i class="glyphicon glyphicon-question-sign" data-container="body"[tooltip]="'views.road-speeds.JurisHelp' | translate"></i>
              </span>
              <!--[disabled]="this.speedSetting === 'global'"-->
            </span>
          </div>

          <div class="speeds-table row">
            <div class="col-sm-4 col-xs-4 col-md-4" *ngIf="selectedSpeedType==='juris'">
              <ul class="parent-list" *ngIf="region ==='NA'">
                <li *ngFor="let country of countryList">
                  <div>
                    <strong (click)="changeVisibility(country)"> {{country}} </strong>
                    <ul *ngIf="this.jurisdictionList[country].show" class="sub-list">
                      <li *ngFor="let state of jurisdictionList[country]" (click)="onJurisdictionChange(state.stateAbbr, state.stateName, state.areSpeedsDefault, false)"
                        [ngClass]="{'selected-item': state.stateAbbr === this.jurisAbbr }">
                        <div class="text-ellipsis">{{state.stateName}}
                          <span class="change-asterix" *ngIf="!state.areSpeedsDefault">*</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
              <ul class="parent-list" *ngIf="region ==='EU'">
                <li *ngFor="let country of jurisdictionList" [ngClass]="{'selected-item': country.countryAbbr === this.jurisAbbr }">
                  <div>
                    <strong (click)="onJurisdictionChange(country.countryAbbr, country.countryName, country.areSpeedsDefault, false)">
                      {{country.countryName}}
                      <span class="change-asterix" *ngIf="!country.areSpeedsDefault">*</span>
                    </strong>
                  </div>
                </li>
              </ul>
              <div *ngIf="selectedSpeedType==='juris'" class="notes bottom-text">
                <span class="change-asterix">*</span> {{'views.road-speeds.customJuris' | translate}}</div>
            </div>
            <div class="col-sm-3 col-xs-3 col-md-3" *ngIf="selectedSpeedType==='global'"></div>
            <div class="col-sm-7 col-xs-7 col-md-7">
              <div class="ng-cloak" *ngIf="isProcessing" ng-cloak>
                <span class="glyphicon glyphicon-refresh spinner"></span> {{'common.Loading' | translate}}
              </div>
              <div *ngIf="!isProcessing">
                <div class="form-group row" *ngIf="selectedSpeedType==='juris'">
                  <span class="name-label">{{jurisName}}</span>
                </div>
                <div class="form-group row" *ngIf="selectedSpeedType==='juris'">
                  <label class="col-md-4 col-sm-4 col-xs-4">&nbsp;</label>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <label for="interstate" class="col-md-2 col-sm-2 col-xs-2" [innerHtml]="'views.road-speeds.rural' | translate">
                    </label>
                  </div>
                  <div class="col-md-2 col-sm-2 col-xs-2">
                    <label for="interstate" class="col-md-2 col-sm-2 col-xs-2" [innerHtml]="'views.road-speeds.urban' | translate">
                    </label>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="interstate" class="col-md-4 col-sm-4 col-xs-4" [innerHtml]="'views.road-speeds.interstate' | translate"></label>
                  <div class="col-md-2 col-sm-2 col-xs-2" [ngClass]="{ 'has-error': !form.controls.InterStateRural.valid}">
                    <input class="form-control input-sm" id="InterStateRural" name="InterStateRural" type="number"
                      formControlName="InterStateRural" min="5" max="maxSpeed" step="1" required />
                    <div class="glyphicon glyphicon-exclamation-sign error-glyph error-glyph-2x" *ngIf="(!form.controls.InterStateRural.valid)">
                      <div class="error-tooltip bg-danger">
                        <p *ngIf="form.controls.InterStateRural.errors?.required">{{ 'validation.numberRequired' |
                          translate}}</p>
                        <p *ngIf="form.controls.InterStateRural.errors?.incrementBy">{{ 'common.Increment' | translate:
                          {increment: form.controls.InterStateRural.errors.incrementBy.increments}
                          }}
                        </p>
                        <p *ngIf="form.controls.InterStateRural.errors?.min || form.controls.InterStateRural.errors?.max">
                          {{ 'common.FallsBetween' | translate: {min: minSpeed, max: maxSpeed} }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-2 col-xs-2" *ngIf="selectedSpeedType ==='juris'" [ngClass]="{ 'has-error': !form.controls.InterStateUrban.valid}">
                    <input class="form-control input-sm" id="InterStateUrban" name="InterStateUrban" type="number"
                      formControlName="InterStateUrban" min="minSpeed" max="maxSpeed" step="1" required />
                    <div class="glyphicon glyphicon-exclamation-sign error-glyph error-glyph-2x" *ngIf="(!form.controls.InterStateUrban.valid)">
                      <div class="error-tooltip bg-danger">
                        <p *ngIf="form.controls.InterStateUrban.errors?.required">{{ 'validation.numberRequired' |
                          translate}}</p>
                        <p *ngIf="form.controls.InterStateUrban.errors?.incrementBy">{{ 'common.Increment' | translate:
                          {increment: form.controls.InterStateUrban.errors.incrementBy.increments}
                          }}
                        </p>
                        <p *ngIf="form.controls.InterStateUrban.errors?.min || form.controls.InterStateUrban.errors?.max">
                          {{ 'common.FallsBetween' | translate: {min: minSpeed, max: maxSpeed} }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-2 col-xs-2 units">{{units}}<i *ngIf="showUnitConversionTooltip" style = "margin-top:-1px"class="glyphicon glyphicon-info-sign" data-container="body" [tooltip]="showInterstateSpeedConversionInfo()"></i></div>
                </div>
                <div class="form-group row">
                  <label for="DividedRural" class="col-md-4 col-sm-4 col-xs-4" [innerHtml]="'views.road-speeds.major' | translate"></label>
                  <div class="col-md-2 col-sm-2 col-xs-2" [ngClass]="{ 'has-error': !form.controls.DividedRural.valid}">
                    <input class="form-control input-sm" id="DividedRural" name="DividedRural" type="number"
                      formControlName="DividedRural" min="minSpeed" max="maxSpeed" step="1" required />
                    <div class="glyphicon glyphicon-exclamation-sign error-glyph error-glyph-2x" *ngIf="!form.controls.DividedRural.valid">
                      <div class="error-tooltip bg-danger">
                        <p *ngIf="form.controls.DividedRural.errors?.required">{{ 'validation.numberRequired' |
                          translate}}</p>
                        <p *ngIf="form.controls.DividedRural.errors?.incrementBy">{{ 'common.Increment' | translate:
                          {increment: form.controls.DividedRural.errors.incrementBy.increments}
                          }}
                        </p>
                        <p *ngIf="form.controls.DividedRural.errors?.min || form.controls.DividedRural.errors?.max">
                          {{ 'common.FallsBetween' | translate: {min: minSpeed, max: maxSpeed} }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-2 col-xs-2" [ngClass]="{ 'has-error':  !form.controls.DividedUrban.valid}"
                    *ngIf="selectedSpeedType ==='juris'">
                    <input class="form-control input-sm" id="DividedUrban" name="DividedUrban" type="number"
                      formControlName="DividedUrban" min="minSpeed" max="maxSpeed" step="1" required />
                    <div class="glyphicon glyphicon-exclamation-sign error-glyph error-glyph-2x" *ngIf="!form.controls.DividedUrban.valid">
                      <div class="error-tooltip bg-danger">
                        <p *ngIf="form.controls.DividedUrban.errors?.required">{{ 'validation.numberRequired' |
                          translate}}</p>
                        <p *ngIf="form.controls.DividedUrban.errors?.incrementBy">{{ 'common.Increment' | translate:
                          {increment: form.controls.DividedUrban.errors.incrementBy.increments}
                          }}
                        </p>
                        <p *ngIf="form.controls.DividedUrban.errors?.min || form.controls.DividedUrban.errors?.max">
                          {{ 'common.FallsBetween' | translate: {min: minSpeed, max: maxSpeed} }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-2 col-xs-2 units">{{units}} <i *ngIf="showUnitConversionTooltip" style = "margin-top:-1px"class="glyphicon glyphicon-info-sign" data-container="body" [tooltip]="showDividedSpeedConversionInfo()"></i></div>
                </div>
                <div class="form-group row">
                  <label for="PrimaryRural" class="col-md-4 col-sm-4 col-xs-4" [innerHtml]="'views.road-speeds.primary' | translate"></label>
                  <div class="col-md-2 col-sm-2 col-xs-2" [ngClass]="{ 'has-error': !form.controls.PrimaryRural.valid}">
                    <input class="form-control input-sm" id="PrimaryRural" name="PrimaryRural" type="number"
                      formControlName="PrimaryRural" min="minSpeed" max="maxSpeed" step="1" required />
                    <div class="glyphicon glyphicon-exclamation-sign error-glyph error-glyph-2x" *ngIf="!form.controls.PrimaryRural.valid">
                      <div class="error-tooltip bg-danger">
                        <p *ngIf="form.controls.PrimaryRural.errors?.required">{{ 'validation.numberRequired' |
                          translate}}</p>
                        <p *ngIf="form.controls.PrimaryRural.errors?.incrementBy">{{ 'common.Increment' | translate:
                          {increment: form.controls.PrimaryRural.errors.incrementBy.increments}
                          }}
                        </p>
                        <p *ngIf="form.controls.PrimaryRural.errors?.min || form.controls.PrimaryRural.errors?.max">
                          {{ 'common.FallsBetween' | translate: {min: minSpeed, max: maxSpeed} }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-2 col-xs-2" [ngClass]="{ 'has-error': !form.controls.PrimaryUrban.valid}"
                    *ngIf="selectedSpeedType ==='juris'">
                    <input class="form-control input-sm" id="PrimaryUrban" name="PrimaryUrban" type="number"
                      formControlName="PrimaryUrban" min="minSpeed" max="maxSpeed" step="1" required />
                    <div class="glyphicon glyphicon-exclamation-sign error-glyph error-glyph-2x" *ngIf="!form.controls.PrimaryUrban.valid">
                      <div class="error-tooltip bg-danger">
                        <p *ngIf="form.controls.PrimaryUrban.errors?.required">{{ 'validation.numberRequired' |
                          translate}}</p>
                        <p *ngIf="form.controls.PrimaryUrban.errors?.incrementBy">{{ 'common.Increment' | translate:
                          {increment: form.controls.PrimaryUrban.errors.incrementBy.increments}
                          }}
                        </p>
                        <p *ngIf="form.controls.PrimaryUrban.errors?.min || form.controls.PrimaryUrban.errors?.max">
                          {{ 'common.FallsBetween' | translate: {min: minSpeed, max: maxSpeed} }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-2 col-xs-2 units">{{units}} <i *ngIf="showUnitConversionTooltip" style = "margin-top:-1px"class="glyphicon glyphicon-info-sign" data-container="body" [tooltip]="showPrimarySpeedConversionInfo()"></i></div>
                </div>

                <div class="form-group row">
                  <label for="SecondaryRural" class="col-md-4 col-sm-4 col-xs-4" [innerHtml]="'views.road-speeds.secondary' | translate"></label>
                  <div class="col-md-2 col-sm-2 col-xs-2" [ngClass]="{ 'has-error': !form.controls.SecondaryRural.valid}">
                    <input class="form-control input-sm" id="SecondaryRural" name="SecondaryRural" type="number"
                      formControlName="SecondaryRural" min="minSpeed" max="maxSpeed" step="1" required />
                    <div class="glyphicon glyphicon-exclamation-sign error-glyph error-glyph-2x" *ngIf="!form.controls.SecondaryRural.valid">
                      <div class="error-tooltip bg-danger">
                        <p *ngIf="form.controls.SecondaryRural.errors?.required">{{ 'validation.numberRequired' |
                          translate}}</p>
                        <p *ngIf="form.controls.SecondaryRural.errors?.incrementBy">{{ 'common.Increment' | translate:
                          {increment: form.controls.SecondaryRural.errors.incrementBy.increments}
                          }}
                        </p>
                        <p *ngIf="form.controls.SecondaryRural.errors?.max || form.controls.SecondaryRural.errors?.min">
                          {{ 'common.FallsBetween' | translate: {min: minSpeed, max: maxSpeed} }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-2 col-xs-2" [ngClass]="{ 'has-error': !form.controls.SecondaryUrban.valid}"
                    *ngIf="selectedSpeedType ==='juris'">
                    <input class="form-control input-sm" id="SecondaryUrban" name="SecondaryUrban" type="number"
                      formControlName="SecondaryUrban" min="minSpeed" max="maxSpeed" step="1" required />
                    <div class="glyphicon glyphicon-exclamation-sign error-glyph error-glyph-2x" *ngIf="!form.controls.SecondaryUrban.valid">
                      <div class="error-tooltip bg-danger">
                        <p *ngIf="form.controls.SecondaryUrban.errors?.required">{{ 'validation.numberRequired' |
                          translate}}</p>
                        <p *ngIf="form.controls.SecondaryUrban.errors?.incrementBy">{{ 'common.Increment' | translate:
                          {increment: form.controls.SecondaryUrban.errors.incrementBy.increments}
                          }}
                        </p>
                        <p *ngIf="form.controls.SecondaryUrban.errors?.max || form.controls.SecondaryUrban.errors?.min">
                          {{ 'common.FallsBetween' | translate: {min: minSpeed, max: maxSpeed} }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-2 col-xs-2 units">{{units}} <i *ngIf="showUnitConversionTooltip" style = "margin-top:-1px" class="glyphicon glyphicon-info-sign" data-container="body" [tooltip]="showSecondarySpeedConversionInfo()"></i></div>
                </div>
                <div class="form-group row">
                  <label for="LocalRural" class="col-md-4 col-sm-4 col-xs-4" [innerHtml]="'views.road-speeds.localRoads' | translate"></label>
                  <div class="col-md-2 col-sm-2 col-xs-2" [ngClass]="{ 'has-error': !form.controls.LocalRural.valid}">
                    <input class="form-control input-sm" id="LocalRural" name="LocalRural" type="number"
                      formControlName="LocalRural" min="minSpeed" max="maxSpeed" step="1" required />
                    <div class="glyphicon glyphicon-exclamation-sign error-glyph error-glyph-2x" *ngIf="!form.controls.LocalRural.valid">
                      <div class="error-tooltip bg-danger">
                        <p *ngIf="form.controls.LocalRural.errors?.required">{{ 'validation.numberRequired' |
                          translate}}</p>
                        <p *ngIf="form.controls.LocalRural.errors?.incrementBy">{{ 'common.Increment' | translate:
                          {increment: form.controls.LocalRural.errors.incrementBy.increments}
                          }}
                        </p>
                        <p *ngIf="form.controls.LocalRural.errors?.max || form.controls.LocalRural.errors?.min">
                          {{ 'common.FallsBetween' | translate: {min: minSpeed, max: maxSpeed} }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-2 col-xs-2" [ngClass]="{ 'has-error': !form.controls.LocalUrban.valid}"
                    *ngIf="selectedSpeedType ==='juris'">
                    <input class="form-control input-sm" id="LocalUrban" name="LocalUrban" type="number"
                      formControlName="LocalUrban" min="minSpeed" max="maxSpeed" step="1" required />
                    <div class="glyphicon glyphicon-exclamation-sign error-glyph error-glyph-2x" *ngIf="!form.controls.LocalUrban.valid">
                      <div class="error-tooltip bg-danger">
                        <p *ngIf="form.controls.LocalUrban.errors?.required">{{ 'validation.numberRequired' |
                          translate}}</p>
                        <p *ngIf="form.controls.LocalUrban.errors?.incrementBy">{{ 'common.Increment' | translate:
                          {increment: form.controls.LocalUrban.errors.incrementBy.increments}
                          }}
                        </p>
                        <p *ngIf="form.controls.LocalUrban.errors?.min || form.controls.LocalUrban.errors?.max">
                          {{ 'common.FallsBetween' | translate: {min: minSpeed, max: maxSpeed} }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-2 col-xs-2 units">{{units}} <i *ngIf="showUnitConversionTooltip" style = "margin-top:-1px"class="glyphicon glyphicon-info-sign" data-container="body" [tooltip]="showLocalSpeedConversionInfo()"></i></div>
                </div>
                <div class="form-group row" *ngIf="selectedSpeedType ==='juris'">
                  <label for="ferries" class="col-md-4 col-sm-4 col-xs-4" [innerHtml]="'views.road-speeds.ferries' | translate"></label>
                  <div class="col-md-2 col-sm-2 col-xs-2" [ngClass]="{ 'has-error': !form.controls.FerriesRural.valid}">
                    <input class="form-control input-sm" id="FerriesRural" name="FerriesRural" type="number"
                      formControlName="FerriesRural" min="minSpeed" max="maxSpeed" step="1" required />
                    <div class="glyphicon glyphicon-exclamation-sign error-glyph error-glyph-2x" *ngIf="!form.controls.FerriesRural.valid">
                      <div class="error-tooltip bg-danger">
                        <p *ngIf="form.controls.FerriesRural.errors?.required">{{ 'validation.numberRequired' |
                          translate}}</p>
                        <p *ngIf="form.controls.FerriesRural.errors?.incrementBy">{{ 'common.Increment' | translate:
                          {increment: form.controls.FerriesRural.errors.incrementBy.increments}
                          }}
                        </p>
                        <p *ngIf="form.controls.FerriesRural.errors?.min || form.controls.FerriesRural.errors?.max">
                          {{ 'common.FallsBetween' | translate: {min: minSpeed, max: maxSpeed} }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-2 col-xs-2" [ngClass]="{ 'has-error': !form.controls.FerriesUrban.valid}">
                    <input class="form-control input-sm" id="FerriesUrban" name="FerriesUrban" type="number"
                      formControlName="FerriesUrban" min="minSpeed" max="maxSpeed" step="1" required />
                    <div class="glyphicon glyphicon-exclamation-sign error-glyph error-glyph-2x" *ngIf="!form.controls.FerriesUrban.valid">
                      <div class="error-tooltip bg-danger">
                        <p *ngIf="form.controls.FerriesUrban.errors?.required">{{ 'validation.numberRequired' |
                          translate}}</p>
                        <p *ngIf="form.controls.FerriesUrban.errors?.incrementBy">{{ 'common.Increment' | translate:
                          {increment: form.controls.FerriesUrban.errors.incrementBy.increments}
                          }}
                        </p>
                        <p *ngIf="form.controls.FerriesUrban.errors?.min || form.controls.FerriesUrban.errors?.max">
                          {{ 'common.FallsBetween' | translate: {min: minSpeed, max: maxSpeed} }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-2 col-xs-2 units">{{units}} <i *ngIf="showUnitConversionTooltip" style = "margin-top:-1px"class="glyphicon glyphicon-info-sign" data-container="body" [tooltip]="showFerrySpeedConversionInfo()"></i></div>

                </div>
                <div [ngClass]="{ 'col-md-6 col-xs-6 col-sm-6': selectedSpeedType ==='global','col-md-8 col-sm-8 col-xs-8': selectedSpeedType ==='juris' }"
                  style="margin: 15px 0px">
                  <button type="button" class="btn btn-sm btn-default pull-right" (click)="resetRoadSpeeds()"
                    [disabled]="((areJurisSpeedsDefault && !form.dirty) || resetSelected)">Reset to Default</button>
                </div>
              </div>
            </div>

          </div>

          <div class="pull-right row" [hidden]="isProcessing && selectedSpeedType ==='global'" [ngStyle]="{'margin-top': selectedSpeedType ==='global' ? '45px' : ''}" >
            <button type="button" class="btn btn-default cancel-btn" (click)="cancel()" [disabled]="!form.dirty || isProcessing">
              <span [innerHtml]="'common.Cancel' | translate"></span>
            </button>
            <button type="submit" class="btn btn-success" [disabled]="!form.valid || !form.dirty || isProcessing">
              <i *ngIf="!form.valid" class="glyphicon glyphicon-exclamation-sign error-glyph"></i>
              <span *ngIf="selectedSpeedType ==='global'" [innerHtml]="'views.road-speeds.save.Global' | translate"></span>
              <span *ngIf="selectedSpeedType ==='juris'" [innerHtml]="'views.road-speeds.save.Jurisdiction' | translate"></span>
            </button>
          </div>
        </div>
      </form>

    </div>

  </div>
  <div class="panel-body" *ngIf="!isRoadSpeedsEnabled">
    This feature is Disabled.
  </div>
</div>

<confirmation-modal #confirmModal title="{{ 'views.unsaved-changes-modal.title' | translate}}" body="{{ 'views.changes-lost-modal.body' | translate}}"></confirmation-modal>
<confirmation-modal #globalModal title="{{ 'views.road-speeds.warning' | translate}}" body="{{ 'view.road-speeds.applyGlobal' | translate }}"></confirmation-modal>
<confirmation-modal #jurisModal title="{{ 'views.road-speeds.warning' | translate}}" body="{{ 'view.road-speeds.applyJurisdiction' | translate }}"></confirmation-modal>
