import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule, Title } from "@angular/platform-browser";
import { NgSelectModule } from "@ng-select/ng-select";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { Angulartics2, Angulartics2Module } from "angulartics2";
import { Angulartics2GoogleAnalytics } from "angulartics2/ga";
import {
  AccordionModule,
  BsDropdownModule,
  ModalModule,
  TabsModule,
  TooltipModule,
} from "ngx-bootstrap";
import { ALKCopyright } from "./alk-components/alk-copyright";
import {
  ALKLanguageContext,
  ALKLanguageSelect,
} from "./alk-components/alk-translations";
import { UserContextService } from "./alk-components/authentication";
import { Autofocus } from "./alk-components/autofocus";
import { Admin, AdminIntegration, AdminUsers } from "./app/admin";
import { AccountEditModal } from "./app/admin/account-edit-modal";
import { CompanySettingsService } from "./app/admin/company-settings";
import { DataRetentionPolicyModal } from "./app/admin/data-retention-policy-modal";
import { AdminUserEditModal } from "./app/admin/users/admin-user-edit-modal.component";
import { AdminUserReplaceModal } from "./app/admin/users/admin-user-replace-modal.component";
import { AppComponent } from "./app/app.component";
import { appRoutingProviders, routing } from "./app/app.routes";
import { AvoidFavorSetDetail, AvoidFavorSetList } from "./app/avoid-favor-sets";
import {
  ConfigurationProfileDetail,
  ConfigurationProfileList,
} from "./app/configuration-profile";
import {
  CustomPlaceSetDetail,
  CustomPlaceSetList,
} from "./app/custom-place-sets";
import { DriverEditModal, DriverImport, DriverList } from "./app/driver";
import { DriverGroupDetail, DriverGroupList } from "./app/driver-group";
import { Landing } from "./app/landing";
import { Login } from "./app/login";
import { ConnectionSettingsComponent } from "./app/mapsets/connection-settings/connection-settings.component";
import { MapRegionsComponent } from "./app/mapsets/mapsets-detail/map-regions/map-regions.component";
import { MapsetsDetailComponent } from "./app/mapsets/mapsets-detail/mapsets-detail.component";
import { MapsetsDetailGuardService } from "./app/mapsets/mapsets-detail/mapsets-detail.guard.service";
import { MapsetsListComponent } from "./app/mapsets/mapsets-list/mapsets-list.component";
import { PartnerAccountSelector } from "./app/partner-account-selector";
import { PartnerAccountList } from "./app/partner-account-selector/partner-account-list";
import { PartnerAccountMoveModal } from "./app/partner-account-selector/partner-account-move-modal/partner-account-move-modal.component";
import { PartnerAccountNewModal } from "./app/partner-account-selector/partner-account-new-modal/partner-account-new-modal.component";
import { RoadSpeedsProfile } from "./app/road-speeds";
import {
  RoutingProfileDetail,
  RoutingProfileList,
} from "./app/routing-profile";
import {
  AuthenticationService,
  ConfirmationModal,
  DisableFCDirective,
  FleetLinkUserService,
  HighlightPipe,
  PagingService,
  StringIfNullPipe,
} from "./app/shared";
import { AlkCloudMenu } from "./app/shared/alk-cloud";
import { SSOTokenChecker } from "./app/shared/authentication";
import { MarketingFooter } from "./app/shared/footer";
import {
  GroupAssociationsComponent,
  SingleSelectGroupAssociationsComponent,
} from "./app/shared/group-associations";
import {
  GroupAddModal,
  SingleSelectGroupAddModal,
} from "./app/shared/group-associations/group-add-modal";
import { GroupAddPaginatedModal } from "./app/shared/group-associations/group-add-paginated-modal";
import { AppHeader } from "./app/shared/header";
import { AuthenticationInterceptor } from "./app/shared/http/authentication.interceptor";
import { FileSizeFormatterPipe } from "./app/shared/pipes/file-size-formatter.pipe";
import { TidModule } from "./app/shared/tid/tid.module";
import { GroupsUtil, Utils } from "./app/shared/utils";
import { SpeedCoachingDetail, SpeedCoachingList } from "./app/speed-coaching";
import { AcceptTerms, ReadTerms, Terms } from "./app/terms";
import { UserSettings } from "./app/user-settings";
import { VehicleEditModal, VehicleImport, VehicleList } from "./app/vehicle";
import {
  ConfigurationProfileAssociations,
  RoutingProfileAssociationsComponent,
  RoutingProfileModal,
  SelectVehiclesModal,
  VehicleGroupDetail,
  VehicleGroupList,
  VehicleGroupService,
} from "./app/vehicle-group";
import { VehicleAssociationList } from "./app/vehicle-group/vehicle-group-detail/vehicle-list";
import {
  VehicleRequestResponseModal,
  VehicleSummary,
} from "./app/vehicle-summary";
import { AzureComponent } from "./app/azure";

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) =>
          new TranslateHttpLoader(http, "/assets/i18n/", ".json"),
        deps: [HttpClient],
      },
    }),
    Angulartics2Module.forRoot(),
    NgSelectModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    AccordionModule.forRoot(),
    TidModule,
    routing,
  ],
  declarations: [
    AlkCloudMenu,
    AppComponent,
    AppHeader,
    MarketingFooter,
    PartnerAccountSelector,
    PartnerAccountList,
    ALKCopyright,
    Landing,
    Login,
    ReadTerms,
    AcceptTerms,
    Terms,
    AzureComponent,
    UserSettings,
    ALKLanguageSelect,
    AvoidFavorSetList,
    AvoidFavorSetDetail,
    CustomPlaceSetList,
    CustomPlaceSetDetail,
    RoutingProfileList,
    RoutingProfileDetail,
    ConfigurationProfileList,
    ConfigurationProfileDetail,
    SpeedCoachingList,
    SpeedCoachingDetail,
    RoadSpeedsProfile,
    VehicleGroupList,
    VehicleGroupDetail,
    VehicleAssociationList,
    SelectVehiclesModal,
    ConfigurationProfileAssociations,
    DriverGroupList,
    DriverGroupDetail,
    VehicleSummary,
    PartnerAccountMoveModal,
    PartnerAccountNewModal,
    Admin,
    AdminIntegration,
    AdminUsers,
    AccountEditModal,
    DataRetentionPolicyModal,
    AdminUserEditModal,
    AdminUserReplaceModal,
    VehicleList,
    VehicleEditModal,
    VehicleRequestResponseModal,
    VehicleImport,
    GroupAddModal,
    GroupAddPaginatedModal,
    SingleSelectGroupAddModal,
    GroupAssociationsComponent,
    SingleSelectGroupAssociationsComponent,
    RoutingProfileAssociationsComponent,
    RoutingProfileModal,
    DriverEditModal,
    DriverList,
    DriverImport,
    Autofocus,
    HighlightPipe,
    StringIfNullPipe,
    ConfirmationModal,
    DisableFCDirective,
    MapsetsListComponent,
    ConnectionSettingsComponent,
    FileSizeFormatterPipe,
    MapsetsDetailComponent,
    MapRegionsComponent,
  ],
  providers: [
    appRoutingProviders,
    Angulartics2,
    Angulartics2GoogleAnalytics,
    Utils,
    GroupsUtil,
    Title,
    ALKLanguageContext,
    UserContextService,
    AuthenticationService,
    FleetLinkUserService,
    PagingService,
    SSOTokenChecker,
    VehicleGroupService,
    MapsetsDetailGuardService,
    CompanySettingsService,
    {
      provide: APP_INITIALIZER,
      useFactory: (tokenChecker: SSOTokenChecker) => () =>
        tokenChecker.loginViaCookie(),
      deps: [SSOTokenChecker],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
