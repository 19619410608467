import { FormControl } from '@angular/forms';

// Validates that the numeric data falls between the supplied min/max
export function validateNumericRange(getForm: any, min: number, max: number) {
   return (fc: FormControl) => {
    const value = fc.value;

    const form = getForm();
    if (!form || !form.value) { return null; }

    if (value >= min && value <= max) { return null; }

    return { validData: { min, max } };
  };
}
