<div bsModal #modal="bs-modal" [config]="{ keyboard: false, backdrop: 'static' }" class="modal fade" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">{{ addMode ? ('common.New' | translate) : ('common.Edit' | translate) }} {{'views.admin-user-edit.User' | translate}}</h4>
      </div>
      <form [formGroup]="form" (ngSubmit)="save()" novalidate>
        <div class="modal-body tab-modal">
          <div class="row header-panel">
            <label class="col-xs-6" [ngClass]="{'has-error': !form.controls.firstName.valid }">
              <span [innerHtml]="'views.user-settings.FirstName' | translate"></span>
              <input type="text" class="form-control input-sm" formControlName="firstName" [disabled]="isProcessing" />
              <div *ngIf="(!form.controls.firstName.valid)" class="glyphicon glyphicon-exclamation-sign error-glyph">
                <div class="error-tooltip">
                  <p *ngIf="form.controls.firstName.errors?.required">{{ 'common.FieldIsRequired' | translate }}</p>
                  <p *ngIf="form.controls.firstName.errors?.maxlength">{{ 'common.MustBeLessThanChars' | translate: {maxChars: form.controls.firstName.errors?.maxlength.requiredLength} }}</p>
                  <p *ngIf="form.controls.firstName.errors?.minlength">{{ 'common.MustBeMoreThanChars' | translate:{minchars: form.controls.firstName.errors?.minlength.requiredLength} }}</p>
                </div>
              </div>
            </label>

            <label class="col-xs-6" [ngClass]="{'has-error': !form.controls.lastName.valid }">
              <span [innerHtml]="'views.user-settings.LastName' | translate"></span>
              <input type="text" class="form-control input-sm" formControlName="lastName" [disabled]="isProcessing" />
              <div *ngIf="(!form.controls.lastName.valid)" class="glyphicon glyphicon-exclamation-sign error-glyph">
                <div class="error-tooltip">
                  <p *ngIf="form.controls.lastName.errors?.required">{{ 'common.FieldIsRequired' | translate }}</p>
                  <p *ngIf="form.controls.lastName.errors?.maxlength">{{ 'common.MustBeLessThanChars' | translate: {maxChars: form.controls.lastName.errors?.maxlength.requiredLength} }}</p>
                  <p *ngIf="form.controls.lastName.errors?.minlength">{{ 'common.MustBeMoreThanChars' | translate:{minchars: form.controls.lastName.errors?.minlength.requiredLength} }}</p>
                </div>
              </div>
            </label>
            <label class="col-xs-6" [ngClass]="{'has-error': !form.controls.emailAddress.valid }">
              <span [innerHtml]="'views.user-settings.EmailAddress' | translate"></span>
              <input type="text" class="form-control input-sm" [readonly]="!addMode" formControlName="emailAddress" [disabled]="isProcessing" />
              <div *ngIf="(!form.controls.emailAddress.valid)" class="glyphicon glyphicon-exclamation-sign error-glyph">
                <div class="error-tooltip">
                  <p *ngIf="form.controls.emailAddress.errors?.required">{{ 'common.FieldIsRequired' | translate }}</p>
                  <p *ngIf="form.controls.emailAddress.errors?.maxlength">{{ 'common.MustBeLessThanChars' | translate: {maxChars: form.controls.emailAddress.errors?.maxlength.requiredLength} }}</p>
                  <p *ngIf="form.controls.emailAddress.errors?.minlength">{{ 'common.MustBeMoreThanChars' | translate:{minchars: form.controls.emailAddress.errors?.minlength.requiredLength} }}</p>
                  <p *ngIf="form.controls.emailAddress.errors?.alkValidEmailAddress">{{ 'common.InvalidEmailAddress' | translate }}</p>
                </div>
              </div>
            </label>

            <label class="col-xs-6">
              <span [innerHtml]="'views.admin-user-edit.Role' | translate"></span>
              <select class="form-control input-sm" formControlName="role">
                <option *ngFor="let option of roles" [value]="option.value">{{option.translationKey | translate}}</option>
              </select>
            </label>
          </div>

          <div class="row tab-panel">
            <tabset>
              <tab heading="{{'views.admin-users-edit.VehicleGroups' | translate}}">
                <group-associations
                  #vehicleGroupAssociations
                  entityLabel="{{'views.admin-users-edit.VehicleGroups' | translate}}"
                  [displayPerPage]="5">
                </group-associations>
              </tab>
              <tab heading="{{'views.admin-users-edit.DriverGroups' | translate}}">
                <group-associations
                  #driverGroupAssociations
                  entityLabel="{{'views.admin-users-edit.DriverGroups' | translate}}"
                  [displayPerPage]="5">
                </group-associations>
              </tab>
            </tabset>
          </div>

          <div class="row" style="margin-top: 15px;">
            <div class="col-md-12">
              <div *ngIf="(emailErrorMsg | async)" class="alert alert-danger" role="alert">
                  <label [innerHtml]="'common.Error' | translate"></label>
                  <span [innerHtml]="emailErrorMsg | async"></span>
              </div>
              <div *ngIf="(errorMsg | async)" class="alert alert-danger" role="alert">
                <label [innerHtml]="'common.Error' | translate"></label>
                <span [innerHtml]="errorMsg | async"></span>
              </div>
            </div>
          </div>

        </div>
        <div class="clearfix"></div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default cancel-btn" [disabled]="isProcessing" (click)="close()">{{ 'common.Cancel' | translate }}</button>
          <button type="submit" class="btn btn-default" [disabled]="isProcessing || !form.valid">
            <i *ngIf="!form.valid" class="glyphicon glyphicon-exclamation-sign error-glyph"></i>
            <span>{{ addMode ? ('common.Save' | translate) : ('common.Update' | translate) }}</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<confirmation-modal #confirmModal title="{{ 'views.unsaved-changes-modal.title' | translate}}" body="{{ 'views.unsaved-changes-modal.body' | translate}}"></confirmation-modal>
