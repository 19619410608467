
import {map} from 'rxjs/operators';
// angular components
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Injectable()
export class FleetLinkUserService {

  constructor(private http: HttpClient) { }

  getCurrentUser(ssoUser: any) {
    const headers = new HttpHeaders({ Authorization: `Bearer ${ssoUser.authToken}`, 'Content-Type': 'application/json; charset=UTF-8' });
    const options = { headers };

    const url = environment.apiUrl + '/v1/users/me';

    return this.http.get<any>(url, options).pipe(
      map(c => {
        const user = c;
        user.region =  user.regionalDataSet.indexOf('EU') >= 0 ? 'EU' : 'NA';
        return user;
      }));
  }
}
