<div class="container">
  <div class="message-panel">
    <div
      *ngIf="accountUpdated == true"
      class="alert alert-success"
      role="alert"
    >
      <strong>Company profile successfully updated</strong>
    </div>
  </div>

  <div class="panel page-panel">
    <div class="row panel action-panel">
      <div class="col-xs-4">&nbsp;</div>
      <div class="col-xs-4">
        <h4 class="text-center">
          <span
            [innerHtml]="
              'views.company-adminstration.CompanyAdministration' | translate
            "
          ></span>
        </h4>
      </div>
      <div class="col-xs-4">&nbsp;</div>
    </div>

    <div class="row header-panel">
      <label class="col-xs-5">
        <span
          [innerHtml]="'views.company-adminstration.CompanyName' | translate"
        ></span>
        <input
          type="text"
          class="form-control input-sm"
          readonly
          [ngModel]="currentAccount.name | stringIfNull: ''"
        />
      </label>

      <label class="col-xs-5">
        <span
          [innerHtml]="'views.company-adminstration.ContactName' | translate"
        ></span>
        <input
          type="text"
          class="form-control input-sm"
          readonly
          [ngModel]="currentAccount.contactName | stringIfNull: ''"
        />
      </label>

      <div class="col-xs-2">
        <button
          class="btn btn-default header-button"
          type="button"
          (click)="accountEditModal.open()"
        >
          <span class="glyphicon glyphicon-edit"></span>
          <span [innerHtml]="'common.Edit' | translate"></span>
        </button>
      </div>

      <label class="col-xs-5">
        <span
          [innerHtml]="
            'views.company-adminstration.IntegrationType' | translate
          "
        ></span>
        <input
          type="text"
          class="form-control input-sm"
          readonly
          [ngModel]="currentAccount.integrationType | stringIfNull: ''"
        />
      </label>

      <label class="col-xs-5">
        <span
          [innerHtml]="'views.company-adminstration.ContactEmail' | translate"
        ></span>
        <input
          type="text"
          class="form-control input-sm"
          readonly
          [ngModel]="currentAccount.contactEmail | stringIfNull: ''"
        />
      </label>

      <label class="col-xs-5" *ngIf="isRouteReporterAccount">
        <span [innerHtml]="'views.account.WeeksToSaveData' | translate"></span>
        <input
          type="text"
          class="form-control input-sm"
          readonly
          [ngModel]="currentAccount.numberOfWeeksToSaveData | stringIfNull: ''"
        />
      </label>

      <div class="col-xs-5 share-data-div">
        <input type="checkbox" disabled [ngModel]="shareDataAnonymously" />
        <span
          class="data-setting-text"
          [innerHtml]="
            'views.company-administration.ShareDataAnonymously' | translate
          "
        ></span>
        <span
          class="ico ico-info-circle"
          tooltip="{{
            'views.company-administration.ShareDataTooltip' | translate
          }}"
        ></span>
      </div>
    </div>

    <div class="row tab-panel">
      <tabset>
        <tab
          heading="{{ 'views.company-adminstration.VehiclesTab' | translate }}"
          (click)="onVehiclesTabSelected()"
          *ngIf="vehiclesTabVisible"
        >
          <vehicle-list></vehicle-list>
        </tab>
        <tab
          heading="{{ 'views.company-adminstration.DriversTab' | translate }}"
          (click)="onDriversTabSelected()"
          *ngIf="driversTabVisible"
        >
          <driver-list></driver-list>
        </tab>
        <tab
          heading="{{ 'views.company-adminstration.UsersTab' | translate }}"
          (click)="onUsersTabSelected()"
          *ngIf="usersTabVisible"
        >
          <admin-users></admin-users>
        </tab>
      </tabset>
    </div>
  </div>
</div>

<account-edit-modal
  #accountEditModal
  (accountChanged)="onAccountUpdated($event)"
  (shareDataChanged)="onShareDataUpdated($event)"
  [account]="currentAccount"
  [shareData]="shareDataAnonymously"
></account-edit-modal>
