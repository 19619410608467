<div class="container">

  <div class="message-panel">
    <div *ngIf="(errorMessage | async)" class="row alert alert-danger" role="alert">
      <label [innerHtml]="'common.Error' | translate"></label>
      <span>{{errorMessage | async}}</span>
    </div>
  </div>

  <div class="panel list-page-panel">

    <div class="row panel action-panel">
      <div class="col-xs-4">&nbsp;</div>
      <div class="col-xs-4">
        <h4 class="text-center">
          <span [innerHtml]="'views.custom-place-set-list.Title' | translate"></span>
        </h4>
      </div>
      <div class="col-xs-4">&nbsp;</div>
    </div>

    <div class="row configure-panel">
      <div class="filter-panel">
        <div class="panel-body">
          <label class="col-xs-4">
            <span [innerHtml]="'common.Filter' | translate"></span>
            <input type="text" class="form-control" placeholder="" [formControl]="searchTerm" (keydown.escape)="clearSearchTerm()"/>
          </label>
          <div class="col-xs-4">
          </div>
        </div>
      </div>
    </div>

    <div class="row grid-panel">
      <table class="table table-striped table-hover list-table clickable-row">
        <thead>
          <tr>
            <th (click)="sortAsc=!sortAsc;sortCol='name';sortString('name', sortAsc)" class="sortable-column configuration-profile-name-col">
              <span [innerHtml]="'views.custom-place-set-list.Name' | translate"></span>
              <span class="column-arrows">
              <span *ngIf="sortCol=='name'" class="glyphicon" [ngClass]="{'glyphicon-triangle-top': sortAsc, 'glyphicon-triangle-bottom': !sortAsc}"></span>
              </span>
            </th>
         <th (click)="sortString('vehicleGroupsAbbreviated', sortAsc);sortAsc=!sortAsc;sortCol='vehicleGroupsAbbreviated'" class="sortable-column vehicle-group-abbreviated-list-col">
            <span [innerHtml]="'views.custom-placeSet.tab.VehicleGroups' | translate"></span>
            <span class="column-arrows">
              <span *ngIf="sortCol=='vehicleGroupsAbbreviated'" class="glyphicon" [ngClass]="{'glyphicon-triangle-top': !sortAsc, 'glyphicon-triangle-bottom': sortAsc}"></span>
            </span>
          </th>
        <th (click)="sortNumber('isDefault', sortAsc);sortAsc=!sortAsc;sortCol='isDefault'" class="sortable-column" style = "width:80px;padding-left:5px;padding-right:0px;">
          <span [innerHtml]="'views.set-list.Default' | translate"></span>
          <span class="column-arrows">
            <span *ngIf="sortCol=='isDefault'" class="glyphicon" [ngClass]="{'glyphicon-triangle-top': sortAsc, 'glyphicon-triangle-bottom': !sortAsc}"></span>
          </span>
      </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of pageItems; let i = index">
            <td [routerLink]="['/customPlaceSets', item.id]" class="configuration-profile-name-col">
              <span [innerHtml]="item.name | alkHighlight:searchTerm.value" title={{item.name}}></span>
            </td>
           <td [routerLink]="['/customPlaceSets', item.id]" class="vehicle-list-id-col">
            <span [innerHtml]="item.vehicleGroupsAbbreviated | alkHighlight:searchTerm.value" [title]="getToolTipForGroups(item.vehicleGroups)"></span>
          </td>
          <td>
            <span (click)="isDefaultToggle(item)">
                <span *ngIf="!item.isDefault" class="ico ico-checkbox-unchecked"></span>
                <span *ngIf="item.isDefault" class="ico ico-checkbox-checked"></span>
              </span>
            </td>
          </tr>
          <tr *ngIf="isProcessing">
            <td colspan="3"><span class="glyphicon glyphicon-refresh spinner"></span>  {{'common.Loading' | translate}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row pagination-panel">
      <div class="col-md-4 grid-details">
        {{ 'common.DisplayOf' | translate:{pageStartNumber:pageStart(), pageEndNumber:pageEnd(), total:filteredItems.length} }}
      </div>
      <div class="col-md-8">
        <div class="pull-right">
          <button type="button" class="btn btn-default" (click)="previousPage()" [disabled]="!hasPreviousPage()">
          <span class="glyphicon glyphicon-menu-left"></span>
          <span [innerHtml]="'common.Previous' | translate"></span>
        </button>
          <button type="button" class="btn btn-default" (click)="nextPage()" [disabled]="!hasNextPage()">
          <span [innerHtml]="'common.Next' | translate"></span>
          <span class="glyphicon glyphicon-menu-right"></span>
        </button>
        </div>
      </div>
    </div>

  </div>


</div>

<confirmation-modal #setDefaultConfirmModal title="{{ 'views.custom-place-set-list.SetDefaultTitle' | translate }}" body="{{ 'views.custom-place-set-list.ConfirmDefaultBody' | translate }}"></confirmation-modal>
<!-- <confirmation-modal #confirmModal title="{{ 'views.configuration-profile-list.DeleteTitle' | translate}}" body="{{ 'views.configuration-profile-list.DeleteBody' | translate}}"></confirmation-modal>
<confirmation-modal #deleteGroupWarning title="{{ 'views.configuration-profile-list.DeleteWarningTitle' | translate }}" body="{{ 'views.configuration-profile-list.DeleteWarningBody' | translate:{numberOfAssignedGroups: numberOfAssignedGroups} }}"></confirmation-modal> -->
