
import {of as observableOf,  Subscription } from 'rxjs';

import {switchMap, tap, distinctUntilChanged, debounceTime} from 'rxjs/operators';
// angular components
import {
  Component,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  OnDestroy,
  ElementRef
} from '@angular/core';
import { FormControl } from '@angular/forms';

// 3rd party components
import { ModalDirective } from 'ngx-bootstrap';

// alk
import { GroupItem, GroupItemColumn } from './../group-item';

interface SelectableGroupItem extends GroupItem {
  selected?: boolean;
}

@Component({
  selector: 'group-add-modal',
  templateUrl: './group-add-modal.component.html'
})
// tslint:disable-next-line: component-class-suffix
export class GroupAddModal implements OnInit, OnDestroy, OnChanges {

  @ViewChild('modal', { static: true }) modal: ModalDirective;

  // tslint:disable-next-line: variable-name
  private _columnDefinitions: Array<GroupItemColumn>;
  set columnDefinitions(columnDefinitions: Array<GroupItemColumn>) {
    this._columnDefinitions = columnDefinitions;
  }

  // tslint:disable-next-line: variable-name
  private _allPossibleItems: Array<SelectableGroupItem> = [];
  set allPossibleItems(allPossibleItems: Array<SelectableGroupItem>) {
    this._allPossibleItems = allPossibleItems;
    this.isLoading = false;
  }

  get allPossibleItems() {
    return this._allPossibleItems;
  }

  @Input() excludedGroups: Array<SelectableGroupItem> = [];
  // number of rows of groups to display per page
  @Input() displayPerPage = 10;
  // what are these groups of? if vehicles, use 'Vehicle' here so it will display as 'Vehicle Groups'
  @Input() entityLabel = '';

  @Output() itemsAdded = new EventEmitter();

  isLoading = true;
  allSelected = false;
  errorMessage: string;
  searchTerm = new FormControl();
  total = 0;
  currentPage = 0;
  // allAvailableItems = allPossibleItems - excludedGroups
  allAvailableItems: Array<SelectableGroupItem> = [];
  filteredItems: Array<SelectableGroupItem> = [];
  displayedItems: Array<SelectableGroupItem> = [];
  entityHeading: string;
  sub1: Subscription;

  constructor(
    private el: ElementRef) { }

  ngOnInit() {
    this.sub1 = this.modal.onShown.subscribe(() => {
      // todo: make this another directive to auto focus bootstrap modal fields.
      const nativeElement = this.el.nativeElement.getElementsByTagName('input')[0];
      if (nativeElement) { nativeElement.focus(); }
    });

    this.searchTerm.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => {
        this.isLoading = true;
        this.currentPage = 0;
      }),
      switchMap(searchTerm => this.filterResults(searchTerm)), )
      .subscribe(filteredResults => {
        this.filteredItems = filteredResults;
        this.updateCurrentPage();
        this.isLoading = false;
      });
  }

  ngOnDestroy() {
    this.sub1.unsubscribe();
  }

  ngOnChanges() {
    this.entityHeading = 'Select ' + this.entityLabel; // todo: translate
  }

  open() {
    this.allSelected = false;
    this.currentPage = 0;
    // this.selectedIds = {};
    this.clearSearchTerm();
    this.getAvailableItems();
    this.allAvailableItems.forEach(item => item.selected = false);
    this.displayedItems.forEach(item => item.selected = false);
    this.updateCurrentPage();
    this.modal.show();
  }

  clearSearchTerm() {
    this.searchTerm.setValue('');
  }

  onAllChanged(isSelected) {
    this.allSelected = isSelected;

    this.displayedItems.forEach(item => item.selected = this.allSelected);
  }

  select(item, isSelected) {
    item.selected = isSelected;

    // update all-selected checkbox whenever one of the individual checkboxes is selected
    // or after loading a page of results
    this.allSelected = this.isAllSelected();
  }

  saveSelected() {
    const selectedGroups: Array<GroupItem> = [];
    this.allAvailableItems.forEach(item => {
      if (item.selected) {
        selectedGroups.push({ id: item.id, fields: item.fields, pendingAddition: true });
      }
    });
    this.itemsAdded.emit(selectedGroups);
    this.modal.hide();
  }

  nextPage() {
    if (!this.hasNextPage()) { return; }
    this.currentPage++;

    this.updateCurrentPage();
  }

  previousPage() {
    if (!this.hasPreviousPage()) { return; }
    this.currentPage--;

    this.updateCurrentPage();
  }

  hasNextPage() {
    const nextPageStartIndex = (this.currentPage + 1) * this.displayPerPage;
    return this.filteredItems.length > nextPageStartIndex;
  }

  hasPreviousPage() {
    return this.currentPage > 0;
  }

  updateCurrentPage() {
    this.isLoading = true;
    const startIndex = this.currentPage * this.displayPerPage;
    const endIndex = startIndex + this.displayPerPage;
    this.displayedItems = this.filteredItems.slice(startIndex, endIndex);
    this.allSelected = this.isAllSelected();
    this.isLoading = false;
  }

  pageStart() {
    if (this.displayedItems.length === 0) { return 0; }

    return (this.currentPage * this.displayPerPage) + 1;
  }

  pageEnd() {
    let retVal = (this.currentPage * this.displayPerPage) + this.displayPerPage;
    if (retVal > this.filteredItems.length) {
      retVal = this.filteredItems.length;
    }
    return retVal;
  }

  isAllSelected(): boolean {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.displayedItems.length; i++) {
      if (!this.displayedItems[i].selected) { return false; }
    }

    return true;
  }

  getAvailableItems() {
    // remove the excluded groups
    this.allAvailableItems = this.allPossibleItems.slice(); // clone
    this.excludedGroups.forEach(excludedGroup => {
      const idx: number = this.allAvailableItems.map(group => group.id).indexOf(excludedGroup.id);
      if (idx >= 0) {
        this.allAvailableItems.splice(idx, 1);
      }
    });

    this.total = this.allAvailableItems.length;
    this.filterResults(this.searchTerm.value)
      .subscribe(filteredResults => {
        this.filteredItems = filteredResults;
        this.updateCurrentPage();
      });
  }

  filterResults(searchTerm?: string) {
    if (!searchTerm) { return observableOf(this.allAvailableItems); }
    searchTerm = searchTerm.toUpperCase();
    // TODO: right now this is filtering on all results and not honoring the GroupItemColumn.isFilterable setting
    return observableOf(this.allAvailableItems.filter(item => {
      let found = 0;
      item.fields.forEach(field => {
        if (field != null && field.toString().toUpperCase().indexOf(searchTerm) >= 0) {
          found++;
        }
      });
      return (found > 0);
    }));
  }
}
