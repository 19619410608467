import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { TidService } from "../../tid.service";

@Injectable({ providedIn: "root" })
export class TidGuard implements CanActivate {
  constructor(private tidService: TidService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const code = route.queryParams.code;
    if (!code) {
      this.tidService.removeAuthCode();
      this.router.navigateByUrl("/auth/login");
      return false;
    }

    this.tidService.setAuthCode(code);
    return true;
  }
}
