
import {forkJoin as observableForkJoin, from as observableFrom,  Observable ,  Subscription } from 'rxjs';

import {pluck} from 'rxjs/operators';
// angular components
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

// alk app components
import { DriverGroupService } from './../shared';
import { GroupAssociationsComponent } from './../../shared/group-associations';
import { ConfirmationModal } from './../../shared';
import { DriverService } from './../../driver/shared';
import { DriverGroupPartnerPermissions } from '../driver-group-partner-permissions';
import { UserContextService } from './../../../alk-components/authentication';

@Component({
  providers: [DriverGroupService, DriverService, DriverGroupPartnerPermissions],
  templateUrl: './driver-group-detail.component.html'
})
// tslint:disable-next-line: component-class-suffix
export class DriverGroupDetail implements OnInit, OnDestroy {
  public group: any = {}; // details about this driver group
  public downloadUrl: string;
  public isProcessing = false;
  public isInitializing = true;
  public isExportEnabled = true;

  public submitted = false;
  public message = '';
  public isNew = false;
  public addedDrivers: Array<number> = [];
  public removedDrivers: Array<number> = [];
  public errorMessage: Observable<string>;
  public successMessage: Observable<string>;
  public groupId: number;
  public form: FormGroup;

  @ViewChild('confirmModal', { static: true }) confirmModal: ConfirmationModal;
  @ViewChild('driverAssociations', { static: true }) driverAssociations: GroupAssociationsComponent;

  private sub1: Subscription;

  dgPermissions: DriverGroupPartnerPermissions;

  constructor(
    private driverGroupService: DriverGroupService,
    private userContext: UserContextService,
    private driverService: DriverService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private translate: TranslateService,
    private driverGroupPartnerPermissions: DriverGroupPartnerPermissions
  ) {
    this.form = this.fb.group({
      name: ['', Validators.compose([Validators.required, Validators.maxLength(500)])],
      externalId: ['']
    });

    const partnerActivities = this.userContext.getUser().partner.partnerActivities;
    this.isExportEnabled = partnerActivities.canExportDrivers;

    this.dgPermissions = this.driverGroupPartnerPermissions;
  }

  public canDeactivate(): Observable<boolean> | boolean {
    if (!this.form.dirty &&
      this.driverAssociations.itemsPendingAddition.length === 0 &&
      this.driverAssociations.itemsPendingRemoval.length === 0) { return true; }

    return observableFrom(this.confirmModal.open());
  }

  public ngOnDestroy() {
    this.sub1.unsubscribe();
  }

  public ngOnInit() {
    this.driverAssociations.columnDefinitions = [
      { title: 'Driver ID', isFilterable: true },
      { title: 'Name', isFilterable: true }
    ];

    this.sub1 = this.route.params.pipe(pluck('id')).subscribe(id => {

      // we determine if the page is for a new item or an existing item  based on the id ('new' is for a new item)
      this.isNew = isNaN(+id);

      if (this.isNew) {
        this.loadNew();
      } else {
        this.loadExisting(+id);
      }
    });
  }

  public loadExisting(id) {
    this.groupId = id;
    this.downloadUrl = this.driverService.getExportUrl(id, true);

    this.isProcessing = true;
    this.isInitializing = true;

    const getDriverGroupDetails$ = this.driverGroupService.getGroup(id);
    const getGroupDrivers$ = this.driverGroupService.getDriverList([id]);
    const getAccountDrivers$ = this.driverGroupService.getDriverList();

    observableForkJoin([getDriverGroupDetails$, getGroupDrivers$, getAccountDrivers$])
      .subscribe((results: any) => {
        // results[0] is getDriverGroupDetails$
        this.loadForm(results[0]);

        // results[1] ios getGroupDrivers$
        this.driverAssociations.initialItems = results[1].items.map(result => {
          return {
            id: result.id,
            fields: [result.driverId, result.name],
            pendingRemoval: false,
            pendingAddition: false
          };
        });

        // results[2] is getAccountDrivers$
        this.driverAssociations.allPossibleItems = results[2].items.map(result => {
          return {
            id: result.id,
            fields: [result.driverId, result.name],
            pendingRemoval: false,
            pendingAddition: false
          };
        });

        this.isProcessing = false;
        this.isInitializing = false;

      }, error => {
        this.isProcessing = false;
        this.isInitializing = false;
      });
  }

  public loadNew() {
    this.isInitializing = true;
    this.groupId = -1;

    const getAccountDrivers$ = this.driverGroupService.getDriverList();

    this.loadForm({ name: '', externalId: '' });

    getAccountDrivers$.subscribe(result => {
      this.driverAssociations.allPossibleItems = result.items.map(driver => {
        return {
          id: driver.id,
          fields: [driver.driverId, driver.name],
          pendingRemoval: false,
          pendingAddition: false
        };
      });

      this.isInitializing = false;

    }, (error) => {
      this.isInitializing = false;

    });
  }

  public save() {

    this.submitted = true;
    // clear error and success messages when saving
    this.errorMessage = null;
    this.successMessage = null;

    if (!this.form.valid) {
      return;
    }

    this.isProcessing = true;

    this.saveGroupDetails().then(group => {
      return Promise.all([
        this.saveDriverAssociations()
      ]);

    }).then((group) => {
      this.successMessage = this.translate.get('views.driver-group-detail.SaveSuccess');
      this.isProcessing = false;

      // once the page is successfully saved, mark this form as pristine/untouched.
      this.form.markAsPristine();

      // this was a new driver group, redirect to edit page.
      if (this.isNew) {
        this.router.navigate(['/driver-groups', this.groupId]);
      }

    }).catch(error => {
      if (error.code === -3) {
        this.errorMessage = this.translate.get('views.driver-group-detail.error.NameAlreadyExists');
      } else {
        this.errorMessage = this.translate.get('views.driver-group-detail.error.Unknown');
      }

      this.isProcessing = false;
    });

  }

  public saveGroupDetails(): Promise<any> {
    let promise: Promise<any>;

    const driverGroup = {
      name: this.form.value.name,
      externalId: this.form.value.externalId
    };

    if (this.isNew) {
      promise = this.driverGroupService.createGroupDetails(
        {
          name: driverGroup.name,
          driverGroupId: driverGroup.externalId
        }
      ).toPromise().then(group => this.groupId = group.id);

    } else {
      promise = this.driverGroupService.updateGroupDetails(this.groupId, driverGroup).toPromise();
    }

    return promise;
  }

  public saveDriverAssociations(): Promise<any> {
    let promise: Promise<any>;

    promise = this.driverGroupService.associateDrivers(
      this.groupId,
      this.driverAssociations.itemsPendingAddition.map(r => r.id),
      this.driverAssociations.itemsPendingRemoval.map(r => r.id)
    ).toPromise().then(() => {
      this.driverAssociations.initialItems = this.driverAssociations.resultItems;
      this.driverAssociations.resetInitialData();

      // TODO: HOW TO RESET COMPONENT????
      // this.addedDrivers = [];
      // this.removedDrivers = [];
      // RELOAD this.driverAssociations ?
    });

    return promise;
  }

  public cancel() {
    this.router.navigate(['/driver-groups']);
  }

  public loadForm(attributes) {

    for (const attr in attributes) {
      if (!attributes.hasOwnProperty(attr)) { continue; }

      const control =  this.form.controls[attr] as FormControl;
      if (!control) { continue; }

      control.setValue(attributes[attr]);
    }
  }
}
