import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringIfNull'
})

// TODO: there might be a better way to display a value in case of null?
export class StringIfNullPipe implements PipeTransform {
  public transform(value, defaultValue) {

    if (value == null) {
      return defaultValue;
    }

    return value;
  }
}
