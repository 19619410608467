import { RouterModule, Routes } from '@angular/router';
import { Admin } from './admin';
import { AuthGuard } from './auth.guard';
import { AvoidFavorSetDetail, AvoidFavorSetList } from './avoid-favor-sets';
import { CanDeactivateGuard } from './can-deactivate-guard.service';
import { ConfigurationProfileDetail, ConfigurationProfileList } from './configuration-profile';
import { CustomPlaceSetDetail, CustomPlaceSetList } from './custom-place-sets';
import { DriverImport } from './driver';
import { DriverGroupDetail, DriverGroupList } from './driver-group';
// app components
import { Landing } from './landing';
import { ConnectionSettingsComponent } from './mapsets/connection-settings/connection-settings.component';
import { MapsetsDetailComponent } from './mapsets/mapsets-detail/mapsets-detail.component';
import { MapsetsDetailGuardService } from './mapsets/mapsets-detail/mapsets-detail.guard.service';
import { MapsetsListComponent } from './mapsets/mapsets-list/mapsets-list.component';
import { PartnerAccountList } from './partner-account-selector/partner-account-list';
import { RoadSpeedsProfile } from './road-speeds';
import { RoutingProfileDetail, RoutingProfileList } from './routing-profile';
import { LogoutComponent } from './shared/tid/components/logout/logout.component';
import { SpeedCoachingDetail, SpeedCoachingList } from './speed-coaching';
import { AcceptTerms, ReadTerms } from './terms';
import { TOSGuard } from './tos.guard';
import { UserSettings } from './user-settings';
import { VehicleImport } from './vehicle';
import { VehicleGroupDetail, VehicleGroupList } from './vehicle-group';
import { VehicleSummary } from './vehicle-summary';
import { AzureComponent } from './azure';
import { AzureGuard } from './azure.guard';


const routes: Routes = [
  {
    path: 'user', component: UserSettings, canActivate: [AuthGuard, TOSGuard],
    canDeactivate: [CanDeactivateGuard],
    data: { title: 'views.user-settings.Title' }
  },

  {
    path: 'terms', component: ReadTerms,
    data: { title: 'views.read-terms.Title' }
  },
  {
    path: 'accept-terms', component: AcceptTerms, canActivate: [AuthGuard],
    data: { title: 'views.accept-terms.Title' }
  },
  {
    path: 'azure', component: AzureComponent, canActivate: [AuthGuard, AzureGuard],
    data: { title: 'views.azure.Title' }
  },

  {
    path: 'login', component: Landing,
    data: { title: 'views.login.Title' }
  },
  {
    path: 'logout', component: LogoutComponent
  },

  // routing profiles
  {
    path: 'routing-profiles', component: RoutingProfileList, canActivate: [AuthGuard, TOSGuard],
    data: { title: 'views.routing-profile-list.Title' }
  },
  {
    path: 'routing-profiles/:id', component: RoutingProfileDetail, canActivate: [AuthGuard, TOSGuard],
    canDeactivate: [CanDeactivateGuard],
    data: { title: 'views.routing-profile-detail.Title' }
  },

  // configuration profiles
  {
    path: 'configuration-profiles', component: ConfigurationProfileList, canActivate: [AuthGuard, TOSGuard],
    data: { title: 'views.configuration-profile-list.Title' }
  },
  {
    path: 'configuration-profiles/:id', component: ConfigurationProfileDetail, canActivate: [AuthGuard, TOSGuard],
    canDeactivate: [CanDeactivateGuard],
    data: { title: 'views.configuration-profile-detail.Title' }
  },

  // speed coaching profiles
  {
      path: 'speed-coaching', component: SpeedCoachingList, canActivate: [AuthGuard, TOSGuard],
      data: { title: 'views.speed-coaching-list.Title' }
  },
  {
      path: 'speed-coaching/:id', component: SpeedCoachingDetail, canActivate: [AuthGuard, TOSGuard],
      canDeactivate: [CanDeactivateGuard],
      data: { title: 'views.speed-coaching-detail.Title' }
  },
  // road speeds
  {
    path: 'road-speeds', component: RoadSpeedsProfile, canActivate: [AuthGuard, TOSGuard],
    canDeactivate: [CanDeactivateGuard],
    data: { title: 'views.road-speeds.Title' }
  },
  // vehicle groups
  {
    path: 'vehicle-groups', component: VehicleGroupList, canActivate: [AuthGuard, TOSGuard],
    data: { title: 'views.vehicle-group-list.Title' }
  },
  {
    path: 'vehicle-groups/:id', component: VehicleGroupDetail, canActivate: [AuthGuard, TOSGuard],
    canDeactivate: [CanDeactivateGuard],
    data: { title: 'views.vehicle-group-detail.Title' }
  },

  {
    path: 'vehicle/:id', component: VehicleSummary, canActivate: [AuthGuard, TOSGuard],
    data: { title: 'views.vehicle-summary.Title' }
  },

  // driver groups
  {
    path: 'driver-groups', component: DriverGroupList, canActivate: [AuthGuard, TOSGuard],
    data: { title: 'views.driver-group-list.Title' }
  },
  {
    path: 'driver-groups/:id', component: DriverGroupDetail, canActivate: [AuthGuard, TOSGuard],
    canDeactivate: [CanDeactivateGuard],
    data: { title: 'views.driver-group-detail.Title' }
  },

   // partner section
  {
    path: 'partnerAccounts', component: PartnerAccountList, canActivate: [AuthGuard, TOSGuard],
    data: { title: 'views.partner-account-list.Title' }
  },

  // avoid-favor-set section
  {
    path: 'avoidFavorSets', component: AvoidFavorSetList, canActivate: [AuthGuard, TOSGuard],
    data: { title: 'views.avoid-favorSet-detail.Title' }
  },

  {
    path: 'avoidFavorSets/:id', component: AvoidFavorSetDetail, canActivate: [AuthGuard, TOSGuard],
    canDeactivate: [CanDeactivateGuard],
    data: { title: 'views.avoid-favorSet-detail.Title' }
  },

  // custom-place-set section
  {
    path: 'customPlaceSets', component: CustomPlaceSetList, canActivate: [AuthGuard, TOSGuard],
    data: { title: 'views.custom-placeSet-list.Title' }
  },
  {
    path: 'customPlaceSets/:id', component: CustomPlaceSetDetail, canActivate: [AuthGuard, TOSGuard],
    canDeactivate: [CanDeactivateGuard],
    data: { title: 'views.custom-placeSet-detail.Title' }
  },

  // mapsets section
  {
    path: 'mapsets-list', component: MapsetsListComponent, canActivate: [AuthGuard, TOSGuard],
    data: { title: 'views.header.Mapsets' }
  },
  {
    path: 'connection-settings', component: ConnectionSettingsComponent, canActivate: [AuthGuard, TOSGuard],
    data: { title: 'views.mapsets-connection-settings.ConnectionSettings' }
  },
  {
    path: 'mapsets/:id', component: MapsetsDetailComponent, canActivate: [AuthGuard, TOSGuard],
    canDeactivate: [MapsetsDetailGuardService], data: {title: 'views.mapsets.EditMapset' }
  },
  {
    path: 'mapsets/created/:id', component: MapsetsDetailComponent, canActivate: [AuthGuard, TOSGuard],
    canDeactivate: [MapsetsDetailGuardService], data: {title: 'views.mapsets.EditMapset', justCreated: true }
  },
  {
    path: 'mapsets/create', component: MapsetsDetailComponent, canActivate: [AuthGuard, TOSGuard],
    canDeactivate: [MapsetsDetailGuardService], data: {title: 'views.mapsets.CreateMapset' }
  },

  // admin section
  {
    path: 'admin', component: Admin, canActivate: [AuthGuard, TOSGuard],
    data: { roles: ['Company Administrator', 'ALK Administrator', 'ALK Support', 'Support'], title: 'views.account-management.Title' }
  },
  // if anyone removes these paths, please clean up all the related components and all the references
  // {
  //   path: 'admin/integration', component: AdminIntegration, canActivate: [AuthGuard, TOSGuard],
  //   data: { roles: ['Company Administrator', 'ALK Administrator', 'ALK Support', 'Support'], title: 'views.admin-integration.Title' }
  // },
  // {
  //   path: 'admin/users', component: AdminUsers, canActivate: [AuthGuard, TOSGuard],
  //   data: { roles: ['Company Administrator', 'ALK Administrator', 'ALK Support', 'Support'], title: 'views.admin-users.Title' }
  // },
  {
    path: 'vehicles/import', component: VehicleImport, canActivate: [AuthGuard, TOSGuard],
    data: { roles: ['Company Administrator', 'ALK Administrator', 'ALK Support', 'Support'], title: 'views.vehicle-import.Title' }
  },
  {
    path: 'drivers/import', component: DriverImport, canActivate: [AuthGuard, TOSGuard],
    data: { roles: ['Company Administrator', 'ALK Administrator', 'ALK Support', 'Support'], title: 'views.driver-import.Title' }
  },
  { path: '', redirectTo: 'vehicle-groups', pathMatch: 'full' },
  { path: '**', redirectTo: 'vehicle-groups' },

];

export const appRoutingProviders: any[] = [
  CanDeactivateGuard,
  TOSGuard,
  AuthGuard,
  AzureGuard
];

export const routing = RouterModule.forRoot(routes);
