
import {
  from as observableFrom,
  of,
  Observable,
  Subscription,
  EMPTY,
  forkJoin
} from 'rxjs';

import { map, pluck } from 'rxjs/operators';
// angular components
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

// alk app components
import {
  VehicleGroupService,
  SpeedCoachingProfileService,
  ConfigurationProfilesService,
  AvoidFavorService,
  CustomPlaceService
} from './../shared';

import { RoutingProfileService } from './../../shared/routing-profile-service';
import { ConfirmationModal } from './../../shared';
import { GroupAssociationsComponent } from './../../shared/group-associations';
import { SingleSelectGroupAssociationsComponent } from './../../shared/group-associations';
import { VehicleGroupPartnerPermissions } from '../vehicle-group-partner-permissions';
import { VehicleService } from './../../vehicle/shared/vehicle.service';
import { AccountManagementService } from './../../../alk-components/account-management';
import { validMutipleEmails } from './../../../alk-components/common-validation';
import { MapsetsService } from './../../mapsets/shared/mapsets.service';

// alk components
import { UserContextService } from './../../../alk-components/authentication';

@Component({
  providers: [
    VehicleGroupService,
    SpeedCoachingProfileService,
    ConfigurationProfilesService,
    AvoidFavorService,
    CustomPlaceService,
    RoutingProfileService,
    VehicleGroupPartnerPermissions,
    VehicleService,
    AccountManagementService,
    MapsetsService,
    UserContextService],
  templateUrl: './vehicle-group-detail.component.html',
  styles: [`
      .profile-select {
        font-weight: 600px;
      }
    `]
})
// tslint:disable-next-line: component-class-suffix
export class VehicleGroupDetail implements OnInit, OnDestroy {
  public group: any = {}; // details about this vehicle group
  public mapsetsEnabled = false;

  public configurationProfile: any; // configuration profile associated with this vehicle group
  public isConfigurationProfileChanged = false;
  public isGroupSaved = false;
  public configurationProfiles: Array<any> = [];

  public speedCoachingProfile: any;
  public isSpeedCoachingProfileChanged = false;
  public speedCoachingProfiles: Array<any> = [];

  public isProcessing = false;
  public isInitializing = true;
  public submitted = false;
  public message = '';
  public isNew = false;

  public addedVehicles: Array<number> = [];
  public removedVehicles: Array<number> = [];

  public errorMessage: Observable<string>;
  public successMessage: Observable<string>;
  public hyperlinkErrorType: Observable<string>;
  public showHyperlinkError = false;

  public downloadRoutingProfilesUrl: string;
  public downloadUrl: string;

  public groupIds: Array<number> = [];
  public oorEmailIsEnabled = false;

  public groupId: number;
  public form: FormGroup;

  public isRouteReporterAccount = false;
  public canDisplayProfilesTab = true;

  @ViewChild('confirmModal', { static: true }) confirmModal: ConfirmationModal;
  @ViewChild('routingProfileAssociations', { static: true }) routingProfileAssociations: GroupAssociationsComponent;
  @ViewChild('avoidFavorSetAssociations', { static: true }) avoidFavorSetAssociations: SingleSelectGroupAssociationsComponent;
  @ViewChild('customPlaceSetAssociations', { static: true }) customPlaceSetAssociations: SingleSelectGroupAssociationsComponent;
  @ViewChild('vehicleAssociations', { static: true }) vehicleAssociations: GroupAssociationsComponent;
  @ViewChild('mapsetsAssociations', { static: true }) mapsetsAssociations: GroupAssociationsComponent;

  private sub1: Subscription;

  public selectedConfigurationProfile;
  public selectedSpeedConfigurationProfile;

  constructor(
    private vehicleGroupService: VehicleGroupService,
    private speedCoachingProfileService: SpeedCoachingProfileService,
    private configurationProfileService: ConfigurationProfilesService,
    private avoidFavorService: AvoidFavorService,
    private customPlaceService: CustomPlaceService,
    private routingProfileService: RoutingProfileService,
    private vehicleService: VehicleService,
    private accountService: AccountManagementService,
    private mapsetsService: MapsetsService,
    public userContext: UserContextService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private translate: TranslateService,
    public vgPermissions: VehicleGroupPartnerPermissions
  ) {
    this.form = this.fb.group({
      name: ['', Validators.compose([Validators.required, Validators.maxLength(250)])],
      oorEmailIsEnabled: [],
      oorEmail: ['', Validators.compose([Validators.required, Validators.maxLength(500), validMutipleEmails])],
      configurationProfile: [],
      speedCoachingProfile: []
    });
    this.userContext.user.subscribe((x: any) => {
      this.mapsetsEnabled = x.isMapSetEnabled;
    });
  }

  public canDeactivate(): Observable<boolean> | boolean {
    // Don't show the unsaved changes modal after saving a group
    if (!this.form.dirty && this.isGroupSaved) {
      return true;
    }
    // Hiding the routing profile associations when RP is disabled
    // would break this logic, since the code won't be able to access the 'routingProfileAssocaitions' items
    const check = !this.form.dirty &&
      !this.isConfigurationProfileChanged &&
      !this.isSpeedCoachingProfileChanged &&
      this.vehicleAssociations.itemsPendingAddition.length === 0 &&
      this.vehicleAssociations.itemsPendingRemoval.length === 0 &&
      this.avoidFavorSetAssociations.itemsPendingAddition.length === 0 &&
      this.avoidFavorSetAssociations.itemsPendingRemoval.length === 0 &&
      this.customPlaceSetAssociations.itemsPendingAddition.length === 0 &&
      this.customPlaceSetAssociations.itemsPendingRemoval.length === 0 &&
      this.mapsetsAssociations.itemsPendingAddition.length === 0 &&
      this.mapsetsAssociations.itemsPendingRemoval.length === 0;

    // check rp associations only if routing profile is enabled
    if (check && this.userContext.isRoutingProfileEnabled()) {
      if (check && this.routingProfileAssociations.itemsPendingAddition.length === 0 &&
        this.routingProfileAssociations.itemsPendingRemoval.length === 0) {
        return true;
      }
    } else if (check) {
      return true;
    }
    return observableFrom(this.confirmModal.open());
  }

  public ngOnDestroy() {
    this.sub1.unsubscribe();
  }

  public toggleOOREmailIsEnabled() {
    this.oorEmailIsEnabled = !this.oorEmailIsEnabled;
    if (this.oorEmailIsEnabled) {
      this.form.get('oorEmail').setValidators([Validators.required, validMutipleEmails]);
    } else {
      this.form.get('oorEmail').setValidators(null);
    }
    this.form.get('oorEmail').updateValueAndValidity();
  }

  public ngOnInit() {

    if (!this.oorEmailIsEnabled) {
      this.form.get('oorEmail').setValidators(null);
      this.form.get('oorEmail').updateValueAndValidity();
    }
    this.canDisplayProfilesTab = (this.userContext.isConfigurationProfileEnabled() ||
      this.userContext.isRoutingProfileEnabled() ||
      this.userContext.isSpeedCoachingEnabled());
    this.vehicleAssociations.columnDefinitions = [
      { title: 'Vehicle ID', isFilterable: true },
      { title: 'Vehicle Name', isFilterable: true }
    ];

    if (this.userContext.isRoutingProfileEnabled()) {
      this.routingProfileAssociations.columnDefinitions = [
        { title: 'Name', isFilterable: true }
      ];
    }

    this.avoidFavorSetAssociations.columnDefinitions = [
      { title: 'Set Name', isFilterable: true }
    ];

    this.customPlaceSetAssociations.columnDefinitions = [
      { title: 'Set Name', isFilterable: true }
    ];

    this.mapsetsAssociations.columnDefinitions = [
      { title: 'Set Name', isFilterable: true },
      { title: 'Regions', isFilterable: true }
    ];

    this.sub1 = this.route.params.pipe(pluck('id')).subscribe(id => {

      this.accountService.isRouteReporterAccount().subscribe(
        result => {
          this.isRouteReporterAccount = result;
        }
      );

      // we determine if the page is for a new item or an existing item  based on the id ('new' is for a new item)
      this.isNew = isNaN(+id);

      if (this.isNew) {
        this.loadNew();
      } else {
        this.loadExisting(+id);
      }
    });
  }

  public loadForm(attributes) {

    for (const attr in attributes) {
      if (!attributes.hasOwnProperty(attr)) { continue; }

      const control = this.form.controls[attr] as FormControl;
      if (!control) { continue; }

      control.setValue(attributes[attr]);
    }
  }

  public loadExisting(id) {
    // Constants for Indexing of ForkJoin Results
    const GROUP_DETAILS_INDEX = 0;
    const CONFIG_PROFILES_INDEX = 1;
    const CURRENT_CONFIG_PROFILE_INDEX = 2;
    const ROUTING_PROFILES_INDEX = 3;
    const CURRENT_ROUTING_PROFILES_INDEX = 4;
    const CURRENT_VEHICLES_INDEX = 5;
    const VEHICLES_INDEX = 6;
    const AVOID_FAVOR_SETS_INDEX = 7;
    const CURRENT_AVOID_FAVOR_SETS_INDEX = 8;
    const CUSTOMPLACE_SETS_INDEX = 9;
    const CURRENT_CUSTOMPLACE_SETS_INDEX = 10;
    const GROUP_MAPSETS_INDEX = 11;
    const ALL_MAPSETS_TOTAL = 12;
    const SPEEDCOACHING_PROFILES_INDEX = 13;
    const CURRENT_SPEEDCOACHING_PROFILE_INDEX = 14;

    this.groupIds = [id];
    this.groupId = id;

    this.downloadRoutingProfilesUrl = this.routingProfileService.getExportUrl(id);
    this.downloadUrl = this.vehicleService.getExportUrl(id, true);

    this.isInitializing = true;
    this.isProcessing = true;

    const getGroupObs = this.vehicleGroupService.getGroup(id);
    const getConfigurationProfilesObs = this.configurationProfileService.getConfigurationProfiles();
    const getAllAvoidFavorSetsObs = this.avoidFavorService.getAvoidFavorSets().pipe(map(c => c.items));
    const getCurrentAvoidFavorSetsObs = this.avoidFavorService.getAvoidFavorSets([+id]).pipe(map(c => c.items));
    const getAllCustomPlaceSetsObs = this.customPlaceService.getCustomPlaceSets().pipe(map(c => c.items));
    const getCurrentCustomPlaceSetsObs = this.customPlaceService.getCustomPlaceSets([+id]).pipe(map(c => c.items));
    const getConfigurationProfileObs = this.vehicleGroupService.getConfigurationProfile(id);
    const currentRoutingProfilesObs = this.routingProfileService.getRoutingProfiles([+id]).pipe(map(c => c.items));
    const allRoutingProfilesObs = this.routingProfileService.getRoutingProfiles().pipe(map(c => c.items));
    const getGroupVehicles = this.vehicleGroupService.getVehicleList([id]).pipe(map(c => c.items));
    const getAccountVehicles = this.vehicleGroupService.getVehicleList().pipe(map(c => c.items));
    const getGroupCustomMapsetsObs = this.mapsetsService.getVehicleGroupMapsets(id);
    const getAllCustomMapsetsObs = this.mapsetsService.getMapsets().pipe(map(c => c.size));

    const getSpeedCoachingProfiles = this.userContext.isSpeedCoachingEnabled()
      ? this.speedCoachingProfileService.getSpeedCoachingProfiles() : EMPTY;

    const currentSpeedCoachingProfile = this.userContext.isSpeedCoachingEnabled()
      ? this.speedCoachingProfileService.getProfileByGroupId(id) : EMPTY;

    const apiCallArray = [getGroupObs,
      getConfigurationProfilesObs,
      getConfigurationProfileObs,
      allRoutingProfilesObs,
      currentRoutingProfilesObs,
      getGroupVehicles,
      getAccountVehicles,
      getAllAvoidFavorSetsObs,
      getCurrentAvoidFavorSetsObs,
      getAllCustomPlaceSetsObs,
      getCurrentCustomPlaceSetsObs
    ];

    if (this.userContext.isMapSetEnabled()) {
      apiCallArray.push(getGroupCustomMapsetsObs);
      apiCallArray.push(getAllCustomMapsetsObs);
    }
    if (this.userContext.isSpeedCoachingEnabled()) {
      apiCallArray.push(getSpeedCoachingProfiles);
      apiCallArray.push(currentSpeedCoachingProfile);
    }

    forkJoin(apiCallArray).subscribe(results => {

      this.loadForm(results[GROUP_DETAILS_INDEX]);

      this.configurationProfiles = results[CONFIG_PROFILES_INDEX].items.map(c => ({ id: c.id, text: c.name }));

      if (results[CURRENT_CONFIG_PROFILE_INDEX]) {
        this.configurationProfile = [{ id: results[CURRENT_CONFIG_PROFILE_INDEX].id, text: results[CURRENT_CONFIG_PROFILE_INDEX].name }];
        this.form.get('configurationProfile').setValue(this.configurationProfile[0].id);
      }

      if (this.userContext.isSpeedCoachingEnabled()) {
        // load the speedcoaching profiles for this account
        this.speedCoachingProfiles = results[SPEEDCOACHING_PROFILES_INDEX]
            ? results[SPEEDCOACHING_PROFILES_INDEX].items.map(c => {
              return { id: c.id, text: c.name };
            })
            : [];

        // load the default speedcoaching profile
        if (results[CURRENT_SPEEDCOACHING_PROFILE_INDEX]) {
          this.speedCoachingProfile = [{
            id: results[CURRENT_SPEEDCOACHING_PROFILE_INDEX].speedCoachingProfileId,
            text: results[CURRENT_SPEEDCOACHING_PROFILE_INDEX].name
          }];

          this.form.get('speedCoachingProfile').setValue(this.speedCoachingProfile[0].id);
        }
      }

      this.avoidFavorSetAssociations.initialItems = results[CURRENT_AVOID_FAVOR_SETS_INDEX].map(item => {
        return {
          id: item.setID,
          fields: [item.name],
          pendingRemoval: false,
          pendingAddition: false
        };
      });

      this.customPlaceSetAssociations.initialItems = results[CURRENT_CUSTOMPLACE_SETS_INDEX].map(item => {
        return {
          id: item.id,
          fields: [item.name],
          pendingRemoval: false,
          pendingAddition: false
        };
      });
      if (this.userContext.isRoutingProfileEnabled()) {
        this.routingProfileAssociations.initialItems = results[CURRENT_ROUTING_PROFILES_INDEX].map(item => {
          return {
            id: item.routingProfileId,
            fields: [item.name],
            pendingRemoval: false,
            pendingAddition: false
          };
        });
      }

      this.vehicleAssociations.initialItems = results[CURRENT_VEHICLES_INDEX].map(result => {
        return {
          id: result.id,
          fields: [result.vehicleId, result.name],
          pendingRemoval: false,
          pendingAddition: false
        };
      });

      if (this.userContext.isMapSetEnabled()) {
        this.mapsetsAssociations.initialItems = results[GROUP_MAPSETS_INDEX].map(result => {
          const regions = result.mapRegions.map(region => region.mapsetName).join(', ');
          return {
            id: result.customMapsetId,
            fields: [result.customMapsetName, regions],
            pendingRemoval: false,
            pendingAddition: false
          };
        });

        this.mapsetsAssociations.total = results[ALL_MAPSETS_TOTAL]
      }

      this.vehicleAssociations.allPossibleItems = results[VEHICLES_INDEX].map(result => {
        return {
          id: result.id,
          fields: [result.vehicleId, result.name],
          pendingRemoval: false,
          pendingAddition: false
        };
      });
      if (this.userContext.isRoutingProfileEnabled()) {
        this.routingProfileAssociations.allPossibleItems = results[ROUTING_PROFILES_INDEX].map(item => {
          return {
            id: item.routingProfileId,
            fields: [item.name],
            pendingRemoval: false,
            pendingAddition: false
          };
        });
      }

      this.avoidFavorSetAssociations.allPossibleItems = results[AVOID_FAVOR_SETS_INDEX].map(item => {
        return {
          id: item.setID,
          fields: [item.name],
          pendingRemoval: false,
          pendingAddition: false
        };
      });

      this.customPlaceSetAssociations.allPossibleItems = results[CUSTOMPLACE_SETS_INDEX].map(item => {
        return {
          id: item.id,
          fields: [item.name],
          pendingRemoval: false,
          pendingAddition: false
        };
      });

      this.isProcessing = false;
      this.isInitializing = false;
      this.oorEmailIsEnabled = results[GROUP_DETAILS_INDEX].oorEmailIsEnabled;
    }, error => {
      this.isProcessing = false;
      this.isInitializing = false;
    });
  }

  public loadNew() {
    // Constants for indexing of ForkJoin Results
    const CURRENT_CONFIG_PROFILE_INDEX = 0;
    const CONFIG_PROFILES_INDEX = 1;
    const CURRENT_ROUTING_PROFILES_INDEX = 2;
    const ROUTING_PROFILES_INDEX = 3;
    const VEHICLES_INDEX = 4;
    const AVOID_FAVOR_SETS_INDEX = 5;
    const CUSTOM_PLACE_SETS_INDEX = 6;
    const CURRENT_AVOID_FAVORS_INDEX = 7;
    const CURRENT_CUSTOM_PLACES_INDEX = 8;
    const ALL_MAPSETS_TOTAL = 9;
    const CURRENT_SPEEDCOACHING_PROFILE_INDEX = 10;
    const SPEEDCOACHING_PROFILES_INDEX = 11;

    this.groupId = -1;
    this.isInitializing = true;

    const currentRoutingProfilesObs = this.getDefaultRoutingProfiles();
    const allRoutingProfilesObs = this.routingProfileService.getRoutingProfiles().pipe(map(c => c.items));
    const getAccountVehicles = this.vehicleGroupService.getVehicleList().pipe(map(c => c.items));
    const allAvoidFavorSetsObs = this.avoidFavorService.getAvoidFavorSets().pipe(map(c => c.items));
    const currentAvoidFavorSetsObs = this.getDefaultAvoidFavorSets();
    const allCustomPlaceSetsObs = this.customPlaceService.getCustomPlaceSets().pipe(map(c => c.items));
    const currentCustomPlaceSetsObs = this.getDefaultCustomPlaceSets();
    const getAllCustomMapsetsObs = this.mapsetsService.getMapsets().pipe(map(c => c.size));

    const currentSpeedCoachingProfile = this.userContext.isSpeedCoachingEnabled()
      ? this.speedCoachingProfileService.getDefaultProfile_currentAccount() : EMPTY;

    const allSpeedCoachingProfiles = this.userContext.isSpeedCoachingEnabled()
      ? this.speedCoachingProfileService.getSpeedCoachingProfiles() : EMPTY;

    const apiCallArray = [this.getDefaultConfigurationProfile(),
    this.configurationProfileService.getConfigurationProfiles(),
      currentRoutingProfilesObs,
      allRoutingProfilesObs,
      getAccountVehicles,
      allAvoidFavorSetsObs,
      allCustomPlaceSetsObs,
      currentAvoidFavorSetsObs,
      currentCustomPlaceSetsObs
    ];

    if (this.userContext.isMapSetEnabled()) {
      apiCallArray.push(getAllCustomMapsetsObs);
    }

    if (this.userContext.isSpeedCoachingEnabled()) {
      apiCallArray.push(currentSpeedCoachingProfile);
      apiCallArray.push(allSpeedCoachingProfiles);
    }

    forkJoin(apiCallArray)
      .subscribe(results => {
        this.loadForm(this.vehicleGroupService.getDefaultVehicleGroup());

        this.configurationProfiles = results[CONFIG_PROFILES_INDEX]
            ? results[CONFIG_PROFILES_INDEX].items.map(c => ({ id: c.id, text: c.name }))
            : [];
        if (results[CURRENT_CONFIG_PROFILE_INDEX]) {
          this.configurationProfile = [{ id: results[CURRENT_CONFIG_PROFILE_INDEX].id, text: results[CURRENT_CONFIG_PROFILE_INDEX].name }];
          this.form.get('configurationProfile').setValue(this.configurationProfile[0].id);
        }

        if (this.userContext.isSpeedCoachingEnabled()) {
          // load the speedcoaching profiles for this account
          this.speedCoachingProfiles = results[SPEEDCOACHING_PROFILES_INDEX]
              ? results[SPEEDCOACHING_PROFILES_INDEX].items.map(c => {
                  const id = c.speedCoachingProfileId || c.id; // TODO: confirm speedCoachingProfileId is never used here and remove
                  return { id, text: c.name };
                })
              : [];

          // load the default speedcoaching profile
          if (results[CURRENT_SPEEDCOACHING_PROFILE_INDEX]) {
            this.speedCoachingProfile = [{
              id: results[CURRENT_SPEEDCOACHING_PROFILE_INDEX].speedCoachingProfileId,
              text: results[CURRENT_SPEEDCOACHING_PROFILE_INDEX].name
            }];

            this.form.get('speedCoachingProfile').setValue(this.speedCoachingProfile[0].id);
          }
        }
        if (this.userContext.isRoutingProfileEnabled()) {
          this.routingProfileAssociations.allPossibleItems = results[ROUTING_PROFILES_INDEX].map(item => {
            return {
              id: item.routingProfileId,
              fields: [item.name],
              pendingRemoval: false,
              pendingAddition: false
            };
          });
        }
        this.avoidFavorSetAssociations.allPossibleItems = results[AVOID_FAVOR_SETS_INDEX].map(item => {
          return {
            id: item.setID,
            fields: [item.name],
            pendingRemoval: false,
            pendingAddition: false
          };
        });
        this.customPlaceSetAssociations.allPossibleItems = results[CUSTOM_PLACE_SETS_INDEX].map(item => {
          return {
            id: item.id,
            fields: [item.name],
            pendingRemoval: false,
            pendingAddition: false
          };
        });

        this.vehicleAssociations.allPossibleItems = results[VEHICLES_INDEX].map(item => {
          return {
            id: item.id,
            fields: [item.vehicleId, item.name],
            pendingRemoval: false,
            pendingAddition: false
          };
        });

        if (this.userContext.isMapSetEnabled()) {
          this.mapsetsAssociations.total = results[ALL_MAPSETS_TOTAL]
        }

        if (results[CURRENT_ROUTING_PROFILES_INDEX] && this.userContext.isRoutingProfileEnabled()) {
          this.routingProfileAssociations.addInitialItems(results[CURRENT_ROUTING_PROFILES_INDEX].map(item => {
            return {
              id: item.routingProfileId,
              fields: [item.name],
              pendingRemoval: false,
              pendingAddition: false
            };
          }));
        }

        if (results[CURRENT_AVOID_FAVORS_INDEX]) {
          this.avoidFavorSetAssociations.addInitialItems(results[CURRENT_AVOID_FAVORS_INDEX].map(item => {
            return {
              id: item.setID,
              fields: [item.name],
              pendingRemoval: false,
              pendingAddition: false
            };
          }));
        }

        if (results[CURRENT_CUSTOM_PLACES_INDEX]) {
          this.customPlaceSetAssociations.addInitialItems(results[CURRENT_CUSTOM_PLACES_INDEX].map(item => {
            return {
              id: item.id,
              fields: [item.name],
              pendingRemoval: false,
              pendingAddition: false
            };
          }));
        }

        // todo: add items to routingProfileAssociations.pendingAddedItems
        // this.routingProfileAssociations.addInitialItems
        // this.addedRoutingProfiles = results[2].map(c => c.routingProfileId);

        this.isInitializing = false;
      }, () => {
        this.isInitializing = false;

      });

  }

  public onAddedChanged(added: Array<number>) {
    this.addedVehicles = added;
  }

  public onRemovedChanged(removed: Array<number>) {
    this.removedVehicles = removed;
  }

  public save() {

    this.submitted = true;

    this.errorMessage = null;
    this.successMessage = null;
    this.showHyperlinkError = false;

    if (!this.form.valid) {
      return;
    }

    this.isProcessing = true;

    this.saveGroupDetails().then(group => {
      this.successMessage = this.translate.get('views.vehicle-group-detail.SaveSuccess');

      return this.saveVehicleAssociations().then(() => {
        return this.saveAssociations();
      })

    }).then(() => {
      this.isProcessing = false;

      // once the page is successfully saved, mark this form as pristine/untouched.
      this.form.markAsPristine();
      this.isGroupSaved = true;

      // this was a new vehicle group, redirect to edit page.
      if (this.isNew) {
        this.router.navigate(['/vehicle-groups', this.groupId]);
      }

    }).catch(error => {
      let errorCode = error.error!.code;
      if (error.error!.error){
        errorCode = error.error.error.errorCode;
      }

      switch(errorCode) {
        case -3:
          this.errorMessage = this.translate.get('views.vehicle-group-detail.error.NameAlreadyExists');
          break;
        case -7011:
          this.hyperlinkErrorType = this.translate.get('views.vehicle-group-detail.error.TooManyMapsetsVehicle');
          this.showHyperlinkError = true;
          break;
        case -7022:
          this.hyperlinkErrorType = this.translate.get('views.vehicle-group-detail.error.TooManyMapsetsVehicleGroup');
          this.showHyperlinkError = true;
          break;
        default:
          this.errorMessage = this.translate.get('views.vehicle-group-detail.error.Unknown');
      }

      this.isProcessing = false;
    });

  }

  public onConfigurationProfileSelectedRemoved(value: any): void {
    this.isConfigurationProfileChanged = true;
  }

  public onSpeedCoachingProfileSelectedRemoved(value: any): void {
    this.isSpeedCoachingProfileChanged = true;
  }

  public saveGroupDetails(): Promise<any> {
    let promise: Promise<any>;

    if (this.isNew) {
      promise = this.vehicleGroupService.createGroupDetails(this.form.value).toPromise().then(group => this.groupId = group.id);
    } else {
      promise = this.vehicleGroupService.updateGroupDetails(this.groupId, this.form.value).toPromise();
    }

    return promise;
  }

  public saveConfigurationProfileAssociation(): Promise<any> {
    let configurationProfileId = null;
    if (this.form.get('configurationProfile').value) {
      configurationProfileId = this.form.get('configurationProfile').value;
    }

    return this.vehicleGroupService.associateConfigurationProfile(this.groupId, configurationProfileId).toPromise()
      .then(() => this.isConfigurationProfileChanged = false);
  }

  public saveSpeedCoachingProfileAssociations(): Promise<any> {
    let speedCoachingProfileId = null;
    if (this.form.get('speedCoachingProfile').value) {
      speedCoachingProfileId = this.form.get('speedCoachingProfile').value;
    }

    return this.vehicleGroupService.associateSpeedCoachingProfile(this.groupId, speedCoachingProfileId).toPromise()
      .then(() => this.isSpeedCoachingProfileChanged = false);
  }

  public saveVehicleAssociations(): Promise<any> {
    let promise: Promise<any>;
    promise = this.vehicleGroupService.associateVehicles(
      this.groupId,
      this.vehicleAssociations.itemsPendingAddition.map(r => r.fields[0]),
      this.vehicleAssociations.itemsPendingRemoval.map(r => r.fields[0])
    ).toPromise().then(() => {
      this.vehicleAssociations.initialItems = this.vehicleAssociations.resultItems;
      this.vehicleAssociations.resetInitialData();
    });

    return promise;
  }

  // Save avoid favor associations only if there are avoid favor sets to add and/or remove
  public saveAvoidFavorSetAssociations(): Promise<any> {
    let promise: Promise<any>;
    promise = this.vehicleGroupService.associateAvoidFavorSet(
      this.groupId,
      this.avoidFavorSetAssociations.itemsPendingAddition.map(r => r.id),
      this.avoidFavorSetAssociations.itemsPendingRemoval.map(r => r.id)
    ).toPromise().then(() => {
      this.avoidFavorSetAssociations.initialItems = this.avoidFavorSetAssociations.resultItems;
      this.avoidFavorSetAssociations.resetInitialData();
    });

    return promise;
  }

  // Save custom place associations only if there are custom places sets to add and/or remove
  public saveCustomPlaceSetAssociations(): Promise<any> {
    let promise: Promise<any>;
    promise = this.vehicleGroupService.associateCustomPlaceSet(
      this.groupId,
      this.customPlaceSetAssociations.itemsPendingAddition.map(r => r.id),
      this.customPlaceSetAssociations.itemsPendingRemoval.map(r => r.id)
    ).toPromise().then(() => {
      this.customPlaceSetAssociations.initialItems = this.customPlaceSetAssociations.resultItems;
      this.customPlaceSetAssociations.resetInitialData();
    });

    return promise;
  }

  public saveRoutingProfileAssociations(): Promise<any> {
    return this.vehicleGroupService
      .associateRoutingProfiles(this.groupId, this.routingProfileAssociations.itemsPendingAddition.map(c => c.id),
        this.routingProfileAssociations.itemsPendingRemoval.map(c => c.id))
      .toPromise()
      .then(() => {
        this.routingProfileAssociations.initialItems = this.routingProfileAssociations.resultItems;
        this.routingProfileAssociations.resetInitialData();
      });
  }

  public saveCustomMapsetAssociations(): Promise<any> {
    // add and remove vehicle groups from mapsets
    return this.mapsetsService.updateMapsetsForVehicleGroup(this.mapsetsAssociations.itemsPendingAddition,
      this.mapsetsAssociations.itemsPendingRemoval, this.groupId)
      .toPromise()
      .then(() => {
        this.mapsetsAssociations.initialItems = this.mapsetsAssociations.resultItems;
        this.mapsetsAssociations.resetInitialData();
      }).catch(error => {
        this.showHyperlinkError = true;
        const errorCode = error.error!.errors[0].errorCode;
        switch(errorCode) {
          case -7011:
            this.hyperlinkErrorType = this.translate.get('views.vehicle-group-detail.error.TooManyMapsetsVehicle');
            this.showHyperlinkError = true;
            break;
          case -7022:
            this.hyperlinkErrorType = this.translate.get('views.vehicle-group-detail.error.TooManyMapsetsVehicleGroup');
            this.showHyperlinkError = true;
            break;
          default:
            this.errorMessage = this.translate.get('views.vehicle-group-detail.error.UnknownMapsetError');
        }
      });
  }

  public getDefaultAvoidFavorSets() {
    const defaultSets = [];
    return this.avoidFavorService.getAvoidFavorSets().pipe(
      map(c => c.items),
      map(items => {
        items.forEach(item => {
          if (item.isDefault === true) {
            defaultSets.push(item);
          }
        });

        return defaultSets;
      }));
  }

  public getDefaultCustomPlaceSets() {
    const defaultSets = [];
    return this.customPlaceService.getCustomPlaceSets().pipe(
      map(c => c.items),
      map(items => {
        items.forEach(item => {
          if (item.isDefault === true) {
            defaultSets.push(item);
          }
        });

        return defaultSets;
      }));
  }

  public cancel() {
    this.router.navigate(['/vehicle-groups']);
  }

  public onItemSelected(itemSelected) {
    this.router.navigate(['/vehicle', itemSelected.id]);
  }

  getDefaultRoutingProfiles() {
    return this.routingProfileService.getRoutingProfiles(null, null, null, null, true).pipe(
      map(c => {
        if (c.items.length > 0) { return c.items; }

        return null;
      }));
  }

  getDefaultConfigurationProfile() {
    return this.configurationProfileService.getConfigurationProfiles(null, null, null, true).pipe(
      map(c => {
        if (c.items.length > 0) { return c.items[0]; }

        return null;
      }));
  }

  saveAssociations() {
    const promises: Promise<any>[] = [
      this.saveConfigurationProfileAssociation(),
      this.saveSpeedCoachingProfileAssociations(),
      this.saveAvoidFavorSetAssociations(),
      this.saveCustomPlaceSetAssociations()
    ];

    // Save routing profile associations only if there are profiles to add and/or remove
    if (this.routingProfileAssociations.itemsPendingAddition.length > 0 ||
      this.routingProfileAssociations.itemsPendingRemoval.length > 0) {
      promises.push(this.saveRoutingProfileAssociations());
    }

    // Save mapset associations only if there are mapsets to add and/or remove
    if (this.mapsetsAssociations.itemsPendingAddition.length > 0 ||
      this.mapsetsAssociations.itemsPendingRemoval.length > 0) {
      promises.push(this.saveCustomMapsetAssociations());
    }

    return Promise.all(promises);
  }
}
