<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="modal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">{{ 'views.admin-users-replace.ReplaceUser' | translate }}</h4>
      </div>
      <form [formGroup]="form" (ngSubmit)="save()" novalidate>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6">

              <div class="row">
                <h5 class="col-xs-12">{{'views.admin-users-replace.UserToBeReplaced' | translate}}</h5>
              </div>
              <div class="row">
                <label class="col-xs-12">
                  <span [innerHtml]="'views.admin-users-replace.User' | translate"></span>
                  <input type="text" class="form-control input-sm" readonly value="{{oldUser.emailAddress}}">
                </label>
              </div>
              <div class="row">
                <label class="col-xs-12">
                  <span [innerHtml]="'views.admin-users-replace.Role' | translate"></span>
                  <div [ngSwitch]="oldUser.role">
                    <span *ngSwitchCase="'ALKAdmin'">
                      <input type="text" class="form-control input-sm" readonly value="{{ 'roles.ALKAdmin' | translate}}">
                    </span>
                    <span *ngSwitchCase="'ALKSupport'">
                      <input type="text" class="form-control input-sm" readonly value="{{ 'roles.ALKSupport' | translate}}">
                    </span>
                    <span *ngSwitchCase="'CompanyAdmin'">
                      <input type="text" class="form-control input-sm" readonly value="{{ 'roles.CompanyAdmin' | translate}}">
                    </span>
                    <span *ngSwitchCase="'Support'">
                      <input type="text" class="form-control input-sm" readonly value="{{ 'roles.Support' | translate}}">
                    </span>
                    <span *ngSwitchCase="'User'">
                      <input type="text" class="form-control input-sm" readonly value="{{ 'roles.User' | translate}}">
                    </span>
                    <span *ngSwitchDefault>
                      <input type="text" class="form-control input-sm" readonly [value]="oldUser.role">
                    </span>
                  </div>
                </label>
              </div>
              <div class="row">
                <label class="col-xs-12">
                  <span [innerHtml]="'views.admin-users-replace.VehicleGroups' | translate"></span>
                  <input type="text" class="form-control input-sm" readonly value="{{oldUser.vehicleGroupsAbbreviated | stringIfNull: 'common.None' | translate}}">
                </label>
              </div>
              <div class="row">
                <label class="col-xs-12">
                  <span [innerHtml]="'views.admin-users-replace.DriverGroups' | translate"></span>
                  <input type="text" class="form-control input-sm" readonly value="{{oldUser.driverGroupsAbbreviated | stringIfNull: 'common.None' | translate}}">
                </label>
              </div>

            </div>

            <div class="col-md-6">
              <h5 class="col-xs-12">{{'views.admin-users-replace.NewUser' | translate}}</h5>
              <br/>
              <label class="col-xs-12" [ngClass]="{'has-error': !form.controls.firstName.valid }">
              <span [innerHtml]="'views.user-settings.FirstName' | translate"></span>
              <input type="text" class="form-control input-sm" formControlName="firstName" [disabled]="isProcessing" />
              <div *ngIf="(!form.controls.firstName.valid)" class="glyphicon glyphicon-exclamation-sign error-glyph">
                <div class="error-tooltip">
                  <p *ngIf="form.controls.firstName.errors?.required">{{ 'common.FieldIsRequired' | translate }}</p>
                  <p *ngIf="form.controls.firstName.errors?.maxlength">{{ 'common.MustBeLessThanChars' | translate: {maxChars: form.controls.firstName.errors?.maxlength.requiredLength} }}</p>
                  <p *ngIf="form.controls.firstName.errors?.minlength">{{ 'common.MustBeMoreThanChars' | translate:{minchars: form.controls.firstName.errors?.minlength.requiredLength} }}</p>
                </div>
              </div>
            </label>
              <label class="col-xs-12" [ngClass]="{'has-error': !form.controls.lastName.valid }">
              <span [innerHtml]="'views.user-settings.LastName' | translate"></span>
              <input type="text" class="form-control input-sm" formControlName="lastName" [disabled]="isProcessing" />
              <div *ngIf="(!form.controls.lastName.valid)" class="glyphicon glyphicon-exclamation-sign error-glyph">
                <div class="error-tooltip">
                  <p *ngIf="form.controls.lastName.errors?.required">{{ 'common.FieldIsRequired' | translate }}</p>
                  <p *ngIf="form.controls.lastName.errors?.maxlength">{{ 'common.MustBeLessThanChars' | translate: {maxChars: form.controls.lastName.errors?.maxlength.requiredLength} }}</p>
                  <p *ngIf="form.controls.lastName.errors?.minlength">{{ 'common.MustBeMoreThanChars' | translate:{minchars: form.controls.lastName.errors?.minlength.requiredLength} }}</p>
                </div>
              </div>
            </label>
              <label class="col-xs-12" [ngClass]="{'has-error': !form.controls.emailAddress.valid }">
              <span [innerHtml]="'views.user-settings.EmailAddress' | translate"></span>
              <input type="text" class="form-control input-sm" formControlName="emailAddress" [disabled]="isProcessing" />
              <div *ngIf="(!form.controls.emailAddress.valid)" class="glyphicon glyphicon-exclamation-sign error-glyph">
                <div class="error-tooltip">
                  <p *ngIf="form.controls.emailAddress.errors?.required">{{ 'common.FieldIsRequired' | translate }}</p>
                  <p *ngIf="form.controls.emailAddress.errors?.maxlength">{{ 'common.MustBeLessThanChars' | translate: {maxChars: form.controls.emailAddress.errors?.maxlength.requiredLength} }}</p>
                  <p *ngIf="form.controls.emailAddress.errors?.minlength">{{ 'common.MustBeMoreThanChars' | translate:{minchars: form.controls.emailAddress.errors?.minlength.requiredLength} }}</p>
                  <p *ngIf="form.controls.emailAddress.errors?.alkValidEmailAddress">{{ 'common.InvalidEmailAddress' | translate }}</p>
                </div>
              </div>
            </label>
            </div>
          </div>

          <div *ngIf="(errorMsg | async)" class="col-md-12">
            <div class="row">
              <div class="alert alert-danger" role="alert">
                <label [innerHtml]="'common.Error' | translate"></label>
                <span [innerHtml]="errorMsg | async"></span>
              </div>
            </div>
          </div>

          <div *ngIf="showSubmittedSuccess" class="col-md-12">
            <div class="row">
              <div class="alert alert-success" role="alert">
                <span>{{ 'views.admin-users-replace.UserSuccessfullyReplaced' | translate }}</span>
              </div>
            </div>
          </div>

          <div class="clearfix"></div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default cancel-btn" [disabled]="isProcessing" (click)="modal.hide()">{{ 'common.Cancel' | translate }}</button>
          <button type="submit" class="btn btn-default" [disabled]="isProcessing">{{ 'views.admin-users-replace.ReplaceUser' | translate }}</button>
        </div>
      </form>
    </div>
  </div>
</div>
