import { HttpClient, HttpResponse } from '@angular/common/http';
// angular components
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
// alk common components
import { UserContextService } from './../../../alk-components/authentication';
// alk app components
import { PagingService } from './../../shared';

export interface ConfigurationProfile {
  id: number;
  name: string;
  isVehicleGroupDefault: boolean;

  // routing profile sync property
  availableUpdatesSyncInterval: number;

  // compliance properties
  outOfRouteThreshold: number;

  // distance Unit property
  distanceUnits: string;

  // driver acessibility propreties
  allowRoutingProfileEditing: boolean;
  forceRoutingProfileSelection: boolean;
  displayMapReporting: boolean;
  preventAutoRoute: null | boolean | 'null' | 'true' | 'false';
  modifiedOn?: number;
}

@Injectable()
export class ConfigurationProfilesService {
  constructor(
    private http: HttpClient,
    private userContext: UserContextService,
    private paging: PagingService
  ) {}

  getConfigProfilesByVehicleId(id: number) {
    const params = [];
    let url = `${environment.apiUrl}/v1/configuration-profiles/vehicles/${id}`;
    url += params.join('&');

    return this.http.get(url);
  }

  public setIsDefaultChanged(profileId: number, isDefault: boolean) {
    const url =
      environment.apiUrl +
      '/v1/config-profiles/' +
      profileId +
      '/isDefault/' +
      isDefault;

    return this.http.put(url, '').pipe(catchError(this.serverError));
  }

  getConfigurationProfiles(
    id?: number[],
    name?: string,
    paging?: any,
    isDefault?: boolean
  ) {
    const params = [];

    if (paging) {
      params.push(this.paging.getPagingQuery(paging));
    }

    if (isDefault != null) {
      params.push('isDefault=' + isDefault);
    }

    let url = environment.apiUrl + '/v1/configuration-profiles?';
    url += params.join('&');

    return this.http.get<any>(url, { observe: 'response' }).map((resp) => {
      const total = +resp.headers.get('X-Total-Count');
      let items: ConfigurationProfile[] = resp.body;
      items = items.map((c) => {
        // oor thresholds are stored as number of tenths of a mile/kilometer in the db. so '1' means '0.1' and '10' means '1.0'
        c.outOfRouteThreshold = c.outOfRouteThreshold / 10;
        return c;
      });

      return { total, items };
    });
  }

  deleteConfigurationProfile(id: number) {
    const url = `${environment.apiUrl}/v1/configuration-profiles/${id}`;
    return this.http.delete(url);
  }

  create(profile: ConfigurationProfile) {
    const url = `${environment.apiUrl}/v1/configuration-profiles`;
    const clonedProfile = _.cloneDeep(profile);
    // get the number of tenths of a mile/kilometer.
    clonedProfile.outOfRouteThreshold = Math.floor(
      clonedProfile.outOfRouteThreshold * 10
    );
    clonedProfile.distanceUnits = this.getCorrectDistanceUnit();
    clonedProfile.availableUpdatesSyncInterval = this.converHoursToMins(
      clonedProfile.availableUpdatesSyncInterval
    );
    clonedProfile.preventAutoRoute =
      clonedProfile.preventAutoRoute === 'null'
        ? null
        : clonedProfile.preventAutoRoute === 'true';

    const body = JSON.stringify(clonedProfile);

    return this.http.post(url, body, { observe: 'response' }).pipe(
      map((resp) => {
        const modifiedOn = +resp.headers.get('X-LastModified');
        // tslint:disable-next-line:no-string-literal
        if (modifiedOn) {
          profile.modifiedOn = modifiedOn;
        }

        const location = resp.headers.get('Location');

        const idRegex = /\/v1\/configuration-profiles\/([0-9].*)/g;
        const matches = idRegex.exec(location);

        if (matches.length > 1) {
          profile.id = +matches[1];
        }

        return profile;
      }),
      catchError((error) => {
        return observableThrowError(error.json());
      })
    );
  }

  update(id: number, profile: ConfigurationProfile) {
    const url = `${environment.apiUrl}/v1/configuration-profiles/${id}`;
    const clonedProfile = _.cloneDeep(profile);
    // get the number of tenths of a mile/kilometer.
    clonedProfile.outOfRouteThreshold = Math.floor(
      clonedProfile.outOfRouteThreshold * 10
    );
    clonedProfile.distanceUnits = this.getCorrectDistanceUnit();
    clonedProfile.availableUpdatesSyncInterval = this.converHoursToMins(
      clonedProfile.availableUpdatesSyncInterval
    );
    clonedProfile.preventAutoRoute =
      clonedProfile.preventAutoRoute === 'null'
        ? null
        : clonedProfile.preventAutoRoute === 'true';

    const body = JSON.stringify(clonedProfile);

    return this.http.put(url, body, { observe: 'response' }).pipe(
      map((resp) => {
        const modifiedOn = +resp.headers.get('X-LastModified');
        // tslint:disable-next-line:no-string-literal
        if (modifiedOn) {
          profile.modifiedOn = modifiedOn;
        }

        const location = resp.headers.get('Location');

        const idRegex = /\/v1\/configuration-profiles\/([0-9].*)/g;
        const matches = idRegex.exec(location);

        if (matches.length > 1) {
          profile.id = +matches[1];
        }

        return profile;
      }),
      catchError((error) => {
        return observableThrowError(error.json());
      })
    );
  }

  getProfile(id: number) {
    const url = `${environment.apiUrl}/v1/configuration-profiles/${id}`;
    return this.http.get(url).pipe(
      map((c) => {
        const profile = c as ConfigurationProfile;
        profile.outOfRouteThreshold = profile.outOfRouteThreshold / 10;
        profile.distanceUnits = profile.distanceUnits;
        profile.availableUpdatesSyncInterval = this.convertMinsToHours(
          profile.availableUpdatesSyncInterval
        );
        profile.preventAutoRoute =
          profile.preventAutoRoute === null
            ? 'null'
            : profile.preventAutoRoute
            ? 'true'
            : 'false';
        return profile;
      })
    );
  }

  associateVehicleGroups(id: number, vehicleGroupIds: Array<number>) {
    const url = `${environment.apiUrl}/v1/configuration-profiles/${id}/vehicle-groups`;
    const body = JSON.stringify({ entityId: vehicleGroupIds });

    return this.http.put(url, body);
  }

  getDefaultProfile(): ConfigurationProfile {
    return {
      id: -1,
      name: '',
      isVehicleGroupDefault: false,

      //// routing profile sync properties
      availableUpdatesSyncInterval: 6,

      // compliance properties
      outOfRouteThreshold: 0.2,
      // distance Unit property
      distanceUnits: this.getCorrectDistanceUnit(),

      // driver acessibility propreties
      allowRoutingProfileEditing: false,
      forceRoutingProfileSelection: true,
      displayMapReporting: false,
      preventAutoRoute: 'null',
    };
  }

  // TODO: add below to a utils/helpers file
  convertMinsToHours(mins): number {
    return mins / 60;
  }

  converHoursToMins(hours): number {
    return Math.floor(hours * 60);
  }

  getCorrectDistanceUnit(): string {
    return this.userContext.getUser().unitType === 'Metric'
      ? 'Kilometers'
      : 'Miles';
  }

  private serverError(err: any) {
    if (err instanceof HttpResponse) {
      return observableThrowError(`error: ${err.status}` || 'error');
    }
    return observableThrowError(err || 'error');
  }
}
