// angular components
import { Component, OnInit, OnDestroy, ViewChild, Output, EventEmitter, Input, ElementRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

// 3rd party components
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { ModalDirective } from 'ngx-bootstrap';
import { Observable, forkJoin } from 'rxjs';

// app components
import { AccountManagementService, Account } from './../../../alk-components/account-management';
import { alkValidEmailAddress, validateNumericRange, validateInteger } from './../../../alk-components/common-validation';
import { CompanySettingsService } from './../company-settings';
import { ConfirmationModal } from './../../shared';

@Component({
  selector: 'account-edit-modal',
  providers: [AccountManagementService],
  templateUrl: './account-edit-modal.component.html',
  styles: [`
    .share-data-modal-text {
      padding-left:5px;
      color:black
    }
    .share-data-modal-label {
      padding-left:15px
    }
  `]
})
// tslint:disable-next-line: component-class-suffix
export class AccountEditModal implements OnInit, OnDestroy {
  public isProcessing = false;
  public submitted = false;
  public isRouteReporterAccount = false;
  public form: FormGroup;

  @ViewChild('modal', { static: true }) modal: ModalDirective;
  @ViewChild('confirmModal', {static: true}) confirmModal: ConfirmationModal;
  @Output() accountChanged = new EventEmitter();
  @Output() shareDataChanged = new EventEmitter();
  @Input() account: Account;
  @Input() shareData: boolean;

  private sub1: Subscription;

  constructor(
    private accountService: AccountManagementService,
    private el: ElementRef,
    private fb: FormBuilder,
    private ag2analytics: Angulartics2GoogleAnalytics,
    private companySettings: CompanySettingsService
  ) {
    this.form = this.fb.group({
      contactName: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(64)]],
      contactEmail: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(64), alkValidEmailAddress]],
      contactPhone: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(64)]],
      numberOfWeeksToSaveData: [null,
      [Validators.compose([validateNumericRange(() => this.form, 1, 157), validateInteger(() => this.form), Validators.required])]],
      shareDataAnonymously: [],
  });
  }

  ngOnInit() {
    this.modal.onShow.subscribe(c => {
    this.accountService.isRouteReporterAccount().subscribe(
       result => {
        this.isRouteReporterAccount = result;
      }
    );
    this.loadForm(this.account, this.shareData);
    });

    this.sub1 = this.modal.onShown.subscribe(() => {
      // todo: make this another directive to auto focus bootstrap modal fields.
      const nativeElement = this.el.nativeElement.getElementsByTagName('input')[0];
      if (nativeElement) { nativeElement.focus(); }
    });
  }

  ngOnDestroy() {
    this.sub1.unsubscribe();
  }

  loadForm(attributes, shareData) {

    for (const attr in attributes) {
      if (!attributes.hasOwnProperty(attr)) { continue; }

      const control =  this.form.controls[attr] as FormControl;
      if (!control) { continue; }

      control.setValue(attributes[attr]);
    }

    this.form.get('shareDataAnonymously').setValue(shareData);
  }

  open() {
    this.ag2analytics.eventTrack('Account Edit dialog opened', {});
    this.modal.show();
  }

  save() {
    this.submitted = true;

    if (!this.form.valid) {
      this.ag2analytics.eventTrack('Account Edit save attempted', { failReason: 'Invalid input' });
      return;
    }

    this.shareData = this.form.value.shareDataAnonymously;
    this.account.contactName = this.form.value.contactName;
    this.account.contactEmail = this.form.value.contactEmail;
    this.account.contactPhone = this.form.value.contactPhone;
    this.account.numberOfWeeksToSaveData = this.form.value.numberOfWeeksToSaveData;

    this.isProcessing = true;
    const accountObs = this.accountService.updateAccount(this.account);
    const shareDataObs = this.companySettings.updateShareDataSetting(this.form.value.shareDataAnonymously);

    forkJoin([accountObs, shareDataObs])
      .subscribe((data) => {
        this.isProcessing = false;
        this.submitted = false;

        this.ag2analytics.eventTrack('Account Edit save successful', {});

        this.modal.hide();
        this.accountChanged.emit(this.account);
        this.shareDataChanged.emit(this.shareData);
      });
  }
}
