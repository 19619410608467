<div class="container import-container">

  <div class="panel page-panel">
    <div class="row panel action-panel">
      <div class="col-md-4 col-xs-4 col-sm-4">&nbsp;</div>
      <div class="col-md-4 col-sm-4 col-xs-4">
        <h4 class="text-center">
          <span [innerHtml]="'views.vehicle-import.Title' | translate"></span>
        </h4>
      </div>
      <div class="col-md-4 col-sm-4 col-xs-4">&nbsp;</div>
    </div>

    <div class="row grid-panel">
      <div class="panel-body">
        <div class="filter-panel">
          <div class="row">
            <div class="col-md-5 col-xs-5 col-sm-5">
              <label class="col-md-6 col-xs-6 col-sm-6">
                <div class="row">
                  <span [innerHtml]="'views.driver-vehicle-import-ImportFile' | translate"></span><br/>
                  <input type="file" name="importFile" (change)="importFileChanged($event)"
                    #importFile (click)="importFile.value=''" [disabled]="isProcessing" />
                </div>
                <div class="row checkbox">
                  <label>
                    <input type="checkbox" name="firstRowHasHeadings" [(ngModel)]="firstRowHasHeadings" (ngModelChange)="firstRowHasHeadingsChanged()">
                    <span class="col-heading-checkbox" [innerHtml]="'views.driver-vehicle-import-firstRowHasHeadings' | translate"></span>
                  </label>
                </div>
              </label>

              <br />
              <div class="clearfix"></div>

              <div class="loading">
                  <span *ngIf="isProcessing">
                      <span class="glyphicon glyphicon-refresh spinner"></span> Processing...
                    </span>
                    <div *ngIf="(preprocessErrorMessage | async)" class="alert alert-danger alert-dismissible" role="alert">
                        <button type="button" class="close" (click)="preprocessErrorMessage=null"><span>&times;</span></button>
                        <strong>{{preprocessErrorMessage | async}}</strong>
                      </div>
                    <div *ngIf="(preprocessSuccessMessage | async)" class="alert alert-success alert-dismissible" role="alert">
                        <button type="button" class="close" (click)="preprocessSuccessMessage=null"><span>&times;</span></button>
                        <strong>{{ 'views.driver-vehicle-import-readyToImport' | translate }}</strong>
                        <span>{{preprocessSuccessMessage | async}}</span>
                    </div>
                    <div *ngIf="(importSuccessMessage | async)" class="alert alert-success alert-dismissible" role="alert">
                        <strong>{{importSuccessMessage | async}}</strong>
                        <ul>
                          <li><a (click)="downloadResults()">{{ 'views.driver-vehicle-import-downloadResults' | translate }}</a></li>
                        </ul>
                      </div>
                      <div *ngIf="(importErrorMessage | async)" class="alert alert-danger alert-dismissible" role="alert">
                        <button type="button" class="close" (click)="importErrorMessage=null"><span>&times;</span></button>
                        <div><strong>{{importErrorMessage | async}} </strong> </div>
                        <a (click)="downloadResults()"><span class="glyphicon glyphicon-cloud-download"></span> {{ 'views.driver-vehicle-import-downloadResults' | translate }}</a>
                      </div>
              </div>


            </div>

              <div class="info-text col-md-6 col-sm-12 col-xs-12">
                  <div class="row">
                      <span class="glyphicon glyphicon-exclamation-sign col-md-1 col-sm-1 col-xs-1"></span>
                      <ul class="col-md-11 col-sm-11 col-xs-11">
                        <li><span class="" [innerHtml]="'views.driver-vehicle-import-instruction' | translate"></span></li>
                      <ul class="sub-list">
                          <li><span class="" [innerHtml]="'views.vehicle-summary.VehicleID' | translate"></span></li>
                          <li><span class="" [innerHtml]="'views.vehicle-summary.Name' | translate"></span></li>
                          <li><span class="" [innerHtml]="'views.avoid-favor-set.tab.VehicleGroups' | translate"></span></li>
                        </ul>
                    </ul>
                  </div>
                  <br/>
                  <span *ngIf="!canImportVehicles" [innerHtml]="'views.driver-vehicle-import-cannotImport' | translate"><br /></span>
                    <a (click)="downloadSample()" > <span class="glyphicon glyphicon-cloud-download"></span> <span [innerHtml]="'views.driver-vehicle-import-downloadSample' | translate"></span> </a>
                </div>

          </div>

        </div>
      </div>

    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-default pull-left"   *ngIf="( (importSuccessMessage | async) || (importErrorMessage | async))" [routerLink]="['/admin']">
          <a class="link-btn" >Return</a>
      </button>

      <button class="btn btn-default cancel-btn" type="button" [routerLink]="['/admin']" [disabled]="isProcessing" *ngIf="!importSuccess">{{'common.Cancel' | translate }}</button>
      <button type="button" class="btn btn-success pull-right" (click)="upload()" [disabled]="!preProcessSuccess || isProcessing" *ngIf="!importSuccess">Upload</button>
      <!--filler buttons-->
      <button class="btn btn-default cancel-btn" type="button" [disabled]="true" *ngIf="importSuccess">{{'common.Cancel' | translate }}</button>
      <button type="button" class="btn btn-success pull-right" [disabled]="true" *ngIf="importSuccess">Upload</button>
  </div>

</div>
