
import {of as observableOf, forkJoin as observableForkJoin } from 'rxjs';

import {map, debounceTime, distinctUntilChanged, switchMap} from 'rxjs/operators';
// angular components
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

// alk shared components
import { AccountUserManagementService, UserInAccount } from './../../../alk-components/account-user-management';
import { UserContextService } from './../../../alk-components/authentication';
import { ConfirmationModal } from './../../shared';
import { AdminUserEditModal } from './admin-user-edit-modal.component';
import { AdminUserReplaceModal } from './admin-user-replace-modal.component';

@Component({
  selector: 'admin-users',
  providers: [AccountUserManagementService],
  templateUrl: './admin-users.component.html'
})
// tslint:disable-next-line: component-class-suffix
export class AdminUsers implements OnInit {

  @ViewChild('confirmModal', { static: true }) confirmModal: ConfirmationModal;
  @ViewChild('adminUserEditModal', { static: true }) addEditModal: AdminUserEditModal;
  @ViewChild('adminUserReplaceModal', { static: true }) userReplaceModal: AdminUserReplaceModal;

  // number of rows of users to display per page
  @Input() displayPerPage = 10;

  isProcessing = false;
  allUsers: Array<any> = [];
  filteredUsers: Array<any> = [];
  sortAsc = true;
  sortCol = 'fullName';
  searchTerm = new FormControl();
  selectedGroupIds: Array<any> = [];
  pageItems: Array<any> = [];
  userAdded = false;
  total = 0;
  currentPage = 0;
  errorMsg: string;
  canViewVehicleGroups = false;
  canViewDriverGroups = false;

  constructor(
    private userService: AccountUserManagementService,
    private userContext: UserContextService,
    private translate: TranslateService
  ) {
      const partnerActivities = this.userContext.getUser().partner.partnerActivities;
      this.canViewVehicleGroups = partnerActivities.canViewVehicleGroups;
      this.canViewDriverGroups = partnerActivities.canViewDriverGroups;
    }

  ngOnInit() {
    this.isProcessing = true;

    this.searchTerm.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => this.filterAll(term)), )
      .subscribe(items => {
        this.filteredUsers = items;
        this.total = this.filteredUsers.length;
        this.currentPage = 0;
        this.updateCurrentPage();
      });

    this.loadUsers();

  }

  nextPage() {
    if (!this.hasNextPage()) { return; }
    this.currentPage++;

    this.updateCurrentPage();
  }

  previousPage() {
    if (!this.hasPreviousPage()) { return; }
    this.currentPage--;

    this.updateCurrentPage();
  }

  hasNextPage() {
    const nextPageStartIndex = (this.currentPage + 1) * this.displayPerPage;
    return this.total > nextPageStartIndex;
  }

  hasPreviousPage() {
    return this.currentPage > 0;
  }

  pageStart() {
    if (this.filteredUsers.length === 0) { return 0; }
    return (this.currentPage * this.displayPerPage) + 1;
  }

  pageEnd() {
    let retVal = (this.currentPage * this.displayPerPage) + this.displayPerPage;
    if (retVal > this.total) {
      retVal = this.total;
    }
    return retVal;
  }

  isCurrentUser(user) {
    const usr = this.userContext.getUser();
    if (!usr) { return false; }

    return (user.userId === usr.userId);
  }

  editUser(user) {
    // to handle if the entire row is clicked, make sure here as well it is not trying to edit the current user (SWAT-217)
    if (!this.isCurrentUser(user)) {
      this.addEditModal.open(false, user.userId);
    }
  }

  isInRole(role) {
    const user = this.userContext.getUser();
    if (!user) { return false; }
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < user.roles.length; i++) {
      if (user.roles[i].toUpperCase() === role.toUpperCase()) { return true; }
    }
    return false;
  }

  deleteUser(user) {
    this.confirmModal.body = `Are you sure you want to delete user ${user.fullName} (${user.emailAddress})?`;
    this.confirmModal.open()
      .then(() => {
        this.isProcessing = true;

        this.userService.removeUser(user.userId)
          .subscribe(c => {
            this.loadUsers();
            this.isProcessing = false;

          }, (error) => {
            this.errorMsg = 'Error while deleting user';
            this.isProcessing = false;
          });
      }, () => {
      });
  }

  replaceUser(user) {
    this.userReplaceModal.oldUser = user;
    this.userReplaceModal.open();
  }

  onUserUpdated(update) {
    if (update.addedNew) {
      this.loadUsers();
    } else {
      this.loadUsers();
    }
  }

  onUserReplaced(user) {
    this.loadUsers();
  }

  clearSearchTerm() {
    this.searchTerm.setValue('');
  }

  sortString(column: string, asc: boolean) {
    const multiplyBy = asc ? 1 : -1;

    this.filteredUsers.sort((a, b) => {
      const aUpperCase = a[column] != null ? a[column].toUpperCase() : '';
      const bUpperCase = b[column] != null ? b[column].toUpperCase() : '';
      if (aUpperCase < bUpperCase) { return (-1 * multiplyBy); }
      if (aUpperCase > bUpperCase) { return (1 * multiplyBy); }
      return 0;
    });

    this.updateCurrentPage();
  }

  sortNumber(column: string, asc: boolean) {
    const multiplyBy = asc ? 1 : -1;

    this.filteredUsers.sort((a, b) => {
      return (a[column] - b[column]) * multiplyBy;
    });

    this.updateCurrentPage();
  }

  getToolTipForGroups(groups: Array<any>) {
    if (!groups || groups.length === 0) { return ''; }
    return groups.map(c => c.groupName).join(', ');
  }

  onUserEnabledChanged(user: UserInAccount) {
    user.enabled = !user.enabled;
    user.stateName = user.enabled ? 'Enabled' : 'Disabled'; // should we be doing this? or maybe get rid of StateName

    // tslint:disable-next-line: deprecation
    this.userService.setUserEnabledStatus(user.userId, user.enabled).subscribe(null,
      () => this.errorMsg = `Error changing user enabled status for user ${user.emailAddress}`);
  }

  loadUsers() {

    observableForkJoin([
      this.userService.getUsers(),
      this.populateTranslations()
    ]).subscribe(results => {
      const users = results[0];
      const translations = results[1];

      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < users.length; i++) {
        users[i].roleDisplay = translations[users[i].role];
      }

      this.allUsers = users;
      this.filteredUsers = this.allUsers;
      this.total = users.length;
      this.updateCurrentPage();

      this.isProcessing = false;
    }, (error) => {
      this.errorMsg = 'Error loading user information: ' + error.message;
      this.isProcessing = false;
    });

  }

  updateCurrentPage() {
    const startPageIndex = this.currentPage * this.displayPerPage;
    const endPageIndex = startPageIndex + this.displayPerPage;

    this.pageItems = this.filteredUsers.slice(startPageIndex, endPageIndex);
  }

  filter(users: Array<any>, searchTerm: string) {
    searchTerm = searchTerm.toUpperCase();

    return users.filter(user => {
      if (user.fullName != null && user.fullName.toUpperCase().indexOf(searchTerm) !== -1) { return true; }
      if (user.lastName != null && user.lastName.toUpperCase().indexOf(searchTerm) !== -1) { return true; }
      if (user.emailAddress != null && user.emailAddress.toUpperCase().indexOf(searchTerm) !== -1) { return true; }
      // if the searchterm matches any vehicle or driver group, even if it is not one displayed in the abbreviated summary, include it
      if (user.vehicleGroups.find(vehicleGroup => vehicleGroup.groupName.toUpperCase().indexOf(searchTerm) > -1)) { return true; }
      if (user.driverGroups.find(driverGroup => driverGroup.groupName.toUpperCase().indexOf(searchTerm) > -1)) { return true; }

      return false;
    });
  }

  filterAll(searchTerm?: string) {
    let results = this.allUsers;

    if (searchTerm) {
      results = this.filter(results, searchTerm);
    }

    return observableOf(results);
  }

  populateTranslations() {
    return this.translate.get([
      // roles
      'roles.ALKAdmin',
      'roles.ALKSupport',
      'roles.Support',
      'roles.CompanyAdmin',
      'roles.User'
    ]).pipe(map(translated => {
      return {
        ALKAdmin: translated['roles.ALKAdmin'],
        ALKSupport: translated['roles.ALKSupport'],
        CompanyAdmin: translated['roles.CompanyAdmin'],
        Support: translated['roles.Support'],
        User: translated['roles.User']
      };
    }));
  }
}
