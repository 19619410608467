// angular libraries
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// 3rd party libraries
import { Observable } from 'rxjs/Observable';
import { flatMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../shared/authentication';
// app libraries
import { UserContextService } from './../../../alk-components/authentication';

@Injectable()
export class CompanySettingsService {
  constructor(
    private http: HttpClient,
    private userContext: UserContextService,
    private authService: AuthenticationService
  ) {}
  fleetApi = environment.fleetApiUrl + 'api/assets/v1';

  public getDataSharingSettings(): Observable<any> {
    return this.authService.getFleetsApiToken().pipe(
      flatMap((resp) => {
        const headers = {
          Authorization: `bearer ${resp}`,
        };
        const url = `${this.fleetApi}/company/dataShareSettings`;
        return this.http.get(url, { headers });
      })
    );
  }

  public getMapSetSettings() {
    return this.authService.getFleetsApiToken().pipe(
      flatMap((resp) => {
        const headers = {
          Authorization: `bearer ${resp}`,
        };
        const url = `${this.fleetApi}/company/mapsetSettings`;
        return this.http.get(url, { headers });
      })
    );
  }

  public updateShareDataSetting(
    shareDataAnonymously: boolean
  ): Observable<any> {
    return this.authService.getFleetsApiToken().pipe(
      flatMap((resp) => {
        const headers = {
          Authorization: `bearer ${resp}`,
        };
        const body = { shareDataAnonymously };
        const url = `${this.fleetApi}/company/dataShareSettings`;
        return this.http.post(url, body, { headers });
      })
    );
  }
}
