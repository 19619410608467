import { of as observableOf } from 'rxjs';
import { switchMap, tap, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { EventEmitter, OnInit, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
// alk
import { GroupAddModal } from './group-add-modal';
import { GroupAddPaginatedModal } from './group-add-paginated-modal';
var GroupAssociationsComponent = /** @class */ (function () {
    function GroupAssociationsComponent() {
        // what are these groups of? if vehicles, use 'Vehicle' here so it will display as 'Vehicle Groups'
        this.entityLabel = '';
        // when true add and remove functionality is hidden
        this.readonly = false;
        this.additionalTableClasses = '';
        this.itemSelected = new EventEmitter();
        // items which are initially already members of this group. set only once at startup
        // tslint:disable-next-line: variable-name
        this._initialItems = [];
        this._total = 0;
        // number of rows of groups to display per page
        this.displayPerPage = 10;
        // items added to the group from the Add button
        this.itemsPendingAddition = [];
        // tslint:disable-next-line: no-output-on-prefix
        this.onItemsAdded = new EventEmitter();
        // items which were initially provided which are now removed (via the group by the X buttons in the list)
        // note that this does not include added items which are removed, those are just cleared
        this.itemsPendingRemoval = [];
        // tslint:disable-next-line: no-output-on-prefix
        this.onItemsRemoved = new EventEmitter();
        // the final result item set (initialItems + itemsPendingAddition - itemsPendingRemoval)
        // to be clear, itemsPendingRemoval will NOT be included in this list, they will be removed from it!
        // pendingRemoval and pendingAddition properties will be undefined
        // so all initialItems (DB state) + all itemsPendingAddition (in memory not yet saved) - allitemsPendingRemoval (in memory not yet saved)
        this.resultItems = [];
        // all items potentially displayable (restricted by display pagination) in this control
        // to be clear, itemsPendingRemoval WILL be included in this list, they just have pendingRemoval = true
        this.allDisplayableItems = [];
        // all items (initial+added-removed) which match the search term filter
        this.filteredItems = [];
        // filteredItems which are currently displayed on this page (pagination)
        this.currentPageItems = [];
        this.totalFilteredItems = 0;
        this.currentPage = 0;
        this.searchTerm = new FormControl();
    }
    Object.defineProperty(GroupAssociationsComponent.prototype, "initialItems", {
        get: function () {
            return this._initialItems;
        },
        set: function (initialItems) {
            this._initialItems = initialItems;
            this.resetInitialData();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GroupAssociationsComponent.prototype, "total", {
        get: function () {
            return this._total;
        },
        set: function (total) {
            this._total = total;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GroupAssociationsComponent.prototype, "columnDefinitions", {
        set: function (columnDefinitions) {
            this._columnDefinitions = columnDefinitions;
            this.addModal.columnDefinitions = columnDefinitions;
            this.addPaginatedModal.columnDefinitions = columnDefinitions;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GroupAssociationsComponent.prototype, "allPossibleItems", {
        set: function (allPossibleItems) {
            // we only need to pass this along to the add modal
            if (this.entityLabel !== 'Mapsets') {
                this.addModal.allPossibleItems = allPossibleItems;
            }
        },
        enumerable: true,
        configurable: true
    });
    GroupAssociationsComponent.prototype.ngOnChanges = function () {
        this.entityHeading = this.entityLabel; // todo: translate
    };
    GroupAssociationsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.resetInitialData();
        this.searchTerm.valueChanges.pipe(debounceTime(200), distinctUntilChanged(), tap(function () {
            _this.isLoading = true;
            _this.currentPage = 0;
        }), switchMap(function (searchTerm) { return _this.filterResults(searchTerm); }))
            .subscribe(function (results) {
            _this.filteredItems = results;
            _this.totalFilteredItems = _this.filteredItems.length;
            _this.updateCurrentPage();
            _this.isLoading = false;
        });
    };
    GroupAssociationsComponent.prototype.clearSearchTerm = function () {
        this.searchTerm.setValue('');
    };
    // user clicked to remove this item (pendingStates not yet modified upon entry of this function)
    GroupAssociationsComponent.prototype.remove = function (item) {
        var _this = this;
        // if item is in pendingAddition state, just splice it
        if (item.pendingAddition) {
            // splice it from allDisplayableItems
            var indexAll = this.allDisplayableItems.indexOf(item);
            if (indexAll >= 0) {
                this.allDisplayableItems.splice(indexAll, 1);
            }
            // remove it form itemsPendingAddition
            var indexAddedPending = this.itemsPendingAddition.indexOf(item);
            if (indexAddedPending >= 0) {
                this.itemsPendingAddition.splice(indexAddedPending, 1);
            }
        }
        else if (item.pendingRemoval) {
            // update the item in allDisplayableItems
            item.pendingRemoval = false;
            // remove from itemsPendingRemoval
            var index = this.itemsPendingRemoval.indexOf(item);
            if (index >= 0) {
                this.itemsPendingRemoval.splice(index, 1);
            }
        }
        else {
            // modify state in allDisplayableItems
            item.pendingRemoval = true;
            // add to itemsPendingRemoval
            this.itemsPendingRemoval.push(item);
        }
        this.filterResults(this.searchTerm.value)
            .subscribe(function (results) {
            _this.filteredItems = results;
            _this.totalFilteredItems = _this.filteredItems.length;
            _this.updateCurrentPage();
            _this.isLoading = false;
        });
        this.buildResultsArray();
        // if we've removed all items on the current viewing page, back up a page as long as there are any items
        if (this.currentPageItems.length === 0 && this.allDisplayableItems.length > 0) {
            this.previousPage();
        }
        this.onItemsRemoved.emit(this.itemsPendingRemoval);
    };
    GroupAssociationsComponent.prototype.openAddModal = function () {
        // any initial or pending addition item to be excluded from the groups to add from this modal
        if (this.entityLabel === 'Mapsets') {
            this.addPaginatedModal.excludedGroups = this.allDisplayableItems;
            this.addPaginatedModal.open();
        }
        else {
            this.addModal.excludedGroups = this.allDisplayableItems;
            this.addModal.displayPerPage = 10;
            this.addModal.open();
        }
    };
    GroupAssociationsComponent.prototype.resetInitialData = function () {
        var _this = this;
        // reset all values
        this.totalFilteredItems = 0;
        this.filteredItems = [];
        this.itemsPendingAddition = [];
        this.itemsPendingRemoval = [];
        this.allDisplayableItems = [];
        this.currentPageItems = [];
        this.currentPage = 0;
        this.searchTerm.setValue('');
        // make sure any pending changes are cleared out
        this.initialItems.forEach(function (initialItem) {
            initialItem.pendingAddition = false;
            initialItem.pendingRemoval = false;
        });
        // allDisplayableItems and filteredItems start out the same as initialItems
        this.allDisplayableItems = this.initialItems.slice();
        this.filteredItems = this.initialItems.slice();
        this.sortItems(this.initialItems, 0, true);
        this.filterResults(this.searchTerm.value)
            .subscribe(function (results) {
            _this.filteredItems = results;
            _this.totalFilteredItems = _this.filteredItems.length;
            _this.updateCurrentPage();
            _this.isLoading = false;
        });
        this.buildResultsArray();
    };
    GroupAssociationsComponent.prototype.addInitialItems = function (addedItems) {
        this.onAddedItems(addedItems);
    };
    GroupAssociationsComponent.prototype.onRowClicked = function (itemSelected) {
        this.itemSelected.emit(itemSelected);
    };
    // when the user has selected new added items from the popup
    GroupAssociationsComponent.prototype.onAddedItems = function (addedItems) {
        var _this = this;
        addedItems.forEach(function (item) {
            // items that are already added should be excluded from the Add Dialog already
            // but let's assert to make sure
            var indexAdded = _this.itemsPendingAddition.indexOf(item);
            if (indexAdded >= 0) {
                console.error('Trying to add item which is already in itemsPendingAddition:', item);
            }
            var indexAll = _this.allDisplayableItems.indexOf(item);
            if (indexAll >= 0) {
                console.error('Trying to add item which is already in allDisplayableItems:', item);
            }
            item.pendingAddition = true;
            _this.itemsPendingAddition.push(item);
            _this.allDisplayableItems.push(item); // resort allDisplayableItems?
        });
        this.sortItems(this.allDisplayableItems, 0, true);
        // re filter in case the newly added items don't match the search term filter
        this.filterResults(this.searchTerm.value)
            .subscribe(function (results) {
            _this.filteredItems = results;
            _this.totalFilteredItems = _this.filteredItems.length;
            _this.updateCurrentPage();
            _this.isLoading = false;
        });
        this.buildResultsArray();
        this.onItemsAdded.emit(this.itemsPendingAddition);
    };
    GroupAssociationsComponent.prototype.nextPage = function () {
        if (!this.hasNextPage()) {
            return;
        }
        this.currentPage++;
        this.updateCurrentPage();
    };
    GroupAssociationsComponent.prototype.previousPage = function () {
        if (!this.hasPreviousPage()) {
            return;
        }
        this.currentPage--;
        this.updateCurrentPage();
    };
    GroupAssociationsComponent.prototype.hasNextPage = function () {
        var nextPageStartIndex = (this.currentPage + 1) * this.displayPerPage;
        return this.totalFilteredItems > nextPageStartIndex;
    };
    GroupAssociationsComponent.prototype.hasPreviousPage = function () {
        return this.currentPage > 0;
    };
    GroupAssociationsComponent.prototype.pageStart = function () {
        if (this.filteredItems.length === 0) {
            return 0;
        }
        return (this.currentPage * this.displayPerPage) + 1;
    };
    GroupAssociationsComponent.prototype.pageEnd = function () {
        var retVal = (this.currentPage * this.displayPerPage) + this.displayPerPage;
        if (retVal > this.totalFilteredItems) {
            retVal = this.totalFilteredItems;
        }
        return retVal;
    };
    GroupAssociationsComponent.prototype.updateCurrentPage = function () {
        this.isLoading = true;
        var startIndex = this.currentPage * this.displayPerPage;
        var endIndex = startIndex + this.displayPerPage;
        this.currentPageItems = this.filteredItems.slice(startIndex, endIndex);
        this.isLoading = false;
    };
    GroupAssociationsComponent.prototype.filterResults = function (searchTerm) {
        if (!searchTerm) {
            return observableOf(this.allDisplayableItems);
        }
        searchTerm = searchTerm.toUpperCase();
        // TODO: right now this is filtering on all results and not honoring the GroupItemColumn.isFilterable setting
        return observableOf(this.allDisplayableItems.filter(function (item) {
            var found = 0;
            item.fields.forEach(function (field) {
                if (field != null && field.toString().toUpperCase().indexOf(searchTerm) >= 0) {
                    found++;
                }
            });
            return (found > 0);
        }));
    };
    GroupAssociationsComponent.prototype.sortItems = function (items, sortColumnIndex, sortAscending) {
        if (sortAscending === void 0) { sortAscending = true; }
        var multiplyBy = sortAscending ? 1 : -1;
        items.sort(function (a, b) {
            // first sort pending removals on top
            var aPendingRemoval = a.pendingRemoval;
            if (aPendingRemoval === undefined) {
                aPendingRemoval = false;
            }
            var bPendingRemoval = b.pendingRemoval;
            if (bPendingRemoval === undefined) {
                bPendingRemoval = false;
            }
            if (aPendingRemoval < bPendingRemoval) {
                return (1);
            }
            if (aPendingRemoval > bPendingRemoval) {
                return (-1);
            }
            // second sort pending removals on top
            var aPendingAddition = a.pendingAddition;
            if (aPendingAddition === undefined) {
                aPendingAddition = false;
            }
            var bPendingAddition = b.pendingAddition;
            if (bPendingAddition === undefined) {
                bPendingAddition = false;
            }
            if (aPendingAddition < bPendingAddition) {
                return (1);
            }
            if (aPendingAddition > bPendingAddition) {
                return (-1);
            }
            // lastly sort by the specified property
            var aa = null;
            var bb = null;
            if (typeof a[sortColumnIndex] === 'string' && typeof b[sortColumnIndex] === 'string') {
                aa = a.fields[sortColumnIndex].toUpperCase();
                bb = b.fields[sortColumnIndex].toUpperCase();
            }
            else {
                aa = a.fields[sortColumnIndex];
                bb = b.fields[sortColumnIndex];
            }
            if (aa < bb) {
                return (-1 * multiplyBy);
            }
            if (aa > bb) {
                return (1 * multiplyBy);
            }
            return 0;
        });
    };
    GroupAssociationsComponent.prototype.buildResultsArray = function () {
        var _this = this;
        // start with the initial items
        this.resultItems = this.initialItems.slice();
        // then remove all user removed items
        this.itemsPendingRemoval.forEach(function (removedItem) {
            var idx = _this.resultItems.map(function (existingItem) { return existingItem.id; }).indexOf(removedItem.id);
            _this.resultItems.splice(idx, 1);
        });
        // then add the added items
        this.itemsPendingAddition.forEach(function (addedItem) { _this.resultItems.push(addedItem); });
    };
    return GroupAssociationsComponent;
}());
export { GroupAssociationsComponent };
