<fieldset [disabled]="isProcessing || isInitializing">
  <form [formGroup]="form" (ngSubmit)="save()" novalidate>
    <div class="container">
      <div class="message-panel">
        <div
          *ngIf="successMessage | async"
          class="alert alert-success alert-dismissible"
          role="alert"
        >
          <button type="button" class="close" (click)="successMessage = null">
            <span>&times;</span>
          </button>
          <strong>{{ successMessage | async }}</strong>
        </div>
        <div
          *ngIf="errorMessage | async"
          class="alert alert-danger alert-dismissible"
          role="alert"
        >
          <button type="button" class="close" (click)="errorMessage = null">
            <span>&times;</span>
          </button>
          <strong>{{ errorMessage | async }}</strong>
        </div>
      </div>

      <div class="panel page-panel">
        <div class="row panel action-panel">
          <div class="col-xs-12">
            <h4 class="text-center">
              <span
                [innerHtml]="
                  'views.configuration-profile-detail.Title' | translate
                "
              ></span>
              <span style="font-size: 13px" *ngIf="isInitializing"
                >({{ "common.Loading" | translate }})</span
              >
            </h4>
          </div>
        </div>

        <div class="row header-panel">
          <label
            class="col-md-6"
            [ngClass]="{
              'has-error': !isInitializing && !form.controls.name.valid
            }"
          >
            <span
              [innerHtml]="
                'views.configuration-profile-detail.form.Name' | translate
              "
            ></span>
            <input
              type="text"
              class="form-control input-sm"
              formControlName="name"
            />
            <div
              *ngIf="!isInitializing && !form.controls.name.valid"
              class="glyphicon glyphicon-exclamation-sign error-glyph"
            >
              <div class="error-tooltip">
                <p *ngIf="form.controls.name.errors?.required">
                  {{ "common.FieldIsRequired" | translate }}
                </p>
                <p *ngIf="form.controls.name.errors?.maxlength">
                  {{
                    "common.MustBeLessThanChars"
                      | translate
                        : {
                            maxChars:
                              form.controls.name.errors?.maxlength
                                .requiredLength
                          }
                  }}
                </p>
                <p *ngIf="form.controls.name.errors?.minlength">
                  {{
                    "common.MustBeMoreThanChars"
                      | translate
                        : {
                            minchars:
                              form.controls.name.errors?.minlength
                                .requiredLength
                          }
                  }}
                </p>
              </div>
            </div>
          </label>

          <div class="col-md-6">
            <div class="pull-right">
              <td>
                <span (click)="isDefaultToggle()">
                  <span
                    *ngIf="!form.value.isVehicleGroupDefault"
                    class="ico ico-checkbox-unchecked"
                    style="
                      position: absolute;
                      margin-left: -20px;
                      margin-top: 3px;
                    "
                  ></span>
                  <span
                    *ngIf="form.value.isVehicleGroupDefault"
                    class="ico ico-checkbox-checked"
                    style="
                      position: absolute;
                      margin-left: -20px;
                      margin-top: 3px;
                    "
                  ></span>
                </span>
              </td>
              <label>
                <span
                  [innerHtml]="
                    'views.customdata-profile-detail.form.IsDefault' | translate
                  "
                ></span>
              </label>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>

        <div class="row tab-panel">
          <tabset>
            <tab
              heading="{{
                'views.configuration-profile-detail.tab.Options' | translate
              }}"
            >
              <div class="panel accordion-panel">
                <accordion closeOthers="true" class="accordion">
                  <accordion-group isOpen="true" #intervalGroup>
                    <div accordion-heading>
                      <span
                        [innerHtml]="
                          'views.configuration-profile-detail.FleetLinkSyncInterval'
                            | translate
                        "
                      ></span>
                      <i
                        *ngIf="
                          !form.controls.availableUpdatesSyncInterval.valid
                        "
                        class="glyphicon glyphicon-exclamation-sign error-glyph"
                      ></i>
                      <i
                        class="pull-right glyphicon"
                        [ngClass]="{
                          'glyphicon-triangle-bottom': intervalGroup?.isOpen,
                          'glyphicon-triangle-right': !intervalGroup?.isOpen
                        }"
                      ></i>
                    </div>
                    <div class="row">
                      <label
                        class="col-md-4"
                        [ngClass]="{
                          'has-error':
                            !form.controls.availableUpdatesSyncInterval.valid
                        }"
                      >
                        <span
                          >{{
                            "views.configuration-profile-detail.form.AvailableUpdatesSyncInterval"
                              | translate
                          }}
                          <i
                            class="glyphicon glyphicon-question-sign"
                            data-container="body"
                            [tooltip]="
                              'views.configuration-profile-detail.tooltip.AvailableUpdatesSyncInterval'
                                | translate
                            "
                          ></i
                        ></span>
                        <input
                          type="number"
                          min="0.5"
                          max="24"
                          step="0.5"
                          class="form-control input-sm"
                          formControlName="availableUpdatesSyncInterval"
                        />
                        <div
                          *ngIf="
                            !form.controls.availableUpdatesSyncInterval.valid
                          "
                          class="
                            glyphicon glyphicon-exclamation-sign
                            error-glyph error-glyph-2x
                          "
                        >
                          <div class="error-tooltip">
                            <p
                              *ngIf="
                                form.controls.availableUpdatesSyncInterval
                                  .errors?.required
                              "
                            >
                              {{ "common.FieldIsRequired" | translate }}
                            </p>
                            <p
                              *ngIf="
                                form.controls.availableUpdatesSyncInterval
                                  .errors?.max
                              "
                            >
                              {{
                                "common.MustBeLessThanHours"
                                  | translate
                                    : {
                                        maxHours:
                                          form.controls
                                            .availableUpdatesSyncInterval.errors
                                            ?.max.requiredLength
                                      }
                              }}
                            </p>
                            <p
                              *ngIf="
                                form.controls.availableUpdatesSyncInterval
                                  .errors?.min
                              "
                            >
                              {{
                                "common.MustBeMoreThanHours"
                                  | translate
                                    : {
                                        minHours:
                                          form.controls
                                            .availableUpdatesSyncInterval.errors
                                            ?.min.requiredLength
                                      }
                              }}
                            </p>
                            <p
                              *ngIf="
                                form.controls.availableUpdatesSyncInterval
                                  .errors?.incrementBy
                              "
                            >
                              {{
                                "common.Increment"
                                  | translate
                                    : {
                                        increment:
                                          form.controls
                                            .availableUpdatesSyncInterval.errors
                                            ?.incrementBy.increments
                                      }
                              }}
                            </p>
                          </div>
                        </div>
                      </label>
                    </div>
                  </accordion-group>
                  <accordion-group
                    *ngIf="isRouteReporterAccount"
                    #reporterGroup
                  >
                    <div accordion-heading>
                      <span
                        [innerHtml]="
                          'views.configuration-profile-detail.Compliance'
                            | translate
                        "
                      ></span>
                      <i
                        *ngIf="
                          !form.controls.outOfRouteThreshold.valid ||
                          form.errors?.oorThreshold
                        "
                        class="glyphicon glyphicon-exclamation-sign error-glyph"
                      ></i>
                      <i
                        class="pull-right glyphicon"
                        [ngClass]="{
                          'glyphicon-triangle-bottom': reporterGroup?.isOpen,
                          'glyphicon-triangle-right': !reporterGroup?.isOpen
                        }"
                      ></i>
                    </div>
                    <div class="row">
                      <label
                        class="col-md-4"
                        [ngClass]="{
                          'has-error':
                            !form.controls.outOfRouteThreshold.valid ||
                            form.errors?.oorThreshold
                        }"
                      >
                        <span
                          [innerHtml]="
                            (form.value.distanceUnits === 'Miles'
                              ? 'views.configuration-profile-detail.form.OorThreshold.Miles'
                              : 'views.configuration-profile-detail.form.OorThreshold.Kilometers'
                            ) | translate
                          "
                        >
                          <i
                            class="glyphicon glyphicon-question-sign"
                            data-container="body"
                            [tooltip]="
                              'views.configuration-profile-detail.tooltip.OorThreshold'
                                | translate
                            "
                          ></i
                        ></span>
                        <input
                          type="number"
                          [min]="minOor"
                          [max]="maxOor"
                          [step]="stepOor"
                          class="form-control input-sm"
                          formControlName="outOfRouteThreshold"
                        />
                        <i
                          *ngIf="showUnitConversionTooltip"
                          style="margin-left: 335px; margin-top: 20px"
                          class="glyphicon glyphicon-info-sign"
                          data-container="body"
                          [tooltip]="showOORConversionInfo()"
                        ></i>
                        <div
                          *ngIf="
                            !form.controls.outOfRouteThreshold.valid ||
                            form.errors?.oorThreshold
                          "
                          class="
                            glyphicon glyphicon-exclamation-sign
                            error-glyph error-glyph-2x
                          "
                        >
                          <div class="error-tooltip">
                            <p
                              *ngIf="
                                form.controls.outOfRouteThreshold.errors
                                  ?.required
                              "
                            >
                              {{ "common.FieldIsRequired" | translate }}
                            </p>
                            <p
                              *ngIf="
                                form.controls.outOfRouteThreshold.errors
                                  ?.maxDecimalPlaces
                              "
                            >
                              {{
                                "common.MaxDecimalPlaces"
                                  | translate
                                    : {
                                        decimalPlaces:
                                          form.controls.outOfRouteThreshold
                                            .errors.maxDecimalPlaces.max
                                      }
                              }}
                            </p>
                            <p *ngIf="form.errors?.oorThreshold">
                              {{
                                "common.FallsBetween"
                                  | translate
                                    : {
                                        min: form.errors.oorThreshold.min,
                                        max: form.errors.oorThreshold.max
                                      }
                              }}
                            </p>
                          </div>
                        </div>
                      </label>
                    </div>
                  </accordion-group>
                  <accordion-group #accessibilityGroup>
                    <div accordion-heading>
                      <span
                        [innerHtml]="
                          'views.configuration-profile-detail.DriverAccessibility'
                            | translate
                        "
                      ></span>
                      <i
                        class="pull-right glyphicon"
                        [ngClass]="{
                          'glyphicon-triangle-bottom':
                            accessibilityGroup?.isOpen,
                          'glyphicon-triangle-right':
                            !accessibilityGroup?.isOpen
                        }"
                      ></i>
                    </div>
                    <div class="row">
                      <div
                        [ngClass]="{
                          'col-md-3': showPreventAutoRoute,
                          'col-md-4': !showPreventAutoRoute
                        }"
                      >
                        <input
                          type="checkbox"
                          formControlName="allowRoutingProfileEditing"
                        />
                        <label>
                          <span
                            >{{
                              "views.configuration-profile-detail.form.AllowCreationEditing"
                                | translate
                            }}
                            <i
                              class="glyphicon glyphicon-question-sign"
                              data-container="body"
                              [tooltip]="
                                'views.configuration-profile-detail.tooltip.AllowCreationEditing'
                                  | translate
                              "
                            ></i
                          ></span>
                        </label>
                      </div>
                      <div
                        [ngClass]="{
                          'col-md-3': showPreventAutoRoute,
                          'col-md-4': !showPreventAutoRoute
                        }"
                      >
                        <input
                          type="checkbox"
                          formControlName="forceRoutingProfileSelection"
                        />
                        <label>
                          <span
                            >{{
                              "views.configuration-profile-detail.form.ForceRoutingProfileSelection"
                                | translate
                            }}
                            <i
                              class="glyphicon glyphicon-question-sign"
                              data-container="body"
                              [tooltip]="
                                'views.configuration-profile-detail.tooltip.ForceRoutingProfileSelection'
                                  | translate
                              "
                            ></i
                          ></span>
                        </label>
                      </div>
                      <div
                        [ngClass]="{
                          'col-md-3': showPreventAutoRoute,
                          'col-md-4': !showPreventAutoRoute
                        }"
                      >
                        <input
                          type="checkbox"
                          formControlName="displayMapReporting"
                        />
                        <label>
                          <span
                            >{{
                              "views.configuration-profile-detail.form.DisplayMapReporting"
                                | translate
                            }}
                            <i
                              class="glyphicon glyphicon-question-sign"
                              data-container="body"
                              [tooltip]="
                                'views.configuration-profile-detail.tooltip.DisplayMapReporting'
                                  | translate
                              "
                            ></i
                          ></span>
                        </label>
                      </div>
                      <label
                        *ngIf="showPreventAutoRoute"
                        class="col-md-3 col-sm-3 col-xs-3"
                      >
                        <span>{{
                          "views.configuration-profile-detail.form.PreventAutoRoute"
                            | translate
                        }}</span>
                        <select
                          class="form-control input-sm"
                          formControlName="preventAutoRoute"
                        >
                          <option value="null">
                            {{
                              "views.configuration-profile-detail.form.ParCoPilotDefault"
                                | translate
                            }}
                          </option>
                          <option value="true">
                            {{
                              "views.configuration-profile-detail.form.ParEnabled"
                                | translate
                            }}
                          </option>
                          <option value="false">
                            {{
                              "views.configuration-profile-detail.form.ParDisabled"
                                | translate
                            }}
                          </option>
                        </select>
                      </label>
                    </div>
                  </accordion-group>
                </accordion>
              </div>
            </tab>
            <tab
              heading="{{
                'views.configuration-profile-detail.tab.Assignments' | translate
              }}"
            >
              <group-associations
                #vehicleAssociations
                entityLabel="Vehicle groups"
                [displayPerPage]="10"
              >
              </group-associations>
            </tab>
          </tabset>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default cancel-btn" (click)="cancel()">
            <span [innerHtml]="'common.Cancel' | translate"></span>
          </button>
          <button
            type="submit"
            class="btn btn-success"
            [disabled]="!form.valid"
          >
            <i
              *ngIf="!isInitializing && !form.valid"
              class="glyphicon glyphicon-exclamation-sign error-glyph"
            ></i>
            <span [innerHtml]="'common.Save' | translate"></span>
          </button>
        </div>
      </div>
    </div>
  </form>
</fieldset>

<confirmation-modal
  #setDefaultConfirmModal
  title="{{ 'views.configuration-profile-list.SetDefaultTitle' | translate }}"
  body="{{ 'views.configuration-profile-list.ConfirmDefaultBody' | translate }}"
></confirmation-modal>
<confirmation-modal
  #confirmModal
  title="{{ 'views.unsaved-changes-modal.title' | translate }}"
  body="{{ 'views.unsaved-changes-modal.body' | translate }}"
></confirmation-modal>
<confirmation-modal
  #changeDefaultModal
  title="{{
    'views.configuration-profile-detail.warning.ChangeDefaultTitle' | translate
  }}"
  body="{{
    'views.configuration-profile-detail.warning.ChangeDefaultBody'
      | translate: { name: defaultProfile?.name }
  }}"
></confirmation-modal>
