<div class="alk-menu-bar slant" *ngIf="user | async">
  <!-- Logo -->
  <span
    class="main-logo"
    (click)="navigateTo('/vehicle-groups'); currentState('app')"
  >
    <div>
      <img
        id="solera-nav-logo"
        src="/assets/img/solera-nav-logo.svg"
        alt=""
      />
    </div>
  </span>
  <!-- Main Section -->
  <h1 *ngIf="isTermsPage"
    class="nav-title ng-scope"
    [hidden]="isNavVisible"
    [innerHtml]="'views.terms.Title' | translate"
  ></h1>
  <h1 *ngIf="isAzure"
    class="nav-title ng-scope"
    [hidden]="isNavVisible"
    [innerHtml]="'views.azure.Title' | translate"
  ></h1>
  <div class="nav-section" [hidden]="!isNavVisible">
    <ul [hidden]="!isInApp">
      <li
        class="large-btn"
        [hidden]="!this.userContext.isConfigurationProfileEnabled()"
      >
        <button
          type="button"
          (click)="navigateTo('/configuration-profiles')"
          [routerLinkActive]="['selected']"
        >
          <a [routerLink]="['/configuration-profiles']" [hidden]="true"></a>
          <i class="header-icon-large configuration-managment-icon"></i>
          <span
            [innerHtml]="'views.header.ConfigurationManagement' | translate"
          ></span>
        </button>
      </li>
      <li class="large-btn">
        <button
          type="button"
          (click)="navigateTo('/avoidFavorSets')"
          [routerLinkActive]="['selected']"
        >
          <a [routerLink]="['/avoidFavorSets']" [hidden]="true"></a>
          <i class="header-icon-large route-modifier-icon"></i>
          <span [innerHtml]="'views.header.RouteModifiers' | translate"></span>
        </button>
      </li>
      <li class="large-btn">
        <button
          type="button"
          (click)="navigateTo('/customPlaceSets')"
          [routerLinkActive]="['selected']"
        >
          <a [routerLink]="['/customPlaceSets']" [hidden]="true"></a>
          <i class="header-icon-large custom-places-icon"></i>
          <span [innerHtml]="'views.header.CustomPlaceSets' | translate"></span>
        </button>
      </li>
      <li
        class="large-btn longtext-btn"
        [hidden]="!this.userContext.isRoutingProfileEnabled()"
      >
        <button
          type="button"
          (click)="navigateTo('/routing-profiles')"
          [routerLinkActive]="['selected']"
        >
          <a [routerLink]="['/routing-profiles']" [hidden]="true"></a>
          <i class="header-icon-large routing-profiles-icon"></i>
          <span [innerHtml]="'views.header.RoutingProfiles' | translate"></span>
        </button>
      </li>
      <li
        class="large-btn longtext-btn"
        [hidden]="!this.userContext.isSpeedCoachingEnabled()"
      >
        <button
          type="button"
          (click)="navigateTo('/speed-coaching')"
          [routerLinkActive]="['selected']"
        >
          <a [routerLink]="['/speed-coaching']" [hidden]="true"></a>
          <i class="header-icon-large speed-coaching-icon"></i>
          <span
            [innerHtml]="'views.header.SpeedCoachingProfiles' | translate"
          ></span>
        </button>
      </li>
      <li
        class="large-btn longtext-btn"
        [hidden]="!this.userContext.isRoadSpeedsEnabled()"
      >
        <button
          type="button"
          (click)="navigateTo('/road-speeds')"
          [routerLinkActive]="['selected']"
        >
          <a [routerLink]="['/road-speeds']" [hidden]="true"></a>
          <i class="header-icon-large road-speeds-icon ico ico-meter"></i>
          <span [innerHtml]="'views.header.RoadSpeeds' | translate"></span>
        </button>
      </li>
      <li class="large-btn" *ngIf="this.userContext.isMapSetEnabled() && isInPrimaryAccount()">
        <button
          type="button"
          (click)="navigateTo('/mapsets-list')"
          [routerLinkActive]="['selected']"
        >
          <a [routerLink]="['/mapsets-list']" [hidden]="false"></a>
          <i class="header-icon-large mapsets-icon"></i>
          <span [innerHtml]="'views.header.Mapsets' | translate"></span>
        </button>
      </li>
    </ul>
  </div>
  <div class="nav-section" [hidden]="!isNavVisible">
    <ul [hidden]="!isInApp">
      <li class="large-btn" *ngIf="vehicleGroupsVisible">
        <button
          type="button"
          (click)="navigateTo('/vehicle-groups')"
          [routerLinkActive]="['selected']"
        >
          <a [routerLink]="['/vehicle-groups']" [hidden]="true"></a>
          <i class="header-icon-large device-groups-icon ico ico-truck"></i>
          <span [innerHtml]="'views.header.VehicleGroups' | translate"></span>
        </button>
      </li>
      <li class="large-btn" *ngIf="driverGroupsVisible">
        <button
          type="button"
          (click)="navigateTo('/driver-groups')"
          [routerLinkActive]="['selected']"
        >
          <a [routerLink]="['/driver-groups']" [hidden]="true"></a>
          <i class="header-icon-large driver-groups-icon"></i>
          <span [innerHtml]="'views.header.DriverGroups' | translate"></span>
        </button>
      </li>
    </ul>
  </div>

  <div class="nav-section right" [hidden]="isAcceptTerms">
    <alk-cloud></alk-cloud>
  </div>

  <div class="nav-section right" [hidden]="!isNavVisible" style="border-left: 0">
    <ul>
      <!-- Show only for Company Admin and Partner accounts -->
      <li
        class="small-btn"
        [hidden]="!isInApp"
        *ngIf="
          userContext.hasRole([
            'ALK Administrator',
            'Company Administrator',
            'ALK Support',
            'Support'
          ])
        "
      >
        <button
          type="button"
          (click)="navigateTo('/admin')"
          [routerLinkActive]="['selected']"
        >
          <a [routerLink]="['/admin']" [hidden]="true"></a>
          <i class="header-icon-small company-administration-icon"></i>
          <span [innerHtml]="'views.header.CompanyAdmin' | translate"></span>
        </button>
      </li>

      <li class="small-btn">
        <a
          href="https://kb.omnitracs.com/Omnitracs_One/CoPilot/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <button class="nav-button">
            <i class="header-icon-small help-icon"></i>
            <span [innerHtml]="'views.header.Help' | translate"></span>
          </button>
        </a>
      </li>
    </ul>
  </div>

  <!-- Company Section -->
  <div
    class="nav-section right"
    [hidden]="
      !this.userContext.isPartnerAccountUser() ||
      !isInApp ||
      !isNavVisible ||
      !isVehicleViewEnabled
    "
  >
    <ul>
      <li class="large-btn">
        <button
          type="button"
          (click)="navigateTo('/partnerAccounts'); currentState('dm')"
          [routerLinkActive]="['selected']"
        >
          <a [routerLink]="['/partnerAccounts']"></a>
          <i class="header-icon-large partner-management-icon"></i>
          <span
            [innerHtml]="'views.header.deviceManageWrap' | translate"
          ></span>
        </button>
      </li>
    </ul>
  </div>
  <div
    class="nav-section right"
    [hidden]="
      !this.userContext.isPartnerAccountUser() || isInApp || !isNavVisible
    "
  >
    <ul>
      <li class="large-btn">
        <button
          type="button"
          (click)="navigateTo('/vehicle-groups'); currentState('app')"
          [routerLinkActive]="['selected']"
        >
          <a [routerLink]="['/vehicle-groups']" [hidden]="true"></a>
          <i class="header-icon-large device-groups-icon ico ico-gears"></i>
          <span [innerHtml]="'views.header.backtoMain' | translate"></span>
        </button>
      </li>
    </ul>
  </div>

  <div
    class="nav-section right"
    *ngIf="
      userContext.hasRole([
        'ALK Administrator',
        'Company Administrator',
        'ALK Support',
        'Support'
      ])
    "
  >
    <ul class="partner-block">
      <!-- Show only for Partner accounts -->
      <li
        [hidden]="!isInApp || !isNavVisible"
        *ngIf="userContext.isPartnerAccountUser()"
      >
        <partner-account-selector></partner-account-selector>
      </li>
    </ul>
  </div>
</div>
