import { FormControl } from '@angular/forms';

// tslint:disable-next-line: max-line-length
const EMAIL_REGEX = /^([\w!#$%&"*+\/=?^_`{|}~.-]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

export function alkValidEmailAddress(control: FormControl): { [s: string]: boolean } {
  return EMAIL_REGEX.test(control.value) ? null : { alkValidEmailAddress: true };
}

export function isEmail(control: FormControl): { [s: string]: boolean } {
  if (!control.value) {
    return null;
  }

  if (!control.value.match(EMAIL_REGEX)) {
    return { alkEmail: true };
  }

  return null;
}
