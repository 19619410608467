
import {forkJoin as observableForkJoin, from as observableFrom,  Observable ,  Subscription } from 'rxjs';

import {pluck} from 'rxjs/operators';
// angular components
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

// alk app components
import { VehicleGroupService, CustomPlaceService } from './../../vehicle-group/shared';

import { ConfirmationModal } from './../../shared';
import { GroupAssociationsComponent } from './../../shared/group-associations';


@Component({
  providers: [
    VehicleGroupService,
    CustomPlaceService
  ],
  templateUrl: './custom-place-set-detail.component.html'
})
// tslint:disable-next-line: component-class-suffix
export class CustomPlaceSetDetail implements OnInit, OnDestroy {

  public isProcessing = false;
  public isInitializing = true;
  public submitted = false;
  public message = '';

  public addedVehicleGroups: Array<number> = [];
  public removedVehicleGroups: Array<number> = [];

  public errorMessage: Observable<string>;
  public successMessage: Observable<string>;

  public setId: number;
  public form: FormGroup;

  @ViewChild('confirmModal', { static: true }) confirmModal: ConfirmationModal;
  @ViewChild('vehicleGroupAssociations', { static: true }) vehicleGroupAssociations: GroupAssociationsComponent;

  private sub1: Subscription;

  constructor(
    private vehicleGroupService: VehicleGroupService,
    private customPlaceService: CustomPlaceService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private translate: TranslateService,
  ) {
    this.form = this.fb.group({
      name: ['', Validators.compose([Validators.required, Validators.maxLength(500)])]

    });
  }

  public canDeactivate(): Observable<boolean> | boolean {
    if (!this.form.dirty &&
      this.vehicleGroupAssociations.itemsPendingAddition.length === 0 &&
      this.vehicleGroupAssociations.itemsPendingRemoval.length === 0) { return true; }

    return observableFrom(this.confirmModal.open());
  }

  public ngOnDestroy() {
    this.sub1.unsubscribe();
  }

  public ngOnInit() {

    this.vehicleGroupAssociations.columnDefinitions = [
      { title: 'Name', isFilterable: true }
    ];

    this.sub1 = this.route.params.pipe(pluck('id')).subscribe(id => {

      // Load details for the CP Set
      this.loadExisting(+id);

    });
  }

  // This form has just one form control
  public loadForm(attributes) {
    const control = this.form.controls.name as FormControl;
    control.setValue(attributes.items.name);
  }

  public loadExisting(id) {
    // Constants for Indexing of ForkJoin Results
    const CUSTOM_PLACE_SET_DETAILS_INDEX = 0;
    const VEHICLE_GROUP_INDEX = 1;
    const CURRENT_VEHICLE_GROUPS_INDEX = 2;
    this.setId = id;

    this.isInitializing = true;
    this.isProcessing = true;

    const getCustomPlaceSetObs = this.customPlaceService.getCustomPlaceSetById(+id);
    const getAllVehicleGroupsObs = this.vehicleGroupService.getGroups();
    const getCurrentVehicleGroupObs = this.vehicleGroupService.getGroupsByCPSetId(+id);

    observableForkJoin([
      getCustomPlaceSetObs,
      getAllVehicleGroupsObs,
      getCurrentVehicleGroupObs
    ]).subscribe((results: any[]) => {

      this.loadForm(results[CUSTOM_PLACE_SET_DETAILS_INDEX]);

      this.vehicleGroupAssociations.allPossibleItems = results[VEHICLE_GROUP_INDEX].map(item => {
        return {
          id: item.id,
          fields: [item.name],
          pendingRemoval: false,
          pendingAddition: false
        };
      });

      this.vehicleGroupAssociations.initialItems = results[CURRENT_VEHICLE_GROUPS_INDEX].map(item => {
        return {
          id: item.id,
          fields: [item.name],
          pendingRemoval: false,
          pendingAddition: false
        };
      });

      this.isProcessing = false;
      this.isInitializing = false;
    }, error => {
      this.isProcessing = false;
      this.isInitializing = false;
    });
  }

  public saveVehicleGroupAssociations(): Promise<any> {
    let promise: Promise<any>;
    promise = this.customPlaceService.associateVehicleGroups(
      this.setId,
      this.vehicleGroupAssociations.itemsPendingAddition.map(r => r.id),
      this.vehicleGroupAssociations.itemsPendingRemoval.map(r => r.id)
    ).toPromise().then(() => {
      this.vehicleGroupAssociations.initialItems = this.vehicleGroupAssociations.resultItems;
      this.vehicleGroupAssociations.resetInitialData();
    });

    return promise;
  }
  public save() {
    this.submitted = true;

    this.errorMessage = null;
    this.successMessage = null;

    if (!this.form.valid) {
      return;
    }
    this.saveVehicleGroupAssociations()
      .then(() => {
        this.successMessage = this.translate.get('views.custom-placeSet-detail.SaveSuccess');
        this.isProcessing = false;

        // once the page is successfully saved, mark this form as pristine/untouched.
        this.form.markAsPristine();

      })
      .catch(error => {
        if (error.code === -3) {
          this.errorMessage = this.translate.get('views.custom-placeSet-detail.SaveFailed');
        }

        this.isProcessing = false;
      });

    this.isProcessing = true;
  }

  public cancel() {
    this.router.navigate(['/customPlaceSets']);
  }

  public onItemSelected(itemSelected) {
    this.router.navigate(['/vehicle-groups', itemSelected.id]);
  }
}
