/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../alk-components/alk-copyright/alk-copyright.component.ngfactory";
import * as i2 from "../alk-components/alk-copyright/alk-copyright.component";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "./shared/header/header.component.ngfactory";
import * as i6 from "./shared/header/header.component";
import * as i7 from "../alk-components/authentication/user-context.service";
import * as i8 from "./admin/data-retention-policy-modal/data-retention-policy-modal.component.ngfactory";
import * as i9 from "./admin/data-retention-policy-modal/data-retention-policy-modal.component";
import * as i10 from "angulartics2/ga";
import * as i11 from "angulartics2";
import * as i12 from "../alk-components/account-management/account-management.service";
import * as i13 from "@angular/common/http";
import * as i14 from "./app.component";
import * as i15 from "../alk-components/alk-translations/alk-language-context";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "footer", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "pull-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "alk-copyright", [], null, null, null, i1.View_ALKCopyright_0, i1.RenderType_ALKCopyright)), i0.ɵdid(3, 49152, null, 0, i2.ALKCopyright, [], null, null), (_l()(), i0.ɵted(-1, null, [" | "])), (_l()(), i0.ɵeld(5, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(6, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(7, 1), (_l()(), i0.ɵted(-1, null, ["Terms of Use"])), (_l()(), i0.ɵted(-1, null, [" | "])), (_l()(), i0.ɵeld(10, 0, null, null, 1, "a", [["href", "https://kb.omnitracs.com/Omnitracs_One/CoPilot/"], ["rel", "noopener noreferrer"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Contact Support"])), (_l()(), i0.ɵeld(12, 0, null, null, 2, "div", [["class", "pull-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "div", [["class", "pull-right"], ["id", "user-info"]], null, null, null, null, null)), (_l()(), i0.ɵted(14, null, [" ", " (", "), ", " "]))], function (_ck, _v) { var currVal_2 = _ck(_v, 7, 0, "/terms"); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 6).target; var currVal_1 = i0.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_3 = _co.user.userName; var currVal_4 = _co.user.roles; var currVal_5 = (_co.user.currentPartnerAccountName ? _co.user.currentPartnerAccountName : ""); _ck(_v, 14, 0, currVal_3, currVal_4, currVal_5); }); }
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { dataRetentionPolicyModal: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "app-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-header", [], null, null, null, i5.View_AppHeader_0, i5.RenderType_AppHeader)), i0.ɵdid(3, 114688, null, 0, i6.AppHeader, [i7.UserContextService, i3.Router], null, null), (_l()(), i0.ɵeld(4, 0, null, null, 5, "div", [], null, null, null, null, null)), i0.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(6, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(7, { "content-container": 0 }), (_l()(), i0.ɵeld(8, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(9, 212992, null, 0, i3.RouterOutlet, [i3.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i0.ɵdid(11, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(12, 0, null, null, 1, "data-retention-policy-modal", [], null, null, null, i8.View_DataRetentionPolicyModal_0, i8.RenderType_DataRetentionPolicyModal)), i0.ɵdid(13, 49152, [[1, 4], ["dataRetentionPolicyModal", 4]], 0, i9.DataRetentionPolicyModal, [i3.Router], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_0 = _ck(_v, 7, 0, (_co.showHeaderFooter !== false)); _ck(_v, 6, 0, currVal_0); _ck(_v, 9, 0); var currVal_1 = _co.showHeaderFooter; _ck(_v, 11, 0, currVal_1); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 3, "app", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵprd(512, null, i10.Angulartics2GoogleAnalytics, i10.Angulartics2GoogleAnalytics, [i11.Angulartics2]), i0.ɵprd(512, null, i12.AccountManagementService, i12.AccountManagementService, [i13.HttpClient, i7.UserContextService]), i0.ɵdid(3, 4243456, null, 0, i14.AppComponent, [i15.ALKLanguageContext, i10.Angulartics2GoogleAnalytics, i3.Router, i0.ViewContainerRef, i7.UserContextService, i12.AccountManagementService], null, null)], null, null); }
var AppComponentNgFactory = i0.ɵccf("app", i14.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
