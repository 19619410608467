
import {throwError as observableThrowError, of as observableOf,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
// angular components
import { Injectable } from '@angular/core';

import * as _ from 'lodash';

// alk common components

// shared components
import { GroupsUtil } from './../../../app/shared/utils/groupsUtil';

// alk app components
import { PagingService } from './../../shared';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';

export interface AvoidFavorSet {
    setID: number;
    name: string;
    isDefault: boolean;
    modifiedOn: number;
}

@Injectable()
export class AvoidFavorService {

  constructor(private http: HttpClient,
              private paging: PagingService) { }

    setIsDefaultChanged(setId: number, isDefault: boolean) {
    const url = environment.apiUrl + '/v1/avoidFavor-sets/' + setId + '/isDefault/' + isDefault;

    return this.http.put(url, '').pipe(
      catchError(this.serverError));
  }

 getAvoidFavorSetsByVehicleId(
   vehicleId: number,
   paging?: any,
   isDefault?: boolean): Observable<{total: number, items: AvoidFavorSet[]}> {
     const params = [];

     if (paging) {
      params.push(this.paging.getPagingQuery(paging));
    }
     if (isDefault != null) { params.push('isDefault=' + isDefault); }

     let url = environment.apiUrl + '/v1/avoidFavor-sets/' + vehicleId;
     url += params.join('&');

     return this.http.get<any>(url, { observe: 'response' }).map(resp => {
        const total = +resp.headers.get('X-Total-Count');
        const items: AvoidFavorSet[] = resp.body;
        return { total, items };
      });
   }

  getAvoidFavorSets(
    groupIds?: number[],
    name?: string,
    paging?: any,
    isDefault?: boolean): Observable<{ total: number, items: AvoidFavorSet[] }> {

    const params = [];

    if (paging) {
      params.push(this.paging.getPagingQuery(paging));
    }

    if (groupIds && groupIds.length > 0) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < groupIds.length; i++) {
        params.push('groupIds=' + groupIds[i]);
      }
    }

    if (isDefault != null) { params.push('isDefault=' + isDefault); }

    let url = environment.apiUrl + '/v1/avoidFavor-sets?';
    url += params.join('&');

    const afSets: any[] = [];
    const maxGroupsToDisplay = 2;

    return this.http.get<any>(url).pipe(
      map(responses => {
        responses.forEach(resp => {
          resp.vehicleGroupsAbbreviated = (resp.vehicleGroups.length > 0) ?
          GroupsUtil.getAbbreviatedGroupsName(resp.vehicleGroups, maxGroupsToDisplay) : '';
          afSets.push(resp);
        });
        return { total: afSets.length, items: afSets };
      }), );
  }

  getAvoidFavorSetById(setId: number) {
    const url = environment.apiUrl + '/v1/avoidFavorSets/' + setId;

    return this.http.get(url).map(resp => {
        return { items: resp };
      });
   }

  public associateVehicleGroups(setId: number, add: Array<number>, remove: Array<number>) {
    if ((!add || add.length === 0) && (!remove || remove.length === 0)) { return observableOf({}); }

    const url = `${environment.apiUrl}/v1/avoidFavor-sets/${setId}/vehicle-groups`;
    const body = JSON.stringify({ add, remove });

    return this.http.put(url, body);
  }

  private serverError(err: any) {
    if (err instanceof HttpResponse) {
      return observableThrowError(`error: ${err.status}` || 'error');
    }
    return observableThrowError(err || 'error');
  }
}
