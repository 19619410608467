import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Observer, of } from 'rxjs';
import { CompanySettingsService } from '../../admin/company-settings';
import { ConfirmationModal } from '../../shared';
import { GroupAssociationsComponent } from '../../shared/group-associations';
import { VehicleGroupService } from '../../vehicle-group';
import { MapsetsService } from '../shared/mapsets.service';
import { Mode } from '../shared/mode.enum';
import { MapRegionsComponent } from './map-regions/map-regions.component';
var MapsetsDetailComponent = /** @class */ (function () {
    function MapsetsDetailComponent(mapsetsService, route, router, translate, vehicleGroupService, companySettingsService) {
        this.mapsetsService = mapsetsService;
        this.route = route;
        this.router = router;
        this.translate = translate;
        this.vehicleGroupService = vehicleGroupService;
        this.companySettingsService = companySettingsService;
        this.allVehicleGroups = [];
        this.mode = Mode.Create;
        this.showSuccessMessage = false;
        this.showHyperlinkError = false;
        this.MAPSET_NAME_CHARACTER_LIMIT = 64;
        this.isSaving = false;
        this.MAX_SELECTED_REGIONS = 5;
        this.init();
    }
    MapsetsDetailComponent.prototype.init = function () {
        var _this = this;
        this.route.data.subscribe(function (x) {
            if (x.justCreated) {
                _this.showSuccessMessage = true;
            }
        });
        this.companySettingsService.getMapSetSettings().subscribe(function (x) {
            _this.mapSetSettings = x;
            _this.MAX_SELECTED_REGIONS = _this.mapSetSettings.maxMapSetAssignments;
        });
        this.route.params.subscribe(function (params) {
            if (!Number.isNaN(+params.id)) {
                _this.mode = Mode.Edit;
                _this.mapsetsService.getMapsetById(+params.id).subscribe(function (x) {
                    _this.mapset = x;
                    _this.vehicleGroupAssociations.initialItems =
                        _this.mapset.vehicleGroups.length > 0
                            ? _this.mapset.vehicleGroups.map(function (g) {
                                return {
                                    id: g.vehicleGroupId,
                                    fields: [g.vehicleGroupName],
                                    pendingRemoval: false,
                                    pendingAddition: false,
                                };
                            })
                            : new Array();
                });
            }
            else {
                _this.mapset = {
                    vehicleGroups: new Array(),
                    mapRegions: new Array(),
                    defaultMapset: false,
                };
            }
        });
        this.route.params.subscribe(function (params) {
            if (!Number.isNaN(+params.id)) {
                _this.mode = Mode.Edit;
                _this.mapsetsService.getMapsetById(+params.id).subscribe(function (x) {
                    _this.mapset = x;
                });
            }
            else {
                _this.mapset = {
                    vehicleGroups: new Array(),
                    mapRegions: new Array(),
                    defaultMapset: false,
                };
            }
        });
    };
    MapsetsDetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.vehicleGroupService.getGroups().subscribe(function (x) {
            x.forEach(function (v) {
                _this.allVehicleGroups.push({
                    vehicleGroupId: v.id,
                    vehicleGroupName: v.name,
                });
            });
            _this.vehicleGroupAssociations.allPossibleItems =
                _this.allVehicleGroups.map(function (v) {
                    return {
                        id: v.vehicleGroupId,
                        fields: [v.vehicleGroupName],
                        pendingRemoval: false,
                        pendingAddition: false,
                    };
                });
        });
        this.vehicleGroupAssociations.columnDefinitions = [
            { title: 'Name', isFilterable: true },
        ];
    };
    Object.defineProperty(MapsetsDetailComponent.prototype, "headerText", {
        get: function () {
            return this.mode === Mode.Create
                ? 'views.mapsets.CreateMapset'
                : 'views.mapsets.EditMapset';
        },
        enumerable: true,
        configurable: true
    });
    MapsetsDetailComponent.prototype.confirmSetAsDefault = function () {
        var _this = this;
        if (!this.mapset.defaultMapset) {
            this.translate
                .get('views.mapsets-details.SetAsDefault')
                .subscribe(function (x) { return (_this.confirmModal.title = x); });
            this.translate
                .get('views.mapsets-details.DefaultConfirmation')
                .subscribe(function (x) { return (_this.confirmModal.body = x); });
        }
        else {
            this.translate
                .get('views.mapsets-details.RemoveAsDefault')
                .subscribe(function (x) {
                _this.confirmModal.title = x;
            });
            this.translate
                .get('views.mapsets-details.RemoveDefaultConfirmation')
                .subscribe(function (x) {
                _this.confirmModal.body = x;
            });
        }
        this.confirmModal.open().then(function (x) {
            _this.mapset.defaultMapset = !_this.mapset.defaultMapset;
        }, function () { });
    };
    MapsetsDetailComponent.prototype.contains = function (arr, id) {
        if (arr.indexOf(id) >= 0) {
            return true;
        }
        return false;
    };
    MapsetsDetailComponent.prototype.save = function () {
        var e_1, _a;
        var _this = this;
        this.errorMsg = null;
        this.showHyperlinkError = false;
        this.isSaving = true;
        var pendingRemoval = new Array();
        var pendingAddition = new Array();
        try {
            for (var _b = tslib_1.__values(this.allVehicleGroups), _c = _b.next(); !_c.done; _c = _b.next()) {
                var group = _c.value;
                if (this.contains(this.vehicleGroupAssociations.initialItems.map(function (x) { return x.id; }), group.vehicleGroupId) &&
                    !this.contains(this.vehicleGroupAssociations.resultItems.map(function (x) { return x.id; }), group.vehicleGroupId)) {
                    pendingRemoval.push(group.vehicleGroupId);
                }
                if (!this.contains(this.vehicleGroupAssociations.initialItems.map(function (x) { return x.id; }), group.vehicleGroupId) &&
                    this.contains(this.vehicleGroupAssociations.resultItems.map(function (x) { return x.id; }), group.vehicleGroupId)) {
                    pendingAddition.push(group.vehicleGroupId);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.mapset.mapRegions = this.regions.buildMapsets();
        if (this.mapset.mapRegions.length < 1) {
            this.errorMsg = 'At least one mapset must be selected';
        }
        else {
            if (this.mode === Mode.Create) {
                // don't navigate back to list view on save to maintain consistency throughout the app
                this.mapset.customMapsetName = this.mapset.customMapsetName.trim();
                this.mapsetsService.createMapset(this.mapset).subscribe(function (created) {
                    if (pendingAddition.length > 0) {
                        _this.mapsetsService
                            .assignVehicleGroupsToMapset(created.data.customMapsetId, pendingAddition)
                            .subscribe(function (x) {
                            _this.router.navigateByUrl("mapsets/created/" + created.data.customMapsetId);
                        }, function (error) {
                            _this.errorMsg = error.error.message;
                            _this.router.navigateByUrl("mapsets/created/" + created.data.customMapsetId);
                        }, function () {
                            _this.resetInitialMapset();
                        });
                    }
                    else {
                        _this.router.navigateByUrl("mapsets/created/" + created.data.customMapsetId);
                    }
                }, function (error) { return (_this.errorMsg = error.error.message); });
            }
            else {
                this.mapsetsService.updateMapset(this.mapset).subscribe(function (x) {
                    var e_2, _a, e_3, _b;
                    var vehicleGroups = _this.vehicleGroupAssociations.initialItems.map(function (vehicle) { return vehicle.id; });
                    if (pendingAddition.length > 0) {
                        try {
                            for (var pendingAddition_1 = tslib_1.__values(pendingAddition), pendingAddition_1_1 = pendingAddition_1.next(); !pendingAddition_1_1.done; pendingAddition_1_1 = pendingAddition_1.next()) {
                                var pendingVehicle = pendingAddition_1_1.value;
                                vehicleGroups.push(pendingVehicle);
                            }
                        }
                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                        finally {
                            try {
                                if (pendingAddition_1_1 && !pendingAddition_1_1.done && (_a = pendingAddition_1.return)) _a.call(pendingAddition_1);
                            }
                            finally { if (e_2) throw e_2.error; }
                        }
                    }
                    if (pendingRemoval.length > 0) {
                        var _loop_1 = function (pendingVehicle) {
                            vehicleGroups = vehicleGroups.filter(function (vehicle) { return vehicle !== pendingVehicle; });
                        };
                        try {
                            for (var pendingRemoval_1 = tslib_1.__values(pendingRemoval), pendingRemoval_1_1 = pendingRemoval_1.next(); !pendingRemoval_1_1.done; pendingRemoval_1_1 = pendingRemoval_1.next()) {
                                var pendingVehicle = pendingRemoval_1_1.value;
                                _loop_1(pendingVehicle);
                            }
                        }
                        catch (e_3_1) { e_3 = { error: e_3_1 }; }
                        finally {
                            try {
                                if (pendingRemoval_1_1 && !pendingRemoval_1_1.done && (_b = pendingRemoval_1.return)) _b.call(pendingRemoval_1);
                            }
                            finally { if (e_3) throw e_3.error; }
                        }
                    }
                    _this.mapsetsService.assignVehicleGroupsToMapset(x.data.customMapsetId, vehicleGroups).subscribe(function (y) { }, function (error) {
                        var errorCode = error && error.error && error.error.error && error.error.error.errorCode
                            ? error.error.error.errorCode : -1;
                        switch (errorCode) {
                            case -7011:
                                _this.hyperlinkErrorType = _this.translate.get('views.vehicle-group-detail.error.TooManyMapsetsVehicle');
                                _this.showHyperlinkError = true;
                                break;
                            case -7022:
                                _this.hyperlinkErrorType = _this.translate.get('views.vehicle-group-detail.error.TooManyMapsetsVehicleGroup');
                                _this.showHyperlinkError = true;
                                break;
                            default:
                                _this.errorMsg = error.error.message;
                        }
                    }, function () {
                        _this.resetInitialMapset();
                    });
                    _this.showSuccessMessage = true;
                    _this.isSaving = false;
                    // put mapset object to update mapset api
                }, function (error) {
                    var errorCode = error && error.error && error.error.errorCode ? error.error.errorCode : -1;
                    switch (errorCode) {
                        case -7011:
                            _this.hyperlinkErrorType = _this.translate.get('views.vehicle-group-detail.error.TooManyMapsetsVehicle');
                            _this.showHyperlinkError = true;
                            break;
                        case -7022:
                            _this.hyperlinkErrorType = _this.translate.get('views.vehicle-group-detail.error.TooManyMapsetsVehicleGroup');
                            _this.showHyperlinkError = true;
                            break;
                        default:
                            _this.errorMsg = error.error.message;
                    }
                    _this.isSaving = false;
                });
            }
        }
    };
    MapsetsDetailComponent.prototype.resetInitialMapset = function () {
        this.regions.updateInitialMapset(); // set initial mapset to what the mapset is currently for saving validation
        this.vehicleGroupAssociations.initialItems =
            this.vehicleGroupAssociations.resultItems;
    };
    MapsetsDetailComponent.prototype.canDeactivate = function () {
        var _this = this;
        if (!this.isSaving && this.regions.mapsetHasChanged()) {
            return new Observable(function (observer) {
                var dialog = _this.confirmLeave.open();
                dialog.then(function (x) {
                    observer.next(x);
                    observer.complete();
                });
            });
        }
        return of(true);
    };
    return MapsetsDetailComponent;
}());
export { MapsetsDetailComponent };
