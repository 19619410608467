import { Component } from '@angular/core';

@Component({
  selector: 'azure',
  templateUrl: './azure.component.html',
})
export class AzureComponent {

  constructor() { }
}
