/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core/src/translate.pipe";
import * as i2 from "@ngx-translate/core/src/translate.service";
import * as i3 from "@angular/common";
import * as i4 from "ngx-bootstrap/dropdown/bs-dropdown.state";
import * as i5 from "ngx-bootstrap/dropdown/bs-dropdown.directive";
import * as i6 from "ngx-bootstrap/component-loader/component-loader.factory";
import * as i7 from "ngx-bootstrap/dropdown/bs-dropdown.config";
import * as i8 from "ngx-bootstrap/dropdown/bs-dropdown-toggle.directive";
import * as i9 from "./alk-cloud.service";
import * as i10 from "@angular/common/http";
import * as i11 from "./alk-cloud.component";
import * as i12 from "../../../alk-components/authentication/user-context.service";
import * as i13 from "@angular/router";
var styles_AlkCloudMenu = [];
var RenderType_AlkCloudMenu = i0.ɵcrt({ encapsulation: 2, styles: styles_AlkCloudMenu, data: {} });
export { RenderType_AlkCloudMenu as RenderType_AlkCloudMenu };
function View_AlkCloudMenu_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToAppPage(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "img", [["class", "pull-left"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), i0.ɵpid(131072, i1.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "cloud-menu-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "cloud-menu-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.productImgSrc; var currVal_1 = i0.ɵunv(_v, 2, 1, i0.ɵnov(_v, 3).transform(_v.parent.context.$implicit.productTitle)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform(_v.parent.context.$implicit.productTitle)); _ck(_v, 5, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform(_v.parent.context.$implicit.productText)); _ck(_v, 8, 0, currVal_3); }); }
function View_AlkCloudMenu_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "li", [["class", "cursor-pointer list-group-item cloud-menu-item"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlkCloudMenu_2)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.productLicensed; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AlkCloudMenu_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h5", [["class", "legend"]], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵpid(131072, i1.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 0, 0, i0.ɵnov(_v, 1).transform("common.nav.UnlicensedApps")); _ck(_v, 0, 0, currVal_0); }); }
function View_AlkCloudMenu_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "row cloud-menu-unlicensed-alert"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", "cloud-menu-unlicensed-label"]], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵpid(131072, i1.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 1, "a", [["class", "btn btn-default btn-xs pull-right cloud-menu-unlicensed-btn"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToInfoPage(_v.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵpid(131072, i1.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("common.nav.NotLicensed")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform("common.nav.LearnMore")); _ck(_v, 4, 0, currVal_1); }); }
function View_AlkCloudMenu_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "img", [["class", "pull-left"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), i0.ɵpid(131072, i1.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "cloud-menu-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "cloud-menu-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlkCloudMenu_7)), i0.ɵdid(11, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_4 = _v.parent.context.$implicit.showUnlicensedMessage; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.productImgSrc; var currVal_1 = i0.ɵunv(_v, 2, 1, i0.ɵnov(_v, 3).transform(_v.parent.context.$implicit.productTitle)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform(_v.parent.context.$implicit.productTitle)); _ck(_v, 5, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform(_v.parent.context.$implicit.productText)); _ck(_v, 8, 0, currVal_3); }); }
function View_AlkCloudMenu_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "li", [["class", "list-group-item cloud-menu-item cursor-pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleUnlicensedProductInfo(_v.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlkCloudMenu_6)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = !_v.context.$implicit.productLicensed; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AlkCloudMenu_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ul", [["class", "list-group cloud-menu"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlkCloudMenu_5)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.unlicensedApps; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AlkCloudMenu_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h5", [["class", "legend"]], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵpid(131072, i1.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 0, 0, i0.ɵnov(_v, 1).transform("common.nav.ExpiredApps")); _ck(_v, 0, 0, currVal_0); }); }
function View_AlkCloudMenu_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "row cloud-menu-unlicensed-alert"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", "cloud-menu-unlicensed-label"]], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵpid(131072, i1.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 1, "a", [["class", "btn btn-default btn-xs pull-right cloud-menu-unlicensed-btn"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToInfoPage(_v.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵpid(131072, i1.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("common.nav.NotLicensed")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform("common.nav.LearnMore")); _ck(_v, 4, 0, currVal_1); }); }
function View_AlkCloudMenu_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "img", [["class", "pull-left"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), i0.ɵpid(131072, i1.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "cloud-menu-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "cloud-menu-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlkCloudMenu_12)), i0.ɵdid(11, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_4 = _v.parent.context.$implicit.showUnlicensedMessage; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.productImgSrc; var currVal_1 = i0.ɵunv(_v, 2, 1, i0.ɵnov(_v, 3).transform(_v.parent.context.$implicit.productTitle)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform(_v.parent.context.$implicit.productTitle)); _ck(_v, 5, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform(_v.parent.context.$implicit.productText)); _ck(_v, 8, 0, currVal_3); }); }
function View_AlkCloudMenu_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "li", [["class", "list-group-item cloud-menu-item cursor-pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleUnlicensedProductInfo(_v.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlkCloudMenu_11)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.productExpired; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AlkCloudMenu_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ul", [["class", "list-group cloud-menu"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlkCloudMenu_10)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.expiredApps; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AlkCloudMenu_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 27, "ul", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 16777216, null, null, 26, "li", [["class", "large-btn cloud-button dropdown"], ["dropdown", ""]], [[2, "dropup", null], [2, "open", null], [2, "show", null]], null, null, null, null)), i0.ɵprd(512, null, i4.BsDropdownState, i4.BsDropdownState, []), i0.ɵdid(3, 212992, null, 0, i5.BsDropdownDirective, [i0.ElementRef, i0.Renderer2, i0.ViewContainerRef, i6.ComponentLoaderFactory, i7.BsDropdownConfig, i4.BsDropdownState], { isOpen: [0, "isOpen"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 5, "button", [["alk-analytics", "['ALK Apps']"], ["class", "nav-button dropdown-toggle"], ["dropdownToggle", ""], ["id", "locations-btn"]], [[1, "aria-haspopup", 0], [1, "disabled", 0], [1, "aria-expanded", 0]], [[null, "click"], ["document", "click"], [null, "keyup.esc"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("document:click" === en)) {
        var pd_1 = (i0.ɵnov(_v, 5).onDocumentClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("keyup.esc" === en)) {
        var pd_2 = (i0.ɵnov(_v, 5).onEsc() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = ((_co.isOpen = true) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 147456, null, 0, i8.BsDropdownToggleDirective, [i4.BsDropdownState, i0.ElementRef], null, null), (_l()(), i0.ɵeld(6, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵpid(131072, i1.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(9, 0, null, null, 0, "span", [["class", "caret"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 17, "div", [["class", "dropdown-menu dropdown-menu-right popover cloud-panel"], ["role", "menu"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 3, "h5", [["class", "legend"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵpid(131072, i1.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(14, 0, null, null, 0, "span", [["class", "cursor-pointer ico ico-times-circle pull-right"], ["style", "top: -1px;font-size: 16px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.isOpen = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 2, "ul", [["class", "list-group cloud-menu"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlkCloudMenu_1)), i0.ɵdid(17, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlkCloudMenu_3)), i0.ɵdid(19, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlkCloudMenu_4)), i0.ɵdid(21, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlkCloudMenu_8)), i0.ɵdid(23, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlkCloudMenu_9)), i0.ɵdid(25, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(26, 0, null, null, 1, "a", [["class", "btn btn-xs btn-default pull-right cloud-menu-btn cloud-menu-btn-last"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵpid(131072, i1.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.isOpen; _ck(_v, 3, 0, currVal_3); var currVal_10 = _co.licensedApps; _ck(_v, 17, 0, currVal_10); var currVal_11 = (_co.unlicensedApps.length > 0); _ck(_v, 19, 0, currVal_11); var currVal_12 = (_co.unlicensedApps.length > 0); _ck(_v, 21, 0, currVal_12); var currVal_13 = (_co.expiredApps.length > 0); _ck(_v, 23, 0, currVal_13); var currVal_14 = (_co.expiredApps.length > 0); _ck(_v, 25, 0, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 3).dropup; var currVal_1 = i0.ɵnov(_v, 3).isOpen; var currVal_2 = (i0.ɵnov(_v, 3).isOpen && i0.ɵnov(_v, 3).isBs4); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = true; var currVal_5 = i0.ɵnov(_v, 5).isDisabled; var currVal_6 = i0.ɵnov(_v, 5).isOpen; _ck(_v, 4, 0, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.webToolsImgSrc; _ck(_v, 6, 0, currVal_7); var currVal_8 = i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform("common.nav.webTools")); _ck(_v, 7, 0, currVal_8); var currVal_9 = i0.ɵunv(_v, 12, 0, i0.ɵnov(_v, 13).transform("common.nav.LicensedApps")); _ck(_v, 12, 0, currVal_9); var currVal_15 = i0.ɵunv(_v, 26, 0, i0.ɵnov(_v, 27).transform("views.header.Logout")); _ck(_v, 26, 0, currVal_15); }); }
export function View_AlkCloudMenu_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "alk-cloud", [], null, null, null, View_AlkCloudMenu_0, RenderType_AlkCloudMenu)), i0.ɵprd(512, null, i9.AlkCloudService, i9.AlkCloudService, [i10.HttpClient]), i0.ɵdid(2, 114688, null, 0, i11.AlkCloudMenu, [i12.UserContextService, i13.Router, i9.AlkCloudService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var AlkCloudMenuNgFactory = i0.ɵccf("alk-cloud", i11.AlkCloudMenu, View_AlkCloudMenu_Host_0, {}, {}, []);
export { AlkCloudMenuNgFactory as AlkCloudMenuNgFactory };
