
import { Injectable } from '@angular/core';

// alk app components
import { UserContextService } from './../../../alk-components/authentication';
import { convertKMToMile, convertMileToKM } from './../utils/convertUtil';

import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class RoadSpeedsService {

  constructor( private http: HttpClient, private userContext: UserContextService) {}

  public getJurisdictionsList(vehicleType) {
    const url = `${environment.apiUrl}/v1/jurisdictions?vehicleType=` + vehicleType;
    return this.http.get<any>(url);
  }

  public getGlobalSpeeds(vehicleType: string, isDefault: boolean) {
    let url = `${environment.apiUrl}/v1/globalRoadSpeeds?vehicleType=` + vehicleType;

    if (isDefault) {
      url += `&isDefault=` + isDefault;
    }
    return this.http.get(url).map(resp => {
        return this.convertToDisplayUnit(resp);
      });
  }

  public getSpeedsbyJurisdiction(state: string, vehicleType: string, isDefault: boolean) {
    let url = `${environment.apiUrl}/v1/roadSpeeds?vehicleType=` + vehicleType + `&jurisAbbrev=` + state;
    if (isDefault) {
      url += `&isDefault=` + isDefault;
    }
    return this.http.get(url).map(resp => {
        return this.convertToDisplayUnit(resp);
      });
  }

  public  enableGlobalSpeeds(useGlobal: boolean) {
    const url = environment.apiUrl + '/v1/enableGlobalRoadSpeeds/' + useGlobal;

    return this.http.put(url, '');
  }

  public updateSpeeds(vehicle: string, juris: string, state: string, newSpeeds) {
    let url = `${environment.apiUrl}/v1/`;

    if (juris === 'global') {
      url += `globalRoadSpeeds?vehicleType=` + vehicle;
    } else {
      url += `roadSpeeds?vehicleType=` + vehicle + `&jurisAbbrev=` + state;
    }
    const speeds = this.getRoadSpeedsRequestBody(newSpeeds);
    const body = JSON.stringify(speeds);

    return this.http.post(url, body);
  }

  // Reconvert speeds back to mph before persisting the db - speeds were converted to metric only for display purposes
  private  getRoadSpeedsRequestBody(form): Array<object> {
    const reqBody: Array<object> = [];
    Object.keys(form.controls).forEach(key => {
      if (key !== 'speedSelection' && key !== 'vehicleType') {
        const tempObj = {
          roadCategory: key.toString(),
          roadSpeedValue: this.userContext.getUser().unitType === 'Metric' ? convertKMToMile(form.value[key]) : form.value[key]
        };
        reqBody.push(tempObj);
      }
    });
    return reqBody;
  }

  // If the account is metric, show the road speeds in metric
  private convertToDisplayUnit(speeds: any) {
    if (this.userContext.getUser().unitType === 'Metric') {
       speeds.forEach(speed => {
        speed.roadSpeedValue = convertMileToKM(speed.roadSpeedValue);
       });
    }
    return speeds;
  }
}
