<div class="container with-nav terms-page">
  <div class="panel page-panel">

    <div class="row">
      <div class="col-xs-12">
        <terms></terms>

        <div style="text-align: right; padding-bottom: 10px;">
          <form novalidate (ngSubmit)="acceptTerms()">
            <button id="accept-terms" type="submit" class="btn btn-success" [disabled]="isProcessing">{{ 'views.accept-terms.Title' | translate }}</button>
          </form>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</div>
