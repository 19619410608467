<fieldset [disabled]="isProcessing">
  <form [formGroup]="form" (ngSubmit)="save()" novalidate>

    <div class="container">

      <div class="message-panel">
        <div *ngIf="(successMessage | async)" class="alert alert-success alert-dismissible" role="alert">
          <button type="button" class="close" (click)="successMessage=null"><span>&times;</span></button>
          <strong>{{successMessage | async}}</strong>
        </div>
        <div *ngIf="(errorMessage | async)" class="alert alert-danger alert-dismissible" role="alert">
          <button type="button" class="close" (click)="errorMessage=null"><span>&times;</span></button>
          <strong>{{errorMessage | async}}</strong>
        </div>
      </div>

      <div class="panel page-panel">

        <div class="row action-panel">
          <div class="col-xs-4">&nbsp;</div>
          <div class="col-xs-4">
            <h4 class="text-center">
              <span [innerHtml]="'views.driver-group-detail.DriverGroup' | translate"></span>
              <span *ngIf="isNew" class="label label-success">NEW</span>
            </h4>
          </div>
        </div>

        <div class="row header-panel">
          <label class="col-xs-4" [ngClass]="{'has-error': !isInitializing && !form.controls.name.valid }">
            <span [innerHtml]="'views.driver-group-detail.GroupName' | translate"></span>
            <input type="input" formControlName="name" [readonly]="isProcessing || !dgPermissions.editButtonVisible" class="form-control input-sm" autocomplete="off" />
            <div *ngIf="!isInitializing && (!form.controls.name.valid)" class="glyphicon glyphicon-exclamation-sign error-glyph">
              <div class="error-tooltip">
                <p *ngIf="form.controls.name.errors?.required">{{ 'common.FieldIsRequired' | translate }}</p>
                <p *ngIf="form.controls.name.errors?.maxlength">{{ 'common.MustBeLessThanChars' | translate: {maxChars: form.controls.name.errors?.maxlength.requiredLength} }}</p>
              </div>
            </div>
          </label>
          <!-- <div class="pull-right filter-fix">
              <button type="button" class="btn btn-default" *ngIf="isExportEnabled || !isNew">
                 <a class="link-btn" [href]="downloadUrl" download="groups.csv">
                    <span class="glyphicon glyphicon-cloud-upload"></span>
                    <span [innerHtml]="'common.Export' | translate"></span>
                  </a>
              </button>
          </div> -->
          <div class="col-xs-4"></div>
          <div class="col-xs-4 text-right">
            <div  *ngIf = 'isExportEnabled'  class="btn-group gear-button" dropdown>
              <button type="button" class="btn btn-default" *ngIf="!isNew" dropdownToggle>
                <span class="ico ico-cog"></span>
                <!--<span class="caret"></span>-->
              </button>
              <ul role="menu" aria-labelledby="single-button" class="dropdown-menu">
                <li role="menuitem">
                  <a class="" [href]="downloadUrl" download="groups.csv">
                    <span [innerHtml]="'common.Export' | translate"></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="row tab-panel">
          <tabset>
            <tab heading="Drivers">
              <group-associations #driverAssociations entityLabel="{{'views.driver-group-detail.Drivers' | translate}}" [displayPerPage]="10"
                [readonly]="!dgPermissions.editButtonVisible">
              </group-associations>
            </tab>
          </tabset>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-default cancel-btn" (click)="cancel()">
              <span [innerHtml]="'common.Cancel' | translate"></span>
            </button>
              <button type="submit" class="btn btn-success pull-right" [disabled]="isInitializing || !form.valid" *ngIf="dgPermissions.editButtonVisible">
              <i *ngIf="!isInitializing && !form.valid" class="glyphicon glyphicon-exclamation-sign error-glyph"></i>
              <span [innerHtml]="'common.Save' | translate"></span>
            </button>
        </div>
      </div>
    </div>

  </form>
</fieldset>

<confirmation-modal #confirmModal title="{{ 'views.unsaved-changes-modal.title' | translate}}" body="{{ 'views.unsaved-changes-modal.body' | translate}}"></confirmation-modal>
