
import {throwError as observableThrowError, of as observableOf } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
// angular components
import { Injectable } from '@angular/core';

// alk common components

// alk app components
import { PagingService } from './../../shared';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DriverGroupService {

  constructor(private http: HttpClient,
              private paging: PagingService) { }

  public getGroups(
    groupId?: number[],
    driverId?: number[],
    searchTerm?: string
  ) {

    let url = environment.apiUrl + '/v1/driver-groups?';

    const params = [];

    if (searchTerm) { params.push('searchTerm=' + encodeURIComponent(searchTerm)); }

    if (groupId && groupId.length > 0) {
      params.push(this.getArrayQueryParam(groupId, 'groupId'));
    }

    if (driverId && driverId.length > 0) {
      params.push(this.getArrayQueryParam(driverId, 'driverId'));
    }

    url += params.join('&');

    return this.http.get<any>(url);
  }

  public getGroup(groupId: number) {
    const url = environment.apiUrl + '/v1/driver-groups/' + groupId;

    return this.http.get(url);
  }

  public getDriverList(includeGroupIds?: number[]) {
    const baseUrl = environment.apiUrl + '/v1/driverlist';
    const query = includeGroupIds ? `?includeGroupIds=${includeGroupIds.join(',')}` : '';
    const url = baseUrl + query;
    return this.http.get<any>(url).map(items => {
      return { total: items.length, items };
    });
  }

  // NOTE: deprecated due to performance
  public getDrivers(includeGroupId?: number, excludeGroupId?: number, searchTerm?: string, paging?: any) {
    let url = environment.apiUrl + '/v1/drivers?';

    const params = [];
    if (paging) {
      params.push(this.paging.getPagingQuery(paging));
    }

    if (searchTerm) {
      params.push('searchTerm=' + encodeURIComponent(searchTerm));
    }

    if (includeGroupId) {
      params.push('includeGroupId=' + includeGroupId);
    }

    if (excludeGroupId) {
      params.push('excludeGroupId=' + excludeGroupId);
    }

    url += params.join('&');

    return this.http.get(url, { observe: 'response' }).pipe(
      map(resp => {
        const total = +resp.headers.get('X-Total-Count');
        return { total, items: resp.body };
      }));
  }

  public getDriverDetails(driverId: number) {
    const url = `${environment.apiUrl}/v1/drivers/${driverId}`;

    return this.http.get(url);
  }

  public deleteGroup(groupId: number) {
    const url = `${environment.apiUrl}/v1/driver-groups/${groupId}`;
    return this.http.delete(url);
  }

  public associateDrivers(groupId: number, add: Array<number>, remove: Array<number>) {
    if ((!add || add.length === 0) && (!remove || remove.length === 0)) { return observableOf({}); }

    const url = `${environment.apiUrl}/v1/driver-groups/${groupId}/drivers`;
    const body = JSON.stringify({ add, remove });

    return this.http.put(url, body);
  }

  public updateGroupDetails(groupId: number, groupDetails) {
    const url = `${environment.apiUrl}/v1/driver-groups/${groupId}`;
    const body = JSON.stringify(groupDetails);

    return this.http.put(url, body).pipe(
      catchError(error => {
        return observableThrowError(error.json());
      }));
  }

  public createGroupDetails(groupDetails) {
    const url = `${environment.apiUrl}/v1/driver-groups`;
    const body = JSON.stringify(groupDetails);

    return this.http.post(url, body, { observe: 'response' }).pipe(
      map(resp => {
        const modifiedOn = +resp.headers.get('X-LastModified');
        groupDetails.modifiedOn = modifiedOn;

        const location = resp.headers.get('Location');

        const idRegex = /\/v1\/driver-groups\/([0-9].*)/g;
        const matches = idRegex.exec(location);

        if (matches.length > 1) { groupDetails.id = +matches[1]; }

        return groupDetails;

      }), catchError(error => {
        return observableThrowError(error.json());
      }), );
  }

  public getDefaultDriverGroup() {
    return {
      name: ''
    };
  }

  private getArrayQueryParam(arr, propName) {
    const params = [];

    if (arr && arr.length > 0) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < arr.length; i++) {
        params.push(propName + '=' + arr[i]);
      }
    }

    return params.join('&');
  }
}
