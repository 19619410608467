
import {of as observableOf,  Observable } from 'rxjs';

import {switchMap, distinctUntilChanged, debounceTime} from 'rxjs/operators';
// angular components
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

// 3rd party components
import { TranslateService } from '@ngx-translate/core';

import { AvoidFavorService } from './../../vehicle-group/shared';
import { ConfirmationModal } from './../../shared';

import { GroupsUtil } from './../../../app/shared/utils/groupsUtil';


@Component({
  providers: [AvoidFavorService],
  templateUrl: './avoid-favor-set-list.component.html'
})
// tslint:disable-next-line: component-class-suffix
export class AvoidFavorSetList implements OnInit {

  @ViewChild('confirmModal', { static: true }) confirmModal: ConfirmationModal;
  @ViewChild('setDefaultConfirmModal', { static: true }) setDefaultConfirmModal: ConfirmationModal;

  public isProcessing = false;
  public allItems: Array<any> = [];
  public filteredItems: Array<any> = [];
  public sortAsc = true;
  public sortCol = 'name';

  public searchTerm = new FormControl();

  public errorMessage: Observable<any>;

  public currentPage = 0;
  public perPage = 10;
  public pageItems: Array<any> = [];

  constructor(
    private avoidFavorService: AvoidFavorService,
    private translate: TranslateService
  ) { }

  public ngOnInit() {
    this.isProcessing = true;

    this.searchTerm.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => this.filterAll(term)), )
      .subscribe(items => {
        this.filteredItems = items;

        this.currentPage = 0;
        this.setCurrentPageItems();
      });

    this.avoidFavorService.getAvoidFavorSets()
      .subscribe(response => {

        this.allItems = response.items;
        this.filteredItems = this.allItems;
        this.isProcessing = false;

        this.setCurrentPageItems();
      }, error => {
        this.errorMessage = this.translate.get('views.avoid-favor-set-list.errors.UnknownRetrievingAFSets');
        this.isProcessing = false;
      });
  }

  public previousPage() {
    if (!this.hasPreviousPage()) { return; }

    this.currentPage--;
    this.setCurrentPageItems();
  }

  public getToolTipForGroups(groups: Array<any>) {
    return GroupsUtil.getToolTipforGroups(groups);
  }

  public nextPage() {
    if (!this.hasNextPage()) { return; }

    this.currentPage++;
    this.setCurrentPageItems();
  }

  public hasNextPage(): boolean {
    const nextPageStartIndex = (this.currentPage + 1) * this.perPage;
    return this.filteredItems.length > nextPageStartIndex;
  }

  public hasPreviousPage(): boolean {
    return this.currentPage > 0;
  }

  public clearSearchTerm() {
    this.searchTerm.setValue('');
  }

  public sortString(column: string, asc: boolean) {
    const multiplyBy = asc ? 1 : -1;

    this.filteredItems.sort((a, b) => {
      const aUpperCase = a[column].toUpperCase();
      const bUpperCase = b[column].toUpperCase();

      if (aUpperCase < bUpperCase) { return (-1 * multiplyBy); }
      if (aUpperCase > bUpperCase) { return (1 * multiplyBy); }
      return 0;
    });

    this.setCurrentPageItems();
  }

  public sortNumber(column: string, asc: boolean) {
    const multiplyBy = asc ? 1 : -1;

    this.filteredItems.sort((a, b) => {
      return (a[column] - b[column]) * multiplyBy;
    });

    this.setCurrentPageItems();
  }

  public pageStart() {
    if (this.filteredItems.length === 0) { return 0; }
    return (this.currentPage * this.perPage) + 1;
  }

  public pageEnd() {
    let retVal = (this.currentPage * this.perPage) + this.perPage;
    if (retVal > this.filteredItems.length) {
      retVal = this.filteredItems.length;
    }
    return retVal;
  }

  public isDefaultToggle(item: any) {

    const selectedElementCurDefaultVal = !item.isDefault;

    if (!selectedElementCurDefaultVal) {
      this.translate.get('views.avoid-favorSet-list.UnassignDefaultTitle').subscribe(res => {
        this.setDefaultConfirmModal.title = res;
      });
      this.translate.get('views.avoid-favorSet-list.UnassignDefaultBody').subscribe(res => {
        this.setDefaultConfirmModal.body = res;
      });
    } else {
      this.translate.get('views.avoid-favorSet-list.SetDefaultTitle').subscribe(res => {
        this.setDefaultConfirmModal.title = res;
      });
      this.translate.get('views.avoid-favorSet-list.ConfirmDefaultBody').subscribe(res => {
        this.setDefaultConfirmModal.body = res;
      });
    }

    this.setDefaultConfirmModal.open()
      .then(() => {
        item.isProcessing = true;
        item.isDefault = !item.isDefault;

        // tslint:disable-next-line: deprecation
        this.avoidFavorService.setIsDefaultChanged(item.setID, item.isDefault).subscribe(null, (error) => {
          item.isProcessing = false;
          this.errorMessage = this.translate.get('views.avoid-favor-set-list.errors.UnableToSetDefaultSet');
       });
      });
  }

  private setCurrentPageItems() {
    const startPageIndex = this.currentPage * this.perPage;
    const endPageIndex = startPageIndex + this.perPage;

    this.pageItems = this.filteredItems.slice(startPageIndex, endPageIndex);
  }

  private filter(items: Array<any>, searchTerm: string) {
    searchTerm = searchTerm.toUpperCase();

    return items.filter(item => {
      if (item.name != null && item.name.toUpperCase().indexOf(searchTerm) !== -1) { return true; }
      if (item.vehicleGroupsAbbreviated != null && item.vehicleGroupsAbbreviated.toUpperCase().indexOf(searchTerm) !== -1) {return true; }

      return false;
    });
  }

  private filterAll(searchTerm?: string) {
    let results = this.allItems;

    if (searchTerm) {
      results = this.filter(results, searchTerm);
    }

    return observableOf(results);
  }

}
