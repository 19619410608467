<div class="profile-list-panel" [class.minheight]="!readonly">
  <div class="panel-heading">
    <h3 class="panel-title pull-left">
      <span>{{ entityHeading }}</span>
      <span class="summary" *ngIf="(itemsPendingAddition.length > 0 || itemsPendingRemoval.length > 0)">
        (
        <span *ngIf="(itemsPendingAddition.length > 0)">{{itemsPendingAddition.length}} Added</span>
      <span *ngIf="(itemsPendingRemoval.length > 0)">{{itemsPendingRemoval.length}} Removed</span> )
      </span>
      <span class="ico ico-circle-notch ico-spin" *ngIf="isLoading"></span>
    </h3>

    <div class="pull-right">
    </div>

    <div class="clearfix"></div>
  </div>

  <div class="panel-body">
    <div class="alert alert-warning" *ngIf="((allDisplayableItems?.length === 0) && (!readonly) && (!searchTerm.dirty))">
      <span [innerHtml]="'views.group-associations.NoItems' | translate: {entityLabel: entityLabel}"></span>
    </div>

    <div class="alert alert-warning" *ngIf="((allDisplayableItems?.length === 0) && (readonly) && (!searchTerm.dirty))">
      <span [innerHtml]="'views.group-associations.NoItemsReadOnly' | translate: {entityLabel: entityLabel}"></span>
    </div>

    <div class="filter-panel">
      <label>
        <span [innerHtml]="'common.Filter' | translate"></span>
        <input id="group-assoc-filter" type="text" class="form-control input-sm" placeholder="" [formControl]="searchTerm" (keydown.escape)="clearSearchTerm()" />
      </label>
      <button id="add-vehicle-group" class="btn btn-default pull-right" type="button" (click)="openAddModal()" *ngIf="!readonly">
        <span class="ico ico-plus"></span>
        <span [innerHtml]="'common.Add' | translate"></span>
      </button>
      <div class="btn-group pull-right" dropdown>
        <button type="button" class="btn btn-default" *ngIf="downloadUrl" dropdownToggle>
          <span class="ico ico-cog"></span>
        </button>
        <ul class="dropdown-menu" role="menu" aria-labelledby="single-button">
          <li role="menuitem">
            <a class="" [href]="downloadUrl" download="export.csv">
              <span class="glyphicon glyphicon-cloud-download"></span>
              <span [innerHtml]="'common.Export' | translate"></span>
            </a>
          </li>
        </ul>
      </div>
      <span class="summary" *ngIf="(itemsPendingAddition.length > 0 || itemsPendingRemoval.length > 0)">
        (
        <span *ngIf="(itemsPendingAddition.length > 0)">{{itemsPendingAddition.length}} Added</span>
      <span *ngIf="(itemsPendingRemoval.length > 0)">{{itemsPendingRemoval.length}} Removed</span> )
      </span>
    </div>

    <table id="group-assoc-table" class="table table-striped table-hover list-table" [ngClass]="additionalTableClasses" *ngIf="((allDisplayableItems?.length > 0) || (searchTerm.dirty))">
      <thead>
        <tr>
          <th *ngFor="let column of _columnDefinitions">{{ column.title }}</th>
          <th *ngIf="!readonly">{{ 'views.group-associations.ColumnHeaderModified' | translate }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of currentPageItems" class="js-vehicle-list-row">
          <td (click)="onRowClicked(item)" *ngFor="let field of item.fields; let idx = index" class="{{('group-association-' + entityHeading.split(' ').join('') + '-' + _columnDefinitions[idx].title.split(' ').join('') + '-col') | lowercase}}">
            <span [ngClass]="{'deleted': item.pendingRemoval}" [innerHtml]="field | alkHighlight:searchTerm.value"></span>
          </td>
          <td (click)="onRowClicked(item)" *ngIf="!readonly" class="group-association-modified-col">
            <span class="modified-added" *ngIf="!readonly && item.pendingAddition">{{ 'views.group-associations.WillBeAdded' | translate }}</span>
            <span class="modified-removed" *ngIf="!readonly && item.pendingRemoval">{{ 'views.group-associations.WillBeRemoved' | translate }}</span>
          </td>
          <td class="text-right">
            <button class="btn btn-default" type="button" *ngIf="!readonly" (click)="remove(item)" title="{{ !item.pendingRemoval ? ('views.group-associations.RemoveGroup' | translate) : ('views.group-associations.UndoRemove' | translate)}}">
              <span class="glyphicon" [ngClass]="{'glyphicon-repeat' : item.pendingRemoval, 'glyphicon-remove': !item.pendingRemoval}"></span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="pagination-panel" *ngIf="((allDisplayableItems?.length > 0) || (searchTerm.dirty))">
      <div id="group-assoc-pagination" class="pull-left grid-details">
        {{ 'common.DisplayOf' | translate:{pageStartNumber:pageStart(), pageEndNumber:pageEnd(), total:totalFilteredItems} }}
      </div>

      <div class="pull-right" *ngIf="(hasPreviousPage() || hasNextPage())">
        <button type="button" id="group-assoc-prev" class="btn btn-default" (click)="previousPage()" [disabled]="!hasPreviousPage()">
          <span class="glyphicon glyphicon-menu-left"></span>
          <span [innerHtml]="'common.Previous' | translate"></span>
        </button>
        <button type="button" id="group-assoc-next" class="btn btn-default" (click)="nextPage()" [disabled]="!hasNextPage()">
          <span [innerHtml]="'common.Next' | translate"></span>
          <span class="glyphicon glyphicon-menu-right"></span>
        </button>
      </div>
    </div>

  </div>
</div>

<group-add-modal #addModal [entityLabel]="entityLabel" [excludedGroups]="initialItems" (itemsAdded)="onAddedItems($event)">
</group-add-modal>

<group-add-paginated-modal #addPaginatedModal [entityLabel]="entityLabel" [excludedGroups]="initialItems" [overallTotal]="total" (itemsAdded)="onAddedItems($event)">
</group-add-paginated-modal>
