<ul>
  <li class="large-btn cloud-button dropdown" dropdown [isOpen]="isOpen">
    <button id="locations-btn" class="nav-button dropdown-toggle" (click)="isOpen = true" dropdownToggle alk-analytics="['ALK Apps']">
          <img [src]="webToolsImgSrc" />
          <span [innerHtml]="'common.nav.webTools' | translate"></span> <span class="caret"></span>
      </button>
    <div class="dropdown-menu dropdown-menu-right popover cloud-panel" role="menu" (click)="$event.stopPropagation();">
      <h5 class="legend">
        <span [innerHtml]="'common.nav.LicensedApps' | translate"></span>
        <span (click)="isOpen = false" class="cursor-pointer ico ico-times-circle pull-right" style="top: -1px;font-size: 16px;"></span>
      </h5>
      <ul class="list-group cloud-menu">
        <li class="cursor-pointer list-group-item cloud-menu-item" *ngFor="let product of licensedApps">
          <div *ngIf="product.productLicensed">
            <a (click)="goToAppPage(product)">
              <img [src]="product.productImgSrc" [alt]="product.productTitle | translate" class="pull-left">
              <div class="cloud-menu-title">{{product.productTitle | translate}}</div>
              <div class="cloud-menu-text">{{product.productText | translate}}</div>
            </a>
          </div>
        </li>
      </ul>
      <h5 *ngIf="unlicensedApps.length > 0" class="legend" [innerHtml]="'common.nav.UnlicensedApps' | translate"></h5>
      <ul *ngIf="unlicensedApps.length > 0" class="list-group cloud-menu">
        <li class="list-group-item cloud-menu-item cursor-pointer" *ngFor="let product of unlicensedApps" (click)="toggleUnlicensedProductInfo(product, $event)">
          <div *ngIf="!product.productLicensed">
            <div>
              <img [src]="product.productImgSrc" [alt]="product.productTitle | translate" class="pull-left">
              <div class="cloud-menu-title">{{product.productTitle | translate}}</div>
              <div class="cloud-menu-text">{{product.productText | translate}}</div>
            </div>
            <div class="container-fluid" *ngIf="product.showUnlicensedMessage">
              <div class="row cloud-menu-unlicensed-alert">
                <span class="cloud-menu-unlicensed-label" [innerHtml]="'common.nav.NotLicensed' | translate"></span>
                <a (click)="goToInfoPage(product)" class="btn btn-default btn-xs pull-right cloud-menu-unlicensed-btn" [innerHtml]="'common.nav.LearnMore' | translate"></a>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <h5 *ngIf="expiredApps.length > 0" class="legend" [innerHtml]="'common.nav.ExpiredApps' | translate"></h5>
      <ul *ngIf="expiredApps.length > 0" class="list-group cloud-menu">
        <li class="list-group-item cloud-menu-item cursor-pointer" *ngFor="let product of expiredApps" (click)="toggleUnlicensedProductInfo(product, $event)">
          <div *ngIf="product.productExpired">
            <div>
              <img [src]="product.productImgSrc" [alt]="product.productTitle | translate" class="pull-left">
              <div class="cloud-menu-title">{{product.productTitle | translate}}</div>
              <div class="cloud-menu-text">{{product.productText | translate}}</div>
            </div>
            <div class="container-fluid" *ngIf="product.showUnlicensedMessage">
              <div class="row cloud-menu-unlicensed-alert">
                <span class="cloud-menu-unlicensed-label" [innerHtml]="'common.nav.NotLicensed' | translate"></span>
                <a (click)="goToInfoPage(product)" class="btn btn-default btn-xs pull-right cloud-menu-unlicensed-btn" [innerHtml]="'common.nav.LearnMore' | translate"></a>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <a class="btn btn-xs btn-default pull-right cloud-menu-btn cloud-menu-btn-last" (click)="logout($event)" [innerHtml]="'views.header.Logout' | translate"></a>
    </div>
  </li>
</ul>
