// alk components
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';

// app components
import { AppModule } from './app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// trackJS configuration
declare var trackJs: any;
if (environment.trackJsEnvironment) {
  console.log('trackJS env:', environment.trackJsEnvironment);
  trackJs.configure({
    application: environment.trackJsEnvironment
  });
} else {
  console.log('trackJS disabled');
  // trackJs.configure({
  //   // enabled: false // doesn't work after initial script load
  //   onError: function (errorPayload, originalError) {
  //     return false; // returning false will prevent transmit of your error data
  //   }
  // });
}

platformBrowserDynamic().bootstrapModule(AppModule);
