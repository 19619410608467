<div bsModal #modal="bs-modal" [config]="{ keyboard: false, backdrop: 'static' }" class="modal fade" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">{{
          "View"| translate}}</h4>
      </div>
      <form [formGroup]="form" novalidate>
        <div class = "modal-body tab-modal">
          <h4 class= "modal-title response" ><label>{{ 'views.company-adminstration.VehicleSummary.DeviceId' | translate }}: {{deviceId}}</label></h4>
          <h4 class= "modal-title response" ><label *ngIf = 'addTripDetails'>{{ 'views.company-adminstration.VehicleSummary.TripId' | translate }}: {{tripId}}</label></h4>
          <h4 class= "modal-title response" ><label *ngIf = 'addTimeStamp'>{{ 'views.company-adminstration.VehicleSummary.Timestamp' | translate }}: {{timeStamp}}</label></h4>
          <h4 class= "modal-title response" ><label *ngIf = 'addRevision'>{{ 'views.company-adminstration.VehicleSummary.Revision' | translate }}: {{revision}}</label></h4>

        </div>
        <div class="modal-body tab-modal">
         <h4 class= "modal-title request" > <label>{{ 'views.company-adminstration.VehicleSummary.Request' | translate }}</label></h4>
         <pre class= "request-details">{{request}}</pre>
        </div>

        <div class="modal-body tab-modal">
          <h4 class= "modal-title response" > <label>{{ 'views.company-adminstration.VehicleSummary.Response' | translate }}</label></h4>
         <pre class = "response-details">{{response}}</pre>
        </div>

        <div class="clearfix"></div>

        <div class="modal-footer">
          <button type="button" class="btn btn-default cancel-btn" [disabled]="isProcessing" (click)="close()">{{ 'common.Cancel' | translate }}</button>
          <button type="button" class="btn btn-default" [disabled]="isProcessing || !addTripDetails" (click)="loadTripDetails()">{{'views.company-adminstration.VehicleSummary.TripDetails' | translate }}</button>
        </div>
      </form>
    </div>
  </div>
</div>
