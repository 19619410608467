import { UserAssociatedGroup } from './account-user-associated-group';

export class UserInAccount {
  userId: number;
  // name: string;
  firstName: string;
  lastName: string;
  fullName: string;
  emailAddress: string;
  roleDisplay: string;
  // roles: string[];
  // isAdmin: boolean;
  role: string;
  accountId: number;
  stateName: string;
  enabled: boolean;
  vehicleGroups: UserAssociatedGroup[] = [];
  vehicleGroupsAbbreviated = '';
  driverGroups: UserAssociatedGroup[] = [];
  driverGroupsAbbreviated = '';
}
