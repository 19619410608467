
import {of as observableOf } from 'rxjs';

import {switchMap, distinctUntilChanged, debounceTime} from 'rxjs/operators';
// angular components
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

// 3rd party components

// alk
import { UserContextService } from '../../../alk-components/authentication';
import { VehicleService } from './../../vehicle/shared';
import { PartnerAccountService } from '../partner-account.service';
import { PartnerAccount } from '../partner-account';
import { PartnerAccountMoveModal } from '../partner-account-move-modal';
import { PartnerAccountNewModal } from '../partner-account-new-modal';


@Component({
  selector: 'partner-account-list',
  templateUrl: './partner-account-list.component.html',
  providers: [PartnerAccountService, VehicleService]
})
// tslint:disable-next-line: component-class-suffix
export class PartnerAccountList implements OnInit {

  @ViewChild('partnerAccountMoveModal', { static: true }) partnerAccountMoveModal: PartnerAccountMoveModal;
  @ViewChild('partnerAccountNewModal', { static: true }) partnerAccountNewModal: PartnerAccountNewModal;

  public isProcessing = false;
  public allDriverGroups: Array<any> = [];
  public filteredPartnerAccounts: Array<any> = [];
  public sortAsc = true;
  public sortCol = 'name';
  public searchTerm = new FormControl();
  public selectedGroupIds: Array<any> = [];
  public errorMsg: string;
  public currentPage = 0;
  public perPage = 10;
  public pageItems: Array<any> = [];
  public partnerAccounts: Array<PartnerAccount>;
  public allVehicles: Array<any> = [];
  public filteredVehicles: Array<any> = [];
  public total = 0;
  public totalAcc = 0;
  public ids: Array<any> = [];
  public filterText = '';
  public filteredAccountItems: any;
  public selectAccount = 'Please select an account with devices to display devices';
  public totalVehicleCount = 0;
  public currentAccountId: number;
  public currentAccountname = '';
  public loading = false;
  public disableNew = false;

  searchAccount = '';
  searchDevice = '';

  constructor(
    private partnerAccountService: PartnerAccountService,
    private vehicleService: VehicleService,
    private userContext: UserContextService) { }

  ngOnInit() {
    // only attempt to populate this control if the current user is a partner
    this.disableNew = !this.userContext.isManualIntegration();
    if (this.userContext.isPartnerAccountUser()) {
      this.isProcessing = true;
      this.filterAccounts('');
      this.searchTerm.valueChanges.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(term => this.filterAll(term)),
      ).subscribe(items => {
        this.filteredPartnerAccounts = items;
      });

      this.getPartnerAccounts();
    }

  }

  getPartnerAccounts() {
      this.partnerAccountService.getPartnerAccounts()
        .subscribe(accounts => {
          this.partnerAccounts = accounts;
          this.getTotalVehicles();
          this.pageItems = this.partnerAccounts;
          this.filteredPartnerAccounts = this.pageItems;
          this.filteredAccountItems = this.filteredPartnerAccounts;
          this.totalAcc = this.partnerAccounts.length;
          if (!this.currentAccountId && !this.currentAccountname) {
            this.currentAccountId  = this.filteredAccountItems[0].id;
            this.currentAccountname = this.filteredAccountItems[0].name;
          }
          this.loadVehicles(this.currentAccountId , this.currentAccountname);  /*change this to unassigned bucket after implementing that */

        }, error => {
          this.errorMsg = 'There was an error retrieving partner accounts: ' + error.message;
        }, () => {
          this.isProcessing = false;
        });
  }
  getTotalVehicles() {
     this.totalVehicleCount = 0;
     this.partnerAccounts.forEach(element => {
      this.totalVehicleCount += element.vehicleCount;
    }
    );
  }
  moveDevices() {
    this.partnerAccountMoveModal.open(false, this.partnerAccounts, this.ids, this.currentAccountId);
  }
  newDevice() {
    this.partnerAccountNewModal.open(this.currentAccountId, this.currentAccountname);
  }
  toggleSelectedId(vehicle) {
      const idx = this.ids.indexOf(vehicle);
      // is currently selected
      if (idx > -1) {
        this.ids.splice(idx, 1);
      } else {
        this.ids.push(vehicle);
      }
  }
  selectAll() {
    this.ids = this.filteredVehicles;
  }
  selectNone() {
    this.ids = [];
  }
  isDisabled() {
    return (this.ids.length === 0 || this.partnerAccounts.length === 0 );
  }
  filterAccounts(filter) {
      if (!filter) {
        this.filteredAccountItems = this.partnerAccounts;
      } else {
        const filterUpperCase = filter.toUpperCase();
        this.filteredAccountItems = this.partnerAccounts.filter(c => c.name != null
        && (c.name.toUpperCase().indexOf(filterUpperCase) !== -1
        || c.id.toString().indexOf(filterUpperCase) !== -1));
      }
  }

  filterDevices(filter) {
    this.selectNone();
    if (!filter) {
         this.filteredVehicles = this.allVehicles;
      } else {
        const filterUpperCase = filter.toUpperCase();
        this.filteredVehicles = this.allVehicles.filter(c => c.name.toUpperCase().indexOf(filterUpperCase) !== -1
        || c.vehicleId.toString().indexOf(filterUpperCase) !== -1);
      }
  }
  reloadVehicles(account) {
    this.loadVehicles(account.id, name);
    this.getPartnerAccounts();
    this.getTotalVehicles();
  }

  loadVehicles(id, name) {
    this.currentAccountId = id;
    this.currentAccountname = name;
    this.currentAccountId = id;
    this.ids = [];
    this.filteredVehicles = [];
    this.loading = true;
    this.selectAccount = null;
    this.vehicleService.getVehiclesByAccount(id)
      .subscribe(vehicles => {
        this.allVehicles = vehicles.items;
        this.filteredVehicles = this.allVehicles;
        this.loading = false;
        this.total = vehicles.total;
        this.selectAccount = null;
      }, error => {
        this.errorMsg = 'There was an error retrieving vehicles: ' + error.message;
      }, () => {
        this.isProcessing = false;
      });
  }

  clearSearchTerm() {
    this.searchTerm.setValue('');
    this.filterDevices('');
  }

  sortString(column: string, asc: boolean) {
    const multiplyBy = asc ? 1 : -1;

    this.filteredPartnerAccounts.sort((a, b) => {
      const aUpperCase = a[column].toUpperCase();
      const bUpperCase = b[column].toUpperCase();

      if (aUpperCase < bUpperCase) { return (-1 * multiplyBy); }
      if (aUpperCase > bUpperCase) { return (1 * multiplyBy); }
      return 0;
    });
  }

  sortNumber(column: string, asc: boolean) {
    const multiplyBy = asc ? 1 : -1;

    this.filteredPartnerAccounts.sort((a, b) => {
      return (a[column] - b[column]) * multiplyBy;
    });
  }

  pageStart() {
    if (this.filteredPartnerAccounts.length === 0) { return 0; }
    return (this.currentPage * this.perPage) + 1;
  }

  pageEnd() {
    let retVal = (this.currentPage * this.perPage) + this.perPage;
    if (retVal > this.filteredPartnerAccounts.length) {
      retVal = this.filteredPartnerAccounts.length;
    }
    return retVal;
  }

  getGroupIndex(groups, group): number {
    for (let i = 0; i < groups.length; i++) {
      if (groups[i].id === group.id) {
        return i;
      }
    }

    return -1;
  }



  filter(groups: Array<any>, searchTerm: string) {
    searchTerm = searchTerm.toUpperCase();

    return groups.filter(group => {
      if ((group.name != null && group.name.toUpperCase().indexOf(searchTerm) !== -1))  { return true; }
      return false;
    });
  }

  filterAll(searchTerm?: string) {
    let results = this.pageItems;

    if (searchTerm) {
      results = this.filter(results, searchTerm);
    }

    return observableOf(results);
  }

}
