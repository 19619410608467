// angular components
import { Component } from "@angular/core";

@Component({
  selector: "marketing-footer",
  templateUrl: "./marketing-footer.component.html",
})
// tslint:disable-next-line: component-class-suffix
export class MarketingFooter {
  currentYear = new Date().getFullYear();
}
