import * as tslib_1 from "tslib";
// angular components
import { OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
// 3rd party components
import { Angulartics2GoogleAnalytics } from "angulartics2/ga";
import { combineLatest } from "rxjs";
import { filter, take } from "rxjs/operators";
import { TidService } from "../shared/tid/tid.service";
// alk shared components
import { UserContextService } from "./../../alk-components/authentication";
// app specific components
import { AuthenticationService } from "./../shared/authentication";
var Login = /** @class */ (function () {
    function Login(authenticationService, router, userContext, route, ag2analytics, tidService) {
        var _this = this;
        this.authenticationService = authenticationService;
        this.router = router;
        this.userContext = userContext;
        this.route = route;
        this.ag2analytics = ag2analytics;
        this.tidService = tidService;
        this.USERNAME_DOES_NOT_EXIST = "USERNAME_DOES_NOT_EXIST";
        this.creds = { selectedAccount: null };
        this.isProcessing = false;
        this.message = "";
        this.tmxTidService = null;
        this.tidEventHandler = function (event) {
            switch (event.type) {
                case "SIGN_IN_USERNAME":
                    _this.errorMessage$.next(null);
                    _this.tidService
                        .checkMigrationStatus(event.username)
                        .pipe(take(1))
                        .subscribe({
                        next: function (_a) {
                            var exists = _a.exists, migrated = _a.migrated;
                            if (!exists) {
                                _this.tmxTidService.showSignInUsername();
                                _this.tmxTidService.setSignInValues();
                                _this.errorMessage$.next(_this.USERNAME_DOES_NOT_EXIST);
                                return;
                            }
                            if (migrated) {
                                _this.tidService.signInWithTid(event.username);
                            }
                            else {
                                _this.tmxTidService.showSignInConfirm();
                                _this.tmxTidService.setSignInValues(event.username);
                            }
                        },
                    });
                    break;
                case "SIGN_IN_ACCOUNT":
                    _this.authenticationService
                        .loginWithTid(_this.tidService.getAuthCode(), event.account)
                        .then(function (ssoResponse) {
                        _this.redirectUrl = _this.userContext.getRedirectUrl(_this.redirectUrl);
                        ssoResponse.user.currentPartnerAccountId =
                            ssoResponse.user.accountId;
                        _this.configureTrackJs(ssoResponse.user);
                        _this.router.navigateByUrl(_this.redirectUrl);
                    }, function (error) { return _this.handleSSOError(error, event.username); });
                    _this.tidService.accounts$.next([]);
                    break;
                case "SIGN_IN_CONFIRM_CANCEL":
                    _this.tmxTidService.showSignInUsername();
                    break;
                case "SIGN_IN_TID":
                    _this.tidService.signInWithTid(event.username, true);
                    break;
            }
        };
        this.tidConfig = {
            eventHandler: this.tidEventHandler,
        };
        this.errorMessage$ = this.tidService.errorMessage$;
        this.accounts$ = this.tidService.accounts$.pipe(filter(function (accounts) { return accounts.length > 0; }));
        // tslint:disable-next-line:no-string-literal
        var r = this.route.snapshot.params["r"]; // redirect to the r query parameter or the home screen afterwards
        if (r) {
            this.redirectUrl = decodeURIComponent(r);
        }
        this.redirectUrl = this.redirectUrl || "/vehicle-groups";
    }
    Login.prototype.ngOnInit = function () {
        var _this = this;
        this.tidService.checkForComponents$.pipe(take(1)).subscribe({
            next: function (exists) {
                if (!_this.tmxTidService && exists) {
                    _this.tmxTidService = tmxtidmodule.init(_this.tidConfig);
                }
            },
        });
        this.subscription = combineLatest([
            this.tidService.checkForComponents$,
            this.accounts$,
        ]).subscribe({
            next: function (_a) {
                var _b = tslib_1.__read(_a, 2), exists = _b[0], accounts = _b[1];
                if (exists) {
                    _this.tmxTidService.showSignInAccounts(accounts);
                }
            },
        });
    };
    Login.prototype.handleSSOError = function (error, username) {
        if (error.error) {
            error = error.error;
        }
        this.isProcessing = false;
        if (error.status !== null && error.status === "FailedToLogin") {
            this.message = "Username or password is incorrect.";
            this.accounts = null;
            this.errorMessage$.next("Username or password is incorrect.");
            this.tmxTidService.showSignInUsername();
            this.tmxTidService.setSignInValues();
        }
        else if (error.status !== null && error.status === "LoginNotExist") {
            this.errorMessage$.next("User login does not exist.");
            this.tmxTidService.showSignInUsername();
            this.tmxTidService.setSignInValues();
        }
        else if (error.status !== null &&
            error.status === "FailedToLoginDueToMultipleAccounts" &&
            error.accounts !== null &&
            error.accounts.length > 0) {
            this.accounts = error.accounts;
            var accounts = error.accounts.map(function (account) { return ({
                displayName: account.accountName,
                value: account.accountName,
            }); }) || [];
            this.tidService.accounts$.next(accounts);
            this.creds.selectedAccount = this.accounts[0].accountName;
        }
        else {
            this.errorMessage$.next("Unknown error while attempting to login.");
            this.tmxTidService.showSignInUsername();
            this.tmxTidService.setSignInValues();
        }
        this.ag2analytics.eventTrack("Login", {
            success: false,
            reason: this.message,
            username: username,
        });
    };
    Login.prototype.configureTrackJs = function (ssoUser) {
        // add the user information to trackJs
        try {
            trackJs.configure({
                userId: ssoUser.userName + " (#" + ssoUser.userId + "), Account " + ssoUser.account + " (#" + ssoUser.accountId + ")",
            });
            this.ag2analytics.setUserProperties({
                userId: ssoUser.userId,
                name: ssoUser.firstName + " " + ssoUser.lastName,
                email: ssoUser.userName,
            });
            this.ag2analytics.eventTrack("Login", {
                success: true,
                reason: "",
                username: ssoUser.userName,
            });
        }
        catch (err) { }
    };
    Login.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
        this.tmxTidService.destroy();
    };
    return Login;
}());
export { Login };
