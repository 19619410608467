import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  forkJoin as observableForkJoin,
  from as observableFrom,
  Observable,
  of as observableOf,
  Subscription,
} from 'rxjs';
import { pluck } from 'rxjs/operators';
import { AccountManagementService } from './../../../alk-components/account-management';
import { UserContextService } from './../../../alk-components/authentication';
import {
  ConfirmationModal,
  incrementBy,
  max,
  maxDecimalPlaces,
  min,
} from './../../shared';
import { GroupAssociationsComponent } from './../../shared/group-associations';
import {
  convertKMToMileDecimal,
  convertMileToKMDecimal,
} from './../../shared/utils/convertUtil';
import {
  ConfigurationProfilesService,
  VehicleGroupService,
} from './../../vehicle-group/shared';

@Component({
  templateUrl: './configuration-profile-detail.component.html',
  providers: [
    ConfigurationProfilesService,
    VehicleGroupService,
    AccountManagementService,
  ],
})
// tslint:disable-next-line: component-class-suffix
export class ConfigurationProfileDetail implements OnInit, OnDestroy {
  public id: number;
  public isNew: boolean;
  public isProcessing = false;
  public isInitializing = true;

  public submitted = false;

  public errorMessage: Observable<string>;
  public successMessage: Observable<string>;

  public validation;

  public form: FormGroup;

  // todo: these need to be updated for kilometers
  public minOor = 0.1;
  public maxOor = 10;
  public stepOor = 0.1;

  public defaultProfile: any;

  public isRouteReporterAccount = false;
  public showUnitConversionTooltip = false;
  public isDefaultToggleDirty = false;
  public showPreventAutoRoute = false;

  @ViewChild('confirmModal', { static: true }) confirmModal: ConfirmationModal;
  @ViewChild('setDefaultConfirmModal', { static: true })
  setDefaultConfirmModal: ConfirmationModal;
  @ViewChild('changeDefaultModal', { static: true })
  changeDefaultModal: ConfirmationModal;
  @ViewChild('vehicleAssociations', { static: true })
  vehicleAssociations: GroupAssociationsComponent;

  private sub1: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private profileService: ConfigurationProfilesService,
    private userContext: UserContextService,
    private fb: FormBuilder,
    private vehicleGroupService: VehicleGroupService,
    private accountService: AccountManagementService,
    private translate: TranslateService
  ) {
    this.form = this.fb.group(
      {
        name: [
          '',
          [
            Validators.required,
            Validators.minLength(1),
            Validators.maxLength(64),
          ],
        ],
        isVehicleGroupDefault: [false],

        // routing profile property
        availableUpdatesSyncInterval: [
          6,
          Validators.compose([
            min(3),
            max(24),
            incrementBy(0.5),
            Validators.required,
          ]),
        ],

        // compliance properties
        outOfRouteThreshold: [
          0.2,
          Validators.compose([Validators.required, maxDecimalPlaces(1)]),
        ],

        // distanceUnit property
        distanceUnits: [],

        // driver acessibility properties
        allowRoutingProfileEditing: [],
        forceRoutingProfileSelection: [],
        displayMapReporting: [],
        preventAutoRoute: [],
      },
      {
        // these validators depend on multiple feeds
        validator: Validators.compose([this.outOfRouteThresholdValidator]),
      }
    );
  }

  public outOfRouteThresholdValidator(fg: FormGroup) {
    const oor = fg.value.outOfRouteThreshold;

    // TODO: add logic for using different values for metric/english
    const minVal = 0.1;
    const maxVal = 10;

    if (oor >= minVal && oor <= maxVal) {
      return null;
    }

    return { oorThreshold: { min: minVal, max: maxVal } };
  }

  public canDeactivate(): Observable<boolean> | boolean {
    if (
      !this.isDefaultToggleDirty &&
      !this.form.dirty &&
      this.vehicleAssociations.itemsPendingAddition.length === 0 &&
      this.vehicleAssociations.itemsPendingRemoval.length === 0
    ) {
      return true;
    }

    return observableFrom(this.confirmModal.open());
  }

  public ngOnInit() {
    const user = this.userContext.getUser();
    this.showPreventAutoRoute = this.userContext.hasRole([
      'ALK Administrator',
      'ALK Support',
    ]);
    this.showUnitConversionTooltip = user.enableUnitConversion;

    this.sub1 = this.route.params.pipe(pluck('id')).subscribe((id) => {
      this.isInitializing = true;
      this.accountService.isRouteReporterAccount().subscribe((result) => {
        this.isRouteReporterAccount = result;
      });

      // we determine if the page is for a new configuration profile or
      // an existing configuration profile based on the id ('new' is for a new profile)
      this.isNew = isNaN(+id);

      const profileObs = this.isNew
        ? this.loadNewProfile()
        : this.loadExistingProfile(+id);
      const vehicleGroupsObs = this.isNew
        ? observableOf([])
        : this.vehicleGroupService.getGroups(null, null, null, null, [+id]);

      observableForkJoin([
        vehicleGroupsObs,
        this.vehicleGroupService.getGroups(),
        profileObs,
      ]).subscribe(
        (result) => {
          this.id = result[2].id;
          this.loadForm(result[2]);

          this.vehicleAssociations.columnDefinitions = [
            { title: 'Name', isFilterable: true },
          ];

          this.vehicleAssociations.initialItems = result[0].map((group) => {
            return {
              id: group.id,
              fields: [group.name],
              pendingRemoval: false,
              pendingAddition: false,
            };
          });

          this.vehicleAssociations.allPossibleItems = result[1].map((group) => {
            return {
              id: group.id,
              fields: [group.name],
              pendingRemoval: false,
              pendingAddition: false,
            };
          });

          // finished loading page, update flag to let ui know to not display loading indicator
          this.isInitializing = false;
        },
        () => {
          this.errorMessage = this.translate.get(
            'views.configuration-profile-detail.errors.UnknownLoading'
          );
          this.isInitializing = false;
        }
      );
    });
  }

  public loadForm(attributes) {
    for (const attr in attributes) {
      if (!attributes.hasOwnProperty(attr)) {
        continue;
      }

      const control = this.form.controls[attr] as FormControl;
      if (!control) {
        continue;
      }

      control.setValue(attributes[attr]);
    }
  }

  public loadNewProfile(): Observable<any> {
    const profile = this.profileService.getDefaultProfile();
    return observableOf(profile);
  }

  public loadExistingProfile(id: number): Observable<any> {
    return this.profileService.getProfile(id);
  }

  public ngOnDestroy() {
    this.sub1.unsubscribe();
  }

  public isDefaultToggle() {
    const selectedElementCurDefaultVal =
      !this.form.controls.isVehicleGroupDefault.value;

    if (!selectedElementCurDefaultVal) {
      this.translate
        .get('views.configuration-profile-list.UnassignDefaultTitle')
        .subscribe((res) => {
          this.setDefaultConfirmModal.title = res;
        });
      this.translate
        .get('views.configuration-profile-list.UnassignDefaultBody')
        .subscribe((res) => {
          this.setDefaultConfirmModal.body = res;
        });
    } else {
      this.translate
        .get('views.configuration-profile-list.SetDefaultTitle')
        .subscribe((res) => {
          this.setDefaultConfirmModal.title = res;
        });
      this.translate
        .get('views.configuration-profile-list.ConfirmDefaultBody')
        .subscribe((res) => {
          this.setDefaultConfirmModal.body = res;
        });
    }

    this.setDefaultConfirmModal.open().then(() => {
      this.form.controls.isVehicleGroupDefault.setValue(
        selectedElementCurDefaultVal
      );
      this.isDefaultToggleDirty = true;
    });
  }

  public save() {
    this.submitted = true;
    this.isDefaultToggleDirty = false;
    // clear error and success messages when saving
    this.errorMessage = null;
    this.successMessage = null;

    if (!this.form.valid) {
      return;
    }
    this.form.value.name = this.form.controls.name.value.trim();

    this.confirmOverrideDefault().then(() => {
      this.isProcessing = true;

      let promise: Promise<any>;

      // if this is a new configuration profile, save them in sequence.  If it is an existing, save them at the same time
      if (this.isNew) {
        promise = this.saveProfileDetails();

        // only need to save the vehicle groups if we have any
        if (this.vehicleAssociations.resultItems.length > 0) {
          promise = promise.then(() => this.saveVehicleGroups());
        }
      } else {
        /* Used to execute both in parallel but there was a timing issue.
         * And than we should come back to it to see what caused the issue */
        promise = this.saveProfileDetails();
        promise = promise.then(() => this.saveVehicleGroups());
      }

      promise.then(
        () => {
          this.successMessage = this.translate.get(
            'views.configuration-profile-detail.success.SaveSuccess'
          );

          // once the page is successfully saved, mark this form as pristine/untouched.
          this.form.markAsPristine();

          if (this.isNew) {
            this.router.navigate(['/configuration-profiles', this.id]);
          }

          this.isProcessing = false;
        },
        (error) => {
          this.isProcessing = false;

          if (error.code === -2) {
            this.errorMessage = this.translate.get(
              'views.configuration-profile-detail.errors.MaxReached'
            );
          } else if (error.code === -3) {
            this.errorMessage = this.translate.get(
              'views.configuration-profile-detail.errors.NameExists'
            );
          } else {
            this.errorMessage = this.translate.get(
              'views.configuration-profile-detail.errors.UnknownSave'
            );
          }
        }
      );
    });

    this.submitted = false; // back to clean slate.
  }

  public cancel() {
    this.router.navigate(['/configuration-profiles']);
  }

  public showOORConversionInfo() {
    if (this.form.controls.distanceUnits.value === 'Miles') {
      return `${convertMileToKMDecimal(
        this.form.controls.outOfRouteThreshold.value
      ).toString()} kilometres`;
    }
    return `${convertKMToMileDecimal(
      this.form.controls.outOfRouteThreshold.value
    ).toString()} miles`;
  }

  private saveProfileDetails(): Promise<any> {
    if (this.isNew) {
      return this.profileService
        .create(this.form.value)
        .toPromise()
        .then((newProfile) => {
          this.id = newProfile.id;
          return newProfile;
        });
    } else {
      return this.profileService.update(this.id, this.form.value).toPromise();
    }
  }

  private saveVehicleGroups(): Promise<any> {
    return this.profileService
      .associateVehicleGroups(
        this.id,
        this.vehicleAssociations.resultItems.map((r) => r.id)
      )
      .toPromise()
      .then(() => {
        this.vehicleAssociations.initialItems =
          this.vehicleAssociations.resultItems;
        this.vehicleAssociations.resetInitialData();
      });
  }

  private confirmOverrideDefault(): Promise<any> {
    if (this.form.value.isVehicleGroupDefault !== true) {
      return Promise.resolve();
    }

    return this.profileService
      .getConfigurationProfiles(null, null, null, true)
      .toPromise()
      .then((resp) => {
        if (resp.items.length > 0 && resp.items[0].id !== this.id) {
          this.defaultProfile = resp.items[0];

          return this.changeDefaultModal.open() as Promise<unknown>;
        } else {
          return Promise.resolve();
        }
      });
  }
}
