<div id="tmxtid-sign-in-component" class="tmxtid-init"></div>

<ng-container *ngIf="errorMessage$ | async as errorMessage">
  <div
    *ngIf="errorMessage === USERNAME_DOES_NOT_EXIST; else showErrorMessage"
    class="alert alert-danger"
    style="margin-top: 16px"
  >
    This username does not exist. Please try a different email or
    <a
      href="https://kb.omnitracs.com/Omnitracs_One/CoPilot/"
      class="alert-link"
      rel="noopener noreferrer"
      target="_blank"
    >contact support</a>
    for help.
  </div>
  <ng-template #showErrorMessage>
    <div class="alert alert-danger" style="margin-top: 16px">
      {{ errorMessage }}
    </div>
  </ng-template>
</ng-container>
