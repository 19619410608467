
import { tap, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

// angular components
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

// alk
import { UserContextService } from '../../../alk-components/authentication';
import { ConfirmationModal } from '../../shared';
import { MapsetsService } from '../shared/mapsets.service';

@Component({
  providers: [MapsetsService],
  templateUrl: './mapsets-list.component.html',
  styles: [`
    .row-text {
      font-weight: 600;
    }
    .row-subtext {
      color: #8c8b96;
      font-size: 11px
    }
    .column-name {
      font-size: 12px;
    }
    .connection-btn {
      margin-right: 5px;
    }
    .page-title {
      font-weight: 600;
    }
  `]
})
export class MapsetsListComponent implements OnInit {

  @ViewChild('confirmModal', { static: true }) confirmModal: ConfirmationModal;
  @ViewChild('setDefaultConfirmModal', { static: true }) setDefaultConfirmModal: ConfirmationModal;

  public isProcessing = false;
  public sortDir = 'ASC';
  public sortCol = 'name';
  public searchTerm = new FormControl();
  public errorMsg: string;
  public showHyperlinkError = false;
  public currentPage = 0;
  public perPage = 10;
  public total = 0;
  public pageItems: Array<any> = [];
  public user: any = null;
  public isFullAccess = false;

  constructor(
    private mapsetsService: MapsetsService,
    private translate: TranslateService,
    private userContext: UserContextService) { }

  public ngOnInit() {
    this.isProcessing = true;
    this.searchTerm.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => {
        this.currentPage = 0;
      })).subscribe(() => {
        this.updateCurrentPage();
      });

    this.updateCurrentPage();

    this.isFullAccess = !(this.userContext.getUser().roles[0].toUpperCase() === 'USER');
  }

  public deleteMapset(mapset: any) {
    this.confirmModal.open()
      .then(() => {
        this.isProcessing = true;
        if (mapset.defaultMapset) {
          this.mapsetsService.setDefaultMapset(mapset);
        }

        this.mapsetsService.deleteMapset(mapset.customMapsetId)
          .subscribe(resp => {
            const index = this.pageItems.findIndex(set => mapset.customMapsetId === set.customMapsetId);
            this.pageItems.splice(index, 1);
            this.updateCurrentPage();
          }, error => {
            const msg = this.getErrorMsg(error);
            this.errorMsg = `There was an error deleting the selected Mapset: ${msg}`;
          }, () => {
            this.isProcessing = false;
          });
      });
  }

  public previousPage() {
    if (!this.hasPreviousPage()) { return; }

    this.currentPage--;
    this.updateCurrentPage();
  }

  public nextPage() {
    if (!this.hasNextPage()) { return; }

    this.currentPage++;
    this.updateCurrentPage();
  }

  public hasNextPage(): boolean {
    const nextPageStartIndex = (this.currentPage + 1) * this.perPage;
    return this.total > nextPageStartIndex;
  }

  public hasPreviousPage(): boolean {
    return this.currentPage > 0;
  }

  public clearSearchTerm() {
    this.searchTerm.setValue('');
  }

  public pageStart() {
    if (this.total === 0) { return 0; }

    return (this.currentPage * this.perPage) + 1;
  }

  public pageEnd() {
    const retVal = (this.currentPage * this.perPage) + this.pageItems.length;
    return retVal;
  }

  public toggleDefaultMapset(mapset: any) {
    if (!this.isFullAccess) {
      return;
    }

    const currentDefault = this.pageItems.find(defaultMapset => {
      return defaultMapset.defaultMapset;
    });

    if (mapset === currentDefault) {
      this.translate.get('views.mapsets-list.UnassignDefaultTitle').subscribe(res => {
        this.setDefaultConfirmModal.title = res;
      });
      this.translate.get('views.mapsets-list.UnassignDefaultBody').subscribe(res => {
        this.setDefaultConfirmModal.body = res;
      });
    } else {
      this.translate.get('views.mapsets-list.SetDefaultTitle').subscribe(res => {
        this.setDefaultConfirmModal.title = res;
      });
      this.translate.get('views.mapsets-list.ConfirmDefaultBody').subscribe(res => {
        this.setDefaultConfirmModal.body = res;
      });
    }

    this.setDefaultConfirmModal.open()
      .then(() => {
        if (currentDefault) {
          currentDefault.defaultMapset = false;
        }
        if (currentDefault !== mapset) {
          mapset.defaultMapset = !mapset.defaultMapset;
        }
        this.mapsetsService.setDefaultMapset(mapset)
          .subscribe(resp => {
            this.showHyperlinkError = false;
            this.errorMsg = null;
          },
            error => {
              if (currentDefault) {
                currentDefault.defaultMapset = true;
              }
              if (currentDefault !== mapset) {
                mapset.defaultMapset = !mapset.defaultMapset;
              }

              if (error.error!.errorCode === -7018){
                this.showHyperlinkError = true;
              } else {
                const msg = this.getErrorMsg(error);
                this.errorMsg = `There was an error selecting the Mapset as default: ${msg}`;
              }
            }
          );
      });
  }

  public updateCurrentPage() {
    this.isProcessing = true;

    this.getItems(this.searchTerm.value)
      .subscribe(itemsResponse => {
        this.pageItems = itemsResponse.data;
        this.mapRegions();
        this.total = itemsResponse.size;
        this.isProcessing = false;
      }, error => {
        this.isProcessing = false;
      });
  }

  public toggleSortDir(){
    if (this.sortDir === 'ASC'){
      this.sortDir = 'DESC'
    } else {
      this.sortDir = 'ASC'
    }
  }

  private getErrorMsg(resp) {
    let msg = resp.msg;
    if (resp.error) {
      if (resp.error.errors) {
        msg = resp.error.errors[0] ? resp.error.errors[0].message : msg;
      }
    }
    return msg;
  }

  private getItems(query: string) {
    return this.mapsetsService.getMapsets(query,
      { sortExpression: this.sortCol, sortDirection: this.sortDir, offset: this.currentPage * 10, limit: this.perPage });
  }

  private mapRegions(){
    this.pageItems.forEach(mapset => {
      mapset.regions = mapset.mapRegions.map(region => {
        return region.mapsetName;
      });
    });
  }
}
