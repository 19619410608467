
import {of as observableOf } from 'rxjs';

import {switchMap, tap, distinctUntilChanged, debounceTime} from 'rxjs/operators';
import { Component, Input, Output, EventEmitter, OnInit, OnChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

// alk
import { GroupAddModal } from './group-add-modal';
import { GroupAddPaginatedModal } from './group-add-paginated-modal';
import { GroupItem, GroupItemColumn } from './group-item';

@Component({
  selector: 'group-associations',
  styles: [`
  .deleted {
    text-decoration: line-through;
  }
  .modified-added {
    color: green;
  }
  .modified-removed {
    color: darkred;
  }
  .minheight {
    min-height: 25em;
  }
  `],
  templateUrl: './group-associations.component.html'
})
export class GroupAssociationsComponent implements OnInit, OnChanges {

  @ViewChild('addModal', { static: true }) addModal: GroupAddModal;

  @ViewChild('addPaginatedModal', { static: true }) addPaginatedModal: GroupAddPaginatedModal;

  // what are these groups of? if vehicles, use 'Vehicle' here so it will display as 'Vehicle Groups'
  @Input() entityLabel = '';

  // when true add and remove functionality is hidden
  @Input() readonly = false;

  @Input() downloadUrl: string;

  @Input() additionalTableClasses = '';
  @Output() itemSelected = new EventEmitter();

  // items which are initially already members of this group. set only once at startup
  // tslint:disable-next-line: variable-name
  private _initialItems: Array<GroupItem> = [];
  set initialItems(initialItems: Array<GroupItem>) {
    this._initialItems = initialItems;
    this.resetInitialData();
  }
  get initialItems(): Array<GroupItem> {
    return this._initialItems;
  }

  private _total: number = 0;
  set total(total: number) {
    this._total = total;
  }
  get total() {
    return this._total
  }

  // ngOnChanges event is NOT fired when set programatically outside of template so using setter
  // @Input() initialItems: Array<GroupItem> = [];

  // tslint:disable-next-line: variable-name
  private _columnDefinitions: Array<GroupItemColumn>;
  set columnDefinitions(columnDefinitions: Array<GroupItemColumn>) {
    this._columnDefinitions = columnDefinitions;
    this.addModal.columnDefinitions = columnDefinitions;
    this.addPaginatedModal.columnDefinitions = columnDefinitions;
  }

  set allPossibleItems(allPossibleItems: Array<GroupItem>) {
    // we only need to pass this along to the add modal
    if (this.entityLabel !== 'Mapsets'){
      this.addModal.allPossibleItems = allPossibleItems;
    }
  }

  // number of rows of groups to display per page
  @Input() displayPerPage = 10;

  // items added to the group from the Add button
  itemsPendingAddition: Array<GroupItem> = [];
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onItemsAdded = new EventEmitter();

  // items which were initially provided which are now removed (via the group by the X buttons in the list)
  // note that this does not include added items which are removed, those are just cleared
  itemsPendingRemoval: Array<GroupItem> = [];
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onItemsRemoved = new EventEmitter();

  // the final result item set (initialItems + itemsPendingAddition - itemsPendingRemoval)
  // to be clear, itemsPendingRemoval will NOT be included in this list, they will be removed from it!
  // pendingRemoval and pendingAddition properties will be undefined
  // so all initialItems (DB state) + all itemsPendingAddition (in memory not yet saved) - allitemsPendingRemoval (in memory not yet saved)
  resultItems: Array<GroupItem> = [];

  // all items potentially displayable (restricted by display pagination) in this control
  // to be clear, itemsPendingRemoval WILL be included in this list, they just have pendingRemoval = true
  allDisplayableItems: Array<GroupItem> = [];

  // all items (initial+added-removed) which match the search term filter
  filteredItems: Array<GroupItem> = [];

  // filteredItems which are currently displayed on this page (pagination)
  currentPageItems: Array<GroupItem> = [];

  totalFilteredItems = 0;
  currentPage = 0;
  isLoading: boolean;
  errorMessage: string;
  searchTerm: FormControl = new FormControl();
  entityHeading: string;


  constructor() { }

  ngOnChanges() {
    this.entityHeading = this.entityLabel; // todo: translate
  }

  ngOnInit() {
    this.resetInitialData();

    this.searchTerm.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => {
        this.isLoading = true;
        this.currentPage = 0;
      }),
      switchMap(searchTerm => this.filterResults(searchTerm)), )
      .subscribe(results => {
        this.filteredItems = results;
        this.totalFilteredItems = this.filteredItems.length;
        this.updateCurrentPage();
        this.isLoading = false;
      });
  }

  clearSearchTerm() {
    this.searchTerm.setValue('');
  }

  // user clicked to remove this item (pendingStates not yet modified upon entry of this function)
  remove(item: GroupItem) {
    // if item is in pendingAddition state, just splice it
    if (item.pendingAddition) {
      // splice it from allDisplayableItems
      const indexAll = this.allDisplayableItems.indexOf(item);
      if (indexAll >= 0) { this.allDisplayableItems.splice(indexAll, 1); }

      // remove it form itemsPendingAddition
      const indexAddedPending = this.itemsPendingAddition.indexOf(item);
      if (indexAddedPending >= 0) { this.itemsPendingAddition.splice(indexAddedPending, 1); }
    } else if (item.pendingRemoval) {
      // update the item in allDisplayableItems
      item.pendingRemoval = false;

      // remove from itemsPendingRemoval
      const index = this.itemsPendingRemoval.indexOf(item);
      if (index >= 0) { this.itemsPendingRemoval.splice(index, 1); }

    } else {
      // modify state in allDisplayableItems
      item.pendingRemoval = true;
      // add to itemsPendingRemoval
      this.itemsPendingRemoval.push(item);
    }

    this.filterResults(this.searchTerm.value)
      .subscribe(results => {
        this.filteredItems = results;
        this.totalFilteredItems = this.filteredItems.length;
        this.updateCurrentPage();
        this.isLoading = false;
      });
    this.buildResultsArray();

    // if we've removed all items on the current viewing page, back up a page as long as there are any items
    if (this.currentPageItems.length === 0 && this.allDisplayableItems.length > 0) {
      this.previousPage();
    }

    this.onItemsRemoved.emit(this.itemsPendingRemoval);
  }

  openAddModal() {
    // any initial or pending addition item to be excluded from the groups to add from this modal
    if (this.entityLabel === 'Mapsets'){
      this.addPaginatedModal.excludedGroups = this.allDisplayableItems;
      this.addPaginatedModal.open();
    } else {
      this.addModal.excludedGroups = this.allDisplayableItems;
      this.addModal.displayPerPage = 10;
      this.addModal.open();
    }
  }

  resetInitialData() {
    // reset all values
    this.totalFilteredItems = 0;
    this.filteredItems = [];
    this.itemsPendingAddition = [];
    this.itemsPendingRemoval = [];
    this.allDisplayableItems = [];
    this.currentPageItems = [];
    this.currentPage = 0;
    this.searchTerm.setValue('');

    // make sure any pending changes are cleared out
    this.initialItems.forEach(initialItem => {
      initialItem.pendingAddition = false;
      initialItem.pendingRemoval = false;
    });

    // allDisplayableItems and filteredItems start out the same as initialItems
    this.allDisplayableItems = this.initialItems.slice();
    this.filteredItems = this.initialItems.slice();

    this.sortItems(this.initialItems, 0, true);

    this.filterResults(this.searchTerm.value)
      .subscribe(results => {
        this.filteredItems = results;
        this.totalFilteredItems = this.filteredItems.length;
        this.updateCurrentPage();
        this.isLoading = false;
      });
    this.buildResultsArray();
  }

  addInitialItems(addedItems: Array<GroupItem>) {
    this.onAddedItems(addedItems);
  }

  onRowClicked(itemSelected: any) {
    this.itemSelected.emit(itemSelected);
  }

  // when the user has selected new added items from the popup
  onAddedItems(addedItems: Array<GroupItem>) {
    addedItems.forEach(item => {
      // items that are already added should be excluded from the Add Dialog already
      // but let's assert to make sure
      const indexAdded = this.itemsPendingAddition.indexOf(item);
      if (indexAdded >= 0) { console.error('Trying to add item which is already in itemsPendingAddition:', item); }
      const indexAll = this.allDisplayableItems.indexOf(item);
      if (indexAll >= 0) { console.error('Trying to add item which is already in allDisplayableItems:', item); }

      item.pendingAddition = true;
      this.itemsPendingAddition.push(item);
      this.allDisplayableItems.push(item); // resort allDisplayableItems?
    });

    this.sortItems(this.allDisplayableItems, 0, true);

    // re filter in case the newly added items don't match the search term filter
    this.filterResults(this.searchTerm.value)
      .subscribe(results => {
        this.filteredItems = results;
        this.totalFilteredItems = this.filteredItems.length;
        this.updateCurrentPage();
        this.isLoading = false;
      });

    this.buildResultsArray();
    this.onItemsAdded.emit(this.itemsPendingAddition);
  }

  nextPage() {
    if (!this.hasNextPage()) { return; }
    this.currentPage++;

    this.updateCurrentPage();
  }

  previousPage() {
    if (!this.hasPreviousPage()) { return; }
    this.currentPage--;

    this.updateCurrentPage();
  }

  hasNextPage() {
    const nextPageStartIndex = (this.currentPage + 1) * this.displayPerPage;
    return this.totalFilteredItems > nextPageStartIndex;
  }

  hasPreviousPage() {
    return this.currentPage > 0;
  }

  pageStart() {
    if (this.filteredItems.length === 0) { return 0; }
    return (this.currentPage * this.displayPerPage) + 1;
  }

  pageEnd() {
    let retVal = (this.currentPage * this.displayPerPage) + this.displayPerPage;
    if (retVal > this.totalFilteredItems) {
      retVal = this.totalFilteredItems;
    }
    return retVal;
  }

  updateCurrentPage() {
    this.isLoading = true;
    const startIndex = this.currentPage * this.displayPerPage;
    const endIndex = startIndex + this.displayPerPage;
    this.currentPageItems = this.filteredItems.slice(startIndex, endIndex);
    this.isLoading = false;
  }

  filterResults(searchTerm?: string) {
    if (!searchTerm) { return observableOf(this.allDisplayableItems); }
    searchTerm = searchTerm.toUpperCase();
    // TODO: right now this is filtering on all results and not honoring the GroupItemColumn.isFilterable setting
    return observableOf(this.allDisplayableItems.filter(item => {
      let found = 0;
      item.fields.forEach(field => {
        if (field != null && field.toString().toUpperCase().indexOf(searchTerm) >= 0) {
          found++;
        }
      });
      return (found > 0);
    }));
  }

  sortItems(items: Array<GroupItem>, sortColumnIndex: number, sortAscending = true) {

    const multiplyBy = sortAscending ? 1 : -1;
    items.sort((a, b) => {
      // first sort pending removals on top
      let aPendingRemoval = a.pendingRemoval;
      if (aPendingRemoval === undefined) {
        aPendingRemoval = false;
      }
      let bPendingRemoval = b.pendingRemoval;
      if (bPendingRemoval === undefined) {
        bPendingRemoval = false;
      }
      if (aPendingRemoval < bPendingRemoval) { return (1); }
      if (aPendingRemoval > bPendingRemoval) { return (-1); }

      // second sort pending removals on top
      let aPendingAddition = a.pendingAddition;
      if (aPendingAddition === undefined) {
        aPendingAddition = false;
      }
      let bPendingAddition = b.pendingAddition;
      if (bPendingAddition === undefined) {
        bPendingAddition = false;
      }
      if (aPendingAddition < bPendingAddition) { return (1); }
      if (aPendingAddition > bPendingAddition) { return (-1); }

      // lastly sort by the specified property
      let aa: any = null;
      let bb: any = null;
      if (typeof a[sortColumnIndex] === 'string' && typeof b[sortColumnIndex] === 'string') {
        aa = a.fields[sortColumnIndex].toUpperCase();
        bb = b.fields[sortColumnIndex].toUpperCase();
      } else {
        aa = a.fields[sortColumnIndex];
        bb = b.fields[sortColumnIndex];
      }
      if (aa < bb) { return (-1 * multiplyBy); }
      if (aa > bb) { return (1 * multiplyBy); }
      return 0;
    });
  }

  buildResultsArray() {
    // start with the initial items
    this.resultItems = this.initialItems.slice();

    // then remove all user removed items
    this.itemsPendingRemoval.forEach(removedItem => {
      const idx: number = this.resultItems.map(existingItem => existingItem.id).indexOf(removedItem.id);
      this.resultItems.splice(idx, 1);
    });

    // then add the added items
    this.itemsPendingAddition.forEach(addedItem => { this.resultItems.push(addedItem); });
  }

}
