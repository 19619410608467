<div class="device-list-panel">
  <div class="panel-heading">
    <h3 class="panel-title pull-left">
      Users
      <span class="ico ico-circle-notch ico-spin" *ngIf="isProcessing"></span>
    </h3>

    <div class="pull-right">

    </div>

    <div class="clearfix"></div>
  </div>

  <div class="panel-body">
    <div class="filter-panel">
      <label>
        <span [innerHtml]="'views.admin-users.Filter' | translate"></span>
        <input type="text" class="form-control input-sm" placeholder="" [formControl]="searchTerm" (keydown.escape)="clearSearchTerm()"/>
      </label>
            <button class="btn btn-default btn-sm pull-right filter-fix" type="button" (click)="adminUserEditModal.open(true)">
        <span class="ico ico-plus"></span>
        <span [innerHtml]="'common.New' | translate"></span>
      </button>
    </div>

    <div *ngIf="userAdded==true" class="alert alert-success" role="alert">
      <strong>New user successfully added</strong>
    </div>
    <div *ngIf="errorMsg" class="row alert alert-danger" role="alert">
      <label [innerHtml]="'common.Error' | translate"></label>
      <span>{{errorMsg}}</span>
    </div>

    <table class="table table-striped table-hover list-table">
      <thead>
        <tr>
          <th (click)="sortAsc=!sortAsc;sortCol='fullName';sortString('fullName', sortAsc)" class="sortable-column admin-user-name-col">
            <span [innerHtml]="'views.admin-users-list.Name' | translate"></span>
            <span class="column-arrows">
              <span *ngIf="sortCol=='fullName'" class="glyphicon" [ngClass]="{'glyphicon-triangle-top': sortAsc, 'glyphicon-triangle-bottom': !sortAsc}"></span>
            </span>
          </th>
          <!--<th (click)="sortString('emailAddress', sortAsc);sortAsc=!sortAsc;sortCol='emailAddress'" class="sortable-column">
            <span [innerHtml]="'views.admin-users-list.EmailAddress' | translate"></span>
            <span class="column-arrows">
              <span *ngIf="sortCol=='emailAddress'" class="glyphicon" [ngClass]="{'glyphicon-triangle-top': !sortAsc, 'glyphicon-triangle-bottom': sortAsc}"></span>
            </span>
          </th>-->
          <th *ngIf = 'canViewVehicleGroups' (click)="sortAsc=!sortAsc;sortCol='vehicleGroupsAbbreviated';sortString('vehicleGroupsAbbreviated', sortAsc)" class="sortable-column admin-user-groups-col">
            <span [innerHtml]="'views.admin-users-list.VehicleGroups' | translate"></span>
            <span class="column-arrows">
              <span *ngIf="sortCol=='vehicleGroupsAbbreviated'" class="glyphicon" [ngClass]="{'glyphicon-triangle-top': sortAsc, 'glyphicon-triangle-bottom': !sortAsc}"></span>
            </span>
          </th>
          <th *ngIf = 'canViewDriverGroups' (click)="sortAsc=!sortAsc;sortCol='driverGroupsAbbreviated';sortString('driverGroupsAbbreviated', sortAsc)" class="sortable-column admin-user-groups-col">
            <span [innerHtml]="'views.admin-users-list.DriverGroups' | translate"></span>
            <span class="column-arrows">
              <span *ngIf="sortCol=='driverGroupsAbbreviated'" class="glyphicon" [ngClass]="{'glyphicon-triangle-top': sortAsc, 'glyphicon-triangle-bottom': !sortAsc}"></span>
            </span>
          </th>
          <th (click)="sortAsc=!sortAsc;sortCol='roleDisplay';sortString('roleDisplay', sortAsc)" class="sortable-column admin-user-role-col">
            <span [innerHtml]="'views.admin-users-list.Role' | translate"></span>
            <span class="column-arrows">
              <span *ngIf="sortCol=='roleDisplay'" class="glyphicon" [ngClass]="{'glyphicon-triangle-top': sortAsc, 'glyphicon-triangle-bottom': !sortAsc}"></span>
            </span>
          </th>
          <th (click)="sortAsc=!sortAsc;sortCol='state';sortString('state', sortAsc)" class="sortable-column admin-user-enabled-col">
            <span [innerHtml]="'views.admin-users-list.Enabled' | translate"></span>
            <span class="column-arrows">
              <span *ngIf="sortCol=='state'" class="glyphicon" [ngClass]="{'glyphicon-triangle-top': sortAsc, 'glyphicon-triangle-bottom': !sortAsc}"></span>
            </span>
          </th>
          <th style="width:150px;"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of pageItems; let i = index" class="clickable-row">
          <td (click)="editUser(user)" class="admin-user-name-col">
            <span [innerHtml]="user.fullName | alkHighlight:searchTerm.value" title="{{user.fullName}}"></span> <br/>
            <span [innerHtml]="user.emailAddress | alkHighlight:searchTerm.value" class="list-email-address" title="{{user.emailAddress}}"></span>
          </td>
          <td *ngIf = 'canViewVehicleGroups' (click)="editUser(user)" class="admin-user-groups-col">
            <span [innerHtml]="user.vehicleGroupsAbbreviated | alkHighlight:searchTerm.value" [title]="getToolTipForGroups(user.vehicleGroups)"></span>
          </td>
          <td *ngIf = 'canViewDriverGroups' (click)="editUser(user)" class="admin-user-groups-col">
            <span [innerHtml]="user.driverGroupsAbbreviated | alkHighlight:searchTerm.value" [title]="getToolTipForGroups(user.driverGroups)"></span>
          </td>
          <td (click)="editUser(user)" class="admin-user-role-col">
            <span [innerHtml]="user.roleDisplay | alkHighlight:searchTerm.value"></span>
          </td>
          <td class="admin-user-enabled-col">
            <input type="checkbox" #{{user.userId}} [ngModel]="user.enabled" (change)="onUserEnabledChanged(user)" *ngIf="!isCurrentUser(user)" />
          </td>
          <td class="text-right">
            <button type="button" (click)="editUser(user)" title="{{'common.Edit' | translate}}" *ngIf="!isCurrentUser(user)" class="btn btn-default">
              <span class="glyphicon glyphicon-edit"></span>
            </button>
            <button  type="button" (click)="replaceUser(user)" title="{{'common.Replace' | translate}}" *ngIf="!isCurrentUser(user) && !isInRole('support')" class="btn btn-default">
              <span class="glyphicon glyphicon-link"></span>
            </button>
            <button type="button" (click)="deleteUser(user)" title="{{'common.Delete' | translate}}" *ngIf="!isCurrentUser(user)" class="btn btn-default">
              <span class="ico ico-trash"></span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="pagination-panel">
      <div class="pull-left grid-details">
        {{ 'common.DisplayOf' | translate:{pageStartNumber:pageStart(), pageEndNumber:pageEnd(), total:total} }}
      </div>
      <div class="pull-right">
        <button type="button" class="btn btn-default" (click)="previousPage()" [disabled]="!hasPreviousPage()">
          <span class="glyphicon glyphicon-menu-left"></span>
          <span [innerHtml]="'common.Previous' | translate"></span>
        </button>
        <button type="button" class="btn btn-default" (click)="nextPage()" [disabled]="!hasNextPage()">
          <span [innerHtml]="'common.Next' | translate"></span>
          <span class="glyphicon glyphicon-menu-right"></span>
        </button>
      </div>
    </div>

  </div>
</div>

<confirmation-modal #confirmModal title="Delete User" body="You are deleting this user. Are you sure?"></confirmation-modal>
<admin-user-edit-modal #adminUserEditModal (userChanged)="onUserUpdated($event)"></admin-user-edit-modal>
<admin-user-replace-modal #adminUserReplaceModal (userChanged)="onUserReplaced($event)"></admin-user-replace-modal>
