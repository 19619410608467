
import {of as observableOf,  Observable } from 'rxjs';

import {switchMap, distinctUntilChanged, debounceTime} from 'rxjs/operators';
// angular components
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl} from '@angular/forms';

// alk
import { VehicleGroupService } from './../shared';
import { ConfirmationModal } from './../../shared';
import { VehicleGroupPartnerPermissions } from '../vehicle-group-partner-permissions';

@Component({
  providers: [VehicleGroupService, VehicleGroupPartnerPermissions],
  templateUrl: './vehicle-group-list.component.html'
})
// tslint:disable-next-line: component-class-suffix
export class VehicleGroupList implements OnInit {

  @ViewChild('confirmModal', { static: true }) confirmModal: ConfirmationModal;

  public isProcessing = false;
  public allVehicleGroups: Array<any> = [];
  public filteredVehicleGroups: Array<any> = [];
  public sortAsc = true;
  public sortCol = 'name';
  public searchTerm = new FormControl();
  public selectedGroupIds: Array<any> = [];
  public errorMsg: string;
  public currentPage = 0;
  public perPage = 10;
  public pageItems: Array<any> = [];
  public activePartnerAccount$: Observable<any>;
  vgPermissions: VehicleGroupPartnerPermissions;

  constructor(
    private vehicleGroupService: VehicleGroupService,
    private vehicleGroupPartnerPermissions: VehicleGroupPartnerPermissions) {
      this.vgPermissions = this.vehicleGroupPartnerPermissions;
    }

  public ngOnInit() {
    this.isProcessing = true;

    this.loadVehicleGroups();

    // can't get this working, seems to fail to subscribe to all events after the first one
    // this.activePartnerAccount$ = this.userContext.activePartnerAccount;
    // this.activePartnerAccount$.subscribe( (activePartnerAccount) => {
    //   //console.log('in active partner subscribe');
    //   this.loadVehicleGroups();
    // }, err => {
    //   console.log('error in active partner subscription:', err);
    // });

  }

  public loadVehicleGroups() {
    this.searchTerm.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => this.filterAll(term)), )
      .subscribe(items => {
        this.filteredVehicleGroups = items;

        this.currentPage = 0;
        this.setCurrentPageItems();
      });

    this.vehicleGroupService.getGroups()
      .subscribe(groups => {
        this.allVehicleGroups = groups;
        this.filteredVehicleGroups = this.allVehicleGroups;

        this.sortString(this.sortCol, this.sortAsc);
      }, error => {
        this.errorMsg = 'There was an error retrieving vehicle groups: ' + error.message;
      }, () => {
        this.isProcessing = false;
      });
  }

  public previousPage() {
    if (!this.hasPreviousPage()) { return; }

    this.currentPage--;
    this.setCurrentPageItems();
  }

  public nextPage() {
    if (!this.hasNextPage()) { return; }

    this.currentPage++;
    this.setCurrentPageItems();
  }

  public deleteGroup(group) {
    this.confirmModal.open()
      .then(() => {
        // tslint:disable-next-line:no-string-literal
        group['isProcessing'] = true;

        this.vehicleGroupService.deleteGroup(group.id)
          .subscribe(c => {
            // tslint:disable-next-line:no-string-literal
            group['isProcessing'] = false;
            // remove the group from the list and get the current page of items
            const index = this.getGroupIndex(this.allVehicleGroups, group);

            // if we didn't find the group index, don't do anything.  Otherwise, remove the item from the list
            if (index >= 0) {
              this.allVehicleGroups.splice(index, 1);
              this.filteredVehicleGroups = this.allVehicleGroups;
              this.setCurrentPageItems();
            }

          }, (error) => {
            // tslint:disable-next-line:no-string-literal
            group['isProcessing'] = false;
            this.errorMsg = 'Error while deleting vehicle group';
          });
      });
  }

  public hasNextPage(): boolean {
    const nextPageStartIndex = (this.currentPage + 1) * this.perPage;
    return this.filteredVehicleGroups.length > nextPageStartIndex;
  }

  public hasPreviousPage(): boolean {
    return this.currentPage > 0;
  }

  public clearSearchTerm() {
    this.searchTerm.setValue('');
  }

  public sortString(column: string, asc: boolean) {
    const multiplyBy = asc ? 1 : -1;

    this.filteredVehicleGroups.sort((a, b) => {
      const aUpperCase = a[column].toUpperCase();
      const bUpperCase = b[column].toUpperCase();

      if (aUpperCase < bUpperCase) { return (-1 * multiplyBy); }
      if (aUpperCase > bUpperCase) { return (1 * multiplyBy); }
      return 0;
    });

    this.setCurrentPageItems();
  }

  public sortNumber(column: string, asc: boolean) {
    const multiplyBy = asc ? 1 : -1;

    this.filteredVehicleGroups.sort((a, b) => {
      return (a[column] - b[column]) * multiplyBy;
    });

    this.setCurrentPageItems();
  }

  public pageStart() {
    if (this.filteredVehicleGroups.length === 0) { return 0; }
    return (this.currentPage * this.perPage) + 1;
  }

  public pageEnd() {
    let retVal = (this.currentPage * this.perPage) + this.perPage;
    if (retVal > this.filteredVehicleGroups.length) {
      retVal = this.filteredVehicleGroups.length;
    }
    return retVal;
  }

  private getGroupIndex(groups, group): number {
    for (let i = 0; i < groups.length; i++) {
      if (groups[i].id === group.id) {
        return i;
      }
    }

    return -1;
  }

  private setCurrentPageItems() {
    const startPageIndex = this.currentPage * this.perPage;
    const endPageIndex = startPageIndex + this.perPage;

    this.pageItems = this.filteredVehicleGroups.slice(startPageIndex, endPageIndex);
  }

  private filter(groups: Array<any>, searchTerm: string) {
    searchTerm = searchTerm.toUpperCase();

    return groups.filter(group => {
      if (group.name != null && group.name.toUpperCase().indexOf(searchTerm) !== -1) { return true; }
      if (group.externalId != null && group.externalId.toUpperCase().indexOf(searchTerm) !== -1) { return true; }

      return false;
    });
  }

  private filterAll(searchTerm?: string) {
    let results = this.allVehicleGroups;

    if (searchTerm) {
      results = this.filter(results, searchTerm);
    }

    return observableOf(results);
  }

}
