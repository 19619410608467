import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserContextService } from './../../../alk-components/authentication';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(private userContext: UserContextService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log(request.url);

    if (request.url.match('/assets/')) { // dont intercept
      return next.handle(request);
    }

    const httpOptions = {
      headers: request.headers
    };

    if (!httpOptions.headers.get('Content-Type')) {
      httpOptions.headers = httpOptions.headers.set('Content-Type', 'application/json');
    }

    const user = this.userContext.getUser();

    // when requesting partner accounts, we want that to be in the context of the actual authenticated user
    const isRequestForPartnerAccounts = request.url.match('/account/partneraccounts');
    if (user && user.currentPartnerAccountId && !isRequestForPartnerAccounts) {
      httpOptions.headers = httpOptions.headers.set('ALK-TargetAccount', user.currentPartnerAccountId);
    }

    if (user && user.authToken) {
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer ${user.authToken}`);
    }

    return next.handle(request.clone(httpOptions));
  }
}
