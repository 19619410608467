import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// alk components
import { UserContextService } from './../../../alk-components/authentication';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
// tslint:disable-next-line: component-class-suffix
export class AppHeader implements OnInit {
  user: any;
  // to prevent an upstream error from marking these as false and failing to complete
  // all code in the constructor and causing these sections to be inaccessible
  driverGroupsVisible = true;
  vehicleGroupsVisible = true;
  isInApp = true;
  isNavVisible = true;
  isAcceptTerms = false;
  isTermsPage = false;
  isAzure = false;
  isVehicleViewEnabled = true;

  @ViewChild('partnerAccount', { static: false }) partnerAccount: HTMLSelectElement;

  constructor(
    public userContext: UserContextService,
    private router: Router) {}

  ngOnInit() {
    this.user = this.userContext.user;
    this.setControlsVisibility();
    this.isInApp = this.router.url === '/partnerAccounts' ? false : true;
    this.setNavVisibility();
  }

  setNavVisibility() {
    this.router.events.subscribe((event: NavigationEnd) => {
      if (event.url) {
        this.isAcceptTerms = (event.url.indexOf('/accept-terms') > -1) ? true : false;
        this.isTermsPage = this.isAcceptTerms || event.url === '/terms';
        this.isAzure = (event.url.indexOf('/azure') > -1) ? true : false;
        this.isNavVisible = (event.url === '/terms' || event.url.indexOf('/accept-terms') > -1) || this.isAzure ? false : true;
      }
});
}
  navigateTo(page: string) {
    this.router.navigate([page]);
  }

  currentState(state) {
    this.isInApp = state === 'app' ? true : false;
  }

  isInPrimaryAccount() {
    const user = this.userContext.getUser();
    return user.accountId === user.currentPartnerAccountId;
  }

  setControlsVisibility() {
    this.userContext.user.subscribe(() => {
      if (this.userContext.isAuthenticated()) {

        const partnerActivities = this.userContext.getUser().partner.partnerActivities;
        this.driverGroupsVisible = partnerActivities.canViewDriverGroups;
        this.vehicleGroupsVisible = partnerActivities.canViewVehicleGroups;
        this.isVehicleViewEnabled = partnerActivities.canViewVehicles;
      }
    });
  }

  logout() {
    this.router.navigate(["tid/logout"]);
  }
}
