<ng-container *ngIf="!loading">
  <div
   [ngClass]="{'checkbox-row-container': region == na, 'checkbox-row-container-eu': region== eu}">
    <div class="border-right" id="side-selector"
      [ngClass]="{'side-selector': region == na, 'region': region == na, 'eu-parent-panel': region == eu, 'region-eu': region == eu}">
      <div [ngClass]="{'disabled': maxCheckboxesSelected}"
        class="custom-control custom-checkbox region-checkbox parent-checkbox master-checkbox"
        (click)="masterClicked(checkboxModel)">
        <span class="custom-control-input ico"
          [ngClass]="{'ico-checkbox-checked': checkboxModel.checked, 'ico-checkbox-unchecked': !checkboxModel?.checked}"></span>
        <label class="custom-control-label">All {{checkboxModel.name}}</label>
      </div>
      <ng-container *ngFor="let panRegion of checkboxModel?.children">
        <div [ngClass]="{'disabled': checkboxModel?.checked || (!panRegion.checked && maxCheckboxesSelected)}"
          class="custom-control custom-checkbox child-checkbox region-checkbox"
          (click)="middleNodeClicked([checkboxModel], panRegion)">
          <span class="custom-control-input ico"
            [ngClass]="{'ico-checkbox-checked': panRegion.checked, 'ico-checkbox-unchecked': !panRegion.checked}"></span>
          <label class="custom-control-label">{{panRegion.name}}</label>
          <span class="checkbox-description" *ngIf="region == eu">{{panRegion.description}}</span>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="region == na">
      <ng-container *ngFor="let panRegion of checkboxModel.children">
        <ng-container *ngFor="let fullRegion of panRegion.children">
          <div class="region border-right">
            <div [ngClass]="{'disabled': panRegion.checked || (!fullRegion.checked && maxCheckboxesSelected)}"
              class="custom-control custom-checkbox region-checkbox parent-checkbox"
              (click)="middleNodeClicked([panRegion, checkboxModel], fullRegion)">
              <span class="custom-control-input ico"
                [ngClass]="{'ico-checkbox-checked': fullRegion.checked, 'ico-checkbox-unchecked': !fullRegion.checked}"></span>
              <label class="custom-control-label">{{fullRegion.name}}</label>
            </div>
            <ng-container *ngFor="let subRegion of fullRegion.children">
              <div [ngClass]="{'disabled': fullRegion.checked || (!subRegion.checked && maxCheckboxesSelected)}"
                class="custom-control custom-checkbox child-checkbox region-checkbox"
                (click)="childClicked([fullRegion, panRegion, checkboxModel], subRegion)">
                <span class="custom-control-input ico"
                  [ngClass]="{'ico-checkbox-checked': subRegion.checked, 'ico-checkbox-unchecked': !subRegion.checked}"></span>
                <label class="custom-control-label">{{subRegion.name}}</label>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="region == eu">
      <div class="region-eu col-auto border-right">
        <ng-container *ngFor="let subRegion of descriptionCheckboxes">
          <!--index 0 is child checkbox, index 1 is immediate parent checkbox-->
          <div [ngClass]="{'disabled': subRegion[1].checked || (!subRegion[0].checked && maxCheckboxesSelected)}"
            class="custom-control custom-checkbox child-checkbox region-checkbox"
            (click)="childClicked([subRegion[1], checkboxModel], subRegion[0])">
            <span class="custom-control-input ico"
              [ngClass]="{'ico-checkbox-checked': subRegion[0].checked, 'ico-checkbox-unchecked': !subRegion[0].checked}"></span>
            <label class="custom-control-label">{{subRegion[0].name}}</label>
            <span *ngIf="subRegion[0].description.split(',').length > 1"
              class="checkbox-description">{{subRegion[0].description}}</span>
          </div>
        </ng-container>
      </div>
      <div class="region-eu col-auto border-right">
        <ng-container *ngFor="let subRegion of nonDescriptionCheckboxes">
          <div [ngClass]="{'disabled': subRegion[1].checked || (!subRegion[0].checked && maxCheckboxesSelected)}"
            class="custom-control custom-checkbox child-checkbox region-checkbox"
            (click)="childClicked([subRegion[1], checkboxModel], subRegion[0])">
            <span class="custom-control-input ico"
              [ngClass]="{'ico-checkbox-checked': subRegion[0].checked, 'ico-checkbox-unchecked': !subRegion[0].checked}"></span>
            <label class="custom-control-label">{{subRegion[0].name}}</label>
            <span *ngIf="subRegion[0].description.split(',').length > 1"
              class="checkbox-description">{{subRegion[0].description}}</span>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>
