export class VehicleMeasurements {

    // Note min-maxes for Auto have been set using min from LightDuty and max from Truck
    // Auto profiles have no restrictions

    private truckMinMaxTotalLengthDimensions = {
        NA: {
            Metric: {
                Truck:
                    { min: 2.44 * 100, max: 21.34 * 100 },
                MidsizeTruck:
                    { min: 2.44 * 100, max: 12.19 * 100 },
                LightDuty:
                    { min: 2.44 * 100, max: 7.92 * 100 },
                Auto:
                    { min: 0, max: 21.34 * 100 },
                Bus:
                    { min: 2.44 * 100, max: 13.72 * 100 },

            },
            English: {
                Truck:
                    { min: 8 * 12, max: 70 * 12 },
                MidsizeTruck:
                    { min: 8 * 12, max: 40 * 12 },
                LightDuty:
                    { min: 8 * 12, max: 26 * 12 },
                Auto:
                    { min: 0, max: 70 * 12 },
                Bus:
                    { min: 8 * 12, max: 45 * 12 }
            }
        },
        EU: {
            Metric: {
                Truck:
                    { min: 0, max: 28 * 100 },
                MidsizeTruck:
                    { min: 0, max: 10 * 100 },
                LightDuty:
                    { min: 0, max: 10 * 100 },
                Auto:
                    { min: 0, max: 28 * 100 },
                Bus:
                    { min: 2.44 * 100, max: 13.72 * 100 },

            },
            English: {
                Truck:
                    { min: 0, max: 91.8 * 12 },
                MidsizeTruck:
                    { min: 0, max: 32.80 * 12 },
                LightDuty:
                    { min: 0, max: 32.80 * 12 },
                Auto:
                    { min: 0, max: 91.8 * 12 },
                Bus:
                    { min: 8 * 12, max: 45 * 12 }
            }
        },
    };

    private truckMinMaxTotalHeightDimensions = {
        NA: {
            Metric: {
                Truck:
                    { min: (1 * 100) + 52, max: (4 * 100) + 57 },
                MidsizeTruck:
                    { min: (1 * 100) + 52, max: (4 * 100) + 11 },
                LightDuty:
                    { min: (1 * 100) + 52, max: (2 * 100) + 13 },
                Auto:
                    { min: (1 * 100) + 52, max: (4 * 100) + 57 },
                Bus:
                    { min: (1 * 100) + 52, max: (3 * 100) + 63 },
            },
            English: {
                Truck:
                    { min: 5 * 12, max: 15 * 12 },
                MidsizeTruck:
                    { min: 5 * 12, max: 13 * 12 + 6 },
                LightDuty:
                    { min: 5 * 12, max: 7 * 12 },
                Auto:
                    { min: 5 * 12, max: 7 * 12 },
                Bus:
                    { min: 5 * 12, max: 11 * 12 + 9 }
            }
        },
        EU: {
            Metric: {
                Truck:
                    { min: 0, max: 5 * 100 },
                MidsizeTruck:
                    { min: 0, max: 3.8 * 100 },
                LightDuty:
                    { min: 0, max: 3.8 * 100 },
                Auto:
                    { min: 0, max: 5 * 100 },
                Bus:
                    { min: (1 * 100) + 52, max: (3 * 100) + 63 },
            },
            English: {
                Truck:
                    { min: 0, max: (16 * 12) + 5 },
                MidsizeTruck:
                    { min: 0, max: (12 * 12) + 6 },
                LightDuty:
                    { min: 0, max: (12 * 12) + 6 },
                Auto:
                    { min: 0, max: (16 * 12) + 5 },
                Bus:
                    { min: 5 * 12, max: 11 * 12 + 9 }
            }
        },
    };

    private truckMinMaxTotalWidthDimensions = {
        NA: {
            Metric: {
                Truck:
                    { min: 1.52, max: 2.59 },
                MidsizeTruck:
                    { min: 1.52, max: 2.44 },
                LightDuty:
                    { min: 1.52, max: 2.44 },
                Auto:
                    { min: 1.52, max: 2.59 },
                Bus:
                    { min: 1.52, max: 2.59 }
            },
            English: {
                Truck:
                    { min: 60, max: 102 },
                MidsizeTruck:
                    { min: 60, max: 96 },
                LightDuty:
                    { min: 60, max: 96 },
                Auto:
                    { min: 60, max: 102 },
                Bus:
                    { min: 60, max: 102 }
            }
        },
        EU: {
            Metric: {
                Truck:
                    { min: 0, max: 3 },
                MidsizeTruck:
                    { min: 0, max: 2.5 },
                LightDuty:
                    { min: 0, max: 2.5 },
                Auto:
                    { min: 0, max: 3 },
                Bus:
                    { min: 1.52, max: 2.59 }
            },
            English: {
                Truck:
                    { min: 0, max: 118 },
                MidsizeTruck:
                    { min: 0, max: 98 },
                LightDuty:
                    { min: 0, max: 98 },
                Auto:
                    { min: 0, max: 118 },
                Bus:
                    { min: 60, max: 102 }
            }
        },
    };

    private truckMinMaxTotalWeightDimensions = {
        NA: {
            Metric: {
                Truck:
                    { min: 26000, max: 156470 },
                MidsizeTruck:
                    { min: 10000, max: 26000 },
                LightDuty:
                    { min: 1500, max: 10000 },
                Auto:
                    { min: 1500, max: 156470 },
                Bus:
                    { min: 3500, max: 45000 }
            },
            English: {
                Truck:
                    { min: 26000, max: 156470 },
                MidsizeTruck:
                    { min: 10000, max: 26000 },
                LightDuty:
                    { min: 1500, max: 10000 },
                Auto:
                    { min: 1500, max: 156470 },
                Bus:
                    { min: 3500, max: 45000 }

            }
        },
        EU: {
            Metric: {
                Truck:
                    { min: 0, max: 60 },
                MidsizeTruck:
                    { min: 0, max: 16 },
                LightDuty:
                    { min: 0, max: 7.5 },
                Auto:
                    { min: 0, max: 60 },
                Bus:
                    { min: 1.59, max: 20.41 }
            },
            English: {
                Truck:
                    { min: 0, max: 60 },
                MidsizeTruck:
                    { min: 0, max: 16 },
                LightDuty:
                    { min: 0, max: 7.5 },
                Auto:
                    { min: 0, max: 60 },
                Bus:
                    { min: 1.59, max: 20.41 }
            }
        },
    };

    private truckMinMaxTotalWeightPerAxleDimensions = {
        NA: {
            Metric: {
                Truck:
                    { min: 800, max: 80000 },
                MidsizeTruck:
                    { min: 800, max: 26000 },
                LightDuty:
                    { min: 0, max: 7500 },
                Auto:
                    { min: 0, max: 80000 },
                Bus:
                    { min: 800, max: 24000 }
            },
            English: {
                Truck:
                    { min: 800, max: 80000 },
                MidsizeTruck:
                    { min: 800, max: 26000 },
                LightDuty:
                    { min: 0, max: 7500 },
                Auto:
                    { min: 0, max: 80000 },
                Bus:
                    { min: 800, max: 24000 }
            }
        },
        EU: {
            Metric: {
                Truck:
                    { min: 0, max: 13 },
                MidsizeTruck:
                    { min: 0, max: 11.5 },
                LightDuty:
                    { min: 0, max: 7.5 },
                Auto:
                    { min: 0, max: 13 },
                Bus:
                    { min: 0.36, max: 10.9 }
            },
            English: {
                Truck:
                    { min: 0, max: 13 },
                MidsizeTruck:
                    { min: 0, max: 11.5 },
                LightDuty:
                    { min: 0, max: 7.5 },
                Auto:
                    { min: 0, max: 13 },
                Bus:
                    { min: 0.36, max: 10.9 }
            }
        },
    };

    public getLengthLimits(inputHeight, { vehicleType = 'Truck', unitType = 'English', region = 'NA' }) {
        const displayLabel = unitType === 'English' ? 'ft' : 'm';
        const divider = unitType === 'English' ? 12 : 100;
        const limits = this.truckMinMaxTotalLengthDimensions[region][unitType][vehicleType];

        if (inputHeight > Math.round(limits.max) || inputHeight < limits.min) {
            const minString = limits.min / divider + displayLabel;
            const maxString = (limits.max / divider) + displayLabel;
            return { totalLength: { min: minString, max: maxString } };
        }

        return null;
    }

    public getHeightLimits(inputHeight, { vehicleType = 'Truck', unitType = 'English', region = 'NA' }) {
        const displayLabel = unitType === 'English' ? { wholeUnit: 'ft', decimalUnit: 'in' } : { wholeUnit: 'm', decimalUnit: 'cm' };
        const divider = unitType === 'English' ? 12 : 100;
        const limits = this.truckMinMaxTotalHeightDimensions[region][unitType][vehicleType];

        if (inputHeight > limits.max || inputHeight < limits.min) {
            const decimalMinString = limits.min % divider > 0 ? ' ' + limits.min % divider + displayLabel.decimalUnit : '';
            const decimalMaxString = limits.max % divider > 0 ? ' ' + limits.max % divider + displayLabel.decimalUnit : '';
            const minString = Math.floor(limits.min / divider) + displayLabel.wholeUnit + decimalMinString;
            const maxString = Math.floor(limits.max / divider) + displayLabel.wholeUnit + decimalMaxString;
            return { maxHeight: { min: minString, max: maxString } };
        }

        return null;
    }

    public getWidthLimits(inputWidth, { vehicleType = 'Truck', unitType = 'English', region = 'NA' }) {
        const displayLabel = unitType === 'English' ? 'in' : 'm';
        const limits = this.truckMinMaxTotalWidthDimensions[region][unitType][vehicleType];

        if (inputWidth > limits.max || inputWidth < limits.min) {
            return { maxWidth: { min: limits.min + ' ' + displayLabel, max: limits.max + ' ' + displayLabel } };
        }

        return null;
    }

    public getWeightLimits(inputWeight, { vehicleType = 'Truck', unitType = 'English', region = 'NA' }) {
        const displayLabel = region === 'NA' ? 'lbs' : 't';
        const limits = this.truckMinMaxTotalWeightDimensions[region][unitType][vehicleType];

        if (inputWeight > limits.max || inputWeight < limits.min) {
            return { totalWeight: { min: limits.min + ' ' + displayLabel, max: limits.max + ' ' + displayLabel } };
        }

        return null;
    }

    public getWeightPerAxleLimits(inputWeightPerAxle, { vehicleType = 'Truck', unitType = 'English', region = 'NA' }) {
        const displayLabel = region === 'NA' ? 'lbs' : 't';
        const limits = this.truckMinMaxTotalWeightPerAxleDimensions[region][unitType][vehicleType];

        if (inputWeightPerAxle > limits.max || inputWeightPerAxle < limits.min) {
            return { totalWeightPerAxle: { min: limits.min + ' ' + displayLabel, max: limits.max + ' ' + displayLabel } };
        }

        return null;
    }
}
