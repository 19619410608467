
import {from as observableFrom,  Observable } from 'rxjs';
// angular components
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

// 3rd party components

// app components
import { ConfirmationModal } from './../shared';
import { UserContextService } from './../../alk-components/authentication';
import { AccountUserManagementService } from './../../alk-components/account-user-management';
import { alkValidEmailAddress } from './../../alk-components/common-validation';

@Component({
  selector: 'user-settings',
  providers: [AccountUserManagementService],
  templateUrl: './user-settings.component.html'
})
// tslint:disable-next-line: component-class-suffix
export class UserSettings implements OnInit {

  public isProcessing = false;
  public errMsg = '';
  public saved = false;

  public form: FormGroup;

  isInitializing = false;

  @ViewChild('confirmModal', { static: true }) confirmModal: ConfirmationModal;

  constructor(
    private userContext: UserContextService,
    private userService: AccountUserManagementService,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      userId: [],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      emailAddress: ['', alkValidEmailAddress]
    });
  }

  public loadForm(attributes) {
    for (const attr in attributes) {
      if (!attributes.hasOwnProperty(attr)) { continue; }

      const control =  this.form.controls[attr] as FormControl;
      if (!control) { continue; }

      control.setValue(attributes[attr]);
    }
  }

  public canDeactivate(): Observable<boolean> | boolean {
    if (!this.form.dirty) { return true; }

    return observableFrom(this.confirmModal.open());
  }

  ngOnInit() {
    this.userService.getUserProfile(this.userContext.getUser().userId)
      .subscribe(u => { this.loadForm(u); }, err => this.errMsg = 'Error loading user information');
  }

  onSubmit() {
    if (!this.form.valid) { return; }

    this.isProcessing = true;

    this.userService.updateUserProfile(this.form.value)
      .subscribe(result => {
        this.isProcessing = false;
        if (result.success) {
          // also update the current user context
          // however we can't call userContext.setUser and give it this user because that user was populated
          // from the AuthWS, which has different fields from this user, so just update the changed fields
          // this.userContext.setUser(this.user);
          const currentUser = this.userContext.getUser();
          currentUser.firstName = this.form.value.firstName;
          currentUser.lastName = this.form.value.lastName;
          currentUser.userName = this.form.value.emailAddress;
          this.userContext.setUser(currentUser);
          this.saved = true;
          this.form.markAsPristine();
          setTimeout(() => this.saved = false, 3000);
        } else {
          this.errMsg = result.errMsg;
        }
      }, error => {
        this.errMsg = error.errMsg;
      });
  }

}
