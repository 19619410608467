<div bsModal #modal="bs-modal" [config]="{ keyboard: false, backdrop: 'static' }" class="modal fade" tabindex="-1">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="modal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">
          <span>{{ entityHeading }}</span>
        </h4>
      </div>
      <div class="modal-body embedded-list-panel">
        <div class="alert alert-warning" *ngIf="allPossibleItems.length === 0">
          <span id="no-vehicles-msg" [innerHtml]="'views.group-associations.NoneAvailableToAssign' | translate: {entityLabel: entityLabel}"></span>
        </div>

        <div class="alert alert-warning" *ngIf="allPossibleItems.length > 0 && allAvailableItems.length === 0">
          <span [innerHtml]="'views.group-associations.AllAssigned' | translate: {entityLabel: entityLabel}"></span>
        </div>

        <div class="alert alert-warning" *ngIf="allAvailableItems.length > 0 && filteredItems.length === 0 ">
          <span [innerHtml]="'views.group-associations.FilterHidingAll' | translate: {total: allAvailableItems.length, entityLabel: entityLabel}"></span>
        </div>

        <div class="filter-panel" *ngIf="allAvailableItems.length > 0">
          <div class="panel-body">
            <label>
              <span [innerHtml]="'common.Filter' | translate"></span>
              <input type="text" class="form-control input-sm" [formControl]="searchTerm" (keydown.escape)="clearSearchTerm()" />
            </label>
          </div>
        </div>

        <table class="table table-striped  list-table" *ngIf="displayedItems?.length > 0">
          <thead>
            <tr>
              <th class="checkbox-column">
                <input type="checkbox" [ngModel]="allSelected" (change)="onAllChanged($event.target.checked)" />
              </th>
              <th *ngFor="let column of _columnDefinitions">{{ column.title }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of displayedItems">
              <td class="checkbox-column">
                <input type="checkbox" [ngModel]="item.selected" (change)="select(item, $event.target.checked)" />
              </td>
            <td *ngFor="let field of item.fields; let idx = index" class="{{('group-association-add-' + entityHeading.split(' ').join('') + '-' + _columnDefinitions[idx].title.split(' ').join('') + '-col') | lowercase}}">
              <span [ngClass]="{'deleted': item.pendingRemoval}" [innerHtml]="field | alkHighlight:searchTerm.value"></span>
            </td>
            </tr>
          </tbody>
        </table>

        <div class="pagination-panel">
          <div class="pull-left grid-details">
            {{ 'common.DisplayOf' | translate:{pageStartNumber:pageStart(), pageEndNumber:pageEnd(), total:filteredItems.length} }}
          </div>

          <div class="pull-right" *ngIf="(hasPreviousPage() || hasNextPage())">
            <button type="button" class="btn btn-default" (click)="previousPage()" [disabled]="!hasPreviousPage()">
              <span class="glyphicon glyphicon-menu-left"></span>
              <span [innerHtml]="'common.Previous' | translate"></span>
            </button>
            <button type="button" class="btn btn-default" (click)="nextPage()" [disabled]="!hasNextPage()">
              <span [innerHtml]="'common.Next' | translate"></span>
              <span class="glyphicon glyphicon-menu-right"></span>
            </button>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
      <div class="modal-footer">
        <button id="cancel-button" type="button" class="btn btn-default cancel-btn" (click)="modal.hide()">{{ 'common.Cancel' | translate }}</button>
        <button type="button" class="btn btn-default" (click)="saveSelected()">OK</button>
      </div>
    </div>
  </div>
</div>
