
import {of as observableOf } from 'rxjs';

import {switchMap, distinctUntilChanged, debounceTime} from 'rxjs/operators';
// angular components
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';





// alk
import { UserContextService } from '../../../alk-components/authentication';
import { DriverGroupService } from './../shared';
import { ConfirmationModal } from './../../shared';

@Component({
  providers: [DriverGroupService],
  templateUrl: './driver-group-list.component.html'
})
// tslint:disable-next-line: component-class-suffix
export class DriverGroupList implements OnInit {

  @ViewChild('confirmModal', { static: true }) confirmModal: ConfirmationModal;

  public isProcessing = false;
  public allDriverGroups: Array<any> = [];
  public filteredDriverGroups: Array<any> = [];
  public sortAsc = true;
  public sortCol = 'name';
  public searchTerm = new FormControl();
  public selectedGroupIds: Array<any> = [];
  public errorMsg: string;
  public currentPage = 0;
  public perPage = 10;
  public pageItems: Array<any> = [];
  public addButtonVisible = true;
  public editButtonVisible = true;
  public deleteButtonVisible = true;

  constructor(
    private driverGroupService: DriverGroupService,
    private userContext: UserContextService) { }

  public ngOnInit() {
    this.isProcessing = true;
    this.searchTerm.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => this.filterAll(term)), )
      .subscribe(items => {
        this.filteredDriverGroups = items;

        this.currentPage = 0;
        this.setCurrentPageItems();
      });

    this.driverGroupService.getGroups()
      .subscribe(groups => {
        this.allDriverGroups = groups;
        this.filteredDriverGroups = this.allDriverGroups;

        this.setCurrentPageItems();
      }, error => {
        this.errorMsg = 'There was an error retrieving driver groups: ' + error.message;
      }, () => {
        this.isProcessing = false;
      });

    // Set button visibility based on allowable activities
    const partnerActivities = this.userContext.getUser().partner.partnerActivities;
    this.addButtonVisible = partnerActivities.canAddDriverGroups;
    this.editButtonVisible = partnerActivities.canUpdateDriverGroups;
    this.deleteButtonVisible = partnerActivities.canDeleteDriverGroups;
  }

  public previousPage() {
    if (!this.hasPreviousPage()) { return; }

    this.currentPage--;
    this.setCurrentPageItems();
  }

  public nextPage() {
    if (!this.hasNextPage()) { return; }

    this.currentPage++;
    this.setCurrentPageItems();
  }

  public deleteGroup(group) {
    this.confirmModal.open()
      .then(() => {
        // tslint:disable-next-line:no-string-literal
        group['isProcessing'] = true;

        this.driverGroupService.deleteGroup(group.id)
          .subscribe(c => {
            // tslint:disable-next-line:no-string-literal
            group['isProcessing'] = false;
            // remove the group from the list and get the current page of items
            const index = this.getGroupIndex(this.allDriverGroups, group);

            // if we didn't find the group index, don't do anything.  Otherwise, remove the item from the list
            if (index >= 0) {
              this.allDriverGroups.splice(index, 1);
              this.filteredDriverGroups = this.allDriverGroups;
              this.setCurrentPageItems();
            }

          }, (error) => {
            // tslint:disable-next-line:no-string-literal
            group['isProcessing'] = false;
            this.errorMsg = 'Error while deleting driver group';
          });
      });
  }

  public hasNextPage(): boolean {
    const nextPageStartIndex = (this.currentPage + 1) * this.perPage;
    return this.filteredDriverGroups.length > nextPageStartIndex;
  }

  public hasPreviousPage(): boolean {
    return this.currentPage > 0;
  }

  public clearSearchTerm() {
    this.searchTerm.setValue('');
  }

  public sortString(column: string, asc: boolean) {
    const multiplyBy = asc ? 1 : -1;

    this.filteredDriverGroups.sort((a, b) => {
      const aUpperCase = a[column].toUpperCase();
      const bUpperCase = b[column].toUpperCase();

      if (aUpperCase < bUpperCase) { return (-1 * multiplyBy); }
      if (aUpperCase > bUpperCase) { return (1 * multiplyBy); }
      return 0;
    });

    this.setCurrentPageItems();
  }

  public sortNumber(column: string, asc: boolean) {
    const multiplyBy = asc ? 1 : -1;

    this.filteredDriverGroups.sort((a, b) => {
      return (a[column] - b[column]) * multiplyBy;
    });

    this.setCurrentPageItems();
  }

  public pageStart() {
    if (this.filteredDriverGroups.length === 0) { return 0; }
    return (this.currentPage * this.perPage) + 1;
  }

  public pageEnd() {
    let retVal = (this.currentPage * this.perPage) + this.perPage;
    if (retVal > this.filteredDriverGroups.length) {
      retVal = this.filteredDriverGroups.length;
    }
    return retVal;
  }

  private getGroupIndex(groups, group): number {
    for (let i = 0; i < groups.length; i++) {
      if (groups[i].id === group.id) {
        return i;
      }
    }

    return -1;
  }

  private setCurrentPageItems() {
    const startPageIndex = this.currentPage * this.perPage;
    const endPageIndex = startPageIndex + this.perPage;

    this.pageItems = this.filteredDriverGroups.slice(startPageIndex, endPageIndex);
  }

  private filter(groups: Array<any>, searchTerm: string) {
    searchTerm = searchTerm.toUpperCase();

    return groups.filter(group => {
      if (group.name != null && group.name.toUpperCase().indexOf(searchTerm) !== -1) { return true; }
      if (group.externalId != null && group.externalId.toUpperCase().indexOf(searchTerm) !== -1) { return true; }

      return false;
    });
  }

  private filterAll(searchTerm?: string) {
    let results = this.allDriverGroups;

    if (searchTerm) {
      results = this.filter(results, searchTerm);
    }

    return observableOf(results);
  }

}
