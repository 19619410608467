// angular components
import { Component, OnInit, OnDestroy, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';

// 3rd party components
import { Angulartics2Segment } from 'angulartics2/segment';
import { ModalDirective } from 'ngx-bootstrap';

// app components
import { VehicleService } from './../../vehicle';
import { PartnerAccountService } from '../partner-account.service';
import { PartnerAccount } from '../partner-account';

@Component({
  selector: 'partner-account-move-modal',
  providers: [PartnerAccountService],
  templateUrl: './partner-account-move-modal.component.html'
})
// tslint:disable-next-line: component-class-suffix
export class PartnerAccountMoveModal implements OnInit, OnDestroy {
  addMode = false; // if false we are in edit mode
  isProcessing = false;
  submitted = false;
  emailErrorMsg = '';
  errorMsg = '';
  form: FormGroup;

  @ViewChild('modal', { static: true }) modal: ModalDirective;
  @Output() vehicleChanged = new EventEmitter();

  private sub1: Subscription;
  CurrentAccountId: number;
  partnerAccounts: Array<PartnerAccount>;
  devices: any;
  deviceNumber: any;

  constructor(
    private vehicleService: VehicleService,
    private fb: FormBuilder,
    private el: ElementRef,
    private ag2analytics: Angulartics2Segment) {
    this.form = this.fb.group({ selectedAccount: []});
  }

  ngOnInit() {

    this.sub1 = this.modal.onShown.subscribe(() => {
      const nativeElement = this.el.nativeElement.getElementsByTagName('input')[this.addMode ? 0 : 1];
      if (nativeElement) { nativeElement.focus(); }
    });
  }

  ngOnDestroy() {
    this.sub1.unsubscribe();
  }

  open(addMode: boolean, partnerAccounts: Array<PartnerAccount>, devices: any, id: number) {
    this.addMode = addMode;
    this.partnerAccounts = this.getFilteredAccounts(partnerAccounts, id);
    this.form = this.fb.group({ selectedAccount: [this.partnerAccounts[0].id]});
    this.devices = devices;
    this.deviceNumber = this.devices.length;
    this.CurrentAccountId = id;
    // better way?  should we be destroying this on modal close instead of hide?
    this.errorMsg = '';
    this.emailErrorMsg = '';
    this.modal.show();
  }

  // remove toAccount in the dropDown, todo: use angularJS filter
  getFilteredAccounts( partnerAccounts: Array<PartnerAccount>, toAccountId: number) {
    const filteredPartnerAccounts: Array<PartnerAccount> = [];
    partnerAccounts.forEach(account => {
      if (account.id !== toAccountId) {
        filteredPartnerAccounts.push(account);
      }
    });
    return filteredPartnerAccounts;
  }

  save() {
    this.submitted = true;
    // clear error and success messages when saving
    this.errorMsg = null;

    if (!this.form.valid) {
      return;
    }

    if (!this.form.valid) {
      this.ag2analytics.eventTrack('Vehicle Move attempted', { failReason: 'Invalid input' });
      return;
    }
    this.vehicleService.moveVehicles(this.devices, this.form.value.selectedAccount)
    .subscribe( resp => {
      this.modal.hide();
      this.vehicleChanged.emit({
      id: this.CurrentAccountId,
      name: ''
      });
    }, err => {
        this.errorMsg = 'There was an error in moving devices to the target account. ' + err.resp.message;
      }, () => {
        this.isProcessing = false;
      }
    );

  }



}
