
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { UserInAccount } from './account-user';
import { UserAssociatedGroup } from './account-user-associated-group';

import { environment } from '../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';

@Injectable()
export class AccountUserManagementService {

  constructor(private http: HttpClient) {
  }

  getUser(userId: number) {
    const url = environment.apiUrl + '/v1/users/' + userId;
    return this.http.get(url).map(this.mapUserFromService);
  }

  // current users in the account
  getUsers() {
    const url = environment.apiUrl + '/v1/users';
    const maxGroupsToDisplay = 2;

    return this.http.get<Array<any>>(url).map(responses => {
        const users: UserInAccount[] = [];
        responses.forEach(respUser => {
          const user = this.mapUserFromService(respUser);

          if (user.vehicleGroups.length > 0) {
            user.vehicleGroupsAbbreviated = user.vehicleGroups
              .sort((a, b) => b.numMembers - a.numMembers)
              .map(c => c.groupName)
              .slice(0, maxGroupsToDisplay).join(', ');

            if (user.vehicleGroups.length > maxGroupsToDisplay) {
              user.vehicleGroupsAbbreviated += `, (${user.vehicleGroups.length - maxGroupsToDisplay} more)... `;
            }
          }

          if (user.driverGroups.length > 0) {
            user.driverGroupsAbbreviated = user.driverGroups
              .sort((a, b) => b.numMembers - a.numMembers)
              .map(c => c.groupName)
              .slice(0, maxGroupsToDisplay).join(', ');
            if (user.driverGroups.length > maxGroupsToDisplay) {
              user.driverGroupsAbbreviated += `, (${user.driverGroups.length - maxGroupsToDisplay} more)... `;
            }
          }

          users.push(user);
        });

        return users;
      });
  }

  createUser(user: UserInAccount) {
    const url = environment.apiUrl + '/v1/users';
    const body = JSON.stringify({
      emailAddress: user.emailAddress,
      firstName: user.firstName,
      lastName: user.lastName,
      enabled: true,
      role: user.role
    });

    return this.http.post(url, body, { observe: 'response' }).pipe(
      map(resp => {
        // read the newly created ID primary key from the http headers of the response
        const location = resp.headers.get('Location');
        const idRegex = /\/v1\/users\/([0-9].*)/g;
        const matches = idRegex.exec(location);
        let newUserId = 0;
        if (matches.length > 1) { newUserId = +matches[1]; }
        return { status: resp.status, userId: newUserId };
      }),
      catchError(err => {
        return observableThrowError({ status: err.status });
      }), );
  }

  updateUser(user: UserInAccount) {
    const url = environment.apiUrl + '/v1/users/' + user.userId;
    const body = JSON.stringify({
      emailAddress: user.emailAddress,
      firstName: user.firstName,
      lastName: user.lastName,
      role: user.role,
      enabled: user.enabled
    });

    return this.http.put(url, body).pipe(
      catchError(this.serverError));
  }

  replaceUser(replacingUserId: number, newUser: UserInAccount) {
    const url = environment.apiUrl + '/v1/users/' + replacingUserId + '/replace';
    const body = JSON.stringify({
      emailAddress: newUser.emailAddress,
      firstName: newUser.firstName,
      lastName: newUser.lastName,
      enabled: true,
      role: 'User' // will be overridden
    });

    return this.http.post(url, body).pipe(
      catchError(response => {
        return observableThrowError({ status: response.status });
      }));
  }

  setUserEnabledStatus(userId: number, enabled: boolean) {
    const url = environment.apiUrl + '/v1/users/' + userId + '/' + (enabled ? 'enable' : 'disable');

    return this.http.put(url, '').pipe(
      catchError(this.serverError));
  }

  removeUser(userId: number) {
    const url = environment.apiUrl + '/v1/users/' + userId;

    return this.http.delete(url).pipe(
      catchError(this.serverError));
  }

  getUserProfile(userId: number): Observable<UserInAccount> {
    const url = environment.apiUrl + '/v1/users/current';
    return this.http.get<any>(url).pipe(
      map(u => {
        const userProfile: UserInAccount = new UserInAccount();
        userProfile.userId = u.userId;
        userProfile.accountId = u.accountId;
        userProfile.firstName = u.firstName;
        userProfile.lastName = u.lastName;
        userProfile.emailAddress = u.emailAddress;
        userProfile.role = u.role;
        userProfile.stateName = u.stateName;
        userProfile.enabled = u.enabled;
        return userProfile;
      }),
      catchError(error => {
        return observableThrowError({ error });
      }), );

  }

  updateUserProfile(user: UserInAccount) {
    const url = environment.apiUrl + '/v1/users/profile';
    return this.http.put(url, JSON.stringify(user), { observe: 'response' }).pipe(
      map(response => {
        if (response.status === 200) {
          return {
            success: true,
            errMsg: ''
          };
        } else {
          return {
            success: false,
            errMsg: `Error updating user: Err Code ${response.status}`
          };
        }
      }),
      catchError(err => {
        return observableThrowError({
          success: false,
          errMsg: `Error updating user: Err Code ${err}`
        });
      }), );
  }

  getUserVehicleGroups(userId: number) {
    const url = environment.apiUrl + '/v1/users/' + userId + '/vehicle-groups';
    return this.http.get<any>(url).pipe(
      map((groups) => {
        const transformedResponse: Array<any> = [];
        groups.forEach(group => {
          transformedResponse.push(
            {
              id: group.groupId, // ?
              name: group.groupName
            });
        });
        return transformedResponse;
      }),
      catchError(error => {
        return observableThrowError({ error });
      }
      ), );
  }

  setUserVehicleGroups(userId: number, vehicleGroupIds: Array<number>) {
    const url = environment.apiUrl + '/v1/users/' + userId + '/vehicle-groups';
    const body = JSON.stringify(vehicleGroupIds);

    return this.http.put(url, body).pipe(
      catchError(this.serverError));
  }

  getUserDriverGroups(userId: number) {
    const url = environment.apiUrl + '/v1/users/' + userId + '/driver-groups';
    return this.http.get<any>(url).pipe(
      map((groups) => {
        const transformedResponse: Array<any> = [];
        groups.forEach(group => {
          transformedResponse.push(
            {
              id: group.groupId, // ?
              name: group.groupName
            });
        });
        return transformedResponse;
      }),
      catchError(error => {
        return observableThrowError({ error });
      }
      ), );
  }

  setUserDriverGroups(userId: number, vehicleGroupIds: Array<number>) {
    const url = environment.apiUrl + '/v1/users/' + userId + '/driver-groups';
    const body = JSON.stringify(vehicleGroupIds);

    return this.http.put(url, body).pipe(
      catchError(this.serverError));
  }

  getUserRoles() {
    const url = environment.apiUrl + '/v1/users/roles';
    return this.http.get<Array<any>>(url).pipe(
      catchError(error => {
        return observableThrowError({ error });
      }
      ), );
  }

  private mapUserFromService(respUser): UserInAccount {
    const user: UserInAccount = new UserInAccount();
    user.userId = respUser.userId;
    user.accountId = respUser.accountId;
    user.firstName = respUser.firstName;
    user.lastName = respUser.lastName;

    if (user.firstName && user.lastName) {
      user.fullName = respUser.firstName + ' ' + respUser.lastName;
    } else if (user.firstName) {
      user.fullName = respUser.firstName;
    } else if (user.lastName) {
      user.fullName = respUser.lastName;
    } else {
      user.fullName = '';
    }

    user.emailAddress = respUser.emailAddress;
    user.role = respUser.role;
    user.stateName = respUser.stateName;
    user.enabled = respUser.enabled;

    const vehicleGroups: UserAssociatedGroup[] = new Array<UserAssociatedGroup>();

    respUser.vehicleGroups.forEach(resp => {
      const group: UserAssociatedGroup = new UserAssociatedGroup();
      group.groupId = resp.groupId;
      group.groupName = resp.groupName;
      group.numMembers = resp.numMembers;
      vehicleGroups.push(resp);
    });
    user.vehicleGroups = vehicleGroups;

    const driverGroups: UserAssociatedGroup[] = new Array<UserAssociatedGroup>();
    respUser.driverGroups.forEach(respDriverGroup => {
      const driverGroup: UserAssociatedGroup = new UserAssociatedGroup();
      driverGroup.groupId = respDriverGroup.groupId;
      driverGroup.groupName = respDriverGroup.groupName;
      driverGroup.numMembers = respDriverGroup.numMembers;
      driverGroups.push(driverGroup);
    });
    user.driverGroups = driverGroups;

    return user;
  }

  private serverError(err: any) {
    if (err instanceof HttpResponse) {
      return observableThrowError(`error: ${err.status}` || 'error');
    }
    return observableThrowError(err || 'error');
  }
}
