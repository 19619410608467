/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./marketing-footer.component";
var styles_MarketingFooter = [];
var RenderType_MarketingFooter = i0.ɵcrt({ encapsulation: 2, styles: styles_MarketingFooter, data: {} });
export { RenderType_MarketingFooter as RenderType_MarketingFooter };
export function View_MarketingFooter_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "container-fluid footer-trimble-corp"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "row py-3 text-white"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "a", [["class", "text-white mr-3 pl-4"], ["href", "https://www.trimble.com/Corporate/Copyrights.aspx"], ["rel", "noopener noreferrer"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, [" \u00A9 Copyright ", ", Trimble Inc. "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentYear; _ck(_v, 5, 0, currVal_0); }); }
export function View_MarketingFooter_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "marketing-footer", [], null, null, null, View_MarketingFooter_0, RenderType_MarketingFooter)), i0.ɵdid(1, 49152, null, 0, i1.MarketingFooter, [], null, null)], null, null); }
var MarketingFooterNgFactory = i0.ɵccf("marketing-footer", i1.MarketingFooter, View_MarketingFooter_Host_0, {}, {}, []);
export { MarketingFooterNgFactory as MarketingFooterNgFactory };
