import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { UserContextService } from './../../../alk-components/authentication';

import { EUEnglishAuto, EUEnglishHeavyDuty, EUEnglishMediumDuty, EUEnglishLightDuty, EUEnglishBus } from './routing-profile.eu.english';
import { EU40Foot, EU61Foot, EU54Foot, EUEnglishCustom } from './routing-profile.eu.english';

import { EUMetricAuto, EUMetricHeavyDuty, EUMetricLightDuty, EUMetricMediumDuty, EUMetricBus } from './routing-profile.eu.metric';
import { EU12Meter, EU16Meter, EU18Meter, EUMetricCustom } from './routing-profile.eu.metric';

import { NAEnglishHeavyDuty, NAEnglishLightDuty, NAEnglishMediumDuty, NAEnglishAuto, NAEnglishBus } from './routing-profile.na.english';
import { NA28Foot, NA40Foot, NA48Foot, NA53Foot, NAEnglishCustom } from './routing-profile.na.english';
import { NAMetric28Foot, NAMetric40Foot, NAMetric48Foot, NAMetric53Foot, NAMetricCustom } from './routing-profile.na.metric';
import { NAMetricHeavyDuty, NAMetricMediumDuty, NAMetricLightDuty, NAMetricAuto, NAMetricBus } from './routing-profile.na.metric';

/*import {} from './routing-profile.na.metric';*/

const NoRestriction = {
  truckDimensions: 'NoRestriction',
  maxHeight: 0,
  maxWidth: 0,
  totalLength: 0,
  totalWeight: 0,
  totalWeightPerAxle: 0
};

export const BaseRoutingProfile = {
  routingProfileId: -1,
  vehicleType: 'Truck',
  tollRoadType: 'Use',
  truckDimensions: 'NoRestriction',
  maxHeight: (12 * 13) + 6,
  maxWidth: 102,
  totalLength: (12 * 53),
  totalWeight: 80000,
  totalWeightPerAxle: 34000,
  hazmatType: 'None',
  congestionZone: 2,
  environmentalZone: 2,
  displayRestrictions: 'BasedOnTruckRestrictions',
  classOverrides: 3,
  nationalNetwork: true,
  fiftyThreeFootTrailer: true,
  overrideRestrictions: false,
  propane: false,
  ferryClosed: false,
  vehicleGroups: [],
  statusDeleted: false,
  unitsOfMeasure: 'English',
  numAxles: 2,
  lcv: false,
  distanceUnits: 'Miles',
  tollDiscouraged: false,
  highwayOnly: false,
  includeFerryDistance: false,
  tollCurrency: 0,
  exchangeRate: '0.000',
  fuelUnits: 'Gallons',
  costGHG: '1.00',
  costPerFuelUnit: '1.00',
  fuelEconomyLoad: '1.00',
  fuelEconomyEmpty: '1.00',
  costMaintLoad: '1.00',
  costMaintEmpty: '1.00',
  costTimeLoad: '1.00',
  costTimeEmpty: '1.00',
  dataVersion: '',
  hubRouting: false,
  tollDiscountPlans: null,
  includeTollData: false,
  default: false,
  roadPreferencesFreewaysAFType: 'Neutral',
  roadPreferencesFreewaysSpeed: 55,
  roadPreferencesDividedHighwaysAFType: 'Neutral',
  roadPreferencesDividedHighwaysSpeed: 55,
  roadPreferencesPrimaryRoadsAFType: 'Neutral',
  roadPreferencesPrimaryRoadsSpeed: 55,
  roadPreferencesSecondaryRoadsAFType: 'Neutral',
  roadPreferencesSecondaryRoadsSpeed: 55,
  roadPreferencesLocalStreetsAFType: 'Neutral',
  roadPreferencesLocalStreetsSpeed: 55,
  isFerryDiscouraged: false,
  governorSpeedLimitSelect: 'disabled',
  elevationSelect: 'any',
  governorSpeedLimit: null,
  elevationLimit: 7500,
  name: '',
  routingType: 'Practical',
  activeRoutes: 'Disabled',
  bordersOpen: true,
  trailerHeight: 0,
};

export enum UnitType {
  English,
  Metric
}

@Injectable()
export class RoutingProfileDefaults {
  private vehicleTypes = {
    Metric: {
      EU: {
        Truck: EUMetricHeavyDuty,
        MidsizeTruck: EUMetricMediumDuty,
        LightDuty: EUMetricLightDuty,
        Auto: EUMetricAuto,
        Bus: EUMetricBus
      },
      NA: {
        Truck: NAMetricHeavyDuty,
        MidsizeTruck: NAMetricMediumDuty,
        LightDuty: NAMetricLightDuty,
        Auto: NAMetricAuto,
        Bus: NAMetricBus
      }
    },
    English: {
      EU: {
        Truck: EUEnglishHeavyDuty,
        MidsizeTruck: EUEnglishMediumDuty,
        LightDuty: EUEnglishLightDuty,
        Auto: EUEnglishAuto,
        Bus : EUEnglishBus
      },
      NA: {
        Truck: NAEnglishHeavyDuty,
        MidsizeTruck: NAEnglishMediumDuty,
        LightDuty: NAEnglishLightDuty,
        Auto: NAEnglishAuto,
        Bus : NAEnglishBus
      }
    }
  };

  private truckDimensions = {
    Metric: {
      EU: {
        EU12Meter,
        EU16Meter,
        EU18Meter,
        Custom: EUMetricCustom,
        NoRestriction
      },
      NA: {
        DoubleTrailers28: NAMetric28Foot,
        StraightTruck40: NAMetric40Foot,
        SemiTrailer48: NAMetric48Foot,
        TrailerOrTwins53: NAMetric53Foot,
        Custom: NAMetricCustom,
        NoRestriction
      }
    },
    English: {
      EU: {
        EU40Foot,
        EU54Foot,
        EU61Foot,
        Custom: EUEnglishCustom,
        NoRestriction
      },
      NA: {
        DoubleTrailers28: NA28Foot,
        StraightTruck40: NA40Foot,
        SemiTrailer48: NA48Foot,
        TrailerOrTwins53: NA53Foot,
        Custom: NAEnglishCustom,
        NoRestriction
      }
    }
  };

  constructor(private userContext: UserContextService) { }

  public getDefaultRoutingProfile() {
    const user = this.userContext.getUser();
    const region = user.region;
    // when getting a default profile use the unit type (metric/english), the region (EU/NA) and get the heavy duty truck.
    // tslint:disable-next-line:no-string-literal
    const units = this.vehicleTypes[user.unitType][region]['Truck'];

    return _.merge({}, BaseRoutingProfile, units);
  }

  public getVehiclePreset(vehicleType: string, defaults?: { unitType: string, region: string }) {
    let region;
    let unitType;
    const user = this.userContext.getUser();

    if (defaults) {
      region = defaults.region || user.region;
      unitType = defaults.unitType || user.unitType;
    } else {
      region = user.region;
      unitType = user.unitType;
    }

    const units = this.vehicleTypes[unitType];

    // todo: log error
    if (!units) { return {}; }

    const regionUnits = units[region];

    if (!regionUnits) { return {}; }

    // tslint:disable-next-line:no-string-literal
    regionUnits[vehicleType]['unitsOfMeasure'] = unitType;

    return regionUnits[vehicleType];
  }

  public getMeasurements(preset: string, defaults?: { unitType: string, region: string }) {
    let region;
    let unitType;
    const user = this.userContext.getUser();

    if (defaults) {
      region = defaults.region || user.region;
      unitType = defaults.unitType || user.unitType;
    } else {
      region = user.region;
      unitType = user.unitType;
    }

    const dimensions = this.truckDimensions[unitType];

    if (!dimensions) { return {}; }

    const regionDimensions = dimensions[region];

    if (!regionDimensions) { return {}; }

    // tslint:disable-next-line:no-string-literal
    regionDimensions[preset]['unitsOfMeasure'] = unitType;

    return regionDimensions[preset];
  }


}
