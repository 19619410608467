/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../shared/terms.component.ngfactory";
import * as i2 from "../shared/terms.component";
import * as i3 from "@angular/common/http";
import * as i4 from "../../../alk-components/alk-translations/alk-language-context";
import * as i5 from "@angular/platform-browser";
import * as i6 from "@ngx-translate/core/src/translate.service";
import * as i7 from "@angular/forms";
import * as i8 from "@ngx-translate/core/src/translate.pipe";
import * as i9 from "./accept-terms.service";
import * as i10 from "../../../alk-components/authentication/user-context.service";
import * as i11 from "./accept-terms.component";
import * as i12 from "@angular/router";
var styles_AcceptTerms = [];
var RenderType_AcceptTerms = i0.ɵcrt({ encapsulation: 2, styles: styles_AcceptTerms, data: {} });
export { RenderType_AcceptTerms as RenderType_AcceptTerms };
export function View_AcceptTerms_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 15, "div", [["class", "container with-nav terms-page"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 14, "div", [["class", "panel page-panel"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 13, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 12, "div", [["class", "col-xs-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "terms", [], null, null, null, i1.View_Terms_0, i1.RenderType_Terms)), i0.ɵdid(5, 114688, null, 0, i2.Terms, [i3.HttpClient, i4.ALKLanguageContext, i5.DomSanitizer, i6.TranslateService], null, null), (_l()(), i0.ɵeld(6, 0, null, null, 8, "div", [["style", "text-align: right; padding-bottom: 10px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 7, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngSubmit"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 9).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 9).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("ngSubmit" === en)) {
        var pd_2 = (_co.acceptTerms() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(8, 16384, null, 0, i7.ɵangular_packages_forms_forms_z, [], null, null), i0.ɵdid(9, 4210688, null, 0, i7.NgForm, [[8, null], [8, null]], null, { ngSubmit: "ngSubmit" }), i0.ɵprd(2048, null, i7.ControlContainer, null, [i7.NgForm]), i0.ɵdid(11, 16384, null, 0, i7.NgControlStatusGroup, [[4, i7.ControlContainer]], null, null), (_l()(), i0.ɵeld(12, 0, null, null, 2, "button", [["class", "btn btn-success"], ["id", "accept-terms"], ["type", "submit"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i0.ɵted(13, null, ["", ""])), i0.ɵpid(131072, i8.TranslatePipe, [i6.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(15, 0, null, null, 0, "div", [["class", "clearfix"]], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 5, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 11).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 11).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 11).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 11).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 11).ngClassValid; var currVal_5 = i0.ɵnov(_v, 11).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 11).ngClassPending; _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.isProcessing; _ck(_v, 12, 0, currVal_7); var currVal_8 = i0.ɵunv(_v, 13, 0, i0.ɵnov(_v, 14).transform("views.accept-terms.Title")); _ck(_v, 13, 0, currVal_8); }); }
export function View_AcceptTerms_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "accept-terms", [], null, null, null, View_AcceptTerms_0, RenderType_AcceptTerms)), i0.ɵprd(512, null, i9.AcceptTermsService, i9.AcceptTermsService, [i3.HttpClient, i10.UserContextService]), i0.ɵdid(2, 245760, null, 0, i11.AcceptTerms, [i12.ActivatedRoute, i12.Router, i9.AcceptTermsService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var AcceptTermsNgFactory = i0.ɵccf("accept-terms", i11.AcceptTerms, View_AcceptTerms_Host_0, {}, {}, []);
export { AcceptTermsNgFactory as AcceptTermsNgFactory };
