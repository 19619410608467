
import {throwError as observableThrowError, forkJoin } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// alk common components
import { UserContextService } from './../../../alk-components/authentication/user-context.service';

// alk app components
import { PagingService } from './../../shared/http';

import { environment } from '../../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';

@Injectable()
export class RoutingProfileService {

  constructor(
    private http: HttpClient,
    private userContext: UserContextService,
    private translate: TranslateService,
    private paging: PagingService
  ) { }

  public deleteRoutingProfile(routingProfileId: number) {
    const url = `${environment.apiUrl}/v1/routing-profiles/${routingProfileId}`;
    return this.http.delete(url);
  }


  public getRoutingProfilesByVehicleId(id: number) {
    const params = [];
    let url = `${environment.apiUrl}/v1/routing-profiles/vehicles/${id}`;
    url += params.join('&');

    return this.http.get(url);
  }

  public getRoutingProfiles(
    includeGroupId?: Array<number>,
    excludeGroupId?: Array<number>,
    searchTerm?: string,
    paging?: any,
    isDefault?: boolean
  ) {
    let url = environment.apiUrl + '/v1/routing-profiles?';

    const params = [];
    if (paging) {
      params.push(this.paging.getPagingQuery(paging));
    }

    if (isDefault != null) {
      params.push('isDefault=' + isDefault);
    }

    if (searchTerm && searchTerm.trim().length) {
      params.push('searchTerm=' + encodeURIComponent(searchTerm));
    }

    if (includeGroupId && includeGroupId.length > 0) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < includeGroupId.length; i++) {
        params.push('includeGroupId=' + includeGroupId[i]);
      }
    }

    if (excludeGroupId && excludeGroupId.length > 0) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < excludeGroupId.length; i++) {
        params.push('excludeGroupId=' + excludeGroupId[i]);
      }
    }

    url += params.join('&');

    return forkJoin([
      this.http.get<any>(url, { observe: 'response' }),
      this.populateTranslations()
    ]).pipe(map(results => {
      const resp = results[0];
      const translations = results[1];

      const user = this.userContext.getUser();

      const total = +resp.headers.get('X-Total-Count');

      const profiles = resp.body;

      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < profiles.length; i++) {
        if (profiles[i].routingType === 'Fastest') {
          profiles[i].routingType = 'Practical';
        }
        if (user.region === 'EU' && profiles[i].vehicleType === 'Auto' && profiles[i].routingType === 'Practical') {
          profiles[i].routingTypeDisplay = translations.Quickest;
        } else {
          profiles[i].routingTypeDisplay = translations[profiles[i].routingType];
        }
        profiles[i].truckDimensionsDisplay = translations[profiles[i].truckDimensions];
        profiles[i].vehicleTypeDisplay = translations[profiles[i].vehicleType];
        profiles[i].hazmatTypeDisplay = translations[profiles[i].hazmatType];
      }

      return { total, items: profiles };

    }));
  }

  public setIsDefaultChanged(profileId: number, isDefault: boolean) {
    const url = environment.apiUrl + '/v1/routing-profiles/' + profileId + '/isDefault/' + isDefault;

    return this.http.put(url, '').pipe(
      catchError(this.serverError));
  }

  public getRoutingProfile(id) {
    const url = `${environment.apiUrl}/v1/routing-profiles/${id}`;
    return this.http.get(url);
  }

  public associateVehicleGroups(id: number, vehicleGroupIds: any[]) {
    const url = `${environment.apiUrl}/v1/routing-profiles/${id}/vehicle-groups`;
    const body = JSON.stringify({ entityId: vehicleGroupIds });

    return this.http.put(url, body);
  }

  public updateRoutingProfileDetails(id: number, routingProfile: any) {
    const url = `${environment.apiUrl}/v1/routing-profiles/${id}`;
    const body = JSON.stringify(routingProfile);

    return this.http.put(url, body, { observe: 'response' }).pipe(
      map(resp => {
        const modifiedOn = +resp.headers.get('X-LastModified');
        routingProfile.modifiedOn = modifiedOn;

        const location = resp.headers.get('Location');

        const idRegex = /\/v1\/routing-profiles\/([0-9].*)/g;
        const matches = idRegex.exec(location);

        if (matches.length > 1) { routingProfile.routingProfileId = +matches[1]; }

        return routingProfile;
      }), catchError(error => {
        return observableThrowError(error.json());
      }), );
  }

  public createRoutingProfileDetails(routingProfile: any) {
    const url = `${environment.apiUrl}/v1/routing-profiles`;
    const body = JSON.stringify(routingProfile);

    return this.http.post(url, body, { observe: 'response' }).pipe(
      map(resp => {
        const modifiedOn = +resp.headers.get('X-LastModified');
        routingProfile.modifiedOn = modifiedOn;

        const location = resp.headers.get('Location');

        const idRegex = /\/v1\/routing-profiles\/([0-9].*)/g;
        const matches = idRegex.exec(location);

        if (matches.length > 1) { routingProfile.routingProfileId = +matches[1]; }

        return routingProfile;
      }), catchError(error => {
        return observableThrowError(error.json());
      }), );
  }

  public getExportUrl(groupId?: number) {
    const param = [];
    param.push(`authToken=${this.userContext.getUser().authToken}`);

    if (this.userContext.getUser().currentPartnerAccountId) {
      param.push(`targetAccount=${this.userContext.getUser().currentPartnerAccountId}`);
    }

    if (groupId) {
      param.push(`groupId=${groupId}`);
    }

    const url = `${environment.apiUrl}/v1/routing-profiles/export?` + param.join('&');
    return url;
  }

  private populateTranslations() {
    return this.translate.get([
      // vehicle types
      'views.routing-profile-detail.reference.HeavyDuty',
      'views.routing-profile-detail.reference.MediumDuty',
      'views.routing-profile-detail.reference.LightDuty',
      'views.routing-profile-detail.reference.Auto',
      'views.routing-profile-detail.reference.SchoolBus',

      // routing types
      'views.routing-profile-detail.reference.Practical',
      'views.routing-profile-detail.reference.Shortest',
      'views.routing-profile-detail.reference.Quickest',

      // truck dimension types
      'views.routing-profile-detail.reference.NoRestriction',
      'views.routing-profile-detail.reference.SemiTrailer48',
      'views.routing-profile-detail.reference.Trailer53',
      'views.routing-profile-detail.reference.Double28',
      'views.routing-profile-detail.reference.Straight40',
      'views.routing-profile-detail.reference.Custom',
      'views.routing-profile-detail.reference.EU12Meter',
      'views.routing-profile-detail.reference.EU16Meter',
      'views.routing-profile-detail.reference.EU18Meter',

      // hazmat types
      'views.routing-profile-detail.reference.None',
      'views.routing-profile-detail.reference.General',
      'views.routing-profile-detail.reference.Explosives',
      'views.routing-profile-detail.reference.Inhalants',
      'views.routing-profile-detail.reference.Radioactive',
      'views.routing-profile-detail.reference.Caustic',
      'views.routing-profile-detail.reference.Flammable',
      'views.routing-profile-detail.reference.HarmfulToWater'

    ]).pipe(map(translated => {
      return {
        Truck: translated['views.routing-profile-detail.reference.HeavyDuty'],
        MidsizeTruck: translated['views.routing-profile-detail.reference.MediumDuty'],
        LightDuty: translated['views.routing-profile-detail.reference.LightDuty'],
        Auto: translated['views.routing-profile-detail.reference.Auto'],
        Bus: translated['views.routing-profile-detail.reference.SchoolBus'],

        Practical: translated['views.routing-profile-detail.reference.Practical'],
        Shortest: translated['views.routing-profile-detail.reference.Shortest'],
        Quickest: translated['views.routing-profile-detail.reference.Quickest'],

        NoRestriction: translated['views.routing-profile-detail.reference.NoRestriction'],
        SemiTrailer48: translated['views.routing-profile-detail.reference.SemiTrailer48'],
        TrailerOrTwins53: translated['views.routing-profile-detail.reference.Trailer53'],
        DoubleTrailers28: translated['views.routing-profile-detail.reference.Double28'],
        StraightTruck40: translated['views.routing-profile-detail.reference.Straight40'],
        Custom: translated['views.routing-profile-detail.reference.Custom'],
        EU12Meter: translated['views.routing-profile-detail.reference.EU12Meter'],
        EU16Meter: translated['views.routing-profile-detail.reference.EU16Meter'],
        EU18Meter: translated['views.routing-profile-detail.reference.EU18Meter'],

        None: translated['views.routing-profile-detail.reference.None'],
        General: translated['views.routing-profile-detail.reference.General'],
        Explosives: translated['views.routing-profile-detail.reference.Explosives'],
        Inhalants: translated['views.routing-profile-detail.reference.Inhalants'],
        Radioactive: translated['views.routing-profile-detail.reference.Radioactive'],
        Caustic: translated['views.routing-profile-detail.reference.Caustic'],
        Flammable: translated['views.routing-profile-detail.reference.Flammable'],
        HarmfulToWater: translated['views.routing-profile-detail.reference.HarmfulToWater']
      };
    }));
  }

  private serverError(err: any) {
    if (err instanceof HttpResponse) {
      return observableThrowError(`error: ${err.status}` || 'error');
    }
    return observableThrowError(err || 'error');
  }
}
