
import {map} from 'rxjs/operators';
// angular components
import { Injectable } from '@angular/core';

// application components
import { UserContextService } from './../../../alk-components/authentication';

import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AcceptTermsService {
  constructor(private http: HttpClient, private userContext: UserContextService) {

  }

  acceptTerms() {
    const user = this.userContext.getUser();
    user.currentTermsOfUseAccepted = true;
    this.userContext.setUser(user);

    const url = environment.apiUrl + '/v1/user/accept-terms';

    return this.http.post(url, {}).pipe(
      map(c => ({ status: 1 })));
  }
}
