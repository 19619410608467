/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./login.component";
import * as i3 from "../shared/authentication/authentication.service";
import * as i4 from "@angular/router";
import * as i5 from "../../alk-components/authentication/user-context.service";
import * as i6 from "angulartics2/ga";
import * as i7 from "../shared/tid/tid.service";
var styles_Login = [];
var RenderType_Login = i0.ɵcrt({ encapsulation: 2, styles: styles_Login, data: {} });
export { RenderType_Login as RenderType_Login };
function View_Login_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "alert alert-danger"], ["style", "margin-top: 16px"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" This username does not exist. Please try a different email or "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "a", [["class", "alert-link"], ["href", "https://kb.omnitracs.com/Omnitracs_One/CoPilot/"], ["rel", "noopener noreferrer"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["contact support"])), (_l()(), i0.ɵted(-1, null, [" for help. "]))], null, null); }
function View_Login_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "alert alert-danger"], ["style", "margin-top: 16px"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf; _ck(_v, 1, 0, currVal_0); }); }
function View_Login_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_Login_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["showErrorMessage", 2]], null, 0, null, View_Login_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.ngIf === _co.USERNAME_DOES_NOT_EXIST); var currVal_1 = i0.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_Login_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "tmxtid-init"], ["id", "tmxtid-sign-in-component"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_Login_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.errorMessage$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_Login_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "login", [], null, null, null, View_Login_0, RenderType_Login)), i0.ɵdid(1, 245760, null, 0, i2.Login, [i3.AuthenticationService, i4.Router, i5.UserContextService, i4.ActivatedRoute, i6.Angulartics2GoogleAnalytics, i7.TidService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginNgFactory = i0.ɵccf("login", i2.Login, View_Login_Host_0, {}, {}, []);
export { LoginNgFactory as LoginNgFactory };
