// angular components
import { Component } from '@angular/core';

// alk components
import { DriverService, Driver } from '../shared/driver.service';
import { UserContextService } from './../../../alk-components/authentication';
import { Utils } from '../../shared/utils';

// 3rd party components
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { duration } from 'moment';

@Component({
  selector: 'driver-import',
  providers: [DriverService],
  templateUrl: './driver-import.component.html',
  styles: [`
  a:hover {
    cursor:pointer;
  }
  `]
})
// tslint:disable-next-line: component-class-suffix
export class DriverImport {
  firstRowHasHeadings = true;
  validDriverLines: Array<Driver> = [];
  preProcessSuccess = false;
  preprocessSuccessMessage: Observable<string>;
  preprocessErrorMessage: Observable<string>;
  importSuccess = false;
  importSuccessMessage: Observable<string>;
  importErrorMessage: Observable<string>;
  isProcessing = false;
  importResults = [];
  canImportDrivers: boolean;
  private importFilename = '';
  private rawTextFile: any = '';

  constructor(
    private driverService: DriverService,
    private userContext: UserContextService,
    private translate: TranslateService) {
    this.canImportDrivers = this.userContext.getUser().partner.partnerActivities.canImportDrivers;
  }

  // as soon as a file is selected, start pre-processing it
  importFileChanged($event) {
    this.readImportFile($event.target.files[0]);
    this.importSuccess = false;
    this.importSuccessMessage = null;
    this.importErrorMessage = null;
  }

  firstRowHasHeadingsChanged() {
    if (!this.rawTextFile) { return; }

    // no need to reload import file, but re-process the import if the headings checkbox has changed
    this.preProcessImport();
  }

  upload() {
    this.isProcessing = true;
    this.importSuccess = false;
    this.importSuccessMessage = null;
    this.importErrorMessage = null;

    // also clear out the preprocess messages since we're past that now
    this.preprocessSuccessMessage = null;
    this.preprocessErrorMessage = null;

    this.driverService.import(this.validDriverLines)
      .subscribe(result => {
        this.isProcessing = false;
        this.importSuccess = true;
        this.importResults = result.resp;
        const updatedItems = this.importResults.filter(item => item.importResult.toString().toUpperCase() === 'UPDATED');
        const insertedItems = this.importResults.filter(item => item.importResult.toString().toUpperCase() === 'INSERTED');

        // tslint:disable-next-line:max-line-length
        this.importSuccessMessage = this.translate.get('views.driver-vehicle-import-SuccessMessage-Drivers', { updatedItemsLength: updatedItems.length, insertedItemsLength: insertedItems.length });
      }, err => {
        this.isProcessing = false;
        this.importSuccess = false;
        this.importErrorMessage = this.translate.get('views.driver-vehicle-import-importErrorMessage');
        this.importResults = err.resp;
      });
  }

  downloadSample() {
    const blob = new Blob([this.sampleImportCsv()], { type: 'text/csv' });
    const uri = window.URL.createObjectURL(blob);
    this.saveFileAs(uri, 'driver-import-sample.csv');
  }

  downloadResults() {
    const blob = new Blob([this.convertJsonItemResultsToCsv()], { type: 'text/csv' });
    const uri = window.URL.createObjectURL(blob);
    const importFilenameSansExt = this.importFilename.replace('.csv', '');
    this.saveFileAs(uri, `${importFilenameSansExt}-results.csv`);
  }

  private sampleImportCsv(): string {
    const csvLines = [];
    csvLines.push('Driver ID,Driver Name');
    csvLines.push('SampleDriverID1,SampleDriverName1');
    csvLines.push('SampleDriverID2,SampleDriverName2');
    return csvLines.join('\r\n');
  }

  private convertJsonItemResultsToCsv(): string {
    const csvLines = [];
    csvLines.push('DriverId,DriverName,ImportResult,ValidationResult');
    this.importResults.forEach(resultItem => {
      let { name, driverId } = resultItem.item;
      const { validationResult, importResult } = resultItem;
      name = Utils.csvSafeString(name);
      driverId = Utils.csvSafeString(driverId);
      csvLines.push([driverId, name, importResult, validationResult].join(','));
    });
    return csvLines.join('\r\n');
  }

  private saveFileAs(uri, filename) {
    const link = document.createElement('a');
    if (typeof link.getAttribute('download') === 'string' || typeof link.getAttribute('download') === 'object') {
      link.href = uri;
      link.setAttribute('download', filename);

      // Firefox requires the link to be in the body
      document.body.appendChild(link);

      // simulate click
      link.click();

      // remove the link when done
      document.body.removeChild(link);
    } else {
      window.open(uri);
    }
  }

  private readImportFile(importFile: File) {
    this.importFilename = importFile.name;
    const fileReader: FileReader = new FileReader();

    fileReader.onloadend = (e) => {
      this.rawTextFile = fileReader.result;
      this.preProcessImport();
    };
    fileReader.readAsText(importFile);
  }

  private preProcessImport() {
    this.preprocessSuccessMessage = null;
    this.preprocessErrorMessage = null;

    let lines = this.rawTextFile.split(/\r?\n/);

    if (this.firstRowHasHeadings) {
      if (Utils.csvToArray(lines[0]) === null) {
        this.preprocessErrorMessage = this.translate.get('views.driver-driver-import-invalidFileType', {
          linesLength: lines.length,
          importFilename: this.importFilename
        });
        return;
      } else {
        const [driverIdHeader, nameHeader, ...unexpected] = Utils.csvToArray(lines[0]);
        if (nameHeader.toUpperCase() === 'DRIVER NAME' && driverIdHeader.toUpperCase() === 'DRIVER ID') {
          lines.splice(0, 1);
        } else {
          this.preprocessErrorMessage = this.translate.get('views.driver-driver-import-invalidFileType', {
            linesLength: lines.length,
            importFilename: this.importFilename
          });
          return;
        }
      }
    }


    // filter out any blank lines
    lines = lines.filter(line => line.trim().length > 0);

    this.validDriverLines = [];
    const unexpectedLines = [];
    const incompleteLines = [];
    let lineNum = this.firstRowHasHeadings ? 1 : 0;

    lines.forEach(line => {
      lineNum++;
      const [driverId, name, ...unexpected] = Utils.csvToArray(line); // line.split(',');
      if (name === undefined || driverId === undefined) {
        incompleteLines.push({ lineNum, line });
      } else if (unexpected.length > 0) {
        unexpectedLines.push({ lineNum, line });
      } else {
        this.validDriverLines.push({ name, driverId });
      }
    });

    const duplicateError = this.checkDuplicates(lines);
    const numInvalidLines = unexpectedLines.length + incompleteLines.length;
    const numValidLines = this.validDriverLines.length;
    const numTotalLines = numValidLines + numInvalidLines;

    this.preProcessSuccess = (numInvalidLines === 0 && numValidLines > 0 && duplicateError.length === 0);

    if (this.preProcessSuccess) {
      // tslint:disable-next-line:max-line-length
      this.preprocessSuccessMessage = this.translate.get('views.driver-vehicle-import-preprocessSuccessMessage', { linesLength: lines.length, importFilename: this.importFilename });

    } else {

      if (numValidLines === 0) {
        this.preprocessErrorMessage = this.translate.get('views.driver-vehicle-import-preprocessErrorNoLinesMessage');

      } else if (incompleteLines.length > 0) {
        const [{ lineNum: firstIncompleteLineNum }] = incompleteLines;

        // tslint:disable-next-line:max-line-length
        this.preprocessErrorMessage = this.translate.get('views.driver-vehicle-import-preprocessErrorMessage', { numInvalidLines, numTotalLines, importFilename: this.importFilename, firstLineNum: firstIncompleteLineNum, incompleteLinesLength: incompleteLines.length - 1 });

      } else if (unexpectedLines.length > 0) {
        const [{ lineNum: firstUnexpectedLineNum }] = unexpectedLines;

        // tslint:disable-next-line:max-line-length
        this.preprocessErrorMessage = this.translate.get('views.driver-vehicle-import-preprocessErrorMessage', { numInvalidLines, numTotalLines, importFilename: this.importFilename, firstLineNum: firstUnexpectedLineNum, incompleteLinesLength: unexpectedLines.length - 1 });
      } else if (duplicateError.length > 0) {
        // tslint:disable-next-line: max-line-length
        this.preprocessErrorMessage = this.translate.get('views.driver-vehicle-import-preprocessErrorDuplicateDriverIds', {driverIds: duplicateError});
      }
    }
  }

  private checkDuplicates(lines) {
    const allLines = lines;
    const duplicates = allLines.map((line) => {
      const l = line.split(',');
      return l[0];
    }).reduce((acc, e, i, a) => {
      // if the element exists at an earlier point in the array (this is not the first instance)
      // and if the element doesn't exist in the accumulator (this is the first duplicate)
      if (a.indexOf(e) !== i && acc.indexOf(e) < 0) {
        acc.push(e);
      }
      return acc;
    }, []);
    return duplicates.join(', ');
  }
}
