import { FormControl } from '@angular/forms';

const invalidNameList: Array<string> = ['auto', 'default', 'rv', 'motorcycle', 'bicycle', 'walking',
'light duty', 'medium duty', 'heavy duty', 'temp', 'new profile'];

export function validRoutingProfileName(control: FormControl): { [s: string]: boolean } {
  let val = control.value;

  if (val === null) {
    return null;
  }
  val = val.toLowerCase();

  if (invalidNameList.indexOf(val) !== -1) {
    return {isNameValid: true};
  }

  if (isCharAllowed(val)) {
    return {isCharAllowed: true};
  }

  if (!/^(?!.*\s\s)\S(.*\S)?$/.test(val)) {
    return {multipleSpaces: true};
  }

  function isCharAllowed(valChar: string) {
    const charsNotAllowed = ['^', '/', ':', '\\'];
    if (charsNotAllowed.some(v => valChar.indexOf(v) !== -1)) {
      return true;
    }
  }
}
