import { of as observableOf } from 'rxjs';
import { switchMap, tap, distinctUntilChanged, debounceTime } from 'rxjs/operators';
// angular components
import { EventEmitter, OnInit, OnChanges, OnDestroy, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
// 3rd party components
import { ModalDirective } from 'ngx-bootstrap';
var GroupAddModal = /** @class */ (function () {
    function GroupAddModal(el) {
        this.el = el;
        // tslint:disable-next-line: variable-name
        this._allPossibleItems = [];
        this.excludedGroups = [];
        // number of rows of groups to display per page
        this.displayPerPage = 10;
        // what are these groups of? if vehicles, use 'Vehicle' here so it will display as 'Vehicle Groups'
        this.entityLabel = '';
        this.itemsAdded = new EventEmitter();
        this.isLoading = true;
        this.allSelected = false;
        this.searchTerm = new FormControl();
        this.total = 0;
        this.currentPage = 0;
        // allAvailableItems = allPossibleItems - excludedGroups
        this.allAvailableItems = [];
        this.filteredItems = [];
        this.displayedItems = [];
    }
    Object.defineProperty(GroupAddModal.prototype, "columnDefinitions", {
        set: function (columnDefinitions) {
            this._columnDefinitions = columnDefinitions;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GroupAddModal.prototype, "allPossibleItems", {
        get: function () {
            return this._allPossibleItems;
        },
        set: function (allPossibleItems) {
            this._allPossibleItems = allPossibleItems;
            this.isLoading = false;
        },
        enumerable: true,
        configurable: true
    });
    GroupAddModal.prototype.ngOnInit = function () {
        var _this = this;
        this.sub1 = this.modal.onShown.subscribe(function () {
            // todo: make this another directive to auto focus bootstrap modal fields.
            var nativeElement = _this.el.nativeElement.getElementsByTagName('input')[0];
            if (nativeElement) {
                nativeElement.focus();
            }
        });
        this.searchTerm.valueChanges.pipe(debounceTime(200), distinctUntilChanged(), tap(function () {
            _this.isLoading = true;
            _this.currentPage = 0;
        }), switchMap(function (searchTerm) { return _this.filterResults(searchTerm); }))
            .subscribe(function (filteredResults) {
            _this.filteredItems = filteredResults;
            _this.updateCurrentPage();
            _this.isLoading = false;
        });
    };
    GroupAddModal.prototype.ngOnDestroy = function () {
        this.sub1.unsubscribe();
    };
    GroupAddModal.prototype.ngOnChanges = function () {
        this.entityHeading = 'Select ' + this.entityLabel; // todo: translate
    };
    GroupAddModal.prototype.open = function () {
        this.allSelected = false;
        this.currentPage = 0;
        // this.selectedIds = {};
        this.clearSearchTerm();
        this.getAvailableItems();
        this.allAvailableItems.forEach(function (item) { return item.selected = false; });
        this.displayedItems.forEach(function (item) { return item.selected = false; });
        this.updateCurrentPage();
        this.modal.show();
    };
    GroupAddModal.prototype.clearSearchTerm = function () {
        this.searchTerm.setValue('');
    };
    GroupAddModal.prototype.onAllChanged = function (isSelected) {
        var _this = this;
        this.allSelected = isSelected;
        this.displayedItems.forEach(function (item) { return item.selected = _this.allSelected; });
    };
    GroupAddModal.prototype.select = function (item, isSelected) {
        item.selected = isSelected;
        // update all-selected checkbox whenever one of the individual checkboxes is selected
        // or after loading a page of results
        this.allSelected = this.isAllSelected();
    };
    GroupAddModal.prototype.saveSelected = function () {
        var selectedGroups = [];
        this.allAvailableItems.forEach(function (item) {
            if (item.selected) {
                selectedGroups.push({ id: item.id, fields: item.fields, pendingAddition: true });
            }
        });
        this.itemsAdded.emit(selectedGroups);
        this.modal.hide();
    };
    GroupAddModal.prototype.nextPage = function () {
        if (!this.hasNextPage()) {
            return;
        }
        this.currentPage++;
        this.updateCurrentPage();
    };
    GroupAddModal.prototype.previousPage = function () {
        if (!this.hasPreviousPage()) {
            return;
        }
        this.currentPage--;
        this.updateCurrentPage();
    };
    GroupAddModal.prototype.hasNextPage = function () {
        var nextPageStartIndex = (this.currentPage + 1) * this.displayPerPage;
        return this.filteredItems.length > nextPageStartIndex;
    };
    GroupAddModal.prototype.hasPreviousPage = function () {
        return this.currentPage > 0;
    };
    GroupAddModal.prototype.updateCurrentPage = function () {
        this.isLoading = true;
        var startIndex = this.currentPage * this.displayPerPage;
        var endIndex = startIndex + this.displayPerPage;
        this.displayedItems = this.filteredItems.slice(startIndex, endIndex);
        this.allSelected = this.isAllSelected();
        this.isLoading = false;
    };
    GroupAddModal.prototype.pageStart = function () {
        if (this.displayedItems.length === 0) {
            return 0;
        }
        return (this.currentPage * this.displayPerPage) + 1;
    };
    GroupAddModal.prototype.pageEnd = function () {
        var retVal = (this.currentPage * this.displayPerPage) + this.displayPerPage;
        if (retVal > this.filteredItems.length) {
            retVal = this.filteredItems.length;
        }
        return retVal;
    };
    GroupAddModal.prototype.isAllSelected = function () {
        // tslint:disable-next-line: prefer-for-of
        for (var i = 0; i < this.displayedItems.length; i++) {
            if (!this.displayedItems[i].selected) {
                return false;
            }
        }
        return true;
    };
    GroupAddModal.prototype.getAvailableItems = function () {
        var _this = this;
        // remove the excluded groups
        this.allAvailableItems = this.allPossibleItems.slice(); // clone
        this.excludedGroups.forEach(function (excludedGroup) {
            var idx = _this.allAvailableItems.map(function (group) { return group.id; }).indexOf(excludedGroup.id);
            if (idx >= 0) {
                _this.allAvailableItems.splice(idx, 1);
            }
        });
        this.total = this.allAvailableItems.length;
        this.filterResults(this.searchTerm.value)
            .subscribe(function (filteredResults) {
            _this.filteredItems = filteredResults;
            _this.updateCurrentPage();
        });
    };
    GroupAddModal.prototype.filterResults = function (searchTerm) {
        if (!searchTerm) {
            return observableOf(this.allAvailableItems);
        }
        searchTerm = searchTerm.toUpperCase();
        // TODO: right now this is filtering on all results and not honoring the GroupItemColumn.isFilterable setting
        return observableOf(this.allAvailableItems.filter(function (item) {
            var found = 0;
            item.fields.forEach(function (field) {
                if (field != null && field.toString().toUpperCase().indexOf(searchTerm) >= 0) {
                    found++;
                }
            });
            return (found > 0);
        }));
    };
    return GroupAddModal;
}());
export { GroupAddModal };
