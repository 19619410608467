<div class="app-container">
  <app-header></app-header>

  <div [ngClass]="{ 'content-container': showHeaderFooter !== false }">
    <router-outlet></router-outlet>
  </div>

  <footer *ngIf="showHeaderFooter">
    <div class="pull-left">
      <alk-copyright></alk-copyright> |
      <a [routerLink]="['/terms']">Terms of Use</a> |
      <a
        href="https://kb.omnitracs.com/Omnitracs_One/CoPilot/"
        rel="noopener noreferrer"
        target="_blank"
        >Contact Support</a
      >
    </div>
    <div class="pull-right">
      <div id="user-info" class="pull-right">
        {{ user.userName }} ({{ user.roles }}),
        {{ user.currentPartnerAccountName ? user.currentPartnerAccountName : "" }}
      </div>
    </div>
  </footer>
</div>
<data-retention-policy-modal
  #dataRetentionPolicyModal
></data-retention-policy-modal>
