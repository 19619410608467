import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, Observer, of } from 'rxjs';
import { CompanySettingsService } from '../../admin/company-settings';
import { ConfirmationModal } from '../../shared';
import { GroupAssociationsComponent } from '../../shared/group-associations';
import { VehicleGroupService } from '../../vehicle-group';
import { CustomMapset } from '../shared/custom-mapset.model';
import { MapsetsService } from '../shared/mapsets.service';
import { Mode } from '../shared/mode.enum';
import { VehicleGroup } from '../shared/vehicle-group.model';
import { MapRegionsComponent } from './map-regions/map-regions.component';
import { CanComponentDeactivate } from './mapsets-detail.guard.service';
@Component({
  providers: [MapsetsService],
  templateUrl: './mapsets-detail.component.html',
  styleUrls: ['./mapsets-detail.component.scss'],
})
export class MapsetsDetailComponent implements OnInit, CanComponentDeactivate {
  @ViewChild('vehicleGroupAssociations', { static: true })
  vehicleGroupAssociations: GroupAssociationsComponent;
  @ViewChild(MapRegionsComponent, { static: false })
  regions: MapRegionsComponent;
  @ViewChild('confirmModal', { static: true }) confirmModal: ConfirmationModal;
  @ViewChild('confirmLeave', { static: true }) confirmLeave: ConfirmationModal;

  public mapset: CustomMapset;
  private allVehicleGroups: VehicleGroup[] = [];
  private mode = Mode.Create;
  public errorMsg;
  public showSuccessMessage = false;
  public showHyperlinkError = false;
  public hyperlinkErrorType: Observable<string>;
  public MAPSET_NAME_CHARACTER_LIMIT = 64;
  private isSaving = false;
  MAX_SELECTED_REGIONS = 5;
  private mapSetSettings: any;

  constructor(
    private mapsetsService: MapsetsService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private vehicleGroupService: VehicleGroupService,
    private companySettingsService: CompanySettingsService
  ) {
    this.init();
  }

  private init() {
    this.route.data.subscribe((x) => {
      if (x.justCreated) {
        this.showSuccessMessage = true;
      }
    });
    this.companySettingsService.getMapSetSettings().subscribe((x) => {
      this.mapSetSettings = x;
      this.MAX_SELECTED_REGIONS = this.mapSetSettings.maxMapSetAssignments;
    });
    this.route.params.subscribe((params) => {
      if (!Number.isNaN(+params.id)) {
        this.mode = Mode.Edit;
        this.mapsetsService.getMapsetById(+params.id).subscribe((x) => {
          this.mapset = x;
          this.vehicleGroupAssociations.initialItems =
            this.mapset.vehicleGroups.length > 0
              ? this.mapset.vehicleGroups.map((g) => {
                  return {
                    id: g.vehicleGroupId,
                    fields: [g.vehicleGroupName],
                    pendingRemoval: false,
                    pendingAddition: false,
                  };
                })
              : new Array();
        });
      } else {
        this.mapset = {
          vehicleGroups: new Array(),
          mapRegions: new Array(),
          defaultMapset: false,
        };
      }
    });
    this.route.params.subscribe((params) => {
      if (!Number.isNaN(+params.id)) {
        this.mode = Mode.Edit;
        this.mapsetsService.getMapsetById(+params.id).subscribe((x) => {
          this.mapset = x;
        });
      } else {
        this.mapset = {
          vehicleGroups: new Array(),
          mapRegions: new Array(),
          defaultMapset: false,
        };
      }
    });
  }
  ngOnInit() {
    this.vehicleGroupService.getGroups().subscribe((x) => {
      x.forEach((v) => {
        this.allVehicleGroups.push({
          vehicleGroupId: v.id,
          vehicleGroupName: v.name,
        });
      });
      this.vehicleGroupAssociations.allPossibleItems =
        this.allVehicleGroups.map((v) => {
          return {
            id: v.vehicleGroupId,
            fields: [v.vehicleGroupName],
            pendingRemoval: false,
            pendingAddition: false,
          };
        });
    });
    this.vehicleGroupAssociations.columnDefinitions = [
      { title: 'Name', isFilterable: true },
    ];
  }

  public get headerText(): string {
    return this.mode === Mode.Create
      ? 'views.mapsets.CreateMapset'
      : 'views.mapsets.EditMapset';
  }

  public confirmSetAsDefault() {
    if (!this.mapset.defaultMapset) {
      this.translate
        .get('views.mapsets-details.SetAsDefault')
        .subscribe((x) => (this.confirmModal.title = x));
      this.translate
        .get('views.mapsets-details.DefaultConfirmation')
        .subscribe((x) => (this.confirmModal.body = x));
    } else {
      this.translate
        .get('views.mapsets-details.RemoveAsDefault')
        .subscribe((x) => {
          this.confirmModal.title = x;
        });
      this.translate
        .get('views.mapsets-details.RemoveDefaultConfirmation')
        .subscribe((x) => {
          this.confirmModal.body = x;
        });
    }
    this.confirmModal.open().then(
      (x) => {
        this.mapset.defaultMapset = !this.mapset.defaultMapset;
      },
      () => {}
    );
  }

  private contains(arr, id) {
    if (arr.indexOf(id) >= 0) {
      return true;
    }
    return false;
  }

  public save() {
    this.errorMsg = null;
    this.showHyperlinkError = false;
    this.isSaving = true;
    const pendingRemoval: number[] = new Array();
    const pendingAddition: number[] = new Array();
    for (const group of this.allVehicleGroups) {
      if (
        this.contains(
          this.vehicleGroupAssociations.initialItems.map((x) => x.id),
          group.vehicleGroupId
        ) &&
        !this.contains(
          this.vehicleGroupAssociations.resultItems.map((x) => x.id),
          group.vehicleGroupId
        )
      ) {
        pendingRemoval.push(group.vehicleGroupId);
      }
      if (
        !this.contains(
          this.vehicleGroupAssociations.initialItems.map((x) => x.id),
          group.vehicleGroupId
        ) &&
        this.contains(
          this.vehicleGroupAssociations.resultItems.map((x) => x.id),
          group.vehicleGroupId
        )
      ) {
        pendingAddition.push(group.vehicleGroupId);
      }
    }
    this.mapset.mapRegions = this.regions.buildMapsets();
    if (this.mapset.mapRegions.length < 1) {
      this.errorMsg = 'At least one mapset must be selected';
    } else {
      if (this.mode === Mode.Create) {
        // don't navigate back to list view on save to maintain consistency throughout the app
        this.mapset.customMapsetName = this.mapset.customMapsetName.trim();
        this.mapsetsService.createMapset(this.mapset).subscribe(
          (created: any) => {
            if (pendingAddition.length > 0) {
              this.mapsetsService
                .assignVehicleGroupsToMapset(
                  created.data.customMapsetId,
                  pendingAddition
                )
                .subscribe(
                  (x) => {
                    this.router.navigateByUrl(
                      `mapsets/created/${created.data.customMapsetId}`
                    );
                  },
                  (error) => {
                    this.errorMsg = error.error.message;
                    this.router.navigateByUrl(
                      `mapsets/created/${created.data.customMapsetId}`
                    );
                  },
                  () => {
                    this.resetInitialMapset();
                  }
                );
            } else {
              this.router.navigateByUrl(
                `mapsets/created/${created.data.customMapsetId}`
              );

            }
          },
          (error) => (this.errorMsg = error.error.message)
        );
      } else {
        this.mapsetsService.updateMapset(this.mapset).subscribe(
          (x: any) => {
            let vehicleGroups = this.vehicleGroupAssociations.initialItems.map(vehicle => vehicle.id);
            if (pendingAddition.length > 0) {
              for (const pendingVehicle of pendingAddition) {
                vehicleGroups.push(pendingVehicle)
              }
            }
            if (pendingRemoval.length > 0) {
              for (const pendingVehicle of pendingRemoval)  {
                vehicleGroups = vehicleGroups.filter(vehicle => vehicle !== pendingVehicle)
              }
            }
            this.mapsetsService.assignVehicleGroupsToMapset(x.data.customMapsetId, vehicleGroups).subscribe(
              (y) => {},
              (error) => {
                const errorCode = error && error.error && error.error.error && error.error.error.errorCode
                  ? error.error.error.errorCode : -1;
                switch(errorCode) {
                  case -7011:
                    this.hyperlinkErrorType = this.translate.get('views.vehicle-group-detail.error.TooManyMapsetsVehicle');
                    this.showHyperlinkError = true;
                    break;
                  case -7022:
                    this.hyperlinkErrorType = this.translate.get('views.vehicle-group-detail.error.TooManyMapsetsVehicleGroup');
                    this.showHyperlinkError = true;
                    break;
                  default:
                    this.errorMsg = error.error.message;
                }
              },
              () => {
                this.resetInitialMapset();
              }
            );
            this.showSuccessMessage = true;
            this.isSaving = false;
            // put mapset object to update mapset api
          },
          (error) => {
            const errorCode = error && error.error && error.error.errorCode ? error.error.errorCode : -1;
            switch(errorCode) {
              case -7011:
                this.hyperlinkErrorType = this.translate.get('views.vehicle-group-detail.error.TooManyMapsetsVehicle');
                this.showHyperlinkError = true;
                break;
              case -7022:
                this.hyperlinkErrorType = this.translate.get('views.vehicle-group-detail.error.TooManyMapsetsVehicleGroup');
                this.showHyperlinkError = true;
                break;
              default:
                this.errorMsg = error.error.message;
            }
            this.isSaving = false;
          }
        );
      }
    }
  }
  private resetInitialMapset() {
    this.regions.updateInitialMapset(); // set initial mapset to what the mapset is currently for saving validation
    this.vehicleGroupAssociations.initialItems =
      this.vehicleGroupAssociations.resultItems;
  }

  canDeactivate(): Observable<boolean> {
    if (!this.isSaving && this.regions.mapsetHasChanged()) {
      return new Observable((observer: Observer<boolean>) => {
        const dialog = this.confirmLeave.open();
        dialog.then((x) => {
          observer.next(x);
          observer.complete();
        });
      });
    }
    return of(true);
  }
}
