<div class="container">

  <div class="message-panel">
    <div *ngIf="(errorMessage | async)" class="alert alert-danger alert-dismissible" role="alert">
      <button type="button" class="close" (click)="errorMessage=null"><span>&times;</span></button>
      <strong>{{errorMessage | async}}</strong>
    </div>
  </div>

  <div class="panel page-panel">

    <div class="row panel action-panel">
      <div class="col-xs-4">&nbsp;</div>
      <div class="col-xs-4">
        <h4 class="text-center">
          <span [innerHtml]="'views.vehicle-summary.Title' | translate"></span>
        </h4>
      </div>
      <div class="col-xs-4">&nbsp;</div>
    </div>

    <div class="row header-panel">
      <label class="col-xs-3">
        <span [innerHtml]="'views.vehicle-summary.Name' | translate"></span>
        <input type="text" class="form-control input-sm" id="summary-name" readonly [ngModel]="vehicle?.name | stringIfNull:'[unknown]'" />
      </label>

      <label class="col-xs-3">
        <span [innerHtml]="'views.vehicle-summary.VehicleID' | translate"></span>
        <input type="text" class="form-control input-sm" id="summary-id" readonly [ngModel]="vehicle?.vehicleId | stringIfNull:'[unknown]'" />
      </label>

      <label class="col-xs-3">
        <span [innerHtml]="'views.vehicle-summary.DataVersion' | translate"></span>
        <input type="text" class="form-control input-sm" id="summary-data-version" readonly [ngModel]="vehicle?.mapDataVersion | stringIfNull:'[unknown]'" />
      </label>

      <label class="col-xs-3">
        <span [innerHtml]="'views.vehicle-summary.SoftwareVersion' | translate"></span>
        <input type="text" class="form-control input-sm" id="summary-software-version" readonly [ngModel]="vehicle?.appVersion | stringIfNull:'[unknown]'" />
      </label>
    </div>
    <div class="row header-panel">
      <label class="col-xs-3">
        <span [innerHtml]="'views.vehicle-summary.LastCommunicationRequest' | translate"></span>
        <select class="form-control input-sm" (change)="onChange($event.target.value)">
         <option *ngFor="let request of recentRequests;" [value]="request.requestId" >{{request.operation}}</option>
       </select>
      </label>

      <label class="col-xs-3">
        <span [innerHtml]="'views.vehicle-summary.LastCommunicationTime' | translate"></span>
        <input type="text" class="form-control input-sm" id="summary-name" readonly [ngModel]="coPilotRequest?.dateTime | stringIfNull:'[unknown]'" />
      </label>

      <label class="col-xs-3">
         <button class="btn btn-default view" type="button" [disabled]="showViewRequest()" *ngIf = "userContext.isAuthorizedToViewDeviceRequest()" (click)="vehicleRequestResponseModal.open(true, coPilotRequest.requestId)" title = "View Request from {{startDate}}">
           <span [innerHtml]="'View Request' | translate"></span>
         </button>
      </label>
    </div>

    <!-- Re visit this, Hidden and NgIfs do not work on tabs -->
    <div class="row tab-panel">

      <tabset [ngClass]="{'hideVehicleGroups': !canViewVehicleGroups, 'hideSpeedCoachingProfiles': !this.userContext.isSpeedCoachingEnabled(), 'hideRoutingProfiles': !this.userContext.isRoutingProfileEnabled(), 'hideConfigProfiles': !this.userContext.isConfigurationProfileEnabled()}">
        <tab heading="Vehicle Groups">
          <div class="read-only-list-panel">
            <group-associations #vehicleGroupAssociations entityLabel="Vehicle Groups" [displayPerPage]="5" [readonly]="true"></group-associations>
          </div>
        </tab>

        <tab heading="Configuration Profiles" [active] = "!canViewVehicleGroups">
          <div class="read-only-list-panel">
            <group-associations #configProfileAssociations entityLabel="Configuration Profiles" [displayPerPage]="5" [readonly]="true"></group-associations>
          </div>
        </tab>

       <tab heading="SpeedCoaching Profiles">
          <div class="read-only-list-panel">
            <group-associations #speedCoachingProfileAssociations entityLabel="SpeedCoaching Profiles" [displayPerPage]="5" [readonly]="true"></group-associations>
          </div>
        </tab>

        <tab heading="Routing Profiles">
          <div class="read-only-list-panel">
             <group-associations #routingProfileAssociations entityLabel="Routing Profiles" [displayPerPage]="5" [readonly]="true"></group-associations>
          </div>
        </tab>

        <tab heading="Route Modifier Sets">
          <div class="read-only-list-panel">
            <group-associations #avoidFavorSetAssociations entityLabel="Avoid Favor Sets" [displayPerPage]="5" [readonly]="true"></group-associations>
          </div>
        </tab>

        <tab heading="CustomPlace Sets">
          <div class="read-only-list-panel">
            <group-associations #customPlaceSetAssociations entityLabel="Custom Place Sets" [displayPerPage]="5" [readonly]="true"></group-associations>
          </div>
        </tab>
      </tabset>
    </div>
  </div>
</div>

<vehicle-request-response-modal #vehicleRequestResponseModal></vehicle-request-response-modal>
