import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { TranslateService } from '@ngx-translate/core';

import { ALKLanguageContext } from './../../../alk-components/alk-translations';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'terms',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './terms.component.html'
})
// tslint:disable-next-line: component-class-suffix
export class Terms implements OnInit {
  termsHtml: SafeHtml;
  errorMessage: Observable<string>;

  constructor(
    private http: HttpClient,
    private languageContext: ALKLanguageContext,
    private sanitizer: DomSanitizer,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    const url = `/assets/terms/${this.languageContext.getLanguage()}/terms.html`;

    this.http.get<any>(url, {responseType: 'text' as 'json'}).subscribe(resp => {
      this.termsHtml = this.sanitizer.bypassSecurityTrustHtml(resp);

    }, () => this.errorMessage = this.translate.get('views.terms.UnknownError'));
  }
}
