// angular components
import { Injectable } from '@angular/core';

// alk common components

import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

export class AlkCloudApp {
  constructor(public currentTOS: number, public productTitle: string,
              public productText: string, public productLicensed: boolean,
              public productAppUrl: string, public productInfoUrl: string,
              public productExpired: boolean, public productImgSrc: string,
              public showUnlicensedMessage: boolean) {
  }
}

@Injectable()
export class AlkCloudService {

  constructor(private http: HttpClient) { }

  public getLicensedApps() {
    const url = environment.apiUrl + '/v1/user/licensed-apps';
    return this.http.get(url).map(c => this.convertToAlkApp(c));
  }

  private convertToAlkApp(json) {
    const results: Array<AlkCloudApp> = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < json.length; i++) {
      results.push(new AlkCloudApp(
        json[i].currentTermsOfUse,
        json[i].name
          .replace(/^ALK /, '')
          .replace(/^CoPilot /, ''),
        json[i].description,
        json[i].licensed,
        json[i].homeUrl,
        json[i].homeUrl,
        json[i].expired,
        '/assets/img/' + json[i].imageSource,
        false)
      );
    }

    return results;
  }
}
