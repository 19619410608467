<div class="container">

  <div class="message-panel">
    <div *ngIf="errorMsg" class="row alert alert-danger" role="alert">
      <label [innerHtml]="'common.Error' | translate"></label>
      <span>{{errorMsg}}</span>
    </div>
  </div>

  <div class="panel list-page-panel">

    <div class="row panel action-panel">
      <div class="col-xs-4">&nbsp;</div>
      <div class="col-xs-4">
        <h4 class="text-center">
        <span [innerHtml]="'views.driver-group-list.Title' | translate"></span>
      </h4>
      </div>
      <div class="col-xs-4">&nbsp;</div>
    </div>

    <div class="row configure-panel">
      <div class="filter-panel">
        <div class="panel-body">
          <label class="col-xs-4">
            <span [innerHtml]="'views.driver-group-list.Filter' | translate"></span>
            <input type="text" class="form-control" placeholder="" [formControl]="searchTerm" (keydown.escape)="clearSearchTerm()"/>
          </label>
          <div class="col-xs-4"></div>
          <div class="col-xs-4 text-right filter-add">
            <a class="btn btn-default" [routerLink]="['/driver-groups', 'new']" *ngIf="addButtonVisible">
              <span class="ico ico-plus"></span>
              <span [innerHtml]="'views.driver-group-list.NewDriverGroup' | translate"></span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row grid-panel">
      <table class="table table-striped table-hover list-table clickable-row">
        <thead>
          <tr>
            <th (click)="sortAsc=!sortAsc;sortCol='name';sortString('name', sortAsc)" class="sortable-column driver-group-name-col">
              <span [innerHtml]="'views.driver-group-list.Name' | translate"></span>
              <span class="column-arrows">
                <span *ngIf="sortCol=='name'" class="glyphicon" [ngClass]="{'glyphicon-triangle-top': sortAsc, 'glyphicon-triangle-bottom': !sortAsc}"></span>
              </span>
            </th>
            <th (click)="sortAsc=!sortAsc;sortCol='numberOfDrivers';sortNumber('numberOfDrivers', sortAsc)" class="col-md-2 sortable-column">
              <span [innerHtml]="'views.driver-group-list.NumberOfDrivers' | translate"></span>
              <span class="column-arrows">
                <span *ngIf="sortCol=='numberOfDrivers'" class="glyphicon" [ngClass]="{'glyphicon-triangle-top': sortAsc, 'glyphicon-triangle-bottom': !sortAsc}"></span>
              </span>
            </th>
            <th style="width:120px;">

            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let group of pageItems; let i = index">
            <td [routerLink]="['/driver-groups', group.id]" class="driver-group-name-col">
              <span [innerHtml]="group.name | alkHighlight:searchTerm.value" title="{{group.name}}"></span>
            </td>
            <td [routerLink]="['/driver-groups', group.id]">
              {{group.numberOfDrivers}}
            </td>
            <td class="text-right">
              <a [routerLink]="['/driver-groups', group.id]" title="{{'common.Edit' | translate}}" class="btn btn-default" *ngIf="editButtonVisible">
                <span class="glyphicon glyphicon-edit"></span>
              </a>
              <button type="button" (click)="deleteGroup(group)" title="{{'common.Delete' | translate}}" class="btn btn-default" *ngIf="deleteButtonVisible">
              <span class="ico ico-trash"></span>
            </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row pagination-panel">
      <div class="col-md-4 grid-details">
        {{ 'common.DisplayOf' | translate:{pageStartNumber:pageStart(), pageEndNumber:pageEnd(), total:filteredDriverGroups.length} }}
      </div>
      <div class="col-md-8">
        <div class="pull-right">
          <button type="button" class="btn btn-default" (click)="previousPage()" [disabled]="!hasPreviousPage()">
          <span class="glyphicon glyphicon-menu-left"></span>
          <span [innerHtml]="'common.Previous' | translate"></span>
        </button>
          <button type="button" class="btn btn-default" (click)="nextPage()" [disabled]="!hasNextPage()">
          <span [innerHtml]="'common.Next' | translate"></span>
          <span class="glyphicon glyphicon-menu-right"></span>
        </button>
        </div>
      </div>
    </div>

  </div>
</div>

<confirmation-modal #confirmModal title="Delete Driver Group" body="You are deleting this driver group. Are you sure?"></confirmation-modal>
