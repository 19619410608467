export class GroupsUtil {
    // Generate comma separated string from list of groups
    static getAbbreviatedGroupsName(groups: Array<any>, maxGroupsToDisplay: number) {
        let abbreviatedGroupsName = '';
        abbreviatedGroupsName = groups
            .sort((a, b) => {
              return (a.name > b.name) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0);
            })
            .map(c => c.name)
            .slice(0, maxGroupsToDisplay).join(', ');

        if (groups.length > maxGroupsToDisplay) {
            abbreviatedGroupsName += `, (${groups.length - maxGroupsToDisplay} more)... `;
        }
        return abbreviatedGroupsName;
    }

    // Generate consolidated groups name info for tooltip
    static getToolTipforGroups(groups: any[]) {
        if (!groups || groups.length === 0) { return ''; }
        return groups.map(c => c.name).join(', ');
    }
}
