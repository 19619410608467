import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  template: "<router-outlet></router-outlet> ",
  styles: [
    `
      :host {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TidComponent {}
