// vehicle types

export const NAMetricHeavyDuty = {
  unitsOfMeasure: 'Metric',
  distanceUnits: 'Kilometers',
  fuelUnits: 'Liters',
  vehicleType: 'Truck',
  truckDimensions: 'TrailerOrTwins53',
  maxHeight: 4.11,
  maxWidth: 2.59,
  totalLength: 16.15,
  totalWeight: 80000,
  totalWeightPerAxle: 34000,

  roadPreferencesFreewaysAFType: 'Neutral',
  roadPreferencesFreewaysSpeed: 43,
  roadPreferencesDividedHighwaysAFType: 'Neutral',
  roadPreferencesDividedHighwaysSpeed: 31,
  roadPreferencesPrimaryRoadsAFType: 'Neutral',
  roadPreferencesPrimaryRoadsSpeed: 25,
  roadPreferencesSecondaryRoadsAFType: 'Neutral',
  roadPreferencesSecondaryRoadsSpeed: 16,
  roadPreferencesLocalStreetsAFType: 'Neutral',
  roadPreferencesLocalStreetsSpeed: 12,
  classOverrides: 3,
  nationalNetwork: true,
  fiftyThreeFootTrailer: true,
  propane: false
};

export const NAMetricMediumDuty = {
  unitsOfMeasure: 'Metric',
  distanceUnits: 'Kilometers',
  fuelUnits: 'Liters',
  vehicleType: 'MidsizeTruck',
  truckDimensions: 'Custom',
  maxHeight: 4.11,
  maxWidth: 2.44,
  totalLength: 7.92,
  totalWeight: 26000,
  totalWeightPerAxle: 20000,
  numAxles: 2,
  roadPreferencesFreewaysAFType: 'Neutral',
  roadPreferencesFreewaysSpeed: 43,
  roadPreferencesDividedHighwaysAFType: 'Neutral',
  roadPreferencesDividedHighwaysSpeed: 31,
  roadPreferencesPrimaryRoadsAFType: 'Neutral',
  roadPreferencesPrimaryRoadsSpeed: 25,
  roadPreferencesSecondaryRoadsAFType: 'Neutral',
  roadPreferencesSecondaryRoadsSpeed: 16,
  roadPreferencesLocalStreetsAFType: 'Neutral',
  roadPreferencesLocalStreetsSpeed: 12,
  classOverrides: 0,
  nationalNetwork: false,
  fiftyThreeFootTrailer: false,
  propane: false
};

export const NAMetricLightDuty = {
  unitsOfMeasure: 'Metric',
  distanceUnits: 'Kilometers',
  fuelUnits: 'Liters',
  vehicleType: 'LightDuty',
  truckDimensions: 'Custom',
  maxHeight: 2.13,
  maxWidth: 2.44,
  totalLength: 6.10,
  totalWeight: 8500,
  totalWeightPerAxle: 7500,
  numAxles: 2,
  roadPreferencesFreewaysAFType: 'Neutral',
  roadPreferencesFreewaysSpeed: 43,
  roadPreferencesDividedHighwaysAFType: 'Neutral',
  roadPreferencesDividedHighwaysSpeed: 31,
  roadPreferencesPrimaryRoadsAFType: 'Neutral',
  roadPreferencesPrimaryRoadsSpeed: 25,
  roadPreferencesSecondaryRoadsAFType: 'Neutral',
  roadPreferencesSecondaryRoadsSpeed: 16,
  roadPreferencesLocalStreetsAFType: 'Neutral',
  roadPreferencesLocalStreetsSpeed: 12,
  displayRestrictions: 'BasedOnTruckRestrictions',
  classOverrides: 0,
  nationalNetwork: false,
  fiftyThreeFootTrailer: false,
  hazmatType: 'None'
};

export const NAMetricAuto = {
  unitsOfMeasure: 'Metric',
  distanceUnits: 'Kilometers',
  fuelUnits: 'Liters',
  vehicleType: 'Auto',
  roadPreferencesFreewaysAFType: 'Neutral',
  roadPreferencesFreewaysSpeed: 43,
  roadPreferencesDividedHighwaysAFType: 'Neutral',
  roadPreferencesDividedHighwaysSpeed: 31,
  roadPreferencesPrimaryRoadsAFType: 'Neutral',
  roadPreferencesPrimaryRoadsSpeed: 25,
  roadPreferencesSecondaryRoadsAFType: 'Neutral',
  roadPreferencesSecondaryRoadsSpeed: 16,
  roadPreferencesLocalStreetsAFType: 'Neutral',
  roadPreferencesLocalStreetsSpeed: 12,
  displayRestrictions: 'BasedOnTruckRestrictions',
  classOverrides: 0,
  nationalNetwork: false,
  fiftyThreeFootTrailer: false,
  hazmatType: 'None'
};

export const NAMetricBus = {
  unitsOfMeasure: 'Metric',
  distanceUnits: 'Kilometers',
  fuelUnits: 'Liters',
  vehicleType: 'Bus',
  truckDimensions: 'Custom',
  maxHeight: 3.32,
  maxWidth: 2.44,
  totalLength: 12.19,
  totalWeight: 33000,
  totalWeightPerAxle: 23000,
  numAxles: 2,
  classOverrides: 0,
  nationalNetwork: false,
  fiftyThreeFootTrailer: false,
  hazmatType: 'None',
  trailerHeight: 0
};

// preset truck dimensions
export const NAMetric48Foot = {
  truckDimensions: 'SemiTrailer48',
  maxHeight: 4.11,
  maxWidth: 2.44,
  totalLength: 14.63,
  totalWeight: 80000,
  totalWeightPerAxle: 34000,
  numAxles: 2,
  nationalNetwork: false,
  fiftyThreeFootTrailer: false
};

export const NAMetric53Foot = {
  truckDimensions: 'TrailerOrTwins53',
  maxHeight: 4.11,
  maxWidth: 2.59,
  totalLength: 16.15,
  totalWeight: 80000,
  totalWeightPerAxle: 34000,
  numAxles: 5
};

export const NAMetric28Foot = {
  truckDimensions: 'DoubleTrailers28',
  maxHeight: 4.11,
  maxWidth: 2.59,
  totalLength: 17.07,
  totalWeight: 80000,
  totalWeightPerAxle: 34000,
  numAxles: 2,
  lcv: true,
  trailerCount: 2,
  trailerAxleCount: 3,
  trailerHeight: 0,
  tireCount: 22
};

export const NAMetric40Foot = {
  truckDimensions: 'StraightTruck40',
  maxHeight: 4.11,
  maxWidth: 2.44,
  totalLength: 12.19,
  totalWeight: 45000,
  totalWeightPerAxle: 34000,
  numAxles: 3,
  nationalNetwork: false,
  fiftyThreeFootTrailer: false,
  trailerCount: 0,
  trailerAxleCount: 0,
  trailerHeight: 0,
  tireCount: 0
};


export const NAMetricCustom = {
  truckDimensions: 'Custom',
  maxHeight: 4.11,
  maxWidth: 2.59,
  totalLength: 16.15,
  totalWeight: 80000,
  totalWeightPerAxle: 34000,
  numAxles: 5,
  nationalNetwork: true
};
