// angular components
import { Component, ViewChild, Input } from '@angular/core';

// 3rd party components
import { ModalDirective } from 'ngx-bootstrap';

@Component({
  selector: 'confirmation-modal',
  templateUrl: './confirmation-modal.component.html'
})
// tslint:disable-next-line: component-class-suffix
export class ConfirmationModal {
  @ViewChild('modal', { static: true }) modal: ModalDirective;

  @Input() title: string;
  @Input() body: string;

  private resolve;
  private reject;

  open(): Promise<boolean> {
    this.modal.show();

    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  onOKClicked() {
    this.modal.hide();
    this.resolve(true);
  }

  onCancelClicked() {
    this.modal.hide();
    this.reject(true);
  }
}

