import { HttpClient, HttpHeaders } from "@angular/common/http";
// angular components
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { TidService } from "../tid/tid.service";
import { UserContextService } from "./../../../alk-components/authentication";

@Injectable()
export class AuthenticationService {
  constructor(
    private http: HttpClient,
    private userContext: UserContextService,
    private tidService: TidService
  ) {}

  logout(): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=UTF-8",
    });
    const options = { headers };
    const url = environment.ssoUrl + "/v2/security/logout";
    const user = this.userContext.getUser();

    if (!user) {
      return new Observable();
    }

    const body = {
      authToken: this.userContext.getUser().authToken,
    };

    this.userContext.clearUser();
    sessionStorage.removeItem("fleetsAPIToken");

    return this.http.post(url, body, options);
  }

  loginWithTid(authCode: string, account?: string) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=UTF-8",
    });
    const options = { headers, withCredentials: true };
    const url = environment.ssoUrl + "/v1/trimbleid/authenticate";
    sessionStorage.removeItem("fleetsAPIToken");

    return new Promise((resolve, reject) => {
      const body = {
        authorizationCode: authCode,
        redirectUrl: `${location.origin}/tid/redirect`,
        applicationName: environment.applicationName,
        account,
        trimbleApplication: environment.tidAppName,
      };

      this.http
        .post(url, body, options)
        .pipe(
          map((c) => c),
          tap((user: any) => {
            this.tidService.setIdToken(user ? user.idToken : null);
          })
        )
        .subscribe(
          this.handleAuthenticationSuccess(resolve, reject),
          this.handleError(reject)
        );
    }) as Promise<any>;
  }

  cookieLogin(): Promise<any> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=UTF-8",
    });
    const options = { headers, withCredentials: true };
    const url = environment.ssoUrl + "/v2/security/verify";
    const body = {
      product: environment.applicationName,
    };
    sessionStorage.removeItem("fleetsAPIToken");

    return new Promise((resolve, reject) => {
      return this.http
        .post(url, body, options)
        .pipe(map((c) => c))
        .subscribe(
          this.handleAuthenticationSuccess(resolve, reject),
          this.handleError(reject)
        );
    });
  }

  getUserInfo(authToken: string): Promise<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + authToken,
      }),
    };
    const url = environment.apiUrl + "/v1/user";

    return new Promise((resolve, reject) => {
      return this.http
        .get(url, options)
        .pipe(map((c) => c))
        .subscribe(
          this.handleSuccess(resolve, reject),
          this.handleError(reject)
        );
    });
  }

  getFleetsApiToken(): Observable<any> {
    const fleetApi = environment.fleetApiUrl + "api/assets/v1";
    if (sessionStorage.fleetsAPIToken) {
      return new Observable((x) => {
        x.next(sessionStorage.fleetsAPIToken);
        x.complete();
      });
    } else {
      return this.http
        .post(`${fleetApi}/accounts/webAuthenticate`, {
          tokenExpression: `token=${this.userContext.getUser().authToken}`,
        })
        .pipe(
          map((x: any) => {
            sessionStorage.fleetsAPIToken = x.token;
            return x.token as string;
          })
        );
    }
  }

  private handleAuthenticationSuccess(resolve, reject) {
    const me = this;
    return (ssoUser) => {
      this.userContext.isAzureAccount = (ssoUser.cloudProvider === 'Azure');
      me.getUserInfo(ssoUser.authToken).then(
        (userInfo) => {
          resolve(userInfo);
        },
        (err) => {
          reject(err);
        }
      );
    };
  }

  private handleSuccess(resolve, reject) {
    const me = this;
    return (ssoUser) => {
      if (ssoUser.isValid === true && ssoUser.user !== null) {
        me.userContext.setUser(ssoUser.user);
        resolve(ssoUser);
      } else {
        me.logout();
        reject(ssoUser);
      }
    };
  }

  private handleError(reject) {
    const me = this;
    return (error) => {
      me.logout();
      reject(error);
    };
  }
}
