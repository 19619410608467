import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Angulartics2GoogleAnalytics } from "angulartics2/ga";
import { UserContextService } from "../../../../../alk-components/authentication";
import { AuthenticationService } from "../../../authentication";
import { configureTrackJs } from "../../../utils/configureTrackJs";
import { TidService } from "../../tid.service";

@Component({
  templateUrl: "./redirect.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RedirectComponent implements OnInit {
  private redirectUrl = "/vehicle-groups";

  constructor(
    private userContext: UserContextService,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private tidService: TidService,
    private ag2analytics: Angulartics2GoogleAnalytics
  ) {}

  ngOnInit() {
    this.userContext.clearUser();
    this.userContext.clearSelectedPartnerAccount();

    const authCode = this.route.snapshot.queryParams.code;
    this.authenticationService.loginWithTid(authCode).then(
      (ssoResponse) => {
        this.redirectUrl = this.userContext.getRedirectUrl(this.redirectUrl);
        ssoResponse.user.currentPartnerAccountId = ssoResponse.user.accountId;
        configureTrackJs(ssoResponse.user, this.ag2analytics);
        this.router.navigateByUrl(this.redirectUrl);
      },
      (error) => this.handleSSOError(error)
    );
  }

  private handleSSOError(error) {
    if (
      error.error &&
      error.error.status === "FailedToLoginDueToMultipleAccounts"
    ) {
      let accounts: { displayName: string; value: string }[] = [];
      if (
        error.error &&
        Array.isArray(error.error.accounts) &&
        error.error.accounts.length > 0
      ) {
        accounts =
          error.error.accounts.map((account: { accountName: string }) => ({
            displayName: account.accountName,
            value: account.accountName,
          })) || [];
      }

      this.tidService.accounts$.next(accounts);
    } else {
      this.tidService.removeAuthCode();

      this.tidService.errorMessage$.next(
        error.error && error.error.reason
          ? error.error.reason
          : "Failed to login."
      );
    }

    this.router.navigateByUrl("/login");
  }
}
