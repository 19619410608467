import _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

// angular components
import { Component, OnInit, ViewChild } from '@angular/core';

// alk
import { UserContextService } from '../../../alk-components/authentication';
import { MapsetsService } from '../shared/mapsets.service';
import { ConfirmationModal } from '../../shared';

@Component({
  providers: [MapsetsService],
  templateUrl: './connection-settings.component.html',
  styleUrls: ['./connection-settings.component.scss']
})
export class ConnectionSettingsComponent implements OnInit {

  @ViewChild('confirmModal', { static: true }) confirmModal: ConfirmationModal;

  public isProcessing = false;
  public errorMsg = '';
  public blacklistedConnections: Array<object> = null;
  public isWifiOnly = true;
  public wifiName = '';
  public isFullAccess = false;
  public originalValues: any = {};
  public showSuccessMessage = false;

  constructor(
    private translateService: TranslateService,
    private mapsetsService: MapsetsService,
    private userContext: UserContextService) { }

  public ngOnInit() {
    this.isProcessing = true;

    this.mapsetsService.getConnectionSettings()
      .subscribe(resp => {
        this.blacklistedConnections = resp.blackListedWifiNames;
        this.isWifiOnly = resp.isWifiOnly;
        this.originalValues.blacklistedConnections = resp.blackListedWifiNames.slice();
        this.originalValues.isWifiOnly = resp.isWifiOnly;
      }, error => {
        this.errorMsg = 'There was an error retrieving current connection settings: ' + error.message;
      }, () => {
        this.isProcessing = false;
      });

    this.isFullAccess = !(this.userContext.getUser().roles[0].toUpperCase() === 'USER');
  }

  public deleteConnection(index) {
    this.confirmModal.open().then(() => {
      this.blacklistedConnections.splice(index, 1);
    });
  }

  public addConnection(name) {
    if (this.isValidWifiName()) {
      this.blacklistedConnections.push({wifiName: name.trim()});
      this.wifiName = '';
    }
  }

  public isValidAdd() {
    return this.isValidWifiName() && this.isFullAccess;
  }

  public isValidWifiName() {
    return this.wifiName.trim() !== '';
  }

  public isValidSave() {
    return this.isFullAccess && (this.originalValues.isWifiOnly !== this.isWifiOnly ||
      !_.isEqual(this.blacklistedConnections, this.originalValues.blacklistedConnections));
  }

  public save() {
    this.isProcessing = true;

    this.mapsetsService.updateConnectionSettings(this.blacklistedConnections, this.isWifiOnly)
      .subscribe(resp => {
        this.originalValues.blacklistedConnections = this.blacklistedConnections.slice();
        this.originalValues.isWifiOnly = this.isWifiOnly;
        this.showSuccessMessage = true;
      }, error => {
        this.errorMsg = 'There was an error updating current connection settings: ' + error.message;
      }, () => {
        this.isProcessing = false;
      });
  }
}
