
import {of as observableOf, forkJoin as observableForkJoin, from as observableFrom,  Observable ,  Subscription } from 'rxjs';

import {pluck} from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { ConfigurationProfilesService, VehicleGroupService, SpeedCoachingProfileService } from './../../vehicle-group/shared';
import { AccountManagementService } from './../../../alk-components/account-management';
import { ConfirmationModal, min, max, incrementBy } from './../../shared';
import { GroupAssociationsComponent } from './../../shared/group-associations';
import { UserContextService } from '../../../alk-components/authentication';
import {convertKMToMile, convertMileToKM} from './../../shared/utils/convertUtil';

@Component({
  templateUrl: './speed-coaching-detail.component.html',
  providers: [SpeedCoachingProfileService, ConfigurationProfilesService, VehicleGroupService, AccountManagementService]
})
// tslint:disable-next-line: component-class-suffix
export class SpeedCoachingDetail implements OnInit, OnDestroy {
  public id: number;
  public ownerId: number;
  public unitType: string;
  public speedUnit: string;
  public showUnitConversionTooltip = false;
  public isNew: boolean;
  public isProcessing = false;
  public isInitializing = true;

  public submitted = false;

  public errorMessage: Observable<string>;
  public successMessage: Observable<string>;

  public validation;

  public form: FormGroup;

  public defaultProfile: any;
  public translateParams: object;
  public isDefaultToggleDirty = false;

  @ViewChild('confirmModal', { static: true }) confirmModal: ConfirmationModal;
  @ViewChild('changeDefaultModal', { static: true }) changeDefaultModal: ConfirmationModal;
  @ViewChild('setDefaultConfirmModal', { static: true }) setDefaultConfirmModal: ConfirmationModal;
  @ViewChild('vehicleGroupAssociations', { static: true }) vehicleGroupAssociations: GroupAssociationsComponent;

  private sub1: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userContext: UserContextService,
    private speedCoachingProfileService: SpeedCoachingProfileService,
    private fb: FormBuilder,
    private vehicleGroupService: VehicleGroupService,
    private translate: TranslateService
  ) {
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(64)]],
      isDefault: [false],

      // speed Coaching properties
      warningSpeedLimit: [2, Validators.compose([
        min(1), max(30), incrementBy(1), Validators.required
      ])],
      warningTimeLimit: [15, Validators.compose([
        min(1), max(600), incrementBy(1), Validators.required
      ])],
      infractionSpeedLimit: [2, Validators.compose([
        min(1), max(30), incrementBy(1), Validators.required
      ])],
      infractionTimeLimit: [15, Validators.compose([
        min(1), max(600), incrementBy(1), Validators.required
      ])]
    });

    const user = this.userContext.getUser();
    this.translateParams = { user };
  }

  public canDeactivate(): Observable<boolean> | boolean {
    if (!this.isDefaultToggleDirty && !this.form.dirty &&
      this.vehicleGroupAssociations.itemsPendingAddition.length === 0 &&
      this.vehicleGroupAssociations.itemsPendingRemoval.length === 0) { return true; }

    return observableFrom(this.confirmModal.open());
  }

  public ngOnInit() {

    const user = this.userContext.getUser();
    this.unitType = user.unitType;
    this.speedUnit = this.unitType === 'English' ? 'Miles' : 'Kilometers';
    this.showUnitConversionTooltip = user.enableUnitConversion;

    this.sub1 = this.route.params.pipe(pluck('id'))
      .subscribe(id => {
        this.isInitializing = true;

        // we determine if the page is for a new configuration profile or
        // an existing configuration profile based on the id ('new' is for a new profile)
        this.isNew = isNaN(+id);

        const profileObs = this.isNew ? this.loadNewProfile() : this.loadExistingProfile(+id);

        observableForkJoin([
          this.vehicleGroupService.getGroups(),
          profileObs
        ]).subscribe(result => {
          this.id = result[1].speedCoachingProfileId;
          this.ownerId = result[1].ownerId;
          this.loadForm(result[1]);

          this.vehicleGroupAssociations.columnDefinitions = [
            { title: 'Name', isFilterable: true }
          ];

          if (result[1].vehicleGroups) {
            this.vehicleGroupAssociations.initialItems = result[1].vehicleGroups.map(group => {
              return {
                id: group.id,
                fields: [group.name],
                pendingRemoval: false,
                pendingAddition: false
              };
            });
          }

          this.vehicleGroupAssociations.allPossibleItems = result[0].map(group => {
            return {
              id: group.id,
              fields: [group.name],
              pendingRemoval: false,
              pendingAddition: false
            };
          });

          // finished loading page, update flag to let ui know to not display loading indicator
          this.isInitializing = false;
        }, error => {
          const fieldName = 'views.speed-coaching-list.errors.ForbiddenRetrievingSpeedCoachingProfiles';
          this.errorMessage = error.statusText === 'Forbidden' ? this.translate.get(fieldName)
          : this.translate.get('views.speed-coaching-list.errors.UnknownRetrievingSpeedCoachingProfiles');
          this.isInitializing = false;
        });

      });
  }
  public isDefaultToggle() {

    const selectedElementCurDefaultVal = !this.form.controls.isDefault.value;

    if (!selectedElementCurDefaultVal) {
      this.translate.get('views.speed-coaching-profile-list.UnassignDefaultTitle').subscribe(res => {
        this.setDefaultConfirmModal.title = res;
      });
      this.translate.get('views.speed-coaching-profile-list.UnassignDefaultBody').subscribe(res => {
        this.setDefaultConfirmModal.body = res;
      });
    } else {
      this.translate.get('views.speed-coaching-profile-list.SetDefaultTitle').subscribe(res => {
        this.setDefaultConfirmModal.title = res;
      });
      this.translate.get('views.speed-coaching-profile-list.ConfirmDefaultBody').subscribe(res => {
        this.setDefaultConfirmModal.body = res;
      });
    }

    this.setDefaultConfirmModal.open()
      .then(() => {
        this.form.controls.isDefault.setValue(selectedElementCurDefaultVal);
        this.isDefaultToggleDirty = true;
      });
  }
  public loadForm(attributes) {

    for (const attr in attributes) {
      if (!attributes.hasOwnProperty(attr)) { continue; }

      const control =  this.form.controls[attr] as FormControl;
      if (!control) { continue; }

      control.setValue(attributes[attr]);
    }
  }

  public loadNewProfile(): Observable<any> {
    const profile = this.speedCoachingProfileService.getDefaultProfile();
    return observableOf(profile);
  }

  public loadExistingProfile(id: number): Observable<any> {
    return this.speedCoachingProfileService.getProfile(id);
  }

  public ngOnDestroy() {
    this.sub1.unsubscribe();
  }

  public save() {
    this.submitted = true;
    this.isDefaultToggleDirty = false;

    // clear error and success messages when saving
    this.errorMessage = null;
    this.successMessage = null;

    if (!this.form.valid) {
      return;
    }
    this.form.value.name = this.form.controls.name.value.trim();

    this.isProcessing = true;

    let promise: Promise<any>;

    // if this is a new speed coaching profile save the profile details first
    if (this.isNew) {
      promise = this.saveProfileDetails();

      // only need to save the vehicle groups if we have any
      if (this.vehicleGroupAssociations.resultItems.length > 0) {
        promise = promise.then(() => this.saveVehicleGroups());
      }

    } else {
      /* Used to execute both in parallel but there was a timing issue.
       * And than we should come back to it to see what caused the issue */
      promise = this.saveProfileDetails();
      promise = promise.then(() => this.saveVehicleGroups());
    }

    promise.then(() => {
      this.successMessage = this.translate.get('views.speed-coaching-profile-detail.success.SaveSuccess');

      // once the page is successfully saved, mark this form as pristine/untouched.
      this.form.markAsPristine();

      if (this.isNew) {
        this.router.navigate(['/speed-coaching', this.id]);
      }

      this.isProcessing = false;

    }, error => {
      this.isProcessing = false;

      if (error.code === -2) {
        this.errorMessage = this.translate.get('views.speed-coaching-detail.errors.MaxReached');

      } else if (error.code === -3) {
        this.errorMessage = this.translate.get('views.speed-coaching-detail.errors.NameExists');
      } else if (error.code === 403) {
        this.errorMessage = this.translate.get('views.speed-coaching-list.errors.ForbiddenSpeedCoachingProfiles');
      } else {
        this.errorMessage = this.translate.get('views.speed-coaching-detail.errors.UnknownSave');
      }

    });
    this.submitted = false; // back to clean state.
  }

  public cancel() {
    this.router.navigate(['/speed-coaching']);
  }

  public showWarningSpeedLimitConversionInfo() {
    if (this.unitType === 'English') {
      return `${convertMileToKM(this.form.controls.warningSpeedLimit.value).toString()} kph`;
    }
    return `${convertKMToMile(this.form.controls.warningSpeedLimit.value).toString()} mph`;
  }

  public showInfractionSpeedLimitConversionInfo() {
    if (this.unitType === 'English') {
      return `${convertMileToKM(this.form.controls.infractionSpeedLimit.value).toString()} kph`;
    }
    return `${convertKMToMile(this.form.controls.infractionSpeedLimit.value).toString()}  mph`;
  }

  private saveProfileDetails(): Promise<any> {
    if (this.isNew) {
      return this.speedCoachingProfileService.create(this.form.value).toPromise().then(newProfile => {
        this.id = newProfile.speedCoachingProfileId;
        return newProfile;
      });

    } else {
      return this.speedCoachingProfileService.update(this.id, this.ownerId, this.form.value).toPromise();
    }
  }

  private saveVehicleGroups(): Promise<any> {
    return this.speedCoachingProfileService.associateVehicleGroups(
      this.id,
      this.vehicleGroupAssociations.resultItems.map(r => r.id)
    ).toPromise().then(() => {
      this.vehicleGroupAssociations.initialItems = this.vehicleGroupAssociations.resultItems;
      this.vehicleGroupAssociations.resetInitialData();
    });
  }

}
