// angular components
import { Component, OnInit } from '@angular/core';

// alk shared components
import { AccountManagementService, Account } from './../../alk-components/account-management';
import { UserContextService } from './../../alk-components/authentication';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { CompanySettingsService } from './company-settings';

@Component({
  selector: 'admin',
  providers: [AccountManagementService],
  templateUrl: './admin.component.html',
  styles: [`
    .share-data-div {
      margin-top:16px;
      padding-left:15px;
    }
    .data-setting-text {
      padding-left:5px;
    }
  `]
})
// tslint:disable-next-line: component-class-suffix
export class Admin implements OnInit {
  currentUser: any;
  currentAccount: Account;
  passwordChanged = false;
  accountUpdated = false;
  vehiclesTabVisible = true;
  driversTabVisible = false;
  usersTabVisible = true; // only to change the order of tabs
  isRouteReporterAccount = false;
  shareDataAnonymously = false;

  constructor(
    private userContext: UserContextService,
    private accountService: AccountManagementService,
    private ag2analytics: Angulartics2GoogleAnalytics,
    private companySettings: CompanySettingsService) {
    this.currentAccount = new Account();

    const partnerActivities = this.userContext.getUser().partner.partnerActivities;
    this.vehiclesTabVisible = partnerActivities.canViewVehicles;
    this.driversTabVisible = partnerActivities.canViewDrivers;

  }

  ngOnInit() {
    this.currentUser = this.userContext.getUser();
    this.accountService.isRouteReporterAccount().subscribe(
       result => {
        this.isRouteReporterAccount = result;
      }
    );

    this.accountService.getAccount().subscribe(data => this.currentAccount = data);
    this.companySettings.getDataSharingSettings().subscribe(data => {
      this.shareDataAnonymously = data.shareDataAnonymously;
    });
  }

  onAccountUpdated(account) {
    this.currentAccount = account;
    this.accountUpdated = true;
    setTimeout(() => this.accountUpdated = false, 2500);
  }

  onShareDataUpdated(shareData) {
    this.shareDataAnonymously = shareData;
  }

  onPasswordChanged() {
    this.passwordChanged = true;
    setTimeout(() => this.passwordChanged = false, 2500);
  }

  onUsersTabSelected() {
    this.ag2analytics.eventTrack('Users tab selected', {});
  }

  onVehiclesTabSelected() {
    this.ag2analytics.eventTrack('Vehicles tab selected', {});
  }

  onDriversTabSelected() {
    this.ag2analytics.eventTrack('Drivers tab selected', {});
  }

}
