<div class="container">

  <div class="message-panel">

    <div *ngIf="errorMsg | async" class="row alert alert-danger" role="alert">
      <label [innerHtml]="'common.Error' | translate"></label>
      <span>{{errorMsg | async}}</span>
    </div>

  </div>

  <div class="panel list-page-panel">

    <div class="row panel action-panel">
      <div class="col-xs-4">&nbsp;</div>
      <div class="col-xs-4">
        <h4 class="text-center">
          <span [innerHtml]="'views.routing-profile-list.Title' | translate"></span>
        </h4>
      </div>
      <div class="col-xs-4">&nbsp;</div>
    </div>

    <div class="row configure-panel">
      <div class="filter-panel">
        <div class="panel-body">
          <label class="col-xs-4">
          <span [innerHtml]="'common.Filter' | translate"></span>
          <input type="text" class="form-control" placeholder="" [formControl]="searchTerm" (keydown.escape)="clearSearchTerm()"/>
        </label>
          <div class="col-xs-4">
          </div>
          <div class="col-xs-4 text-right filter-add">
            <a class="btn btn-default" [routerLink]="['/routing-profiles', 'new']">
              <span class="ico ico-plus"></span>
              <span [innerHtml]="'views.routing-profile-list.NewProfile' | translate"></span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row grid-panel">
      <table class="table table-striped table-hover list-table clickable-row">
        <thead>
          <tr>
            <th (click)="sortAsc=!sortAsc;sortCol='name';sortString('name', sortAsc)" class="sortable-column routing-profile-name-col">
              <span [innerHtml]="'views.vehicle-group-list.Name' | translate"></span>
              <span class="column-arrows">
              <span *ngIf="sortCol=='name'" class="glyphicon" [ngClass]="{'glyphicon-triangle-top': sortAsc, 'glyphicon-triangle-bottom': !sortAsc}"></span>
              </span>
            </th>
            <th (click)="sortAsc=!sortAsc;sortCol='vehicleTypeDisplay';sortString('vehicleTypeDisplay', sortAsc)" class="sortable-column routing-profile-vehicleType-col">
              <span [innerHtml]="'views.vehicle-group-list.VehicleType' | translate"></span>
              <span class="column-arrows">
              <span *ngIf="sortCol=='vehicleTypeDisplay'" class="glyphicon" [ngClass]="{'glyphicon-triangle-top': sortAsc, 'glyphicon-triangle-bottom': !sortAsc}"></span>
              </span>
            </th>
            <th (click)="sortAsc=!sortAsc;sortCol='routingTypeDisplay';sortString('routingTypeDisplay', sortAsc)" class="sortable-column routing-profile-routingType-col">
              <span [innerHtml]="'views.vehicle-group-list.RoutingType' | translate"></span>
              <span class="column-arrows">
              <span *ngIf="sortCol=='routingTypeDisplay'" class="glyphicon" [ngClass]="{'glyphicon-triangle-top': sortAsc, 'glyphicon-triangle-bottom': !sortAsc}"></span>
              </span>
            </th>
            <th (click)="sortAsc=!sortAsc;sortCol='truckDimensionsDisplay';sortString('truckDimensionsDisplay', sortAsc)" class="sortable-column routing-profile-dimensions-col">
              <span [innerHtml]="'views.vehicle-group-list.Dimensions' | translate"></span>
              <span class="column-arrows">
              <span *ngIf="sortCol=='truckDimensionsDisplay'" class="glyphicon" [ngClass]="{'glyphicon-triangle-top': sortAsc, 'glyphicon-triangle-bottom': !sortAsc}"></span>
              </span>
            </th>
            <th (click)="sortAsc=!sortAsc;sortCol='hazmatTypeDisplay';sortString('hazmatTypeDisplay', sortAsc)" class="sortable-column routing-profile-hazmat-col">
              <span [innerHtml]="'views.vehicle-group-list.Hazmat' | translate"></span>
              <span class="column-arrows">
              <span *ngIf="sortCol=='hazmatTypeDisplay'" class="glyphicon" [ngClass]="{'glyphicon-triangle-top': sortAsc, 'glyphicon-triangle-bottom': !sortAsc}"></span>
              </span>
            </th>
            <th (click)="sortAsc=!sortAsc;sortCol='numberOfAssignedGroups';sortNumber('numberOfAssignedGroups', sortAsc)" class="sortable-column routing-profile-groups-col" style="width:155px;">
              <span [innerHtml]="'views.routing-profile-list.NumberOfAssignedGroups' | translate"></span>
              <span class="column-arrows">
              <span *ngIf="sortCol=='numberOfAssignedGroups'" class="glyphicon" [ngClass]="{'glyphicon-triangle-top': sortAsc, 'glyphicon-triangle-bottom': !sortAsc}"></span>
              </span>
            </th>
            <th (click)="sortNumber('isDefault', sortAsc);sortAsc=!sortAsc;sortCol='isDefault'" class="sortable-column" style = "width:100px;padding-left:5px;padding-right:0px;">
              <span [innerHtml]="'views.profile-list.Default' | translate"></span>
              <span class="column-arrows">
                <span *ngIf="sortCol=='isDefault'" class="glyphicon" [ngClass]="{'glyphicon-triangle-top': sortAsc, 'glyphicon-triangle-bottom': !sortAsc}"></span>
              </span>
          </th>
            <th style="text-align:right;" class="routing-profile-actions-col">

            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of pageItems; let i = index">
            <td [routerLink]="['/routing-profiles', item.routingProfileId]" class="routing-profile-name-col">
              <span [innerHtml]="item.name | alkHighlight:searchTerm.value" title="{{item.name}}"></span>
            </td>
            <td [routerLink]="['/routing-profiles', item.routingProfileId]">
              <span [innerHtml]="item.vehicleTypeDisplay | alkHighlight:searchTerm.value "></span>
            </td>
            <td [routerLink]="['/routing-profiles', item.routingProfileId]">
              <span [innerHtml]="item.routingTypeDisplay | alkHighlight:searchTerm.value "></span>
            </td>
            <td [routerLink]="['/routing-profiles', item.routingProfileId]" class="routing-profile-dimensions-col">
              <span [innerHtml]="item.truckDimensionsDisplay | alkHighlight:searchTerm.value " title="{{item.truckDimensionsDisplay}}"></span>
            </td>
            <td [routerLink]="['/routing-profiles', item.routingProfileId]">
              <span [innerHtml]="item.hazmatTypeDisplay | alkHighlight:searchTerm.value "></span>
            </td>
            <td [routerLink]="['/routing-profiles', item.routingProfileId]">
              <span>{{item.numberOfAssignedGroups}}</span>
            </td>
            <td>
            <span (click)="isDefaultToggle(item)">
                <span *ngIf="!item.isAccountWideDefault" class="ico ico-checkbox-unchecked"></span>
                <span *ngIf="item.isAccountWideDefault" class="ico ico-checkbox-checked"></span>
              </span>
            </td>
            <td class="text-right">
              <a [routerLink]="['/routing-profiles', item.routingProfileId]" title="{{'common.Edit' | translate}}" class="btn btn-default">
                <span class="glyphicon glyphicon-edit"></span>
              </a>
              <button type="button" (click)="deleteItem(item)" title="{{'common.Delete' | translate}}" class="btn btn-default">
                <span class="ico ico-trash"></span>
              </button>
            </td>
          </tr>
          <tr *ngIf="isProcessing">
            <td colspan="7"> <span class="glyphicon glyphicon-refresh spinner"></span>  {{'common.Loading' | translate}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row pagination-panel">
      <div class="col-md-4 grid-details">
        {{ 'common.DisplayOf' | translate:{pageStartNumber:pageStart(), pageEndNumber:pageEnd(), total:total} }}
      </div>
      <div class="col-md-8">
        <div class="pull-right">
          <button type="button" class="btn btn-default" (click)="previousPage()" [disabled]="!hasPreviousPage()">
          <span class="glyphicon glyphicon-menu-left"></span>
          <span [innerHtml]="'common.Previous' | translate"></span>
        </button>
          <button type="button" class="btn btn-default" (click)="nextPage()" [disabled]="!hasNextPage()">
          <span [innerHtml]="'common.Next' | translate"></span>
          <span class="glyphicon glyphicon-menu-right"></span>
        </button>
        </div>
      </div>
    </div>

  </div>

</div>

<confirmation-modal #setDefaultConfirmModal title="{{ 'views.routing-profile-list.SetDefaultTitle' | translate }}" body="{{ 'views.routing-profile-list.ConfirmDefaultBody' | translate }}"></confirmation-modal>
<confirmation-modal #confirmModal title="{{ 'views.routing-profile-list.DeleteTitle' | translate }}" body="{{ 'views.routing-profile-list.DeleteBody' | translate }}"></confirmation-modal>
<confirmation-modal #deleteGroupWarning title="{{ 'views.routing-profile-list.DeleteWarningTitle' | translate }}" body="{{ 'views.routing-profile-list.DeleteWarningBody' | translate:{numberOfAssignedGroups: numberOfAssignedGroups} }}"></confirmation-modal>
