// angular components
import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';

// 3rd party components
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { ModalDirective } from 'ngx-bootstrap';

// app components
import { AccountUserManagementService } from './../../alk-components/account-user-management';
import { VehicleGroupService } from './../vehicle-group/shared';
import { DriverGroupService } from './../driver-group/shared';
import { VehicleService } from './../vehicle/shared';

import { environment } from '../../environments/environment';

@Component({
  selector: 'vehicle-request-response-modal',
  providers: [AccountUserManagementService, VehicleGroupService, DriverGroupService, VehicleService],
  templateUrl: './vehicle-request-response-modal.component.html'
})

// tslint:disable-next-line: component-class-suffix
export class VehicleRequestResponseModal {
  addTripDetails = false;
  addTimeStamp = false;
  addRevision = false;
  tripId: number;
  timeStamp: string;
  revision: string;
  deviceId: string;
  isProcessing = false;
  submitted = false;
  form: FormGroup;

  emailErrorMsg: Observable<string>;
  errorMsg: Observable<string>;
  errorUserName = '';

  @ViewChild('modal', { static: true }) modal: ModalDirective;

  public request: string;
  public response: string;

  constructor(
    private vehicleService: VehicleService,
    private fb: FormBuilder,
    private ag2analytics: Angulartics2GoogleAnalytics
  ) {
    this.form = this.fb.group({
    });
  }

  close() {
    this.modal.hide();
  }

  open(addMode: boolean, requestId: number) {
    this.vehicleService.getRequestBodyById(requestId)
      .subscribe(results => {
        this.request = results.request;
        this.response = results.response;
        this.addTripDetails = this.request.indexOf('tripId') > -1;
        if (this.addTripDetails) {
          this.tripId = JSON.parse(this.request).tripId;
        }

        // add TimeStamp if the request has non-empty timestamp
        this.addTimeStamp = this.request.indexOf('timeStamp') > -1 && JSON.parse(this.request).timeStamp !== '';
        if (this.addTimeStamp) {
          this.timeStamp = this.getJSTimeFromEpochs(this.request);
        }

        // add revision if the request has non-empty revision
        this.addRevision = this.request.indexOf('revision') > -1 && JSON.parse(this.request).revision !== '';
        if (this.addRevision) {
          this.revision = this.getJSTimeFromTicks(this.request);
        }

        // format the final request and response

        if (this.request !== '') {
          this.request = this.tryParseJson(this.request);
        }

        if (this.response !== '') {
          this.response = this.tryParseJson(this.response);
        }

        this.deviceId = JSON.parse(this.request).deviceId;

        this.errorMsg = null;

        this.ag2analytics.eventTrack(`Request View dialog opened`, {});
        this.modal.show();

      }, error => {
        this.isProcessing = false;

      });

  }

  getJSTimeFromEpochs(request): string {
    const timeStamp: string = JSON.parse(request).timeStamp;
    return this.convertEpochToJSTime(timeStamp);
  }

  getJSTimeFromTicks(request): string {
    const revision: string = JSON.parse(request).revision;
    return this.convertTicksToJSTime(revision);
  }

  /// Converts Epochs to JS Time
  convertEpochToJSTime(timeStamp: string): string {
    const date = new Date(parseFloat(timeStamp.substr(6)));
    const formattedTimeStamp = (date.getMonth() + 1) + '/' +
      date.getDate() + '/' + date.getFullYear() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
    return formattedTimeStamp.toString();
  }

  /// Converts Ticks to JS Time
  convertTicksToJSTime(revision: any): string {
    const ticks = revision;
    const ticksToMicrotime = ticks / 10000;
    const epochMicrotimeDiff = Math.abs(new Date(0, 0, 1).setFullYear(1));
    const tickDate = new Date(ticksToMicrotime - epochMicrotimeDiff);

    const formattedRevision = (tickDate.getMonth() + 1) + '/' +
      tickDate.getDate() + '/' + tickDate.getFullYear() + ' ' + tickDate.getHours() + ':' +
      tickDate.getMinutes() + ':' + tickDate.getSeconds();
    return formattedRevision.toString();
  }

  /// Pretty prints JSON
  tryParseJson(text: string) {
    try {
      const result = JSON.parse(text);
      // Handle cases where where an exception is not raised
      if (result && typeof result === 'object') {
        return JSON.stringify(result, null, '\t');
      }
    } catch (e) { }
  }

  loadTripDetails() {
    const url = environment.fleetViewUrl + `/app/compliance?tripId=` + this.tripId;
    window.open(url);
  }
}
