import { Router } from "@angular/router";
import { TidService } from "../../tid.service";
import * as i0 from "@angular/core";
import * as i1 from "../../tid.service";
import * as i2 from "@angular/router";
var TidGuard = /** @class */ (function () {
    function TidGuard(tidService, router) {
        this.tidService = tidService;
        this.router = router;
    }
    TidGuard.prototype.canActivate = function (route) {
        var code = route.queryParams.code;
        if (!code) {
            this.tidService.removeAuthCode();
            this.router.navigateByUrl("/auth/login");
            return false;
        }
        this.tidService.setAuthCode(code);
        return true;
    };
    TidGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TidGuard_Factory() { return new TidGuard(i0.ɵɵinject(i1.TidService), i0.ɵɵinject(i2.Router)); }, token: TidGuard, providedIn: "root" });
    return TidGuard;
}());
export { TidGuard };
