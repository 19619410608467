
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Account } from './account';

// angular libraries
import { Injectable } from '@angular/core';

// app libraries
import { UserContextService } from './../authentication';

import { environment } from '../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';

@Injectable()
export class AccountManagementService {

  constructor(private http: HttpClient, private userContext: UserContextService) {
  }

  public getDataRetentionPolicy() {
    const url = environment.apiUrl + '/v1/account/dataRetention-policy';
    return this.http.get(url);
  }

  getAccount() {
    const url = environment.apiUrl + '/v1/account';

    return this.http.get<any>(url).pipe(
      map(responseData => {
        const account: Account = new Account();
        account.name = responseData.name;
        account.contactName = responseData.contactName;
        account.contactEmail = responseData.contactEmail;
        account.contactPhone = responseData.contactPhone;
        account.numberOfWeeksToSaveData = responseData.accountSettings.numberOfWeeksToSaveData;

        const currentPartnerName =  this.userContext.getUser().partner.partnerName;
        account.integrationType = this.userContext.isManualIntegration() ? 'Manual Integration' : currentPartnerName;

        return account;
      }),
      catchError(this.serverError), );
  }

  updateAccount(account: Account): Observable<any> {
    const url = environment.apiUrl + '/v1/account';
    const body = JSON.stringify(account);

    return this.http.put(url, body).pipe(
      catchError(this.serverError));
  }

  isRouteReporterAccount() {
    const url = environment.apiUrl + '/v1/account/routeReporterAccountStatus';
    return this.http.get<boolean>(url);
  }

  getVehicleTypes() {
    const url = environment.apiUrl + '/v1/account/VehicleTypes';
    return this.http.get<any>(url);
  }

  private serverError(err: any) {
    if (err instanceof HttpResponse) {
      return observableThrowError(`error: ${err.status}` || 'error');
    }
    return observableThrowError(err || 'error');
  }

}
