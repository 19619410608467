// angular components
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
// 3rd party components
import { Angulartics2GoogleAnalytics } from "angulartics2/ga";
import { combineLatest, Subscription } from "rxjs";
import { filter, take } from "rxjs/operators";
import {
  TmxTidConfig,
  TmxTidEvent,
  TmxTidModule,
  TmxTidService
} from "../../tmx-tid-3.0.2";
import { TidService } from "../shared/tid/tid.service";
// alk shared components
import { UserContextService } from "./../../alk-components/authentication";
// app specific components
import { AuthenticationService } from "./../shared/authentication";

declare var tmxtidmodule: TmxTidModule;

declare var trackJs;

@Component({
  selector: "login",
  templateUrl: "./login.component.html",
})
// tslint:disable-next-line: component-class-suffix
export class Login implements OnInit, OnDestroy {
  public USERNAME_DOES_NOT_EXIST = "USERNAME_DOES_NOT_EXIST";
  public selectedAccount;
  public accounts;
  public creds = { selectedAccount: null };
  public isProcessing = false;
  public message = "";
  private redirectUrl: string;
  private tmxTidService: TmxTidService | null = null;
  private tidEventHandler = (event: TmxTidEvent) => {
    switch (event.type) {
      case "SIGN_IN_USERNAME":
        this.errorMessage$.next(null);
        this.tidService
          .checkMigrationStatus(event.username)
          .pipe(take(1))
          .subscribe({
            next: ({ exists, migrated }) => {
              if (!exists) {
                this.tmxTidService.showSignInUsername();
                this.tmxTidService.setSignInValues();
                this.errorMessage$.next(this.USERNAME_DOES_NOT_EXIST);
                return;
              }

              if (migrated) {
                this.tidService.signInWithTid(event.username);
              } else {
                this.tmxTidService.showSignInConfirm();
                this.tmxTidService.setSignInValues(event.username);
              }
            },
          });
        break;
      case "SIGN_IN_ACCOUNT":
        this.authenticationService
          .loginWithTid(this.tidService.getAuthCode(), event.account)
          .then(
            (ssoResponse) => {
              this.redirectUrl = this.userContext.getRedirectUrl(
                this.redirectUrl
              );
              ssoResponse.user.currentPartnerAccountId =
                ssoResponse.user.accountId;
              this.configureTrackJs(ssoResponse.user);
              this.router.navigateByUrl(this.redirectUrl);
            },
            (error) => this.handleSSOError(error, event.username)
          );
        this.tidService.accounts$.next([]);
        break;
      case "SIGN_IN_CONFIRM_CANCEL":
        this.tmxTidService.showSignInUsername();
        break;
      case "SIGN_IN_TID":
        this.tidService.signInWithTid(event.username, true);
        break;
    }
  };
  private readonly tidConfig: TmxTidConfig = {
    eventHandler: this.tidEventHandler,
  };
  errorMessage$ = this.tidService.errorMessage$;
  accounts$ = this.tidService.accounts$.pipe(
    filter((accounts) => accounts.length > 0)
  );
  subscription: Subscription;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private userContext: UserContextService,
    private route: ActivatedRoute,
    private ag2analytics: Angulartics2GoogleAnalytics,
    private tidService: TidService
  ) {
    // tslint:disable-next-line:no-string-literal
    const r = this.route.snapshot.params["r"]; // redirect to the r query parameter or the home screen afterwards

    if (r) {
      this.redirectUrl = decodeURIComponent(r);
    }

    this.redirectUrl = this.redirectUrl || "/vehicle-groups";
  }

  ngOnInit() {
    this.tidService.checkForComponents$.pipe(take(1)).subscribe({
      next: (exists) => {
        if (!this.tmxTidService && exists) {
          this.tmxTidService = tmxtidmodule.init(this.tidConfig);
        }
      },
    });

    this.subscription = combineLatest([
      this.tidService.checkForComponents$,
      this.accounts$,
    ]).subscribe({
      next: ([exists, accounts]) => {
        if (exists) {
          this.tmxTidService.showSignInAccounts(accounts);
        }
      },
    });
  }

  private handleSSOError(error, username) {
    if (error.error) {
      error = error.error;
    }

    this.isProcessing = false;
    if (error.status !== null && error.status === "FailedToLogin") {
      this.message = "Username or password is incorrect.";
      this.accounts = null;
      this.errorMessage$.next("Username or password is incorrect.");
      this.tmxTidService.showSignInUsername();
      this.tmxTidService.setSignInValues();
    } else if (error.status !== null && error.status === "LoginNotExist") {
      this.errorMessage$.next("User login does not exist.");
      this.tmxTidService.showSignInUsername();
      this.tmxTidService.setSignInValues();
    } else if (
      error.status !== null &&
      error.status === "FailedToLoginDueToMultipleAccounts" &&
      error.accounts !== null &&
      error.accounts.length > 0
    ) {
      this.accounts = error.accounts;
      const accounts: { displayName: string; value: string }[] =
        error.accounts.map((account: { accountName: string }) => ({
          displayName: account.accountName,
          value: account.accountName,
        })) || [];
      this.tidService.accounts$.next(accounts);
      this.creds.selectedAccount = this.accounts[0].accountName;
    } else {
      this.errorMessage$.next("Unknown error while attempting to login.");
      this.tmxTidService.showSignInUsername();
      this.tmxTidService.setSignInValues();
    }

    this.ag2analytics.eventTrack("Login", {
      success: false,
      reason: this.message,
      username,
    });
  }

  private configureTrackJs(ssoUser: any) {
    // add the user information to trackJs
    try {
      trackJs.configure({
        userId: `${ssoUser.userName} (#${ssoUser.userId}), Account ${ssoUser.account} (#${ssoUser.accountId})`,
      });

      this.ag2analytics.setUserProperties({
        userId: ssoUser.userId,
        name: `${ssoUser.firstName} ${ssoUser.lastName}`,
        email: ssoUser.userName,
      });

      this.ag2analytics.eventTrack("Login", {
        success: true,
        reason: "", // no message for success login
        username: ssoUser.userName,
      });
    } catch (err) {}
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.tmxTidService.destroy();
  }
}
