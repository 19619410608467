<div class="container  with-nav terms-page">
  <div class="panel page-panel">

    <div class="row">
      <div class="col-xs-12">
        <terms></terms>
      </div>
    </div>
  </div>
</div>
