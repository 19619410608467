
import {forkJoin as observableForkJoin, Observable, Subscription, EMPTY } from 'rxjs';

import {map, pluck} from 'rxjs/operators';
// angular components
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import {
  VehicleGroupService,
  ConfigurationProfilesService,
  SpeedCoachingProfileService,
  AvoidFavorService,
  CustomPlaceService
} from './../vehicle-group/shared';
import { RoutingProfileService } from './../shared';
import { VehicleService } from './../vehicle/shared';
import { UserContextService } from './../../alk-components/authentication';
import { GroupAssociationsComponent } from './../shared/group-associations';
import { CoPilotRequest } from '../vehicle-summary';
import { formatDateTimePerLocale } from './../shared/utils/dateUtils';
import moment from 'moment';


@Component({
  providers: [
    VehicleGroupService,
    ConfigurationProfilesService,
    RoutingProfileService,
    SpeedCoachingProfileService,
    VehicleService,
    AvoidFavorService,
    CustomPlaceService
  ],
  templateUrl: './vehicle-summary.component.html'
})
// tslint:disable-next-line: component-class-suffix
export class VehicleSummary implements OnInit, OnDestroy {

  @ViewChild('vehicleGroupAssociations', { static: true }) vehicleGroupAssociations: GroupAssociationsComponent;
  @ViewChild('speedCoachingProfileAssociations', { static: true }) speedCoachingProfileAssociations: GroupAssociationsComponent;
  @ViewChild('avoidFavorSetAssociations', { static: true }) avoidFavorSetAssociations: GroupAssociationsComponent;
  @ViewChild('customPlaceSetAssociations', { static: true }) customPlaceSetAssociations: GroupAssociationsComponent;
  @ViewChild('configProfileAssociations', { static: true }) configProfileAssociations: GroupAssociationsComponent;
  @ViewChild('routingProfileAssociations', { static: true }) routingProfileAssociations: GroupAssociationsComponent;

  public isProcessing = false;
  public isRequestAvailable = false;
  public recentRequests: Array<CoPilotRequest>;
  public vehicle: any;
  public coPilotRequest: any;
  public startDate: any;
  public errorMessage: Observable<string>;
  public groupIds: Array<number> = [];
  public canViewVehicleGroups = false;

  private sub1: Subscription;
  private id: number;

  constructor(
    public userContext: UserContextService,
    private vehicleGroupService: VehicleGroupService,
    private vehicleService: VehicleService,
    private avoidFavorService: AvoidFavorService,
    private customPlaceService: CustomPlaceService,
    private speedCoachingProfileService: SpeedCoachingProfileService,
    private configurationProfilesService: ConfigurationProfilesService,
    private routingProfileService: RoutingProfileService,
    private translate: TranslateService,
    private route: ActivatedRoute) {
       const partnerActivities = this.userContext.getUser().partner.partnerActivities;
       this.canViewVehicleGroups = partnerActivities.canViewVehicleGroups;
     }

  ngOnInit() {
    const VEHICLE_INDEX = 0;
    const VEHICLE_GROUPS_INDEX = 1;
    const AVOID_FAVOR_SETS_INDEX = 2;
    const CUSTOM_PLACE_SETS_INDEX = 3;
    const SPEEDCOACHING_PROFILES_INDEX = 6;
    const CONFIG_PROFILE_INDEX = 4;
    const ROUTING_PROFILE_INDEX = 5;

    this.vehicleGroupAssociations.columnDefinitions = [
      { title: 'Name', isFilterable: true },
      { title: '# Vehicles', isFilterable: false }
    ];

    this.configProfileAssociations.columnDefinitions = [
      { title: 'Name', isFilterable: true }
    ];

    this.routingProfileAssociations.columnDefinitions = [
      { title: 'Name', isFilterable: true }
    ];


    this.speedCoachingProfileAssociations.columnDefinitions = [
      { title: 'Name', isFilterable: true }
    ];


    this.avoidFavorSetAssociations.columnDefinitions = [
      { title: 'Name', isFilterable: true },
      { title: 'Active', isFilterable: false },
      { title: 'Last Modified', isFilterable: false }
    ];

    this.customPlaceSetAssociations.columnDefinitions = [
      { title: 'Name', isFilterable: true }
    ];

    this.sub1 = this.route.params.pipe(pluck('id')).subscribe(id => {
      this.isProcessing = true;
      this.id = +id;

      this.vehicleService.getCoPilotRequestDetails(this.id)
        .subscribe(results => {
          results.forEach(result => {
              result.dateTime = formatDateTimePerLocale(result.dateTime, window.navigator.language);
          });
          this.recentRequests = results;
          this.coPilotRequest = results[0];
          // disable view request button if request list is empty
          if (this.recentRequests.length > 0) {
            this.isRequestAvailable = true;
            const date = new Date();
            date.setMonth(date.getMonth() - 1);
            this.startDate = (date.getMonth() + 1) + '/' +
              date.getDate() + '/' + date.getFullYear() + ' ' +
              this.ensureLeadingZero(date.getHours()) + ':' +
              this.ensureLeadingZero(date.getMinutes()) + ':' +
              this.ensureLeadingZero(date.getSeconds());
          }
        }, error => {
          this.isProcessing = false;
          this.isRequestAvailable = false;
        });



      const speedCoachingProfiles = this.userContext.isSpeedCoachingEnabled()
      ? this.getSpeedCoachingProfilesForVehicle(this.id) : EMPTY;

      const apiCallArray = [this.vehicleGroupService.getVehicleDetails(this.id),
      this.getGroupsForVehicle(this.id),
      this.getAvoidFavorSetsForVehicle(this.id),
      this.getCustomPlaceSetsForVehicle(this.id),
      this.getConfigurationProfilesForVehicle(this.id),
      this.getRoutingProfilesForVehicle(this.id)];

      if (this.userContext.isSpeedCoachingEnabled()) {
      apiCallArray.push(speedCoachingProfiles);
    }

      observableForkJoin(apiCallArray).subscribe((results: any) => {
        // set vehicle details
        this.vehicle = results[VEHICLE_INDEX];

        if (this.userContext.isSpeedCoachingEnabled()) {
          this.speedCoachingProfileAssociations.initialItems = results[SPEEDCOACHING_PROFILES_INDEX].map(item => {
            return {
              id: item.speedCoachingProfileId,
              fields: [item.name]
            };
          });
        }

        // set vehicle groups tab
        this.vehicleGroupAssociations.allPossibleItems = results[VEHICLE_GROUPS_INDEX];
        this.vehicleGroupAssociations.initialItems = results[VEHICLE_GROUPS_INDEX];

        this.groupIds = results[1].map(c => c.id);

        this.avoidFavorSetAssociations.initialItems = results[AVOID_FAVOR_SETS_INDEX].map((item, idx) => {
          const modifiedDate = moment(item.modifiedOn).format('LLL');
          const isActive = (idx === 0); // first item is always active, due to OrberBy Modified

          return {
            id: item.setID,
            fields: [item.name, isActive, modifiedDate]
          };
        });

        this.customPlaceSetAssociations.initialItems = results[CUSTOM_PLACE_SETS_INDEX].map(item => {
          return {
            id: item.id,
            fields: [item.name]
          };
        });

        this.configProfileAssociations.initialItems = results[CONFIG_PROFILE_INDEX].map(item => {
          return {
            id: item.id,
            fields: [item.name]
          };
        });

        this.routingProfileAssociations.initialItems = results[ROUTING_PROFILE_INDEX].map(item => {
          return {
            id: item.routingProfileId,
            fields: [item.name]
          };
        });

        this.isProcessing = false;

      }, error => {
        this.errorMessage = this.translate.get('common.UnknownError');
        this.isProcessing = false;
      });

    });
  }

  ngOnDestroy() {
    this.sub1.unsubscribe();
  }

  ensureLeadingZero(dateComponent: number): string {
    return ('0' + dateComponent).slice(-2);
  }

  showViewRequest() {
    return (this.isProcessing || !this.isRequestAvailable);
  }

  getGroupsForVehicle(vehicleId) {
    return this.vehicleGroupService.getGroups(null, [this.id], null, null, null).pipe(
      map(results => {
        return results.map(c => ({ id: c.id, fields: [c.name, c.numberOfVehicles] }));
      }));
  }

  getAvoidFavorSetsForVehicle(vehicleId) {
    return this.avoidFavorService.getAvoidFavorSetsByVehicleId(vehicleId).pipe(
      map(c => c.items));
  }

  getCustomPlaceSetsForVehicle(vehicleId) {
    return this.customPlaceService.getCustomPlaceSetsByVehicleId(vehicleId).pipe(
      map(c => c.items));
  }

  getSpeedCoachingProfilesForVehicle(vehicleId) {
    return this.speedCoachingProfileService.getSpeedCoachingProfilesByVehicleId(vehicleId);
  }

  getConfigurationProfilesForVehicle(vehicleId) {
    return this.configurationProfilesService.getConfigProfilesByVehicleId(vehicleId);
  }

  getRoutingProfilesForVehicle(vehicleId) {
    return this.routingProfileService.getRoutingProfilesByVehicleId(vehicleId);
  }

  onChange(value: string) {
    this.recentRequests.forEach(request => {
      if (String(request.requestId) === value) {
        this.coPilotRequest = request;
      }
    });
  }
}
