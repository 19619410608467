import { tap, distinctUntilChanged, debounceTime } from 'rxjs/operators';
// angular components
import { EventEmitter, OnInit, OnChanges, OnDestroy, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MapsetsService } from '../../../mapsets/shared/mapsets.service';
// 3rd party components
import { ModalDirective } from 'ngx-bootstrap';
var GroupAddPaginatedModal = /** @class */ (function () {
    function GroupAddPaginatedModal(mapsetsService, el) {
        this.mapsetsService = mapsetsService;
        this.el = el;
        // tslint:disable-next-line: variable-name
        this.excludedGroups = [];
        // number of rows of groups to display per page
        this.displayPerPage = 10;
        // what are these groups of? if vehicles, use 'Vehicle' here so it will display as 'Vehicle Groups'
        this.entityLabel = '';
        this.overallTotal = 0;
        this.itemsAdded = new EventEmitter();
        this.isProcessing = true;
        this.allSelected = false;
        this.searchTerm = new FormControl();
        this.currentTotal = 0;
        this.currentPage = 0;
        this.displayedItems = [];
        this.selectedItems = [];
    }
    Object.defineProperty(GroupAddPaginatedModal.prototype, "columnDefinitions", {
        set: function (columnDefinitions) {
            this._columnDefinitions = columnDefinitions;
        },
        enumerable: true,
        configurable: true
    });
    GroupAddPaginatedModal.prototype.ngOnInit = function () {
        var _this = this;
        this.sub1 = this.modal.onShown.subscribe(function () {
            // todo: make this another directive to auto focus bootstrap modal fields.
            var nativeElement = _this.el.nativeElement.getElementsByTagName('input')[0];
            if (nativeElement) {
                nativeElement.focus();
            }
        });
        this.searchTerm.valueChanges.pipe(debounceTime(200), distinctUntilChanged(), tap(function () {
            _this.currentPage = 0;
        })).subscribe(function () {
            _this.updateCurrentPage();
        });
    };
    GroupAddPaginatedModal.prototype.ngOnDestroy = function () {
        this.sub1.unsubscribe();
    };
    GroupAddPaginatedModal.prototype.ngOnChanges = function () {
        this.entityHeading = 'Select ' + this.entityLabel; // todo: translate
    };
    GroupAddPaginatedModal.prototype.open = function () {
        this.allSelected = false;
        this.currentPage = 0;
        this.clearSearchTerm();
        this.updateCurrentPage();
        this.selectedItems = [];
        this.modal.show();
    };
    GroupAddPaginatedModal.prototype.clearSearchTerm = function () {
        this.searchTerm.setValue('');
    };
    GroupAddPaginatedModal.prototype.onAllChanged = function (isSelected) {
        var _this = this;
        this.allSelected = isSelected;
        this.displayedItems.forEach(function (item) {
            _this.updateItem(item, isSelected);
        });
    };
    GroupAddPaginatedModal.prototype.disableAllSelected = function () {
        this.allSelected = false;
    };
    GroupAddPaginatedModal.prototype.select = function (item, isSelected) {
        if (item.disabled === false) {
            this.disableAllSelected();
        }
        this.updateItem(item, isSelected);
    };
    GroupAddPaginatedModal.prototype.updateItem = function (item, isSelected) {
        if (item.disabled === false) {
            item.selected = isSelected;
            var newlySelected = this.selectedItems.some(function (selectedItem) {
                return selectedItem.id === item.id;
            }) === false;
            if (isSelected && newlySelected) {
                this.selectedItems.push(item);
            }
            else if (isSelected === false) {
                this.selectedItems = this.selectedItems.filter(function (selectedItem) {
                    return selectedItem.id !== item.id;
                });
            }
        }
    };
    GroupAddPaginatedModal.prototype.saveSelected = function () {
        var selectedGroups = [];
        this.selectedItems.forEach(function (item) {
            if (item.selected) {
                selectedGroups.push({ id: item.id, fields: item.fields, pendingAddition: true });
            }
        });
        this.itemsAdded.emit(selectedGroups);
        this.modal.hide();
    };
    GroupAddPaginatedModal.prototype.nextPage = function () {
        if (!this.hasNextPage()) {
            return;
        }
        this.currentPage++;
        this.updateCurrentPage();
    };
    GroupAddPaginatedModal.prototype.previousPage = function () {
        if (!this.hasPreviousPage()) {
            return;
        }
        this.currentPage--;
        this.updateCurrentPage();
    };
    GroupAddPaginatedModal.prototype.getItems = function (query) {
        if (this.entityLabel === "Mapsets") {
            return this.mapsetsService.getMapsets(query, { offset: this.currentPage * 10, limit: this.displayPerPage });
        }
    };
    GroupAddPaginatedModal.prototype.formatMapsets = function (itemsResponse) {
        var _this = this;
        this.displayedItems = itemsResponse.data.map(function (result) {
            var regions = result.mapRegions.map(function (region) { return region.mapsetName; }).join(', ');
            var disabled = _this.excludedGroups.some(function (item) {
                return item.id === result.customMapsetId;
            });
            var selected = _this.selectedItems.some(function (item) {
                return item.id === result.customMapsetId;
            }) || disabled ? true : false;
            return {
                id: result.customMapsetId,
                fields: [result.customMapsetName, regions],
                pendingRemoval: false,
                pendingAddition: false,
                selected: selected,
                disabled: disabled
            };
        });
    };
    GroupAddPaginatedModal.prototype.hasNextPage = function () {
        var nextPageStartIndex = (this.currentPage + 1) * this.displayPerPage;
        return this.currentTotal > nextPageStartIndex;
    };
    GroupAddPaginatedModal.prototype.hasPreviousPage = function () {
        return this.currentPage > 0;
    };
    GroupAddPaginatedModal.prototype.pageStart = function () {
        if (this.currentTotal === 0) {
            return 0;
        }
        return (this.currentPage * this.displayPerPage) + 1;
    };
    GroupAddPaginatedModal.prototype.pageEnd = function () {
        var retVal = (this.currentPage * this.displayPerPage) + this.displayedItems.length;
        return retVal;
    };
    GroupAddPaginatedModal.prototype.updateCurrentPage = function () {
        var _this = this;
        this.isProcessing = true;
        this.getItems(this.searchTerm.value)
            .subscribe(function (itemsResponse) {
            if (_this.entityLabel === "Mapsets") {
                _this.formatMapsets(itemsResponse);
            }
            _this.currentTotal = itemsResponse.size;
            _this.isProcessing = false;
            _this.allSelected = false;
        }, function (error) {
            _this.isProcessing = false;
        });
    };
    return GroupAddPaginatedModal;
}());
export { GroupAddPaginatedModal };
