import * as tslib_1 from "tslib";
// third party components
import { BehaviorSubject, Observable } from "rxjs";
var SELECTED_PARTNER_ACCOUNT = "selectedPartnerAccount";
var UserContextService = /** @class */ (function () {
    function UserContextService() {
        // tslint:disable-next-line: variable-name
        this._user = new BehaviorSubject(null);
        // tslint:disable-next-line: variable-name
        this._activePartnerAccount = new BehaviorSubject(null);
        this.isAzureAccount = false;
        var u = localStorage.getItem("user");
        if (u) {
            this._user.next(JSON.parse(u)); // set initial value of user and let subscribers know.
        }
    }
    Object.defineProperty(UserContextService.prototype, "user", {
        get: function () {
            var _this = this;
            return new Observable(function (fn) { return _this._user.subscribe(fn); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserContextService.prototype, "activePartnerAccount", {
        get: function () {
            var _this = this;
            return new Observable(function (fn) { return _this._activePartnerAccount.subscribe(fn); });
        },
        enumerable: true,
        configurable: true
    });
    UserContextService.prototype.clearUser = function () {
        localStorage.removeItem("user");
        this._user.next(null); // let subscribers know the user is cleared
    };
    UserContextService.prototype.clearSelectedPartnerAccount = function () {
        localStorage.removeItem(SELECTED_PARTNER_ACCOUNT);
    };
    UserContextService.prototype.setUser = function (user) {
        user.region = user.regionalDataSet.indexOf("EU") >= 0 ? "EU" : "NA";
        user.speedUnit = user.region === "EU" ? "kmph" : "mph";
        var selectedPartnerAccount = JSON.parse(localStorage.getItem(SELECTED_PARTNER_ACCOUNT));
        if (isPartnerAccountUser(user) && selectedPartnerAccount) {
            user.currentPartnerAccountId = parseInt(selectedPartnerAccount.partnerAccountId, 10);
            user.currentPartnerAccountName = selectedPartnerAccount.partnerAccountName;
        }
        else {
            user.currentPartnerAccountId = user.accountId;
            user.currentPartnerAccountName = user.account;
        }
        localStorage.setItem("user", JSON.stringify(user));
        this._user.next(user); // let subscribers know of the user
        // partner account might have been set initially from login component
        if (user.currentPartnerAccountId) {
            this._activePartnerAccount.next(user.currentPartnerAccountId);
        }
    };
    UserContextService.prototype.setSelectedPartnerAccount = function (partnerAccountId, partnerAccountName) {
        localStorage.setItem(SELECTED_PARTNER_ACCOUNT, JSON.stringify({ partnerAccountId: partnerAccountId, partnerAccountName: partnerAccountName }));
    };
    UserContextService.prototype.setPartnerAccount = function (partnerAccountId, partnerAccountName) {
        var user = this._user.getValue();
        if (user == null) {
            console.error("Attempted to set current partner account when no user context yet exists");
            return;
        }
        // add a property for the current partner account selected
        user.currentPartnerAccountId = partnerAccountId;
        user.currentPartnerAccountName = partnerAccountName;
        localStorage.setItem("user", JSON.stringify(user));
        // let subscribers know of the user and partner account context
        this._user.next(user);
        this._activePartnerAccount.next(partnerAccountId);
    };
    UserContextService.prototype.getUser = function () {
        return this._user.getValue();
    };
    UserContextService.prototype.isAuthenticated = function () {
        return this._user.getValue() != null;
    };
    UserContextService.prototype.hasRole = function (roles) {
        if (!this.isAuthenticated()) {
            return false;
        }
        var user = this._user.getValue();
        return userHasRole(user, roles);
    };
    UserContextService.prototype.hasAcceptedTerms = function () {
        if (!this.isAuthenticated()) {
            return false;
        }
        var user = this._user.getValue();
        if (user == null) {
            return false;
        }
        return user.currentTermsOfUseAccepted === true;
    };
    UserContextService.prototype.isManualIntegration = function () {
        var user = this._user.getValue();
        if (user == null) {
            return false;
        }
        var partner = user.partner;
        if (partner == null) {
            return false;
        }
        var partnerActivities = partner.partnerActivities;
        if (partnerActivities != null &&
            partnerActivities.canAddVehicles &&
            partnerActivities.canUpdateVehicles &&
            partnerActivities.canDeleteVehicles &&
            partnerActivities.canImportVehicles &&
            partnerActivities.canAddVehicleGroups &&
            partnerActivities.canUpdateVehicleGroups &&
            partnerActivities.canDeleteVehicleGroups &&
            partnerActivities.canAddDrivers &&
            partnerActivities.canUpdateDrivers &&
            partnerActivities.canDeleteDrivers &&
            partnerActivities.canImportDrivers &&
            partnerActivities.canAddDriverGroups &&
            partnerActivities.canUpdateDriverGroups &&
            partnerActivities.canDeleteDriverGroups) {
            return true;
        }
        return false;
    };
    UserContextService.prototype.isVehicleGroupDisabled = function () {
        var user = this._user.getValue();
        if (user == null) {
            return false;
        }
        var partner = user.partner;
        if (partner == null) {
            return false;
        }
        return !partner.partnerActivities.canViewVehicleGroups;
    };
    UserContextService.prototype.isPartnerAccountUser = function () {
        var user = this._user.getValue();
        return isPartnerAccountUser(user);
    };
    UserContextService.prototype.isSpeedCoachingEnabled = function () {
        return this._user.getValue().isSpeedCoachingEnabled;
    };
    UserContextService.prototype.isRoutingProfileEnabled = function () {
        return this._user.getValue().isRoutingProfileEnabled;
    };
    UserContextService.prototype.isConfigurationProfileEnabled = function () {
        return this._user.getValue().isConfigProfileEnabled;
    };
    UserContextService.prototype.isRoadSpeedsEnabled = function () {
        return this._user.getValue().isRoadSpeedsEnabled;
    };
    UserContextService.prototype.isMapSetEnabled = function () {
        return this._user.getValue().isMapSetEnabled;
    };
    UserContextService.prototype.isAuthorizedToViewDeviceRequest = function () {
        var user = this._user.getValue();
        return isAuthorizedToViewDeviceRequest(user);
    };
    UserContextService.prototype.isAuthorizedToEditDataRetention = function () {
        var user = this._user.getValue();
        return (!user.isDisabled &&
            user.currentTermsOfUseAccepted &&
            isAuthorizedToEditDataRetention(user));
    };
    UserContextService.prototype.getRedirectUrl = function (redirectUrl) {
        if (this.isPartnerAccountUser()) {
            return "/partnerAccounts";
        }
        else if (this.isVehicleGroupDisabled()) {
            return "/routing-profiles";
        }
        else {
            return redirectUrl;
        }
    };
    return UserContextService;
}());
export { UserContextService };
// moved these outside the class as they are also called from login component with the authResp
export function isPartnerAccountUser(user) {
    var hasSupportRole = userHasRole(user, ["ALK Support", "Support"]);
    var isValidPartnerType = isValidPartnerAccountType(user);
    // since we're not supporting partner type 'Other' yet, return false if the user has partner
    // type of 'Other', even if the user has a support role.
    return hasSupportRole && isValidPartnerType;
}
export function isAuthorizedToViewDeviceRequest(user) {
    return userHasRole(user, ["ALK Support", "ALK Administrator"]);
}
export function isAuthorizedToEditDataRetention(user) {
    return userHasRole(user, ["ALK Administrator", "Company Administrator"]);
}
// returns true if the passed user has any of the roles passed
export function userHasRole(user, rolesToCheckFor) {
    var e_1, _a, e_2, _b;
    if (user == null || user.roles == null || !user.roles.length) {
        return false;
    }
    try {
        // check if the user has any of the roles
        for (var _c = tslib_1.__values(user.roles), _d = _c.next(); !_d.done; _d = _c.next()) {
            var hasRole = _d.value;
            try {
                for (var rolesToCheckFor_1 = (e_2 = void 0, tslib_1.__values(rolesToCheckFor)), rolesToCheckFor_1_1 = rolesToCheckFor_1.next(); !rolesToCheckFor_1_1.done; rolesToCheckFor_1_1 = rolesToCheckFor_1.next()) {
                    var passedRole = rolesToCheckFor_1_1.value;
                    // needs to be case insensitive search, in some environments, it's "Support" vs "support"
                    if (hasRole.toUpperCase() === passedRole.toUpperCase()) {
                        return true;
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (rolesToCheckFor_1_1 && !rolesToCheckFor_1_1.done && (_b = rolesToCheckFor_1.return)) _b.call(rolesToCheckFor_1);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
        }
        finally { if (e_1) throw e_1.error; }
    }
    // if user has none of the roles, return false
    return false;
}
export function isValidPartnerAccountType(user) {
    if (user == null || user.partner.partnerName == null) {
        return false;
    }
    var isOtherTypePartnerAccount = false;
    switch (user.partner.partnerName.toUpperCase()) {
        case "OTHER":
        case "UNKNOWN":
            isOtherTypePartnerAccount = true;
    }
    return !isOtherTypePartnerAccount;
}
