export function min(minValue: number) {
  return (control) => {
    if (control.value == null) { return null; }

    const value = +control.value;

    if (value >= minValue) { return null; }

    return { min: { requiredLength: minValue } };
  };
}

export function max(maxValue: number) {
  return (control) => {
    if (control.value == null) { return null; }

    const value = +control.value;

    if (value <= maxValue) { return null; }

    return { max: { requiredLength: maxValue } };
  };
}

export function incrementBy(increments: number) {
  return (control) => {
    if (control.value == null) { return null; }

    const value = +control.value;

    if (value % increments === 0) { return null; }

    return { incrementBy: { increments } };
  };
}

export function maxDecimalPlaces(maxPlaces: number) {
  return (control) => {
    if (control.value == null) { return null; }

    const value = +control.value;

    const match = ('' + value).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);

    // no decimal places, return null
    if (!match) { return null; }

    const actual = Math.max(
      0,
      // Number of digits right of decimal point.
      (match[1] ? match[1].length : 0)
      // Adjust for scientific notation.
      - (match[2] ? +match[2] : 0));

    if (actual <= maxPlaces) { return null; }

    return { maxDecimalPlaces: { max } };
  };

}
