import { from as observableFrom } from 'rxjs';
// angular components
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
// 3rd party components
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationModal, incrementBy, min, max } from './../shared';
import { RoadSpeedsService } from './../shared/road-speeds-service';
import { AccountManagementService } from './../../alk-components/account-management';
import { UserContextService } from './../../alk-components/authentication';
import { convertKMToMile, convertMileToKM } from './../shared/utils/convertUtil';
var GlobalRoadSpeedsConstants = {
    Truck: [
        { roadCategory: 'InterStateRural', roadSpeedValue: 43 },
        { roadCategory: 'DividedRural', roadSpeedValue: 31 },
        { roadCategory: 'PrimaryRural', roadSpeedValue: 25 },
        { roadCategory: 'FerriesRural', roadSpeedValue: 18 },
        { roadCategory: 'SecondaryRural', roadSpeedValue: 16 },
        { roadCategory: 'LocalRural', roadSpeedValue: 12 },
        { roadCategory: 'InterStateUrban', roadSpeedValue: 43 },
        { roadCategory: 'DividedUrban', roadSpeedValue: 31 },
        { roadCategory: 'PrimaryUrban', roadSpeedValue: 25 },
        { roadCategory: 'FerriesUrban', roadSpeedValue: 18 },
        { roadCategory: 'SecondaryUrban', roadSpeedValue: 16 },
        { roadCategory: 'LocalUrban', roadSpeedValue: 12 }
    ],
    Auto: [
        { roadCategory: 'InterStateRural', roadSpeedValue: 55 },
        { roadCategory: 'DividedRural', roadSpeedValue: 31 },
        { roadCategory: 'PrimaryRural', roadSpeedValue: 25 },
        { roadCategory: 'FerriesRural', roadSpeedValue: 18 },
        { roadCategory: 'SecondaryRural', roadSpeedValue: 16 },
        { roadCategory: 'LocalRural', roadSpeedValue: 12 },
        { roadCategory: 'InterStateUrban', roadSpeedValue: 55 },
        { roadCategory: 'DividedUrban', roadSpeedValue: 31 },
        { roadCategory: 'PrimaryUrban', roadSpeedValue: 25 },
        { roadCategory: 'FerriesUrban', roadSpeedValue: 18 },
        { roadCategory: 'SecondaryUrban', roadSpeedValue: 16 },
        { roadCategory: 'LocalUrban', roadSpeedValue: 12 }
    ],
    MidsizeTruck: [
        { roadCategory: 'InterStateRural', roadSpeedValue: 50 },
        { roadCategory: 'DividedRural', roadSpeedValue: 35 },
        { roadCategory: 'PrimaryRural', roadSpeedValue: 30 },
        { roadCategory: 'FerriesRural', roadSpeedValue: 18 },
        { roadCategory: 'SecondaryRural', roadSpeedValue: 25 },
        { roadCategory: 'LocalRural', roadSpeedValue: 18 },
        { roadCategory: 'InterStateUrban', roadSpeedValue: 50 },
        { roadCategory: 'DividedUrban', roadSpeedValue: 31 },
        { roadCategory: 'PrimaryUrban', roadSpeedValue: 25 },
        { roadCategory: 'FerriesUrban', roadSpeedValue: 18 },
        { roadCategory: 'SecondaryUrban', roadSpeedValue: 16 },
        { roadCategory: 'LocalUrban', roadSpeedValue: 12 }
    ],
    LightDuty: [
        { roadCategory: 'InterStateRural', roadSpeedValue: 65 },
        { roadCategory: 'DividedRural', roadSpeedValue: 45 },
        { roadCategory: 'PrimaryRural', roadSpeedValue: 30 },
        { roadCategory: 'FerriesRural', roadSpeedValue: 18 },
        { roadCategory: 'SecondaryRural', roadSpeedValue: 25 },
        { roadCategory: 'LocalRural', roadSpeedValue: 18 },
        { roadCategory: 'InterStateUrban', roadSpeedValue: 55 },
        { roadCategory: 'DividedUrban', roadSpeedValue: 30 },
        { roadCategory: 'PrimaryUrban', roadSpeedValue: 20 },
        { roadCategory: 'FerriesUrban', roadSpeedValue: 18 },
        { roadCategory: 'SecondaryUrban', roadSpeedValue: 15 },
        { roadCategory: 'LocalUrban', roadSpeedValue: 10 }
    ],
    Bus: [
        { roadCategory: 'InterStateRural', roadSpeedValue: 50 },
        { roadCategory: 'DividedRural', roadSpeedValue: 35 },
        { roadCategory: 'PrimaryRural', roadSpeedValue: 30 },
        { roadCategory: 'FerriesRural', roadSpeedValue: 18 },
        { roadCategory: 'SecondaryRural', roadSpeedValue: 25 },
        { roadCategory: 'LocalRural', roadSpeedValue: 18 },
        { roadCategory: 'InterStateUrban', roadSpeedValue: 50 },
        { roadCategory: 'DividedUrban', roadSpeedValue: 31 },
        { roadCategory: 'PrimaryUrban', roadSpeedValue: 25 },
        { roadCategory: 'FerriesUrban', roadSpeedValue: 18 },
        { roadCategory: 'SecondaryUrban', roadSpeedValue: 16 },
        { roadCategory: 'LocalUrban', roadSpeedValue: 12 }
    ]
};
var RoadSpeedsProfile = /** @class */ (function () {
    function RoadSpeedsProfile(roadSpeedsService, accountService, fb, fb2, translate, userContext) {
        this.roadSpeedsService = roadSpeedsService;
        this.accountService = accountService;
        this.fb = fb;
        this.fb2 = fb2;
        this.translate = translate;
        this.userContext = userContext;
        // preset values
        this.isRoadSpeedsEnabled = false;
        this.region = this.userContext.getUser().regionalDataSet.indexOf('EU') >= 0 ? 'EU' : 'NA';
        this.showGlobalRoadSpeeds = this.userContext.getUser().showGlobalRoadSpeeds;
        this.units = this.userContext.getUser().unitType === 'Metric' ? 'kph' : 'mph';
        this.maxSpeed = this.userContext.getUser().unitType === 'Metric' ? 137 : 85;
        this.minSpeed = this.userContext.getUser().unitType === 'Metric' ? 8 : 5;
        this.showUnitConversionTooltip = false;
        this.isProcessing = false;
        this.speedSetting = '';
        this.vehicleTypes = [];
        this.selectedVehicleType = '';
        this.vehicleObj = [];
        this.selectedSpeedType = ''; // tab to be displayed
        this.globalSpeeds = [];
        this.jurisdictionSpeeds = [];
        this.speedsBeforeReset = [];
        this.jurisdictionList = [];
        this.jurisAbbr = '';
        this.jurisName = '';
        this.areJurisSpeedsDefault = false;
        this.countryList = [];
        this.origSpeeds = [];
        this.selectedCountry = '';
        this.resetSelected = false;
        this.enableSave = false; // used only for reset
        this.confirmationMsg = '';
        this.form = this.fb.group({
            vehicleType: [],
            InterStateRural: [5, Validators.compose([
                    min(this.minSpeed), max(this.maxSpeed), incrementBy(1), Validators.required,
                ])],
            DividedRural: [5, Validators.compose([
                    min(this.minSpeed), max(this.maxSpeed), incrementBy(1), Validators.required
                ])],
            PrimaryRural: [5, Validators.compose([
                    min(this.minSpeed), max(this.maxSpeed), incrementBy(1), Validators.required
                ])],
            FerriesRural: [5, Validators.compose([
                    min(this.minSpeed), max(this.maxSpeed), incrementBy(1), Validators.required
                ])],
            SecondaryRural: [5, Validators.compose([
                    min(this.minSpeed), max(this.maxSpeed), incrementBy(1), Validators.required
                ])],
            LocalRural: [5, Validators.compose([
                    min(this.minSpeed), max(this.maxSpeed), incrementBy(1), Validators.required
                ])],
            InterStateUrban: [5, Validators.compose([
                    min(this.minSpeed), max(this.maxSpeed), incrementBy(1), Validators.required
                ])],
            DividedUrban: [5, Validators.compose([
                    min(this.minSpeed), max(this.maxSpeed), incrementBy(1), Validators.required
                ])],
            PrimaryUrban: [5, Validators.compose([
                    min(this.minSpeed), max(this.maxSpeed), incrementBy(1), Validators.required
                ])],
            FerriesUrban: [5, Validators.compose([
                    min(this.minSpeed), max(this.maxSpeed), incrementBy(1), Validators.required
                ])],
            SecondaryUrban: [5, Validators.compose([
                    min(this.minSpeed), max(this.maxSpeed), incrementBy(1), Validators.required
                ])],
            LocalUrban: [5, Validators.compose([
                    min(this.minSpeed), max(this.maxSpeed), incrementBy(1), Validators.required
                ])]
        });
        this.speedTypeForm = this.fb2.group({
            speedSelection: []
        });
    }
    RoadSpeedsProfile.prototype.ngOnInit = function () {
        var _this = this;
        var user = this.userContext.getUser();
        this.unitType = user.unitType;
        this.showUnitConversionTooltip = user.enableUnitConversion;
        this.isRoadSpeedsEnabled = this.userContext.isRoadSpeedsEnabled();
        if (!this.isRoadSpeedsEnabled) {
            return;
        }
        this.isProcessing = true;
        this.vehicleTypes = [];
        this.jurisdictionList = [];
        this.accountService.getVehicleTypes().subscribe(function (result) {
            var availableVehicleTypes = [];
            result.forEach(function (vehicleType) {
                switch (vehicleType) {
                    case 'Truck':
                        availableVehicleTypes.push({ seq: 0, id: 'Truck', text: 'Heavy Duty', icon: 'ico-heavyduty' });
                        break;
                    case 'MidsizeTruck':
                        availableVehicleTypes.push({ seq: 1, id: 'MidsizeTruck', text: 'Medium Duty', icon: 'ico-mediumduty' });
                        break;
                    case 'LightDuty':
                        availableVehicleTypes.push({ seq: 2, id: 'LightDuty', text: 'Light Duty', icon: 'ico-lightduty' });
                        break;
                    case 'Auto':
                        availableVehicleTypes.push({ seq: 3, id: 'Auto', text: 'Auto', icon: 'ico-route-car' });
                        break;
                    case 'Bus':
                        availableVehicleTypes.push({ seq: 4, id: 'Bus', text: 'School Bus', icon: 'ico-school-bus' });
                        break;
                }
            });
            _this.vehicleTypes = availableVehicleTypes.sort(function (a, b) {
                return (a.seq > b.seq) ? 1 : ((b.seq > a.seq) ? -1 : 0);
            });
            _this.vehicleObj = [_this.vehicleTypes[0]];
            _this.selectedVehicleType = _this.vehicleTypes[0].id;
            _this.form.get('vehicleType').setValue(_this.vehicleTypes[0].id);
            if (_this.userContext.getUser().useGlobalRoadSpeeds && _this.userContext.getUser().showGlobalRoadSpeeds) {
                _this.speedTypeForm.get('speedSelection').setValue('global');
                _this.getGlobalSpeeds(false);
                _this.speedSetting = 'global';
                _this.selectedSpeedType = 'global'; // tab setting
            }
            else {
                _this.speedTypeForm.get('speedSelection').setValue('juris');
                _this.getJurisdictionList(_this.selectedVehicleType);
                _this.speedSetting = 'juris';
                _this.selectedSpeedType = 'juris'; // tab setting
            }
        });
    };
    RoadSpeedsProfile.prototype.onVehicleTypeChanged = function () {
        var _this = this;
        this.form.get('vehicleType').markAsPristine();
        if (this.form.dirty) {
            this.confirmModal.open().then(function () {
                _this.changeVehicleType(_this.form.get('vehicleType').value);
                _this.form.markAsPristine();
            });
        }
        else {
            this.changeVehicleType(this.form.get('vehicleType').value);
        }
    };
    RoadSpeedsProfile.prototype.changeVehicleType = function (id) {
        this.selectedVehicleType = id;
        var typeName = this.vehicleTypes.filter(function (type) { return type.id === id; })[0].text;
        this.vehicleObj = [{
                id: id,
                text: typeName
            }];
        if (this.selectedSpeedType === 'juris') {
            this.getJurisdictionList(this.selectedVehicleType);
        }
        else {
            this.getGlobalSpeeds(false);
        }
        this.form.markAsPristine();
    };
    RoadSpeedsProfile.prototype.getJurisdictionList = function (selectedVehicleType) {
        var _this = this;
        this.isProcessing = true;
        this.roadSpeedsService.getJurisdictionsList(selectedVehicleType).subscribe(function (result) {
            if (_this.region === 'NA') {
                _this.jurisdictionList = _this.groupby(result, 'countryName');
                _this.countryList = _this.getCountries(_this.jurisdictionList);
                _this.selectedCountry = _this.selectedCountry ? _this.selectedCountry : _this.countryList[0];
                if (_this.selectedCountry) {
                    _this.changeVisibility(_this.selectedCountry);
                }
                _this.jurisAbbr = _this.jurisAbbr ? _this.jurisAbbr : _this.jurisdictionList[_this.selectedCountry][0].stateAbbr;
                _this.jurisName = _this.jurisName ? _this.jurisName : _this.jurisdictionList[_this.selectedCountry][0].stateName;
                _this.areJurisSpeedsDefault = _this.jurisName ?
                    _this.isDefault(_this.selectedCountry, _this.jurisName, 'NA') : _this.jurisdictionList[_this.selectedCountry][0].areSpeedsDefault;
                _this.onJurisdictionChange(_this.jurisAbbr, _this.jurisName, _this.areJurisSpeedsDefault, false);
            }
            else {
                _this.jurisdictionList = result;
                _this.jurisAbbr = _this.jurisAbbr ? _this.jurisAbbr : _this.jurisdictionList[0].countryAbbr;
                _this.jurisName = _this.jurisName ? _this.jurisName : _this.jurisdictionList[0].countryName;
                _this.areJurisSpeedsDefault = _this.jurisName ?
                    _this.isDefault('', _this.jurisName, 'EU') : _this.jurisdictionList[0].areJurisSpeedsDefault;
                _this.onJurisdictionChange(_this.jurisAbbr, _this.jurisName, _this.areJurisSpeedsDefault, false);
            }
        }, function (error) {
            _this.errorMessage = _this.translate.get('views.road-speeds.errors.getError');
            _this.isProcessing = false;
        });
    };
    RoadSpeedsProfile.prototype.getGlobalSpeeds = function (isDefault) {
        var _this = this;
        this.isProcessing = true;
        this.roadSpeedsService.getGlobalSpeeds(this.selectedVehicleType, isDefault).subscribe(function (result) {
            _this.globalSpeeds = result;
            _this.loadForm(_this.globalSpeeds);
            _this.isProcessing = false;
            _this.areJurisSpeedsDefault = _this.areGlobalSpeedsDefault(_this.globalSpeeds, _this.selectedVehicleType);
        }, function (error) {
            _this.errorMessage = _this.translate.get('views.road-speeds.errors.getError');
            _this.isProcessing = false;
        });
    };
    RoadSpeedsProfile.prototype.changeVisibility = function (country) {
        if (this.jurisdictionList[country].show) {
            this.jurisdictionList[country].show = !this.jurisdictionList[country].show;
        }
        else {
            this.jurisdictionList[country].show = true;
        }
        this.selectedCountry = country;
    };
    RoadSpeedsProfile.prototype.onTabChange = function (tab) {
        var _this = this;
        if (this.selectedSpeedType !== tab && this.speedSetting !== 'global') {
            if (this.form.dirty) {
                this.confirmModal.open().then(function () {
                    _this.form.markAsPristine();
                    _this.changeView(tab);
                });
            }
            else {
                this.changeView(tab);
            }
            // this.handleFormChanges(this.changeView, tab);
        }
    };
    RoadSpeedsProfile.prototype.changeView = function (tab) {
        this.isProcessing = true;
        if (tab === 'juris') {
            this.getJurisdictionList(this.selectedVehicleType);
        }
        else {
            this.getGlobalSpeeds(false);
        }
        this.selectedSpeedType = tab;
    };
    RoadSpeedsProfile.prototype.onJurisdictionChange = function (state, name, areSpeedsDefault, isDefault) {
        var _this = this;
        this.isProcessing = true;
        if (this.form.dirty && !isDefault) {
            this.confirmModal.open().then(function () {
                _this.form.markAsPristine();
                _this.changeJurisdiction(state, name, areSpeedsDefault, isDefault);
            });
        }
        else {
            this.changeJurisdiction(state, name, areSpeedsDefault, isDefault);
        }
    };
    RoadSpeedsProfile.prototype.changeJurisdiction = function (state, name, areSpeedsDefault, isDefault) {
        var _this = this;
        this.jurisName = name;
        this.jurisAbbr = state;
        this.areJurisSpeedsDefault = areSpeedsDefault;
        this.roadSpeedsService.getSpeedsbyJurisdiction(state, this.selectedVehicleType, isDefault).subscribe(function (result) {
            _this.jurisdictionSpeeds = result;
            _this.loadForm(_this.jurisdictionSpeeds);
            _this.enableSave = isDefault;
            _this.isProcessing = false;
        }, function (error) {
            _this.errorMessage = _this.translate.get('views.road-speeds.errors.getError');
            _this.isProcessing = false;
        });
    };
    RoadSpeedsProfile.prototype.onSettingDropdown = function (type) {
        var _this = this;
        var modal = type === 'global' ? this.globalModal : this.jurisModal;
        if (this.speedSetting !== 'type') {
            modal.open().then(function () {
                _this.changeSpeedSetting(type);
            });
        }
        this.speedTypeForm.get('speedSelection').setValue(this.speedSetting);
    };
    RoadSpeedsProfile.prototype.changeSpeedSetting = function (type) {
        var _this = this;
        var useGlobal = type === 'global' ? true : false;
        this.roadSpeedsService.enableGlobalSpeeds(useGlobal)
            .subscribe(function (r) {
            _this.speedSetting = type;
            _this.successMessage = _this.translate.get('view.road-speeds.savedSettings');
            _this.speedTypeForm.get('speedSelection').setValue(_this.speedSetting);
            _this.userContext.getUser().useGlobalRoadSpeeds = useGlobal;
            if (type === 'global' && _this.selectedSpeedType === 'juris') {
                _this.form.markAsPristine(); // SWAT-1002 enable buttons
                _this.changeView('global');
            }
        }, function (error) {
            _this.speedTypeForm.get('speedSelection').setValue(_this.speedSetting);
            _this.errorMessage = _this.translate.get('view.road-speeds.unableToSaveSettings');
        });
    };
    RoadSpeedsProfile.prototype.loadForm = function (list) {
        this.origSpeeds = this.resetSelected ? this.origSpeeds : list;
        this.resetSelected = false;
        // tslint:disable-next-line: prefer-for-of
        for (var i = 0; i < list.length; i++) {
            var category = list[i].roadCategory;
            var speedValue = list[i].roadSpeedValue;
            this.form.get(category).setValue(speedValue);
        }
    };
    RoadSpeedsProfile.prototype.getCountries = function (list) {
        var countryList = [];
        Object.keys(list).forEach(function (key, index) {
            countryList.push(key);
        });
        return countryList;
    };
    RoadSpeedsProfile.prototype.resetRoadSpeeds = function () {
        this.form.markAsDirty();
        this.resetSelected = true;
        this.speedsBeforeReset = this.origSpeeds;
        if (this.selectedSpeedType === 'global') {
            this.getGlobalSpeeds(true);
        }
        else {
            this.onJurisdictionChange(this.jurisAbbr, this.jurisName, this.areJurisSpeedsDefault, true);
        }
    };
    RoadSpeedsProfile.prototype.cancel = function () {
        this.form.markAsPristine();
        var speeds = this.resetSelected ? this.speedsBeforeReset : this.origSpeeds;
        if (this.selectedSpeedType === 'global') {
            this.areJurisSpeedsDefault = this.areGlobalSpeedsDefault(speeds, this.selectedVehicleType);
        }
        this.resetSelected = false;
        this.loadForm(speeds);
    };
    RoadSpeedsProfile.prototype.save = function () {
        var _this = this;
        this.errorMessage = null;
        this.successMessage = null;
        this.resetSelected = false;
        this.roadSpeedsService.updateSpeeds(this.selectedVehicleType, this.selectedSpeedType, this.jurisAbbr, this.form).toPromise().then(function () {
            _this.form.markAsPristine();
            if (_this.selectedSpeedType === 'juris') {
                _this.getJurisdictionList(_this.selectedVehicleType);
            }
            else {
                _this.getGlobalSpeeds(false);
            }
            _this.successMessage = _this.translate.get('views.road-speeds.saved');
        }, function (error) {
            _this.isProcessing = false;
            _this.errorMessage = _this.translate.get('views.road-speeds.errors.DataError');
        });
    };
    RoadSpeedsProfile.prototype.canDeactivate = function () {
        if (!this.form.dirty) {
            return true;
        }
        return observableFrom(this.confirmModal.open());
    };
    // TODO @vani change all the functions to use this
    RoadSpeedsProfile.prototype.handleFormChanges = function (func) {
        var _this = this;
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        if (this.form.dirty) {
            this.confirmModal.open().then(function () {
                _this.form.markAsPristine();
                func(args);
            });
        }
        else {
            func(args);
        }
    };
    RoadSpeedsProfile.prototype.showInterstateSpeedConversionInfo = function () {
        if (this.unitType === 'English') {
            if (this.selectedSpeedType === 'global') {
                return convertMileToKM(this.form.controls.InterStateRural.value).toString() + " kph";
            }
            // tslint:disable-next-line: max-line-length
            return convertMileToKM(this.form.controls.InterStateRural.value).toString() + " kph / " + convertMileToKM(this.form.controls.InterStateUrban.value).toString() + " kph";
        }
        else {
            if (this.selectedSpeedType === 'global') {
                return convertKMToMile(this.form.controls.InterStateRural.value).toString() + " mph";
            }
            // tslint:disable-next-line: max-line-length
            return convertKMToMile(this.form.controls.InterStateRural.value).toString() + " mph / " + convertKMToMile(this.form.controls.InterStateUrban.value).toString() + " mph";
        }
    };
    RoadSpeedsProfile.prototype.showDividedSpeedConversionInfo = function () {
        if (this.unitType === 'English') {
            if (this.selectedSpeedType === 'global') {
                return convertMileToKM(this.form.controls.DividedRural.value).toString() + " kph";
            }
            // tslint:disable-next-line: max-line-length
            return convertMileToKM(this.form.controls.DividedRural.value).toString() + " kph / " + convertMileToKM(this.form.controls.DividedUrban.value).toString() + " kph";
        }
        else {
            if (this.selectedSpeedType === 'global') {
                return convertKMToMile(this.form.controls.DividedRural.value).toString() + " mph ";
            }
        }
        // tslint:disable-next-line: max-line-length
        return convertKMToMile(this.form.controls.DividedRural.value).toString() + " mph / " + convertKMToMile(this.form.controls.DividedUrban.value).toString() + " mph";
    };
    RoadSpeedsProfile.prototype.showSecondarySpeedConversionInfo = function () {
        if (this.unitType === 'English') {
            if (this.selectedSpeedType === 'global') {
                return convertMileToKM(this.form.controls.SecondaryRural.value).toString() + " kph";
            }
            // tslint:disable-next-line: max-line-length
            return convertMileToKM(this.form.controls.SecondaryRural.value).toString() + " kph / " + convertMileToKM(this.form.controls.SecondaryUrban.value).toString() + " kph";
        }
        else {
            if (this.selectedSpeedType === 'global') {
                return convertKMToMile(this.form.controls.SecondaryRural.value).toString() + "  mph";
            }
        }
        // tslint:disable-next-line: max-line-length
        return convertKMToMile(this.form.controls.SecondaryRural.value).toString() + "  mph / " + convertKMToMile(this.form.controls.SecondaryUrban.value).toString() + " mph";
    };
    RoadSpeedsProfile.prototype.showPrimarySpeedConversionInfo = function () {
        if (this.unitType === 'English') {
            if (this.selectedSpeedType === 'global') {
                return convertMileToKM(this.form.controls.PrimaryRural.value).toString() + " kph";
            }
            // tslint:disable-next-line: max-line-length
            return convertMileToKM(this.form.controls.PrimaryRural.value).toString() + " kph / " + convertMileToKM(this.form.controls.PrimaryUrban.value).toString() + " kph";
        }
        else {
            if (this.selectedSpeedType === 'global') {
                return convertKMToMile(this.form.controls.PrimaryRural.value).toString() + " mph";
            }
        }
        // tslint:disable-next-line: max-line-length
        return convertKMToMile(this.form.controls.PrimaryRural.value).toString() + " mph / " + convertKMToMile(this.form.controls.PrimaryUrban.value).toString() + " mph";
    };
    RoadSpeedsProfile.prototype.showLocalSpeedConversionInfo = function () {
        if (this.unitType === 'English') {
            if (this.selectedSpeedType === 'global') {
                return convertMileToKM(this.form.controls.LocalRural.value).toString() + " kph";
            }
            // tslint:disable-next-line: max-line-length
            return convertMileToKM(this.form.controls.LocalRural.value).toString() + " kph / " + convertMileToKM(this.form.controls.LocalUrban.value).toString() + " kph";
        }
        else {
            if (this.selectedSpeedType === 'global') {
                return convertKMToMile(this.form.controls.LocalRural.value).toString() + " mph";
            }
        }
        // tslint:disable-next-line: max-line-length
        return convertKMToMile(this.form.controls.LocalRural.value).toString() + " mph / " + convertKMToMile(this.form.controls.LocalUrban.value).toString() + " mph";
    };
    RoadSpeedsProfile.prototype.showFerrySpeedConversionInfo = function () {
        if (this.unitType === 'English') {
            if (this.selectedSpeedType === 'global') {
                return convertMileToKM(this.form.controls.FerriesRural.value).toString() + " kph";
            }
            // tslint:disable-next-line: max-line-length
            return convertMileToKM(this.form.controls.FerriesRural.value).toString() + " kph / " + convertMileToKM(this.form.controls.FerriesUrban.value).toString() + " kph";
        }
        else {
            if (this.selectedSpeedType === 'global') {
                return convertKMToMile(this.form.controls.FerriesRural.value).toString() + " mph";
            }
        }
        // tslint:disable-next-line: max-line-length
        return convertKMToMile(this.form.controls.FerriesRural.value).toString() + " mph / " + convertKMToMile(this.form.controls.FerriesUrban.value).toString() + " mph";
    };
    RoadSpeedsProfile.prototype.isDefault = function (country, juris, region) {
        var list = region === 'NA' ? this.jurisdictionList[country] : this.jurisdictionList;
        var i = list.map(function (s) {
            return s.stateName || s.countryName;
        }).indexOf(juris);
        return list[i].areSpeedsDefault;
    };
    RoadSpeedsProfile.prototype.areGlobalSpeedsDefault = function (speeds, vehicleType) {
        return (JSON.stringify(speeds) === JSON.stringify(GlobalRoadSpeedsConstants[vehicleType]));
    };
    RoadSpeedsProfile.prototype.groupby = function (list, prop) {
        return list.reduce(function (groups, item) {
            var val = item[prop];
            groups[val] = groups[val] || [];
            groups[val].push(item);
            return groups;
        }, []);
    };
    return RoadSpeedsProfile;
}());
export { RoadSpeedsProfile };
