
import {of as observableOf } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { UserContextService } from './../alk-components/authentication';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private auth: UserContextService, private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    // some special roles are required so check if the user has at least 1 of those roles
    const user = this.auth.getUser();

    // if user is not authenticated let them authenticate first, bring them to the login page with a redirect back to this page.
    if (!this.auth.isAuthenticated()) {
      const authParams = { s: 0, r: state.url };
      this.router.navigate(['/login', authParams]);
      return observableOf(false);
    }

    // tslint:disable-next-line:no-string-literal
    const roles = next.data['roles'] || [];

    // user is authenticated at this point, and no special roles are required, return true.
    if (roles.length === 0) { return observableOf(true); }

    // use rhas at least one of the roles, return true
    if (this.hasAnyRole(user.roles, roles)) { return observableOf(true); }

    // user does not have at least one of the roles, redirect them to the login page
    // todo: update login page to look at 's' parameter to know what message to appear. 1- user not authorized, 0 - user not authenticated.
    const params = { s: 1 };

    this.auth.clearUser();
    this.router.navigate(['/login', params]);
    return observableOf(false);
  }

  hasAnyRole(hasRoles: Array<string>, lookForRoles: Array<string>) {
    for (const lookForRole of lookForRoles) {
      for (const hasRole of hasRoles) {
        // needs to be case insensitive search, in some environments, it's "Support" vs "support"
        if (hasRole.toUpperCase() === lookForRole.toUpperCase()) {
         return true;
        }
      }
    }
    return false;
  }
}
