import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';

// 3rd party components
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';

// alk components
import { PartnerAccount } from './partner-account';
import { PartnerAccountService } from './partner-account.service';
import { UserContextService } from './../../alk-components/authentication';

@Component({
  selector: 'partner-account-selector',
  templateUrl: './partner-account-selector.component.html',
  providers: [PartnerAccountService]
})
// tslint:disable-next-line: component-class-suffix
export class PartnerAccountSelector implements OnInit {
  partnerAccounts: Array<PartnerAccount>;
  partnerItems: Array<any> = [];
  isProcessing = true;
  public form: FormGroup;

  @ViewChild('partnerSelect', { static: true }) partnerSelect: any; // why doesn't SelectComponent work?  (from ng2-select)

  constructor(
    private partnerAccountService: PartnerAccountService,
    private userContextService: UserContextService,
    private router: Router,
    private ag2analytics: Angulartics2GoogleAnalytics,
    private fb: FormBuilder) {
      this.form = this.fb.group({
        partner: []
      });
    }

  ngOnInit() {
    // only attempt to populate this control if the current user is a partner
    if (this.userContextService.isPartnerAccountUser()) {
      this.partnerAccountService.getPartnerAccounts().subscribe(resp => {
        this.isProcessing = false;
        this.partnerAccounts = resp;
        if (this.partnerAccounts.length <= 0) {
          console.warn('Current user is a partner user but has no partner accounts.');
        } else {
          this.partnerItems = this.partnerAccounts.map(partnerAccount => ({ id: partnerAccount.id, text: partnerAccount.name }));
          let currentPartnerId = this.userContextService.getUser().currentPartnerAccountId;

          // // if there is no currently selected partner, use the current account
          // if (!currentPartnerId)
          //   currentPartnerId = this.userContextService.getUser().accountId;

          // if there is no currently selected partner, default to the first account in the list
          if (!currentPartnerId) {
            currentPartnerId = this.partnerAccounts[0].id;
          }

          const currentPartnerItem = this.partnerItems.filter(partnerItem => partnerItem.id === currentPartnerId).pop();
          if (!currentPartnerItem) {
            console.error(`Unable to find current partner, currentPartnerId: ${currentPartnerId}, partnerAccounts:`, this.partnerItems);
          } else {
            this.form.get('partner').setValue(currentPartnerId);
          }

        }

      });
    }
  }

  onPartnerSelectionChanged() {
    const partnerName = this.partnerItems.filter(item => item.id === this.form.get('partner').value)[0].text;
    // store the partner account in the user context
    this.userContextService.setPartnerAccount(this.form.get('partner').value, partnerName);
    // store the partner-AccountId, cookie login cleans the user context and we loose track of
    // the selected partnerAccount while we switch partner accounts at the vehicle-groups page
    this.userContextService.setSelectedPartnerAccount(this.form.get('partner').value.toString(), partnerName);
    // log the event to analytics
    this.ag2analytics.eventTrack(`Switched to partner account: ${partnerName}`, {});
    // we need to force the current page to refresh, easiest way to do that is route away
    // however if you try to route to the same page the request will be ignored
    // so we will always navigate to the home landing page, and the user context updates an
    // observable for the active partner account, which the dashboard subscribes to, so if
    // the user was already on the dashboard, that components knows to refresh
    if (this.router.routerState.snapshot.url === '/vehicle-groups') {
      window.location.href = '/vehicle-groups';
    } else {
      this.router.navigate(['/vehicle-groups']);
    }
  }

}
