
import {forkJoin as observableForkJoin,  Subscription ,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
// angular components
import { Component, OnInit, OnDestroy, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

// 3rd party components
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { ModalDirective } from 'ngx-bootstrap';
import { TranslateService } from '@ngx-translate/core';

// app components
import { ConfirmationModal } from './../../shared';
import { AccountUserManagementService, UserInAccount, UserAssociatedGroup } from './../../../alk-components/account-user-management';
import { VehicleGroupService } from './../../vehicle-group/shared';
import { DriverGroupService } from './../../driver-group/shared';
import { GroupAssociationsComponent, GroupItem } from './../../shared/group-associations';
import { alkValidEmailAddress } from './../../../alk-components/common-validation';

@Component({
  selector: 'admin-user-edit-modal',
  providers: [AccountUserManagementService, VehicleGroupService, DriverGroupService],
  templateUrl: './admin-user-edit-modal.component.html'
})
// tslint:disable-next-line: component-class-suffix
export class AdminUserEditModal implements OnInit, OnDestroy {
  addMode = false; // if false we are in edit mode
  isProcessing = false;
  submitted = false;

  roles: Array<any> = [];
  vehicleGroups: Array<any> = [];
  driverGroups: Array<any> = [];
  form: FormGroup;

  emailErrorMsg: Observable<string>;
  errorMsg: Observable<string>;
  errorUserName = '';

  @ViewChild('confirmModal', { static: true }) confirmModal: ConfirmationModal;
  @ViewChild('modal', { static: true }) modal: ModalDirective;
  @ViewChild('vehicleGroupAssociations', { static: true }) vehicleGroupAssociations: GroupAssociationsComponent;
  @ViewChild('driverGroupAssociations', { static: true }) driverGroupAssociations: GroupAssociationsComponent;
  @Output() userChanged = new EventEmitter();

  private sub1: Subscription;
  private user: UserInAccount;

  constructor(
    private accountUserService: AccountUserManagementService,
    private vehicleGroupService: VehicleGroupService,
    private driverGroupService: DriverGroupService,
    private el: ElementRef,
    private fb: FormBuilder,
    private translate: TranslateService,
    private ag2analytics: Angulartics2GoogleAnalytics
  ) {
    this.form = this.fb.group({
      emailAddress: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(64), alkValidEmailAddress]],
      firstName: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(64)]],
      lastName: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(64)]],
      enabled: [],
      role: []
    });
  }

  close() {
    // if 0 driver groups added/removed and 0 vehicle groups added/removed and no changes to the form, close.
    if (!this.form.dirty &&
      this.driverGroupAssociations.itemsPendingAddition.length === 0 &&
      this.driverGroupAssociations.itemsPendingRemoval.length === 0 &&
      this.vehicleGroupAssociations.itemsPendingAddition.length === 0 &&
      this.vehicleGroupAssociations.itemsPendingRemoval.length === 0
    ) {
      this.modal.hide();
    } else {
      this.confirmModal.open().then(() => this.modal.hide());
    }
  }

  ngOnInit() {
    this.vehicleGroupAssociations.columnDefinitions = [
      { title: 'Name', isFilterable: true }
    ];
    this.driverGroupAssociations.columnDefinitions = [
      { title: 'Name', isFilterable: true }
    ];

    this.accountUserService.getUserRoles()
      .subscribe(roles => {
        const availableRoles = [];

        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < roles.length; i++) {
          if (roles[i].toUpperCase() === 'ALKADMIN') {
            availableRoles.push({ translationKey: 'roles.ALKAdmin', value: roles[i], sort: 0 });

          } else if (roles[i].toUpperCase() === 'ALKSUPPORT') {
            availableRoles.push({ translationKey: 'roles.ALKSupport', value: roles[i], sort: 1 });

          } else if (roles[i].toUpperCase() === 'SUPPORT') {
            availableRoles.push({ translationKey: 'roles.Support', value: roles[i], sort: 2 });

          } else if (roles[i].toUpperCase() === 'COMPANYADMIN') {
            availableRoles.push({ translationKey: 'roles.CompanyAdmin', value: roles[i], sort: 3 });

          } else if (roles[i].toUpperCase() === 'USER') {
            availableRoles.push({ translationKey: 'roles.User', value: roles[i], sort: 4 });

          }
        }

        this.roles = availableRoles.sort((a, b) => a.sort - b.sort);
      });

    this.vehicleGroupService.getGroups().pipe(
      map(results => {
        // convert to an array of GroupItem
        // better way to do this?
        const allGroups: Array<GroupItem> = [];
        results.forEach(result => {
          allGroups.push({
            id: result.id,
            fields: [result.name]
          });
        });
        return allGroups;
      }))
      .subscribe(results => {
        this.vehicleGroupAssociations.allPossibleItems = results;
      }, error => {
        this.errorMsg = this.translate.get('views.admin-user-edit.errors.UnknownGettingVehicleGroups', { error: error.message });
      });

    this.driverGroupService.getGroups().pipe(
      map(results => {
        // convert to an array of GroupItem
        // better way to do this?
        const allGroups: Array<GroupItem> = [];
        results.forEach(result => {
          allGroups.push({
            id: result.id,
            fields: [result.name]
          });
        });
        return allGroups;
      }))
      .subscribe(results => {
        this.driverGroupAssociations.allPossibleItems = results;
      }, error => {
        this.errorMsg = this.translate.get('views.admin-user-edit.errors.UnknownGettingDriverGroups', { error: error.message });
      });

    this.sub1 = this.modal.onShown.subscribe(() => {
      // when the modal is shown, make sure the first input tag gets focus
      const nativeElement = this.el.nativeElement.getElementsByTagName('input')[0];
      if (nativeElement) { nativeElement.focus(); }
    });
  }

  ngOnDestroy() {
    this.sub1.unsubscribe();
  }

  loadForm(attributes) {

    for (const attr in attributes) {
      if (!attributes.hasOwnProperty(attr)) { continue; }

      const control =  this.form.controls[attr] as FormControl;
      if (!control) { continue; }

      control.setValue(attributes[attr]);
    }
  }

  open(addMode: boolean, userId?: number) {
    this.addMode = addMode;
    // better way?  should we be destroying this on modal close instead of hide?
    this.errorMsg = null;
    this.emailErrorMsg = null;

    this.ag2analytics.eventTrack(`User ${addMode ? 'Add' : 'Edit'} dialog opened`, {});
    this.modal.show();

    if (this.addMode) {
      this.user = this.getNewUser();
      this.loadForm(this.user);

      // mark as pristine when intially loading form on opening the modal
      this.form.markAsPristine();

      // reset
      this.vehicleGroupAssociations.initialItems = [];
      this.driverGroupAssociations.initialItems = [];

      this.vehicleGroups = [];
      this.driverGroups = [];

    } else {
      this.isProcessing = true;

      observableForkJoin([
        this.getUserDetails(userId),
        this.getVehicleGroups(userId),
        this.getDriverGroups(userId)
      ]).subscribe(results => {

        const user = results[0];

        this.user = user;
        this.vehicleGroupAssociations.initialItems = results[1];
        this.driverGroupAssociations.initialItems = results[2];

        this.loadForm(this.user);

        // mark as pristine when intially loading form on opening the modal
        this.form.markAsPristine();

        this.isProcessing = false;

      }, (error) => {
        this.isProcessing = false;
      });
    }
  }

  getUserDetails(userId: number): Observable<any> {
    return this.accountUserService.getUser(userId);
  }

  getVehicleGroups(userId: number): Observable<Array<any>> {
    return this.accountUserService.getUserVehicleGroups(userId).pipe(
      map(results => {
        return results.map(result => {
          return { id: result.id, fields: [result.name], pendingRemoval: false, pendingAddition: false };
        });
      }));
  }

  getDriverGroups(userId: number): Observable<Array<any>> {
    return this.accountUserService.getUserDriverGroups(userId).pipe(
      map(results => {
        return results.map(result => {
          return { id: result.id, fields: [result.name], pendingRemoval: false, pendingAddition: false };
        });
      }));
  }

  save() {
    this.submitted = true;
    this.errorMsg = null;

    if (!this.form.valid) {
      this.ag2analytics.eventTrack('User Edit save attempted', { failReason: 'Invalid input' });
      return;
    }

    const user = this.user;
    user.enabled = this.form.value.enabled;
    user.emailAddress = this.form.value.emailAddress;
    user.firstName = this.form.value.firstName;
    user.lastName = this.form.value.lastName;
    user.role = this.form.value.role;

    user.vehicleGroups = this.vehicleGroupAssociations.resultItems.map(dg => {
      const uaGroup = new UserAssociatedGroup();
      uaGroup.groupId = dg.id;
      uaGroup.groupName = dg.fields[0];
      return uaGroup;
    });

    user.driverGroups = this.driverGroupAssociations.resultItems.map(dg => {
      const uaGroup = new UserAssociatedGroup();
      uaGroup.groupId = dg.id;
      uaGroup.groupName = dg.fields[0];
      return uaGroup;
    });

    this.isProcessing = true;
    let createOrUpdateUser$: Observable<any> = null;
    if (this.addMode) {
      createOrUpdateUser$ = this.accountUserService.createUser(user);
    } else {
      createOrUpdateUser$ = this.accountUserService.updateUser(user);
    }

    createOrUpdateUser$.subscribe(resp => {
      const userId: number = this.addMode ? resp.userId : user.userId;

      // now upate the vehicle and driver groups for this user
      const updateUserVehicleGroups$ = this.accountUserService.setUserVehicleGroups(
        userId,
        user.vehicleGroups.map(uag => uag.groupId));
      const updateUserDriverGroups$ = this.accountUserService.setUserDriverGroups(
        userId,
        user.driverGroups.map(uag => uag.groupId));

        observableForkJoin([updateUserVehicleGroups$, updateUserDriverGroups$])
        .subscribe(() => {
          this.isProcessing = false;
          this.submitted = false;

          this.ag2analytics.eventTrack('User update save successful', {});

          this.modal.hide();
          this.userChanged.emit({
            addedNew: false,
            user
          });
        },
        (err) => {
          this.isProcessing = false;

          this.errorMsg = this.translate.get('views.admin-user-edit.errors.UnknownSaving');

          const analyticMessage = `An error occurred updating groups for this user: ${err}`;
          this.ag2analytics.eventTrack('User create/update error', { errMsg: analyticMessage });
        }
      );

    }, (err) => {
      // error from crate/update
      this.isProcessing = false;
      if (err.status === 409) {
        this.errorUserName = this.form.value.emailAddress;
        this.emailErrorMsg = this.translate.get('views.admin-user-edit.errors.UserAlreadyExists', { username: this.errorUserName });
      } else if (this.addMode) {
        this.errorMsg = this.translate.get('views.admin-user-edit.errors.UnknownCreatingUser', { error: err.status });
      } else {
        this.errorMsg = this.translate.get('views.admin-user-edit.errors.UnknownUpdatingUser', { error: err.status });
      }
    });
  }

  private getNewUser() {
    const newUser = new UserInAccount();
    newUser.enabled = true;
    newUser.firstName = '';
    newUser.lastName = '';
    newUser.emailAddress = '';
    newUser.role = 'User';

    return newUser;
  }
}
