
import { of as observableOf } from 'rxjs';

import { switchMap, distinctUntilChanged, debounceTime } from 'rxjs/operators';
// angular components
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

// alk components
import { VehicleService, Vehicle } from '../shared/vehicle.service';
import { VehicleEditModal } from '../vehicle-edit-modal';

// shared
import { GroupsUtil } from './../../../app/shared/utils/groupsUtil';

import { ConfirmationModal } from './../../shared';
import { UserContextService } from './../../../alk-components/authentication';

@Component({
  selector: 'vehicle-list',
  providers: [VehicleService],
  templateUrl: './vehicle-list.component.html'
})
// tslint:disable-next-line: component-class-suffix
export class VehicleList implements OnInit {

  @ViewChild('confirmModal', { static: true }) confirmModal: ConfirmationModal;
  @ViewChild('vehicleEditModal', { static: true }) vehicleEditModal: VehicleEditModal;

  isProcessing = false;
  allVehicles = [];
  filteredVehicles = [];
  sortAsc = true;
  searchTerm = new FormControl();
  downloadUrl = '';
  isImportEnabled = true;
  isExportEnabled = true;
  addButtonVisible = true;
  editButtonVisible = true;
  deleteButtonVisible = true;
  isActiveVehiclesOnly = true;

  errorMsg: string;
  total = 0;
  currentPage = 0;
  perPage = 10;
  pageItems: Array<any> = [];

  vehicleAdded = false;
  sortCol = '';

  constructor(
    private vehicleService: VehicleService,
    private userContext: UserContextService,
    private router: Router
  ) {
    const hasAlkRole = this.userContext.hasRole([
      'ALK Administrator',
      'ALK Support',
    ]);
    const partnerActivities = this.userContext.getUser().partner.partnerActivities;
    this.isImportEnabled = partnerActivities.canImportVehicles && hasAlkRole;
    this.isExportEnabled = partnerActivities.canExportVehicles;
    this.addButtonVisible = partnerActivities.canAddVehicles && hasAlkRole;
    this.editButtonVisible = partnerActivities.canUpdateVehicles;
    this.deleteButtonVisible = partnerActivities.canDeleteVehicles;
  }

  public ngOnInit() {
    this.isProcessing = true;

    this.searchTerm.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => this.filterAll(term)))
      .subscribe(items => {
        this.filteredVehicles = items;
        this.total = this.filteredVehicles.length;
        this.currentPage = 0;
        this.setCurrentPageItems();
      });

    this.getVehicleList();
  }

  getVehicleList() {
    this.vehicleService.getVehicleList(this.isActiveVehiclesOnly)
      .subscribe(vehicles => {
        this.allVehicles = vehicles.items;
        this.filteredVehicles = this.allVehicles;
        this.total = this.filteredVehicles.length;
        this.setCurrentPageItems();
      }, error => {
        this.errorMsg = 'There was an error retrieving vehicles: ' + error.message;
      }, () => {
        this.isProcessing = false;
        this.loadVehicles();
      });
  }

  loadVehicles() {
    this.vehicleService.getVehicles(null, null, null, null, this.isActiveVehiclesOnly)
      .subscribe(vehicles => {
        this.allVehicles = vehicles.items;
        this.filteredVehicles = this.allVehicles;
        this.total = this.filteredVehicles.length;
        this.setCurrentPageItems();
      }, error => {
        this.errorMsg = 'There was an error retrieving vehicles: ' + error.message;
      }, () => {
        this.isProcessing = false;
      });

    this.downloadUrl = this.vehicleService.getExportUrl(null, true);
  }

  toggleActiveVehicles() {
    this.isProcessing = true;
    this.getVehicleList();
  }

  editVehicle(vehicle) {
    if (this.editButtonVisible) {
      this.vehicleEditModal.open(false, vehicle.id);
    }
  }

  showVehicleDetails(vehicle) {
    this.router.navigate(['/vehicle', vehicle.id]);
  }

  deleteItem(item) {
    this.confirmModal.open()
      .then(() => {
        // tslint:disable-next-line:no-string-literal
        item['isProcessing'] = true;

        this.vehicleService.deleteVehicle(item.id)
          .subscribe(c => {
            // tslint:disable-next-line:no-string-literal
            item['isProcessing'] = false;
            // remove the group from the list and get the current page of items
            const index = this.getItemIndex(this.allVehicles, item);

            // if we didn't find the group index, don't do anything.  Otherwise, remove the item from the list
            if (index >= 0) {
              this.allVehicles.splice(index, 1);
              this.filteredVehicles = this.allVehicles;
              this.total = this.filteredVehicles.length;
              this.setCurrentPageItems();
            }

          }, (error) => {
            // tslint:disable-next-line:no-string-literal
            item['isProcessing'] = false;
            this.errorMsg = 'Error while deleting vehicle';
          });
      });
  }

  getToolTipForGroups(groups: Array<any>) {
    return GroupsUtil.getToolTipforGroups(groups);
  }

  sortString(column: string, asc: boolean) {
    const multiplyBy = asc ? 1 : -1;

    this.filteredVehicles.sort((a, b) => {
      let aUpperCase = '';
      if (a[column] != null) {
        aUpperCase = a[column].toUpperCase();
      }
      let bUpperCase = '';
      if (b[column] != null) {
        bUpperCase = b[column].toUpperCase();
      }

      if (aUpperCase < bUpperCase) { return (-1 * multiplyBy); }
      if (aUpperCase > bUpperCase) { return (1 * multiplyBy); }
      return 0;
    });

    this.setCurrentPageItems();
  }

  sortNumber(column: string, asc: boolean) {
    const multiplyBy = asc ? 1 : -1;

    this.filteredVehicles.sort((a, b) => {
      return (a[column] - b[column]) * multiplyBy;
    });

    this.setCurrentPageItems();
  }

  previousPage() {
    if (!this.hasPreviousPage()) { return; }

    this.currentPage--;
    this.setCurrentPageItems();
  }

  nextPage() {
    if (!this.hasNextPage()) { return; }

    this.currentPage++;
    this.setCurrentPageItems();
  }

  pageStart() {
    if (this.filteredVehicles.length === 0) { return 0; }
    return (this.currentPage * this.perPage) + 1;
  }

  pageEnd() {
    let retVal = (this.currentPage * this.perPage) + this.perPage;
    if (retVal > this.total) {
      retVal = this.total;
    }
    return retVal;
  }

  onVehicleUpdated(update) {
    if (update.addedNew) {
      this.loadVehicles();
    } else {
      // this.loadVehicles();
      const modifiedVehicle: Vehicle = this.allVehicles.filter(vehicle => vehicle.id === update.id)[0];
      modifiedVehicle.name = update.vehicle.name;
      modifiedVehicle.vehicleId = update.vehicle.vehicleId;
    }
  }

  public hasNextPage(): boolean {
    const nextPageStartIndex = (this.currentPage + 1) * this.perPage;
    return this.total > nextPageStartIndex;
  }

  public hasPreviousPage(): boolean {
    return this.currentPage > 0;
  }

  private getItemIndex(groups, group): number {
    for (let i = 0; i < groups.length; i++) {
      if (groups[i].id === group.id) {
        return i;
      }
    }

    return -1;
  }

  private setCurrentPageItems() {
    const startPageIndex = this.currentPage * this.perPage;
    const endPageIndex = startPageIndex + this.perPage;

    this.pageItems = this.filteredVehicles.slice(startPageIndex, endPageIndex);
  }

  private filter(vehicles: Array<any>, searchTerm: string) {
    searchTerm = searchTerm.toUpperCase();

    return vehicles.filter(vehicle => {
      if (vehicle.name != null &&
        vehicle.name.toUpperCase().indexOf(searchTerm) !== -1) { return true; }
      if (vehicle.vehicleId != null &&
        vehicle.vehicleId.toUpperCase().indexOf(searchTerm) !== -1) { return true; }
      if (vehicle.vehicleGroupsAbbreviated != null &&
        vehicle.vehicleGroupsAbbreviated.toUpperCase().indexOf(searchTerm) !== -1) { return true; }

      return false;
    });
  }

  private filterAll(searchTerm?: string) {
    let results = this.allVehicles;

    if (searchTerm) {
      results = this.filter(results, searchTerm);
    }

    return observableOf(results);
  }

  clearSearchTerm() {
    this.searchTerm.setValue('');
  }
}
