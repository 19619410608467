import { Injectable, EventEmitter } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { LanguageOption } from './language-option';

@Injectable()
export class ALKLanguageContext {

  public onLangChange: EventEmitter<LanguageOption> = new EventEmitter<LanguageOption>();
  private availableLanguages;

  // todo: pass abstract class into constructor which has the langauges supported
  constructor(
    private translate: TranslateService,
    private title: Title,
    private router: Router) {

    //  let deutsche = new LanguageOption('Deutsche', 'de');
    const english = new LanguageOption('English', 'en', true);

    this.availableLanguages = [english];

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {

        const titleKey = this.getTitle(router.routerState, router.routerState.root).join('-');
        const productNameKey = 'application.ProductName';
        this.translate.get([titleKey, productNameKey]).subscribe((translatedValues) =>
          this.title.setTitle(translatedValues[productNameKey] + ' - ' + translatedValues[titleKey]));
      }
    });
  }

  public init() {
    // first try to get the users lang out of local Storage.
    // If it doesn't exist in there, get it out of the navigator object if that exists
    let userLang = this.getLanguage() || this.translate.getBrowserLang();

    userLang = /(de|en)/gi.test(userLang) ? userLang : 'en';

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.setLanguage(userLang);
  }

  public getAvailableLanguages(): Array<LanguageOption> {
    return this.availableLanguages;
  }

  public getLanguage() {
    return localStorage.getItem('alk-language');
  }

  public setLanguage(language) {

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.availableLanguages.length; i++) {
      if (this.availableLanguages[i].value === language) {
        localStorage.setItem('alk-language', language);
        this.translate.use(language);
        this.onLangChange.emit(this.availableLanguages[i]);
        break;
      }
    }
  }

  // TODO: this might be a better way: https://github.com/angular/angular/issues/9662#issuecomment-229034288 to get data of current route
  // collect that title data properties from all child routes
  // there might be a better way but this worked for me
  private getTitle(state, parent) {
    const data = [];

    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }

    return data;
  }
}
