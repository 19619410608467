import { Component, OnInit, OnDestroy } from '@angular/core';

import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';

import { ALKLanguageContext } from './alk-language-context';
import { Subscription } from 'rxjs';

@Component({
  selector: 'alk-language-select',
  templateUrl: './alk-language-select.component.html'
})
// tslint:disable-next-line: component-class-suffix
export class ALKLanguageSelect implements OnInit, OnDestroy {
  availableLanguages: Array<any>;
  selectedLanguage: string;

  private langChangeSubscription: Subscription;

  constructor(private languageContext: ALKLanguageContext, private ag2analytics: Angulartics2GoogleAnalytics) { }

  ngOnInit() {
    this.availableLanguages = this.languageContext.getAvailableLanguages();

    // set the language to the initial value
    const currentLanguage = this.languageContext.getLanguage();
    if (currentLanguage) { this.setLangOnComponent(currentLanguage); }

    // listen for the language to change and update the display value when it does change
    this.langChangeSubscription = this.languageContext.onLangChange.subscribe(event => {
      this.setLangOnComponent(event.value);
      this.ag2analytics.eventTrack('Language Change', { language: event.value });
    });
  }

  ngOnDestroy() {
    this.langChangeSubscription.unsubscribe();
  }

  setLangOnComponent(language: string) {
    // language is already the language code , example: 'en'.
    // Could set the selectedLanguage directly. This just ensures that it is a valid language.
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.availableLanguages.length; i++) {
      if (this.availableLanguages[i].value === language) {
        this.selectedLanguage = this.availableLanguages[i].value;
        break;
      }
    }
  }

  switchLang() {
    this.languageContext.setLanguage(this.selectedLanguage);
  }
}
