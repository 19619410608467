// for speeds and distances
export function convertMileToKM(miles: any) {
    return Math.round(((miles * 1.6093) * 10) / 10);
}

// for speeds and distances
export function convertKMToMileDecimal(km: any) {
    return (((km / 1.6093) * 10) / 10).toFixed(2);
}

// for oor
export function convertMileToKMDecimal(miles: any) {
    return (((miles * 1.6093) * 10) / 10).toFixed(2);
}

// for speeds and distances
export function convertKMToMile(km: any) {
    return Math.round(((km / 1.6093) * 10) / 10);
}

// For elevation limit
export function convertFtToMetre(feet: any) {
    return Math.round(((feet * 0.3048) * 10) / 10);
}

// For elevation limit
export function convertMetreToFt(metre: any) {
    return Math.round(((metre / 0.3048) * 10) / 10);
}

// For Width
export function convertInchToMetre(inch: any) {
    return (((inch * 0.0254) * 100) / 100).toFixed(2);
}

// For Width, while displaying in
export function convertMetreToInch(cm: any) {
    return Math.round(((cm / 0.0254) * 100) / 100);
}

// For Height and Length
export function cmtoFeetInches(cm: any) {
    const realFeet = ((cm * 0.393700) / 12);
    let feet = Math.floor(realFeet);
    let inches = Math.round((realFeet - feet) * 12);

    if (inches === 12) {
        inches = 0;
        feet = feet + 1;
    }
    // tslint:disable-next-line: max-line-length
    return `${feet === 0 && inches === 0 ? `0 in` : `${feet === 0 ? `${inches} in` : `${inches === 0 ? `${feet} ft` : `${feet} ft ${inches} in`}`} `}`;
}

// For Height and Length
export function inchtoMcm(inch: any) {
    const realMetre = ((inch * 2.54) / 100);
    let metre = Math.floor(realMetre);
    let cm = Math.round((realMetre - metre) * 100);
    if (cm === 100) {
        cm = 0;
        metre = metre + 1;
    }
    return `${metre === 0 && cm === 0 ? `0 cm` : `${metre === 0 ? `${cm} cm` : `${cm === 0 ? `${metre} m` : `${metre} m ${cm} cm`}`} `}`;
}

// For Weight and weight per axle
export function metricTonneToLbs(mtonne: any) {
    return `${Math.round(mtonne * 2204.62)} lbs`;
}

export function lbsToMetricTonne(mtonne: any) {
    return `${Math.round(mtonne / 2204.62)} t`;
}
