import { Component, Input, OnInit } from '@angular/core';
import { clone, isEqual } from 'lodash';
import { CompanySettingsService } from '../../../admin/company-settings/company-settings.service';
import { CheckBoxModel } from '../../shared/checkbox.model';
import { CustomMapset } from '../../shared/custom-mapset.model';
import { Mapset } from '../../shared/mapset.model';
import { MapsetsService } from '../../shared/mapsets.service';
import { ResponseRegion } from '../../shared/region.enum';
import { PanRegion } from './map-region.model';
@Component({
  selector: 'map-regions-list',
  providers: [MapsetsService],
  styleUrls: ['./map-regions.component.scss'],
  templateUrl: './map-regions.component.html',
})
export class MapRegionsComponent implements OnInit {
  private mapSetSettings: any;
  private MAX_SELECTED_REGIONS = 5;
  private OTHER_REGIONS = [
    'Alaska',
    'Hawaii',
    'Puerto Rico',
    'US Virgin Islands',
  ];
  private otherRegionArray = [];
  private region: ResponseRegion;
  private regions: PanRegion;
  public initialMapset: CustomMapset;
  private na = ResponseRegion.NA;
  private eu = ResponseRegion.EU; // can't use enum in angular template unless it's a local variable
  private descriptionCheckboxes: CheckBoxModel[][] = new Array();
  private nonDescriptionCheckboxes: CheckBoxModel[][] = new Array();
  loading = true;
  @Input() mapset: CustomMapset;
  checkboxModel: CheckBoxModel;
  constructor(
    private mapsetsService: MapsetsService,
    private companySettingsService: CompanySettingsService
  ) {}

  ngOnInit() {
    this.mapsetsService.getMapRegions().subscribe((x) => {
      this.regions = x;
      this.initRegions();
    });
    this.companySettingsService.getMapSetSettings().subscribe((x) => {
      this.mapSetSettings = x;
      this.MAX_SELECTED_REGIONS = this.mapSetSettings.maxMapSetAssignments;
    });
  }

  private initRegions() {
    this.updateInitialMapset();
    this.initialMapset.mapRegions.sort((x, y) => this.sortMapsets(x, y));
    this.checkboxModel = {
      id: this.regions.regionDetails.mapsetId,
      name: this.regions.regionDetails.name,
      checked: this.shouldBeChecked(this.regions.regionDetails.mapsetId),
      description: this.regions.regionDetails.description,
      children: [],
    } as CheckBoxModel;
    if (this.regions.regionDetails.name.indexOf('North America') >= 0) {
      this.region = ResponseRegion.NA;
    }
    if (this.regions.regionDetails.name.indexOf('Europe') >= 0) {
      this.region = ResponseRegion.EU;
    }
    for (const fullRegion of this.regions.fullRegions) {
      if (fullRegion.regionDetails.description === 'Canada') {
        const fullRegionCheckbox = this.returnCheckboxNode(
          fullRegion.regionDetails.mapsetId,
          fullRegion.regionDetails.name,
          fullRegion.regionDetails.description
        );
        this.checkboxModel.children.push(fullRegionCheckbox);
        const canadianProvincesCheckbox = this.returnCheckboxNode(
          fullRegion.regionDetails.mapsetId,
          'Canadian Provinces',
          'Canada'
        );
        fullRegionCheckbox.children.push(canadianProvincesCheckbox);
        for (const majorRegion of fullRegion.majorRegions) {
          const subRegionCheckbox = this.returnCheckboxNode(
            majorRegion.regionDetails.mapsetId,
            majorRegion.regionDetails.name,
            majorRegion.regionDetails.description
          );
          canadianProvincesCheckbox.children.push(subRegionCheckbox);
        }
      } else {
        const fullRegionCheckbox = this.returnCheckboxNode(
          fullRegion.regionDetails.mapsetId,
          fullRegion.regionDetails.name,
          fullRegion.regionDetails.description
        );
        this.checkboxModel.children.push(fullRegionCheckbox);
        for (const majorRegion of fullRegion.majorRegions) {
          const subRegionCheckbox = this.returnCheckboxNode(
            majorRegion.regionDetails.mapsetId,
            majorRegion.regionDetails.name,
            majorRegion.regionDetails.description
          );
          if (this.OTHER_REGIONS.indexOf(majorRegion.regionDetails.name) >= 0) {
            this.otherRegionArray.push(subRegionCheckbox);
            continue;
          }
          fullRegionCheckbox.children.push(subRegionCheckbox);
          if (this.region === ResponseRegion.EU) {
            if (majorRegion.regionDetails.description.split(',').length > 1) {
              this.descriptionCheckboxes.push([
                subRegionCheckbox,
                fullRegionCheckbox,
              ]);
            } else {
              this.nonDescriptionCheckboxes.push([
                subRegionCheckbox,
                fullRegionCheckbox,
              ]);
            }
          }
          if (!majorRegion.minorRegions) {
            continue;
          }
          for (const minorRegion of majorRegion.minorRegions) {
            const stateCheckbox = this.returnCheckboxNode(
              minorRegion.mapsetId,
              minorRegion.name,
              minorRegion.description
            );
            subRegionCheckbox.children.push(stateCheckbox);
          }
        }
        if (this.otherRegionArray.length > 0) {
          fullRegionCheckbox.children.push({
            name: 'Other',
            id: 0,
            description: '',
            children: this.otherRegionArray,
            checked: this.otherRegionArray.every((x) => x.checked === true),
          });
        }
      }
    }
    this.InitializeCheckBoxes(this.checkboxModel);
  }

  public updateInitialMapset() {
    if (this.mapset) {
      this.initialMapset = JSON.parse(JSON.stringify(this.mapset));
    }
  }
  private shouldBeChecked(id: number): boolean {
    if (this.mapset.mapRegions == null) {
      return false;
    }
    return this.mapset.mapRegions.filter((x) => x.mapsetId === id).length > 0;
  }

  private returnCheckboxNode(
    id: number,
    name: string,
    description?: string
  ): CheckBoxModel {
    return {
      id,
      name,
      description: description || '',
      checked: this.shouldBeChecked(id),
      children: [],
    };
  }

  public mapsetHasChanged() {
    if (!this.initialMapset) {
      return false;
    } else {
      const initial = clone(this.initialMapset.mapRegions);
      const mapsets = this.buildMapsets();
      mapsets.sort((x, y) => this.sortMapsets(x, y));
      initial.sort((x, y) => this.sortMapsets(x, y));
      const hasChanged =
        (this.mapset &&
          this.mapset.customMapsetName !==
            this.initialMapset.customMapsetName) ||
        this.mapset.defaultMapset !== this.initialMapset.defaultMapset ||
        !isEqual(initial, mapsets);
      return hasChanged;
    }
  }

  private sortMapsets(x: Mapset, y: Mapset) {
    if (x.mapsetId === y.mapsetId) {
      return 0;
    }
    if (x.mapsetId < y.mapsetId) {
      return -1;
    }
    return 1;
  }

  private parentClicked(parentBox: CheckBoxModel) {
    if (this.maxCheckboxesSelected && !parentBox.checked) {
      return;
    }
    parentBox.checked = !parentBox.checked;
    const child = (checkbox: CheckBoxModel) => {
      checkbox.checked = parentBox.checked;
    };
    this.iterateTree(child, null, parentBox);
  }

  private InitializeCheckBoxes(checkbox: CheckBoxModel) {
    const parent = () => {
      if (this.checkboxModel.checked) {
        this.selectUnselectAll(checkbox);
      }
    };

    const child = (childCheckBox: CheckBoxModel) => {
      if (childCheckBox.checked) {
        this.selectUnselectAll(childCheckBox);
      }
    };
    this.iterateTree(child, parent, checkbox);
    this.loading = false;
  }

  private middleNodeClicked(parentCheckBoxes, childCheckBox) {
    if (
      parentCheckBoxes[0].checked ||
      (!childCheckBox.checked && this.maxCheckboxesSelected)
    ) {
      return;
    }
    if (childCheckBox.name === 'Other' && !childCheckBox.checked) {
      if (
        this.mapsetsSelected(true) + this.OTHER_REGIONS.length >
        this.MAX_SELECTED_REGIONS
      ) {
        return;
      }
    }
    this.parentClicked(childCheckBox);
    parentCheckBoxes.forEach((x) => {
      this.childDetection(x);
    });
  }

  private childClicked(parentCheckBoxes, childCheckBox) {
    if (
      parentCheckBoxes[0].checked ||
      (!childCheckBox.checked && this.maxCheckboxesSelected)
    ) {
      return;
    }
    childCheckBox.checked = !childCheckBox.checked;
    parentCheckBoxes.forEach((x) => {
      this.childDetection(x);
    });
  }

  private childDetection(parentCheckBox) {
    parentCheckBox.checked = !parentCheckBox.checked;
    parentCheckBox.checked = parentCheckBox.children.every((x) => {
      return x.checked;
    });
  }

  private masterClicked(masterCheckBox) {
    if (this.maxCheckboxesSelected) {
      return;
    }
    masterCheckBox.checked = !masterCheckBox.checked;
    this.selectUnselectAll(masterCheckBox);
  }

  private selectUnselectAll(masterCheckBox: CheckBoxModel) {
    const func = (checkbox) => {
      checkbox.checked = masterCheckBox.checked;
    };
    this.iterateTree(func, null, masterCheckBox);
  }

  public buildMapsets() {
    const mapsets: Mapset[] = new Array();
    const parentFunction = () => {
      if (this.checkboxModel.checked) {
        mapsets.push(
          this.checkboxToMapset(this.checkboxModel.id, this.checkboxModel.name)
        );
        return true;
      }
    };
    const childFunction = (checkbox: CheckBoxModel) => {
      if (checkbox.name === 'Other') {
        return false;
      }
      if (checkbox.checked) {
        mapsets.push(this.checkboxToMapset(checkbox.id, checkbox.name));
        return true;
      }
    };
    this.iterateTree(childFunction, parentFunction);
    return mapsets;
  }

  public get maxCheckboxesSelected(): boolean {
    return (
      this.mapsetsSelected() === this.MAX_SELECTED_REGIONS ||
      this.mapsetsSelected() >= this.MAX_SELECTED_REGIONS
    );
  }

  private mapsetsSelected(ignoreOther?: boolean) {
    let numChecked = 0;
    const parentFunction = () => {
      if (this.checkboxModel && this.checkboxModel.checked) {
        numChecked++;
        return true;
      }
    };
    const childFunction = (checkbox: CheckBoxModel) => {
      let ignoreRegion = false;
      if (ignoreOther){
        ignoreRegion = this.otherRegionArray.some(
          region => {
            return region.id === checkbox.id;
          }
        );
      }
      if (checkbox.name === 'Other' || ignoreRegion) {
        return false;
      }
      if (checkbox.checked) {
        numChecked++;
        return true;
      }
    };
    this.iterateTree(childFunction, parentFunction);
    return numChecked;
  }

  private checkboxToMapset(id: number, name: string): Mapset {
    return {
      mapsetId: id,
      mapsetName: name,
    } as Mapset;
  }

  private iterateTree(
    childFunction: (CheckBoxModel) => any,
    parentFunction?: (CheckBoxModel) => any,
    startingCheckBox?: CheckBoxModel
  ) {
    if (this.checkboxModel) {
      const checkbox = startingCheckBox ? startingCheckBox : this.checkboxModel;
      if (parentFunction && parentFunction.apply(this)) {
        return;
      }
      this.treeNode(checkbox, childFunction);
    }
  }

  private treeNode(checkbox: CheckBoxModel, func: (CheckBoxModel) => any) {
    for (const child of checkbox.children) {
      if (func.apply(this, [child])) {
        continue;
      }
      this.treeNode(child, func);
    }
  }
}
