import { Injectable } from '@angular/core';

@Injectable()
export class PagingService {

  public getPagingQuery(paging?: any): string {
    if (!paging) { return ''; }

    const params = [];

    if (paging.pageNumber != null) {
      params.push('pageNumber=' + paging.pageNumber);
    }

    if (paging.offset != null) {
      params.push('offset=' + paging.offset);
    }

    if (paging.limit != null) {
      params.push('limit=' + paging.limit);
    }

    if (paging.sortBy != null) {
      params.push('sortBy=' + encodeURIComponent(paging.sortBy));
    }

    if (paging.sortAscending != null) {
      params.push('sortAscending=' + (paging.sortAscending === true ? 'true' : 'false'));
    }

    if (paging.sortExpression != null) {
      params.push('sortExpression=' + encodeURIComponent(paging.sortExpression));
    }

    if (paging.sortDirection != null) {
      params.push('sortDirection=' + encodeURIComponent(paging.sortDirection));
    }

    return params.join('&');
  }
}
