import { Injectable } from '@angular/core';
import { UserContextService } from '../../alk-components/authentication';

@Injectable()
export class DriverGroupPartnerPermissions {
  public addButtonVisible = false;
  public editButtonVisible = false;
  public deleteButtonVisible = false;

  constructor(private userContext: UserContextService) {
    const partnerActivities = this.userContext.getUser().partner.partnerActivities;
    this.addButtonVisible = partnerActivities.canAddDriverGroups;
    this.editButtonVisible = this.addButtonVisible ? true : partnerActivities.canUpdateDriverGroups;
    this.deleteButtonVisible = partnerActivities.canDeleteDriverGroups;
  }
}
