/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "ngx-bootstrap/modal/modal.directive";
import * as i2 from "ngx-bootstrap/component-loader/component-loader.factory";
import * as i3 from "@ngx-translate/core/src/translate.pipe";
import * as i4 from "@ngx-translate/core/src/translate.service";
import * as i5 from "./confirmation-modal.component";
var styles_ConfirmationModal = [];
var RenderType_ConfirmationModal = i0.ɵcrt({ encapsulation: 2, styles: styles_ConfirmationModal, data: {} });
export { RenderType_ConfirmationModal as RenderType_ConfirmationModal };
export function View_ConfirmationModal_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { modal: 0 }), (_l()(), i0.ɵeld(1, 16777216, null, null, 15, "div", [["bsModal", ""], ["class", "modal fade"], ["tabindex", "-1"]], null, [[null, "click"], [null, "keydown.esc"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.esc" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onEsc($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 212992, [[1, 4], ["modal", 4]], 0, i1.ModalDirective, [i0.ElementRef, i0.ViewContainerRef, i0.Renderer2, i2.ComponentLoaderFactory], null, null), (_l()(), i0.ɵeld(3, 0, null, null, 13, "div", [["class", "modal-dialog modal-lg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 12, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "button", [["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancelClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(9, 0, null, null, 0, "h4", [["class", "modal-title"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 0, "div", [["class", "modal-body"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 5, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 2, "button", [["class", "btn btn-default cancel-btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancelClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(13, null, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(15, 0, null, null, 1, "button", [["class", "btn btn-default"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOKClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["OK"]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 9, 0, currVal_0); var currVal_1 = _co.body; _ck(_v, 10, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 13, 0, i0.ɵnov(_v, 14).transform("common.Cancel")); _ck(_v, 13, 0, currVal_2); }); }
export function View_ConfirmationModal_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "confirmation-modal", [], null, null, null, View_ConfirmationModal_0, RenderType_ConfirmationModal)), i0.ɵdid(1, 49152, null, 0, i5.ConfirmationModal, [], null, null)], null, null); }
var ConfirmationModalNgFactory = i0.ɵccf("confirmation-modal", i5.ConfirmationModal, View_ConfirmationModal_Host_0, { title: "title", body: "body" }, {}, []);
export { ConfirmationModalNgFactory as ConfirmationModalNgFactory };
