
import { Subscription } from 'rxjs';

import { tap, distinctUntilChanged, debounceTime } from 'rxjs/operators';
// angular components
import {
  Component,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  OnDestroy,
  ElementRef
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { MapsetsService } from '../../../mapsets/shared/mapsets.service';

// 3rd party components
import { ModalDirective } from 'ngx-bootstrap';

// alk
import { GroupItem, GroupItemColumn } from '../group-item';

interface SelectableGroupItem extends GroupItem {
  selected?: boolean;
  disabled?: boolean;
}

@Component({
  providers: [MapsetsService],
  selector: 'group-add-paginated-modal',
  templateUrl: './group-add-paginated-modal.component.html'
})
// tslint:disable-next-line: component-class-suffix
export class GroupAddPaginatedModal implements OnInit, OnDestroy, OnChanges {

  @ViewChild('modal', { static: true }) modal: ModalDirective;

  // tslint:disable-next-line: variable-name
  private _columnDefinitions: Array<GroupItemColumn>;
  set columnDefinitions(columnDefinitions: Array<GroupItemColumn>) {
    this._columnDefinitions = columnDefinitions;
  }

  // tslint:disable-next-line: variable-name
  @Input() excludedGroups: Array<SelectableGroupItem> = [];
  // number of rows of groups to display per page
  @Input() displayPerPage = 10;
  // what are these groups of? if vehicles, use 'Vehicle' here so it will display as 'Vehicle Groups'
  @Input() entityLabel = '';

  @Input() overallTotal = 0;

  @Output() itemsAdded = new EventEmitter();

  isProcessing = true;
  allSelected = false;
  searchTerm = new FormControl();
  currentTotal = 0;
  currentPage = 0;
  displayedItems: Array<SelectableGroupItem> = [];
  selectedItems: Array<SelectableGroupItem> = [];
  entityHeading: string;
  sub1: Subscription;

  constructor(
    private mapsetsService: MapsetsService,
    private el: ElementRef) { }

  ngOnInit() {
    this.sub1 = this.modal.onShown.subscribe(() => {
      // todo: make this another directive to auto focus bootstrap modal fields.
      const nativeElement = this.el.nativeElement.getElementsByTagName('input')[0];
      if (nativeElement) { nativeElement.focus(); }
    });

    this.searchTerm.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => {
        this.currentPage = 0;
      })).subscribe(() => {
        this.updateCurrentPage();
      });
  }

  ngOnDestroy() {
    this.sub1.unsubscribe();
  }

  ngOnChanges() {
    this.entityHeading = 'Select ' + this.entityLabel; // todo: translate
  }

  open() {
    this.allSelected = false;
    this.currentPage = 0;
    this.clearSearchTerm();
    this.updateCurrentPage();
    this.selectedItems = [];
    this.modal.show();
  }

  clearSearchTerm() {
    this.searchTerm.setValue('');
  }

  onAllChanged(isSelected) {
    this.allSelected = isSelected;

    this.displayedItems.forEach(item => {
      this.updateItem(item, isSelected);
    });
  }

  disableAllSelected(){
    this.allSelected = false;
  }

  select(item, isSelected) {
    if (item.disabled === false){
      this.disableAllSelected()
    }
    this.updateItem(item, isSelected);
  }

  updateItem(item, isSelected){
    if (item.disabled === false){
      item.selected = isSelected;
      const newlySelected = this.selectedItems.some((selectedItem) => {
        return selectedItem.id === item.id
      }) === false;
      if (isSelected && newlySelected){
        this.selectedItems.push(item)
      } else if (isSelected === false) {
        this.selectedItems = this.selectedItems.filter((selectedItem) => {
          return selectedItem.id !== item.id
        })
      }
    }
  }

  saveSelected() {
    const selectedGroups: Array<GroupItem> = [];
    this.selectedItems.forEach(item => {
      if (item.selected) {
        selectedGroups.push({ id: item.id, fields: item.fields, pendingAddition: true });
      }
    });
    this.itemsAdded.emit(selectedGroups);
    this.modal.hide();
  }

  nextPage() {
    if (!this.hasNextPage()) { return; }
    this.currentPage++;

    this.updateCurrentPage();
  }

  previousPage() {
    if (!this.hasPreviousPage()) { return; }
    this.currentPage--;

    this.updateCurrentPage();
  }

  getItems(query: string) {
    if (this.entityLabel === "Mapsets"){
      return this.mapsetsService.getMapsets(query,
        { offset: this.currentPage * 10, limit: this.displayPerPage });
    }
  }

  formatMapsets(itemsResponse: any){
    this.displayedItems = itemsResponse.data.map(result => {
      const regions = result.mapRegions.map(region => region.mapsetName).join(', ');
      const disabled = this.excludedGroups.some((item) => {
        return item.id === result.customMapsetId
      })
      const selected = this.selectedItems.some((item) => {
          return item.id === result.customMapsetId
        }) || disabled ? true : false;
      return {
        id: result.customMapsetId,
        fields: [result.customMapsetName, regions],
        pendingRemoval: false,
        pendingAddition: false,
        selected,
        disabled
      };
    });
  }

  public hasNextPage(): boolean {
    const nextPageStartIndex = (this.currentPage + 1) * this.displayPerPage;
    return this.currentTotal > nextPageStartIndex;
  }

  public hasPreviousPage(): boolean {
    return this.currentPage > 0;
  }

  public pageStart() {
    if (this.currentTotal === 0) { return 0; }

    return (this.currentPage * this.displayPerPage) + 1;
  }

  public pageEnd() {
    const retVal = (this.currentPage * this.displayPerPage) + this.displayedItems.length;
    return retVal;
  }

  public updateCurrentPage() {
    this.isProcessing = true;

    this.getItems(this.searchTerm.value)
      .subscribe(itemsResponse => {
        if (this.entityLabel === "Mapsets"){
          this.formatMapsets(itemsResponse)
        }
        this.currentTotal = itemsResponse.size;
        this.isProcessing = false;
        this.allSelected = false;
      }, error => {
        this.isProcessing = false;
      });
  }
}
