
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {map, catchError} from 'rxjs/operators';
// angular components
import { Injectable } from '@angular/core';

import * as _ from 'lodash';

// alk common components

// alk app components
import { environment } from '../../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';

export interface SpeedCoachingProfile {
    speedCoachingProfileId: number;
    Name: string;
    OwnerId: number;
    NumberOfAssignedVehicleGroups: number;
    IsDefault: boolean;
    warningSpeedLimit: number;
    warningTimeLimit: number;
    infractionSpeedLimit: number;
    infractionTimeLimit: number;
}

@Injectable()
export class SpeedCoachingProfileService {

  constructor(private http: HttpClient) { }

  public setIsDefaultChanged(profileId: number, isDefault: boolean) {
    const url = environment.apiUrl + '/v1/speedCoaching-profiles/' + profileId + '/isDefault/' + isDefault;

    return this.http.put(url, '').pipe(
      catchError(this.serverError));
  }

  getProfile(id: number) {
    const url = `${environment.apiUrl}/v1/speedCoaching-profiles/${id}`;
    return this.http.get(url);
  }

  getSpeedCoachingProfilesByVehicleId(id: number) {
    const params = [];
    let url = `${environment.apiUrl}/v1/speedCoaching-profiles/vehicles/${id}`;
    url += params.join('&');

    return this.http.get(url);
  }

  getProfileByGroupId(id: number) {
    const url = `${environment.apiUrl}/v1/speedCoaching-profiles/vehicle-groups/${id}`;
    return this.http.get(url);
  }

  getDefaultProfile_currentAccount() {
    const url = `${environment.apiUrl}/v1/speedCoaching-profiles/default`;
    return this.http.get(url);
  }


  getSpeedCoachingProfiles(): Observable<{ total: number, items: SpeedCoachingProfile[] }> {
    const params = [];
    let url = environment.apiUrl + '/v1/speedCoaching-profiles';
    url += params.join('&');

    return this.http.get<any>(url, { observe: 'response' }).map(resp => {
        const total = +resp.headers.get('X-Total-Count');
        const items: SpeedCoachingProfile[] = resp.body;
        return { total, items };
      });
  }

  create(profile: SpeedCoachingProfile) {
    const url = `${environment.apiUrl}/v1/speedCoaching-profiles`;

    const body = JSON.stringify(profile);

    return this.http.post<any>(url, body).pipe(
      map(resp => {
        return resp;
      }), catchError(error => {
        return observableThrowError(error.json());
      }), );
  }

  update(id: number, ownerId: number, profile: SpeedCoachingProfile) {
    const url = `${environment.apiUrl}/v1/speedCoaching-profiles/${id}`;
    profile.OwnerId = ownerId;
    const body = JSON.stringify(profile);

    return this.http.put(url, body).pipe(
      map(resp => {
        return resp;
      }), catchError(error => {
        return observableThrowError(error.json());
      }), );
  }

  deleteSpeedCoachingProfile(id: number) {
    const url = `${environment.apiUrl}/v1/speedCoaching-profiles/${id}`;
    return this.http.delete(url);
  }

  associateVehicleGroups(id: number, vehicleGroupIds: Array<number>) {
    const url = `${environment.apiUrl}/v1/speedCoaching-profiles/${id}/vehicle-groups`;
    const body = JSON.stringify({ entityId: vehicleGroupIds });

    return this.http.put(url, body);
  }

  getDefaultProfile(): SpeedCoachingProfile {
    return {
      speedCoachingProfileId: -1,
      Name: '',
      OwnerId: -1,
      IsDefault: false,
      NumberOfAssignedVehicleGroups: 0,
      warningSpeedLimit: 2,
      warningTimeLimit: 15,
      infractionSpeedLimit: 2,
      infractionTimeLimit: 30
    };

  }

  private serverError(err: any) {
    if (err instanceof HttpResponse) {
      return observableThrowError(`error: ${err.status}` || 'error');
    }
    return observableThrowError(err || 'error');
  }
}
